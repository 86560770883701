import { useCallback, useEffect } from 'react';

const useDetectUserLeavingApp = (callback?: () => void) => {
  const handleUserLeaving = useCallback(
    e => {
      e.preventDefault();
      if (callback) callback();
    },
    [callback],
  );
  useEffect(() => {
    window.addEventListener('beforeunload', handleUserLeaving);
    return () => {
      window.removeEventListener('beforeunload', handleUserLeaving);
    };
  }, []);
};

export default useDetectUserLeavingApp;
