import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import { getAuthenticationService } from '@mmw/services-holder';
import { ActionsObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, timeout } from 'rxjs/operators';

import { refreshAccessToken } from '../actions';

const { logger } = contextualConfig.application;
const { defaultTimeout } = contextualConfig.api;

type Input = ReturnType<typeof refreshAccessToken.start>;
type Output =
  | ReturnType<typeof refreshAccessToken.success>
  | ReturnType<typeof refreshAccessToken.error>;
const refreshAccessTokenEpic = (
  action$: ActionsObservable<Input>,
): Observable<Output> =>
  action$.pipe(
    ofType(refreshAccessToken.start.match),
    tap(() => logger.info('Trying to refresh access token')),
    switchMap(() =>
      from(getAuthenticationService().refreshAuthentication()).pipe(
        timeout(defaultTimeout),
        map(data => refreshAccessToken.success(data, null)),
        catchError(error => of(refreshAccessToken.error(error, null))),
      ),
    ),
  );

export default refreshAccessTokenEpic;
