import {
  DataCorrectionModelJSON,
  RegistrationResponseJSON,
  RegistrationStatusModelJSON,
  SearchRegistrationRequestFiltersJSON,
} from '@mmw/services-core-trader-registration/types';
import {
  createSelector,
  createSelectorById,
} from '@redux-basic-module/selector-utils';
import { SearchMeta } from '@redux-search-module/actions-utils/types';
import {
  CarouselSearchByUuidState,
  SearchByUuidState,
} from '@redux-search-module/reducer-utils';
import { get } from 'lodash';
import { U } from 'ts-toolbelt';

import {
  INITIAL_CAROUSEL_SEARCH_BY_UUID_STATE,
  INITIAL_RETRIEVE_STATE,
  INITIAL_SEARCH_BY_UUID_STATE,
} from './reducer';
import { NAMESPACE, RootState } from './types';

const searchStateSelector = (
  state: RootState,
  { searchUuid }: SearchMeta,
): SearchByUuidState<SearchRegistrationRequestFiltersJSON> =>
  state[NAMESPACE].searchesByUuid[searchUuid] || INITIAL_SEARCH_BY_UUID_STATE;

export const listSelector = (state: RootState, meta: SearchMeta): number[] =>
  searchStateSelector(state, meta).visibleIds;

export const itemByIdSelector = (
  state: RootState,
  id: number,
): U.Nullable<RegistrationResponseJSON> => state[NAMESPACE].itemsById[id];

export const regListByOrgunitSelector = (
  state: RootState,
  orgunitID: number,
): U.Nullable<RegistrationResponseJSON[]> =>
  state[NAMESPACE].regListByOrgunit[orgunitID];

export const totalSelector = (state: RootState, meta: SearchMeta): number =>
  searchStateSelector(state, meta).total;

export const currentSearchUuidSelector = createSelector<string>([
  NAMESPACE,
  'currentSearchUuid',
]);

export const currentPageSelector = (
  state: RootState,
  meta: SearchMeta,
): number => searchStateSelector(state, meta).currentPage;

export const limitSelector = (state: RootState, meta: SearchMeta): number =>
  searchStateSelector(state, meta).limit;

export const loadingSelector = (state: RootState, meta: SearchMeta): boolean =>
  searchStateSelector(state, meta).loading;

export const auditCreationLoadingSelector = createSelector<boolean>([
  NAMESPACE,
  'auditCreation',
  'loading',
]);

export const changeStatusLoading = createSelector<boolean>([
  NAMESPACE,
  'changeStatus',
  'loading',
]);

export const createIssueLoadingSelector = createSelector<boolean>([
  NAMESPACE,
  'createIssue',
  'loading',
]);

export const issueActionLoadingSelector = createSelectorById<number, boolean>(
  [NAMESPACE, 'performIssue', 'issuesById'],
  ['loading'],
);

export const issueActionErrorSelector = createSelectorById<number, boolean>(
  [NAMESPACE, 'performIssue', 'issuesById'],
  ['error'],
);

export const errorSelector = (
  state: RootState,
  meta: SearchMeta,
): U.Nullable<Error> => searchStateSelector(state, meta).error;

export const requestFieldsSelector = (
  state: RootState,
  meta: SearchMeta,
): Partial<SearchRegistrationRequestFiltersJSON> =>
  searchStateSelector(state, meta).requestFields;

const retrieveStateSelector = (state: RootState, itemId: number) =>
  state[NAMESPACE].retrievesById[itemId] || INITIAL_RETRIEVE_STATE;

const carouselSearchStateSelector = (
  state: RootState,
  meta: SearchMeta,
): CarouselSearchByUuidState<SearchRegistrationRequestFiltersJSON> =>
  state[NAMESPACE].carouselSearchesByUuid[meta.searchUuid] ||
  INITIAL_CAROUSEL_SEARCH_BY_UUID_STATE;

export const retrieveRequestAttemptedSelector = (
  state: RootState,
  itemId: number,
): boolean => retrieveStateSelector(state, itemId).requestAttempted;

export const retrieveRequestLoadingSelector = (
  state: RootState,
  itemId: number,
): boolean => retrieveStateSelector(state, itemId).loading;

const hasCarouselNavigation = (
  s: CarouselSearchByUuidState<SearchRegistrationRequestFiltersJSON>,
  type: 'NEXT' | 'PREV',
): boolean => {
  const valueToSum = type === 'NEXT' ? 1 : -1;
  const { currentItemIndexOnCurrentPage, currentPage } = s;
  if (currentItemIndexOnCurrentPage == null) return false;
  const possibleNext = currentItemIndexOnCurrentPage + valueToSum;
  if (!s.loadedIdsByPage[currentPage]) return false;
  if (s.loadedIdsByPage[currentPage][possibleNext]) {
    return true;
  }
  const nextPage = currentPage + valueToSum;
  return s.loadedIdsByPage[nextPage] != null;
};

export const hasNextCarouselSelector = (
  state: RootState,
  meta: SearchMeta,
): boolean => {
  const s = carouselSearchStateSelector(state, meta);
  return hasCarouselNavigation(s, 'NEXT');
};

export const hasPreviousCarouselSelector = (
  state: RootState,
  meta: SearchMeta,
): boolean => {
  const s = carouselSearchStateSelector(state, meta);
  return hasCarouselNavigation(s, 'PREV');
};

export const currentCarouselItemSelector = (
  state: RootState,
  meta: SearchMeta,
): U.Nullable<RegistrationResponseJSON> => {
  const s = carouselSearchStateSelector(state, meta);
  const { currentItemIndexOnCurrentPage, currentPage, loadedIdsByPage } = s;
  if (currentItemIndexOnCurrentPage < 0) return null;
  const itemID = loadedIdsByPage[currentPage]
    ? loadedIdsByPage[currentPage][currentItemIndexOnCurrentPage]
    : null;
  return itemID ? itemByIdSelector(state, itemID) : null;
};

export const dataCorrectionByItemIdSelector = (
  state: RootState,
  id: number,
): U.Nullable<DataCorrectionModelJSON> => {
  const item = state[NAMESPACE].itemsById[id];
  const statusModel = get(item, 'statusModel');
  return get(statusModel, 'dataCorrection');
};

export const statusModelByItemIdSelector = (
  state: RootState,
  id: number,
): U.Nullable<RegistrationStatusModelJSON> => {
  const item = state[NAMESPACE].itemsById[id];
  return get(item, 'statusModel');
};
