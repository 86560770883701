export enum CampaignStatus {
  INVALID_DATA = 1,
  READY_TO_PROCESS = 2, // campaignStatus = Valid
  TRANSACTION_PROCESSING = 3,
  TRANSACTION_FINISHED = 4,
  TRANSACTION_ERROR = 5,
  CANCELLED = 6,
  FORCE_TO_PROCESS = 7,
  VALIDATING = 8,
  VALIDATION_FAILED = 9,
  INCOMPLETE_DATA = 10,
  FORCED_TO_PROCESS_BY_MANUFCT = 11,
  FORCED_BY_LOADER = 12,
  VERIFYING = 13,
  REJECTED = 14,
  VERIFIED_INCOMPLETE = 15,
  FINAL_CHECK = 16,
  FINALLY_INVALID = 17,
  INVESTIGATION = 18,
  VALID_NO_TRANSACTION = 19,
  CLAWBACK = 20,
  QUEUE = 21,
  READY_FOR_TRANSACTION = 22,
  ONESNAP_AI = 23,
  PROCESS_INVOICE = 24,
}
export const {
  INVALID_DATA,
  READY_TO_PROCESS,
  TRANSACTION_PROCESSING,
  TRANSACTION_FINISHED,
  TRANSACTION_ERROR,
  CANCELLED,
  FORCE_TO_PROCESS,
  VALIDATING,
  VALIDATION_FAILED,
  INCOMPLETE_DATA,
  FORCED_TO_PROCESS_BY_MANUFCT,
  FORCED_BY_LOADER,
  VERIFYING,
  REJECTED,
  VERIFIED_INCOMPLETE,
  FINAL_CHECK,
  FINALLY_INVALID,
  INVESTIGATION,
  VALID_NO_TRANSACTION,
  CLAWBACK,
  QUEUE,
  READY_FOR_TRANSACTION,
  ONESNAP_AI,
  PROCESS_INVOICE,
} = CampaignStatus;

export const LIST_TO_CHECK_VALID_VERIFYING_PARTICIPATION = [
  READY_TO_PROCESS,
  FORCE_TO_PROCESS,
  FORCED_TO_PROCESS_BY_MANUFCT,
  FORCED_BY_LOADER,
  VERIFYING,
  VERIFIED_INCOMPLETE,
  TRANSACTION_FINISHED,
  TRANSACTION_PROCESSING,
  FINAL_CHECK,
  INVESTIGATION,
  VALID_NO_TRANSACTION,
  CLAWBACK,
  QUEUE,
  READY_FOR_TRANSACTION,
  ONESNAP_AI,
  PROCESS_INVOICE,
];
