import { Column, JustifyType, Row } from '@mmw/hybrid-ui-structure-containers';
import { use } from '@mmw/ui-theme/utils';
import useTheme from '@mmw/ui-web-hooks-use-theme';
import PageStructure from '@retail/ui-web-structure-page';
import React, { ReactNode, useMemo } from 'react';
import styled from 'styled-components';

const StyledPage = styled(PageStructure)(props =>
  use(props, 'page.customPages.loginOrSignUp.pageBody'),
);

export const PageContent = styled(PageStructure.Content)(props =>
  use(props, 'page.customPages.loginOrSignUp.pageContent'),
);

export const HalfContentContainer = styled(Column)(props =>
  use(props, 'page.customPages.loginOrSignUp.halfContentContainer'),
);

const Container = styled(Row)(props =>
  use(props, 'page.customPages.loginOrSignUp.container'),
);

const LeftContainer = styled(Column)(props =>
  use(props, 'page.customPages.loginOrSignUp.leftContainer'),
);

const BottomContainer = styled(Row)(props =>
  use(props, 'page.customPages.loginOrSignUp.bottomContainer'),
);

type Props = {
  children: ReactNode;
  leftSideContent?: ReactNode;
  bottomContent?: ReactNode;
  backgroundColor?: string;
  justifyPageContent?: JustifyType;
  pageContainerStyle?: React.CSSProperties;
  customStyle?: React.CSSProperties;
};

const Page: React.FC<Props> = ({
  children,
  leftSideContent,
  backgroundColor,
  justifyPageContent,
  bottomContent,
  pageContainerStyle,
  customStyle,
}: Props) => {
  const theme = useTheme();
  const customPageStyle = useMemo(
    () => ({
      backgroundColor: backgroundColor || theme.colors.pallet.secondary,
      boxShadow: `10px 5px 5px ${theme.colors.pallet.dark}`,
      ...customStyle,
    }),
    [
      customStyle,
      backgroundColor,
      theme.colors.pallet.dark,
      theme.colors.pallet.secondary,
    ],
  );
  return (
    <StyledPage containerStyle={customPageStyle}>
      <PageContent backgroundColor={backgroundColor}>
        <Container justify={justifyPageContent} style={pageContainerStyle}>
          {leftSideContent && (
            <LeftContainer gap={1}>{leftSideContent}</LeftContainer>
          )}
          {children}
        </Container>
      </PageContent>
      {bottomContent && <BottomContainer>{bottomContent}</BottomContainer>}
    </StyledPage>
  );
};

Page.defaultProps = {
  leftSideContent: null,
};

export default Page;
