const i18n = {
  GENERATE: {
    key: 'generate',
    message: 'generate',
  },
  REGENERATE: {
    key: 'regenerate',
    message: 'regenerate',
  },
  SEARCH: {
    key: 'search',
    message: 'search',
  },
  NEXT: {
    key: 'next',
    message: 'Next',
  },
  NEXT_STEP: {
    key: 'nextStep',
    message: 'Next Step',
  },
  BACK: {
    key: 'back',
    message: 'Back',
  },
  RESET: {
    key: 'reset',
    message: 'Reset',
  },
  ADVANCED_SEARCH: {
    key: 'advancedSearch',
    message: 'Advanced Search',
  },
  DOWNLOAD: {
    key: 'download',
    message: 'Download',
  },
  UPLOAD: {
    key: 'upload',
    message: 'Upload',
  },
  UPLOAD_FILE: {
    key: 'upload.file',
    message: 'Upload file',
  },
  CREATE: {
    key: 'create',
    message: 'Create',
  },
  CREATE_NOW: {
    key: 'createNow',
    message: 'Create Now',
  },
  REGISTER: {
    key: 'register',
    message: 'Register',
  },
  COMPLETE_REGISTRATION: {
    key: 'completeRegistration',
    message: 'Complete Registration',
  },
  NEW_REGISTRATION: {
    key: 'newRegistration',
    message: 'New Registration',
  },
  ADD_PRODUCT: {
    key: 'addProduct',
    message: 'Add Product',
  },
  SAVE: {
    key: 'save',
    message: 'Save',
  },
  ADD_AND_CLOSE: {
    key: 'addAndClose',
    message: 'Add and Close',
  },
  LOGIN: {
    key: 'login',
    message: 'Login',
  },
  ADD: {
    key: 'add',
    message: 'Add',
  },
  FURTHER: {
    key: 'further',
    message: 'Further',
  },
  CANCEL: {
    key: 'cancel',
    message: 'Cancel',
  },
  CONFIRM: {
    key: 'confirm',
    message: 'Confirm',
  },
  CONTINUE: {
    key: 'continue',
    message: 'Continue',
  },
  ACCEPT: {
    key: 'accept',
    message: 'Accept',
  },
  DECLINE: {
    key: 'decline',
    message: 'Decline',
  },
  USE_BARCODE_READER: {
    key: 'useBarcodeReader',
    message: 'Use the barcode reader',
  },
  ENTER_MANUALLY: {
    key: 'enterManually',
    message: 'Enter manually',
  },
  SWITCH_SEARCH_MODE: {
    key: 'switchSearchMode',
    message: 'Switch search mode',
  },
  SUBMIT: {
    key: 'submit',
    message: 'Submit',
  },
  SIGNIN: {
    key: 'signin',
    message: 'Sign In',
  },
  LOGOUT: {
    key: 'logout',
    message: 'Log Out',
  },
  PREVIOUS: {
    key: 'previous',
    message: 'Previous',
  },
  DELETE: {
    key: 'delete',
    message: 'Delete',
  },
  EDIT: {
    key: 'edit',
    message: 'Edit',
  },
  VERIFY_PROFILE: {
    key: 'verifyProfile',
    message: 'Verify Profile',
  },
  UPDATE_AVATAR: {
    key: 'updateAvatar',
    message: 'Update Avatar',
  },
  CHANGE_PROFILE_PICTURE: {
    key: 'changeProfilePicture',
    message: 'Change profile picture',
  },
  ATTACH_PHOTO: {
    key: 'attachAPhoto',
    message: 'Attach a photo',
  },
  RETRY: {
    key: 'retry',
    message: 'Retry',
  },
  SKIP: {
    key: 'skip',
    message: 'Skip',
  },
  APPLY_FILTERS: {
    key: 'applyFilters',
    message: 'Apply filters',
  },
  FILTER: {
    key: 'filter',
    message: 'Filter',
  },
  ACTIONS: {
    key: 'actions',
    message: 'Actions',
  },
  AWARE: {
    key: 'aware',
    message: 'Yes, i confirm',
  },
  SIGN_UP: {
    key: 'signUp',
    message: 'Sign Up',
  },
  DOWNLOAD_IN_CSV_FORMAT: {
    key: 'downloadInCsvFormat',
    message: 'Download in CSV format (limited to 5.000)',
  },
  THIS_OPERATION_CAN_TAKE_A_WHILE: {
    key: 'thisOperationCanTakeAWhile',
    message: 'This operation can take a while, please wait...',
  },
  SEE_TERMS_IN_BROWSER: {
    key: 'seeTermsInBrowser',
    message: 'See terms in browser',
  },
  INSERT_IMAGE: {
    key: 'insertImage',
    message: 'Insert image',
  },
  UPGRADE: {
    key: 'upgrade',
    message: 'Upgrade',
  },
  RECOVER: {
    key: 'recover',
    message: 'Recover',
  },
  SEND: {
    key: 'send',
    message: 'Send',
  },
  EDIT_PRODUCTS: {
    key: 'editProducts',
    message: 'Edit products',
  },
  EDIT_ORGUNITS: {
    key: 'editOrgunits',
    message: 'Edit orgunits',
  },
  VIEW_TERMS_AND_CONDITIONS: {
    key: 'viewTermsAndConditions',
    message: 'View terms and conditions',
  },
  CONFIRM_SELECTION: {
    key: 'confirmSelection',
    message: 'Confirm selection',
  },
};

export const {
  ACCEPT,
  ACTIONS,
  ADD_AND_CLOSE,
  ADD_PRODUCT,
  ADD,
  ADVANCED_SEARCH,
  APPLY_FILTERS,
  ATTACH_PHOTO,
  AWARE,
  BACK,
  CANCEL,
  CHANGE_PROFILE_PICTURE,
  COMPLETE_REGISTRATION,
  CONFIRM,
  CONFIRM_SELECTION,
  CONTINUE,
  CREATE,
  CREATE_NOW,
  DECLINE,
  DELETE,
  DOWNLOAD_IN_CSV_FORMAT,
  DOWNLOAD,
  EDIT,
  EDIT_ORGUNITS,
  EDIT_PRODUCTS,
  ENTER_MANUALLY,
  FILTER,
  GENERATE,
  INSERT_IMAGE,
  LOGIN,
  LOGOUT,
  NEW_REGISTRATION,
  NEXT_STEP,
  NEXT,
  PREVIOUS,
  REGENERATE,
  REGISTER,
  RESET,
  RETRY,
  SAVE,
  SEARCH,
  SEE_TERMS_IN_BROWSER,
  SIGN_UP,
  SIGNIN,
  SKIP,
  SUBMIT,
  SWITCH_SEARCH_MODE,
  THIS_OPERATION_CAN_TAKE_A_WHILE,
  UPDATE_AVATAR,
  UPGRADE,
  UPLOAD_FILE,
  UPLOAD,
  USE_BARCODE_READER,
  VERIFY_PROFILE,
  RECOVER,
  SEND,
  VIEW_TERMS_AND_CONDITIONS,
} = i18n;

export default i18n;
