export default {
  container: {
    style: ({ theme }) => `
      display: flex;
      background-color: ${theme.colors.pallet.alert};
      margin-top: 20px;
      padding: 10px;
      width: 100%;
      box-sizing: border-box;
    `,
  },
  text: {
    style: ({ theme }) => `
      color: ${theme.colors.pallet.light};
    `,
    container: {
      style: () => `
        margin: auto 10px;
      `,
    },
  },
  icon: {
    style: ({ theme }) => `
      fill: ${theme.colors.pallet.light};
      padding: 0;
    `,
    container: {
      style: ({ theme }) => `
        ${theme.media.IE11`height: 35px;`}
        margin: 5px;
      `,
    },
  },
};
