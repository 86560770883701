import { executeRecaptchaAction } from '@mmw/ui-libraries-recaptcha';
import { getFieldPaths } from '@mmw/utils-object-utils';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { F, U } from 'ts-toolbelt';

import { loginAppByPassStartAction } from '../actions';
import type { AuthenticationResponseWithUser } from '../types';

export class LoginForm {
  username: U.Nullable<string> = '';

  password: U.Nullable<string> = '';

  applicationId: U.Nullable<string> = '';

  applicationBaseUrl: U.Nullable<string> = '';

  applicationPath: U.Nullable<string> = '';

  applicationContextPath: U.Nullable<string> = '';

  recaptchaResponse: U.Nullable<string> = '';

  stayLoggedIn: U.Nullable<boolean> = null;
}

export const LOGIN_FORM_INITIAL_VALUES = new LoginForm();

export const LOGIN_FORM_FIELD_PATHS = getFieldPaths(LOGIN_FORM_INITIAL_VALUES);

type OnLoginSuccessCb = F.Function<[AuthenticationResponseWithUser]>;

type useLoginAppByPassCb = F.Function<[LoginForm]>;

const useLoginAppByPass = (
  onLoginSuccess?: OnLoginSuccessCb,
): useLoginAppByPassCb => {
  const dispatch = useDispatch();
  return useCallback(
    async (data: LoginForm) => {
      if (!data) return;
      const recaptchaResponse = (await executeRecaptchaAction(
        'x-goog-iap-jwt-assertion',
      )) as string;
      const {
        username,
        password,
        applicationId,
        applicationPath,
        applicationBaseUrl,
        applicationContextPath,
      } = data;
      dispatch(
        loginAppByPassStartAction(
          username,
          password,
          applicationId,
          recaptchaResponse,
          onLoginSuccess,
          applicationBaseUrl,
          applicationPath,
          applicationContextPath,
        ),
      );
    },
    [dispatch, onLoginSuccess],
  );
};

export default useLoginAppByPass;
