import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { setFieldValueAction } from '../../store/actions';
import { FieldPath } from '../../types';
import useFormId from '../useFormId';

function useOnChange<T>(fieldPath: FieldPath): (value: T) => void {
  const dispatch = useDispatch();
  const formId = useFormId();
  return useCallback(
    (value: T) => {
      dispatch(setFieldValueAction<T>(formId, fieldPath, value));
    },
    [formId, fieldPath],
  );
}

export default useOnChange;
