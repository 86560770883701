import { AuthenticationResponseWithUser } from '@mmw/redux-store-auth-api-login-by-password/types';
import { SecurityScopeNames } from '@mmw/services-auth-api-authentication/types';
import { ValidateConsumerMailStatus } from '@mmw/services-auth-api-consumer-account/types';
import {
  CreateConsumerLevel4RequestJSON,
  CreateConsumerResponseJSON,
} from '@mmw/services-core-consumer-membership/types';
import { OrgunitSignupResultJSON } from '@mmw/services-core-trader-contracting/types';
import { F } from 'ts-toolbelt';

import {
  CHECK_CONSUMER_EMAIL_AVAILABILITY_ERROR,
  CHECK_CONSUMER_EMAIL_AVAILABILITY_START,
  CHECK_CONSUMER_EMAIL_AVAILABILITY_SUCCESS,
  CheckConsumerMailAvailabilityErrorAction,
  CheckConsumerMailAvailabilityStartAction,
  CheckConsumerMailAvailabilitySuccessAction,
  CREATE_CONSUMER_ACCOUNT_ERROR,
  CREATE_CONSUMER_ACCOUNT_START,
  CREATE_CONSUMER_ACCOUNT_SUCCESS,
  CreateConsumerAccountErrorAction,
  CreateConsumerAccountStartAction,
  CreateConsumerAccountSuccessAction,
  DELETE_CONSUMER_ACCOUNT_ERROR,
  DELETE_CONSUMER_ACCOUNT_START,
  DELETE_CONSUMER_ACCOUNT_SUCCESS,
  DeleteConsumerAccountErrorAction,
  DeleteConsumerAccountStartAction,
  DeleteConsumerAccountSuccessAction,
  ORGUNIT_SIGNUP_ERROR,
  ORGUNIT_SIGNUP_START,
  ORGUNIT_SIGNUP_SUCCESS,
  OrgunitSignupErrorAction,
  OrgunitSignupFormData,
  OrgunitSignupStartAction,
  OrgunitSignupSuccessAction,
  RESET_ACTION,
  ResetAction,
} from './types';

export const orgunitSignupStartAction = (
  data: OrgunitSignupFormData,
  onLoginSuccess: F.Function<[AuthenticationResponseWithUser]>,
): OrgunitSignupStartAction => ({
  type: ORGUNIT_SIGNUP_START,
  payload: {
    data,
    onLoginSuccess,
  },
});

export const orgunitSignupSuccessAction = (
  data: OrgunitSignupResultJSON,
): OrgunitSignupSuccessAction => ({
  type: ORGUNIT_SIGNUP_SUCCESS,
  payload: data,
});

export const orgunitSignupErrorAction = (
  error: Error | null,
): OrgunitSignupErrorAction => ({
  type: ORGUNIT_SIGNUP_ERROR,
  payload: {
    error,
  },
});

export const checkConsumerMailAvailabilityStartAction = (
  email: string,
  scopeName: SecurityScopeNames,
): CheckConsumerMailAvailabilityStartAction => ({
  type: CHECK_CONSUMER_EMAIL_AVAILABILITY_START,
  payload: {
    email,
    scopeName,
  },
});

export const checkConsumerMailAvailabilitySuccessAction = (
  data: ValidateConsumerMailStatus,
): CheckConsumerMailAvailabilitySuccessAction => ({
  type: CHECK_CONSUMER_EMAIL_AVAILABILITY_SUCCESS,
  payload: data,
});

export const checkConsumerMailAvailabilityErrorAction = (
  error: Error | null,
): CheckConsumerMailAvailabilityErrorAction => ({
  type: CHECK_CONSUMER_EMAIL_AVAILABILITY_ERROR,
  payload: {
    error,
  },
});

export const createConsumerAccountStartAction = (
  data: Partial<CreateConsumerLevel4RequestJSON>,
): CreateConsumerAccountStartAction => ({
  type: CREATE_CONSUMER_ACCOUNT_START,
  payload: data,
});

export const createConsumerAccountSuccessAction = (
  data: CreateConsumerResponseJSON,
): CreateConsumerAccountSuccessAction => ({
  type: CREATE_CONSUMER_ACCOUNT_SUCCESS,
  payload: {
    data,
  },
});

export const createConsumerAccountErrorAction = (
  error: Error | null,
): CreateConsumerAccountErrorAction => ({
  type: CREATE_CONSUMER_ACCOUNT_ERROR,
  payload: {
    error,
  },
});

export const deleteConsumerAccountStartAction =
  (): DeleteConsumerAccountStartAction => ({
    type: DELETE_CONSUMER_ACCOUNT_START,
  });

export const deleteConsumerAccountSuccessAction = (
  success: boolean,
): DeleteConsumerAccountSuccessAction => ({
  type: DELETE_CONSUMER_ACCOUNT_SUCCESS,
  payload: {
    success,
  },
});

export const deleteConsumerAccountErrorAction = (
  error: Error,
): DeleteConsumerAccountErrorAction => ({
  type: DELETE_CONSUMER_ACCOUNT_ERROR,
  payload: {
    error,
  },
});

export const resetAction = (): ResetAction => ({
  type: RESET_ACTION,
});
