import { values } from 'lodash';

export enum RegistrationType {
  CONSUMER = 1,
  EXTERNAL = 2,
  POS_PREMIUM = 4,
  AUTO = 8,
  POS_BASIC = 16,
  DEMODEVICE = 32,
  AUDIT = 64,
}

export const VALUES = values(RegistrationType);

export const {
  CONSUMER,
  EXTERNAL,
  POS_PREMIUM,
  AUTO,
  POS_BASIC,
  DEMODEVICE,
  AUDIT,
} = RegistrationType;
