import {
  createSearchTypes,
  SearchModuleActionTypes,
} from './createSearchTypes';

export interface CarouselSearchModuleActionTypes {
  carousel: {
    INIT: string;
    NEXT: string;
    PREVIOUS: string;
  };
  search: SearchModuleActionTypes;
}
export const INIT_PATTERN = '_INIT';
export const NEXT_PATTERN = '_NEXT';
export const PREVIOUS_PATTERN = '_PREVIOUS';

export function createCarouselSearchTypes(
  namespace: string,
  actionName: string,
): CarouselSearchModuleActionTypes {
  return {
    search: createSearchTypes(namespace, actionName),
    carousel: {
      INIT: `${namespace}/${actionName}${INIT_PATTERN}`,
      NEXT: `${namespace}/${actionName}${NEXT_PATTERN}`,
      PREVIOUS: `${namespace}/${actionName}${PREVIOUS_PATTERN}`,
    },
  };
}
