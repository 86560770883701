import { I18nShape } from '@mmw/constants-i18n';
import withTranslate, { WithTranslateProps } from '@mmw/ui-hoc-with-translate';
import { compose, withPropsOnChange } from 'recompose';

export type WithPlaceholder = {
  placeholder?: string | I18nShape;
};

const withPlaceholder = withPropsOnChange(
  ['placeholder', '_bundleVersion'],
  ({ placeholder, translate }: WithTranslateProps & WithPlaceholder) => {
    if (!placeholder) {
      return null;
    }
    return {
      placeholder:
        typeof placeholder === 'string'
          ? placeholder
          : translate(placeholder.key as string, placeholder.message),
    };
  },
);

const withTranslatedPlaceholder = compose(withTranslate, withPlaceholder);

export default withTranslatedPlaceholder;
