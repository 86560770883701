import Features from '@mmw/constants-features';
import contextualConfig from '@mmw/contextual-config';
import { notifySuccess } from '@mmw/global-feedback-channel';
import ofType from '@mmw/redux-rx-of-type-operator';
import { getAuthenticationService } from '@mmw/services-holder';
import { ActionsObservable } from 'redux-observable';
import { EMPTY, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, timeout } from 'rxjs/operators';

import { requestTanErrorAction, requestTanSuccessAction } from '../actions';
import {
  REQUEST_TAN_START,
  RequestTanErrorAction,
  RequestTanStartAction,
  RequestTanSuccessAction,
} from '../types';

const { logger } = contextualConfig.application;
const { defaultTimeout } = contextualConfig.api;

const requestTanEpic = (
  action$: ActionsObservable<RequestTanStartAction>,
): Observable<RequestTanSuccessAction | RequestTanErrorAction> =>
  action$.pipe(
    ofType(REQUEST_TAN_START),
    tap(() => logger.debug('Trying to request tan')),
    switchMap(({ payload: { username, isReSend } }: RequestTanStartAction) =>
      from(
        getAuthenticationService().requestTan({
          username,
          isReSend,
        }),
      ).pipe(
        timeout(defaultTimeout),
        map(() => requestTanSuccessAction(isReSend)),
        tap(() => {
          if (isReSend) {
            return notifySuccess(Features.AUTH_API.REQUEST_TAN);
          }
          return EMPTY;
        }),
        catchError(error => of(requestTanErrorAction(error, isReSend))),
      ),
    ),
  );

export default requestTanEpic;
