import { EMPTY_OBJECT } from '@mmw/constants-utils';
import { U } from 'ts-toolbelt';

import { BaseAsyncState } from './BaseAsyncState';

export class SimpleAsyncState<T, L = void> extends BaseAsyncState {
  data: U.Nullable<T> = null;

  mappedLists?: U.Nullable<Record<symbol, L>> = EMPTY_OBJECT;

  requestAttempted = false;
}
