export default {
  style: ({ theme }) => `
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: ${theme.page.variable.HEADER_MIN_HEIGHT};
    background-color: ${theme.colors.pallet.light};
    position: fixed;
    top: 0;
    z-index: 990;
  `,
  content: {
    style: ({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: ${theme.page.variable.CONTENT_MAX_WIDTH};
    ${theme.media.tablet`
      :first-child {
        margin-left: 25px;
      }
    `}
    ${theme.media.phone`
      margin-bottom: auto;
      justify-content: center;
    `}
  `,
  },
  logo: {
    style: ({ theme }) =>
      theme.assets && theme.assets.logo
        ? `
      display: flex;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url(${theme.assets.logo});
      width: ${theme.page.variable.LOGO_WIDTH};
      height: ${theme.page.variable.LOGO_HEIGHT};
      cursor: pointer;
      ${theme.media.phone`
        height: 80px;
      `}
    `
        : '',
  },
};
