import { Handlers } from '@redux-basic-module/interfaces';
import { defaultTo } from 'lodash';

import { Action } from './types';

export function createReducer<S, A extends Action>(
  namespace: string,
  initialState: S,
  handlers: Handlers<S, any>,
): (state: S, action: A) => S {
  const handlersWithHydratedAction = handlers;
  handlersWithHydratedAction[`${namespace}_HYDRATE`] = (state, action): S => ({
    ...defaultTo(state, {}),
    ...defaultTo(action.payload, {}),
  });
  return function reducer(state = initialState, action: A): S {
    // @ts-ignore
    if (action.type in handlersWithHydratedAction) {
      // @ts-ignore
      return handlers[action.type](state, action as A);
    }
    return state;
  };
}
