import { Theme } from '@mmw/ui-theme/types';
import { StyledProps } from '@mmw/web-theme';

export default {
  title: {
    style: ({ theme }: StyledProps<Theme>): string => `
      margin: 10px 0;
      color: ${theme.colors.pallet.dark};
    `,
    modifiers: undefined,
  },
  numberLabel: {
    style: ({ theme }: StyledProps<Theme>): string => `
      margin-right: 10px;
      color: ${theme.colors.pallet.dark};
    `,
    modifiers: undefined,
  },
};
