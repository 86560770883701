import { EMPTY_ARRAY, EMPTY_OBJECT } from '@mmw/constants-utils';
import keys from 'lodash/keys';

import {
  getAuditToCampaignStatusMappingBySob,
  getSobAllowedStatusToCreateIssues,
  getSobAuditStatusI18n,
} from './auditStatusBySob';
import { AuditState, AuditStatus } from './types';

export const AUDIT_TO_CAMPAIGN_STATUS_MAPPING =
  getAuditToCampaignStatusMappingBySob();
export const AUDIT_STATUS_KEYS = keys(
  AUDIT_TO_CAMPAIGN_STATUS_MAPPING,
) as AuditStatus[];

export const EMPTY_AUDIT_STATE: AuditState = {
  registration: undefined,
  id: null,
  created: false,
  status: null,
  canOrderProducts: false,
  canCompleteAudit: false,
  canUploadSellout: false,
  canShowFilesCard: false,
  canStartAudit: false,
  isRejected: false,
  allowedStatusTransitions: EMPTY_ARRAY,
  allowedStatusToCreateIssues: getSobAllowedStatusToCreateIssues(),
  createIssuesOnTransition: EMPTY_OBJECT,
  canCreateIssues: false,
};

export const AUDIT_STATUS_I18N = getSobAuditStatusI18n();
