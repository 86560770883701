import qs from 'qs';

const BASE_PATH = '/v1/user-profile';

// eslint-disable-next-line import/prefer-default-export
export const GetSalesOrgBrandsPath = (): string =>
  `${BASE_PATH}/salesorgbrands`;

export const GetProfilePicturePath = (
  headers: Record<string, string>,
): string =>
  `${BASE_PATH}/picture?${qs.stringify({
    ...headers,
  })}`;

export const ExistsProfilePicturePath = (
  headers: Record<string, string>,
): string =>
  `${BASE_PATH}/picture/exists?${qs.stringify({
    ...headers,
  })}`;
