import mmwConfig from '@mmw/config';
import { MANUFACTURER_CLIENT as APPLICATION_ID } from '@mmw/constants-application-ids';
import {
  RETAIL_CLIENT as SECONDARY_RESOURCE_BUNDLE_NAME,
  RETAIL_CLIENT_V4 as DEFAULT_RESOURCE_BUNDLE_NAME,
} from '@mmw/constants-bundle-names';
import { GERMANY } from '@mmw/constants-country-codes';
import { DE, EN } from '@mmw/constants-languages';
import { ContextualConfig } from '@mmw/contextual-config';
import log from '@mmw/logging-logger';
import { deepOverride } from '@mmw/utils-object-utils';
import detectBrowserLanguage from '@mmw/web-utils-detect-browser-language';
import { O } from 'ts-toolbelt';

const APPLICATION_DISPLAY_NAME = 'Manufacturer Client';
const LOGGER = log.extend(APPLICATION_ID);

const DEFAULT_COUNTRY = GERMANY;
const SUPPORTED_LANGUAGES = [DE, EN];
const DEFAULT_LANGUAGE = detectBrowserLanguage(SUPPORTED_LANGUAGES, DE);

let manufacturerConfig: ContextualConfig = {
  ...mmwConfig,
  // Contextual Configs (by application)
  application: {
    displayName: APPLICATION_DISPLAY_NAME,
    applicationId: APPLICATION_ID,
    salesOrgBrandID: -1,
    defaultLanguage: DEFAULT_LANGUAGE,
    supportedLanguages: SUPPORTED_LANGUAGES,
    defaultCountry: DEFAULT_COUNTRY,
    defaultResourceBundleName: DEFAULT_RESOURCE_BUNDLE_NAME,
    secondaryResourceBundleName: SECONDARY_RESOURCE_BUNDLE_NAME,
    checkAuthenticationTimeout: 15 * 60 * 1000,
    logger: LOGGER,
  },
  webApplication: {
    contextPath:
      process.env.REACT_APP_CONTEXT_PATH || process.env.CONTEXT_PATH || '',
  },
  logs: {
    applicationId: APPLICATION_ID,
  },
  googleMaps: {},
  script: undefined,
  // XXX: section to OVERRIDE ...config
  core: {
    authApi: {
      accessTokenKey: 'twc:auth-api:mmw-cli-auth-api:access-token', // XXX: detect retail client token
      refreshTokenKey: 'twc:auth-api:mmw-cli-auth-api:refresh-token', // XXX: detect retail client token
    },
  },
  // store: {
  //   defaultNamespace: 'global',
  //   authenticationNamespace: '', // XXX: share with retail client local store namespace!
  //   fingerprintNamespace: 'fingerprint',
  // },
  // piwik: {
  //   siteId: 320,
  // },
};

export function setConfig(
  config: O.Partial<ContextualConfig, 'deep'> = {},
): void {
  manufacturerConfig = deepOverride(manufacturerConfig, config);
}

export function getConfig(): ContextualConfig {
  return manufacturerConfig;
}
