export default () => '';

// itemsLength <= 3
// ? `
// .BrainhubCarouselItem {
//   width: fit-content !important;
//   min-width: 0 !important;
//   margin-right: 40px;
// }
// `
// : '';
