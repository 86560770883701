import { I18nShape } from '@mmw/constants-i18n';
import { FieldPath } from '@mmw/redux-store-fast-form';
import {
  useOnBlur,
  useOnSelectChange,
  useValue,
} from '@mmw/redux-store-fast-form/hooks/fields';
import Select, {
  OptionsProps,
  OwnProps as SelectProps,
} from '@mmw/ui-web-elements-select';
import { useOnFocus } from '@mmw/ui-web-providers-input-handlers';
import React from 'react';

export type OwnProps = {
  fieldPath: FieldPath;
  placeholder?: string | I18nShape;
} & OptionsProps &
  SelectProps;

const FastFormSelect = ({
  fieldPath,
  options,
  optionsName,
  optionsValue,
  placeholder,
  ...rest
}: OwnProps) => {
  const value = useValue(fieldPath);
  const onBlur = useOnBlur(fieldPath);
  const onChange = useOnSelectChange(fieldPath);
  const onFocus = useOnFocus();
  return (
    <Select
      options={options}
      optionsName={optionsName}
      optionsValue={optionsValue}
      name={fieldPath}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export default FastFormSelect;
