import { StyledProps } from '@mmw/ui-theme/types';

export const linkTextsModifiers = {
  primary: ({ theme }: StyledProps<{}>) => `
    color: ${theme.colors.pallet.primary};
    &:hover {
      text-decoration: underline;
      color: ${theme.colors.pallet.primary};
    }
  `,
  secondary: ({ theme }: StyledProps<{}>) => `
    color: ${theme.colors.pallet.secondary};
    &:hover {
      text-decoration: underline;
      color: ${theme.colors.pallet.secondary};
    }
  `,
  basic: ({ theme }: StyledProps<{}>) => `
    color: ${theme.colors.pallet.basic};
    &:hover {
      text-decoration: underline;
      color: ${theme.colors.pallet.basic};
    }
  `,
  basicLight: ({ theme }: StyledProps<{}>) => `
    color: ${theme.colors.pallet.basicLight};
    &:hover {
      text-decoration: underline;
      color: ${theme.colors.pallet.basicLight};
    }
  `,
  basicDark: ({ theme }: StyledProps<{}>) => `
    color: ${theme.colors.pallet.basicDark};
    &:hover {
      text-decoration: underline;
      color: ${theme.colors.pallet.basicDark};
    }
  `,
  dark: ({ theme }: StyledProps<{}>) => `
    color: ${theme.colors.pallet.dark};
    &:hover {
      text-decoration: underline;
      color: ${theme.colors.pallet.dark};
    }
  `,
  light: ({ theme }: StyledProps<{}>) => `
    color: ${theme.colors.pallet.light};
    &:hover {
      text-decoration: underline;
      color: ${theme.colors.pallet.light};
    }
  `,
};
