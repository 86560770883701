import { getLogger } from '@mmw/contextual-config';
import {
  OperationsEnum,
  UserLoginConfiguration,
} from '@mmw/services-auth-api-authentication/types';
import { executeRecaptchaAction } from '@mmw/ui-libraries-recaptcha';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { F } from 'ts-toolbelt';

import { requestEmailVerificationStartAction } from '../actions';

type CallbackFn = (
  operation?: OperationsEnum,
  userConfig?: UserLoginConfiguration,
  recaptchaToken?: string,
) => void;

const useRequestEmailVerification = (
  operationId?: OperationsEnum,
  config?: UserLoginConfiguration,
  callback?: F.Function<[any?]>,
): CallbackFn => {
  const dispatch = useDispatch();
  return useCallback(
    async (
      operation?: OperationsEnum,
      userConfig?: UserLoginConfiguration,
      recaptchaToken?: string,
    ) => {
      if ((config && config.email) || (userConfig && userConfig)) {
        const { email } = config || userConfig;
        const captchaToken =
          recaptchaToken ||
          (await executeRecaptchaAction('email-verification-request'));
        dispatch(
          requestEmailVerificationStartAction(
            email,
            operation || operationId,
            captchaToken,
          ),
        );
      } else {
        getLogger().error(
          'No email found, cannot continue with request email verification',
        );
      }
      if (callback) callback();
    },
    [callback, config, dispatch, operationId],
  );
};

export default useRequestEmailVerification;
