import { createDI } from '@di/core';
import { ClientFingerprint } from '@mmw/common-client-fingerprint';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import ConsumerAccountService from '@mmw/services-auth-api-consumer-account';
import AdminDemoDeviceService from '@mmw/services-core-admin-demo-device';
import CommonService from '@mmw/services-core-common';
import ConsumerService from '@mmw/services-core-consumer';
import ConsumerBankAccountService from '@mmw/services-core-consumer-bank-account';
import ConsumerMembershipService from '@mmw/services-core-consumer-membership';
import ConsumerProductService from '@mmw/services-core-consumer-product';
import ConsumerProfileService from '@mmw/services-core-consumer-profile';
import DocumentGeneratorService from '@mmw/services-core-document-generator';
import FaqService from '@mmw/services-core-faq';
import LoggedUserDataService from '@mmw/services-core-logged-user-data-service';
import ManuAgreementService from '@mmw/services-core-manu-agreement';
import ManuFlowOfGoodsService from '@mmw/services-core-manu-flow-of-goods';
import ManuMarketingBudgetServiceService from '@mmw/services-core-manu-marketing-budget';
import ManuOnlineReportingService from '@mmw/services-core-manu-online-reporting';
import ManuOrgUnitsService from '@mmw/services-core-manu-orgunit';
import ManuProductsService from '@mmw/services-core-manu-products';
import ManuProjectsService from '@mmw/services-core-manu-projects';
import ManuUserService from '@mmw/services-core-manu-user';
import ManuWalletService from '@mmw/services-core-manu-wallet';
import ManufacturerBusinessRelationService from '@mmw/services-core-manufacturer-business-relation';
import ManufacturerCampaignsService from '@mmw/services-core-manufacturer-campaigns';
import ManufacturerRegistrationService from '@mmw/services-core-manufacturer-registration';
import NewsService from '@mmw/services-core-news';
import OrgunitService from '@mmw/services-core-orgunit';
import PasswordService from '@mmw/services-core-password';
import TraderProductsService from '@mmw/services-core-product';
import ProjectsService from '@mmw/services-core-projects';
import ResourceService from '@mmw/services-core-resource';
import SmsService from '@mmw/services-core-sms-phone-verification';
import SolarDeliveriesService from '@mmw/services-core-solar-delivery';
import SolarTraderFlasherDataService from '@mmw/services-core-solar-trader-flasher-data';
import SolarTraderSignupService from '@mmw/services-core-solar-trader-signup';
import StoreLocatorService from '@mmw/services-core-store-locator';
import TraderAgreementService from '@mmw/services-core-trader-agreement';
import TraderBankAccountService from '@mmw/services-core-trader-bank-account';
import TraderBusinessRelationService from '@mmw/services-core-trader-business-relation';
import TraderCampaignsService from '@mmw/services-core-trader-campaigns';
import TraderClassOfGoodsService from '@mmw/services-core-trader-classofgoods';
import TraderContractingService from '@mmw/services-core-trader-contracting';
import TraderContractsService from '@mmw/services-core-trader-contracts';
import TraderDemoDeviceService from '@mmw/services-core-trader-demo-device';
import TraderEmployeesService from '@mmw/services-core-trader-employees';
import TraderOrgunitService from '@mmw/services-core-trader-orgunit';
import TraderProductGroupService from '@mmw/services-core-trader-product-group';
import TraderProfileService from '@mmw/services-core-trader-profile';
import TraderRegistrationService from '@mmw/services-core-trader-registration';
import TraderRuralProjectsService from '@mmw/services-core-trader-rural-projects';
import TraderSSOService from '@mmw/services-core-trader-sso';
import TraderUsersService from '@mmw/services-core-trader-users';
import TraderWalletService from '@mmw/services-core-trader-wallet';
import TraderWalletByEmployeeService from '@mmw/services-core-trader-wallet-by-employee';
import UserProfileService from '@mmw/services-core-user-profile';
import MarketingToolsSSOService from '@rec-group/marketing-tools-sso';
import { U } from 'ts-toolbelt';

import { NotConfiguredServiceError } from './errors';

// CLIENT FINGER PRINT SERVICE
let clientFingerprintService: ClientFingerprint | null = null;

export function getClientFingerprintService(): ClientFingerprint {
  if (clientFingerprintService == null) {
    throw new NotConfiguredServiceError(
      'ClientFingerprint needs to be configured',
    );
  }
  return clientFingerprintService;
}

export function setClientFingerprintService(service: ClientFingerprint): void {
  clientFingerprintService = service;
}

// AUTHENTICATION SERVICE
let authenticationService: AuthenticationService | null = null;

export function getAuthenticationService(): AuthenticationService {
  if (authenticationService == null) {
    throw new NotConfiguredServiceError(
      'AuthenticationService needs to be configured',
    );
  }
  return authenticationService;
}

export function setAuthenticationService(service: AuthenticationService): void {
  authenticationService = service;
}

// RESOURCE SERVICE
let resourceService: ResourceService | null = null;

export function getResourceService(): ResourceService {
  if (resourceService == null) {
    throw new NotConfiguredServiceError(
      'ResourceService needs to be configured',
    );
  }
  return resourceService;
}

export function setResourceService(service: ResourceService): void {
  resourceService = service;
}

// COMMON SERVICE
let commonService: CommonService | null = null;

export function getCommonService(): CommonService {
  if (commonService == null) {
    throw new NotConfiguredServiceError('CommonService needs to be configured');
  }
  return commonService;
}

export function setCommonService(service: CommonService): void {
  commonService = service;
}

// LOGGED USER DATA SERVICE
let loggedUserDataService: LoggedUserDataService | null = null;

export function getLoggedUserDataService(): LoggedUserDataService {
  if (loggedUserDataService == null) {
    throw new NotConfiguredServiceError(
      'LoggedUserDataService needs to be configured',
    );
  }
  return loggedUserDataService;
}

export function setLoggedUserDataService(service: LoggedUserDataService): void {
  loggedUserDataService = service;
}

// TRADER CONSUMER SERVICE
// let traderConsumerService: TraderConsumerService | null = null;

// export function getConsumerService(): TraderConsumerService {
//   if (traderConsumerService == null) {
//     throw new NotConfiguredServiceError(
//       'TraderConsumerService needs to be configured',
//     );
//   }
//   return traderConsumerService;
// }

// export function setConsumerService(service: TraderConsumerService): void {
//   traderConsumerService = service;
// }

// TRADER PRODUCTS SERVICE
let traderProductsService: TraderProductsService | null = null;

export function getTraderProductsService(): TraderProductsService {
  if (traderProductsService == null) {
    throw new NotConfiguredServiceError(
      'TraderProductsService needs to be configured',
    );
  }
  return traderProductsService;
}

export function setTraderProductsService(service: TraderProductsService): void {
  traderProductsService = service;
}

// TRADER RURAL PROJECTS SERVICE
let traderRuralProjectsService: TraderRuralProjectsService | null = null;

export function getTraderRuralProjectsService(): TraderRuralProjectsService {
  if (traderRuralProjectsService == null) {
    throw new NotConfiguredServiceError(
      'TraderRuralProjectsService needs to be configured',
    );
  }
  return traderRuralProjectsService;
}

export function setTraderRuralProjectsService(
  service: TraderRuralProjectsService,
): void {
  traderRuralProjectsService = service;
}

// // TRADER PROJECTS SERVICE
// let projectsService: ProjectsService | null = null;
//
// export function getProjectsService(): ProjectsService {
//   if (projectsService == null) {
//     throw new NotConfiguredServiceError(
//       'ProjectsService needs to be configured',
//     );
//   }
//   return projectsService;
// }
//
// export function setProjectsService(service: ProjectsService): void {
//   projectsService = service;
// }

// SolarDeliveriesService
export const [getProjectsService, setProjectsService] =
  ProjectsService.createDI<ProjectsService>('ProjectsService');

// SolarDeliveriesService
export const [getSolarDeliveriesService, setSolarDeliveriesService] =
  SolarDeliveriesService.createDI<SolarDeliveriesService>(
    'SolarDeliveriesService',
  );

// Trader DemoDevice Service
export const [getTraderDemoDevicesService, setTraderDemoDevicesService] =
  TraderDemoDeviceService.createDI('TraderDemoDeviceService');

// Admin DemoDevice service
export const [getAdminDemoDevicesService, setAdminDemoDevicesService] =
  AdminDemoDeviceService.createDI('AdminDemoDeviceService');

// TRADER USERS SERVICE
let traderUsersService: TraderUsersService | null = null;

export function getTraderUsersService(): TraderUsersService {
  if (traderUsersService == null) {
    throw new NotConfiguredServiceError(
      'TraderUsersService needs to be configured',
    );
  }
  return traderUsersService;
}

export function setTraderUsersService(service: TraderUsersService): void {
  traderUsersService = service;
}

// TRADER EMPLOYEES SERVICE
let traderEmployeesService: TraderEmployeesService | null = null;

export function getTraderEmployeesService(): TraderEmployeesService {
  if (traderEmployeesService == null) {
    throw new NotConfiguredServiceError(
      'TraderEmployeesService needs to be configured',
    );
  }
  return traderEmployeesService;
}

export function setTraderEmployeesService(
  service: TraderEmployeesService,
): void {
  traderEmployeesService = service;
}

// TRADER DATA SERVICE
let traderOrgunitService: TraderOrgunitService | null = null;

export function getTraderOrgunitService(): TraderOrgunitService {
  if (traderOrgunitService == null) {
    throw new NotConfiguredServiceError(
      'TraderOrgunitService needs to be configured',
    );
  }
  return traderOrgunitService;
}

export function setTraderOrgunitService(service: TraderOrgunitService): void {
  traderOrgunitService = service;
}

// TRADER REGISTRATION SERVICE
let traderRegistrationService: TraderRegistrationService | null = null;

export function getTraderRegistrationService(): TraderRegistrationService {
  if (traderRegistrationService == null) {
    throw new NotConfiguredServiceError(
      'TraderRegistrationService needs to be configured',
    );
  }
  return traderRegistrationService;
}

export function setTraderRegistrationService(
  service: TraderRegistrationService,
): void {
  traderRegistrationService = service;
}

// TRADER CAMPAIGNS SERVICE
let traderCampaignsService: TraderCampaignsService | null = null;

export function getTraderCampaignsService(): TraderCampaignsService {
  if (traderCampaignsService == null) {
    throw new NotConfiguredServiceError(
      'TraderCampaignsService needs to be configured',
    );
  }
  return traderCampaignsService;
}

export function setTraderCampaignsService(
  service: TraderCampaignsService,
): void {
  traderCampaignsService = service;
}

export const [GetManuOnlineReportingService, setManuOnlineReportingService] =
  ManuOnlineReportingService.createDI('ManuOnlineReportingService');

// TRADER CLASS OF GOODS SERVICE
let traderClassOfGoodsService: TraderClassOfGoodsService | null = null;

export function getTraderClassOfGoodsService(): TraderClassOfGoodsService {
  if (traderClassOfGoodsService == null) {
    throw new NotConfiguredServiceError(
      'TraderClassOfGoodsService needs to be configured',
    );
  }
  return traderClassOfGoodsService;
}

export function setTraderClassOfGoodsService(
  service: TraderClassOfGoodsService,
): void {
  traderClassOfGoodsService = service;
}

// TRADER CONTRACTS SERVICE
let traderContractsService: TraderContractsService | null = null;

export function getTraderContractsService(): TraderContractsService {
  if (traderContractsService == null) {
    throw new NotConfiguredServiceError(
      'TraderContractsService needs to be configured',
    );
  }
  return traderContractsService;
}

export function setTraderContractsService(
  service: TraderContractsService,
): void {
  traderContractsService = service;
}

// TRADER CONTRACTING SERVICE
let traderContractingService: TraderContractingService | null = null;

export function getTraderContractingService(): TraderContractingService {
  if (traderContractingService == null) {
    throw new NotConfiguredServiceError(
      'TraderContractingService needs to be configured',
    );
  }
  return traderContractingService;
}

export function setTraderContractingService(
  service: TraderContractingService,
): void {
  traderContractingService = service;
}

// TRADER CAMPAIGNS SERVICE
let traderAgreementService: TraderAgreementService | null = null;

export function getTraderAgreementService(): TraderAgreementService {
  if (traderAgreementService == null) {
    throw new NotConfiguredServiceError(
      'TraderAgreementService needs to be configured',
    );
  }
  return traderAgreementService;
}

export function setTraderAgreementService(
  service: TraderAgreementService,
): void {
  traderAgreementService = service;
}

// TRADER SOLAR SIGNUP SERVICE
let solarTraderSignupService: SolarTraderSignupService | null = null;

export function getSolarTraderSignupService(): SolarTraderSignupService {
  if (solarTraderSignupService == null) {
    throw new NotConfiguredServiceError(
      'SolarTraderSignupService needs to be configured',
    );
  }
  return solarTraderSignupService;
}

export function setSolarTraderSignupService(
  service: SolarTraderSignupService,
): void {
  solarTraderSignupService = service;
}

let solarTraderFlasherDataService: SolarTraderFlasherDataService | null = null;

export function getSolarTraderFlasherDataService(): SolarTraderFlasherDataService {
  if (solarTraderFlasherDataService == null) {
    throw new NotConfiguredServiceError(
      'SolarTraderFlasherDataService needs to be configured',
    );
  }
  return solarTraderFlasherDataService;
}

export function setSolarTraderFlasherDataService(
  service: SolarTraderFlasherDataService,
): void {
  solarTraderFlasherDataService = service;
}

// NEWS SERVICE
let newsService: NewsService | null = null;

export function getNewsService(): NewsService {
  if (newsService == null) {
    throw new NotConfiguredServiceError('NewsService needs to be configured');
  }
  return newsService;
}

export function setNewsService(service: NewsService): void {
  newsService = service;
}

// FAQ SERVICE
let faqService: FaqService | null = null;

export function getFaqService(): FaqService {
  if (faqService == null) {
    throw new NotConfiguredServiceError('FaqService needs to be configured');
  }
  return faqService;
}

export function setFaqService(service: FaqService): void {
  faqService = service;
}

// STORE LOCATOR SERVICE
let storeLocatorService: StoreLocatorService | null = null;

export function getStoreLocatorService(): StoreLocatorService {
  if (storeLocatorService == null) {
    throw new NotConfiguredServiceError(
      'StoreLocatorService needs to be configured',
    );
  }
  return storeLocatorService;
}

export function setStoreLocatorService(service: StoreLocatorService): void {
  storeLocatorService = service;
}

// USER PROFILE SERVICE
let userProfileService: UserProfileService | null = null;

export function getUserProfileService(): UserProfileService {
  if (userProfileService == null) {
    throw new NotConfiguredServiceError(
      'UserProfileService needs to be configured',
    );
  }
  return userProfileService;
}

export function setUserProfileService(service: UserProfileService): void {
  userProfileService = service;
}
// DOCUMENT GENERATOR SERVICE
let documentGeneratorService: DocumentGeneratorService | null = null;

export function getDocumentGeneratorService(): DocumentGeneratorService {
  if (documentGeneratorService == null) {
    throw new NotConfiguredServiceError(
      'DocumentGeneratorService needs to be configured',
    );
  }
  return documentGeneratorService;
}

export function setDocumentGeneratorService(
  service: DocumentGeneratorService,
): void {
  documentGeneratorService = service;
}

// TRADER BANK ACCOUNT
let traderBankAccountService: TraderBankAccountService | null = null;

export function getTraderBankAccountService(): TraderBankAccountService {
  if (traderBankAccountService == null) {
    throw new NotConfiguredServiceError(
      'TraderBankAccountService needs to be configured',
    );
  }
  return traderBankAccountService;
}

export function setTraderBankAccountService(
  service: TraderBankAccountService,
): void {
  traderBankAccountService = service;
}

// MANUFACTURER REGISTRATION SERVICE
let manufacturerRegistrationService: ManufacturerRegistrationService | null =
  null;

export function getManufacturerRegistrationService(): ManufacturerRegistrationService {
  if (manufacturerRegistrationService == null) {
    throw new NotConfiguredServiceError(
      'ManufacturerRegistrationService needs to be configured',
    );
  }
  return manufacturerRegistrationService;
}

export function setManufacturerRegistrationService(
  service: ManufacturerRegistrationService,
): void {
  manufacturerRegistrationService = service;
}

// MANUFACTURER PROJECTS SERVICE
let manufacturerProjectsService: ManuProjectsService | null = null;

export function getManufacturerProjectsService(): ManuProjectsService {
  if (manufacturerProjectsService == null) {
    throw new NotConfiguredServiceError(
      'ManufacturerRegistrationService needs to be configured',
    );
  }
  return manufacturerProjectsService;
}

export function setManufacturerProjectsService(
  service: ManuProjectsService,
): void {
  manufacturerProjectsService = service;
}

// MANUFACTURER ORGUNIT SERVICE
let manufacturerOrgunitService: ManuOrgUnitsService | null = null;

export function getManufacturerOrgunitService(): ManuOrgUnitsService {
  if (manufacturerOrgunitService == null) {
    throw new NotConfiguredServiceError(
      'ManufacturerOrgunitService needs to be configured',
    );
  }
  return manufacturerOrgunitService;
}

export function setManufacturerOrgunitService(
  service: ManuOrgUnitsService,
): void {
  manufacturerOrgunitService = service;
}
// MANUFACTURER TRADER WALLET SERVICE
let manufacturerTraderWalletService: ManuWalletService | null = null;

export function getManufacturerTraderWalletService(): ManuWalletService {
  if (manufacturerTraderWalletService == null) {
    throw new NotConfiguredServiceError(
      'ManufacturerTraderWalletService needs to be configured',
    );
  }
  return manufacturerTraderWalletService;
}

export function setManufacturerTraderWalletService(
  service: ManuWalletService,
): void {
  manufacturerTraderWalletService = service;
}
// MANUFACTURER AGREEMENT SERVICE
export const [getManuAgreementService, setManuAgreementService] =
  ManuAgreementService.createDI('ManuAgreementService');
// MANUFACTURER USER SERVICE
let manufacturerUserService: ManuUserService | null = null;

export function getManufacturerUserService(): ManuUserService {
  if (manufacturerUserService == null) {
    throw new NotConfiguredServiceError(
      'ManufacturerUserService needs to be configured',
    );
  }
  return manufacturerUserService;
}

export function setManufacturerUserService(service: ManuUserService): void {
  manufacturerUserService = service;
}
// MANUFACTURER CAMPAIGNS SERVICE
let manufacturerCampaignsService: ManufacturerCampaignsService | null = null;

export function getManufacturerCampaignsService(): ManufacturerCampaignsService {
  if (manufacturerCampaignsService == null) {
    throw new NotConfiguredServiceError(
      'ManufacturerCampaignsService needs to be configured',
    );
  }
  return manufacturerCampaignsService;
}

export function setManufacturerCampaignsService(
  service: ManufacturerCampaignsService,
): void {
  manufacturerCampaignsService = service;
}

// MANUFACTURER CAMPAIGNS SERVICE
let manufacturerMarketingBudgetService: ManuMarketingBudgetServiceService | null =
  null;

export function getManufacturerMarketingBudgetService(): ManuMarketingBudgetServiceService {
  if (manufacturerMarketingBudgetService == null) {
    throw new NotConfiguredServiceError(
      'ManuMarketingBudgetServiceService needs to be configured',
    );
  }
  return manufacturerMarketingBudgetService;
}

export function setManufacturerMarketingBudgetService(
  service: ManuMarketingBudgetServiceService,
): void {
  manufacturerMarketingBudgetService = service;
}

// CONSUMER TRADER SERVICE
let consumerService: ConsumerService | null = null;

export function getConsumerService(): ConsumerService {
  if (consumerService == null) {
    throw new NotConfiguredServiceError(
      'ConsumerService needs to be configured',
    );
  }
  return consumerService;
}

export function setConsumerService(service: ConsumerService): void {
  consumerService = service;
}

// TRADER WALLET SERVICE
let traderWalletService: U.Nullable<TraderWalletService> = null;

export function getTraderWalletService(): TraderWalletService {
  if (traderWalletService == null) {
    throw new NotConfiguredServiceError(
      'TraderWalletService needs to be configured',
    );
  }
  return traderWalletService;
}

export function setTraderWalletService(service: TraderWalletService): void {
  traderWalletService = service;
}

// CONSUMER PROFILE SERVICE

let consumerProfileService: U.Nullable<ConsumerProfileService> = null;

export function getConsumerProfileService(): ConsumerProfileService {
  if (consumerProfileService == null) {
    throw new NotConfiguredServiceError(
      'ConsumerProfileService needs to be configured',
    );
  }
  return consumerProfileService;
}

export function setConsumerProfileService(
  service: ConsumerProfileService,
): void {
  consumerProfileService = service;
}

// CONSUMER BANK ACCOUNT SERVICE

let consumerBankAccountService: U.Nullable<ConsumerBankAccountService> = null;

export function getConsumerBankAccountService(): ConsumerBankAccountService {
  if (consumerBankAccountService == null) {
    throw new NotConfiguredServiceError(
      'ConsumerBankAccountService needs to be configured',
    );
  }
  return consumerBankAccountService;
}

export function setConsumerBankAccountService(
  service: ConsumerBankAccountService,
): void {
  consumerBankAccountService = service;
}

// CONSUMER MEMBERSHIP SERVICE

let consumerMembershipService: U.Nullable<ConsumerMembershipService> = null;

export function getConsumerMembershipService(): ConsumerMembershipService {
  if (consumerMembershipService == null) {
    throw new NotConfiguredServiceError(
      'ConsumerMembershipService needs to be configured',
    );
  }
  return consumerMembershipService;
}

export function setConsumerMembershipService(
  service: ConsumerMembershipService,
): void {
  consumerMembershipService = service;
}

// CONSUMER ACCOUNT

let consumerAccountService: U.Nullable<ConsumerAccountService> = null;

export function getConsumerAccountService(): ConsumerAccountService {
  if (consumerAccountService == null) {
    throw new NotConfiguredServiceError(
      'ConsumerAccountService needs to be configured',
    );
  }
  return consumerAccountService;
}

export function setConsumerAccountService(
  service: ConsumerAccountService,
): void {
  consumerAccountService = service;
}

// TRADER BUSINESS RELATION
let traderBusinessRelationService: U.Nullable<TraderBusinessRelationService> =
  null;

export function getTraderBusinessRelationService(): TraderBusinessRelationService {
  if (traderBusinessRelationService == null) {
    throw new NotConfiguredServiceError(
      'TraderBusinessRelationService needs to be configured',
    );
  }
  return traderBusinessRelationService;
}

export function setTraderBusinessRelationService(
  service: TraderBusinessRelationService,
): void {
  traderBusinessRelationService = service;
}

// TRADER WALLET BY EMPLOYEE
let traderWalletByEmployeeService: U.Nullable<TraderWalletByEmployeeService> =
  null;

export function getTraderWalletByEmployeeService(): TraderWalletByEmployeeService {
  if (traderWalletByEmployeeService == null) {
    throw new NotConfiguredServiceError(
      'TraderWalletByEmployeeService needs to be configured',
    );
  }
  return traderWalletByEmployeeService;
}

export function setTraderWalletByEmployeeService(
  service: TraderWalletByEmployeeService,
): void {
  traderWalletByEmployeeService = service;
}
// MANUFACTURER BUSINESS RELATION
let manufacturerBusinessRelationService: U.Nullable<ManufacturerBusinessRelationService> =
  null;

export function getManufacturerBusinessRelationService(): ManufacturerBusinessRelationService {
  if (manufacturerBusinessRelationService == null) {
    throw new NotConfiguredServiceError(
      'ManufacturerBusinessRelationService needs to be configured',
    );
  }
  return manufacturerBusinessRelationService;
}

export function setManufacturerBusinessRelationService(
  service: ManufacturerBusinessRelationService,
): void {
  manufacturerBusinessRelationService = service;
}

// ORGUNIT SERVICE

let orgunitService: OrgunitService | null = null;

export function setOrgunitService(service: OrgunitService) {
  orgunitService = service;
}

export function getOrgunitService(): OrgunitService {
  if (!orgunitService) throw new Error('OrgunitService needs to be configured');
  if (!orgunitService.authenticationService)
    orgunitService.authenticationService = getAuthenticationService();
  return orgunitService;
}

// PROFILE SERVICE
export const [getTraderProfileService, setTraderProfileService] =
  TraderProfileService.createDI('TraderProfileService');

export const [getMarketingToolsSSOService, setMarketingToolsSSOService] =
  MarketingToolsSSOService.createDI('MarketingToolsSSOService');

// SMS SERVICE
let smsService: SmsService | null = null;

export function setSmsService(service: SmsService) {
  smsService = service;
}

export function getSmsService(): SmsService {
  if (!smsService) throw new Error('SmsService needs to be configured');
  if (!smsService.authenticationService)
    smsService.authenticationService = getAuthenticationService();
  return smsService;
}

// CONSUMER PRODUCT SERVICE
let consumerProductService: ConsumerProductService | null = null;

export function setConsumerProductService(service: ConsumerProductService) {
  consumerProductService = service;
}

export function getConsumerProductService(): ConsumerProductService {
  if (!consumerProductService)
    throw new Error('ConsumerProductService needs to be configured');
  return consumerProductService;
}

// REGISTRATION SERVICE CONTAINER
export const [getRegistrationsService, setRegistrationsService] = createDI<
  ManufacturerRegistrationService | TraderRegistrationService
>('RegistrationsService');

// TRADER PRODUCT GROUP
export const [getTraderProductGroupService, setTraderProductService] =
  createDI<TraderProductGroupService>('TraderProductGroupService');

// TRADER SSO
export const [getTraderSSOService, setTraderSSOService] =
  createDI<TraderSSOService>('TraderSSOService');
export const [getManuFlowOfGoodsService, setManuFlowOfGoodsService] =
  ManuFlowOfGoodsService.createDI('ManuFlowOfGoodsService');

// MANU PRODUCTS
export const [getManuProductsService, setManuProductsService] =
  ManuProductsService.createDI('ManuProductsService');

// PASSWORD
export const [getPasswordService, setPasswordService] =
  PasswordService.createDI('PasswordService');
