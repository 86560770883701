import { compose, withHandlers, withState } from 'recompose';

const withIsVisible = compose(
  withState('isVisible', 'toggle', ({ isVisible }) => isVisible || false),
  withHandlers({
    show:
      ({ toggle }) =>
      () =>
        toggle(true),
    hide:
      ({ toggle }) =>
      () =>
        toggle(false),
    toggle:
      ({ toggle }) =>
      () =>
        toggle(current => !current),
  }),
);

export type WithIsVisibleProps = {
  isVisible: boolean;
  toggle: Function;
  show: Function;
  hide: Function;
};

export default withIsVisible;
