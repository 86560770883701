import styled from 'styled-components';

const NavbarContent = styled.ul`
  display: flex;
  margin: 0;
  align-items: center;

  ${({ theme }) => theme.media.phone`
    display:none;
  `}
`;

export default NavbarContent;
