const content = {
  style: ({ theme }) => `
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: ${theme.campaignCard.variables.contentPadding};    
  `,
  title: {
    style: () => `
    `,
  },
};

export default content;
