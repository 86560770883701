import { StyledProps } from '@mmw/ui-theme/types';
import defaultTheme from '@mmw/ui-web-elements-icon/theme';

export default {
  ...defaultTheme,
  style: ({ theme }: StyledProps<{}>) => `
    ${defaultTheme.style({ theme })}
    fill: ${theme.colors.pallet.secondary};
    color: ${theme.colors.pallet.secondary};
  `,
  modifiers: {
    ...defaultTheme.modifiers,
    alert: ({ theme }) => `
        fill: ${theme.colors.pallet.alert};
    `,
  },
};
