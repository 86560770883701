import backgroundColorModifiers from '@mmw/ui-theme/modifiers/color/background';

const PADDING = '20px';
const MOBILE_PADDING = '5px 25px';

const BACKGROUND_COLOR_MODIFIERS = backgroundColorModifiers();

const constants = {
  PADDING,
  BACKGROUND_COLOR_MODIFIERS,
  MOBILE_PADDING,
};

export {
  BACKGROUND_COLOR_MODIFIERS,
  constants as default,
  MOBILE_PADDING,
  PADDING,
};
