import { LanguageCode } from '@mmw/constants-languages';
import { getAuthenticationService } from '@mmw/services-holder';
import {
  createContext as ReactCreateContext,
  PropsWithChildren,
  useState,
} from 'react';
import { createStore, StoreApi } from 'zustand';

import { State } from './types';

const authenticationService = getAuthenticationService();

export const RecoverUserIdStoreContext =
  ReactCreateContext<StoreApi<State> | null>(null);

export const RecoverUserIdStoreProvider = ({ children }: PropsWithChildren) => {
  const [store] = useState(() =>
    createStore<State>(set => ({
      isLoading: false,
      success: false,
      error: null,
      actions: {
        recoverUserId: async (email: string, language: LanguageCode) => {
          set({ isLoading: true });
          try {
            const { success } = await authenticationService.recoverUsername(
              email,
              language,
            );
            set({ success, isLoading: false });
          } catch (error) {
            set({ error, isLoading: false });
          }
        },
        reset: () => {
          set({ isLoading: false, success: false, error: null });
        },
      },
    })),
  );
  return (
    <RecoverUserIdStoreContext.Provider value={store}>
      {children}
    </RecoverUserIdStoreContext.Provider>
  );
};
