import { StyledProps } from '@mmw/ui-theme/types';

export default {
  highlight: {
    style: ({
      theme,
      highlight,
    }: StyledProps<{
      highlight: boolean;
    }>) => `
    ${highlight ? `color: ${theme.colors.pallet.primary};` : ''},
    `,
  },
  suggestions: {
    style: ({ theme }: StyledProps<{}>) => `
      cursor: pointer;
      padding: 10px;
      background-color: ${theme.colors.pallet.basicLight};
      color: ${theme.colors.pallet.dark} !important;
    `,
    list: {
      style: ({ theme }: StyledProps<{}>) => `
        margin: 0;
        list-style: none;
        padding: 0;
        font-family: ${theme.fonts.fontFamily};
        background-color: ${theme.colors.pallet.basicLight};
        border: 1px solid ${theme.colors.pallet.basic};
        outline: 1px solid ${theme.colors.pallet.basicLight};
        color: ${theme.colors.pallet.dark};
        max-height: 150px;
        overflow-y:auto;
        span {
          color: ${theme.colors.pallet.dark} !important;
        }
      `,
    },
    highlighted: {
      style: ({ theme }: StyledProps<{}>) => `
        background-color: ${theme.colors.pallet.primary};
        color: ${theme.colors.pallet.dark};
        span {
          color: ${theme.colors.pallet.basicLight} !important;
        }
      `,
    },
    container: {
      style: () => `
        position: absolute;
        display: inline-block;
        z-index: 1;
      `,
    },
  },
};
