import { VALUES as CONSUMER_TYPES } from '@mmw/constants-consumer-types';
import { CONSUMER as VALIDATION } from '@mmw/constants-fields-validations';
import { DEFAULT as I18N } from '@mmw/constants-i18n-validations';
import * as yup from 'yup';

const { string } = yup;

const ERP_CONSUMER = string().max(VALIDATION.ERP_CONSUMER.MAX_LENGTH);

const CONSUMER_NAME = yup.string().max(VALIDATION.CONSUMER_NAME.MAX_LENGTH);

const MEMBERNUMBER = string().max(VALIDATION.ERP_CONSUMER.MAX_LENGTH);

const CONSUMER_TYPE = string().oneOf(CONSUMER_TYPES, I18N.REQUIRED.key);

// consumer / orgunitconsumers table on mmw database
export const CONSUMER = {
  ERP_CONSUMER,
  CONSUMER_NAME,
  MEMBERNUMBER,
  CONSUMER_TYPE,
  ERP_CONSUMER_REQUIRED: ERP_CONSUMER.required(I18N.REQUIRED.key),
};

export default CONSUMER;
