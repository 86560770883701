import {
  BUTTON_TEXT_UUID,
  BUTTON_UUID,
  FORM_INPUT_UUID,
  FORM_SELECT_UUID,
  PAGE_HEADER_UUID,
} from '@ui-system/interfaces';
import { makeStyles } from '@ui-system/style';
import { RootStylesProvider } from '@ui-system/styles-provider';
import React from 'react';

const useStyles = makeStyles(({ utils, theme, css }) => ({
  [BUTTON_UUID]: css`
    border: none;
    box-shadow: none;
    height: 45px;
    border-radius: 20px;
    min-width: 140px;
  `,
  [BUTTON_TEXT_UUID]: css`
    color: ${theme.colors[theme.mode].white};
    text-transform: none;
  `,
  [FORM_SELECT_UUID]: css`
    padding: 5px;
    height: 45px;
    width: 100%;
    border-radius: 5px;
    font-size: 16px;
    border: 1px solid ${theme.colors[theme.mode].gray.A200};
    font-size: 15px;
    ${utils.applyBackgroundColor(theme, 'white')};
  `,
  [FORM_INPUT_UUID]: css`
    border-radius: 5px;
    align-items: center;
    padding: 5px;
    font-size: 16px;
    max-height: 45px;
    height: 45px;
    border: 1px solid ${theme.colors[theme.mode].gray.A200};
    ${utils.applyBorderRadius(theme, 4)};
  `,
  // [FORM_INPUT_CONTAINER_UUID]: css`
  //   height: fit-content;
  // `,
  [PAGE_HEADER_UUID]: css`
    background-color: ${theme.colors[theme.mode].basicLight};
    padding: 30px 40px;
    height: 120px;
    ${utils.applyShadow(theme, 3)};
  `,
}));

export const StylesProvider: React.FC<React.PropsWithChildren<any>> = ({
  children,
}: React.PropsWithChildren<any>) => {
  const styles = useStyles();
  return <RootStylesProvider styles={styles}>{children}</RootStylesProvider>;
};
