import {
  Reducer,
  REQUEST_UNBLOCK_ERROR,
  REQUEST_UNBLOCK_START,
  REQUEST_UNBLOCK_SUCCESS,
  RESET_ACTION,
} from './types';

const INITIAL_STATE = {
  unblockStatus: false,
  loading: false,
  error: null,
};

const authenticationRequestUnblockReducer: Reducer = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case REQUEST_UNBLOCK_START:
      return {
        ...state,
        unblockStatus: false,
        loading: true,
        error: null,
      };
    case REQUEST_UNBLOCK_SUCCESS:
      return {
        ...state,
        unblockStatus: action.payload.success,
        loading: false,
        error: null,
      };
    case REQUEST_UNBLOCK_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case RESET_ACTION:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default authenticationRequestUnblockReducer;
