import {
  ALL_STATUS,
  CreateIssueJSON,
  IssueActionRequired,
  IssueResolutionMode,
  IssueStatus,
  IssueValueObject,
  PerformIssueActionJSON,
} from '@issue/interfaces';
import { EMPTY_OBJECT } from '@mmw/constants-utils';
import contextualConfig from '@mmw/contextual-config';
import { FileJSON, QuestionGroupJSON } from '@mmw/services-core-common/types';
import { QuestionGroupWithCampaignItem } from '@retail/product-registration-wizard-ui/fields/questions/types';
import { EMPTY_ARRAY } from '@shared-utils/array';
import { U } from '@utils/ts';
import update from 'immutability-helper';
import { reduce } from 'lodash';
import filter from 'lodash/filter';
import get from 'lodash/get';
import head from 'lodash/head';
import includes from 'lodash/includes';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import noop from 'lodash/noop';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { ContextIssueType } from './types';
import {
  convertCreatedIssueToContextIssue,
  convertCreateIssueToContextIssue,
  filterIssueByQuestionGroupCode,
  filterIssueByUserId,
  findIssueByFileID,
  findIssueByQuestionGroupCode,
  hasFileIDInThisIssues,
  returnIssuesWithoutFileID,
} from './utils';

export const OPEN_TICKET_STATUS = [IssueStatus.TODO, IssueStatus.IN_PROGRESS];
export const CLOSED_TICKET_STATUS = [IssueStatus.CLOSED];

export type PerformIssueActionJSONWithId = {
  issueID: number;
} & PerformIssueActionJSON<any>;

export type AddIssueOrIssues = (
  issueOrIssues: CreateIssueJSON | CreateIssueJSON[],
  status?: IssueStatus,
) => void;

export type RemoveIssuesByFileID = (fileID: number) => void;

export type AddIssueUpdate = (action: PerformIssueActionJSONWithId) => void;

export class IssuesContextValue {
  issues: ContextIssueType[] = [];

  issuesToSave: CreateIssueJSON[];

  issuesToUpdate: PerformIssueActionJSONWithId[];

  addIssueOrIssues: AddIssueOrIssues = noop;

  addIssueUpdate: AddIssueUpdate = noop;

  removeIssuesByFileID: RemoveIssuesByFileID = noop;
}

const INITIAL_VALUES = new IssuesContextValue();

export const IssuesContext = createContext<IssuesContextValue>(INITIAL_VALUES);

type Props = {
  children: ReactNode;
  initialIssues: U.Nullable<IssueValueObject[]>;
};

const { logger } = contextualConfig.application;
const log = logger.extend('@issue/context');

const useContextValue = (initialIssues: U.Nullable<IssueValueObject[]>) => {
  const [issuesToSave, setIssuesToSave] =
    useState<CreateIssueJSON[]>(EMPTY_ARRAY);
  const [issuesToUpdate, setIssuesToUpdate] =
    useState<PerformIssueActionJSONWithId[]>(EMPTY_ARRAY);

  const [issues, setIssues] = useState<ContextIssueType[]>(
    map(initialIssues, convertCreatedIssueToContextIssue),
  );

  const initOrResetContextIssues = useCallback(
    (newIssues: U.Nullable<IssueValueObject[]>) => {
      setIssuesToSave(EMPTY_ARRAY);
      setIssuesToUpdate(EMPTY_ARRAY);
      setIssues(map(newIssues, convertCreatedIssueToContextIssue));
    },
    [],
  );

  const addIssueUpdate = useCallback(
    (action: PerformIssueActionJSONWithId) => {
      setIssuesToUpdate(prevState => [...prevState, action]);
    },
    [setIssuesToUpdate],
  );

  const addIssueOrIssues = useCallback(
    (issueOrIssues: CreateIssueJSON | CreateIssueJSON[]) => {
      setIssuesToSave(prevState => {
        log.info(
          'Will try to set issues to save in the context',
          issueOrIssues,
        );
        if (isArray(issueOrIssues)) {
          log.info('Issues were an array');
          return update(prevState, { $push: issueOrIssues });
        }
        const fileID = head(get(issueOrIssues, 'data.fileIDs')) as number;
        if (hasFileIDInThisIssues(prevState, fileID)) {
          log.info('File already with open issue', issueOrIssues, fileID);
          return update(prevState, {
            $set: returnIssuesWithoutFileID(prevState, fileID),
          });
        }
        log.info('Saving single issue', issueOrIssues);
        return update(prevState, { $push: [issueOrIssues] });
      });

      setIssues(prevState => {
        log.info('Will try to set issues to show in the context');
        if (isArray(issueOrIssues)) {
          log.info('Issues were an array', issueOrIssues);
          return update(prevState, {
            $push: map(issueOrIssues, issue =>
              convertCreateIssueToContextIssue(issue),
            ),
          });
        }
        const fileID = head(get(issueOrIssues, 'data.fileIDs')) as number;
        if (hasFileIDInThisIssues(prevState, fileID)) {
          log.info('File already with open issue', issueOrIssues, fileID);
          return update(prevState, {
            $set: returnIssuesWithoutFileID(prevState, fileID),
          });
        }
        log.info('Saving single issue', issueOrIssues);
        return update(prevState, {
          $push: [convertCreateIssueToContextIssue(issueOrIssues)],
        });
      });
    },
    [],
  );

  const removeIssuesByFileID = useCallback((fileID: number) => {
    setIssuesToSave(prev =>
      filter(prev, issue => !includes(issue.data.fileIDs, fileID)),
    );
    setIssues(prev =>
      filter(prev, issue => !includes(issue.data.fileIDs, fileID)),
    );
    setIssuesToUpdate(prev =>
      filter(prev, issue => !includes(issue.data.fileIDs, fileID)),
    );
  }, []);

  useEffect(() => {
    initOrResetContextIssues(initialIssues);
  }, [initialIssues, initOrResetContextIssues]);

  return useMemo<IssuesContextValue>(
    () => ({
      issues,
      issuesToSave,
      addIssueOrIssues,
      issuesToUpdate,
      addIssueUpdate,
      removeIssuesByFileID,
    }),
    [
      addIssueOrIssues,
      addIssueUpdate,
      issues,
      issuesToSave,
      issuesToUpdate,
      removeIssuesByFileID,
    ],
  );
};

export const IssuesProvider: React.FC<Props> = ({
  initialIssues,
  children,
}: Props) => {
  const value = useContextValue(initialIssues);
  return (
    <IssuesContext.Provider value={value}>{children}</IssuesContext.Provider>
  );
};

export const useIssuesContext = (): IssuesContextValue =>
  useContext(IssuesContext);

export const useAddIssueOrIssues = (): AddIssueOrIssues => {
  const { addIssueOrIssues } = useIssuesContext();
  return addIssueOrIssues;
};

export const useAddIssueUpdate = (): AddIssueUpdate => {
  const { addIssueUpdate } = useIssuesContext();
  return addIssueUpdate;
};

export const useIssues = (): ContextIssueType[] => {
  const { issues } = useIssuesContext();
  return issues;
};

export const useIssuesToUpdate = (): PerformIssueActionJSONWithId[] => {
  const { issuesToUpdate } = useIssuesContext();
  return issuesToUpdate;
};

export const useRemoveIssuesByFileID = (): RemoveIssuesByFileID => {
  const { removeIssuesByFileID } = useIssuesContext();
  return removeIssuesByFileID;
};

export const useIssuesToSave = (): CreateIssueJSON[] => {
  const { issuesToSave } = useIssuesContext();
  const filesRejected = filter(
    issuesToSave,
    issue =>
      issue.actionRequired ===
        IssueActionRequired.CORRECT_QUESTION_ATTACHMENT_FILE &&
      issue.initialResolutionMode === IssueResolutionMode.UNRESOLVED,
  );
  const questionGroupMap: Record<string, CreateIssueJSON[]> = {};
  filesRejected.forEach(fileRejected => {
    const code = fileRejected.data.questionGroupCodes[0];
    if (!code) return;
    if (!questionGroupMap[code]) {
      questionGroupMap[code] = [fileRejected];
    } else {
      questionGroupMap[code].push(fileRejected);
    }
  });

  return [
    ...issuesToSave,
    ...map(Object.keys(questionGroupMap), questionGroupCode => {
      let fileIDs = [];
      questionGroupMap[questionGroupCode].forEach(issue => {
        fileIDs = fileIDs.concat(issue.data.fileIDs);
      });
      return {
        ...questionGroupMap[questionGroupCode][0],
        initialResolutionMode: null,
        initialStatus: IssueStatus.TODO,
        data: {
          ...questionGroupMap[questionGroupCode][0].data,
          fileIDs,
          configuration: {
            ...(questionGroupMap[questionGroupCode][0].data.configuration ||
              EMPTY_OBJECT),
            amountOfActionsRequiredToResolve:
              questionGroupMap[questionGroupCode].length,
          },
        },
      };
    }),
  ];
};

// UTILS

export function useCountIssuesByStatus(possibleStatus: IssueStatus[]) {
  const issues = useIssues();
  return useMemo(() => {
    if (issues) {
      return reduce(
        issues,
        (acc, issue) => {
          if (includes(possibleStatus, issue.status)) {
            return acc + 1;
          }
          return acc;
        },
        0,
      );
    }
    return 0;
  }, [issues, possibleStatus]);
}

export function useIssueByFile(
  file: U.Nullable<FileJSON>,
  possibleStatus: IssueStatus[],
): U.Nullable<ContextIssueType> {
  const issues = useIssues();
  return useMemo(
    () => findIssueByFileID(issues, file?.fileID, possibleStatus),
    [file, issues, possibleStatus],
  );
}

export function useIssueByQuestionGroup(
  questionGroup: U.Nullable<QuestionGroupJSON | QuestionGroupWithCampaignItem>,
  possibleStatus: IssueStatus[],
): U.Nullable<ContextIssueType> {
  const issues = useIssues();
  return useMemo(
    () =>
      findIssueByQuestionGroupCode(issues, questionGroup?.code, possibleStatus),
    [issues, questionGroup?.code, possibleStatus],
  );
}

export function useIssuesByQuestionGroup(
  questionGroup: U.Nullable<QuestionGroupJSON | QuestionGroupWithCampaignItem>,
  possibleStatus: IssueStatus[],
): U.Nullable<ContextIssueType[]> {
  const issues = useIssues();
  return useMemo(
    () =>
      filterIssueByQuestionGroupCode(
        issues,
        questionGroup?.code,
        possibleStatus,
        { isActionRequired: false },
      ),
    [issues, questionGroup?.code, possibleStatus],
  );
}

export function useIssuesByUserId(
  userId: U.Nullable<number>,
  possibleStatus: IssueStatus[],
): ContextIssueType[] {
  const issues = useIssues();
  return useMemo(() => {
    if (!userId) return EMPTY_ARRAY;
    return filterIssueByUserId(issues, userId, possibleStatus);
  }, [userId, issues, possibleStatus]);
}

function useGetIssueByFile(
  possibleStatus: IssueStatus[],
): (file: U.Nullable<FileJSON>) => U.Nullable<ContextIssueType> {
  const issues = useIssues();
  return useCallback(
    (file: U.Nullable<FileJSON>) =>
      findIssueByFileID(issues, file.fileID, possibleStatus),
    [issues, possibleStatus],
  );
}

export function useHasQuestionGroupAnOpenTicket(
  questionGroup: U.Nullable<QuestionGroupJSON | QuestionGroupWithCampaignItem>,
): boolean {
  const issue = useIssueByQuestionGroup(questionGroup, OPEN_TICKET_STATUS);
  return issue != null;
}

function isFileRejected(issue: U.Nullable<ContextIssueType>): boolean {
  if (!issue) {
    return false;
  }
  return issue.resolutionMode === IssueResolutionMode.UNRESOLVED;
}

function isFileApproved(issue: U.Nullable<ContextIssueType>): boolean {
  if (!issue) {
    return false;
  }
  return issue.resolutionMode === IssueResolutionMode.RESOLVED;
}

export function useGetIsFileApproved(): (
  file: U.Nullable<FileJSON>,
) => boolean {
  const getIssueByFile = useGetIssueByFile(CLOSED_TICKET_STATUS);
  return useCallback(
    (file: U.Nullable<FileJSON>) => {
      const issue = getIssueByFile(file);
      if (!issue) {
        return false;
      }
      return isFileApproved(issue);
    },
    [getIssueByFile],
  );
}

export function useIsFileRejected(file: U.Nullable<FileJSON>): boolean {
  const issue = useIssueByFile(file, ALL_STATUS);
  return isFileRejected(issue);
}

export function useGetIsFileRejected(): (
  file: U.Nullable<FileJSON>,
) => boolean {
  const getIssueByFile = useGetIssueByFile(ALL_STATUS);
  return useCallback(
    (file: U.Nullable<FileJSON>) => {
      const issue = getIssueByFile(file);
      return isFileRejected(issue);
    },
    [getIssueByFile],
  );
}

export function useGetFileRejectedReason(): (
  file: U.Nullable<FileJSON>,
) => U.Nullable<string> {
  const getIssueByFile = useGetIssueByFile(ALL_STATUS);
  return useCallback(
    (file: U.Nullable<FileJSON>) => {
      const issue = getIssueByFile(file);
      if (isFileRejected(issue)) {
        return issue.data.message;
      }
      return null;
    },
    [getIssueByFile],
  );
}

export function useIsFileApproved(file: U.Nullable<FileJSON>): boolean {
  const issue = useIssueByFile(file, ALL_STATUS);
  if (!issue) {
    return false;
  }
  return isFileApproved(issue);
}
