import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import { getConsumerProfileService } from '@mmw/services-holder';
import { Action } from '@reduxjs/toolkit';
import { ActionsObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { getConsumerProfileData } from '../actions';

const { logger } = contextualConfig.application;

type Output =
  | ReturnType<typeof getConsumerProfileData.success>
  | ReturnType<typeof getConsumerProfileData.error>;

const getConsumerProfileDataEpic = (
  action$: ActionsObservable<Action>,
): Observable<Output> =>
  action$.pipe(
    ofType(getConsumerProfileData.start.match),
    tap(() => logger.debug('Trying to get consumer personal data')),
    switchMap(() =>
      from(getConsumerProfileService().getConsumerProfileData()).pipe(
        map(data => getConsumerProfileData.success(data, null)),
        catchError(error => of(getConsumerProfileData.error(error, null))),
      ),
    ),
  );

export default getConsumerProfileDataEpic;
