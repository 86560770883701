export default ({ theme, disabled }) => `
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  min-height: 40px;
  cursor: pointer;
  background-color: ${theme.colors.pallet.primary};
  border: none;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: ${theme.button.variable.BORDER_RADIUS};
  transition: 0.3s;
    &:focus-within {
      outline: none;
      transition: 0.3s;
      box-shadow: 0 0 4px ${theme.colors.pallet.secondary};
    }
    ${
      disabled &&
      `
      background-color: ${theme.colors.pallet.basic};
      cursor: default;
    `
    };
    ${theme.media.desktop(`
      &:hover {
        background-color: ${theme.colors.pallet.secondary};
        ${
          disabled &&
          `
          background-color: ${theme.colors.pallet.basic};
        `
        };
      }
    `)}
    ${
      !disabled &&
      `
      ::-moz-focus-inner {
      border: 0;
    }
    transform: translate3d(0, 0, 0);
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
      background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
      background-repeat: no-repeat;
      background-position: 50%;
      transform: scale(10,10);
      opacity: 0;
      transition: transform .5s, opacity 1s;
    }
    &:active:after {
      transform: scale(0,0);
      opacity: .2;
      transition: 0s;
    }
    `
    }
`;
