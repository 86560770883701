import contextualConfig from '@mmw/contextual-config';
import { HomeLink } from '@mmw/ui-web-blocks-header-mmw';
import get from 'lodash/get';
import * as React from 'react';
// import { Img } from 'react-image';
import styled from 'styled-components';

import DropdownLanguage from './DropdownLanguage';
import DropdownTrader from './DropdownTrader';

// const {
//   clients: {
//     retailClient: { baseURI },
//   },
// } = contextualConfig;

const baseURI = get(contextualConfig, 'clients.retailClient.baseURI', '');

const Header = styled.div`
  position: relative;
  display: flex;
  right: 0;
  bottom: 20px;
  ${({ theme }) => theme.media.upToTablet`
    right: 40px;
  `};
`;

const MobileHeader = styled.div`
  display: flex;
`;

const HeaderMMW = () => (
  <Header>
    <HomeLink homeUrl={baseURI} />
    <DropdownTrader />
    <DropdownLanguage />
  </Header>
);

const MobileHeaderMMW = () => (
  <MobileHeader>
    <HomeLink homeUrl={baseURI} />
    <DropdownTrader />
    <DropdownLanguage />
  </MobileHeader>
);

export { HeaderMMW as default, DropdownTrader, MobileHeaderMMW };
