import CONSTANTS_I18N from '@mmw/constants-i18n';
import { Body2 } from '@mmw/ui-web-elements-typography';
import HelpTooltip from '@retail/ui-hybrid-blocks-help-tooltip';
import React, { useMemo } from 'react';
import styled from 'styled-components';

const {
  VALIDATIONS: { DEFAULT: I18N },
} = CONSTANTS_I18N;

type Props = {
  restrictions: string | string[];
};

const RestrictionsContainer = styled.div(
  ({ theme }) => `
  background-color: ${theme.colors.pallet.background};
  border: ${theme.colors.pallet.error} solid 1px;
  margin-top: 8px;
  padding: 8px;
`,
);

function Restrictions({ restrictions }: Props): React.ClassicElement<Props> {
  const restrictionTips = useMemo(() => {
    if (Array.isArray(restrictions)) {
      return restrictions.join(', ');
    }
    return restrictions;
  }, [restrictions]);
  return (
    <HelpTooltip i18nTitle={I18N.RESTRICTIONS} zIndex="1250">
      <Body2 i18n={I18N.INVALID_INPUTS} />
      <RestrictionsContainer>
        <Body2>{restrictionTips}</Body2>
      </RestrictionsContainer>
    </HelpTooltip>
  );
}

export default Restrictions;
