import { I18nShape } from '@mmw/constants-i18n';
import { FieldPath } from '@mmw/redux-store-fast-form';
import React from 'react';

import FormField from './FormField';
import Select, { OwnProps as SelectProps } from './Select';

export type FastFormSelectProps = {
  fieldPath: FieldPath;
  labelI18n?: I18nShape;
  placeholderI18n?: I18nShape;
  tabIndex?: string;
} & SelectProps;

const FastFormSelectField: React.FC<FastFormSelectProps> = ({
  fieldPath,
  labelI18n,
  options,
  optionsName,
  optionsValue,
  placeholderI18n,
  tabIndex,
  ...props
}: FastFormSelectProps) => (
  <FormField fieldPath={fieldPath} labelI18n={labelI18n} {...props}>
    <Select
      fieldPath={fieldPath}
      options={options}
      optionsName={optionsName}
      optionsValue={optionsValue}
      placeholder={placeholderI18n}
      tabIndex={tabIndex}
      {...props}
    />
  </FormField>
);

export default FastFormSelectField;
