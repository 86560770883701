import useConfigLinks from '@mmw/clients-ui-hooks-use-legal-links';
import { FOOTER_LINKS as FOOTER_LINKS_I18N } from '@mmw/constants-i18n-functionalities';
import { getClientVersion } from '@mmw/contextual-config';
import { useAssets } from '@ui-system/assets';
import { css } from '@ui-system/css';
import { useIsMediaQueryUpToMD, useIsMediaQueryUpToSM } from '@ui-system/media-query';
import { makeStyle } from '@ui-system/style';
import UI from '@ui-system/ui';
import * as React from 'react';
import { useCallback } from 'react';

// TODO: Hardcoded texts

const useContentContainerStyle = makeStyle(
  ({ mediaQueries }) => css`
    display: flex;
    flex: 1;
    justify-content: center;
    margin-top: 30px;
    max-width: 1200px;
    ${mediaQueries.md`
      max-width: 900px;
    `}
    ${mediaQueries.sm`
      max-width: auto;
    `}
  `,
);

const AppVersion: React.FC = () => (
  <UI.Container style={css`
    position: absolute;
    bottom: 15px;
    right: 15px;
  `}
  >
    <UI.Overline modifiers="primary, lowercase">v:</UI.Overline>
    <UI.Overline modifiers="primary">{getClientVersion()}</UI.Overline>
  </UI.Container>
)

const Footer: React.FC = () => {
  const assets = useAssets();
  const navigate = useCallback((link: string | URL) => window.open(link), []);
  const isPhone = useIsMediaQueryUpToSM();
  const upToMd = useIsMediaQueryUpToMD();
  const contentContainerStyle = useContentContainerStyle();

  const { imprintLink, dataProtectionLink, gtcLink } = useConfigLinks();
  return (
    <UI.Container
      bg="basicLight"
      style={css`        
        padding: ${isPhone ? '20px 60px' : '40px 100px'}; 
        position: relative;      
      `}
      direction="column"
      justify="center"
      align="center"
      w="100%"
    >
      <UI.Image src={assets.mmwLogo} width="auto" height={60} />
      <UI.Container
        style={contentContainerStyle}
        modifiers="grid3col"
        responsive="upToSm.direction=column;upToSm.modifiers=grid1col;upToSm.m=4,0,0,0;upToSm.h=100%"
      >
        <UI.Container direction="column" responsive="upToSm.m=0,0,2,0;">
          <UI.Typography modifiers="primary, bold">
            marken mehrwert - brand added value AG
          </UI.Typography>
          <UI.Typography modifiers="primary, thin">
            Schildkrötstraße 15
          </UI.Typography>
          <UI.Typography modifiers="primary, thin">
            68199 Mannheim
          </UI.Typography>
          <UI.Typography modifiers="primary, thin">
            USt-IdNr. DE258032509
          </UI.Typography>
          <UI.Typography modifiers="primary">
            Registergericht Mannheim HRB 704123
          </UI.Typography>
        </UI.Container>
        <UI.Container direction="column" responsive="upToSm.m=0,0,2,0">
          <UI.Typography modifiers="primary">
            Vorstand: Frank Schwarz
          </UI.Typography>
          <UI.Typography modifiers="primary">
            Aufsichtsrat (Vorsitz): Prof. Dr. Stefan Jugel
          </UI.Typography>
          <UI.Typography modifiers="primary">
            Aufsichtsräte: Prof. Dr. Gerald Graf, Prof. Dr. Dietmar von
            Hoyningen-Huen
          </UI.Typography>
        </UI.Container>
        <UI.Container direction="column">
          <UI.Typography modifiers="primary">
            E-Mail: info@markenmehrwert.com
          </UI.Typography>
          <UI.Link onClick={() => navigate(gtcLink)}>
            <UI.Typography
              modifiers="primary, pointer"
              i18n={FOOTER_LINKS_I18N.AGB}
            />
          </UI.Link>
          <UI.Link onClick={() => navigate(imprintLink)}>
            <UI.Typography
              modifiers="primary, pointer"
              i18n={FOOTER_LINKS_I18N.IMPRESSUM}
            />
          </UI.Link>
          <UI.Link onClick={() => navigate(dataProtectionLink)}>
            <UI.Typography
              modifiers="primary, pointer"
              i18n={FOOTER_LINKS_I18N.DATENSCHUTZ}
            />
          </UI.Link>
          <UI.Typography modifiers="primary">
            Cookie-Richtlinie (EU)
          </UI.Typography>
        </UI.Container>
      </UI.Container>
      <AppVersion />
    </UI.Container>
  );
};

export default Footer;
