import createApi, {
  ApiResponse as ApiResponseType,
  Interceptor as InterceptorType,
} from '@mmw/common-api-client';
import config from '@mmw/contextual-config';

export default createApi({
  baseURI: config.api.faq.baseURI,
});

export type ApiResponse<T> = ApiResponseType<T>;
export type Interceptor = InterceptorType;
