import { isIE11, isIEOrEDGE } from '@mmw/constants-devices';
import { isSafari } from 'react-device-detect';

export const BREAKPOINTS = {
  EXTRA_LARGE_DESKTOP: {
    minWidth: 1900,
  },
  LARGE_DESKTOP: {
    minWidth: 1400,
  },
  DESKTOP: {
    minWidth: 901,
  },
  TABLET: {
    maxWidth: 900,
    minWidth: 600,
  },
  PHONE: {
    maxWidth: 599,
  },
  SMALL_PHONES: {
    maxWidth: 360,
  },
};

export type Breakpoints = typeof BREAKPOINTS;
export type Breakpoint = keyof Breakpoints;

export default {
  BREAKPOINTS,
  extraLargeDesktop: style => `
  @media screen and (min-width: ${BREAKPOINTS.EXTRA_LARGE_DESKTOP.minWidth}px) {
    ${style}
  }
`,
  largeDesktop: style => `
  @media screen and (min-width: ${BREAKPOINTS.LARGE_DESKTOP.minWidth}px) {
    ${style}
  }
`,
  desktop: style => `
  @media screen and (min-width: ${BREAKPOINTS.DESKTOP.minWidth}px) {
    ${style}
  }
`,
  upToDesktop: style => `
  @media screen and (max-width: ${BREAKPOINTS.LARGE_DESKTOP.minWidth}px) {
    ${style}
  }
`,
  upToTablet: style => `
    @media screen and (max-width: ${BREAKPOINTS.TABLET.maxWidth}px) {
      ${style}
    }
  `,
  tablet: style => `
    @media screen and (min-width: ${BREAKPOINTS.TABLET.minWidth}px)
    and (max-width: ${BREAKPOINTS.TABLET.maxWidth}px) {
      ${style}
    }
  `,
  phone: style => `
    @media screen and (max-width: ${BREAKPOINTS.PHONE.maxWidth}px) {
      ${style}
    }
  `,
  smallPhones: style => `
  @media screen and (max-width: ${BREAKPOINTS.SMALL_PHONES.maxWidth}px) {
      ${style}
    }
  `,
  landscape: style => `
    @media (orientation: landscape) {
      ${style}
    }
  `,
  portrait: style => `
    @media (orientation: portrait) {
      ${style}
    }
  `,
  IE11: style => (isIE11 ? style : ''),
  IEOrEDGE: style => (isIEOrEDGE ? style : ''),
  notIE11: style => (isIE11 ? '' : style),
  safari: style => (isSafari ? style : ''),
};
