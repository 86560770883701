import useConfigLinks from '@mmw/clients-ui-hooks-use-legal-links';
import { DATA_PROTECTION, GTC, IMPRINT } from '@mmw/constants-i18n-common';
import { getClientVersion } from '@mmw/contextual-config';
import { use } from '@mmw/ui-theme/utils';
import { Caption1 } from '@mmw/ui-web-elements-typography';
import Link from '@mmw/ui-web-materials-link';
import moment from 'moment';
import * as React from 'react';
import styled from 'styled-components';

const CURRENT_YEAR = moment().format('YYYY');

const StyledFooter = styled.footer(props => use(props, 'page.footer'));

const FooterContent = styled.div(props => use(props, 'page.footer.content'));

const LinksContainer = styled.div(props =>
  use(props, 'page.footer.linksContainer'),
);

const FooterText = styled(Caption1)(props => use(props, 'page.footer.text'));

const VersionContainer = styled.div``;

const Footer: React.FC = () => {
  const { imprintLink, dataProtectionLink, gtcLink } = useConfigLinks();
  return (
    <StyledFooter>
      <FooterContent>
        <LinksContainer>
          <Link href={imprintLink} target="_blank">
            <Link.Text i18n={IMPRINT} modifiers="caption1" />
          </Link>
          <Link href={dataProtectionLink} target="_blank">
            <Link.Text i18n={DATA_PROTECTION} modifiers="caption1" />
          </Link>
          <Link href={gtcLink} target="_blank">
            <Link.Text i18n={GTC} modifiers="caption1" />
          </Link>
        </LinksContainer>
        <VersionContainer>
          <>
            <FooterText>{getClientVersion()}</FooterText>
            <FooterText>© brand added value AG,</FooterText>
            <FooterText>{CURRENT_YEAR}</FooterText>
          </>
        </VersionContainer>
      </FooterContent>
    </StyledFooter>
  );
};

export default Footer;
