import Features from '@mmw/constants-features';
import contextualConfig from '@mmw/contextual-config';
import { notifySuccess } from '@mmw/global-feedback-channel';
import ofType from '@mmw/redux-rx-of-type-operator';
import { AuthenticationResponse } from '@mmw/services-auth-api-authentication/types';
import { getAuthenticationService } from '@mmw/services-holder';
import { ActionsObservable } from 'redux-observable';
import { concat, from, Observable, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  tap,
  timeout,
  withLatestFrom,
} from 'rxjs/operators';

import {
  authenticateByTanErrorAction,
  authenticateByTanSuccessAction,
} from '../actions';
import { foundUseridSelector } from '../stateSelector';
import {
  AUTHENTICATE_BY_TAN_START,
  AuthenticateByTanErrorAction,
  AuthenticateByTanStartAction,
  AuthenticateByTanSuccessAction,
  RootState,
} from '../types';

const { logger } = contextualConfig.application;
const { defaultTimeout } = contextualConfig.api;

type Input = AuthenticateByTanStartAction;
type Output = AuthenticateByTanSuccessAction | AuthenticateByTanErrorAction;

const authenticateByTanEpic = (
  action$: ActionsObservable<Input>,
  state$: Observable<RootState>,
): Observable<Output> => {
  const handleSuccess = ({ accessToken }: AuthenticationResponse) =>
    from(getAuthenticationService().getUserDetails()).pipe(
      map(loggedUser =>
        authenticateByTanSuccessAction(loggedUser, accessToken),
      ),
      tap(() => notifySuccess(Features.AUTH_API.AUTHENTICATE_BY_TAN)),
      catchError(error => of(authenticateByTanErrorAction(error))),
    );

  return action$.pipe(
    ofType(AUTHENTICATE_BY_TAN_START),
    withLatestFrom(state$.pipe(map(foundUseridSelector))),
    tap(([_, userid]) =>
      logger.debug(`Trying to authenticate username=${userid}`),
    ),
    switchMap(
      ([
        {
          payload: { tanCode },
        },
        foundUserid,
      ]) =>
        from(
          getAuthenticationService().authenticateByTan({
            username: foundUserid || '',
            tan: tanCode,
          }),
        ).pipe(
          timeout(defaultTimeout),
          tap(({ success }: AuthenticationResponse) =>
            logger.debug(`Result from authentication success=${success}`),
          ),
          switchMap(handleSuccess),
          catchError(error => concat(of(authenticateByTanErrorAction(error)))),
        ),
    ),
  );
};

export default authenticateByTanEpic;
