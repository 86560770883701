import { StyledProps } from '@mmw/ui-theme/types';
import style from '@mmw/ui-web-blocks-items-carousel/theme/style';
import { NEXT_ICON, PREVIOUS_ICON } from '@retail/web-theme/assets';

const arrowStyle = ({ theme, disabled }, icon) => `
  background-color: ${theme.colors.pallet.primary};
  mask-image: url(${icon});
  -webkit-mask-image: url(${icon});
  width: 40px;
  height: 40px;
  opacity: ${disabled ? '0.3' : '1'};
  cursor: ${disabled ? 'default' : 'pointer'};
  ${theme.media.IE11(`
    background: none;
    background-image: url(${icon});
    border: none;
    display: ${disabled ? 'none' : 'flex'};
  `)}
`;

type Props = StyledProps<{
  disabled: boolean;
}>;

const leftArrowStyle = (props: Props) => arrowStyle(props, PREVIOUS_ICON);
const rightArrowStyle = (props: Props) => arrowStyle(props, NEXT_ICON);

const theme = {
  style,
  leftArrow: {
    style: leftArrowStyle,
  },
  rightArrow: {
    style: rightArrowStyle,
  },
};

export default theme;
