import withBundleVersion, {
  WithBundleVersionProps,
} from '@mmw/ui-hoc-with-bundle-version';
import { findMessage } from '@mmw/utils-text-utils';
import { IntlShape } from 'react-intl';
import { ComponentEnhancer, compose, withHandlers } from 'recompose';

type Values = Record<string | number, string | number>;

export type WithTranslateProps = WithBundleVersionProps & {
  translate: (msgKey: string, defaultMsg: string, values?: Values) => string;
};

const withTranslate: ComponentEnhancer<unknown, unknown> = compose(
  withBundleVersion,
  withHandlers({
    translate:
      ({ intl }: { intl: IntlShape }) =>
      (msgKey: string, defaultMsg: string, values: Values | null | undefined) =>
        findMessage(msgKey, defaultMsg, intl, values),
  }),
);
export default withTranslate;
