import { I18nShape } from '@mmw/constants-i18n';
import { AUTHENTICATION as I18N } from '@retail/constants-i18n';
import React, { useCallback } from 'react';
import { U } from 'ts-toolbelt';

import Action, { TroubleActionProps } from './Action';

interface Props extends Omit<TroubleActionProps, 'i18n'> {
  usernameRecoverUrl: U.Nullable<string>;
  i18n?: I18nShape;
}

const UsernameForgottenAction: React.FC<Props> = ({
  i18n,
  useLink,
  usernameRecoverUrl,
  linkPrefix,
  linkSuffix,
  textModifiers,
  ...props
}: Props) => {
  const onClick = useCallback(async () => {
    if (!usernameRecoverUrl) return null;
    return window.open(usernameRecoverUrl);
  }, [usernameRecoverUrl]);

  return (
    <Action
      onClick={onClick}
      i18n={i18n}
      useLink={useLink}
      linkPrefix={linkPrefix}
      linkSuffix={linkSuffix}
      textModifiers={textModifiers}
      {...props}
    />
  );
};

UsernameForgottenAction.defaultProps = {
  i18n: I18N.AUTHENTICATION.USERNAME_FORGOTTEN,
};

export default UsernameForgottenAction;
