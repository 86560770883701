import { Modifiers } from '@mmw/ui-theme/modifiers/types';
import { use } from '@mmw/ui-theme/utils';
import { ChangeEvent } from 'react';
import styled from 'styled-components';
import { F } from 'ts-toolbelt';

export { useRadioState } from './hooks';

type RadioProps = {
  name: string;
  value: string;
  onChange: F.Function<[ChangeEvent<HTMLInputElement>]>;
  modifiers?: Modifiers;
};

const Radio = styled.input.attrs<RadioProps>(() => ({
  type: 'radio',
}))(props => use(props, 'radio'));

export default Radio;
