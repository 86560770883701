// import each from 'lodash/each';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import take from 'lodash/take';

function filterWithMaxSize(
  array: Array<any>,
  condition: (item: any, index: number) => boolean,
  maxSize: number,
) {
  if (isEmpty(array)) return [];
  if (!maxSize || maxSize > array.length) {
    // eslint-disable-next-line no-param-reassign
    maxSize = array.length;
  }

  return take(filter<any, any>(array, condition), maxSize);
}
// TODO: try to optimize the algorythim to not search for more items to filter
// when maxSize is reached.

function hasAny<T>(arrayToLookFor: Array<T>, arrayOfMatches: Array<T>) {
  // let found = false;
  // each(arrayOfMatches, (match: T) => {
  //   if (includes(arrayToLookFor, match)) {
  //     found = true;
  //   }
  // });
  // return found;
  return some(arrayToLookFor, item => includes(arrayOfMatches, item));
}

export { filterWithMaxSize, hasAny };
