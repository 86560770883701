import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import { getUserProfileService } from '@mmw/services-holder';
import { ActionsObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, timeout } from 'rxjs/operators';

import {
  updateLoggedUserProfilePictureErrorAction,
  updateLoggedUserProfilePictureSuccessAction,
} from '../actions';
import {
  UPDATE_LOGGED_USER_PROFILE_PICTURE_START,
  UpdateLoggedUserProfilePictureErrorAction,
  UpdateLoggedUserProfilePictureStartAction,
  UpdateLoggedUserProfilePictureSuccessAction,
} from '../types';

const { logger } = contextualConfig.application;
const { defaultTimeout } = contextualConfig.api;

type Input = UpdateLoggedUserProfilePictureStartAction;
type Output =
  | UpdateLoggedUserProfilePictureSuccessAction
  | UpdateLoggedUserProfilePictureErrorAction;

const updateLoggedUserProfilePicture = (
  action$: ActionsObservable<Input>,
): Observable<Output> =>
  action$.pipe(
    ofType(UPDATE_LOGGED_USER_PROFILE_PICTURE_START),
    tap(() => logger.debug('Update Logged in trader profile picture Start')),
    switchMap((action: UpdateLoggedUserProfilePictureStartAction) =>
      from(
        getUserProfileService().updateLoggedUserProfilePicture(
          action.payload.photo,
        ),
      ).pipe(
        timeout(defaultTimeout),
        map(data => updateLoggedUserProfilePictureSuccessAction(data)),
        catchError(error =>
          of(updateLoggedUserProfilePictureErrorAction(error)),
        ),
      ),
    ),
  );

export default updateLoggedUserProfilePicture;
