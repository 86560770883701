import { use } from '@mmw/ui-theme/utils';
import React from 'react';
import styled from 'styled-components';

type WrapperProps = {
  children: React.ReactNode;
  datePanelPosition?: string;
};

const Wrapper = styled.div<WrapperProps>(props =>
  use(props, 'dateInput.wrapper'),
);

export default Wrapper;
