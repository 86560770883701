import contextualConfig from '@mmw/contextual-config';
import { get } from '@mmw/utils-object-utils';
import { Action } from '@redux-basic-module/reducer-utils/types';
import castArray from 'lodash/castArray';
import { useCallback, useEffect, useRef } from 'react';
import { useStore } from 'react-redux';
import { F } from 'ts-toolbelt';

import { NAMESPACE } from './module';

const { logger } = contextualConfig.application;

const log = logger.extend('use-redux-action');

export function useReduxAction<A extends Action<string, string, string>>(
  callback: F.Function<[A]>,
  type: A['type'],
): void {
  const currentValue = useRef(null);
  const store = useStore();
  const handleChange = useCallback(() => {
    log.info('Will try to handle action for', type);
    const state = store.getState();
    const action = get(state, NAMESPACE) as A;
    const previousValue = currentValue.current;
    currentValue.current = action?.type as string;
    log.info('With the actual action passing', action?.type);
    if (
      previousValue !== action?.type &&
      castArray(type).includes(action?.type)
    ) {
      log.info('Calling the callback for', action?.type, action?.payload);
      callback(action);
    }
  }, [callback, store, type]);

  useEffect(() => {
    const unsubscribe = store.subscribe(handleChange);
    return () => unsubscribe();
  }, [handleChange, store]);
}
