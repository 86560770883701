import useOnKeyDown from '@mmw/ui-web-hooks-use-input-key-down';
import { isFunction } from 'lodash';
import React, { useCallback } from 'react';

import useFastFormProviderContext from '../useFastFormProviderContext';
import useSubmitStart from '../useSubmitStart';

function useOnPressEnterSubmit() {
  const { options } = useFastFormProviderContext();
  const submitFormStart = useSubmitStart();
  const enterKeyDown = useOnKeyDown();
  return useCallback(
    (event: React.KeyboardEvent) => {
      const { onPressEnter } = options;
      if (onPressEnter === false) {
        return;
      }
      if (onPressEnter === true) {
        enterKeyDown(event, submitFormStart);
        return;
      }
      if (isFunction(onPressEnter)) {
        enterKeyDown(event, onPressEnter);
      }
    },
    [enterKeyDown, submitFormStart, options],
  );
}

export default useOnPressEnterSubmit;
