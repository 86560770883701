import { ApiResponse, default as defaultApiV2 } from '@mmw/api-v2';
import { SalesOrgBrand } from '@mmw/constants-salesorgbrand-ids';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import autoBind from 'auto-bind';

import { GetClassOfGoodsPath } from './apiPaths';
import logger from './log';
import { ClassOfGoodJSON } from './types';

type Api = typeof defaultApiV2;

type TraderClassOfGoodsServiceOptions = {
  apiv2?: Api;
  authenticationService: AuthenticationService;
};

class TraderClassOfGoodsService {
  api: Api;

  authenticationService: AuthenticationService;

  constructor({
    apiv2,
    authenticationService,
  }: TraderClassOfGoodsServiceOptions) {
    this.api = apiv2 || defaultApiV2;
    this.authenticationService = authenticationService;
    autoBind(this);
  }

  async getClassOfGoods(
    salesOrgBrand: SalesOrgBrand,
    classOfGood: number | null,
    language: string,
    onlyWhichHasProducts = false,
  ): Promise<Array<ClassOfGoodJSON>> {
    logger.debug(
      'Trying to get classofgoods by lang=%s request=%s',
      language,
      classOfGood,
      onlyWhichHasProducts,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Array<ClassOfGoodJSON>> = await this.api.get(
        GetClassOfGoodsPath(
          salesOrgBrand,
          classOfGood,
          language,
          onlyWhichHasProducts,
        ),
        {
          headers,
        },
      );
      const { data } = response;
      logger.info(`Successfully got classofgoods, size ${data.length}`);
      return data;
    } catch (error) {
      logger.error('Error when getting classofgoods, error=%O', error);
      throw error;
    }
  }
}

export default TraderClassOfGoodsService;
