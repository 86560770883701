import {
  DropdownViewVariant,
  LanguageDropdown,
} from '@i18n/ui-language-dropdown';
import { useNavigateToHome } from '@manufacturer/navigation/hooks';
import { useAssets } from '@ui-system/assets';
import { css } from '@ui-system/css';
import { getHeaderAuthenticatedContent } from '@ui-system/custom-nav-menu';
import UI from '@ui-system/ui';
import * as React from 'react';

import Nav from './Nav';

const HEADER_STYLE = css`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  max-width: 1520px;
  margin: 0 auto;
`;

const AuthenticatedContent = getHeaderAuthenticatedContent();

const Header: React.FC = () => {
  const assets = useAssets();
  const goHome = useNavigateToHome();

  return (
    <UI.Container style={HEADER_STYLE}>
      <UI.Container w="100%">
        <UI.Image
          src={assets.logo}
          width={240}
          height="auto"
          onClick={goHome}
        />
        <Nav />
      </UI.Container>
      <UI.Container align="center" gap={4}>
        <AuthenticatedContent />
        <LanguageDropdown
          customTrigger="click"
          listBackground="basicLight"
          viewVariant={DropdownViewVariant.LABELS_AND_FLAGS}
          round
        />
      </UI.Container>
    </UI.Container>
  );
};

export default Header;
