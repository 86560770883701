import { connect as formikConnect } from '@mmw/ui-libraries-formik-v2';
import { compose, HOC, mapProps } from 'recompose';

// Uses the name to handle the change automatically (formik)
const withValue: HOC<unknown, unknown> = compose(
  formikConnect,
  mapProps(({ formik: { handleChange }, ...rest }) => ({
    ...rest,
    onChange: handleChange,
  })),
);

export default withValue;
