import { Row } from '@mmw/hybrid-ui-structure-containers';
import { use } from '@mmw/ui-theme/utils';
import React from 'react';
import styled from 'styled-components';

import Nav from './elements/Nav';
import NavItem from './elements/NavItem';

const Container = styled.nav(props => use(props, 'navBar'));

type StaticNav = {
  NavItem: typeof NavItem;
  NavContainer: typeof StyledNavContainer;
};

const NavBar: React.FC<React.PropsWithChildren> & StaticNav = ({
  children,
}: React.PropsWithChildren) => (
  <Container>
    <Nav>
      {React.Children.map(children, (child: React.ReactElement) =>
        child ? React.cloneElement(child) : null,
      )}
    </Nav>
  </Container>
);

// @ts-ignore
const NavContainer = styled(Row)(props => use(props, 'navBar.container'));

const StyledNavContainer: React.FC<React.PropsWithChildren> = ({
  children,
}: React.PropsWithChildren) => <NavContainer>{children}</NavContainer>;

NavBar.NavItem = NavItem;
NavBar.NavContainer = StyledNavContainer;

export default NavBar;
