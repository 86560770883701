import config from '@mmw/config';
import contextualConfig from '@mmw/contextual-config';
import { useEffect } from 'react';
import { load } from 'recaptcha-v3';

import { getSafeActionName } from './getSafeActionName';
import logger from './log';

const { applicationId } = contextualConfig.application;
const { publickey } = config.recaptcha;

export async function executeRecaptchaAction(
  action: string,
  captchaConfig = { applicationId, publickey },
): Promise<string | null> {
  // remove invalid chars
  const actionName = getSafeActionName(action, captchaConfig.applicationId);
  try {
    const recaptcha = await load(captchaConfig.publickey, {
      autoHideBadge: true,
    });
    const token = await recaptcha.execute(actionName);
    logger.debug('Received captcha from action', actionName, token);
    return token;
  } catch (error) {
    logger.error('Received captcha error from action', actionName, error);
    return null;
  }
}

export function useExecuteActionOnMount(pageName: string): void {
  useEffect(() => {
    executeRecaptchaAction(`load:${pageName}`).then();
  }, [pageName]);
}
