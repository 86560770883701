import {
  Checkbox as MuiCheckbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from '@material-ui/core';
import componentModifiers from '@ui-system/default-modifiers/form/checkbox-group';
import { useCheckboxGroupStyle } from '@ui-system/default-styles';
import {
  CheckboxGroupProps,
  CheckboxGroupType,
} from '@ui-system/interfaces-form';
import Typography from '@ui-system/material-typography';
import { map } from 'lodash';
import React from 'react';

export const CheckboxGroup: CheckboxGroupType = ({
  options,
  caption,
  onChange,
  error,
  label,
  labelI18n,
  disabled,
  style,
  required,
  row,
  ...props
}: CheckboxGroupProps) => {
  const finalStyle = useCheckboxGroupStyle(style, '', componentModifiers);
  return (
    <FormControl
      required={required}
      error={error}
      component="fieldset"
      disabled={disabled}
      style={finalStyle}
    >
      <FormLabel>
        {(labelI18n && <Typography i18n={labelI18n} />) || (
          <Typography>{label}</Typography>
        )}
      </FormLabel>
      <FormGroup row={row}>
        {map(options, option => (
          <FormControlLabel
            key={option.value}
            control={
              <MuiCheckbox
                value={option.value}
                onChange={onChange}
                name={option.name}
                {...props}
              />
            }
            label={
              (option.labelI18n && <Typography i18n={option.labelI18n} />) || (
                <Typography>{option.label}</Typography>
              )
            }
          />
        ))}
      </FormGroup>
      <FormHelperText>
        <Typography i18n={caption} />
      </FormHelperText>
    </FormControl>
  );
};

export default CheckboxGroup;
