import dividerStyle from './dividerStyle';
import dividedButtonStyle from './style';
import textStyle from './textStyle';

export default {
  style: dividedButtonStyle,
  divider: {
    style: dividerStyle,
  },
  text: {
    style: textStyle,
  },
  modifiers: undefined,
  variable: {
    BORDER_RADIUS: 0,
  },
};
