const namespace = 'operations';

const OPERATIONS = {
  NOTIFICATION_ON_SUCCESS: {
    key: `${namespace}.notificationOnSuccess`,
    message: 'Operation concluded with success',
  },
  NOTIFICATION_ON_ERROR: {
    key: `${namespace}.notificationOnError`,
    message: 'Error while trying to execute the operation',
  },
  SEARCH_RESULT: {
    key: `${namespace}.searchResult`,
    message: 'Search result',
  },
  REDIRECT: {
    key: `${namespace}.redirect`,
    message: 'Redirect',
  },
};

const PROMPT = {
  SHOULD_LEAVE_FORM: {
    key: 'shouldLeaveFormPrompt',
    message:
      'Are you sure you want to leave? You will loose the current information.',
  },
  SHOULD_LEAVE_PAGE: {
    key: 'shouldLeavePagePrompt',
    message:
      'Are you sure you want to leave this page? All data entered will be lost.',
  },
  TITLE: {
    DISCARD_CHANGES: {
      key: 'shouldLeaveFormPrompt.title.discardChanges',
      message: 'Discard changes?',
    },
  },
};

const UPDATE_PROMPT = {
  MESSAGE: {
    key: 'updateAppPrompt.message',
    message:
      'A new update for the app is available. Do you wish to reload the app to get the new updates?',
  },
  TITLE: {
    key: 'updateAppPrompt.title',
    message: 'Update App?',
  },
  IOS_APP_STORE: {
    TITLE: {
      key: 'storesUpdatePrompt.title',
      message: 'New version available',
    },
    MESSAGE: {
      key: 'storesUpdatePrompt.message',
      message:
        'There is an all new version available on the App Store, to continue using your app you will need to upgrade it. Would you like to upgrade now?',
    },
  },
};

const TITLES = {
  CAMPAIGN_STATEMENT: {
    key: 'campaignStatement',
    message: 'Campaign statement',
  },
};

const I18N = {
  OPERATIONS,
  PROMPT,
  UPDATE_PROMPT,
  TITLES,
};

export default I18N;
