import { EMPTY_ARRAY, EMPTY_OBJECT } from '@mmw/constants-utils';
import { BaseAsyncState } from '@redux-async-module/reducer-utils';
import { U } from 'ts-toolbelt';

export class CarouselSearchByUuidState<RequestFields> extends BaseAsyncState {
  requestFields: Partial<RequestFields> = EMPTY_OBJECT;

  // maps page to item ids
  loadedIdsByPage: Record<number, number[]> = EMPTY_ARRAY;

  total = 0;

  limit = 10;

  currentPage = 1;

  currentItemIndexOnCurrentPage: U.Nullable<number> = null;
}
