const dropdownListStyle = {
  field: {
    style: ({ theme }) => `      
      background-color: ${theme.colors.pallet.basicLight};
      padding-left: 10px;    
      position: relative;
    `,
  },
  itemsContainer: {
    style: ({ theme, isVisible }) => `
      background-color: ${theme.colors.pallet.background};
      display: ${isVisible ? 'flex' : 'none'};  
      position: absolute;
      top: 100%;  
      z-index: 200;      
    `,
  },
  item: {
    style: ({ theme, disabled }) => `
      cursor: pointer;
      padding: 15px;
      &:hover {
        background-color: ${disabled ? '' : theme.colors.pallet.secondary};
        pre {
          color: ${disabled ? '' : theme.colors.pallet.light};
        }
      }
    `,
  },
};

export default dropdownListStyle;
