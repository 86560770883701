import { UploadableFileJSON } from '@mmw/services-core-common/types';
import { UploadRegistrationFilesResultJSON } from '@mmw/services-core-trader-registration/types';
import { createAsyncActions } from '@redux-async-module/actions-utils';

import { NAMESPACE } from './types';

export interface StartPayload {
  files: UploadableFileJSON[];
  registrationID: number;
}

export type SuccessPayload = UploadRegistrationFilesResultJSON;
export const updateFilesByType = createAsyncActions<
  StartPayload,
  SuccessPayload,
  Error,
  null
>(NAMESPACE, 'update-files-by-type');
