import { AuthenticationResponseWithUser } from '@mmw/redux-store-auth-api-login-by-password/types';
import { RefreshAuthenticationResponse } from '@mmw/services-auth-api-authentication/types';
import { TokenParseResult } from '@mmw/services-auth-api-token-parser/types';
import { createAsyncActions } from '@redux-async-module/actions-utils';
import { F, U } from 'ts-toolbelt';

import {
  AUTHENTICATE_BY_SSO_TOKEN_ERROR,
  AUTHENTICATE_BY_SSO_TOKEN_START,
  AUTHENTICATE_BY_SSO_TOKEN_SUCCESS,
  AUTHENTICATE_BY_TAN_ERROR,
  AUTHENTICATE_BY_TAN_START,
  AUTHENTICATE_BY_TAN_SUCCESS,
  AUTHENTICATE_ERROR,
  AUTHENTICATE_START,
  AUTHENTICATE_SUCCESS,
  AUTHENTICATE_TRADER_BY_SSO_ERROR,
  AUTHENTICATE_TRADER_BY_SSO_START,
  AUTHENTICATE_TRADER_BY_SSO_SUCCESS,
  AUTHENTICATE_WITH_GOOGLE_ERROR,
  AUTHENTICATE_WITH_GOOGLE_START,
  AUTHENTICATE_WITH_GOOGLE_SUCCESS,
  AuthenticateBySSOTokenErrorAction,
  AuthenticateBySSOTokenStartAction,
  AuthenticateBySSOTokenSuccessAction,
  AuthenticateByTanErrorAction,
  AuthenticateByTanStartAction,
  AuthenticateByTanSuccessAction,
  AuthenticateErrorAction,
  AuthenticateStartAction,
  AuthenticateSuccessAction,
  AuthenticateTraderBySSOErrorAction,
  AuthenticateTraderBySSOStartAction,
  AuthenticateTraderBySSOSuccessAction,
  AuthenticateWithGoogleErrorAction,
  AuthenticateWithGoogleStartAction,
  AuthenticateWithGoogleSuccessAction,
  BYPASS_FORCE_CHANGE_PW,
  BypassForceChangePwAction,
  CHECK_AUTHENTICATION_ERROR,
  CHECK_AUTHENTICATION_START,
  CHECK_AUTHENTICATION_SUCCESS,
  CHECK_AUTHENTICATION_WITH_SYSTEM_TOKEN_ERROR,
  CHECK_AUTHENTICATION_WITH_SYSTEM_TOKEN_START,
  CHECK_AUTHENTICATION_WITH_SYSTEM_TOKEN_SUCCESS,
  CheckAuthenticationErrorAction,
  CheckAuthenticationStartAction,
  CheckAuthenticationSuccessAction,
  CheckAuthWithSystemTokenErrorAction,
  CheckAuthWithSystemTokenStartAction,
  CheckAuthWithSystemTokenSuccessAction,
  LOGOUT_ERROR,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  LOGOUT_WITH_APP_ID_START,
  LogoutErrorAction,
  LogoutStartAction,
  LogoutSuccessAction,
  LogoutWithAppIdStartAction,
  NAMESPACE,
  REQUEST_TAN_ERROR,
  REQUEST_TAN_START,
  REQUEST_TAN_SUCCESS,
  RequestTanErrorAction,
  RequestTanStartAction,
  RequestTanSuccessAction,
  RESET_AUTHENTICATION_ERRORS,
  ResetAuthenticationErrorsAction,
  RETRIEVE_USERID_BY_EMAIL_ERROR,
  RETRIEVE_USERID_BY_EMAIL_START,
  RETRIEVE_USERID_BY_EMAIL_SUCCESS,
  RetrieveUseridByEmailErrorAction,
  RetrieveUseridByEmailStartAction,
  RetrieveUseridByEmailSuccessAction,
} from './types';

// CHECK
export const checkAuthenticationStartAction =
  (): CheckAuthenticationStartAction => ({
    type: CHECK_AUTHENTICATION_START,
  });

type CheckAuthenticationSuccess = (
  loggedUser: U.Nullable<TokenParseResult>,
  accessToken: U.Nullable<string>,
) => CheckAuthenticationSuccessAction;

export const checkAuthenticationSuccessAction: CheckAuthenticationSuccess = (
  loggedUser,
  accessToken,
) => ({
  type: CHECK_AUTHENTICATION_SUCCESS,
  payload: {
    isLoggedIn: loggedUser != null,
    loggedUser,
    accessToken,
  },
});
export const checkAuthenticationErrorAction = (
  error: Error,
): CheckAuthenticationErrorAction => ({
  type: CHECK_AUTHENTICATION_ERROR,
  payload: {
    error,
  },
});

export const checkAuthWithSystemTokenStartAction = ({
  applicationId,
  applicationBaseUrl,
  applicationPath,
  applicationContextPath,
  onSuccess,
}: {
  applicationId: string;
  applicationBaseUrl: U.Nullable<string>;
  applicationPath: U.Nullable<string>;
  applicationContextPath: U.Nullable<string>;
  onSuccess: F.Function<[AuthenticationResponseWithUser]>;
}): CheckAuthWithSystemTokenStartAction => ({
  type: CHECK_AUTHENTICATION_WITH_SYSTEM_TOKEN_START,
  payload: {
    applicationId,
    applicationBaseUrl,
    applicationPath,
    applicationContextPath,
    onSuccess,
  },
});

type CheckAuthWithSystemTokenSuccess = (
  loggedUser: U.Nullable<TokenParseResult>,
  accessToken: U.Nullable<string>,
) => CheckAuthWithSystemTokenSuccessAction;

export const checkAuthWithSystemTokenSuccessAction: CheckAuthWithSystemTokenSuccess =
  (loggedUser, accessToken) => ({
    type: CHECK_AUTHENTICATION_WITH_SYSTEM_TOKEN_SUCCESS,
    payload: {
      isLoggedIn: loggedUser != null,
      loggedUser,
      accessToken,
    },
  });

export const checkAuthWithSystemTokenErrorAction = (
  error: Error,
): CheckAuthWithSystemTokenErrorAction => ({
  type: CHECK_AUTHENTICATION_WITH_SYSTEM_TOKEN_ERROR,
  payload: {
    error,
  },
});

// AUTH
export const resetAuthenticationErrorAction =
  (): ResetAuthenticationErrorsAction => ({
    type: RESET_AUTHENTICATION_ERRORS,
  });

// AUTH
export const authenticateStartAction = (
  username: string,
  password: string,
): AuthenticateStartAction => ({
  type: AUTHENTICATE_START,
  payload: {
    username,
    password,
  },
});

type AuthenticateSuccess = (
  loggedUser: U.Nullable<TokenParseResult>,
  accessToken: U.Nullable<string>,
) => AuthenticateSuccessAction;

export const authenticateSuccessAction: AuthenticateSuccess = (
  loggedUser,
  accessToken,
) => ({
  type: AUTHENTICATE_SUCCESS,
  payload: {
    isLoggedIn: loggedUser != null,
    loggedUser,
    accessToken,
  },
});
export const authenticateErrorAction = (
  error: Error,
): AuthenticateErrorAction => ({
  type: AUTHENTICATE_ERROR,

  payload: {
    error,
  },
});

// AUTH TAN
export const authenticateByTanStartAction = (
  tanCode: string,
): AuthenticateByTanStartAction => ({
  type: AUTHENTICATE_BY_TAN_START,
  payload: {
    tanCode,
  },
});

type AuthenticateByTanSuccess = (
  loggedUser: U.Nullable<TokenParseResult>,
  accessToken: U.Nullable<string>,
) => AuthenticateByTanSuccessAction;

export const authenticateByTanSuccessAction: AuthenticateByTanSuccess = (
  loggedUser,
  accessToken,
) => ({
  type: AUTHENTICATE_BY_TAN_SUCCESS,
  payload: {
    isLoggedIn: loggedUser != null,
    loggedUser,
    accessToken,
  },
});
export const authenticateByTanErrorAction = (
  error: Error,
): AuthenticateByTanErrorAction => ({
  type: AUTHENTICATE_BY_TAN_ERROR,

  payload: {
    error,
  },
});

// AUTH BY SSO TOKEN
export const authenticateBySSOTokenStartAction = (
  ssoToken: string,
  options?: AuthenticateBySSOTokenStartAction['payload']['options'],
): AuthenticateBySSOTokenStartAction => ({
  type: AUTHENTICATE_BY_SSO_TOKEN_START,
  payload: {
    ssoToken,
    options,
  },
});

type AuthenticateBySSOTokenSuccess = (
  loggedUser: U.Nullable<TokenParseResult>,
  accessToken: U.Nullable<string>,
) => AuthenticateBySSOTokenSuccessAction;

export const authenticateBySSOTokenSuccessAction: AuthenticateBySSOTokenSuccess =
  (loggedUser, accessToken) => ({
    type: AUTHENTICATE_BY_SSO_TOKEN_SUCCESS,
    payload: {
      isLoggedIn: loggedUser != null,
      loggedUser,
      accessToken,
    },
  });
export const authenticateBySSOTokenErrorAction = (
  error: Error,
): AuthenticateBySSOTokenErrorAction => ({
  type: AUTHENTICATE_BY_SSO_TOKEN_ERROR,
  payload: {
    error,
  },
});

// TRADER AUTH BY SSO TOKEN
export const authenticateTraderBySSOTokenStartAction = (
  site: string,
  params: any,
): AuthenticateTraderBySSOStartAction => ({
  type: AUTHENTICATE_TRADER_BY_SSO_START,
  payload: {
    site,
    params,
  },
});

export const authenticateTraderBySSOTokenSuccessAction = ({
  loginData,
  ssoToken,
  success,
}): AuthenticateTraderBySSOSuccessAction => ({
  type: AUTHENTICATE_TRADER_BY_SSO_SUCCESS,
  payload: {
    loginData,
    ssoToken,
    success,
  },
});
export const authenticateTraderBySSOTokenErrorAction = (
  error: Error,
): AuthenticateTraderBySSOErrorAction => ({
  type: AUTHENTICATE_TRADER_BY_SSO_ERROR,
  payload: {
    error,
  },
});

// REQUEST TAN
export const requestTanStartAction = (
  username: string,
  isReSend: boolean,
): RequestTanStartAction => ({
  type: REQUEST_TAN_START,

  payload: {
    username,
    isReSend,
  },
});
type RequestTanSuccess = (isReSend: boolean) => RequestTanSuccessAction;
export const requestTanSuccessAction: RequestTanSuccess = (
  isReSend: boolean,
) => ({
  type: REQUEST_TAN_SUCCESS,
  payload: {
    isReSend,
  },
});
export const requestTanErrorAction = (
  error: Error,
  isReSend: boolean,
): RequestTanErrorAction => ({
  type: REQUEST_TAN_ERROR,

  payload: {
    error,
    isReSend,
  },
});

// REQUEST TAN
export const retrieveUseridByEmailStartAction = (
  email: string,
  scopeNames?: string[],
): RetrieveUseridByEmailStartAction => ({
  type: RETRIEVE_USERID_BY_EMAIL_START,
  payload: {
    email,
    scopeNames,
  },
});

export const retrieveUseridByEmailSuccessAction = (
  userid: string,
): RetrieveUseridByEmailSuccessAction => ({
  type: RETRIEVE_USERID_BY_EMAIL_SUCCESS,
  payload: {
    userid,
  },
});
export const retrieveUseridByEmailErrorAction = (
  error: Error,
): RetrieveUseridByEmailErrorAction => ({
  type: RETRIEVE_USERID_BY_EMAIL_ERROR,
  payload: {
    error,
  },
});

// LOGIN WITH GOOGLE
export const authenticateWithGoogleStartAction = (
  applicationId: string,
  googleJwtToken: string,
  applicationBaseUrl: U.Nullable<string>,
  applicationPath: U.Nullable<string>,
): AuthenticateWithGoogleStartAction => ({
  type: AUTHENTICATE_WITH_GOOGLE_START,
  payload: {
    applicationId,
    googleJwtToken,
    applicationBaseUrl,
    applicationPath,
  },
});

export const authenticateWithGoogleSuccessAction = (
  accessToken: string,
): AuthenticateWithGoogleSuccessAction => ({
  type: AUTHENTICATE_WITH_GOOGLE_SUCCESS,
  payload: {
    accessToken,
  },
});

export const authenticateWithGoogleErrorAction = (
  error: Error,
): AuthenticateWithGoogleErrorAction => ({
  type: AUTHENTICATE_WITH_GOOGLE_ERROR,
  payload: {
    error,
  },
});

// LOGOUT
export const logoutStartAction = (
  onSuccess?: F.Function,
): LogoutStartAction => ({
  type: LOGOUT_START,
  payload: { onSuccess },
});
type LogoutSuccess = () => LogoutSuccessAction;
export const logoutSuccessAction: LogoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});
export const logoutErrorAction = (error: Error): LogoutErrorAction => ({
  type: LOGOUT_ERROR,

  payload: {
    error,
  },
});

export const logoutWithAppIdStartAction = (
  applicationId: string,
  applicationBaseUrl?: U.Nullable<string>,
  applicationPath?: U.Nullable<string>,
  applicationContextPath?: U.Nullable<string>,
  global?: U.Nullable<boolean>,
  logoutOthers?: U.Nullable<Record<string, unknown>>,
): LogoutWithAppIdStartAction => ({
  type: LOGOUT_WITH_APP_ID_START,
  payload: {
    applicationId,
    applicationBaseUrl,
    applicationPath,
    applicationContextPath,
    global,
    logoutOthers,
  },
});

export const bypassForceChangePwAction = (
  changePwUuid: string,
): BypassForceChangePwAction => ({
  type: BYPASS_FORCE_CHANGE_PW,
  payload: {
    changePwUuid,
  },
});

export const refreshAccessToken = createAsyncActions<
  null,
  RefreshAuthenticationResponse,
  Error,
  null
>(NAMESPACE, 'refresh-access-token');
