import { createAsyncActions } from '@redux-async-module/actions-utils';
import { createAction } from '@reduxjs/toolkit';

import {
  MoveWizardErrorPayload,
  MoveWizardStartPayload,
  MoveWizardSuccessPayload,
  NAMESPACE,
  StartWizardPayload,
  UpdateAuditErrorPayload,
  UpdateAuditStartPayload,
  UpdateAuditSuccessPayload,
} from './types';

// UPDATE AUDITS ACTIONS MUST BE CALLED AT THE END AND WHEN UPLOADS A FILE
export const updateAudit = createAsyncActions<
  UpdateAuditStartPayload,
  UpdateAuditSuccessPayload,
  UpdateAuditErrorPayload,
  null
>(NAMESPACE, 'update-audit');

// HANDLES FORWARDS/BACKWARDS IN THE WIZARD

export const startWizard = createAction<StartWizardPayload>(
  `${NAMESPACE}/start`,
);

export const moveWizard = createAsyncActions<
  MoveWizardStartPayload,
  MoveWizardSuccessPayload,
  MoveWizardErrorPayload,
  null
>(NAMESPACE, 'move');

export const completeAudit = createAction(`${NAMESPACE}/complete`);

export const resetWizard = createAction(`${NAMESPACE}/reset`);
