import {
  AuthenticationResponse,
  RecaptchaType,
  SecurityScopeNames,
} from '@mmw/services-auth-api-authentication/types';
import { TokenParseResult } from '@mmw/services-auth-api-token-parser/types';
import { getFieldPaths } from '@mmw/utils-object-utils';
import { F, U } from 'ts-toolbelt';

export class LoginByPasswordFormData {
  email = '';

  password = '';
}

export const LOGIN_BY_PASSWORD_FIELD_PATHS = getFieldPaths(
  new LoginByPasswordFormData(),
);

export type AuthenticationResponseWithUser = {
  loggedUser: TokenParseResult | null;
} & AuthenticationResponse;

export const SET_USERNAME = '@mmw/auth-api/login-by-password/SET_USERNAME';
export const SET_RECAPTCHA_RESPONSE =
  '@mmw/auth-api/login-by-password/SET_RECAPTCHA_RESPONSE';
export const LOGIN_BY_PASSWORD_START =
  '@mmw/auth-api/login-by-password/LOGIN_BY_PASSWORD_START';
export const LOGIN_BY_PASSWORD_SUCCESS =
  '@mmw/auth-api/login-by-password/LOGIN_BY_PASSWORD_SUCCESS';
export const LOGIN_BY_PASSWORD_ERROR =
  '@mmw/auth-api/login-by-password/LOGIN_BY_PASSWORD_ERROR';
export const LOGIN_BY_GOOGLE_START =
  '@mmw/auth-api/login-by-password/LOGIN_BY_GOOGLE_START';
export const LOGIN_BY_GOOGLE_SUCCESS =
  '@mmw/auth-api/login-by-password/LOGIN_BY_GOOGLE_SUCCESS';
export const LOGIN_BY_GOOGLE_ERROR =
  '@mmw/auth-api/login-by-password/LOGIN_BY_GOOGLE_ERROR';
export const CLEAR_ERROR = '@mmw/auth-api/login-by-password/CLEAR_ERROR';

export type SetUsernameAction = {
  type: '@mmw/auth-api/login-by-password/SET_USERNAME';
  payload: {
    username: string;
  };
};

export type ClearErrorAction = {
  type: '@mmw/auth-api/login-by-password/CLEAR_ERROR';
  payload: Record<string, unknown>;
};

export type SetRecaptchaResponseAction = {
  type: '@mmw/auth-api/login-by-password/SET_RECAPTCHA_RESPONSE';
  payload: {
    recaptchaResponse: string;
  };
};

export type LoginByPasswordStartAction = {
  type: '@mmw/auth-api/login-by-password/LOGIN_BY_PASSWORD_START';
  payload: {
    deviceId?: U.Nullable<string>;
    applicationId?: U.Nullable<string>;
    username?: U.Nullable<string>;
    password: string;
    recaptchaResponse?: U.Nullable<string>;
    recaptchaType?: U.Nullable<RecaptchaType>;
    onLoginSuccess?: F.Function<[AuthenticationResponseWithUser]>;
    applicationBaseUrl?: U.Nullable<string>;
    applicationPath?: U.Nullable<string>;
    applicationContextPath?: U.Nullable<string>;
    scopeNames?: SecurityScopeNames[];
  };
};

export type LoginByPasswordSuccessAction = {
  type: '@mmw/auth-api/login-by-password/LOGIN_BY_PASSWORD_SUCCESS';
  payload: AuthenticationResponseWithUser;
};

export type LoginByPasswordErrorAction = {
  type: '@mmw/auth-api/login-by-password/LOGIN_BY_PASSWORD_ERROR';
  payload: {
    error: Error;
  };
};

export type LoginByGoogleStartAction = {
  type: '@mmw/auth-api/login-by-password/LOGIN_BY_GOOGLE_START';
  payload: {
    googleToken: string;
    deviceId?: U.Nullable<string>;
    applicationId?: U.Nullable<string>;
    username?: U.Nullable<string>;
    recaptchaResponse?: U.Nullable<string>;
    recaptchaType?: U.Nullable<RecaptchaType>;
    onLoginSuccess?: F.Function<[AuthenticationResponseWithUser]>;
  };
};

export type LoginByGoogleSuccessAction = {
  type: '@mmw/auth-api/login-by-password/LOGIN_BY_GOOGLE_SUCCESS';
  payload: AuthenticationResponseWithUser;
};

export type LoginByGoogleErrorAction = {
  type: '@mmw/auth-api/login-by-password/LOGIN_BY_GOOGLE_ERROR';
  payload: {
    error: Error;
  };
};

export type State = {
  username: U.Nullable<string>;
  redirectUrl: U.Nullable<string>;
  recaptchaResponse: U.Nullable<string>;
  error: U.Nullable<Error>;
  loading: boolean;
};

export const NAMESPACE = 'authenticationLoginByPassword';

export type RootState = {
  [NAMESPACE]: State;
};

export type Action =
  | SetUsernameAction
  | ClearErrorAction
  | SetRecaptchaResponseAction
  | LoginByPasswordStartAction
  | LoginByPasswordSuccessAction
  | LoginByPasswordErrorAction;

export type Reducer = (state: State, action: Action) => State;
