import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import defaultProps from '@ui-system/default-props/tab-bar';
import { useTabBarStyle, useTabStyle } from '@ui-system/default-styles/tabBar';
import { fastHash, I18nShape } from '@ui-system/deps';
import {
  InactiveTabStyle,
  Style,
  TabBarProps,
  TabBarType,
  TypographyVariant,
} from '@ui-system/interfaces';
import { useIsMediaQueryUpToSM } from '@ui-system/media-query';
import UI from '@ui-system/ui';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import map from 'lodash/map';
import React, { useCallback } from 'react';

const renderTabTitle = (
  title:
    | string
    | (I18nShape & { icon?: string; iconColor?: string; iconSize?: number }),
  style: Style | undefined,
  variant?: TypographyVariant,
  modifiers?: string,
  isInactive?: boolean,
  inactiveStyle?: InactiveTabStyle,
): React.ReactElement => {
  if (isObject(title)) {
    return (
      <UI.Container align="center">
        {title?.icon ? (
          <UI.Icon
            name={title?.icon}
            color={
              isInactive && !isEmpty(inactiveStyle)
                ? inactiveStyle?.iconColor
                : title?.iconColor
            }
            size={title?.iconSize}
          />
        ) : null}
        <UI.Typography
          style={style}
          i18n={title}
          variant={variant}
          modifiers={
            isInactive && !isEmpty(inactiveStyle)
              ? inactiveStyle?.modifiers
              : modifiers
          }
        />
      </UI.Container>
    );
  }
  return (
    <UI.Typography style={style} variant={variant} modifiers={modifiers}>
      {title}
    </UI.Typography>
  );
};

const TabBar: TabBarType = ({
  onSelect,
  tabsTitles,
  currentTabIndex,
  // bottom,
  barStyle,
  tabStyle,
  titleVariant,
  titleModifiers,
  titleStyle,
  indicatorColor = 'primary',
  inactiveTabStyles,
  ...props
}: // children,
TabBarProps) => {
  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      onSelect(newValue);
    },
    [onSelect],
  );
  const finalTabBarStyle = useTabBarStyle(barStyle, null, null);
  const finalTabStyle = useTabStyle(tabStyle, null, null);
  const isUpToSm = useIsMediaQueryUpToSM();
  const getInactiveTabStyle = useCallback(
    (isInactive: boolean) => {
      if (isInactive && inactiveTabStyles?.style) {
        return {
          ...finalTabStyle,
          ...inactiveTabStyles?.style,
        };
      }
      return finalTabBarStyle;
    },
    [finalTabBarStyle, finalTabStyle, inactiveTabStyles?.style],
  );
  return (
    <Tabs
      value={currentTabIndex}
      onChange={handleChange}
      aria-label="basic tabs"
      style={finalTabBarStyle}
      variant={isUpToSm ? 'scrollable' : undefined}
      scrollButtons={isUpToSm ? 'auto' : undefined}
      indicatorColor={indicatorColor}
      {...props}
    >
      {map(tabsTitles, (title, index) => (
        <Tab
          key={fastHash(title)}
          label={renderTabTitle(
            title,
            titleStyle,
            titleVariant,
            titleModifiers,
            currentTabIndex !== index,
            inactiveTabStyles,
          )}
          style={getInactiveTabStyle(currentTabIndex !== index)}
        />
      ))}
    </Tabs>
  );
};

TabBar.defaultProps = defaultProps;

export default TabBar;
