import bodyStyle from './bodyStyle';
import columnsStyle from './columnStyle';
import headerRowStyle from './headerRowStyle';
import headerStyle from './headerStyle';
import iconStyle from './iconStyle';
import modifiers from './modifiers';
import rowStyle from './rowStyle';
import tableStyle from './style';

export default {
  style: tableStyle,
  header: {
    style: headerStyle,
    row: {
      style: headerRowStyle,
      modifiers,
    },
  },
  body: {
    style: bodyStyle,
  },
  row: {
    style: rowStyle,
    modifiers,
  },
  column: {
    style: columnsStyle,
    modifiers,
  },
  icon: {
    style: iconStyle,
    modifiers: undefined,
  },
  modifiers,
};
