import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import {
  AuthenticationResponse,
  GoogleAuthenticationRequest,
} from '@mmw/services-auth-api-authentication/types';
import { getAuthenticationService } from '@mmw/services-holder';
import { executeRecaptchaAction } from '@mmw/ui-libraries-recaptcha';
import { ActionsObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, timeout } from 'rxjs/operators';

import {
  authenticateWithGoogleErrorAction,
  authenticateWithGoogleSuccessAction,
} from '../actions';
import {
  AUTHENTICATE_WITH_GOOGLE_START,
  AuthenticateWithGoogleErrorAction,
  AuthenticateWithGoogleStartAction,
  AuthenticateWithGoogleSuccessAction,
} from '../types';

const { logger } = contextualConfig.application;
const { defaultTimeout } = contextualConfig.api;

function handleSuccess({ accessToken }: AuthenticationResponse) {
  return from(getAuthenticationService().getUserDetails()).pipe(
    map(() => authenticateWithGoogleSuccessAction(accessToken)),
    catchError(error => of(authenticateWithGoogleErrorAction(error))),
  );
}

async function googleAuthentication({
  googleJwtToken,
  applicationId,
  ...rest
}: GoogleAuthenticationRequest) {
  const loginData = await getAuthenticationService().authenticateWithGoogle({
    googleJwtToken,
    applicationId,
    recaptchaResponse: await executeRecaptchaAction('x-goog-iap-jwt-assertion'),
    recaptchaType: 'V3',
    ...rest,
  });
  return loginData;
}

type Input = AuthenticateWithGoogleStartAction;
type Output =
  | AuthenticateWithGoogleSuccessAction
  | AuthenticateWithGoogleErrorAction;
const authenticateWithGoogleEpic = (
  action$: ActionsObservable<Input>,
): Observable<Output> =>
  action$.pipe(
    ofType(AUTHENTICATE_WITH_GOOGLE_START),
    tap(action =>
      logger.debug(
        `Trying to authenticate using google token=${action.payload.googleJwtToken}`,
      ),
    ),
    switchMap(action =>
      from(googleAuthentication(action.payload)).pipe(
        timeout(defaultTimeout),
        tap(({ success }: AuthenticationResponse) =>
          logger.debug(`Result from authentication success=${success}`),
        ),
        switchMap(handleSuccess),
        catchError(error => of(authenticateWithGoogleErrorAction(error))),
      ),
    ),
  );

export default authenticateWithGoogleEpic;
