import { StyledProps } from 'styled-components';
import { O } from 'ts-toolbelt';

import banner from './banner';
import content from './content';
import description from './description';
import footer from './footer';
import header from './header';
import navbar from './navbar';
import title from './title';
import variable from './variable';

interface HeaderHeightProps extends StyledProps<O.Object> {
  showNavBar?: boolean;
  navMenuIsPresent?: boolean;
}

export const headerHeight = ({
  theme,
  showNavBar,
  navMenuIsPresent,
}: HeaderHeightProps): string =>
  `${theme.page.variable.LOGO_HEIGHT} + ${
    theme.page.variable.NAVBAR_HEIGHT
  } + ${navMenuIsPresent ? theme.page.variable.NAV_MENU_HEIGHT : '0px'} - ${
    showNavBar ? '0px' : theme.page.variable.NAVBAR_HEIGHT
  }`;

const pageTheme = {
  style: ({
    theme,
    showNavBar,
    navMenuIsPresent,
  }: HeaderHeightProps): string => `
    display: flex;
    flex-direction: column;
    padding-top: calc(${headerHeight({ theme, showNavBar, navMenuIsPresent })});
    background-color: ${theme.colors.pallet.light};    
    min-height: 100vh;
  `,
  header,
  navbar,
  banner,
  content,
  footer,
  title,
  description,
  variable,
};

export default pageTheme;
