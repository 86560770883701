import { enableBatching } from 'redux-batched-actions';
import { IModule } from 'redux-dynamic-modules';

import consumerProfileEpics, { Epics } from './epics';
import consumerProfileReducer from './reducer';
import { NAMESPACE as confumerProfileNamespace, RootState } from './types';

export const reducer = consumerProfileReducer;
export const epics = consumerProfileEpics;
export const NAMESPACE = confumerProfileNamespace;

export function getConsumerProfileModule(): IModule<RootState> & {
  epics: Epics;
} {
  return {
    id: NAMESPACE,
    reducerMap: {
      [NAMESPACE]: enableBatching(reducer),
    },
    epics,
  };
}
