import defaultFaqApi, { ApiResponse } from '@mmw/api-faq';
import defaultApiV2 from '@mmw/api-v2';
import { Tags } from '@mmw/constants-faq/types';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import autoBind from 'auto-bind';

import { QUESTION_PATH, TICKET_PATH } from './apiPaths';
import logger from './log';
import { FAQ, TicketRequestJSON, TicketResponseJSON } from './types';

type ApiV2 = typeof defaultFaqApi;
type FaqApi = typeof defaultFaqApi;

type FaqServiceOptions = {
  apiV2?: ApiV2;
  apiFaq?: FaqApi;
  authenticationService: AuthenticationService;
};

class FaqService {
  apiV2: ApiV2;

  faqApi: FaqApi;

  authenticationService: AuthenticationService;

  constructor({ apiV2, apiFaq, authenticationService }: FaqServiceOptions) {
    this.apiV2 = apiV2 || defaultApiV2;
    this.faqApi = apiFaq || defaultFaqApi;
    this.authenticationService = authenticationService;
    autoBind(this);
  }

  async getFaq(tags: Tags, language: string): Promise<Array<FAQ>> {
    logger.debug('Trying to retrieve frequent asked questions');
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Array<FAQ>> = await this.faqApi.post(
        QUESTION_PATH,
        {
          tags,
          language,
        },
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully retrieved frequent asked questions');
      return data;
    } catch (error) {
      logger.error(
        'Error when retrieving frequent asked questions, error=%O',
        error,
      );
      throw error;
    }
  }

  async sendTicket(
    ticket: TicketRequestJSON,
  ): Promise<TicketResponseJSON | null> {
    logger.debug('Trying to send support ticket');
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<any> = await this.apiV2.post(
        TICKET_PATH,
        {
          ...ticket,
        },
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully sent support ticket');
      return data;
    } catch (error) {
      logger.error('Error when trying to send support ticket, error=%O', error);
      throw error;
    }
  }
}

export default FaqService;
