import { I18nShape } from '@mmw/constants-i18n';
import { FieldPath } from '@mmw/redux-store-fast-form';
import { useMaxAndMinValidationValues } from '@mmw/redux-store-fast-form/hooks';
import {
  FieldConfigOptions,
  useFastFormField,
  useInputFieldConfigProps,
  useIsFormSubmitted,
} from '@mmw/redux-store-fast-form/hooks/fields';
import FormField, {
  OwnProps as FormFieldProps,
} from '@mmw/ui-web-materials-form-field';
import { deepmerge } from '@shared-utils/object';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import omitBy from 'lodash/omitBy';
import React, { useMemo } from 'react';

export type FastFormFieldProps = {
  fieldPath: FieldPath;
  fieldConfigOptions?: FieldConfigOptions;
  errorI18n?: I18nShape;
} & FormFieldProps;

export const FastFormField: React.FC<FastFormFieldProps> = ({
  fieldPath,
  children,
  fieldConfigOptions,
  errorI18n: givenErrorI18n,
  ...rest
}: FastFormFieldProps) => {
  const fieldCustomProperties = useMemo(
    () =>
      deepmerge(
        omitBy(fieldConfigOptions, isNil),
        omitBy({ required: rest.required }, isNil),
      ),
    [fieldConfigOptions, rest.required],
  );
  const inputFieldConfigProps = useInputFieldConfigProps(
    fieldPath,
    // @ts-ignore
    fieldCustomProperties,
  );
  const canShow = useIsFormSubmitted();
  const otherProps = useMemo(
    () => deepmerge(inputFieldConfigProps, rest),
    [inputFieldConfigProps, rest],
  );

  const { value, errors } = useFastFormField<string>(fieldPath);
  const { min, max } = useMaxAndMinValidationValues(fieldPath);
  const caption = useMemo(() => {
    if (
      !isEmpty(errors?.errors) &&
      isObject(errors?.errors[0]) &&
      errors?.errors[0]
    ) {
      return errors?.errors[0];
    }
    if (!isEmpty(errors?.message) || !isEmpty(givenErrorI18n?.message)) {
      return {
        message: errors?.message || givenErrorI18n?.message,
        key: errors?.message || givenErrorI18n?.message,
        values: { value: String(value), min: String(min), max: String(max) },
      };
    }
    return null;
  }, [
    givenErrorI18n?.message,
    errors?.errors,
    errors?.message,
    max,
    min,
    value,
  ]);

  const errorI18n = caption || givenErrorI18n;
  return (
    <FormField
      error={canShow && errorI18n != null}
      errorI18n={canShow ? errorI18n || undefined : undefined}
      {...otherProps}
    >
      {children}
    </FormField>
  );
};

export default FastFormField;
