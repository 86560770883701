import { StyledElement } from '@mmw/ui-theme/types';
import { use } from '@mmw/ui-theme/utils';
import styled from 'styled-components';

const Overlay: StyledElement<'div', unknown> = styled.div(props =>
  use(props, 'spinner.global.overlay'),
);
const SvgSpinner: StyledElement<'svg', unknown> = styled.svg(props =>
  use(props, 'spinner.global.svg'),
);

const Global = {
  Overlay,
  SvgSpinner,
};

export { Global as default, Overlay, SvgSpinner };
