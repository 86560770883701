import defaultIconModifiers from '@mmw/ui-web-elements-icon/theme/modifiers';
import defaultTypographyModifiers from '@mmw/ui-web-elements-typography/theme/modifiers';

export default {
  style: () => `
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
  `,
  modifiers: undefined,
  icon: {
    style: ({ theme }) => `
      fill: ${theme.colors.pallet.secondary};
      ${theme.media.phone`
        padding-bottom: 8px;
        div {
          min-width: 25px;
          min-height: 25px;
        }
      `}
      ${theme.media.IE11(`
        height: 40px;
      `)}
      margin-right: 10px;
    `,
    modifiers: {
      ...defaultIconModifiers,
    },
  },
  text: {
    style: ({ theme }) => `
      color: ${theme.colors.pallet.secondary};        
    `,
    modifiers: {
      ...defaultTypographyModifiers,
    },
  },
};
