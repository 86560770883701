import { SubmitButton } from '@mmw/ui-web-libraries-fast-form';
import React, { ReactNode } from 'react';

import {
  ConfirmPasswordField,
  EmailField,
  PasswordField,
  TANCodeField,
} from './elements/fields';
import SignIn from './SignIn';
import SignUp from './SignUp';

type Props = { children: ReactNode };
interface AuthStatic {
  SignIn: typeof SignIn;
  SignUp: typeof SignUp;
  Email: typeof EmailField;
  Password: typeof PasswordField;
  ConfirmPassword: typeof ConfirmPasswordField;
  TANCode: typeof TANCodeField;
  SubmitButton: typeof SubmitButton;
}
const Auth: React.FC<Props> & AuthStatic = ({ children }: Props) => (
  <>{children}</>
);

Auth.SignIn = SignIn;
Auth.SignUp = SignUp;
Auth.Email = EmailField;
Auth.Password = PasswordField;
Auth.ConfirmPassword = ConfirmPasswordField;
Auth.TANCode = TANCodeField;
Auth.SubmitButton = SubmitButton;

export default Auth;
