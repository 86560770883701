// eslint-disable-next-line max-classes-per-file
import { AVAILABLE_SCOPE_NAMES } from '@consumer/constants-general';
import { CONSUMER_CLIENT } from '@mmw/constants-application-ids';
import { DEFAULT as I18N_VALIDATIONS } from '@mmw/constants-i18n-validations';
import { UTILS } from '@mmw/constants-validation-field-schemas';
import contextualConfig from '@mmw/contextual-config';
import { getFieldPaths } from '@mmw/utils-object-utils';
import { checkIfEmailExists } from '@mmw/yup-validation-consumer-email-availability';
import { object, ref, string } from 'yup';

const { defaultCountry } = contextualConfig.application;

export class BaseUsernameForm {
  username = '';
}

export class UsernameAndTermsForm extends BaseUsernameForm {
  terms = false;
}

export class UsernameAndPasswordForm extends BaseUsernameForm {
  password = '';
}

export class AccountCreationForm extends UsernameAndPasswordForm {
  confirmPassword = '';

  country = defaultCountry;
}

export class NewPasswordForm {
  password = '';

  confirmPassword = '';
}
export class NewPasswordFormWithUUID {
  uuid = '';

  password = '';

  confirmPassword = '';
}

export class TanCodeValidationForm {
  tanCode = '';
}

export const USER_AND_PASS_LOGIN_INITIAL_VALUES = new UsernameAndPasswordForm();

export const USERNAME_AND_TERMS_FIELDPATHS = getFieldPaths(
  new UsernameAndTermsForm(),
);
export const USERNAME_AND_PASSWORD_FIELDPATHS = getFieldPaths(
  new UsernameAndPasswordForm(),
);

export const NEW_PASSWORD_FIELDPATHS = getFieldPaths(new NewPasswordForm());
export const NEW_PASSWORD_UUID_FIELDPATHS = getFieldPaths(
  new NewPasswordFormWithUUID(),
);

export const ACCOUNT_CREATION_FIELDPATHS = getFieldPaths(
  new AccountCreationForm(),
);

export const TAN_CODE_VALIDATION_FIELDPATHS = getFieldPaths(
  new TanCodeValidationForm(),
);

export const USERNAME_VALIDATION_SCHEMA = object<BaseUsernameForm>().shape({
  [USERNAME_AND_TERMS_FIELDPATHS.username.$path]: UTILS.STRING_REQUIRED,
});

const { applicationId } = contextualConfig.application;

const EMAIL_VALIDATION =
  applicationId === CONSUMER_CLIENT
    ? UTILS.EMAIL_REQUIRED.test(checkIfEmailExists(AVAILABLE_SCOPE_NAMES))
    : UTILS.EMAIL_REQUIRED;

export const EMAIL_VALIDATION_SCHEMA = object<BaseUsernameForm>().shape({
  [USERNAME_AND_TERMS_FIELDPATHS.username.$path]: EMAIL_VALIDATION,
});

export const USERNAME_AND_TERMS_VALIDATION_SCHEMA =
  object<UsernameAndTermsForm>().shape({
    [USERNAME_AND_TERMS_FIELDPATHS.username.$path]: UTILS.STRING_REQUIRED,
    [USERNAME_AND_TERMS_FIELDPATHS.terms.$path]: UTILS.TERMS_REQUIRED,
  });

export const USERNAME_AND_PASSWORD_VALIDATION_SCHEMA =
  object<UsernameAndPasswordForm>().shape({
    [USERNAME_AND_PASSWORD_FIELDPATHS.username.$path]: UTILS.STRING_REQUIRED,
    [USERNAME_AND_PASSWORD_FIELDPATHS.password.$path]: UTILS.STRING_REQUIRED,
  });

export const CREATE_PASSWORD_UUID_VALIDATION_SCHEMA = object().shape({
  [NEW_PASSWORD_UUID_FIELDPATHS.password.$path]: UTILS.STRONG_PASSWORD_REQUIRED,
  [NEW_PASSWORD_UUID_FIELDPATHS.uuid.$path]: UTILS.STRING_REQUIRED,
  [NEW_PASSWORD_UUID_FIELDPATHS.confirmPassword.$path]: string()
    .nullable()
    .oneOf(
      [ref(ACCOUNT_CREATION_FIELDPATHS.password.$path), null],
      I18N_VALIDATIONS.PASSWORD_MUST_MATCH.key,
    )
    .required(I18N_VALIDATIONS.REQUIRED.key),
});

export const NEW_PASSWORD_VALIDATION_SCHEMA = object<NewPasswordForm>().shape({
  [ACCOUNT_CREATION_FIELDPATHS.password.$path]: UTILS.STRONG_PASSWORD_REQUIRED,
  [ACCOUNT_CREATION_FIELDPATHS.confirmPassword.$path]: string()
    .nullable()
    .oneOf(
      [ref(ACCOUNT_CREATION_FIELDPATHS.password.$path), null],
      I18N_VALIDATIONS.PASSWORD_MUST_MATCH.key,
    )
    .required(I18N_VALIDATIONS.REQUIRED.key),
});

export const ACCOUNT_CREATION_VALIDATION_SCHEMA =
  object<AccountCreationForm>().shape({
    [ACCOUNT_CREATION_FIELDPATHS.username.$path]: UTILS.STRING_REQUIRED,
    [ACCOUNT_CREATION_FIELDPATHS.password.$path]:
      UTILS.STRONG_PASSWORD_REQUIRED,
    [ACCOUNT_CREATION_FIELDPATHS.confirmPassword.$path]: string()
      .nullable()
      .oneOf(
        [ref(ACCOUNT_CREATION_FIELDPATHS.password.$path), null],
        I18N_VALIDATIONS.PASSWORD_MUST_MATCH.key,
      )
      .required(I18N_VALIDATIONS.REQUIRED.key),
  });

export const TAN_CODE_VALIDATION_SCHEMA = object<TanCodeValidationForm>().shape(
  {
    [TAN_CODE_VALIDATION_FIELDPATHS.tanCode.$path]: UTILS.STRING_REQUIRED,
  },
);
