import { I18nShape } from '@mmw/constants-i18n';
import I18N from '@mmw/constants-i18n-languages';
import { LanguageCode } from '@mmw/constants-languages';
import contextualConfig from '@mmw/contextual-config';
import { changeLanguageStartAction } from '@mmw/redux-store-i18n/actions';
import { find, map } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { F } from 'ts-toolbelt';

import FLAGS from './flags';

const { supportedLanguages: SUPPORTED_LANGUAGES_CODES } =
  contextualConfig.application;

export type AvailableLanguage = {
  languageCode: LanguageCode;
  i18n: I18nShape;
  selectLanguage: F.Function;
  imageUrl: string;
};

const useAvailableLanguages = (onChangeLanguage?: F.Function) => {
  const dispatch = useDispatch();
  const changeLanguage = useCallback(
    language => {
      dispatch(changeLanguageStartAction(language));
      if (onChangeLanguage) {
        onChangeLanguage();
      }
    },
    [dispatch, onChangeLanguage],
  );

  return useMemo(
    (): Array<AvailableLanguage> =>
      map(SUPPORTED_LANGUAGES_CODES, (languageCode: LanguageCode) => ({
        languageCode,
        i18n: I18N[languageCode],
        selectLanguage: () => changeLanguage(languageCode),
        imageUrl: FLAGS[languageCode],
      })),
    [changeLanguage],
  );
};

export const useLanguageImageUrl = (language: LanguageCode) => {
  const availableLanguages = useAvailableLanguages();
  return useMemo(() => {
    if (!language) {
      return null;
    }
    const found = find(
      availableLanguages,
      (availableLanguage: AvailableLanguage) =>
        availableLanguage.languageCode === language,
    );
    return found != null ? found.imageUrl : null;
  }, [availableLanguages, language]);
};

export default useAvailableLanguages;
