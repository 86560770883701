import {
  AvatarProps,
  AvatarType,
  ButtonProps,
  ButtonType,
  CardProps,
  CardType,
  CarouselProps,
  CarouselType,
  ChipType,
  ContainerProps,
  DividerProps,
  DividerType,
  FormType,
  IconType,
  ImageType,
  LinkType,
  ListType,
  MenuContainerType,
  MenuType,
  ModalType,
  PageType,
  PaginatorType,
  PopoverType,
  RatingType,
  ScreenType,
  ScrollType,
  SkeletonType,
  SpinnerType,
  StepperType,
  TabBarType,
  TableType,
  TypographySet,
  TypographyType,
  VideoType,
} from '@ui-system/interfaces';
import { DatePickerType } from '@ui-system/interfaces-datepicker';
import { SwitchSelectorType } from '@ui-system/interfaces-switch-selector';

import { createUISystemInstance } from './createInstance';

export { Animation } from '@ui-system/animation';
export { Container } from '@ui-system/common-container';
export { default as Grid } from '@ui-system/common-grid';
export { useMediaQueries } from '@ui-system/media-query';

// AVATAR
export const Avatar = createUISystemInstance<AvatarType, AvatarProps>('Avatar');
// BUTTON
export const Button = createUISystemInstance<ButtonType, ButtonProps>('Button');
// CARD
export const Card = createUISystemInstance<CardType, CardProps>('Card');
Card.ActionArea = createUISystemInstance('Card.ActionArea');
Card.Header = createUISystemInstance('Card.Header');
Card.Media = createUISystemInstance('Card.Media');
Card.Content = createUISystemInstance('Card.Content');
Card.Actions = createUISystemInstance('Card.Actions');
Card.CollapseArea = createUISystemInstance('Card.CollapseArea');
// CAROUSEL
export const Carousel = createUISystemInstance<CarouselType, CarouselProps>(
  'Carousel',
);

// DIVIDER
export const Divider: DividerType = createUISystemInstance<
  DividerType,
  DividerProps
>('Divider');

// FORM
export const Form = createUISystemInstance<FormType, ContainerProps>('Form');
Form.Input = createUISystemInstance('Form.Input');
Form.Select = createUISystemInstance('Form.Select');
Form.MultipleSelect = createUISystemInstance('Form.MultipleSelect');
Form.Checkbox = createUISystemInstance('Form.Checkbox');
Form.TimePicker = createUISystemInstance('Form.TimePicker');
Form.RadioGroup = createUISystemInstance('Form.RadioGroup');
Form.Switch = createUISystemInstance('Form.Switch');
Form.CheckboxGroup = createUISystemInstance('Form.CheckboxGroup');
Form.DatePicker = createUISystemInstance('Form.DatePicker');
// ICON
export const Icon = createUISystemInstance<IconType>('Icon');

// IMAGE
export const Image = createUISystemInstance<ImageType>('Image');

// VIDEO
export const Video = createUISystemInstance<VideoType>('Video');

// LIST
export const List = createUISystemInstance<ListType>('List');

// MODAL
export const Modal = createUISystemInstance<ModalType>('Modal');

// PAGE
export const Page = createUISystemInstance<PageType>('Page');

Page.Header = createUISystemInstance('Page.Header');
Page.Content = createUISystemInstance('Page.Content');
Page.Footer = createUISystemInstance('Page.Footer');

// PAGINATOR
export const Paginator = createUISystemInstance<PaginatorType>('Paginator');

// RATING
export const Rating = createUISystemInstance<RatingType>('Rating');

// SCREEN
export const Screen = createUISystemInstance<ScreenType>('Screen');
Screen.Header = createUISystemInstance('Screen.Header');
Screen.Content = createUISystemInstance('Screen.Content');
Screen.Bottom = createUISystemInstance('Screen.Bottom');

// TABLE
export const Table = createUISystemInstance<TableType>('Table');
Table.Head = createUISystemInstance('Table.Head');
Table.Body = createUISystemInstance('Table.Body');
Table.Cell = createUISystemInstance('Table.Cell');
Table.Row = createUISystemInstance('Table.Row');

// TAB BAR
export const TabBar = createUISystemInstance<TabBarType>('TabBar');

// SPINNER
export const Spinner = createUISystemInstance<SpinnerType>('Spinner');

// STEPPER
export const Stepper = createUISystemInstance<StepperType>('Stepper');

// SKELETON
export const Skeleton = createUISystemInstance<SkeletonType>('Skeleton');

// TYPOGRAPHY
export const Typography = createUISystemInstance<TypographyType>('Typography');
Typography.Safe = createUISystemInstance('Typography.Safe');
export const H1 = createUISystemInstance<TypographySet['H1']>('H1');
H1.Safe = createUISystemInstance('H1.Safe');
export const H2 = createUISystemInstance<TypographySet['H2']>('H2');
H2.Safe = createUISystemInstance('H2.Safe');
export const H3 = createUISystemInstance<TypographySet['H3']>('H3');
H3.Safe = createUISystemInstance('H3.Safe');
export const H4 = createUISystemInstance<TypographySet['H4']>('H4');
H4.Safe = createUISystemInstance('H4.Safe');
export const H5 = createUISystemInstance<TypographySet['H5']>('H5');
H5.Safe = createUISystemInstance('H5.Safe');
export const H6 = createUISystemInstance<TypographySet['H6']>('H6');
H6.Safe = createUISystemInstance('H6.Safe');
export const Subtitle1 =
  createUISystemInstance<TypographySet['Subtitle1']>('Subtitle1');
Subtitle1.Safe = createUISystemInstance('Subtitle1.Safe');
export const Subtitle2 =
  createUISystemInstance<TypographySet['Subtitle2']>('Subtitle2');
Subtitle2.Safe = createUISystemInstance('Subtitle2.Safe');
export const Caption =
  createUISystemInstance<TypographySet['Caption']>('Caption');
Caption.Safe = createUISystemInstance('Caption.Safe');
export const Overline =
  createUISystemInstance<TypographySet['Overline']>('Overline');
Overline.Safe = createUISystemInstance('Overline.Safe');
export const Body1 = createUISystemInstance<TypographySet['Body1']>('Body1');
Body1.Safe = createUISystemInstance('Body1.Safe');
export const Body2 = createUISystemInstance<TypographySet['Body2']>('Body2');
Body2.Safe = createUISystemInstance('Body2.Safe');
export const ButtonText =
  createUISystemInstance<TypographySet['ButtonText']>('ButtonText');
ButtonText.Safe = createUISystemInstance('ButtonText.Safe');
export const DatePicker = createUISystemInstance<DatePickerType>('DatePicker');
export const SwitchSelector =
  createUISystemInstance<SwitchSelectorType>('SwitchSelector');

export const Menu = createUISystemInstance<MenuType>('Menu');
export const Link = createUISystemInstance<LinkType>('Link');

Menu.MenuContainer =
  createUISystemInstance<MenuContainerType>('Menu.MenuContainer');

export const Scroll = createUISystemInstance<ScrollType>('Scroll');

export const Popover = createUISystemInstance<PopoverType>('Popover');
export const Chip = createUISystemInstance<ChipType>('Chip');
