import {
  juraCreateAuditState,
  mapJuraCampaignToAuditStatus,
} from '@jura/audit-status';
import {
  JURA_ALLOWED_STATUS_TO_CREATE_ISSUES,
  JURA_AUDIT_I18N_MAPPING,
  JURA_CAMPAIGN_STATUS_MAPPING,
  JURA_ICON_MAPPING,
  JURA_STATUS_CHANGE_OPTIONS,
  JURA_STATUS_COLOR_MAPPING,
} from '@jura/audit-status/constants';
import { CampaignStatus } from '@mmw/constants-campaign-status';
import { I18nShape } from '@mmw/constants-i18n';
import { SalesOrgBrand } from '@mmw/constants-salesorgbrand-ids';
import CONFIG from '@mmw/contextual-config';
import { RegistrationResponseJSON } from '@mmw/services-core-trader-registration/types';
import {
  mapOralbCampaignToAuditStatus,
  oralbCreateAuditState,
} from '@oralb/audit-status';
import {
  ORALB_ALLOWED_STATUS_TO_CREATE_ISSUES,
  ORALB_AUDIT_I18N_MAPPING,
  ORALB_CAMPAIGN_STATUS_MAPPING,
  ORALB_ICON_MAPPING,
  ORALB_STATUS_CHANGE_OPTIONS,
  ORALB_STATUS_COLOR_MAPPING,
} from '@oralb/audit-status/constants';
import get from 'lodash/get';
import { O, U } from 'ts-toolbelt';

import { AuditState, AuditStatus, AuditStatusOption } from './types';

const { salesOrgBrandID } = CONFIG.application;

const CAMPAIGN_STATUS_MAPPING_BY_SOB = {
  [SalesOrgBrand.JURA]: JURA_CAMPAIGN_STATUS_MAPPING,
  [SalesOrgBrand.ORALB_DE]: ORALB_CAMPAIGN_STATUS_MAPPING,
};

export function getAuditToCampaignStatusMappingBySob<T>(): T {
  return CAMPAIGN_STATUS_MAPPING_BY_SOB[salesOrgBrandID];
}

const ALLOWED_STATUS_TO_CREATE_ISSUES = {
  [SalesOrgBrand.JURA]: JURA_ALLOWED_STATUS_TO_CREATE_ISSUES,
  [SalesOrgBrand.ORALB_DE]: ORALB_ALLOWED_STATUS_TO_CREATE_ISSUES,
};

export function getSobAllowedStatusToCreateIssues(): AuditStatus[] {
  return ALLOWED_STATUS_TO_CREATE_ISSUES[salesOrgBrandID];
}

const STATUS_COLOR_MAPPING = {
  [SalesOrgBrand.JURA]: JURA_STATUS_COLOR_MAPPING,
  [SalesOrgBrand.ORALB_DE]: ORALB_STATUS_COLOR_MAPPING,
};

export function getAuditStatusColor(status: U.Nullable<string>) {
  if (!status) return null;
  return STATUS_COLOR_MAPPING[salesOrgBrandID]?.[status];
}

const ICON_MAPPING = {
  [SalesOrgBrand.JURA]: JURA_ICON_MAPPING,
  [SalesOrgBrand.ORALB_DE]: ORALB_ICON_MAPPING,
};

export function getAuditIcon(status: string): string | undefined {
  return ICON_MAPPING[salesOrgBrandID]?.[status];
}

const CREATE_AUDIT_STATE_BY_SOB = {
  [SalesOrgBrand.ORALB_DE]: oralbCreateAuditState,
  [SalesOrgBrand.JURA]: juraCreateAuditState,
};

export function getAuditState(
  registration: U.Nullable<RegistrationResponseJSON>,
): AuditState | null {
  if (!registration) return null;
  const sob = get(registration, 'salesOrgBrandID');
  const createAuditState = get(CREATE_AUDIT_STATE_BY_SOB, sob);
  return createAuditState ? createAuditState(registration) : null;
}

const I18N_AUDIT_STATUS_BY_SOB = {
  [SalesOrgBrand.ORALB_DE]: ORALB_AUDIT_I18N_MAPPING,
  [SalesOrgBrand.JURA]: JURA_AUDIT_I18N_MAPPING,
};

export function getSobAuditStatusI18n(): Record<string, I18nShape> | undefined {
  return I18N_AUDIT_STATUS_BY_SOB[salesOrgBrandID];
}

const MAP_CAMPAIGN_TO_AUDIT_STATUS_BY_SOB = {
  [SalesOrgBrand.ORALB_DE]: mapOralbCampaignToAuditStatus,
  [SalesOrgBrand.JURA]: mapJuraCampaignToAuditStatus,
};

export const getCampaignByAuditStatus = (): ((
  s: AuditStatus,
) => CampaignStatus) => MAP_CAMPAIGN_TO_AUDIT_STATUS_BY_SOB[salesOrgBrandID];

const STATUS_CHANGE_OPTIONS_BY_SOB = {
  [SalesOrgBrand.ORALB_DE]: ORALB_STATUS_CHANGE_OPTIONS,
  [SalesOrgBrand.JURA]: JURA_STATUS_CHANGE_OPTIONS,
};

export function getSobStatusChangeOptions():
  | O.Omit<AuditStatusOption, 'name'>[]
  | undefined {
  return STATUS_CHANGE_OPTIONS_BY_SOB[salesOrgBrandID];
}
