export default {
  date: {
    container: {
      style: () => `
        margin: 0 0 15px 0;
      `,
    },
  },
};

export const SPACING = [0, 4];
