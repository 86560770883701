import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import { notAuthenticatedOperationTokenSelector } from '@mmw/redux-store-email-verification/stateSelector';
import { RootState as VerifState } from '@mmw/redux-store-email-verification/types';
import { getConsumerProfileService } from '@mmw/services-holder';
import { Action } from '@reduxjs/toolkit';
import { ActionsObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { changePasswordByOperationToken } from '../actions';
import { ChangePasswordStartPayload, RootState } from '../types';

const { logger } = contextualConfig.application;

type Output =
  | ReturnType<typeof changePasswordByOperationToken.success>
  | ReturnType<typeof changePasswordByOperationToken.error>;

const changePasswordByOperationTokenEpic = (
  action$: ActionsObservable<Action & { payload: ChangePasswordStartPayload }>,
  state$: Observable<RootState & VerifState>,
): Observable<Output> =>
  action$.pipe(
    ofType(changePasswordByOperationToken.start.match),
    tap(() => logger.debug('Trying to change password by operation token')),
    withLatestFrom(state$.pipe(map(notAuthenticatedOperationTokenSelector))),
    switchMap(([{ payload }, notAuthenticatedOperationToken]) =>
      from(
        getConsumerProfileService().changeConsumerPasswordByOperationToken(
          {
            ...payload,
            notAuthenticatedOperationToken:
              notAuthenticatedOperationToken as unknown as string,
          },
          // SecurityScopeNames.LEVEL_4_CONSUMERS,
        ),
      ).pipe(
        map(() => changePasswordByOperationToken.success(true, null)),
        catchError(error =>
          of(changePasswordByOperationToken.error(error, null)),
        ),
      ),
    ),
  );

export default changePasswordByOperationTokenEpic;
