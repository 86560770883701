import { DE, EN, FR, NL, PL } from '@mmw/constants-languages';

import DE_FLAG from './de.svg';
import EN_FLAG from './en.svg';
import FR_FLAG from './fr.svg';
import NL_FLAG from './nl.svg';
import PL_FLAG from './pl.svg';

const FLAGS = {
  [EN]: EN_FLAG,
  [FR]: FR_FLAG,
  [DE]: DE_FLAG,
  [NL]: NL_FLAG,
  [PL]: PL_FLAG,
};

export default FLAGS;
