const namespace = 'campaignStatus';

const i18n = {
  REGISTRATION_STATUS: {
    key: `${namespace}.registrationStatus`,
    message: 'Registration status',
  },
  VALID: {
    TITLE: {
      key: `${namespace}.valid`,
      message: 'Valid',
    },
  },
  INVALID_DATA: {
    TITLE: {
      key: `${namespace}.invalidData`,
      message: 'Invalid',
    },
    DEFAULT: {
      DESCRIPTION: {
        key: `${namespace}.invalidData.description`,
        message: 'Your registration is invalid.',
      },
    },
    CASHBACK: {
      DESCRIPTION: {
        key: `${namespace}.invalidData.cashback.description`,
        message:
          'Your registration is invalid and therefore no Cashback amount can be provided for you.',
      },
    },
    GIFT: {
      DESCRIPTION: {
        key: `${namespace}.invalidData.gift.description`,
        message:
          'Your registration is invalid and therefore no Gift can be provided for you.',
      },
    },
  },
  READY_TO_PROCESS: {
    TITLE: {
      key: `${namespace}.readyToProcess`,
      message: 'Ready to process',
    },
    DEFAULT: {
      DESCRIPTION: {
        key: `${namespace}.readyToProcess.description`,
        message: 'Your registration is valid and your will rewarded.',
      },
    },
    CASHBACK: {
      DESCRIPTION: {
        key: `${namespace}.readyToProcess.cashback.description`,
        message:
          'Your registration is valid and your Cashback amount will be transferred.',
      },
    },
    GIFT: {
      DESCRIPTION: {
        key: `${namespace}.readyToProcess.gift.description`,
        message: 'Your registration is valid and your Gift will be sent.',
      },
    },
  },
  TRANSACTION_PROCESSING: {
    TITLE: {
      key: `${namespace}.transactionProcessing`,
      message: 'Processing',
    },
    DEFAULT: {
      DESCRIPTION: {
        key: `${namespace}.transactionProcessing.description`,
        message:
          'Your registration is valid and your prize is in shipping or transfer process.',
      },
    },
    CASHBACK: {
      DESCRIPTION: {
        key: `${namespace}.transactionProcessing.cashback.description`,
        message:
          'Your registration is valid and your Cashback transfer is in process.',
      },
    },
    GIFT: {
      DESCRIPTION: {
        key: `${namespace}.transactionProcessing.gift.description`,
        message:
          'Your registration is valid and your Gift shipping is in process.',
      },
    },
  },
  VERIFYING: {
    TITLE: {
      key: `${namespace}.verifying`,
      message: 'Verifying',
    },
    DESCRIPTION: {
      key: `${namespace}.verifying.description`,
      message:
        'Your registration is currently under review. You will be informed by e-mail about a changed processing status.',
    },
  },
  VERIFIED_INCOMPLETE: {
    TITLE: {
      key: `${namespace}.verifiedIncomplete`,
      message: 'Verified but Incomplete',
    },
    DESCRIPTION: {
      key: `${namespace}.verifiedIncomplete.description`,
      message:
        'You must complete your registration before it can be processed.',
    },
  },
  DATA_INCOMPLETE: {
    TITLE: {
      key: `${namespace}.dataIncomplete`,
      message: 'Data Incomplete',
    },
    DESCRIPTION: {
      key: `${namespace}.dataIncomplete.description`,
      message:
        'You must complete your registration before it can be processed.',
    },
  },
  INVESTIGATION: {
    TITLE: {
      key: `${namespace}.investigation`,
      message: 'Investigation',
    },
    DESCRIPTION: {
      key: `${namespace}.investigation.description`,
      message:
        'You must complete your registration before it can be processed.',
    },
  },
  // FINAL_CHECK: {
  //   TITLE: {
  //     key: `${namespace}.finalCheck`,
  //     message: 'Final check',
  //   },
  //   DESCRIPTION: {
  //     key: `${namespace}.finalCheck.description`,
  //     message: 'Your registration is in final verification stage, you will receive an e-mail with the response soon.',
  //   },
  // },
  CANCELLED: {
    TITLE: {
      key: `${namespace}.cancelled`,
      message: 'Cancelled',
    },
    DESCRIPTION: {
      key: `${namespace}.cancelled.description`,
      message:
        'Your registration is not participating in this campaign. Please check the conditions, or contact us.',
    },
  },
  FINALLY_INVALID: {
    TITLE: {
      key: `${namespace}.finallyInvalid`,
      message: 'Finally invalid',
    },
    DEFAULT: {
      DESCRIPTION: {
        key: `${namespace}.finallyInvalid.description`,
        message: 'Your registration is invalid.',
      },
    },
  },
  FORCE_TO_PROCESS: {
    TITLE: {
      key: `${namespace}.forceToProcess`,
      message: 'Force to process',
    },
    DEFAULT: {
      DESCRIPTION: {
        key: `${namespace}.forceToProcess.description`,
        message: 'Your registration is valid and your will rewarded.',
      },
    },
    CASHBACK: {
      DESCRIPTION: {
        key: `${namespace}.forceToProcess.cashback.description`,
        message:
          'Your registration is valid and your Cashback amount will be transferred.',
      },
    },
    GIFT: {
      DESCRIPTION: {
        key: `${namespace}.forceToProcess.gift.description`,
        message: 'Your registration is valid and your Gift will be sent.',
      },
    },
  },
  TRANSACTION_FINISHED: {
    TITLE: {
      key: `${namespace}.transactionFinished`,
      message: 'Finished',
    },
  },
  TRANSACTION_ERROR: {
    TITLE: {
      key: `${namespace}.transactionError`,
      message: 'Error',
    },
  },
  VALIDATING: {
    TITLE: {
      key: `${namespace}.validating`,
      message: 'Validating',
    },
  },
  VALIDATION_FAILED: {
    TITLE: {
      key: `${namespace}.validationFailed`,
      message: 'Validation failed',
    },
  },
  FORCED_TO_PROCESS_BY_MANUFCT: {
    TITLE: {
      key: `${namespace}.forcedToProcessByManufct`,
      message: 'Forced to process by manufacturer',
    },
  },
  FORCED_BY_LOADER: {
    TITLE: {
      key: `${namespace}.forcedByLoader`,
      message: 'Forced by loader',
    },
  },
  REJECTED: {
    TITLE: {
      key: `${namespace}.rejected`,
      message: 'Rejected',
    },
  },
  FINAL_CHECK: {
    TITLE: {
      key: `${namespace}.finalCheck`,
      message: 'Final check',
    },
  },
  VALID_NO_TRANSACTION: {
    TITLE: {
      key: `${namespace}.validNoTransaction`,
      message: 'Valid, no transaction',
    },
  },
  CLAWBACK: {
    TITLE: {
      key: `${namespace}.clawback`,
      message: 'Clawback',
    },
  },
  QUEUE: {
    TITLE: {
      key: `${namespace}.queue`,
      message: 'Queue',
    },
  },
};

export const {
  REGISTRATION_STATUS,
  INVALID_DATA,
  FORCE_TO_PROCESS,
  READY_TO_PROCESS,
  TRANSACTION_PROCESSING,
  VERIFYING,
  // FINAL_CHECK,
  VERIFIED_INCOMPLETE,
  DATA_INCOMPLETE,
  CANCELLED,
  INVESTIGATION,
  FINALLY_INVALID,
  QUEUE,
  FORCED_TO_PROCESS_BY_MANUFCT,
  FORCED_BY_LOADER,
  TRANSACTION_FINISHED,
  TRANSACTION_ERROR,
  VALIDATION_FAILED,
  CLAWBACK,
  REJECTED,
  VALIDATING,
  VALID_NO_TRANSACTION,
  FINAL_CHECK,
  VALID,
} = i18n;

export default i18n;
