import { size } from '@mmw/ui-theme/modifiers';
import { StyledProps } from '@mmw/ui-theme/types';
import { mergeStyle } from '@mmw/ui-theme/utils';
import frameworkStyle from '@mmw/ui-web-elements-typography/theme';
import modifiers from '@mmw/ui-web-elements-typography/theme/modifiers';

export const sizes = {
  h1: {
    fontSize: '6rem',
  },
  h2: {
    fontSize: '3.75rem',
  },
  h3: {
    fontSize: '3rem',
  },
  h4: {
    fontSize: '2rem',
  },
  h5: {
    fontSize: '1.5rem',
  },
  h6: {
    fontSize: '1.25rem',
  },
  body1: {
    fontSize: '1rem',
  },
  body2: {
    fontSize: '0.975rem',
  },
  caption1: {
    fontSize: '0.950rem',
  },
  caption2: {
    fontSize: '0.875rem',
  },
};

const style = ({ theme }: StyledProps<{}>) => `
  font-size: 1rem;
  font-family: ${theme.fonts.fontFamily};
  color: ${theme.colors.pallet.secondary};
  margin: 0;
`;

const sizeModifiers = size(sizes);
const theme = {
  style,
  modifiers: {
    ...modifiers,
    ...sizeModifiers,
  },
};

export default mergeStyle(frameworkStyle, theme);
