import { useNavigateToDashboard } from '@manufacturer/navigation/hooks';
import { isDevelopment } from '@mmw/environment';
import { authenticateStartAction } from '@mmw/redux-store-auth-api-authentication/actions';
import delay from 'lodash/delay';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Action } from 'redux';
import { U } from 'ts-toolbelt';

const { REACT_APP_RC_USER: USERNAME, REACT_APP_RC_PASSWORD: PASSWORD } =
  process.env;

function createLoginAction(username: string, password: string) {
  return authenticateStartAction(username, password);
}

function hasCredentials(
  username: U.Nullable<string>,
  password: U.Nullable<string>,
) {
  return !isEmpty(username) && !isEmpty(password);
}

export function createAutoLoginAction(
  username?: string,
  password?: string,
): U.Nullable<Action<string>> {
  if (!isDevelopment()) return null;
  if (
    hasCredentials(USERNAME, PASSWORD) ||
    hasCredentials(username, password)
  ) {
    return createLoginAction(
      <string>USERNAME || (username as string),
      <string>PASSWORD || (password as string),
    );
  }
  return null;
}

function useAutoDevLogin(username?: string, password?: string): void {
  const action = createAutoLoginAction(username, password);
  const dispatch = useDispatch();
  const navigateToDashboard = useNavigateToDashboard();

  useEffect(() => {
    if (action) dispatch(action);
    delay(navigateToDashboard, 1000);
  }, [action, dispatch, navigateToDashboard]);
}

interface AutoDevLoginProps {
  username?: string;
  password?: string;
  onSuccess?: () => void;
}
export const AutoDevLogin: React.FC<AutoDevLoginProps> & {
  enabled: boolean;
} = ({ username, password, onSuccess }: AutoDevLoginProps) => {
  useAutoDevLogin(username, password);
  useEffect(() => {
    if (onSuccess) {
      delay(onSuccess, 1000);
    }
  }, [onSuccess]);
  return null;
};

AutoDevLogin.defaultProps = {
  // username: undefined,
  // password: undefined,
  onSuccess: () => null,
};

AutoDevLogin.enabled = false;
