import Carousel from '@ui-system/common-carousel';
import { Container } from '@ui-system/common-container';
import DatePicker from '@ui-system/common-datepicker';
import Grid from '@ui-system/common-grid';
import { Icon } from '@ui-system/common-icon';
import { Image } from '@ui-system/common-image';
import Link from '@ui-system/common-link';
import List from '@ui-system/common-list';
import Page from '@ui-system/common-page';
import { Paginator } from '@ui-system/common-paginator';
import Rating from '@ui-system/common-rating';
import { Screen } from '@ui-system/common-screen';
import { Scroll } from '@ui-system/common-scroll';
import Skeleton from '@ui-system/common-skeleton';
import { Video } from '@ui-system/common-video';
import { Components } from '@ui-system/interfaces';
import Avatar from '@ui-system/material-avatar';
import Button from '@ui-system/material-button';
import Card from '@ui-system/material-card';
import Chip from '@ui-system/material-chip';
import Divider from '@ui-system/material-divider';
import { Form } from '@ui-system/material-form';
import Menu from '@ui-system/material-menu';
import Modal from '@ui-system/material-modal';
import Popover from '@ui-system/material-popover';
import Spinner from '@ui-system/material-spinner';
import Stepper from '@ui-system/material-stepper';
import TabBar from '@ui-system/material-tab-bar';
import Table from '@ui-system/material-table';
import Typography, * as TypographySet from '@ui-system/material-typography';

import ThemeProvider from './theme';

export const components: Components = {
  ...TypographySet,
  Container,
  Typography,
  Button,
  Image,
  Grid,
  Form,
  Icon,
  Carousel,
  Card,
  Divider,
  Page,
  Modal,
  Rating,
  Avatar,
  Spinner,
  ThemeProvider,
  Screen,
  List,
  Link,
  Table,
  Skeleton,
  DatePicker,
  TabBar,
  SwitchSelector: () => null,
  Paginator,
  Menu,
  Video,
  Scroll,
  Popover,
  Chip,
  Stepper,
};
