import { forEach, isEqual } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import {
  setFieldUnwatchedAction,
  setFieldWatchedAction,
} from '../../store/actions';
import { FieldPath } from '../../types';
import useFormId from '../useFormId';

function useWatchFields(fieldPaths: FieldPath[]) {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const formId = useFormId();

  const watchAll = useCallback(() => {
    forEach(fieldPaths, fieldPath => {
      dispatch(setFieldWatchedAction(formId, fieldPath));
    });
  }, [dispatch, fieldPaths, formId]);

  const unwatchAll = useCallback(() => {
    forEach(fieldPaths, fieldPath => {
      dispatch(setFieldUnwatchedAction(formId, fieldPath));
    });
  }, [dispatch, fieldPaths, formId]);

  useEffect(() => {
    if (!ref || !isEqual(fieldPaths, ref.current)) {
      watchAll();
      ref.current = fieldPaths;
    }
    return () => {
      unwatchAll();
      ref.current = null;
    };
  }, [fieldPaths, unwatchAll, watchAll]);
}

export default useWatchFields;
