import { getSalesOrgBrandId } from '@mmw/contextual-config-utils';
import { isDevelopment } from '@mmw/environment';
import registerApiLogger from '@mmw/logging-api-logger';
import {
  loggedUseridSelector,
  loggedUserOrgunitIDSelector,
} from '@mmw/redux-store-auth-api-authentication/stateSelector';
import { languageSelector } from '@mmw/redux-store-i18n/stateSelector';
import { getClientFingerprintService } from '@mmw/services-holder';
import { Store } from 'redux';
import { createSelector } from 'reselect';

type Options = {
  store: Store<any, any>;
  versionDescriptionObject?: Record<string, any>;
  isInDevMode?: boolean;
};

const configureLogging = async ({
  store,
  versionDescriptionObject,
  isInDevMode,
}: Options) => {
  if (isInDevMode) return;

  const deviceId = await getClientFingerprintService().getClientFingerprint();

  const applicationLogLabelsSelector = createSelector(
    languageSelector,
    loggedUseridSelector,
    loggedUserOrgunitIDSelector,
    (language, userid, orgunitID) => ({
      userid,
      orgunitID,
      language,
      salesOrgBrandID: getSalesOrgBrandId(),
      ...versionDescriptionObject,
    }),
  );

  if (!isDevelopment()) {
    registerApiLogger(() => ({
      ...applicationLogLabelsSelector(store.getState()),
      deviceId,
    }));
  }
};

export default configureLogging;
