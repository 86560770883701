import {
  AEG,
  JURA,
  MIELE,
  ORALB_DE,
  REC,
} from '@mmw/constants-salesorgbrand-ids';
import { getSalesOrgBrandId } from '@mmw/contextual-config-utils';
import size from 'lodash/size';

enum Routes {
  HOME = '/',
  LOGIN = '/login',
  AUDIT = '/audit/:id',
  AUDITS = '/audits',
  STORE = '/store/:id',
  STORES = '/stores',
  PROFILE = '/profile',
  TERMS = '/terms',
  ABOUT = '/about',
  IBV = '/ibv/:id',
  IBVS = '/ibvs',
  MARKETING_BUDGETS = '/marketing-budgets',
  MARKETING_BUDGET = '/marketing-budget/:id',
  MASS_MARKETING_BUDGET = '/mass-marketing-budget',
  PROJECTS = '/projects',
  REGISTRATIONS = '/registrations',
  REGISTRATION = '/registration/:id',
  PARTNERS = '/partners',
  PARTNER = '/partner/:id',
  ONLINE_REPORTING = '/online-reporting',
  RECOVER_PASSWORD_ROOT = '/recover-password',
  RECOVER_PASSWORD = '/recover-password/:uuid',
  DISTRIBUTION_CHANNELS = '/distribution-channels',
  USERS = '/users',
  CAMPAIGNS = '/campaigns',
  CAMPAIGN = '/campaign',
  PRODUCTS = '/products',
  NEW_ACCOUNT = '/new-account',
  REC_COINS_STATEMENT = '/recoins/:id',
}

export const HOME_ROUTE = {
  [JURA]: Routes.STORES,
  [ORALB_DE]: Routes.AUDITS,
  [REC]: Routes.MARKETING_BUDGETS,
};

export function getHomeRouteBySob(): Routes {
  return HOME_ROUTE[getSalesOrgBrandId()];
}

export const AVAILABLE_ROUTES_BY_SOB = {
  [JURA]: [
    Routes.AUDITS,
    // Routes.AUDIT,
    // Routes.HOME,
    Routes.STORE,
    Routes.STORES,
    Routes.IBV,
    Routes.IBVS,
    Routes.ONLINE_REPORTING,
    Routes.CAMPAIGNS,
    Routes.CAMPAIGN,
  ],
  [ORALB_DE]: [Routes.AUDIT, Routes.AUDITS],
  [REC]: [Routes.MARKETING_BUDGET, Routes.MARKETING_BUDGETS],
  [AEG]: [],
  [-1]: [
    Routes.STORE,
    Routes.STORES,
    Routes.ONLINE_REPORTING,
    Routes.DISTRIBUTION_CHANNELS,
    Routes.USERS,
    Routes.CAMPAIGNS,
  ],
  [MIELE]: [
    Routes.HOME,
    Routes.REGISTRATIONS,
    Routes.PRODUCTS,
    Routes.STORE,
    Routes.STORES,
    Routes.ONLINE_REPORTING,
    Routes.DISTRIBUTION_CHANNELS,
    Routes.USERS,
    Routes.CAMPAIGNS,
  ],
};

// export const REGISTER = {
//   path: Routes.REGISTER,
//   redirectAction: (project: ProjectJSON) =>
//     push({
//       pathname: Routes.REGISTER.replace(':id', `${project.id}`),
//       state: {
//         project,
//       },
//     }),
// };

export function getAvailableRoutesToSob(): Routes[] {
  return AVAILABLE_ROUTES_BY_SOB[getSalesOrgBrandId()];
}

export function getAvailableRoutesQuantityToSob(): number {
  return size(getAvailableRoutesToSob());
}

export function getHasMoreThanoneAvailableRouteToSob(): boolean {
  return getAvailableRoutesQuantityToSob() > 1;
}

export function canSobNavigateToRoute(route: Routes): boolean {
  const availableRoutes = getAvailableRoutesToSob();
  if (!availableRoutes) return false;
  return Object.values(availableRoutes).includes(route);
}

export function canSobNavigateToDashboard(): boolean {
  return canSobNavigateToRoute(Routes.HOME);
}
export function canSobNavigateToAudits(): boolean {
  return canSobNavigateToRoute(Routes.AUDITS);
}
export function canSobNavigateToStores(): boolean {
  return canSobNavigateToRoute(Routes.STORES);
}
export function canSobNavigateToStore(): boolean {
  return canSobNavigateToRoute(Routes.STORE);
}

export function canSobNavigateToIbvs(): boolean {
  return canSobNavigateToRoute(Routes.IBVS);
}

export function canSobNavigateToMarketingBudgets(): boolean {
  return canSobNavigateToRoute(Routes.MARKETING_BUDGETS);
}

export function canSobNavigateToMarketingBudget(): boolean {
  return canSobNavigateToRoute(Routes.MARKETING_BUDGET);
}

export function canSobNavigateToOnlineReporting(): boolean {
  return canSobNavigateToRoute(Routes.ONLINE_REPORTING);
}

export function canSobNavigateToDistributionChannels(): boolean {
  return canSobNavigateToRoute(Routes.DISTRIBUTION_CHANNELS);
}

export function canSobNavigateToUsers(): boolean {
  return canSobNavigateToRoute(Routes.USERS);
}

export function canSobNavigateToCampaigns(): boolean {
  return canSobNavigateToRoute(Routes.CAMPAIGNS);
}

export function canSobNavigateToRegistrations(): boolean {
  return canSobNavigateToRoute(Routes.REGISTRATIONS);
}

export function canSobNavigateToProducts(): boolean {
  return canSobNavigateToRoute(Routes.PRODUCTS);
}

export default Routes;
