import {
  createDispatchHook,
  createSelectorHook,
} from '@redux-basic-module/hooks-utils';
import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';

import {
  changePasswordByOperationToken,
  getConsumerProfileData,
  resetSuccessFlagsAction,
  saveConsumerProfileData,
} from './actions';
import {
  changePasswordSuccessStatusSelector,
  consumerProfileDataSelector,
  errorSelector,
  loadingSelector,
  operationTypeSelector,
  successSelector,
} from './stateSelector';

export const useConsumerProfileData = createSelectorHook(
  consumerProfileDataSelector,
);

export const useIsLoading = createSelectorHook(loadingSelector);

export const useError = createSelectorHook(errorSelector);

export const useSuccess = createSelectorHook(successSelector);

export const usePasswordChangeSuccessStatus = createSelectorHook(
  changePasswordSuccessStatusSelector,
);

export const useOperationType = createSelectorHook(operationTypeSelector);

export const useGetConsumerProfileDataStart = createDispatchHook<
  ReturnType<typeof getConsumerProfileData.start>
>(getConsumerProfileData.start);

export const useSaveConsumerProfileDataStart = createDispatchHook<
  ReturnType<typeof saveConsumerProfileData.start>
>(saveConsumerProfileData.start);

export const useChangeConsumerPasswordStart = createDispatchHook<
  ReturnType<typeof changePasswordByOperationToken.start>
>(changePasswordByOperationToken.start);

export const useResetSuccessFlag = createDispatchHook<
  typeof resetSuccessFlagsAction
>(resetSuccessFlagsAction);

export const useLoadConsumerProfileDataOnMount = (): void => {
  const getConsumerProfileDataStart = useGetConsumerProfileDataStart();
  const consumerData = useConsumerProfileData();
  const isConsumerDataEmpty = isEmpty(consumerData);
  useEffect(() => {
    if (isConsumerDataEmpty) {
      getConsumerProfileDataStart(null);
    }
  }, [getConsumerProfileDataStart]);
};
