import styled from 'styled-components';

const MenuDrawer = styled.ul`
  display: none;
  padding: 55px 0 0 0;
  position: absolute;
  width: 100%;
  background-color: #fff;
  ${({ isVisible }) =>
    isVisible
      ? `
    display: flex;
    margin: 0;
    align-items: center;
    flex-direction: column;
  `
      : ''}

  > li {
    justify-content: flex-start;
    flex-direction: column;
    > ul {
      width: 100%;
      position: unset;
      padding: 0;
      border: none;
      border-bottom: 0;
      > li {
        &:first-child {
          border-top: 1px solid #eee;
        }
        padding-bottom: 0;
        padding-top: 0;
        height: 48px;
        > a {
          padding-left: 45px !important;
        }
      }
    }
  }
`;

export default MenuDrawer;
