import { AuditStatusCard } from '@audit/audit-status-card';
import I18N from '@audit/constants-i18n';
import {
  AuditStatus,
  AuditStatusOption,
  ChangeStatusOption,
  getAuditIcon,
  KnownAuditStatus,
} from '@audit/utils';
import {
  CreateIssueJSON,
  IssueResolutionMode,
  IssueStatus,
} from '@issue/interfaces';
import { Caption2 } from '@mmw/ui-web-elements-typography';
import { LeaveRoutePrompt } from '@mmw/ui-web-libraries-react-router';
import { EMPTY_ARRAY } from '@shared-utils/array';
import { Color } from '@ui-system/css';
import { createMock } from '@ui-system/mocks/createMock';
import UI from '@ui-system/ui';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import { useCallback } from 'react';
import * as React from 'react';
import { F } from 'ts-toolbelt';

interface Props {
  status: AuditStatus;
  options: AuditStatusOption[];
  onChange: F.Function<[AuditStatus]>;
  createdIssuesByUser: CreateIssueJSON[];
  loading: boolean;
  iconColor?: Color;
}

export const ChangeAuditStatusView: React.FC<Props> = ({
  status,
  options,
  onChange,
  createdIssuesByUser,
  loading,
  iconColor,
}: Props) => {
  const showCorrections =
    find(
      options,
      o => o.canCreateApprovalIssues || o.requiresToCreateCorrectionIssues,
    ) != null && !isEmpty(createdIssuesByUser);
  const corrections = filter(
    createdIssuesByUser,
    issue =>
      issue.initialResolutionMode !== IssueResolutionMode.RESOLVED &&
      (issue.initialStatus === IssueStatus.TODO || !issue.initialStatus),
  );
  const correctionsSize = sumBy(
    corrections,
    issue => issue.data.configuration.amountOfActionsRequiredToResolve || 1,
  );
  const approvals = filter(
    createdIssuesByUser,
    issue =>
      issue.initialResolutionMode === IssueResolutionMode.RESOLVED &&
      issue.initialStatus === IssueStatus.CLOSED,
  );
  const approvalsSize = sumBy(
    approvals,
    issue => issue.data.configuration.amountOfActionsRequiredToResolve || 1,
  );
  const isOptionEnabled = useCallback(
    (option: AuditStatusOption): boolean => {
      const {
        requiresToCreateCorrectionIssues,
        canCreateApprovalIssues,
        canCreateCorrectionIssues,
      } = option;
      if (requiresToCreateCorrectionIssues && correctionsSize === 0) {
        return false;
      }
      if (!canCreateApprovalIssues && approvalsSize > 0) {
        return false;
      }
      if (!requiresToCreateCorrectionIssues && correctionsSize > 0) {
        return false;
      }
      return !(!canCreateCorrectionIssues && correctionsSize > 0);
    },
    [approvalsSize, correctionsSize],
  );
  return (
    <UI.Container p="2, 0" direction="column" gap={3}>
      <LeaveRoutePrompt
        shouldPrompt={!isEmpty(createdIssuesByUser)}
        i18n={I18N.AUDIT.LEAVE_AUDIT_WITHOUT_SAVING}
      />
      <UI.Container>
        <UI.H4 i18n={I18N.AUDIT.CHANGE_STATUS} />
      </UI.Container>
      <UI.Container align="center">
        <AuditStatusCard status={status} loading={loading} />
        <UI.Container m="0, 2">
          <UI.Icon name="next" size={15} color="primary" />
        </UI.Container>
        <UI.Container gap={2}>
          {!loading &&
            map(options, option => (
              <UI.Button
                onClick={() => onChange(option.value)}
                key={option.value}
                variant="outlined"
                textModifiers="black"
                accessoryRight={getAuditIcon(option.value)}
                iconSize={20}
                iconColor={iconColor}
                disabled={!isOptionEnabled(option)}
                loading={loading}
              >
                {option.name}
              </UI.Button>
            ))}
          {showCorrections && correctionsSize > 0 && (
            <Caption2
              i18n={I18N.AUDIT.PENDING_CORRECTIONS_SELECTED}
              modifiers="alert"
              values={{ count: correctionsSize }}
            />
          )}
          {showCorrections && approvalsSize > 0 && (
            <Caption2
              i18n={I18N.AUDIT.APPROVALS_SELECTED}
              modifiers="success"
              values={{ count: approvalsSize }}
            />
          )}
        </UI.Container>
      </UI.Container>
    </UI.Container>
  );
};

ChangeAuditStatusView.defaultProps = {
  iconColor: 'primary',
};

// Export a ready to render mock to catalog later.
export const ChangeAuditStatusMock = createMock<Props>(ChangeAuditStatusView, {
  status: KnownAuditStatus.TRANSMITTED,
  loading: false,
  createdIssuesByUser: EMPTY_ARRAY,
  options: [] as unknown as ChangeStatusOption<AuditStatus>[],
  // eslint-disable-next-line no-alert
  onChange: (() => alert('hey') as unknown) as (newStatus: AuditStatus) => void,
});
