import { connect as formikConnect, getIn } from '@mmw/ui-libraries-formik-v2';
import { compose, HOC, mapProps } from 'recompose';

import { WithFieldPathProps } from '../types';

const withErrorMessage: HOC<unknown, WithFieldPathProps> = compose(
  formikConnect,
  mapProps(({ formik: { errors }, fieldPath, defaultValue, ...rest }) => ({
    ...rest,
    errorMessage: getIn(errors, fieldPath),
    fieldPath,
  })),
);

export default withErrorMessage;
