export default {
  content: {
    style: () => `
      display: flex;
      padding: 15px 20px;
      flex-wrap: wrap;
      justify-content: flex-start;
    `,
  },
  header: {
    style: ({ theme }) => `
      display: flex;
      ${theme.media.IE11`height: 40px`};
      background-color: ${theme.colors.pallet.background};
      justify-content: space-between;
      align-items: center;
      padding: 10px 5px;
    `,
  },
  tooltip: {
    style: ({ theme }) => `
      left: 10px;
      display: flex;
      flex-direction: column;
      min-height: 160px;
      width: 385px;
      background: ${theme.colors.pallet.light};
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
      ${theme.media.phone`
        width: 300px;
      `}
    `,
  },
  text: {
    style: () => '',
  },
  button: {
    style: ({ theme }) => `
    fill: ${theme.colors.pallet.secondary};
    background: none;
    padding: 0;
  `,
    icon: {
      style: ({ theme }) => `      
      fill: ${theme.colors.pallet.basic};
      padding: 0;
    `,
    },
  },
  icon: {
    style: ({ theme }) => `
      fill: ${theme.colors.pallet.secondary};      
    `,
  },
};
