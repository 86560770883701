import { AuditStatusOption } from '@audit/utils';
import { CampaignStatus } from '@mmw/constants-campaign-status';
import { I18nShape } from '@mmw/constants-i18n';
import {
  APPROVED,
  NEEDS_REVISION,
  OPEN,
  REJECTED,
  TRANSMITTED,
} from '@mmw/constants-i18n-audit-campaign-status';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@mmw/constants-utils';
import { O } from 'ts-toolbelt';

import { JuraAuditState, JuraAuditStatus } from './types';

export const JURA_AUDIT_I18N_MAPPING: Record<string, I18nShape> = {
  OPEN,
  TRANSMITTED,
  NEEDS_REVISION,
  REJECTED,
  APPROVED,
};

export const JURA_CAMPAIGN_STATUS_MAPPING: Partial<
  Record<CampaignStatus, JuraAuditStatus>
> = {
  // [JuraAuditStatus.OPEN]: CampaignStatus.INCOMPLETE_DATA, // CampaignStatus.INVALID_DATA,
  [CampaignStatus.VERIFYING]: JuraAuditStatus.TRANSMITTED,
  [CampaignStatus.VALIDATION_FAILED]: JuraAuditStatus.NEEDS_REVISION,
  [CampaignStatus.REJECTED]: JuraAuditStatus.REJECTED,
  [CampaignStatus.TRANSACTION_FINISHED]: JuraAuditStatus.APPROVED,
  [CampaignStatus.INVALID_DATA]: JuraAuditStatus.OPEN,
  [CampaignStatus.INCOMPLETE_DATA]: JuraAuditStatus.OPEN,
};
export const JURA_CAMPAIGN_STATUS_KEYS = Object.keys(
  JURA_CAMPAIGN_STATUS_MAPPING,
) as JuraAuditStatus[];

export const JURA_STATUS_COLOR_MAPPING = {
  [JuraAuditStatus.OPEN]: '#fd5c40',
  [JuraAuditStatus.APPROVED]: '#0a6645',
  [JuraAuditStatus.REJECTED]: '#bd2812',
  [JuraAuditStatus.TRANSMITTED]: '#fd9a35',
  [JuraAuditStatus.NEEDS_REVISION]: '#fd9a35',
};

export const JURA_ICON_MAPPING = {
  [JuraAuditStatus.OPEN]: 'check',
  [JuraAuditStatus.APPROVED]: 'check',
  [JuraAuditStatus.REJECTED]: 'close',
  [JuraAuditStatus.TRANSMITTED]: 'check',
  [JuraAuditStatus.NEEDS_REVISION]: 'edit',
};

export const JURA_ALLOWED_STATUS_TO_CREATE_ISSUES = [
  JuraAuditStatus.OPEN,
  JuraAuditStatus.NEEDS_REVISION,
];

export const JURA_STATUS_CHANGE_OPTIONS: O.Omit<AuditStatusOption, 'name'>[] = [
  {
    value: JuraAuditStatus.OPEN,
  },
  {
    value: JuraAuditStatus.APPROVED,
    canCreateApprovalIssues: true,
  },
  {
    value: JuraAuditStatus.REJECTED,
    canCreateCorrectionIssues: true,
  },
  {
    value: JuraAuditStatus.TRANSMITTED,
  },
  {
    value: JuraAuditStatus.NEEDS_REVISION,
    requiresToCreateCorrectionIssues: true,
    canCreateCorrectionIssues: true,
    canCreateApprovalIssues: true,
  },
];

export const JURA_EMPTY_AUDIT_STATE: JuraAuditState = {
  registration: undefined,
  id: null,
  created: false,
  status: null,
  canOrderProducts: false,
  canCompleteAudit: false,
  canUploadSellout: false,
  canShowFilesCard: false,
  canStartAudit: false,
  isRejected: false,
  allowedStatusTransitions: EMPTY_ARRAY,
  allowedStatusToCreateIssues: JURA_ALLOWED_STATUS_TO_CREATE_ISSUES,
  createIssuesOnTransition: EMPTY_OBJECT,
  canCreateIssues: false,
};
