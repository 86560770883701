// elements
// utils
import { use } from '@mmw/ui-theme/utils';
import dividedButton from '@mmw/ui-web-blocks-divided-button/theme';
import imagesCarousel from '@mmw/ui-web-blocks-images-carousel/theme';
import itemsCarousel from '@mmw/ui-web-blocks-items-carousel/theme';
import navBar from '@mmw/ui-web-blocks-nav-bar/theme';
import paginator from '@mmw/ui-web-blocks-paginator/theme';
import sideBar from '@mmw/ui-web-blocks-side-bar/theme';
import spinner from '@mmw/ui-web-blocks-spinner/theme';
import typeahead from '@mmw/ui-web-blocks-typeahead/theme';
import breadcrumb from '@mmw/ui-web-elements-breadcrumb/theme';
import checkbox from '@mmw/ui-web-elements-checkbox/theme';
import icon from '@mmw/ui-web-elements-icon/theme';
import input from '@mmw/ui-web-elements-input/theme';
import radio from '@mmw/ui-web-elements-radio/theme';
import select from '@mmw/ui-web-elements-select/theme';
import typography from '@mmw/ui-web-elements-typography/theme';
// blocks
import button from '@mmw/ui-web-materials-button/theme';
import dateInput from '@mmw/ui-web-materials-date-input/theme';
import dropdownList from '@mmw/ui-web-materials-dropdown-list/theme';
import formField from '@mmw/ui-web-materials-form-field/theme';
import inputGroup from '@mmw/ui-web-materials-input-group/theme';
import link from '@mmw/ui-web-materials-link/theme';
import pageTitle from '@mmw/ui-web-materials-page-title/theme';
import card from '@mmw/ui-web-structures-card/theme';
import expansiveCard from '@mmw/ui-web-structures-expansive-card/theme';
// structures
import grid from '@mmw/ui-web-structures-grid/theme';
import mobileBurgerMenu from '@mmw/ui-web-structures-mobile-burger-menu/theme';
import page from '@mmw/ui-web-structures-page/theme';
import paper from '@mmw/ui-web-structures-paper/theme';
import table from '@mmw/ui-web-structures-table/theme';
import tabs from '@mmw/ui-web-structures-tabs/theme';
import assets from '@mmw/web-assets';

import colors from './colors';
import fonts from './fonts';
import form from './form';
import media from './media';
// mmw
import mmw from './mmw';
import spacing from './spacing';

const theme = {
  typography,
  icon,
  checkbox,
  select,
  table,
  pageTitle,
  input,
  radio,
  breadcrumb,
  button,
  dividedButton,
  dateInput,
  card,
  expansiveCard,
  link,
  sideBar,
  inputGroup,
  paginator,
  formField,
  navBar,
  grid,
  tabs,
  page,
  colors,
  fonts,
  media,
  use,
  assets,
  itemsCarousel,
  spinner,
  imagesCarousel,
  mmw,
  paper,
  typeahead,
  spacing,
  mobileBurgerMenu,
  dropdownList,
  form,
};

export type ThemeType = typeof theme;

export type StyledProps<P = unknown> = P & {
  theme?: ThemeType;
};

export default theme;
