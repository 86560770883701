import { I18nShape } from '@mmw/constants-i18n';
import { AUTH_TAN_REQUEST } from '@mmw/constants-i18n-authentication';
import { ContainerProps } from '@ui-system/interfaces-container';
import React from 'react';
import { F } from 'ts-toolbelt';

import Action from './Action';

const { RESEND_CODE } = AUTH_TAN_REQUEST;

interface Props extends ContainerProps {
  i18n?: I18nShape;
  textModifiers?: string;
  loading?: boolean;
  onClick: F.Function;
}

const ResendTanCode: React.FC<Props> = ({
  i18n,
  useLink,
  linkPrefix,
  linkSuffix,
  textModifiers,
  loading,
  onClick,
  ...props
}: Props) => (
  <Action
    onClick={onClick}
    i18n={i18n}
    loading={loading}
    useLink={useLink}
    linkPrefix={linkPrefix}
    linkSuffix={linkSuffix}
    textModifiers={textModifiers}
    {...props}
  />
);

ResendTanCode.defaultProps = {
  i18n: RESEND_CODE,
};

export default ResendTanCode;
