import { ConsumerBankDataJSON } from '@mmw/services-core-consumer-bank-account/types';
import { Action } from '@reduxjs/toolkit';
import { U } from 'ts-toolbelt';

export const RESET_STATUS = '@bankAccount/RESET_STATUS';
export const RESET_DATA = '@bankAccount/RESET_DATA';

export type BankDataSuccessPayload = U.Nullable<ConsumerBankDataJSON[]>;

export type ResetAction = {
  type: '@bankAccount/RESET';
};

export type State = {
  data: U.Nullable<ConsumerBankDataJSON[]>;
  loading: boolean;
  error: Error | null;
  requestAttempted: boolean;
  success: boolean;
  updateDataAttempted: boolean;
};

export const NAMESPACE = 'consumerBankAccount';

export type RootState = {
  consumerBankAccount: State;
};

export type Reducer = (state: State, action: Action) => State;
