import { RecoverPasswordByEmail } from '@auth/view-recover-password';
import { useSubmitPasswordSuccess } from '@consumer/hooks';
import { useNavigateToAuthentication } from '@consumer/navigation';
import { usePasswordChangeSuccessStatus } from '@consumer/profile-redux-store/hooks';
import { LoginContentProps } from '@manufacturer/login-ui';
import { useNavigateToLogin } from '@mmw/common-navigation/hooks';
import { getApplicationId } from '@mmw/contextual-config';
import Page from '@mmw/ui-page';
import { ResourcesContextProvider } from '@mmw/ui-providers-resources';
import { ReCaptchaV3ContextProvider } from '@recaptcha/v3';
import { useAssets } from '@ui-system/assets';
import { css } from '@ui-system/css';
import { Style } from '@ui-system/interfaces';
import UI from '@ui-system/ui';
import React from 'react';

interface LoginPageProps extends LoginContentProps {
  contentStyle?: Style | Style[];
}

const style = css`
  justify-content: center;
`;

const RecoverPasswordPage: React.FC<LoginPageProps> = ({
  contentStyle = style,
  ...props
}: LoginPageProps) => {
  const goBack = useNavigateToLogin();
  const navigateToAuth = useNavigateToAuthentication();
  const onSubmitNewPassword = useSubmitPasswordSuccess();
  const passwordChangedWithSuccess = usePasswordChangeSuccessStatus();
  const assets = useAssets();

  return (
    <Page
      backgroundImageUrl={assets?.loginBackgroundImage}
      backgroundSize="cover"
    >
      <Page.Content contentStyle={contentStyle}>
        <ReCaptchaV3ContextProvider>
          <UI.Container justify="center">
            <ResourcesContextProvider prefixKey={getApplicationId()}>
              <RecoverPasswordByEmail
                onCancel={goBack}
                onSuccess={navigateToAuth}
                onSubmitNewPassword={onSubmitNewPassword}
                successStatus={passwordChangedWithSuccess}
                {...props}
              />
            </ResourcesContextProvider>
          </UI.Container>
        </ReCaptchaV3ContextProvider>
      </Page.Content>
    </Page>
  );
};

export default RecoverPasswordPage;
