import { StyledProps } from '@mmw/ui-theme/types';
import frameworkTheme from '@mmw/ui-web-blocks-divided-button/theme';
import dividerStyle from '@mmw/ui-web-blocks-divided-button/theme/dividerStyle';
import style from '@mmw/ui-web-blocks-divided-button/theme/style';
import textStyle from '@mmw/ui-web-blocks-divided-button/theme/textStyle';
import textModifiers from '@mmw/ui-web-elements-typography/theme/modifiers';

export const modifiers = {
  secondary: ({ theme }: StyledProps<unknown>) => `
    background: ${theme.colors.pallet.basic}
`,
  background: ({ theme }: StyledProps<unknown>) => `
    background-color: ${theme.colors.pallet.background};
  `,
};

const buttonStyle = ({ theme }: StyledProps<unknown>) => `
  ${style({ theme })}
  width: auto;
  min-height: 40px;
  ${theme.media.IE11`
    height: 40px;
    min-width: 250px;
  `}
  ${theme.media.phone`
     width: 100%;
  `};
`;

export default {
  ...frameworkTheme,
  style: buttonStyle,
  divider: {
    style: dividerStyle,
    modifiers,
  },
  text: {
    style: ({ theme }: StyledProps<unknown>) => `
      ${textStyle({ theme })}
      color: ${theme.colors.pallet.light};
    `,
    modifiers: textModifiers,
  },
  modifiers,
};
