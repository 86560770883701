import { UserLoginConfiguration } from '@mmw/services-auth-api-authentication/types';
import { F } from 'ts-toolbelt';

import {
  GET_LOGIN_CONFIG_BY_EMAIL_OR_USERID_ERROR,
  GET_LOGIN_CONFIG_BY_EMAIL_OR_USERID_START,
  GET_LOGIN_CONFIG_BY_EMAIL_OR_USERID_SUCCESS,
  GetLoginConfigByEmailOrUseridErrorAction,
  GetLoginConfigByEmailOrUseridStartAction,
  GetLoginConfigByEmailOrUseridSuccessAction,
} from './types';

export const getLoginConfigByEmailOrUseridStartAction = (
  emailOrUserid: string,
  onSuccess?: F.Function<[UserLoginConfiguration]>,
): GetLoginConfigByEmailOrUseridStartAction => ({
  type: GET_LOGIN_CONFIG_BY_EMAIL_OR_USERID_START,

  payload: {
    emailOrUserid,
    onSuccess,
  },
});

export const getLoginConfigByEmailOrUseridSuccessAction = (
  config: UserLoginConfiguration,
): GetLoginConfigByEmailOrUseridSuccessAction => ({
  type: GET_LOGIN_CONFIG_BY_EMAIL_OR_USERID_SUCCESS,

  payload: {
    config,
  },
});

export const getLoginConfigByEmailOrUseridErrorAction = (
  error: Error,
): GetLoginConfigByEmailOrUseridErrorAction => ({
  type: GET_LOGIN_CONFIG_BY_EMAIL_OR_USERID_ERROR,

  payload: {
    error,
  },
});
