import { I18nShape } from '@mmw/constants-i18n';
import { COUNTRIES as COUNTRIES_I18N } from '@mmw/constants-i18n-fields';
import { useAvailableCountries } from '@mmw/redux-store-common-available-countries/hooks';
import { useSetFieldValue } from '@mmw/redux-store-fast-form/hooks';
import { SelectFormField as FastFormSelectFormField } from '@mmw/ui-hybrid-blocks-fast-form';
import { FastFormSelectProps } from '@mmw/ui-web-libraries-fast-form/SelectFormField';
import get from 'lodash/get';
import head from 'lodash/head';
import size from 'lodash/size';
import React, { useEffect } from 'react';
import { O } from 'ts-toolbelt';

import CountriesSelect from './FormikCountriesSelect';

export { default as CountriesSelectForFilter } from './CountriesSelectForFilter';

type Props = {
  fieldPath: string;
  labelI18n: I18nShape;
} & O.Omit<
  FastFormSelectProps,
  'placeholderI18n' | 'options' | 'optionsName' | 'optionsValue'
>;

export const FastFormCountriesSelect: React.FC<Props> = ({
  fieldPath,
  ...props
}: Props) => {
  const setValue = useSetFieldValue();
  const availableCountries = useAvailableCountries();
  useEffect(() => {
    const id = get(head(availableCountries), 'id');
    if (size(availableCountries) === 1 && id) {
      setValue(fieldPath, id);
    }
  }, [availableCountries, fieldPath, setValue]);
  return (
    <FastFormSelectFormField
      fieldPath={fieldPath}
      placeholderI18n={COUNTRIES_I18N.PLACEHOLDER}
      options={availableCountries}
      optionsName="name"
      optionsValue="id"
      {...props}
    />
  );
};

export default CountriesSelect;
