import { useNavigateTo } from '@lib/navigation/hooks';
import { useBoxStyle } from '@manufacturer/login-ui/LoginForm';
import { DEFAULT as I18N_VALIDATIONS } from '@mmw/constants-i18n-validations';
import { UTILS } from '@mmw/constants-validation-field-schemas';
import { Column, Row } from '@mmw/hybrid-ui-structure-containers';
import { GetLoginConfigFormData } from '@mmw/redux-store-auth-api-login-config-by-user/hooks/useGetLoginConfigSubmitHandler';
import {
  useChangePassword,
  useError,
  useIsLoading,
  useIsValidUuid,
  useValidateChangePasswordUuid,
} from '@mmw/redux-store-auth-api-recover-password/hooks';
import { RECOVER_PASSWORD_FIELD_PATHS as FIELD_PATHS } from '@mmw/redux-store-auth-api-recover-password/types';
import { FastFormProvider } from '@mmw/redux-store-fast-form';
import { BAD_CREDENTIALS } from '@mmw/redux-store-payload-error/constants';
import { useNotifications } from '@mmw/ui-hybrid-libraries-notifications/hooks';
import { GlobalSpinner } from '@mmw/ui-web-blocks-spinner';
import { Body2 } from '@mmw/ui-web-elements-typography';
import Button from '@mmw/ui-web-materials-button';
import Link from '@mmw/ui-web-materials-link';
import { AUTHENTICATION as AUTH_I18N } from '@retail/constants-i18n';
import Auth from '@retail/login-or-signup-ui-web';
import { useNavigateToInformEmail } from '@retail/login-or-signup-ui-web/hooks';
import { EMPTY_OBJECT } from '@shared-utils/object';
import UI from '@ui-system/ui';
import delay from 'lodash/delay';
import React, { ReactNode, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { F } from 'ts-toolbelt';
import { object, ref, string } from 'yup';

import Page from '../elements/PageStructure';

const VALIDATION_SCHEMA = object().shape({
  [FIELD_PATHS.password.$path]: UTILS.STRONG_PASSWORD_REQUIRED,
  [FIELD_PATHS.confirmPassword.$path]: string()
    .oneOf(
      [ref(FIELD_PATHS.password.$path), null],
      I18N_VALIDATIONS.PASSWORD_MUST_MATCH.key,
    )
    .required(I18N_VALIDATIONS.REQUIRED.key),
});

const INITIAL_VALUES = {
  [FIELD_PATHS.password.$path]: '',
  [FIELD_PATHS.confirmPassword.$path]: '',
};

type Props = {
  backgroundColor?: string;
  textsColorModifiers?: string | string[];
  bottomContent?: ReactNode;
};

const Container = styled(Column)`
  z-index: 1;
  max-width: 350px;
  width: 100%;
`;

const PAGE_CONTAINER_STYLE = {
  paddingTop: 0,
};

type RecoverPasswordByUUIDProps = Pick<Props, 'textsColorModifiers'> & {
  onSuccessRoute?: string;
  buttonStyle?: Record<string, any>;
};

export const RecoverPasswordByUUID: React.FC<RecoverPasswordByUUIDProps> = ({
  textsColorModifiers,
  onSuccessRoute,
  buttonStyle,
}: RecoverPasswordByUUIDProps) => {
  const navigateToEnterEmail = useNavigateToInformEmail();
  const validate = useValidateChangePasswordUuid();
  const changePassword = useChangePassword();
  const isValid = useIsValidUuid();
  const notify = useNotifications();
  const error = useError();
  const navigateTo = useNavigateTo();
  const handleSubmit = useCallback(
    ({ password }) => {
      if (isValid) {
        changePassword(password);
        if (!error) {
          notify(AUTH_I18N.AUTHENTICATION.PASSWORD_CHANGED, {
            appearance: 'success',
          });
          delay(() => {
            if (onSuccessRoute) navigateTo(onSuccessRoute);
            else navigateToEnterEmail();
          }, 500);
        }
      } else {
        notify(BAD_CREDENTIALS.I18N.DESCRIPTION, {
          appearance: 'error',
        });
      }
    },
    [
      changePassword,
      error,
      isValid,
      navigateTo,
      navigateToEnterEmail,
      notify,
      onSuccessRoute,
    ],
  );
  useEffect(() => {
    validate();
  }, [validate]);
  return (
    <FastFormProvider
      validationSchema={VALIDATION_SCHEMA}
      initialValues={INITIAL_VALUES}
      onSubmitSuccess={handleSubmit}
    >
      <Auth.Password labelModifiers={textsColorModifiers} />
      <Auth.ConfirmPassword labelModifiers={textsColorModifiers} />
      <Auth.SubmitButton style={buttonStyle} />
    </FastFormProvider>
  );
};

interface ReturnToLoginButtonProps extends Record<string, any> {
  goBack: F.Function;
}

export const ReturnToLoginButton: React.FC<ReturnToLoginButtonProps> = ({
  goBack,
  ...props
}: ReturnToLoginButtonProps) => (
  <Button onClick={goBack} {...props}>
    <Button.Text
      i18n={AUTH_I18N.AUTHENTICATION.BACK_TO_LOGIN}
      modifiers="uppercase"
    />
  </Button>
);

interface RecoverSuccessProps {
  askAgain: F.Function<[GetLoginConfigFormData]>;
  handleGoBack: F.Function;
}

export const RecoverPasswordRequestSuccessContent: React.FC<
  RecoverSuccessProps
> = ({ askAgain, handleGoBack }: RecoverSuccessProps) => {
  const cardBoxStyle = useBoxStyle(EMPTY_OBJECT);
  return (
    <UI.Container
      style={cardBoxStyle}
      direction="column"
      gap={3}
      bg="white"
      p="6, 8"
    >
      <UI.Container direction="column" align="center" justify="center" gap={1}>
        <UI.Icon name="check" size={40} color="success" />
        <UI.H5 i18n={AUTH_I18N.AUTHENTICATION.RECOVER_PASS_EMAIL_SUCCESS} />
      </UI.Container>
      <UI.Container direction="column" gap={2}>
        <ReturnToLoginButton goBack={handleGoBack} />
        <UI.Link
          i18n={AUTH_I18N.AUTHENTICATION.DIDINT_RECEIVE}
          onClick={askAgain}
          modifiers="underline"
        />
      </UI.Container>
    </UI.Container>
  );
};

const Loader: React.FC = () => {
  const isLoading = useIsLoading();
  return isLoading ? <GlobalSpinner visible /> : null;
};

const RecoverPassword: React.FC<Props> = ({
  backgroundColor,
  textsColorModifiers,
  bottomContent,
}: Props) => {
  const navigateToEnterEmail = useNavigateToInformEmail();

  return (
    <>
      <Loader />
      <Page
        backgroundColor={backgroundColor}
        justifyPageContent="center"
        pageContainerStyle={PAGE_CONTAINER_STYLE}
        bottomContent={bottomContent}
      >
        <Container align="center">
          <Row>
            <Body2
              i18n={AUTH_I18N.AUTHENTICATION.ENTER_NEW_PASSWORD}
              modifiers="light"
            />
          </Row>
          <RecoverPasswordByUUID textsColorModifiers={textsColorModifiers} />
          <Row justify="center">
            <Link href="javascript:void(0);" onClick={navigateToEnterEmail}>
              <Link.Text
                i18n={AUTH_I18N.AUTHENTICATION.BACK_TO_LOGIN}
                modifiers="light"
              />
            </Link>
          </Row>
        </Container>
      </Page>
    </>
  );
};

export default RecoverPassword;
