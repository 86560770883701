export default ({ theme }) => `
  width: 1.25rem;
  height: 1.25rem;
  fill: ${theme.colors.pallet.primary};
  color: ${theme.colors.pallet.primary};
  ${theme.media.IE11`
    height: inherit;
    div {
      height: inherit;
    }
  `};
`;
