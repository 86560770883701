import iconModifiers from '@mmw/ui-web-elements-icon/theme/modifiers';

import iconStyle from './iconStyle';
import { linkTextsModifiers } from './modifiers';
import linkStyle from './style';
import textStyle from './textStyle';

export default {
  style: linkStyle,
  icon: {
    style: iconStyle,
    modifiers: iconModifiers,
  },
  text: {
    style: textStyle,
    modifiers: linkTextsModifiers,
  },
};
