import * as check from './check';
import * as constants from './constants';
import * as string from './string';

const validations = {
  string,
  check,
  constants,
};

export { check, constants, validations as default, string };
