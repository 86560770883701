import { connect as formikConnect } from '@mmw/ui-libraries-formik-v2';
import { compose, HOC, mapProps } from 'recompose';

const withValue: HOC<unknown, unknown> = compose(
  formikConnect,
  mapProps(({ formik: { handleBlur }, ...rest }) => ({
    ...rest,
    onBlur: handleBlur,
  })),
);

export default withValue;
