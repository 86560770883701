import contextualConfig from '@mmw/contextual-config';
import isEmpty from 'lodash/isEmpty';
import { F, U } from 'ts-toolbelt';

import handleFinalResult from './handleFinalResult';

const { logger } = contextualConfig.application;

export async function fetchStreaming<S extends object, T extends object>(
  url: string,
  accessToken: U.Nullable<string>,
  request: Record<string, any>,
  handleStreamData: F.Function<[S]>,
): Promise<U.Nullable<T>> {
  try {
    if (!accessToken) {
      throw new Error('Failed to fetach stream api, missing access token');
    }
    const result = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      let buffer = '';

      function processBufferData(data: unknown) {
        if (isEmpty(data)) {
          return;
        }
        buffer = `${buffer}${String(data)}`;
        try {
          while (buffer.indexOf('\n') > -1) {
            const lineBreakIndex = buffer.indexOf('\n');
            const jsonStr = buffer.substring(0, lineBreakIndex);
            let jsonData;
            try {
              jsonData = JSON.parse(jsonStr);
            } catch (error) {
              logger.error('Error on trying to read JSON', error);
              break;
            }
            buffer = buffer.substring(lineBreakIndex + 1);
            if (jsonData.stepStatus) {
              handleStreamData(jsonData);
            } else {
              resolve(jsonData);
            }
          }
        } catch (error) {
          logger.error('Stream reader error', error);
        }
      }
      xhr.open('POST', url, true);
      xhr.setRequestHeader('X-AUTH-ACCESS-TOKEN', accessToken);
      xhr.setRequestHeader('Content-Type', 'application/json');

      xhr.onload = () => {
        const { status } = xhr;
        if (status < 100 || status > 599)
          reject(new TypeError('Network request failed'));
        const type = xhr.getResponseHeader('content-type') || '';
        let body = xhr.responseText;
        if (type.indexOf('application/json') !== -1) {
          try {
            body = JSON.parse(body);
          } catch (e) {
            logger.error('Error on load file', e);
          }
        } else if ('response' in xhr) {
          body = xhr.response;
        }
        const options = {
          status,
          statusText: xhr.statusText,
          body,
          xhr,
        };
        resolve(options);
      };

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 3) {
          // @ts-ignore
          const freshData = xhr.response.substr(xhr.seenBytes);
          // @ts-ignore
          xhr.seenBytes = xhr.responseText.length;
          processBufferData(freshData);
        }
      };
      xhr.send(JSON.stringify(request));
    });
    return handleFinalResult<T>(result as Record<string, any>);
  } catch (error) {
    logger.error(
      'Error on trying to read text stream from one snap api',
      error,
    );
    throw error;
  }
}

// export function streamReaderNode<P extends Record<string, any>, R = any>(
//   stream: Stream,
//   progressCallback: F.Function<[P]>,
// ): Promise<R> {
//   return new Promise((resolve, reject) => {
//     let buffer = '';

//     function processBufferData(data: unknown) {
//       if (isEmpty(data)) {
//         return;
//       }
//       buffer = `${buffer}${String(data)}`;
//       try {
//         while (buffer.indexOf('\n') > -1) {
//           const lineBreakIndex = buffer.indexOf('\n');
//           const jsonStr = buffer.substring(0, lineBreakIndex);
//           let jsonData;
//           try {
//             jsonData = JSON.parse(jsonStr);
//           } catch (error) {
//             log.error('Error on trying to read JSON', error);
//             break;
//           }
//           buffer = buffer.substring(lineBreakIndex + 1);
//           if (jsonData.stepStatus) {
//             progressCallback(jsonData);
//           } else {
//             resolve(jsonData);
//           }
//         }
//       } catch (error) {
//         log.error('Stream reader error', error);
//       }
//     }

//     stream.on('data', data => {
//       processBufferData(data);
//     });

//     stream.on('end', data => {
//       processBufferData(data);
//     });

//     stream.on('error', error => {
//       reject(error);
//     });
//   });
// }
