import defaultApiV2, { ApiResponse } from '@mmw/api-v2';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import autoBind from 'auto-bind';

import { GetWalletBalancePath, GetWalletTransactionsPath } from './apiPaths';
import logger from './log';
import { OrgunitWalletTransactionsHistory } from './types';

type Api = typeof defaultApiV2;

type TraderWalletServiceOptions = {
  apiv2?: Api;
  authenticationService: AuthenticationService;
};

class TraderWalletService {
  api: Api;

  authenticationService: AuthenticationService;

  constructor({ apiv2, authenticationService }: TraderWalletServiceOptions) {
    this.api = apiv2 || defaultApiV2;
    this.authenticationService = authenticationService;
    autoBind(this);
  }

  async getWalletBalance(walletName: string): Promise<number> {
    logger.debug('Trying to get wallet balance of=%d', walletName);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<number> = await this.api.get(
        GetWalletBalancePath(walletName),
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully got wallet balance');
      return data;
    } catch (error) {
      logger.error('Error when getting wallet balance, error=%O', error);
      throw error;
    }
  }

  async getWalletTransactions(
    walletName: string,
  ): Promise<OrgunitWalletTransactionsHistory> {
    logger.debug('Trying to get wallet transactions history of=', walletName);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<OrgunitWalletTransactionsHistory> =
        await this.api.get(GetWalletTransactionsPath(walletName), {
          headers,
        });
      const { data } = response;
      logger.info('Successfully got transactions history');
      return data;
    } catch (error) {
      logger.error('Error when getting transactions history, error=%O', error);
      throw error;
    }
  }
}

export default TraderWalletService;
