import navLinkStyle from '@mmw/ui-web-blocks-paginator/theme/navLinkStyle';
import style from '@mmw/ui-web-blocks-paginator/theme/style';

const arrowStyle = ({ theme, disabled }, rotation) => `
  background-image: url(${theme.assets.DROP_ICON});
  transform: rotate(${rotation});
  width: 40px;
  height: 40px;
  border: none;
  background-color: transparent;
  background-repeat: no-repeat;
  opacity: ${disabled ? '0.3' : '1'};
  cursor: ${disabled ? 'default' : 'pointer'};
  margin: 0 1px;
  &:focus {
    outline: none;
  }
  ::-moz-focus-inner {
    border: 0;
  }
`;

const arrowLeftStyle = props => arrowStyle(props, '90deg');
const arrowRightStyle = props => arrowStyle(props, '-90deg');

export default {
  style,
  navLink: {
    style: ({ theme }) => `
      ${navLinkStyle({ theme })};
      background-color: ${theme.colors.pallet.basicLight};
      padding: 10px 15px;
      margin: 0 1px;
    `,
  },
  leftArrow: {
    style: arrowLeftStyle,
  },
  rightArrow: {
    style: arrowRightStyle,
  },
};
