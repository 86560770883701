import { PhotoFileType } from '@lib/camera/interfaces';
import { ApiResponse } from '@mmw/api-auth';
import defaultApiV2 from '@mmw/api-v2';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import { UploadableFileJSON } from '@mmw/services-core-common/types';
import autoBind from 'auto-bind';

import { GetProfilePicturePath, GetSalesOrgBrandsPath } from './apiPaths';
import logger from './log';

type Api = typeof defaultApiV2;

type UserProfileServiceOptions = {
  apiv2?: Api;
  authenticationService: AuthenticationService;
};

export type SalesOrgBrandJSON = {
  salesorganizationId: number;
  brandId: number;
  countries: Array<string>;
  fallback: boolean;
  image: string;
  brandName: string;
  id: number;
  name: string;
};

class UserProfileService {
  api: Api;

  authenticationService: AuthenticationService;

  constructor({ apiv2, authenticationService }: UserProfileServiceOptions) {
    this.api = apiv2 || defaultApiV2;
    this.authenticationService = authenticationService;
    autoBind(this);
  }

  async getLoggedUserSalesOrgBrands(): Promise<SalesOrgBrandJSON[]> {
    logger.debug('Trying to get logged user salesOrgBrands');
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Array<SalesOrgBrandJSON>> =
        await this.api.get(GetSalesOrgBrandsPath(), { headers });
      const { data } = response;
      logger.info('Successfully got logged user salesOrgBrands');
      return data;
    } catch (error) {
      logger.error('Error when getting sales org brands, error=%O', error);
      throw error;
    }
  }

  async updateLoggedUserProfilePicture(
    photo: PhotoFileType | UploadableFileJSON,
  ): Promise<boolean> {
    logger.debug('Trying to update user picture');
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response = await this.api.post(
        GetProfilePicturePath(headers),
        photo,
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully updated user profile picture', data);
      return data;
    } catch (error) {
      logger.error('Error when updating user profile picture');
      throw error;
    }
  }
}

export default UserProfileService;
