import { ORGUNIT_SIGNUP_FIELD_PATHS } from '@auth/redux-store-user-account/types';
import useConfigLinks from '@mmw/clients-ui-hooks-use-legal-links';
import { I18nShape } from '@mmw/constants-i18n';
import { PERSON as PERSON_I18N } from '@mmw/constants-i18n-fields';
import { Row } from '@mmw/hybrid-ui-structure-containers';
import { FIELD_PATHS } from '@mmw/redux-store-email-verification';
import { Checkbox } from '@mmw/ui-web-elements-checkbox';
import { Body2 } from '@mmw/ui-web-elements-typography';
import { InputFormField } from '@mmw/ui-web-libraries-fast-form';
import { AUTHENTICATION as AUTH_I18N } from '@retail/constants-i18n';
import I18N from '@retail/constants-i18n/pages';
import { FastFormCountriesSelect } from '@retail/ui-hybrid-blocks-countries-select';
import { FastFormLegalFormSelect } from '@retail/ui-hybrid-blocks-legal-form-select';
import React, { InputHTMLAttributes } from 'react';
import { F, O } from 'ts-toolbelt';

type CommonProps = {
  labelI18n?: I18nShape;
  labelModifiers?: string | Array<string>;
  errorI18n?: I18nShape;
} & O.Pick<InputHTMLAttributes<string>, 'tabIndex' | 'type' | 'autoComplete'>;

export const EmailField: React.FC<CommonProps> = ({
  labelModifiers,
  labelI18n,
  ...props
}: CommonProps) => (
  <InputFormField
    fieldPath={ORGUNIT_SIGNUP_FIELD_PATHS.email.$path}
    labelI18n={labelI18n || PERSON_I18N.EMAIL.LABEL}
    modifiers={labelModifiers}
    {...props}
  />
);

export const PasswordField: React.FC<CommonProps> = ({
  labelModifiers,
  labelI18n,
  ...props
}: CommonProps) => (
  <InputFormField
    fieldPath={ORGUNIT_SIGNUP_FIELD_PATHS.password.$path}
    labelI18n={labelI18n || AUTH_I18N.AUTHENTICATION.NEW_PASSWORD}
    modifiers={labelModifiers}
    type="password"
    {...props}
  />
);

export const ConfirmPasswordField: React.FC<CommonProps> = ({
  labelModifiers,
  labelI18n,
  ...props
}: CommonProps) => (
  <InputFormField
    fieldPath={ORGUNIT_SIGNUP_FIELD_PATHS.confirmPassword.$path}
    labelI18n={labelI18n || AUTH_I18N.AUTHENTICATION.CONFIRM_PASSWORD}
    modifiers={labelModifiers}
    type="password"
    {...props}
  />
);

export const FirstNameField: React.FC<CommonProps> = ({
  labelModifiers,
  labelI18n,
  ...props
}: CommonProps) => (
  <InputFormField
    fieldPath={ORGUNIT_SIGNUP_FIELD_PATHS.firstName.$path}
    labelI18n={labelI18n || PERSON_I18N.FIRSTNAME.LABEL}
    modifiers={labelModifiers}
    {...props}
  />
);

export const LastNameField: React.FC<CommonProps> = ({
  labelModifiers,
  labelI18n,
  ...props
}: CommonProps) => (
  <InputFormField
    fieldPath={ORGUNIT_SIGNUP_FIELD_PATHS.lastName.$path}
    labelI18n={labelI18n || PERSON_I18N.LASTNAME.LABEL}
    modifiers={labelModifiers}
    {...props}
  />
);

export const TANCodeField: React.FC<CommonProps> = ({
  labelModifiers,
  labelI18n,
  ...props
}: CommonProps) => (
  <InputFormField
    fieldPath={FIELD_PATHS.tanCode.$path}
    labelI18n={labelI18n || I18N.SIGN_UP.FORM.SECURITY_TOKEN}
    modifiers={labelModifiers}
    {...props}
  />
);

export const PhoneField: React.FC<CommonProps> = ({
  labelModifiers,
  labelI18n,
  ...props
}: CommonProps) => (
  <InputFormField
    fieldPath={ORGUNIT_SIGNUP_FIELD_PATHS.phone.$path}
    labelI18n={labelI18n || PERSON_I18N.PHONE.LABEL}
    modifiers={labelModifiers}
    {...props}
  />
);

export const CompanyNameField: React.FC<CommonProps> = ({
  labelModifiers,
  labelI18n,
  ...props
}: CommonProps) => (
  <InputFormField
    fieldPath={ORGUNIT_SIGNUP_FIELD_PATHS.company.$path}
    labelI18n={labelI18n || PERSON_I18N.COMPANY_NAME.LABEL}
    modifiers={labelModifiers}
    {...props}
  />
);

export const FantasyNameField: React.FC<CommonProps> = ({
  labelModifiers,
  labelI18n,
  ...props
}: CommonProps) => (
  <InputFormField
    fieldPath={ORGUNIT_SIGNUP_FIELD_PATHS.displayName.$path}
    labelI18n={labelI18n || I18N.SIGN_UP.FORM.FANTASY_NAME}
    modifiers={labelModifiers}
    {...props}
  />
);

export const StreetField: React.FC<CommonProps> = ({
  labelModifiers,
  labelI18n,
  ...props
}: CommonProps) => (
  <InputFormField
    fieldPath={ORGUNIT_SIGNUP_FIELD_PATHS.street.$path}
    labelI18n={labelI18n || PERSON_I18N.ADDRESS.STREET.LABEL}
    modifiers={labelModifiers}
    {...props}
  />
);

export const NrField: React.FC<CommonProps> = ({
  labelModifiers,
  labelI18n,
  ...props
}: CommonProps) => (
  <InputFormField
    fieldPath={ORGUNIT_SIGNUP_FIELD_PATHS.nr.$path}
    labelI18n={labelI18n || PERSON_I18N.ADDRESS.NUMBER.LABEL}
    modifiers={labelModifiers}
    {...props}
  />
);

export const ZipCodeField: React.FC<CommonProps> = ({
  labelModifiers,
  labelI18n,
  ...props
}: CommonProps) => (
  <InputFormField
    fieldPath={ORGUNIT_SIGNUP_FIELD_PATHS.zipcode.$path}
    labelI18n={labelI18n || PERSON_I18N.ADDRESS.ZIPCODE.LABEL}
    modifiers={labelModifiers}
    {...props}
  />
);

export const CityField: React.FC<CommonProps> = ({
  labelModifiers,
  labelI18n,
  ...props
}: CommonProps) => (
  <InputFormField
    fieldPath={ORGUNIT_SIGNUP_FIELD_PATHS.city.$path}
    labelI18n={labelI18n || PERSON_I18N.ADDRESS.CITY.LABEL}
    modifiers={labelModifiers}
    {...props}
  />
);

export const CountryField: React.FC<CommonProps> = ({
  labelModifiers,
  labelI18n,
  ...props
}: CommonProps) => (
  <FastFormCountriesSelect
    fieldPath={ORGUNIT_SIGNUP_FIELD_PATHS.country.$path}
    modifiers={labelModifiers}
    labelI18n={labelI18n || PERSON_I18N.ADDRESS.COUNTRY.LABEL}
    required
    {...props}
  />
);

export const LegalFormField: React.FC<CommonProps> = ({
  labelModifiers,
  labelI18n,
  ...props
}: CommonProps) => (
  <FastFormLegalFormSelect
    fieldPath={ORGUNIT_SIGNUP_FIELD_PATHS.legalForm.$path}
    labelI18n={labelI18n || PERSON_I18N.LEGAL_FORM.LABEL}
    modifiers={labelModifiers}
    {...props}
  />
);

export const TaxNumberField: React.FC<CommonProps> = ({
  labelModifiers,
  labelI18n,
  ...props
}: CommonProps) => (
  <InputFormField
    fieldPath={ORGUNIT_SIGNUP_FIELD_PATHS.taxnumber.$path}
    labelI18n={labelI18n || PERSON_I18N.TAX_NUMBER.LABEL}
    modifiers={labelModifiers}
    {...props}
  />
);

export const SalesTaxIdField: React.FC<CommonProps> = ({
  labelModifiers,
  labelI18n,
  ...props
}: CommonProps) => (
  <InputFormField
    fieldPath={ORGUNIT_SIGNUP_FIELD_PATHS.salestaxid.$path}
    labelI18n={labelI18n || PERSON_I18N.VAT.LABEL}
    modifiers={labelModifiers}
    {...props}
  />
);

interface TermsProps {
  onChange: F.Function<[boolean]>;
  value: boolean;
  labelModifiers?: string | string[];
  labelI18n?: I18nShape;
}

const SPACING = [4, 0];

const STYLE = {
  cursor: 'pointer',
};
export const Terms: React.FC<TermsProps> = ({
  onChange,
  value,
  labelI18n,
  labelModifiers,
}: TermsProps) => {
  const { dataProtectionLink } = useConfigLinks();
  return (
    <Row spacing={SPACING} style={STYLE}>
      <Checkbox onChange={() => onChange(!value)} checked={value} />
      <Body2
        i18n={labelI18n || I18N.SIGN_UP.FORM.TERMS}
        modifiers={labelModifiers}
        onClick={() => window.open(dataProtectionLink)}
      />
    </Row>
  );
};
