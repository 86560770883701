import modifiers, { size } from '@mmw/ui-theme/modifiers';
import colors from '@mmw/ui-theme/modifiers/color';

export const sizes = {
  h1: {
    fontSize: '6rem',
  },
  h2: {
    fontSize: '3.75rem',
  },
  h3: {
    fontSize: '3rem',
  },
  h4: {
    fontSize: '2rem',
  },
  h5: {
    fontSize: '1.5rem',
  },
  h6: {
    fontSize: '1.25rem',
  },
  body1: {
    fontSize: '1rem',
  },
  body2: {
    fontSize: '0.975rem',
  },
  caption1: {
    fontSize: '0.950rem',
  },
  caption2: {
    fontSize: '0.875rem',
  },
};

export const typoModifiers = modifiers.typography;
export const sizeModifiers = size(sizes);
export const colorModifiers = colors();

export default {
  ...typoModifiers,
  ...sizeModifiers,
  ...colorModifiers,
};
