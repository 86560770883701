export default {
  style: ({ theme }) => `
    display: flex;
    justify-content: center;
    padding: auto;
    width: 100%;
    height: ${theme.page.variable.NAVBAR_HEIGHT};
    background-color: ${theme.colors.pallet.primary};    
  `,
  content: {
    style: ({ theme }) => `
      max-width: ${theme.page.variable.CONTENT_MAX_WIDTH};
      width: 100%;
    `,
  },
};
