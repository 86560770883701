import { enableBatching } from 'redux-batched-actions';
import { IModule } from 'redux-dynamic-modules';

import { checkAuthenticationStartAction } from './actions';
import authenticationEpics, { Epics } from './epics';
import authenticationReducer from './reducer';
import { NAMESPACE as authenticationNamespace, RootState } from './types';

export * from './epics';
export const epics = authenticationEpics;
export const reducer = authenticationReducer;
export const NAMESPACE = authenticationNamespace;

export function getAuthCoreModule(): IModule<RootState> & {
  epics: Epics;
} {
  return {
    id: NAMESPACE,
    reducerMap: {
      // @ts-ignore
      [NAMESPACE]: enableBatching(reducer),
    },
    epics: authenticationEpics,
    initialActions: [checkAuthenticationStartAction()],
  };
}
