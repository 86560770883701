import { LanguageCode } from '@mmw/constants-languages';
import {
  POS_PREMIUM,
  RegistrationType,
} from '@mmw/constants-registration-types';
import { NOT_INFORMED as NOT_INFORMED_SALES_TYPE } from '@mmw/constants-sales-types';
import { SalesOrgBrand } from '@mmw/constants-salesorgbrand-ids';
import { EMPTY_ARRAY } from '@mmw/constants-utils';
import { ValidationError } from '@mmw/constants-validation-errors';
import contextualConfig from '@mmw/contextual-config';
import TraderProductsService from '@mmw/services-core-product';
import { ProductJSON } from '@mmw/services-core-product/types';
import {
  findCampaignItemWithSameQuestions,
  findMatchingCampaignItem,
  mapCampaignItemKeys,
} from '@mmw/utils-campaign-item';
import { OneSnapRegistrationItemData } from '@one-snap/store-creator/types';
import { GlobalSelections } from '@retail/product-registration-wizard-redux-store/types';
import { find, isEmpty, isEqual, map, pick, uniq, xor } from 'lodash';
import { U } from 'ts-toolbelt';
import uuid from 'uuid/v4';

import {
  CampaignItemJSON,
  ConsumerAccountJSON,
  RegistrationCommonJSON,
  RegistrationItemSelectionJSON,
  RegistrationRequestJSON,
  RegistrationResponseJSON,
} from './types';

const EMPTY_PURCHASE_SELECTION = {
  invoiceFiles: EMPTY_ARRAY,
  purchaseDate: new Date(),
  salesType: NOT_INFORMED_SALES_TYPE,
};

export const EMPTY_ACCOUNT: ConsumerAccountJSON = {
  accountowner: '',
  account: '',
  country: contextualConfig.application.defaultCountry,
  bic: '',
  iban: '',
  agency: '',
  manualbankname: '',
};

export const emptyRegistrationRequestJSON = (
  storeID: number | null,
  language: LanguageCode,
  registrationType: RegistrationType | null,
  salesOrgBrandID: SalesOrgBrand,
): RegistrationRequestJSON => ({
  salesOrgBrandID,
  storeID: storeID || -1,
  registrationType: registrationType || POS_PREMIUM,
  purchaseSelection: { ...EMPTY_PURCHASE_SELECTION },
  campaignItems: [],
  availableThirdPartyCampaigns: [],
  shippingAddress: null,
  consumer: null,
  dataPrivacyConsent: false,
  account: null,
  campaignID: null,
  selectedCampaignCodes: [],
  excludedCampaignCodes: [],
  selectedCampaign: false,
  language,
  emailConfiguration: {
    send: false,
  },
  productSelection: [],
  createConsumerAsEnduser: false,
  consumerMaxDistanceConfirmation: false,
});

export const emptyRegistrationRequestJSONWithGlobalSelections = (
  globalSelections: GlobalSelections,
  language: LanguageCode,
  storeID: number | null,
  needsAccount: boolean,
): RegistrationRequestJSON => {
  const emptyRegistration = emptyRegistrationRequestJSON(
    storeID,
    language,
    globalSelections.registrationType,
    contextualConfig.application.salesOrgBrandID,
  );
  if (globalSelections.selectedCampaign) {
    const { selectedCampaign, overrideByCampaignID } = globalSelections;
    emptyRegistration.campaignID = selectedCampaign.campaignID;
    // XXX: we should always set selected true
    // otherwise in cases where we have one opt and other normal it doesnt trigger
    // emptyRegistration.selectedCampaign = selectedCampaign.registrationConfiguration.optional;
    emptyRegistration.selectedCampaign = true;
    if (overrideByCampaignID) {
      emptyRegistration.campaignID = overrideByCampaignID;
    }
    if (!isEmpty(selectedCampaign.selectedCampaignCodes)) {
      emptyRegistration.selectedCampaignCodes =
        selectedCampaign.selectedCampaignCodes;
    }
    const {
      purchaseDate: { max },
    } = selectedCampaign;
    if (max && new Date(max) < new Date()) {
      emptyRegistration.purchaseSelection.purchaseDate = new Date(max);
    }
  }
  // XXX: make this dynamic
  if (needsAccount) {
    emptyRegistration.account = {
      ...EMPTY_ACCOUNT,
    };
  } else {
    emptyRegistration.account = null;
  }
  return emptyRegistration;
};

const sameCampaignID = (
  requestCi: CampaignItemJSON,
  responseCi: CampaignItemJSON,
): boolean => requestCi.campaign.campaignID === responseCi.campaign.campaignID;

const sameProductPosition = (
  requestCi: CampaignItemJSON,
  responseCi: CampaignItemJSON,
): boolean => requestCi.productPosition === responseCi.productPosition;

const findRequestCampaignItemWithCis = (
  campaignItems: U.Nullable<CampaignItemJSON[]>,
  responseCi: CampaignItemJSON,
): U.Nullable<CampaignItemJSON> =>
  find(
    campaignItems,
    requestCi =>
      sameCampaignID(requestCi, responseCi) &&
      sameProductPosition(requestCi, responseCi),
  );

const findRequestCampaignItem = (
  request: RegistrationCommonJSON,
  responseCi: CampaignItemJSON,
): U.Nullable<CampaignItemJSON> =>
  findRequestCampaignItemWithCis(request.campaignItems, responseCi);

const FIELDS_TO_UPDATE_CAMPAIGN_ITEM = [
  'campaignStatus',
  'campaign',
  'termsAccepted',
  'campaignStatusLabel',
  'validationErrors',
  'manualValidationErrors',
  'validationStatusModel',
  'errorLabels',
  'hasAccount',
  'visible',
  'productID',
];

const didCampaignItemStatusChanged = (
  requestCi: CampaignItemJSON,
  responseCi: CampaignItemJSON,
): boolean => {
  const req = pick(requestCi, FIELDS_TO_UPDATE_CAMPAIGN_ITEM);
  const res = pick(responseCi, FIELDS_TO_UPDATE_CAMPAIGN_ITEM);
  return !isEqual(req, res);
};

export const copyRegistrationResponseIntoRequest = (
  request: RegistrationRequestJSON,
  response: RegistrationResponseJSON,
): RegistrationRequestJSON => {
  if (!request || !response) {
    return request;
  }
  let changed = false;
  const campaignItems = map(response.campaignItems, responseCi => {
    const requestCi = findRequestCampaignItem(request, responseCi);
    if (!requestCi) {
      changed = true;
      return responseCi;
    }
    changed = didCampaignItemStatusChanged(requestCi, responseCi);
    if (!changed) {
      return requestCi;
    }
    return {
      ...requestCi,
      ...pick(responseCi, FIELDS_TO_UPDATE_CAMPAIGN_ITEM),
    };
  });
  if (!changed) {
    return request;
  }
  return {
    ...request,
    campaignItems,
  };
};

export const mergeCampaignItemsWithArrays = (
  initialResponseCis: U.Nullable<CampaignItemJSON[]>,
  responseCis: U.Nullable<CampaignItemJSON[]>,
): {
  changed: boolean;
  campaignItems: CampaignItemJSON[];
} => {
  const initialCampaignItems = uniq(
    map(
      initialResponseCis,
      ci => `${ci.campaign.campaignID}-${ci.productID}-${ci.productPosition}`,
    ),
  );
  const newCampaignItems = uniq(
    map(
      responseCis,
      ci => `${ci.campaign.campaignID}-${ci.productID}-${ci.productPosition}`,
    ),
  );
  let changedCis = xor(initialCampaignItems, newCampaignItems).length !== 0;

  const campaignItems = map(responseCis, responseCi => {
    const requestCi = findRequestCampaignItemWithCis(
      initialResponseCis,
      responseCi,
    );
    if (!requestCi) {
      changedCis = true;
      return responseCi;
    }
    const changedStatus = didCampaignItemStatusChanged(requestCi, responseCi);
    if (!changedStatus) {
      return requestCi;
    }
    changedCis = true;
    return {
      ...requestCi,
      ...pick(responseCi, FIELDS_TO_UPDATE_CAMPAIGN_ITEM),
    };
  });
  return {
    campaignItems,
    changed: changedCis,
  };
};

export const mergeRegistrationResponses = (
  initialResponse: U.Nullable<RegistrationResponseJSON>,
  response: RegistrationResponseJSON | null,
): RegistrationResponseJSON | null => {
  if (!initialResponse || !response) {
    return initialResponse || response;
  }
  const { campaignItems, changed: changedCis } = mergeCampaignItemsWithArrays(
    initialResponse?.campaignItems,
    response.campaignItems,
  );

  const productPositions = uniq(
    map(
      initialResponse.productSelection,
      (p, index: number) => `${index}-${p.productID}`,
    ),
  );
  const newPositions = uniq(
    map(
      response.productSelection,
      (p, index: number) => `${index}-${p.productID}`,
    ),
  );
  const hasChangedProducts = xor(productPositions, newPositions).length !== 0;

  if (!changedCis && !hasChangedProducts) {
    // XXX: this should never happen, I added here
    if (!isEqual(initialResponse.statusModel, response.statusModel)) {
      return {
        ...initialResponse,
        statusModel: response.statusModel,
      };
    }
    return initialResponse;
  }
  return {
    ...initialResponse,
    productSelection: hasChangedProducts
      ? response.productSelection
      : initialResponse.productSelection,
    campaignItems,
    statusModel: response.statusModel,
  };
};

export const mergeOnlyCampaignItems = (
  simulatedData: U.Nullable<CampaignItemJSON[]>,
  currentInformedData: U.Nullable<CampaignItemJSON[]>,
): CampaignItemJSON[] => {
  if (!simulatedData && !currentInformedData) {
    return EMPTY_ARRAY;
  }
  if (isEmpty(currentInformedData)) {
    return simulatedData || EMPTY_ARRAY;
  }
  const simulatedCiKeys = mapCampaignItemKeys(simulatedData);
  const currentInformedCiKeys = mapCampaignItemKeys(currentInformedData);
  const diffs = xor(currentInformedCiKeys, simulatedCiKeys);
  if (diffs.length === 0) {
    return currentInformedData || EMPTY_ARRAY;
  }
  return map(simulatedData, simulatedCi => {
    const previousExactCi = findMatchingCampaignItem(
      simulatedCi,
      currentInformedData,
      true,
    );
    if (previousExactCi) {
      return {
        ...previousExactCi,
        ...pick(simulatedCi, FIELDS_TO_UPDATE_CAMPAIGN_ITEM),
      };
    }
    let similarAnsweredCi = findMatchingCampaignItem(
      simulatedCi,
      currentInformedData,
      false,
    );
    if (!similarAnsweredCi) {
      similarAnsweredCi = findCampaignItemWithSameQuestions(
        simulatedCi,
        currentInformedData,
      );
    }

    if (!similarAnsweredCi) {
      return simulatedCi;
    }
    return {
      ...simulatedCi,
      questionGroups: similarAnsweredCi.questionGroups,
    };
  });
};

export const mergeCampaignItems = (
  simulatedData: RegistrationRequestJSON,
  currentInformedData: RegistrationResponseJSON,
): CampaignItemJSON[] =>
  mergeOnlyCampaignItems(
    simulatedData?.campaignItems,
    currentInformedData.campaignItems,
  );

export const isBlocked = (response: RegistrationResponseJSON): boolean =>
  find(
    response.campaignItems,
    ci => ci.validationStatusModel.registrationBlocked,
  ) != null;

export const isTermsNotAccepted = (
  response: RegistrationResponseJSON,
): boolean =>
  find(
    response.campaignItems,
    ci => ci.validationStatusModel.termsNotAccepted,
  ) != null;

export const hasNonVerifyingErrors = (
  response: RegistrationResponseJSON,
): boolean =>
  find(
    response.campaignItems,
    ci => ci.validationStatusModel.hasNonVerifyingErrors,
  ) != null;

export const hasNonVerifyingOrRegistrationMarkedErrors = (
  response: RegistrationResponseJSON,
): boolean =>
  find(response.campaignItems, ({ validationErrorsString }) => {
    if (!validationErrorsString || validationErrorsString === '0') return false;
    return !(
      validationErrorsString === ValidationError.REQUIRES_VERIFICATION ||
      validationErrorsString === ValidationError.REGISTRATION_MARKED ||
      validationErrorsString === ValidationError.BOTH
    );
  }) != null;

export const transformRegistrationRequestToSimulation = (
  data: RegistrationRequestJSON,
): RegistrationRequestJSON => ({
  ...data,
  purchaseSelection: {
    ...data.purchaseSelection,
    // XXX: remove invoice files data to speed up simulation
    invoiceFiles: map(data.purchaseSelection.invoiceFiles, invoiceFile => ({
      ...invoiceFile,
      blob: undefined,
      file: undefined,
    })),
  },
});

export const createRegistrationItem = (
  product: ProductJSON,
  productUuid: string,
  kitUuid?: string,
): RegistrationItemSelectionJSON => ({
  uuid: productUuid,
  product,
  productID: product.productID,
  serialnumber: product.serialnumber || '',
  imei: product.imei || '',
  coupon: '',
  promotionCode: '',
  givenEan: '',
  quantity: null,
  kitUuid,
  comment: null,
  serialHelpImage: product.serialHelpImage,
  serialHelpText: product.serialHelpText,
});

export async function createKitRegistrationItems(
  product: ProductJSON,
  language: LanguageCode,
  getService: () => TraderProductsService,
): Promise<Array<RegistrationItemSelectionJSON>> {
  const { productID } = product;
  const components = await getService().getProductComponents(
    productID,
    language,
  );
  const mainKitUuid = uuid();
  const mainKitProduct = createRegistrationItem(product, mainKitUuid);
  return [
    mainKitProduct,
    ...map(components, p => createRegistrationItem(p, uuid(), mainKitUuid)),
  ];
}

export function mapOneSnapProcessedDataToRegistrationReqObj(
  processedData: U.Nullable<OneSnapRegistrationItemData>,
  initialRequestObj: RegistrationRequestJSON,
) {
  if (isEmpty(processedData)) return null;

  return {
    ...initialRequestObj,
    purchaseSelection: {
      ...initialRequestObj?.purchaseSelection,
      purchaseDate: processedData?.purchaseDate,
      invoiceNumber: processedData?.invoiceNumber,
    },
    productSelection: processedData.product
      ? [createRegistrationItem(processedData.product as ProductJSON, uuid())]
      : EMPTY_ARRAY,
    consumer: processedData.consumer,
  };
}
