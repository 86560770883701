const namespace = 'auth';
const signinNamespace = 'signin';
const signupNamespace = 'signup';
const placeholderNamespace = 'placeholder';

const SIGN_IN = {
  TITLE: {
    key: `${namespace}.${signinNamespace}`,
    message: 'Sign In',
  },
  INTRODUTCTION: {
    key: `${namespace}.${signinNamespace}.intro`,
    message:
      "Please log in here with your MMW ID. If you don't have one yet, you can quickly and easily create a new one",
  },
  PLACEHOLDERS: {
    INFORM_YOUR_EMAIL: {
      key: `${placeholderNamespace}.informeYourEmail`,
      message: 'Inform your Email',
    },
  },
  PASSWORD_FORGOTTEN: {
    key: `${namespace}.passwordForgotten`,
    message: 'Password forgotten',
  },
  NEW_MMW_ID: {
    key: `${namespace}.newMmwId`,
    message: 'New MMW-ID',
  },
  DONT_HAVE_MMW_ID: {
    key: `${namespace}.dontHaveMmwId`,
    message: 'No MMW ID yet?',
  },
};

const SIGN_UP = {
  INTRODUCTION: {
    key: `${namespace}.${signupNamespace}.intro`,
    message:
      'Please enter your email address and a password to create your MMW-ID',
  },
  CREATE_NOW: {
    key: `${namespace}.${signupNamespace}.createNow`,
    message: 'Create now',
  },
  CONFIRM_PASSWORD: {
    key: `${namespace}.${signupNamespace}.confirmPassword`,
    message: 'Confirm password',
  },
  CREATE_MMW_ID: {
    key: `${namespace}.${signupNamespace}.createMmwId`,
    message: 'Create MMW-ID',
  },
  TAN_CODE: {
    TITLE: {
      key: `${namespace}.${signupNamespace}.code`,
      message: 'Code',
    },
    SUBTITLE: {
      key: `${namespace}.${signupNamespace}.tanCodeSubtitle`,
      message:
        'We have sent a confirmation code to your email. Please enter it here to activate your MMW-ID',
    },
  },
};

const TAN_CODE_VALIDATION = {
  SMS_VALIDATION_DESC: {
    key: `${namespace}.tanCodeSmsValidation`,
    message:
      'We have sent a confirmation code to phone by SMS. Please enter it here to validate this operation',
  },
  EMAIL_VALIDATION_DESC: {
    key: `${namespace}.tanCodeEmailValidation`,
    message:
      'We have sent a confirmation code to your email. Please enter it here to validate this operation',
  },
};

const I18N = {
  SIGN_IN,
  SIGN_UP,
  TAN_CODE_VALIDATION,
};

export default I18N;
