import { clientServiceByAuthoritiesSelector } from '@mmw/redux-store-auth-api-authentication/stateSelector';
import OrgunitServiceSelectors from '@mmw/services-core-orgunit/selectors';

const { values } = Object;

const {
  accept,
  branch,
  branches,
  companies,
  company,
  groupname,
  salesmen,
  salestype,
  save,
} = OrgunitServiceSelectors;

export const orgunitAcceptByAuthSelector = clientServiceByAuthoritiesSelector(
  ...values(accept),
);

export const orgunitBranchByAuthSelector = clientServiceByAuthoritiesSelector(
  ...values(branch),
);

export const orgunitBranchesByAuthSelector = clientServiceByAuthoritiesSelector(
  ...values(branches),
);

export const orgunitSalesmenByAuthSelector = clientServiceByAuthoritiesSelector(
  ...values(salesmen),
);
export const orgunitGroupnameByAuthSelector =
  clientServiceByAuthoritiesSelector(...values(groupname));

export const orgunitSalestypeByAuthSelector =
  clientServiceByAuthoritiesSelector(...values(salestype));

export const orgunitCompaniesByAuthSelector =
  clientServiceByAuthoritiesSelector(...values(companies));

export const orgunitCompanyByAuthSelector = clientServiceByAuthoritiesSelector(
  ...values(company),
);

export const orgunitSaveByAuthSelector = clientServiceByAuthoritiesSelector(
  ...values(save),
);
