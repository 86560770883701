import changePasswordByOperationTokenEpic from './changePasswordByOperationTokenEpic';
import getConsumerProfileDataEpic from './getConsumerProfileDataEpic';
import saveConsumerProfileDataEpic from './saveConsumerProfileDataEpic';

const epics = [
  getConsumerProfileDataEpic,
  saveConsumerProfileDataEpic,
  changePasswordByOperationTokenEpic,
];

export {
  changePasswordByOperationTokenEpic,
  getConsumerProfileDataEpic,
  saveConsumerProfileDataEpic,
};
export type Epics = typeof epics;

export default epics;
