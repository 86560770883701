import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import { getAuthenticationService } from '@mmw/services-holder';
import { ActionsObservable } from 'redux-observable';
import { concat, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, timeout } from 'rxjs/operators';

import {
  changePasswordErrorAction,
  changePasswordSuccessAction,
} from '../actions';
import {
  CHANGE_PASSWORD_START,
  ChangePasswordErrorAction,
  ChangePasswordStartAction,
  ChangePasswordSuccessAction,
} from '../types';

const { logger } = contextualConfig.application;
const { defaultTimeout } = contextualConfig.api;

const changePasswordEpic =
  () =>
  (
    // eslint-disable-next-line max-len
    action$: ActionsObservable<ChangePasswordStartAction>,
  ): Observable<ChangePasswordSuccessAction | ChangePasswordErrorAction> =>
    action$.pipe(
      ofType(CHANGE_PASSWORD_START),
      tap(() => logger.debug('Trying to change password')),
      switchMap(
        ({
          payload: { uuid, password, language, onSuccess },
        }: ChangePasswordStartAction) =>
          from(
            getAuthenticationService().changePassword({
              uuid,
              password,
              language,
            }),
          ).pipe(
            timeout(defaultTimeout),
            map(response => {
              if (onSuccess) onSuccess();
              return changePasswordSuccessAction(!!response);
            }),
            catchError(error => concat(of(changePasswordErrorAction(error)))),
          ),
      ),
    );

export default changePasswordEpic;
