const features = {
  DELETE_EMPLOYEE: 'DELETE_EMPLOYEE',
  CHECK_ERP_NUMBER: 'CHECK_ERP_NUMBER',
  SEARCH_PROJECT_PANELS: 'SEARCH_PROJECT_PANELS',
  ALREADY_ADDED_PANEL: 'ALREADY_ADDED_PANEL',
  CREATE_EMPLOYEE: 'CREATE_EMPLOYEE',
  OPEN_FILE_IN_BROWSER: 'OPEN_FILE_IN_BROWSER',
  UPDATE_EMPLOYEE: 'UPDATE_EMPLOYEE',
  LOAD_PROJECT_DETAILS: 'LOAD_PROJECT_DETAILS',
  CREATE_ACCOUNT_COMPLEX: 'CREATE_ACCOUNT_COMPLEX',
  ADD_MANUAL_PANEL: 'ADD_MANUAL_PANEL',
};

export default features;
