import defaultApiV2, { ApiResponse } from '@mmw/api-v2';
import { LanguageCode } from '@mmw/constants-languages';
import { RegistrationSource } from '@mmw/constants-registration-sources';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import {
  Pagination,
  UploadableFileJSON,
} from '@mmw/services-core-common/types';
import {
  CampaignItemJSON,
  RegistrationRequestJSON,
  RegistrationResponseJSON,
  SearchRegistrationRequestJSON,
  UploadRegistrationFilesResultJSON,
} from '@mmw/services-core-trader-registration/types';
import { normalizeQuestionsIntoRegistrationResponse } from '@mmw/utils-campaign-item';
import autoBind from 'auto-bind';
import map from 'lodash/map';

import {
  ChangeRegistrationStatusPath,
  ChangeRegistrationValidationErrorPath,
  DownloadRegistrationAsXLSPath,
  GetCreateIssuesPath,
  GetRegistrationItemCommentsPath,
  GetRegistrationItemHistoryPath,
  GetRegistrationPath,
  GetRegistrationUserPath,
  GetSearchRegistrationsPath,
  PerformIssueActionPath,
  PerformRegistrationPath,
  RemoveFileByIdPath,
  RetrieveRegistrationPath,
  RevalidateRegistrationPath,
  ReverseRegistrationPath,
  UpdateCampaignItemsQuestionsPath,
  UpdateFilesByTypePath,
} from './apiPaths';
import logger from './log';
import {
  ChangeRegistrationStatusOperations,
  CreateIssueRequestJSON,
  CreateIssueResponseJSON,
  PerformIssueActionJSON,
  RegistrationActionWithStatusRequestJSON,
  RegistrationActionWithValidationErrorRequestJSON,
  RegistrationHistoryResponseJSON,
  RegistrationItemCommentJSON,
  ReverseRegistrationRequestJSON,
  UserJSON,
} from './types';

type Api = typeof defaultApiV2;
type ManufacturerRegistrationServiceOptions = {
  apiv2?: Api;
  authenticationService: AuthenticationService;
};

class ManufacturerRegistrationService {
  api: Api;

  authenticationService: AuthenticationService;

  constructor({
    apiv2,
    authenticationService,
  }: ManufacturerRegistrationServiceOptions) {
    this.api = apiv2 || defaultApiV2;
    this.authenticationService = authenticationService;
    autoBind(this);
  }

  async getRegistration(
    registrationId: number,
    language: LanguageCode,
  ): Promise<RegistrationResponseJSON> {
    logger.debug('Trying to load registration by id', registrationId);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<RegistrationResponseJSON> =
        await this.api.get(GetRegistrationPath(registrationId, language), {
          headers,
        });
      const data = normalizeQuestionsIntoRegistrationResponse(response.data);
      logger.info('Successfully got registration by id=', registrationId);
      return data;
    } catch (error) {
      logger.error('Error when getting registration, error=%O', error);
      throw error;
    }
  }

  async createIssues(
    request: CreateIssueRequestJSON,
  ): Promise<CreateIssueResponseJSON> {
    logger.debug('Trying to create issue for registration', request);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<CreateIssueResponseJSON> =
        await this.api.post(GetCreateIssuesPath(), request, {
          headers,
        });
      logger.info('Successfully created issue for registration', request);
      return response.data;
    } catch (error) {
      logger.error(
        'Error when created issue for registration, error=%O',
        error,
      );
      throw error;
    }
  }

  async getRegistrations(
    options: SearchRegistrationRequestJSON,
    language: LanguageCode,
  ): Promise<Pagination<RegistrationResponseJSON>> {
    logger.debug('Trying to load registrations by request=%O', options);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Pagination<RegistrationResponseJSON>> =
        await this.api.post(GetSearchRegistrationsPath(language), options, {
          headers,
        });
      const { data } = response;
      const mappedData = {
        ...data,
        list: map(data.list, normalizeQuestionsIntoRegistrationResponse),
      };
      logger.info('Successfully got registrations by request=%O', mappedData);
      return mappedData;
    } catch (error) {
      logger.error('Error when getting registrations, error=%O', error);
      throw error;
    }
  }

  async retrieveRegistration(
    registrationID: number,
    language: LanguageCode,
  ): Promise<RegistrationResponseJSON> {
    logger.debug(`Trying to retrieve registration by id=${registrationID}`);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<RegistrationResponseJSON> =
        await this.api.get(RetrieveRegistrationPath(registrationID, language), {
          headers,
        });
      const { data } = response;
      logger.info(`Success to retrieve registration by id=${registrationID}`);
      return normalizeQuestionsIntoRegistrationResponse(data);
    } catch (error) {
      logger.error(
        `Error to retrieve registration by id=${registrationID}`,
        error,
      );
      throw error;
    }
  }

  async changeRegistrationStatusByRequest(
    request: RegistrationActionWithStatusRequestJSON,
  ): Promise<ChangeRegistrationStatusOperations> {
    logger.debug(`Trying to change status of registration`);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ChangeRegistrationStatusOperations> =
        await this.api.post(ChangeRegistrationStatusPath(), request, {
          headers,
        });
      const { data } = response;
      logger.info(`Status on response to change registration status`, data);
      return data;
    } catch (error) {
      logger.error(`Error changing status of registration`, error);
      throw error;
    }
  }

  async changeRegistrationValidationErrorByRequest(
    request: RegistrationActionWithValidationErrorRequestJSON,
  ): Promise<ChangeRegistrationStatusOperations> {
    logger.debug(`Trying to change validation error of registration`);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ChangeRegistrationStatusOperations> =
        await this.api.post(ChangeRegistrationValidationErrorPath(), request, {
          headers,
        });
      const { data } = response;
      logger.info(
        `Status on response to change registration validation error`,
        data,
      );
      return data;
    } catch (error) {
      logger.error(`Error changing validation error of registration`, error);
      throw error;
    }
  }

  async updateCampaignItemsQuestions(
    registrationID: number,
    language: LanguageCode,
    campaignItems: CampaignItemJSON[],
  ): Promise<RegistrationResponseJSON> {
    logger.debug(
      'Trying to update questions for',
      registrationID,
      campaignItems,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<RegistrationResponseJSON> =
        await this.api.post(
          UpdateCampaignItemsQuestionsPath(registrationID, language),
          campaignItems,
          {
            headers,
          },
        );
      logger.info(
        'Successfully updated questions for',
        registrationID,
        response.data.campaignItems,
      );
      return response.data;
    } catch (error) {
      logger.error('Error when updating questions, error=%O', error);
      throw error;
    }
  }

  async updateFilesByType(
    registrationID: number,
    language: LanguageCode,
    files: UploadableFileJSON[],
    ignoreStatusChangeAfterCorrection = true,
  ): Promise<UploadRegistrationFilesResultJSON> {
    logger.debug('Trying to upload files for', registrationID, files);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<UploadRegistrationFilesResultJSON> =
        await this.api.post(
          UpdateFilesByTypePath(
            registrationID,
            language,
            ignoreStatusChangeAfterCorrection,
          ),
          files,
          {
            headers,
          },
        );
      logger.info(
        'Successfully uploaded files for',
        registrationID,
        response.data.files,
      );
      return response.data;
    } catch (error) {
      logger.error('Error when uploading files, error=%O', error);
      throw error;
    }
  }

  async removeFileById(
    registrationID: number,
    fileID: number,
    language: LanguageCode,
    ignoreStatusChangeAfterCorrection = true,
  ): Promise<UploadRegistrationFilesResultJSON> {
    logger.debug(
      `Trying to remove file id=${fileID} for reg id=${registrationID}`,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<UploadRegistrationFilesResultJSON> =
        await this.api.post(
          RemoveFileByIdPath(
            registrationID,
            fileID,
            language,
            ignoreStatusChangeAfterCorrection,
          ),
          {},
          {
            headers,
          },
        );
      logger.info(
        `Successfully removed file id=${fileID} for reg id=${registrationID}`,
      );
      return response.data;
    } catch (error) {
      logger.error(`Error when removing file by id=${fileID}, error=%O`, error);
      throw error;
    }
  }

  async performRegistration(
    registrationRequest: RegistrationRequestJSON,
    simulate: boolean,
    source: RegistrationSource,
  ): Promise<RegistrationResponseJSON> {
    logger.debug(
      `Trying to perform registration, simulate=${String(simulate)}`,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<RegistrationResponseJSON> =
        await this.api.post(
          PerformRegistrationPath(simulate, source),
          registrationRequest,
          { headers },
        );
      const data = normalizeQuestionsIntoRegistrationResponse(response.data);
      if (!simulate) {
        logger.info(
          'Successfully registered, ordernumber=%s',
          data.ordernumber,
        );
      } else {
        logger.info('Successfully simulated');
      }
      return data;
    } catch (error) {
      logger.error('Error when performing registration, error=%O', error);
      throw error;
    }
  }

  async updateIssue<D>(
    registrationID: number,
    issueID: number,
    language: LanguageCode,
    actions: PerformIssueActionJSON<D>[],
  ): Promise<ChangeRegistrationStatusOperations> {
    logger.debug(
      `Trying to peform issue action/update for issue id=${issueID}`,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ChangeRegistrationStatusOperations> =
        await this.api.post(
          PerformIssueActionPath(registrationID, issueID, language),
          actions,
          {
            headers,
          },
        );
      const { data } = response;
      logger.info(`Peformed issue action/update for issue id=${issueID}`);
      return data;
    } catch (error) {
      logger.error(`Error changing issue id=${issueID} of registration`, error);
      throw error;
    }
  }

  async getRegistrationItemHistory(
    registrationItemId: number,
    language: LanguageCode,
  ): Promise<RegistrationHistoryResponseJSON[]> {
    logger.debug(
      'Trying to load registration item history by id',
      registrationItemId,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<RegistrationHistoryResponseJSON[]> =
        await this.api.get(
          GetRegistrationItemHistoryPath(registrationItemId, language),
          {
            headers,
          },
        );
      const { data } = response;
      logger.info(
        'Successfully got registration item history by id=',
        registrationItemId,
      );
      return data;
    } catch (error) {
      logger.error(
        'Error when getting registration item history, error=%O',
        error,
      );
      throw error;
    }
  }

  async getRegistrationUser(
    registrationItemId: number,
    language: LanguageCode,
  ): Promise<UserJSON> {
    logger.debug(
      'Trying to load registration user by registration item id',
      registrationItemId,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<UserJSON> = await this.api.get(
        GetRegistrationUserPath(registrationItemId, language),
        {
          headers,
        },
      );
      const { data } = response;
      logger.info(
        'Successfully got registration user by registration item id=',
        registrationItemId,
      );
      return data;
    } catch (error) {
      logger.error('Error when getting registration user, error=%O', error);
      throw error;
    }
  }

  async downloadRegistrationsAsXLS(
    request: string[],
    language: LanguageCode,
  ): Promise<Blob> {
    logger.debug('Trying to download registrations by request=%O', request);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Blob> = await this.api.post(
        DownloadRegistrationAsXLSPath(language),
        request,
        {
          headers,
          responseType: 'blob',
        },
      );
      logger.info(`Successfully downloaded registrations`);
      return new Blob([response.data]);
    } catch (error) {
      logger.error('Error when downloading registrations, error=%O', error);
      throw error;
    }
  }

  async reverseRegistrations(
    request: Partial<ReverseRegistrationRequestJSON>,
    language: LanguageCode,
  ): Promise<ChangeRegistrationStatusOperations> {
    logger.debug('Trying to reverse registrations by request=%O', request);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ChangeRegistrationStatusOperations> =
        await this.api.post(ReverseRegistrationPath(language), request, {
          headers,
        });
      logger.info('Successfully reversed registrations by request=%O', request);
      return response.data;
    } catch (error) {
      logger.error(
        `Error when trying to reverse registrations, error=%O`,
        error,
      );
      throw error;
    }
  }

  async revalidateRegistrations(
    request: string[],
    language: LanguageCode,
  ): Promise<ChangeRegistrationStatusOperations> {
    logger.debug('Trying to revalidate registrations by request=%O', request);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ChangeRegistrationStatusOperations> =
        await this.api.post(RevalidateRegistrationPath(language), request, {
          headers,
        });
      logger.info(
        'Successfully revalidate registrations by request=%O',
        request,
      );
      return response.data;
    } catch (error) {
      logger.error(
        `Error when trying to revalidate registrations, error=%O`,
        error,
      );
      throw error;
    }
  }

  async getRegistrationItemComments(
    registrationItemId: number,
    language: LanguageCode,
  ): Promise<RegistrationItemCommentJSON[]> {
    logger.debug(
      'Trying to load registration item comments by id',
      registrationItemId,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<RegistrationItemCommentJSON[]> =
        await this.api.get(
          GetRegistrationItemCommentsPath(registrationItemId, language),
          {
            headers,
          },
        );
      const { data } = response;
      logger.info(
        'Successfully got registration item comments by id=',
        registrationItemId,
      );
      return data;
    } catch (error) {
      logger.error(
        'Error when getting registration item comments, error=%O',
        error,
      );
      throw error;
    }
  }
}

export default ManufacturerRegistrationService;
