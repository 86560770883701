import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import { EpicConfig, RequestType } from '@redux-async-module/interfaces';
import { Epic } from '@redux-basic-module/interfaces';
import defaultTo from 'lodash/defaultTo';
import { from, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import {
  catchError,
  filter,
  map,
  switchMap,
  tap,
  timeout,
} from 'rxjs/operators';

import { fixEpicToString } from './fixEpicToString';

const reduxLog = contextualConfig.application.logger.extend('redux-utils');
const { defaultTimeout } = contextualConfig.api;

export function createEpic<
  RootState = Record<string, any>,
  StartPayload = Record<string, any>,
  SuccessPayload = Record<string, any>,
  Meta = Record<string, any>,
>(epicConfig: EpicConfig<RootState, StartPayload, SuccessPayload, Meta>): Epic {
  const log = reduxLog.extend(epicConfig.actions.start.type);
  const epic: Epic<StartPayload, SuccessPayload> = (action$, state$) =>
    // @ts-ignore
    action$.pipe(
      ofType(epicConfig.actions.start.type),
      tap(action => log.debug('Received action', action)),
      filter(({ payload }) => {
        if (epicConfig.filter) {
          return epicConfig.filter(payload, state$.value);
        }
        return true;
      }),
      switchMap(({ payload, meta }: { payload: StartPayload; meta: Meta }) => {
        const body = defaultTo(
          epicConfig.requestOptions.getBody
            ? epicConfig.requestOptions.getBody(payload, state$.value)
            : undefined,
          payload,
        );

        const headers = {
          'Content-Type': 'application/json',
          ...defaultTo(
            epicConfig.requestOptions.getHeaders
              ? epicConfig.requestOptions.getHeaders(payload, state$.value)
              : undefined,
            {},
          ),
        };
        const path = epicConfig.requestOptions.getPath(payload, state$.value);
        log.debug('Will perform ajax request', {
          headers,
          body,
          path,
          type: epicConfig.requestOptions.type,
        });
        const promise =
          epicConfig.requestOptions.type === RequestType.get
            ? ajax.getJSON(path, headers)
            : ajax.post(path, body, headers);

        return from(promise).pipe(
          timeout(defaultTimeout),
          map(response => {
            log.debug('Success on ajax response, will return data');
            const successPayload = epicConfig.onSuccess
              ? epicConfig.onSuccess(response, payload)
              : response;
            return epicConfig.actions.success(successPayload, meta);
          }),
          catchError(error => {
            log.error('Error on ajax response', error);
            return of(epicConfig.actions.error({ error }, meta));
          }),
        );
      }),
    );
  return fixEpicToString(epic);
}
