import { LanguageCode } from '@mmw/constants-languages';
import { accessTokenQuery } from '@mmw/services-auth-api-authentication';
import qs from 'qs';
import { U } from 'ts-toolbelt';

import { AccountTypes } from './types';

const BASE_PATH = '/v1/trader/orgunit';

export const getSaveAccountUrl = (
  branchID: number,
  language: LanguageCode,
): string => `${BASE_PATH}/${branchID}/account?${qs.stringify({ language })}`;

export const getAccountUrl = (
  branchID: number,
  accountType: AccountTypes,
  language: LanguageCode,
): string =>
  `${BASE_PATH}/${branchID}/account/${accountType}?${qs.stringify({
    language,
  })}`;

export const DownloadIdentificationLetter = (
  branchID: number,
  accountType: AccountTypes,
  accessToken: U.Nullable<string>,
  language: LanguageCode,
  accountToAllBranches: boolean,
): string =>
  `${BASE_PATH}/${branchID}/account/${accountType}/document?${accessTokenQuery(
    accessToken,
  )}&${qs.stringify({
    language,
    accountToAllBranches,
  })}`;
