import contextualConfig from '@mmw/contextual-config';
import { useAvailableCountries } from '@mmw/redux-store-common-available-countries/hooks';
import find from 'lodash/find';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';

const { supportedCountries } = contextualConfig.application;

export function useCountryNameByCode(countryCode: string) {
  const availableCountries = useAvailableCountries();
  return useMemo(() => {
    const foundCountry = find(availableCountries, c => c.id === countryCode);

    if (!isEmpty(foundCountry) && includes(supportedCountries, countryCode)) {
      return foundCountry.name;
    }

    return null;
  }, [availableCountries, countryCode]);
}
