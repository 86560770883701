import { AuditState } from '@audit/utils';
import { ManufacturerAuditStatusOption } from '@manufacturer/audits-ui/types';
import { useTranslate } from '@mmw/utils-text-utils/hooks';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import { useMemo } from 'react';
import { U } from 'ts-toolbelt';

import { ORALB_AUDIT_I18N_MAPPING } from './constants';
import { OralbAuditStatus } from './types';

export function getAuditStatusIcon(
  status: U.Nullable<OralbAuditStatus>,
): string {
  const icons = {
    [OralbAuditStatus.OPEN]: 'check',
    [OralbAuditStatus.APPROVED]: 'check',
    [OralbAuditStatus.REJECTED]: 'close',
    [OralbAuditStatus.APPROVED_PENDING]: 'check',
    [OralbAuditStatus.APPROVED_FOR_INITIAL_ORDER]: 'check',
    [OralbAuditStatus.IN_VERFICATION]: 'check',
    [OralbAuditStatus.TRANSMITTED]: 'check',
    [OralbAuditStatus.NEEDS_REVISION]: 'edit',
  };
  if (status) {
    return icons[status];
  }
  return '';
}

export function useOralbAuditStatusChangeOptions(
  auditState: U.Nullable<AuditState> = null,
): ManufacturerAuditStatusOption[] {
  const translate = useTranslate();
  const allOptions = useMemo<ManufacturerAuditStatusOption[]>(
    () => [
      {
        name: translate(ORALB_AUDIT_I18N_MAPPING.OPEN),
        value: OralbAuditStatus.OPEN,
      },
      {
        name: translate(ORALB_AUDIT_I18N_MAPPING.APPROVED),
        value: OralbAuditStatus.APPROVED,
        canCreateApprovalIssues: true,
      },
      {
        name: translate(ORALB_AUDIT_I18N_MAPPING.REJECTED),
        value: OralbAuditStatus.REJECTED,
        canCreateCorrectionIssues: true,
      },
      {
        name: translate(ORALB_AUDIT_I18N_MAPPING.APPROVED_PENDING),
        value: OralbAuditStatus.APPROVED_PENDING,
        hideButton: true,
      },
      {
        name: translate(ORALB_AUDIT_I18N_MAPPING.APPROVED_FOR_INITIAL_ORDER),
        value: OralbAuditStatus.APPROVED_FOR_INITIAL_ORDER,
        canCreateApprovalIssues: true,
      },
      {
        name: translate(ORALB_AUDIT_I18N_MAPPING.IN_VERFICATION),
        value: OralbAuditStatus.IN_VERFICATION,
      },
      {
        name: translate(ORALB_AUDIT_I18N_MAPPING.TRANSMITTED),
        value: OralbAuditStatus.TRANSMITTED,
      },
      {
        name: translate(ORALB_AUDIT_I18N_MAPPING.NEEDS_REVISION),
        value: OralbAuditStatus.NEEDS_REVISION,
        requiresToCreateCorrectionIssues: true,
        canCreateCorrectionIssues: true,
        canCreateApprovalIssues: true,
      },
    ],
    [translate],
  );

  return useMemo<ManufacturerAuditStatusOption[]>(
    () =>
      filter(
        allOptions,
        ({ value }) =>
          value === auditState?.status ||
          includes(auditState?.allowedStatusTransitions, value),
      ),
    [allOptions, auditState?.status, auditState?.allowedStatusTransitions],
  );
}
