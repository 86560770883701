import mmwAssets from '@mmw/ui-system/assets';

import loginBackgroundImage from './loginBackgroundImage.jpg';
import logo from './logo.png';
import retailNameBlue from './retailNameBlue.png';
import retailNameWhite from './retailNameWhite.png';

const mmwRetailNameLogo = {
  blue: retailNameBlue,
  white: retailNameWhite,
};

const assets = {
  loginBackgroundImage,
  mmwRetailNameLogo,
  logo,
  ...mmwAssets,
};

export default assets;
