import { connect as formikConnect } from '@mmw/ui-libraries-formik-v2';
import { compose, HOC, mapProps } from 'recompose';

const withOnClickSubmit: HOC<unknown, unknown> = compose(
  formikConnect,
  mapProps(({ formik: { handleSubmit }, ...rest }) => ({
    ...rest,
    onClick: handleSubmit,
  })),
);

export default withOnClickSubmit;
