export default {
  style: ({ theme }) => `
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    ${theme.media.IE11`
      min-height: 40px;
      padding: 15px 0;
    `}
  `,
  modifiers: undefined,
  text: {
    style: ({ theme }) => `
      color: ${theme.colors.pallet.basicDark};
      ${theme.media.IE11`
        width: 550px;
        max-width: 100%;
        margin: 20px 0 0 0;
      `}
    `,
    modifiers: undefined,
  },
};
