import { I18nShape } from '@mmw/constants-i18n';
import { Modifiers } from '@mmw/ui-theme/modifiers/types';
import { concat } from '@mmw/ui-theme/modifiers/utils';
import { use } from '@mmw/ui-theme/utils';
import Icon from '@mmw/ui-web-elements-icon';
import { HELP_ICON } from '@retail/web-theme/assets';
import * as React from 'react';
import styled from 'styled-components';
import { U } from 'ts-toolbelt';
import { useDeepCompareMemo } from 'use-deep-compare';

import ErrorMessage from './elements/ErrorMessage';
import Label from './elements/Label';
import Required from './elements/Required';

const StyledField = styled.div<{ modifiers?: Modifiers }>(props =>
  use(props, 'formField'),
);

const LabelContainer = styled.span(props =>
  use(props, 'formField.labelContainer'),
);

export const HelpButtonIcon = styled(Icon)(props =>
  use(props, 'formField.helpIcon'),
);

export type OwnProps = {
  children: React.ReactNode;
  labelComponent?: React.ReactElement;
  errorComponent?: React.ReactElement;
  error?: boolean;
  required?: boolean;
  modifiers?: Modifiers;
  labelModifiers?: Modifiers;
  errorI18n?: I18nShape;
  labelI18n?: I18nShape;
  hasHelp?: boolean;
  errorAbsolute?: boolean;
  helpClick?: () => void;
  labelPrefix?: U.Nullable<string>;
  labelSuffix?: U.Nullable<string>;
};

export const FormField: React.FC<OwnProps> = ({
  children,
  error,
  labelI18n,
  labelComponent,
  errorI18n,
  errorComponent,
  required,
  modifiers,
  hasHelp,
  helpClick,
  labelModifiers,
  labelPrefix,
  labelSuffix,
  ...props
}: OwnProps) =>
  useDeepCompareMemo(
    () => (
      <StyledField modifiers={error ? 'error' : undefined} {...props}>
        {!labelComponent && (labelI18n || required || hasHelp) && (
          <LabelContainer>
            {labelI18n && (
              <Label
                modifiers={
                  error
                    ? concat(labelModifiers || modifiers, 'error')
                    : labelModifiers || modifiers
                }
                i18n={labelI18n}
                prefix={labelPrefix}
                suffix={labelSuffix}
              />
            )}
            {required && <Required>*</Required>}
            {hasHelp && <HelpButtonIcon src={HELP_ICON} onClick={helpClick} />}
          </LabelContainer>
        )}
        {labelComponent}
        {React.Children.map(children, child =>
          React.cloneElement(child, {
            ...props,
            modifiers: error
              ? concat('error', child.props.modifiers)
              : child.props.modifiers,
            required,
          }),
        )}
        {error && (errorComponent || <ErrorMessage i18n={errorI18n} />)}
      </StyledField>
    ),
    [
      error,
      props,
      labelComponent,
      labelI18n,
      labelModifiers,
      modifiers,
      required,
      hasHelp,
      helpClick,
      children,
      errorComponent,
      errorI18n,
    ],
  );

FormField.ErrorMessage = ErrorMessage;

export { FormField as default, ErrorMessage, Label, LabelContainer, Required };
