import { useDeepCompareMemo } from '@react-utils/hooks';
import { History, LocationDescriptorObject, LocationState } from 'history';
import find from 'lodash/find';
import get from 'lodash/get';

import useHistory from './useHistory';
import useLocation from './useLocation';

export const useNavigationState = <S = LocationState>():
  | S
  | Record<string, any> => {
  const location = useLocation();
  return location.state;
};

export function useNavigationStateItem<T = any>(path: string): T | null {
  const state = useNavigationState();
  return useDeepCompareMemo(() => get(state, path), [path, state]);
}

interface RouteHistory extends History {
  entries?: LocationDescriptorObject[];
}

export function useNavigationStateItemFromRoute<T = any>(
  route: string,
  path: string,
): T | null {
  const history = useHistory<RouteHistory>() as RouteHistory;
  const locationDate = find(
    history?.entries,
    (item: LocationDescriptorObject) => item?.pathname === route,
  );
  return useDeepCompareMemo(
    () => get(locationDate?.state, path),
    [path, locationDate?.state],
  );
}
