import contextualConfig from '@mmw/contextual-config';
import { RegistrationResponseJSON } from '@mmw/services-core-trader-registration/types';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { U } from 'ts-toolbelt';

import { currentCarouselItemSelector } from '../stateSelector';
import { RootState } from '../types';
import useSearchUuid from './useSearchUuid';

const { logger } = contextualConfig.application;

const useCurrentCarouselItem = (): U.Nullable<RegistrationResponseJSON> => {
  const searchUuid = useSearchUuid();
  const selector = useCallback(
    (state: RootState) => {
      if (!searchUuid) {
        logger.error('Search uuid not set, not using provider correctly!');
        return null;
      }
      return currentCarouselItemSelector(state, { searchUuid });
    },
    [searchUuid],
  );
  return useSelector(selector);
};

export default useCurrentCarouselItem;
