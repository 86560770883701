import rightArrow from '../assets/dropdown-icon.png';

export default () => `
    background: url(${rightArrow}) no-repeat transparent;
    float: right;
    width: 40px;
    height: 40px;
    border: none;
    position: absolute;
    right: 0;
    transform: rotate(-90deg);
    cursor: pointer;
`;
