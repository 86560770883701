import contextualConfig from '@mmw/contextual-config';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';

import { checkAuthenticationStartAction } from '../actions';
import { CheckAuthenticationStartAction } from '../types';

const { checkAuthenticationTimeout: CHECK_AUTHENTICATION_TIMEOUT } =
  contextualConfig.application;

const runCheckAuthenticationEpic =
  (): Observable<CheckAuthenticationStartAction> =>
    timer(0, CHECK_AUTHENTICATION_TIMEOUT).pipe(
      map(() => checkAuthenticationStartAction()),
    );

export default runCheckAuthenticationEpic;
