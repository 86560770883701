import { StyledElement } from '@mmw/ui-theme/types';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { F } from 'ts-toolbelt';

export const Overlay: StyledElement<'div', unknown> = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: transparent;
`;

const useClickOutside = (close: F.Function) => {
  const elementRef = useRef(null);

  // const onClickOutSide = useCallback(
  //   (e: React.SyntheticEvent) => {
  //     if (elementRef?.current?.contains(e.target)) {
  //       close();
  //     }
  //   },
  //   [close],
  // );

  useEffect(() => {
    function handleClickOutside(event) {
      if (elementRef.current && !elementRef.current.contains(event.target)) {
        close();
      }
    }
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [elementRef]);

  return {
    elementRef,
  };
};

export default useClickOutside;
