import menuItemStyle from './menuItem';
import style from './style';

export default {
  style,
  menuItem: {
    style: menuItemStyle,
    itemText: {
      style: ({ theme }) => `
        color: ${theme.colors.pallet.light};
      `,
    },
  },
};
