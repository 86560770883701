export default ({ theme }) => ({
  bmBurgerButton: {
    position: 'absolute',
    width: '36px',
    height: '30px',
    top: '30px',
    right: theme.media.isTablet ? '60px' : '30px',
  },
  bmBurgerBars: {
    background: theme.colors.pallet.primary,
  },
  bmBurgerBarsHover: {
    background: theme.colors.pallet.dark,
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
  },
  bmCross: {
    background: theme.colors.pallet.background,
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    width: 'none',
    top: '80px',
  },
  bmMenu: {
    background: theme.colors.pallet.dark,
    padding: '2.5em 0',
    fontSize: '1.15em',
  },
  bmMorphShape: {
    fill: theme.colors.pallet.basic,
  },
  bmItemList: {
    color: theme.colors.pallet.light,
    display: 'flex',
    flexDirection: 'column',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
});
