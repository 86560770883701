import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import { orgunitGroupnameByAuthSelector } from '@mmw/redux-store-core-orgunit/selectors';
import { ActionsObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  tap,
  timeout,
  withLatestFrom,
} from 'rxjs/operators';

import {
  getOrgunitGroupNamesErrorAction,
  getOrgunitGroupNamesSuccessAction,
} from '../actions';
import { RootState } from '../stateSelector';
import {
  GET_ORGUNIT_GROUP_NAMES_START,
  GetOrgunitGroupNamesErrorAction,
  GetOrgunitGroupNamesStartAction,
  GetOrgunitGroupNamesSuccessAction,
} from '../types';

const { logger } = contextualConfig.application;
const { defaultTimeout } = contextualConfig.api;

type Input = GetOrgunitGroupNamesStartAction;
type Output =
  | GetOrgunitGroupNamesSuccessAction
  | GetOrgunitGroupNamesErrorAction;
const getOrgunitGroupNamesEpic = (
  action$: ActionsObservable<Input>,
  state$: Observable<RootState>,
): Observable<Output> =>
  action$.pipe(
    ofType(GET_ORGUNIT_GROUP_NAMES_START),
    withLatestFrom(state$.pipe(map(orgunitGroupnameByAuthSelector))),
    tap(() => logger.debug('Get Logged in trader data Start')),
    switchMap(([, getGroupnames]) =>
      from(getGroupnames() || []).pipe(
        timeout(defaultTimeout),
        tap(() => logger.debug('Got logged user data')),
        map(data => getOrgunitGroupNamesSuccessAction(data)),
        catchError(error => of(getOrgunitGroupNamesErrorAction(error))),
      ),
    ),
  );

export default getOrgunitGroupNamesEpic;
