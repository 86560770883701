import { ChangeRegistrationStatusOperations } from '@mmw/services-core-manufacturer-registration/types';
import {
  CampaignItemJSON,
  RegistrationResponseJSON,
} from '@mmw/services-core-trader-registration/types';
import { getFieldPaths } from '@mmw/utils-object-utils';
import { U } from 'ts-toolbelt';

export const NAMESPACE = 'update-audit';

export enum WizardDirections {
  BACKWARD,
  FORWARD,
}

export type UpdateAuditStartPayload = {
  registrationID: number;
  campaignItems: CampaignItemJSON[];
  shouldSubmitOnMove: boolean;
  storeId: number;
  currentQuestionIndex: number;
};

export type UpdateAuditSuccessPayload =
  | {
      updated: boolean;
      audit: RegistrationResponseJSON;
      operation: U.Nullable<ChangeRegistrationStatusOperations>;
      completed: boolean;
    }
  | {
      updated: false;
      audit: null;
      operation: null;
      completed: false;
    };

export type UpdateAuditErrorPayload = Error;

export type UploadAuditSuccessAction = {
  type: 'UPLOAD_AUDIT_SUCCESS';
  payload: UpdateAuditSuccessPayload;
  meta: any;
};

export type StartWizardPayload = {
  questionsAmount: number;
};

export type MoveWizardStartPayload = {
  direction?: WizardDirections;
  position?: number;
};

export type MoveWizardSuccessPayload = {
  newIndex: number;
};

export type MoveWizardErrorPayload = Error;

export class State {
  loading = false;

  completed = false;

  error: U.Nullable<Error> = null;

  currentQuestionIndex = 0;

  questionsAmount: U.Nullable<number> = null;

  operation: U.Nullable<ChangeRegistrationStatusOperations> = null;
}

export const FIELDPATHS = getFieldPaths(new State());

export interface RootState {
  [NAMESPACE]: State;
}
