export enum SalesType {
  NOT_INFORMED = 0,
  ONLINE = 1,
  OFFLINE = 2,
  BOTH = 3,
}

export const { NOT_INFORMED, ONLINE, OFFLINE, BOTH } = SalesType;
export const VALUES = [
  SalesType.NOT_INFORMED,
  SalesType.ONLINE,
  SalesType.OFFLINE,
  SalesType.BOTH,
];
