import { OrgunitSignupFormData } from '@auth/redux-store-user-account/types';
import { FastFormOptions, FastFormProvider } from '@mmw/redux-store-fast-form';
import { merge } from 'lodash';
import React, { ReactNode, useMemo } from 'react';
import { F } from 'ts-toolbelt';
import uuidv4 from 'uuid/v4';
import { ObjectSchema } from 'yup';

import {
  CityField,
  CompanyNameField,
  ConfirmPasswordField,
  CountryField,
  FantasyNameField,
  FirstNameField,
  LastNameField,
  LegalFormField,
  NrField,
  PhoneField,
  SalesTaxIdField,
  StreetField,
  TaxNumberField,
  Terms,
  ZipCodeField,
} from './elements/fields';

const INITIAL_VALUES: OrgunitSignupFormData = {
  city: '',
  company: '',
  confirmPassword: '',
  country: '',
  displayName: '',
  email: '',
  firstName: '',
  lastName: '',
  legalForm: 0,
  password: '',
  phone: '',
  salesType: undefined,
  street: '',
  nr: '',
  tan: '',
  taxnumber: '',
  salestaxid: '',
  terms: false,
  zipcode: '',
  createAcceptedBusinessRelations: undefined,
};

type SignUpPropsType = {
  children: ReactNode;
  validationSchema: ObjectSchema<OrgunitSignupFormData>;
  onSubmitSuccess: F.Function<[OrgunitSignupFormData]>;
  onSubmitError?: F.Function;
  initialValues?: OrgunitSignupFormData;
  options?: Partial<FastFormOptions<OrgunitSignupFormData>>;
  formId?: string;
};

interface SignUpStatics {
  ConfirmPasswordField: typeof ConfirmPasswordField;
  PhoneField: typeof PhoneField;
  CompanyNameField: typeof CompanyNameField;
  FantasyNameField: typeof FantasyNameField;
  StreetField: typeof StreetField;
  NrField: typeof NrField;
  ZipCodeField: typeof ZipCodeField;
  CityField: typeof CityField;
  CountryField: typeof CountryField;
  LegalFormField: typeof LegalFormField;
  TaxNumberField: typeof TaxNumberField;
  SalesTaxIdField: typeof SalesTaxIdField;
  FirstNameField: typeof FirstNameField;
  LastNameField: typeof LastNameField;
  Terms: typeof Terms;
}

export const FORM_ID = uuidv4();

const SignUp: React.FC<SignUpPropsType> & SignUpStatics = ({
  children,
  validationSchema,
  onSubmitSuccess,
  onSubmitError,
  initialValues,
  options,
  formId,
}: SignUpPropsType) => {
  const mergedInitialValues = useMemo(
    () => merge(INITIAL_VALUES, initialValues),
    [initialValues],
  );
  return (
    <FastFormProvider
      onSubmitError={onSubmitError}
      validationSchema={validationSchema}
      initialValues={mergedInitialValues}
      onSubmitSuccess={onSubmitSuccess}
      options={options}
      formId={formId || FORM_ID}
    >
      {children}
    </FastFormProvider>
  );
};

SignUp.ConfirmPasswordField = ConfirmPasswordField;
SignUp.PhoneField = PhoneField;
SignUp.CompanyNameField = CompanyNameField;
SignUp.FantasyNameField = FantasyNameField;
SignUp.StreetField = StreetField;
SignUp.NrField = NrField;
SignUp.ZipCodeField = ZipCodeField;
SignUp.CityField = CityField;
SignUp.CountryField = CountryField;
SignUp.LegalFormField = LegalFormField;
SignUp.TaxNumberField = TaxNumberField;
SignUp.SalesTaxIdField = SalesTaxIdField;
SignUp.FirstNameField = FirstNameField;
SignUp.LastNameField = LastNameField;
SignUp.Terms = Terms;

export default SignUp;
