import { library } from '@fortawesome/fontawesome-svg-core';
import { faCaretDown, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useAvailableStores,
  useChangeStore,
  useSelectedStoreName,
} from '@mmw/redux-store-core-trader-logged-user/hooks';
import { AvailableStore } from '@mmw/services-core-trader-orgunit/types';
import { Theme } from '@mmw/ui-theme/types';
import { Body2 } from '@mmw/ui-web-elements-typography';
import useIsVisible from '@mmw/ui-web-hooks-use-is-visible';
import React from 'react';
import styled from 'styled-components';
import { U } from 'ts-toolbelt';

library.add(faHome, faCaretDown);

const ICON_FAS_CARETDOWN = ['fas', 'caret-down'];

const CaretDownIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.mmw.colors.palette.primary};
`;
const Dropdown = styled.div`
  display: flex;
`;

const DropdownButton = styled.div`
  ${({ theme }) => `
      border: 1px solid ${theme.colors.pallet.basic};
      ${theme.media.phone`
        width: 160px;
      `}
  `};
  background-color: ${({ theme }) => theme.colors.pallet.light};
  display: flex;
  margin-bottom: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  width: 270px;
  height: 30px;
  padding: 0 10px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  ${({ theme }) => theme.media.phone`
    width: 160px;
  `}
`;

const DropdownSelected = styled(Body2)`
  color: ${({ theme }) => theme.mmw.colors.palette.primary};
  white-space: nowrap;
  width: 270px;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ theme }) => theme.media.phone`
    width: 160px;
  `}
`;
const DropdownItemText = styled(Body2)`
  color: ${({ theme }) => theme.mmw.colors.palette.primary};
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: transparent;
  z-index: 1;
`;

const DropdownItems = styled.div(
  ({ theme }) => `
  position: absolute;
  z-index: 2;
  top: 32px;
  max-height: 300px;
  overflow: auto; 
  ${theme.media.IE11`right: 35px;`}
  cursor: pointer;
`,
);

const DropdownItem = styled.div<{
  active: boolean;
}>`
  display: flex;
  background-color: ${({ theme }) => theme.colors.pallet.light};
  width: 300px;
  /* height: auto; */
  padding: 10px 10px;
  align-items: center;
  ${({ theme }) => theme.media.phone`width: 220px;`};
  ${({ theme, active }: { active: boolean; theme: Theme }) => {
    if (active) {
      return `
      span {
        color: ${theme.colors.pallet.light};
      }
      background-color: ${theme.mmw.colors.palette.primary};`;
    }
    return '';
  }}}
  ${({ theme }) => `
     border-bottom: 1px solid ${theme.colors.pallet.basic};
     border-left: 1px solid ${theme.colors.pallet.basic};
     border-right: 1px solid ${theme.colors.pallet.basic};
  `};
  &:hover {
    background-color: ${({ theme }) => theme.mmw.colors.palette.primary};
    color: ${({ theme }) => theme.colors.pallet.light};
    span {
      color: ${({ theme }) => theme.colors.pallet.light};
    }
  }
   :first-child {
    border-top-right-radius: 3px ;
    border-top-left-radius: 3px ;
    ${({ theme }) => `
     border: 1px solid ${theme.colors.pallet.basic};
  `};
  }
  :last-child {
    border-top: none;
    border-bottom-right-radius: 3px ;
    border-bottom-left-radius: 3px ;
  }
`;

const IconCaret = styled.span`
  ${({ theme }) => `
      border: 1px solid ${theme.colors.pallet.basic};
  `};
  display: flex;
  background-color: ${({ theme }) => theme.colors.pallet.light};
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-left: 0;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

type Props = {
  selectedStoreName: U.Nullable<string>;
  toggle: () => void;
};

const DropdownContent: React.FC<Props> = ({
  toggle,
  selectedStoreName,
}: Props) => {
  // const { elementRef } = useClickOutside(toggle);
  const availableStores = useAvailableStores();
  const changeStore = useChangeStore();

  return (
    <>
      <Overlay onClick={toggle} />
      <DropdownItems>
        {availableStores.map((store: AvailableStore) => (
          <DropdownItem
            key={store.name}
            active={store.name === selectedStoreName}
            onClick={() => {
              changeStore(store.id);
              toggle();
            }}
          >
            <DropdownItemText>{store.name}</DropdownItemText>
          </DropdownItem>
        ))}
      </DropdownItems>
    </>
  );
};

const DropdownTrader: React.FC = () => {
  const { isVisible, toggle } = useIsVisible();
  const selectedStoreName = useSelectedStoreName();
  if (!selectedStoreName) return null;
  return (
    <>
      <Dropdown>
        <DropdownButton onClick={toggle}>
          <DropdownSelected>{selectedStoreName}</DropdownSelected>
        </DropdownButton>
        <IconCaret onClick={toggle}>
          <CaretDownIcon icon={ICON_FAS_CARETDOWN} />
        </IconCaret>
        {isVisible ? (
          <DropdownContent
            selectedStoreName={selectedStoreName}
            toggle={toggle}
          />
        ) : null}
      </Dropdown>
    </>
  );
};

export default DropdownTrader;
