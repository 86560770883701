import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import { U } from 'ts-toolbelt';

function handleFinalResult<T extends object>(
  result: Record<string, any>,
): U.Nullable<T> {
  if (isEmpty(result)) return null;
  const reulstJson = result.body;
  let splittedJson = reulstJson.split('\n');
  splittedJson = splittedJson.filter(item => !isEmpty(item));
  if (!splittedJson) return null;
  return JSON.parse(last(splittedJson) as string);
}

export default handleFinalResult;
