import qs from 'qs';

const BASE_PATH = '/v1/manu/product';

export const GetManuProductsPath = (request, language): string =>
  `${BASE_PATH}/?${qs.stringify({
    language,
  })}`;

export const GetManuProductsCampaignsPath = (request, language): string =>
  `${BASE_PATH}/products-campaigns?${qs.stringify({
    language,
  })}`;

export const GetManuProductsClassOfGoods = (request, language): string =>
  `${BASE_PATH}/classofgoods-hierarchy?${qs.stringify({
    ...request,
    language,
  })}`;
