import { resetBankDataAction } from '@consumer/bank-account-redux-store/actions';
import { useNavigateToAuthentication } from '@consumer/navigation/hooks';
import { resetProfileDataAction } from '@consumer/profile-redux-store/actions';
import { useLogoutStart } from '@mmw/redux-store-auth-api-authentication/hooks';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

function useUserLogoutAction() {
  const navigateToAuth = useNavigateToAuthentication();
  const dispatch = useDispatch();
  const onAfterLogout = useCallback(() => {
    dispatch(resetProfileDataAction());
    dispatch(resetBankDataAction());
    navigateToAuth();
  }, [dispatch, navigateToAuth]);
  const logout = useLogoutStart(onAfterLogout);
  return useCallback(() => {
    logout();
  }, [logout]);
}

export default useUserLogoutAction;
