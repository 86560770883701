import { CampaignType } from '@mmw/constants-campaign-types';
import { CountryCode } from '@mmw/constants-country-codes';
import { FileType } from '@mmw/constants-file-types';
import { QuestionGroupDisplayType } from '@mmw/constants-question-group-display-types';
import { QuestionGroupType } from '@mmw/constants-question-group-types';
import { Roles } from '@mmw/constants-roles';
import { Title as TitleEnum } from '@mmw/constants-titles-salutations';
import contextualConfig from '@mmw/contextual-config';
import { AuthenticationAuthorities } from '@mmw/services-auth-api-authentication/types';
import { getFieldPaths } from '@shared-utils/object';
import { cloneDeep } from 'lodash';
import { U } from 'ts-toolbelt';

const { defaultCountry: DEFAULT_COUNTRY } = contextualConfig.application;

export type ABNValidationResult = {
  available: boolean;
  valid: boolean;
};

export type Pagination<T = unknown> = {
  list: T[];
  limit: number;
  offset: number;
  total: number;
  fullList?: T[];
  currentPage?: number;
};

export class PaginatorHandler<T = unknown>
  implements Omit<Pagination<T>, 'fullList' | 'currentPage'>
{
  list: T[] = [];

  limit = 10;

  offset = 0;

  total = 0;

  constructor(initial = {} as PaginatorHandler<T>) {
    return { ...this, ...initial };
  }
}

export type DomainValue = {
  domkey: string;
  value: string;
};

export type ResourceJSON = {
  resourcekey: string;
  resource: string;
};

export type Item<T, P> = {
  id: T;
  name: P;
};

export type SimpleApiConnectionStatusJSON = {
  environment: string;
  host: string;
  version: string;
};

export type Country = Item<string, string>;
export type SalesType = Item<number, string>;
export type Salutation = Item<string, string>;
export type Title = Item<string, string>;
export type Language = Item<string, string>;

export type OperationResult = {
  success: boolean;
};

export type AddressJSON = {
  addressID?: U.Nullable<number>;
  company?: U.Nullable<string>;
  street: U.Nullable<string>;
  nr: U.Nullable<string>;
  complement?: U.Nullable<string>;
  zipcode: U.Nullable<string>;
  city: U.Nullable<string>;
  state: U.Nullable<string>;
  country: U.Nullable<string>;
  name1?: U.Nullable<string>;
  name2?: U.Nullable<string>;
  lat?: U.Nullable<number>;
  lng?: U.Nullable<number>;
  distance?: U.Nullable<number>;
  apiVerified?: boolean;
};

export const EMPTY_ADDRESS_JSON: AddressJSON = {
  addressID: null,
  company: '',
  street: '',
  nr: '',
  complement: '',
  zipcode: '',
  city: '',
  state: '',
  country: DEFAULT_COUNTRY,
  name1: '',
  name2: '',
  lat: null,
  lng: null,
  distance: null,
  apiVerified: false,
};

export type PersonJSON = {
  personID?: U.Nullable<number>;
  firstname?: U.Nullable<string>;
  lastname?: U.Nullable<string>;
  birthdate?: Date | null;
  email?: U.Nullable<string>;
  phone?: U.Nullable<string>;
  phonework?: U.Nullable<string>;
  phonemobile?: U.Nullable<string>;
  phonemobilework?: U.Nullable<string>;
  fax?: U.Nullable<string>;
  address?: U.Nullable<AddressJSON>;
  gender?: U.Nullable<string>;
  domkeytitle?: U.Nullable<string>;
  domkeysalutation?: U.Nullable<string>;
  // eslint-disable-next-line camelcase
  erp_personkey?: U.Nullable<string>;
  preferredLanguage?: U.Nullable<string>;
};

export const EMPTY_PERSON_JSON: PersonJSON = {
  personID: null,
  firstname: '',
  lastname: '',
  birthdate: null,
  email: '',
  phone: '',
  phonework: '',
  phonemobile: '',
  phonemobilework: '',
  fax: '',
  address: {
    ...EMPTY_ADDRESS_JSON,
  },
  gender: '',
  domkeytitle: TitleEnum.EMPTY,
  domkeysalutation: '',
  erp_personkey: '',
};

export type CompanyJSON = {
  companyID: U.Nullable<number>;
  fax: U.Nullable<string>;
  phone: U.Nullable<string>;
  salestaxid: U.Nullable<string>;
  taxnumber: U.Nullable<string>;
  homepage: U.Nullable<string>;
  crnumber: U.Nullable<string>;
  crcity: U.Nullable<string>;
  email: U.Nullable<string>;
  company: U.Nullable<string>;
  displayname?: U.Nullable<string>;
  gln?: U.Nullable<string>;
  managementbody?: U.Nullable<string>;
  salestype?: U.Nullable<SalesType>;
  legalform?: U.Nullable<number>;
};

export interface ERPOrgunitItemJSON extends CompanyJSON {
  id: number;
  participant: number;
  owner: string;
  address: AddressJSON;
  account: AccountJSON;
}

export const EMPTY_COMPANY_JSON: CompanyJSON = {
  companyID: null,
  fax: '',
  phone: '',
  salestaxid: '',
  taxnumber: '',
  homepage: '',
  crnumber: '',
  crcity: '',
  email: '',
  company: '',
};

export type ConsumerJSON = {
  consumerID?: U.Nullable<number>;
  consumerName?: U.Nullable<string>;
  deleted?: Date | null | void;
  person: PersonJSON;
  company?: CompanyJSON | null | void;
  membernumber?: U.Nullable<string>;
  created?: Date | null | void;
  newsletter?: boolean;
};

export const EMPTY_CONSUMER_JSON: ConsumerJSON = {
  consumerID: null,
  consumerName: '',
  deleted: null,
  person: {
    ...EMPTY_PERSON_JSON,
  },
  company: {
    ...EMPTY_COMPANY_JSON,
  },
  membernumber: '',
  created: null,
  newsletter: false,
};

export const emptyConsumerJSON = (): ConsumerJSON =>
  cloneDeep(EMPTY_CONSUMER_JSON);

export type UploadableFileJSON = {
  type: FileType;
  name: string;
  format: string;
  file: U.Nullable<string>; // BASE 64,,
  uri?: U.Nullable<string>;
  blob?: U.Nullable<Blob>; // only due to conversion, not needed possibly
  metadata?: Record<string, string>;
};

export type UploadableFileBlob = {
  type: FileType;
  name: string;
  format: string;
  blob: File; // comes from Upload component
};

export type UploadableFileAny = {
  type: FileType;
  name: string;
  format: string;
  file: any;
};

export type FileJSON = {
  fileID: number;
  name: string;
  format: string;
  uuid: string;
  created: Date;
  url: string;
  externalURL: string;
  type: FileType;
  createdBy: {
    userid: string;
    firstname: string;
    lastname: string;
    enduserID: number;
  };
  metadata?: Record<string, string>;
};

export interface UploadFilesResultJSON {
  success: boolean;
  files: FileJSON[];
  error: string;
}

export type QuestionJSON = {
  id: number;
  answer: string | boolean | null | void; // XXX: called normalize on trader reg service,
  expectedAnswer: U.Nullable<string>;
  code: string;
  text: U.Nullable<string>; // XXX: it can be an "OTHER" field,
  position: number;
  answerID: U.Nullable<number>;
  isOther: boolean;
};

export type QuestionGroupJSON = {
  id: number;
  text: string;
  type: QuestionGroupType;
  questions: QuestionJSON[];
  answer: U.Nullable<string | boolean>; // XXX: called normalize on trader reg service,
  displayType: QuestionGroupDisplayType;
  position: number;
  code: string;
  otherAnswer: U.Nullable<string>;
  required: boolean;
  requiresUploads?: U.Nullable<number>;
};

export type CampaignAddedValueJSON = {
  campaignAddedValueID: number;
  name: string;
  available: number;
  code: string;
  image: U.Nullable<string>; // url
};

export type CampaignConfigsJSON = {
  campaignClientPageTitle: string;
  campaignClientUrl: string;
  campaignId: number;
  disabledOverviewPages: boolean;
  estimatedRegistrationNumber: any;
  jiraIssueKey: any;
  linkGeneratorUrl: string;
  otrsQueueId: any;
  otrsQueueName: string;
  phonemonitorQueueName: string;
  qmQueueName: string;
  registrationAlertRecipients: any;
  staticContextPath: string;
};

type CampaignStaticResource = {
  imageStaticPath: string;
  imageUrl: string;
  resourceType: string;
  staticPathType: string;
  format: string;
  canUpload: boolean;
  name: string;
};

export type CampaignJSON = {
  campaignID: number;
  salesOrgBrand: number;
  activated: boolean;
  name: string;
  image: string;
  description: U.Nullable<string>;
  code: string;
  type: CampaignType;
  imageNotNull: boolean;
  optional: boolean;
  requiresAccount: boolean;
  parent: boolean;
  mailingDate: Date | null | void;
  validfrompurchase: Date;
  validtopurchase: Date;
  enddate: Date;
  startdate: Date;
  addedValues: Array<CampaignAddedValueJSON>; // if campaign is GIFT and has configured
  configs: CampaignConfigsJSON;
  staticResources: CampaignStaticResource[];
};

export type LegalFormJSON = {
  id: number;
  name: string;
  country: string;
};

export type AccountJSON = {
  accountowner: U.Nullable<string>;
  account: U.Nullable<string>;
  country: CountryCode;
  bic: U.Nullable<string>;
  iban: U.Nullable<string>;
  agency: U.Nullable<string>;
  manualbankname: U.Nullable<string>;
  ignoreSave?: boolean;
  authenticated?: boolean;
  accountID: number;
  type: 0;
};

export class UserJSON {
  id: number;

  userid: string;

  email: string;

  roles: Roles[];

  authorities: AuthenticationAuthorities[];

  addPermissionGroups: string[];

  firstname: string;

  lastname: string;

  salesmanId: string;

  phone?: string;

  lastLogin?: Date;

  preferredLanguage?: string;

  group?: string;

  isKamUser?: U.Nullable<boolean> = null;
}

export const USER_FIELD_PATHS = getFieldPaths(new UserJSON());
export interface UserRequestJSON extends UserJSON {
  offset: number;
  limit: number;
}

export type OwnerJSON = {
  id: number;
  participant: number;
  owner: string;
  address: AddressJSON;
  phone: string;
  crcity: string;
  crnumber: string;
  email: string;
  homepage: string;
  taxnumber: string;
  salestaxid: string;
  account: AccountJSON;
  displayname: string;
  gln: string;
  managementbody: string;
  salestype: number;
  legalform: number;
};
