import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import { getConsumerBankAccountService } from '@mmw/services-holder';
import { Action } from '@reduxjs/toolkit';
import { ActionsObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { saveConsumerBankData } from '../actions';

const { logger } = contextualConfig.application;

type Output =
  | ReturnType<typeof saveConsumerBankData.success>
  | ReturnType<typeof saveConsumerBankData.error>;

const saveConsumerBankDataEpic = (
  action$: ActionsObservable<Action>,
  // state$: Observable<RootState & VerifState>,
): Observable<Output> =>
  action$.pipe(
    ofType(saveConsumerBankData.start.match),
    tap(() => logger.debug('Trying to save consumer personal data')),
    // withLatestFrom(state$.pipe(map(notAuthenticatedOperationTokenSelector))),
    mergeMap(({ payload: bankAccount }) =>
      from(
        getConsumerBankAccountService().saveConsumerBankData({
          ...bankAccount,
          notAuthenticatedOperationToken:
            bankAccount.notAuthenticatedOperationToken,
        }),
      ).pipe(
        map(data => saveConsumerBankData.success(data)),
        catchError(error => of(saveConsumerBankData.error(error))),
      ),
    ),
  );

export default saveConsumerBankDataEpic;
