import { enableBatching } from 'redux-batched-actions';
import { IModule } from 'redux-dynamic-modules';

import userAccountEpics, { Epics } from './epics';
import accountCreationReducer from './reducer';
import { NAMESPACE as userAccountNamespace, RootState } from './types';

export const reducer = accountCreationReducer;
export const NAMESPACE = userAccountNamespace;
export * from './epics';
export { default as epics } from './epics';

export function userAccountModule(): IModule<RootState> & {
  epics: Epics;
} {
  return {
    id: NAMESPACE,
    reducerMap: {
      [NAMESPACE]: enableBatching(reducer),
    },
    epics: userAccountEpics,
  };
}
