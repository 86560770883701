import { StyledProps } from '@mmw/ui-theme/types';

import { ColumnProps } from '../elements/Column';

export default ({
  theme,
  noWrap,
  center,
}: StyledProps<ColumnProps>): string => `
  padding: 6px 12px;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: ${theme.colors.pallet.secondary};
  ${noWrap ? 'white-space: no-wrap;' : ''};
  ${center ? 'text-align: center;' : ''}
`;
