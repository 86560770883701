import { SignAgreementForm } from '@agreement/view-form-data';
import { LanguageCode } from '@mmw/constants-languages';
import { AuthenticationAuthorities } from '@mmw/services-auth-api-authentication/types';
import { AuthenticatedServiceController } from '@mmw/services-auth-api-authentication/utils/controller';
import { Pagination } from '@mmw/services-core-common/types';
import {
  OrgUnitDataJSON,
  OrgUnitDetailsJSON,
  OrgUnitJSON,
  OrgUnitRequest,
  OrgUnitRequestQuerystring,
} from '@mmw/services-core-manu-orgunit/types';
import { ContractAgreementJSON } from '@mmw/services-core-trader-contracts/types';
import {
  AvailableStore,
  CompanyDetails,
  CreateCompanyDetails,
  Salesman,
} from '@mmw/services-core-trader-orgunit/types';
import {
  getManufacturerOrgunitService,
  getTraderContractsService,
  getTraderOrgunitService,
} from '@mmw/services-holder';

type CompanyController = AuthenticatedServiceController<
  CompanyDetails | OrgUnitDataJSON,
  [number | null]
>;
type CompaniesController = AuthenticatedServiceController<
  Record<number, CompanyDetails> | Pagination<OrgUnitJSON>,
  [OrgUnitRequest, LanguageCode | void, OrgUnitRequestQuerystring | void]
>;
type SaveController = AuthenticatedServiceController<
  CompanyDetails,
  [CompanyDetails, number | null, LanguageCode]
>;
type BranchController = AuthenticatedServiceController<
  CompanyDetails,
  [CreateCompanyDetails]
>;
type BranchesController = AuthenticatedServiceController<
  AvailableStore[],
  [void]
>;
type GroupnameController = AuthenticatedServiceController<string[], [void]>;
type SalesmenController = AuthenticatedServiceController<Salesman[], [void]>;
type SalestypeController = AuthenticatedServiceController<
  CompanyDetails,
  [number, number]
>;
type AcceptController = AuthenticatedServiceController<
  ContractAgreementJSON,
  [
    SignAgreementForm & {
      orgunitID: number;
      offerorID: number;
    },
    LanguageCode,
  ]
>;

function tryto<S extends (...args: any) => any>(
  cb: S,
  fallback = {},
): ReturnType<S> {
  /**
   * will bypass failed callbacks
   * use it case scope not needed
   */
  try {
    return cb();
  } catch (e) {
    return fallback as ReturnType<typeof cb>;
  }
}

export default class OrgunitServiceController {
  static company: CompanyController = {
    trader: {
      authorities: [
        AuthenticationAuthorities.PERM_TRADER_SOLAR_PROJECTS,
        AuthenticationAuthorities.ROLE_SOLARDIST,
        AuthenticationAuthorities.PERM_TRADER_ORGUNIT_DETAILS_VIEW,
        AuthenticationAuthorities.ROLE_USER,
      ],
      callback: tryto(getTraderOrgunitService)?.getCompanyDetails,
    },
    manufacturer: {
      authorities: [
        AuthenticationAuthorities.PERM_MANUFACTURER_SOLAR_PROJECTS,
        AuthenticationAuthorities.ROLE_RMANUFCT,
      ],
      callback: tryto(getManufacturerOrgunitService)?.retrieveOrgunit,
    },
  };

  static companies: CompaniesController = {
    trader: {
      authorities: [
        AuthenticationAuthorities.PERM_TRADER_SOLAR_PROJECTS,
        AuthenticationAuthorities.PERM_TRADER_ORGUNIT_DETAILS_VIEW,
        AuthenticationAuthorities.ROLE_USER,
      ],
      callback: ({ ids }) =>
        tryto(getTraderOrgunitService)?.getAllCompanyDetails(ids),
    },
    manufacturer: {
      authorities: [
        AuthenticationAuthorities.PERM_MANUFACTURER_SOLAR_PROJECTS,
        AuthenticationAuthorities.ROLE_RMANUFCT,
      ],
      callback: tryto(getManufacturerOrgunitService)?.getOrgUnits,
    },
  };

  static branch: BranchController = {
    trader: {
      authorities: [
        AuthenticationAuthorities.PERM_TRADER_SOLAR_PROJECTS,
        AuthenticationAuthorities.PERM_TRADER_ORGUNIT_DETAILS_VIEW,
        AuthenticationAuthorities.PERM_TRADER_PROFILE,
        AuthenticationAuthorities.ROLE_USER,
      ],
      callback: tryto(getTraderOrgunitService)?.createBranch,
    },
    manufacturer: {
      authorities: [
        AuthenticationAuthorities.PERM_MANUFACTURER_SOLAR_PROJECTS,
        AuthenticationAuthorities.ROLE_RMANUFCT,
      ],
      callback: () => null,
    },
  };

  static branches: BranchesController = {
    trader: {
      authorities: [
        AuthenticationAuthorities.PERM_TRADER_SOLAR_PROJECTS,
        AuthenticationAuthorities.PERM_TRADER_ORGUNIT_DETAILS_VIEW,
        AuthenticationAuthorities.PERM_TRADER_PROFILE,
        AuthenticationAuthorities.ROLE_USER,
      ],
      callback: tryto(getTraderOrgunitService)?.getAvailableStores,
    },
    manufacturer: {
      authorities: [
        AuthenticationAuthorities.PERM_MANUFACTURER_SOLAR_PROJECTS,
        AuthenticationAuthorities.ROLE_RMANUFCT,
      ],
      callback: () => null,
    },
  };

  static groupname: GroupnameController = {
    trader: {
      authorities: [
        AuthenticationAuthorities.PERM_TRADER_SOLAR_PROJECTS,
        AuthenticationAuthorities.PERM_TRADER_ORGUNIT_DETAILS_VIEW,
        AuthenticationAuthorities.ROLE_USER,
      ],
      callback: tryto(getTraderOrgunitService)?.getOrgunitGroupNames,
    },
    manufacturer: {
      authorities: [
        AuthenticationAuthorities.PERM_MANUFACTURER_SOLAR_PROJECTS,
        AuthenticationAuthorities.ROLE_RMANUFCT,
      ],
      callback: () => null,
    },
  };

  static salesmen: SalesmenController = {
    trader: {
      authorities: [
        AuthenticationAuthorities.PERM_TRADER_SOLAR_PROJECTS,
        AuthenticationAuthorities.PERM_TRADER_ORGUNIT_DETAILS_VIEW,
        AuthenticationAuthorities.ROLE_USER,
      ],
      callback: tryto(getTraderOrgunitService)?.getSalesmen,
    },
    manufacturer: {
      authorities: [
        AuthenticationAuthorities.PERM_MANUFACTURER_SOLAR_PROJECTS,
        AuthenticationAuthorities.ROLE_RMANUFCT,
      ],
      callback: () => null,
    },
  };

  static salestype: SalestypeController = {
    trader: {
      authorities: [
        AuthenticationAuthorities.PERM_TRADER_SOLAR_PROJECTS,
        AuthenticationAuthorities.PERM_TRADER_ORGUNIT_DETAILS_VIEW,
        AuthenticationAuthorities.ROLE_USER,
      ],
      callback: tryto(getTraderOrgunitService)?.updateSalesType,
    },
    manufacturer: {
      authorities: [
        AuthenticationAuthorities.PERM_MANUFACTURER_SOLAR_PROJECTS,
        AuthenticationAuthorities.ROLE_RMANUFCT,
      ],
      callback: () => null,
    },
  };

  static save: SaveController = {
    trader: {
      authorities: [
        AuthenticationAuthorities.PERM_TRADER_SOLAR_PROJECTS,
        AuthenticationAuthorities.PERM_TRADER_ORGUNIT_DETAILS_VIEW,
        AuthenticationAuthorities.ROLE_USER,
      ],
      callback: tryto(getTraderOrgunitService)?.saveCompanyDetails,
    },
    manufacturer: {
      authorities: [
        AuthenticationAuthorities.PERM_MANUFACTURER_SOLAR_PROJECTS,
        AuthenticationAuthorities.ROLE_RMANUFCT,
      ],
      callback: async (company, id, language) => {
        const res = id
          ? await getManufacturerOrgunitService().updateOrgunitDetails(
              id,
              {
                ...new OrgUnitDetailsJSON(),
                ...company,
              },
              language,
            )
          : null;
        return res ? { ...res, companyId: res.id, address: res } : null;
      },
    },
  };

  static accept: AcceptController = {
    trader: {
      authorities: [],
      callback: async (
        {
          city,
          company,
          country,
          street,
          zipcode,
          nr,
          legalform,
          salestaxid,

          orgunitID,
          offerorID,
          authorizedSignatory,
          role,
        },
        language,
      ) => {
        const details = (await OrgunitServiceController.company.trader.callback(
          orgunitID,
        )) as CompanyDetails;
        const mappedData: CompanyDetails = {
          ...details,
          address: {
            ...(details.address || {}),
            city,
            company,
            country,
            street,
            zipcode,
            nr,
          },
          legalform,
          salestaxid,
        };
        OrgunitServiceController.save.trader.callback(
          mappedData,
          orgunitID,
          language,
        );

        return getTraderContractsService().acceptContract(
          orgunitID,
          offerorID,
          authorizedSignatory,
          role,
          language,
        );
      },
    },
    manufacturer: {
      authorities: [
        AuthenticationAuthorities.PERM_MANUFACTURER_SOLAR_PROJECTS,
        AuthenticationAuthorities.ROLE_RMANUFCT,
      ],
      callback: async () => null,
    },
  };
}
