// XXX: copied from https://github.com/mmw-ag/mmw/blob/master_cloud/mmw-constants/src/main/java/de/kybeidos/mmw/constants/SalesOrgBrandEnum.java
import { Brand } from '@mmw/constants-brands';

export enum SalesOrgBrand {
  AEG = 13,
  BEKO_DE = 155,
  BOSCH = 19,
  BOSCH_AT = 105,
  BOSCH_CH = 123,
  BOSCH_LU = 160,
  BRAUN_DE = 141,
  BROTHER_AT = 149,
  BROTHER_CH = 133,
  BROTHER_DE = 102,
  CANON_AT = 107,
  CANON_CH = 108,
  CANON_DE = 106,
  DELONGHI = 12,
  DENON = 8,
  FESTOOL = 104,
  GIGASET = 96,
  GROHE = 117,
  GROHE_AT = 118,
  GROHE_CH = 129,
  JURA = 36,
  KENWOOD = 85,
  KWS_FR = 101,
  LG = 78,
  LIEBHERR = 103,
  LIEBHERR_AT = 113,
  MEGABRAND = 79,
  MIELE = 99,
  NOOP = -1,
  OPPO = 111,
  OPPO_CH = 130,
  ORALB_DE = 125,
  REC = 33,
  ROMMELSBACHER = 158,
  SAGE_DE = 121,
  SAMSUNG = 18,
  SHARKNINJA = 157,
  SIEMENS = 17,
  SIEMENS_AT = 94,
  SIEMENS_CH = 122,
  SIEMENS_LU = 159,
  SONY = 20,
  ZEISS = 109,
}

export const {
  AEG,
  BEKO_DE,
  BOSCH_AT,
  BOSCH_CH,
  BOSCH_LU,
  BOSCH,
  BRAUN_DE,
  BROTHER_AT,
  BROTHER_CH,
  BROTHER_DE,
  CANON_AT,
  CANON_CH,
  CANON_DE,
  DELONGHI,
  DENON,
  FESTOOL,
  GIGASET,
  GROHE_AT,
  GROHE_CH,
  GROHE,
  JURA,
  KENWOOD,
  KWS_FR,
  LG,
  LIEBHERR_AT,
  LIEBHERR,
  MEGABRAND,
  MIELE,
  NOOP,
  OPPO_CH,
  OPPO,
  ORALB_DE,
  REC,
  ROMMELSBACHER,
  SAGE_DE,
  SAMSUNG,
  SHARKNINJA,
  SIEMENS_AT,
  SIEMENS_CH,
  SIEMENS_LU,
  SIEMENS,
  SONY,
  ZEISS,
} = SalesOrgBrand;

export const BRAND_MAPPING: Record<SalesOrgBrand, Brand> = {
  [AEG]: Brand.AEG,
  [BEKO_DE]: Brand.BEKO,
  [BOSCH_AT]: Brand.BOSCH,
  [BOSCH_CH]: Brand.BOSCH,
  [BOSCH_LU]: Brand.BOSCH,
  [BOSCH]: Brand.BOSCH,
  [BRAUN_DE]: Brand.BRAUN,
  [BROTHER_AT]: Brand.BROTHER,
  [BROTHER_CH]: Brand.BROTHER,
  [BROTHER_DE]: Brand.BROTHER,
  [CANON_AT]: Brand.CANON,
  [CANON_CH]: Brand.CANON,
  [CANON_DE]: Brand.CANON,
  [DELONGHI]: Brand.DELONGHI,
  [DENON]: Brand.DENON,
  [FESTOOL]: Brand.FESTOOL,
  [GIGASET]: Brand.GIGASET,
  [GROHE_AT]: Brand.GROHE,
  [GROHE_CH]: Brand.GROHE,
  [GROHE]: Brand.GROHE,
  [JURA]: Brand.JURA,
  [KENWOOD]: Brand.KENWOOD,
  [KWS_FR]: Brand.KWS,
  [LG]: Brand.LG,
  [LIEBHERR_AT]: Brand.LIEBHERR,
  [LIEBHERR]: Brand.LIEBHERR,
  [MEGABRAND]: Brand.MEGABRAND,
  [MIELE]: Brand.MIELE,
  [NOOP]: Brand.NOOP,
  [OPPO_CH]: Brand.OPPO,
  [OPPO]: Brand.OPPO,
  [ORALB_DE]: Brand.BRAUN,
  [REC]: Brand.REC,
  [ROMMELSBACHER]: Brand.ROMMELSBACHER,
  [SAGE_DE]: Brand.SAGE,
  [SAMSUNG]: Brand.SAMSUNG,
  [SHARKNINJA]: Brand.SHARKNINJA,
  [SIEMENS_AT]: Brand.SIEMENS,
  [SIEMENS_CH]: Brand.SIEMENS,
  [SIEMENS_LU]: Brand.SIEMENS,
  [SIEMENS]: Brand.SIEMENS,
  [SONY]: Brand.SONY,
  [ZEISS]: Brand.ZEISS,
};

// SOB NAMES MAPPED TO USE CAMPAIGN IMAGES FROM STATIC
export const SOB_NAME_SATIC_MAP = {
  [AEG]: 'aeg',
  [BOSCH_AT]: 'bosch-at',
  [BOSCH_CH]: 'bosch-ch',
  [BOSCH_LU]: 'bosch-lu',
  [BOSCH]: 'bosch',
  [CANON_AT]: 'canon-at',
  [CANON_CH]: 'canon-ch',
  [CANON_DE]: 'canon',
  [DELONGHI]: 'delonghi',
  [GIGASET]: 'gigaset',
  [GROHE]: 'grohe',
  [JURA]: 'jura',
  [MIELE]: 'miele',
  [ORALB_DE]: 'oralb',
  [ROMMELSBACHER]: 'rommelsbacher',
  [SAMSUNG]: 'samsung',
  [SIEMENS_AT]: 'siemens-at',
  [SIEMENS_CH]: 'siemens-ch',
  [SIEMENS_LU]: 'siemens-lu',
  [SIEMENS]: 'siemens',
  [SONY]: 'sony',
};

export const SOB_MAPPING_BY_COUNTRY: Record<string, SalesOrgBrand[]> = {
  LU: [BOSCH_LU, SIEMENS_LU],
  AT: [BROTHER_AT, BOSCH_AT, CANON_AT, GROHE_AT, LIEBHERR_AT, SIEMENS_AT],
  CH: [BROTHER_CH, BOSCH_CH, CANON_CH, GROHE_CH, SIEMENS_CH, OPPO_CH],
  DE: [
    BEKO_DE,
    BOSCH,
    BRAUN_DE,
    BROTHER_DE,
    CANON_DE,
    DELONGHI,
    DENON,
    FESTOOL,
    GIGASET,
    GROHE,
    JURA,
    KENWOOD,
    LG,
    LIEBHERR,
    MIELE,
    OPPO,
    ORALB_DE,
    REC,
    ROMMELSBACHER,
    SAGE_DE,
    SAMSUNG,
    SHARKNINJA,
    SIEMENS,
    SONY,
    ZEISS,
  ],
};

export const OFFEROR_ID_BRAND_MAPPING: Record<string, number> = {
  REC: 376142,
};

export const BRANDS_THAT_BLOCK_CAMPAIGNS_ON_ANOTHER_COUNTRY = [
  GROHE,
  GROHE_AT,
  GROHE_CH,
];
