import {
  ChangeConsumerPasswordByOperationToken,
  ConsumerProfileJSON,
} from '@mmw/services-core-consumer-profile/types';
import { Action } from '@reduxjs/toolkit';
import { U } from 'ts-toolbelt';

export const RESET_SUCCESS_FLAG = '@profile/RESET_SUCCESS_FLAG';
export const RESET_DATA = '@profile/RESET_DATA';

export type ProfileDataPayload = U.Nullable<ConsumerProfileJSON>;

export type ChangePasswordStartPayload = Omit<
  ChangeConsumerPasswordByOperationToken,
  'notAuthenticatedOperationToken'
>;

export enum ProfileOperationType {
  changePassword = 'change-password',
  getProfileData = 'get-profile-data',
  saveProfileData = 'save-profile-data',
}

export type State = {
  data: U.Nullable<ConsumerProfileJSON>;
  loading: boolean;
  error: U.Nullable<Error>;
  changePassword: {
    success: boolean;
  };
  success: boolean;
  operationType: U.Nullable<ProfileOperationType>;
};

export const NAMESPACE = 'consumerProfile';

export type RootState = {
  consumerProfile: State;
};

export type Reducer = (state: State, action: Action) => State;
