import { injectIntl, IntlShape } from 'react-intl';
import { compose, HOC, withProps } from 'recompose';

export type WithBundleVersionProps = {
  _bundleVersion?: string;
  intl?: IntlShape;
};

const withBundleVersion: HOC<unknown, {}> = compose(
  injectIntl,
  withProps(({ intl }: { intl: IntlShape }) => {
    const { _bundleVersion } = intl.messages;
    return {
      _bundleVersion,
    };
  }),
);
export default withBundleVersion;
