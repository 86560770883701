import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import { getAuthenticationService } from '@mmw/services-holder';
import { ActionsObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, timeout } from 'rxjs/operators';

import { logoutErrorAction, logoutSuccessAction } from '../actions';
import {
  LOGOUT_START,
  LogoutErrorAction,
  LogoutStartAction,
  LogoutSuccessAction,
} from '../types';

const { logger } = contextualConfig.application;
const { defaultTimeout } = contextualConfig.api;

const logoutEpic = (
  action$: ActionsObservable<LogoutStartAction>,
): Observable<LogoutSuccessAction | LogoutErrorAction> =>
  action$.pipe(
    ofType(LOGOUT_START),
    tap(() => logger.info('Trying to logout')),
    switchMap(({ payload }) =>
      from(getAuthenticationService().logout()).pipe(
        timeout(defaultTimeout),
        map(() => {
          if (payload.onSuccess) payload.onSuccess();
          return logoutSuccessAction();
        }),
        catchError(error => of(logoutErrorAction(error))),
      ),
    ),
  );

export default logoutEpic;
