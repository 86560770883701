import './configureConfig';
import './configureServices';
import './configureInjections';

import consumerIconSet from '@consumer/icon-set';
import IconSetProvider from '@lib/icons-set-provider';
import { MaterialPickersProvider } from '@lib/material-pickers-ui-system';
import contextualConfig from '@mmw/contextual-config';
import { AvailableLanguagesProvider } from '@mmw/redux-store-common-available-languages/context';
import {
  CUSTOM_COMPONENTS,
  MMW_THEME_WEB,
  StylesProvider,
} from '@mmw/ui-system';
import { Theme } from '@mmw/ui-theme/types';
import BaseApp from '@mmw/web-base-app';
import NotifyBrowserUpdate from '@mmw/web-utils-notify-browser-update';
import { ReCaptchaV3ContextProvider } from '@recaptcha/v3';
import assets from '@retail/ui-system/assets';
import retailTheme from '@retail/web-theme';
import { EMPTY_OBJECT } from '@shared-utils/object';
import { Context as AssetsContext } from '@ui-system/assets';
import { UiSystemProvider } from '@ui-system/config';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import ToastNotificationsProvider from 'toast-notifications';

import store from './configure-store';
import configureStoreListeners from './configureStoreListeners';
import NavigationContainer from './NavigationContainer';

NotifyBrowserUpdate();
configureStoreListeners(store);

const theme = {
  ...MMW_THEME_WEB,
  assets: {
    ...MMW_THEME_WEB.assets,
    ...assets,
  },
};

const App: React.FC = () => (
  <ThemeProvider theme={retailTheme as Theme}>
    <UiSystemProvider components={CUSTOM_COMPONENTS} theme={theme}>
      <StylesProvider>
        <IconSetProvider customSet={consumerIconSet}>
          <AssetsContext.Provider
            value={theme.assets || (EMPTY_OBJECT as Record<string, any>)}
          >
            <BaseApp store={store}>
              <AvailableLanguagesProvider
                restrictedLanguages={
                  contextualConfig.application.supportedLanguages
                }
              >
                <MaterialPickersProvider>
                  <ReCaptchaV3ContextProvider>
                    <ToastNotificationsProvider>
                      <NavigationContainer />
                    </ToastNotificationsProvider>
                  </ReCaptchaV3ContextProvider>
                </MaterialPickersProvider>
              </AvailableLanguagesProvider>
            </BaseApp>
          </AssetsContext.Provider>
        </IconSetProvider>
      </StylesProvider>
    </UiSystemProvider>
  </ThemeProvider>
);

export default App;
