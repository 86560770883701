import useAvailableLanguages from '@mmw/clients-ui-hooks-use-available-languages';
import { getStaticUrl } from '@mmw/config';
import { useLanguage } from '@mmw/redux-store-i18n/hooks';
import { useTranslate } from '@mmw/utils-text-utils/hooks';
import { EMPTY_OBJECT } from '@shared-utils/object';
import { Hex } from '@ui-system/color';
import { Color, css } from '@ui-system/css';
import {
  AvatarSize,
  ContainerProps,
  MenuOptionInterface,
  Style,
  TypographyVariant,
} from '@ui-system/interfaces';
import { makeStyle, useMergedStyles } from '@ui-system/style';
import UI from '@ui-system/ui';
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';
import React, { useCallback, useMemo, useState } from 'react';
import charAt from 'voca/char_at';

const FLAGS_STATIC_URL = `${getStaticUrl()}/images/countries/flags2`;

export enum DropdownLangSizeVariant {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

const SIZES = {
  [DropdownLangSizeVariant.SMALL]: {
    textVariant: 'body2' as TypographyVariant,
    flagSize: 'small' as AvatarSize,
    topPosition: -35,
  },
  [DropdownLangSizeVariant.MEDIUM]: {
    textVariant: 'body1' as TypographyVariant,
    flagSize: 'medium' as AvatarSize,
    topPosition: -42,
  },
  [DropdownLangSizeVariant.LARGE]: {
    textVariant: 'h6' as TypographyVariant,
    flagSize: 'large' as AvatarSize,
    topPosition: -52,
  },
};

interface IconComponentProps extends Record<string, any> {
  style?: Style;
  isHovering?: boolean;
  opened?: boolean;
  size?: AvatarSize;
}

const ArrowIcon: React.FC<IconComponentProps> = ({
  style,
  isHovering,
  size = 'medium',
  opened,
  ...props
}: IconComponentProps) => {
  const iconStyle = useMergedStyles(
    style,
    css`
      margin-bottom: 0px;
      margin-left: 5px;
    `,
  );
  const iconSize = useMemo(
    () =>
      ({
        small: 14,
        medium: 18,
        large: 22,
      })[size],
    [size],
  );
  return (
    <UI.Icon
      name={opened ? 'up' : 'down'}
      size={iconSize}
      color={isHovering ? 'white' : 'primary'}
      style={iconStyle}
      {...props}
    />
  );
};

const useMenuStyle = makeStyle<{
  bg?: Color | Hex;
  justFlags?: boolean;
  sizeVariant?: DropdownLangSizeVariant;
}>(
  ({ utils, theme, props, mediaQueries }) => css`
    height: 100%;
    justify-content: flex-start;
    padding: 0;
    ${utils.applyShadow(theme, 2)};
    ${props?.bg ? utils.applyBackgroundColor(theme, props.bg) : ''};
    top: ${SIZES[props.sizeVariant || DropdownLangSizeVariant.SMALL]
      .topPosition}px;
    ${mediaQueries.upToMd`
      top: ${SIZES[props.sizeVariant || DropdownLangSizeVariant.SMALL].topPosition - 15}px;
    `}
    ${props.justFlags
      ? `
      width: 90px;
    `
      : ''};
  `,
);

interface CountryFlagIconProps extends ContainerProps {
  src?: string;
  langName?: string;
  flagSize?: AvatarSize;
}

const FlagIcon: React.FC<CountryFlagIconProps> = ({
  src,
  langName,
  flagSize,
  ...props
}: CountryFlagIconProps) => (
  <UI.Container modifiers="pointer" {...props}>
    <UI.Avatar src={src} size={flagSize || 'small'} shape="round">
      {charAt(langName, 0) || 'L'}
    </UI.Avatar>
  </UI.Container>
);
interface LabelWithFlagProps extends ContainerProps {
  option: MenuOptionInterface;
  isHovering?: boolean;
  isFirstChild?: boolean;
  textVariant?: TypographyVariant;
  flagSize?: AvatarSize;
}

const LabelWithFlag: React.FC<LabelWithFlagProps> = ({
  option,
  isHovering,
  textVariant,
  flagSize,
  ...props
}: LabelWithFlagProps) => {
  const translate = useTranslate();
  const currentLabel = translate(option.label);
  return (
    <UI.Container align="center" gap={1} onClick={option.onClick} {...props}>
      <FlagIcon
        src={option.image}
        langName={currentLabel}
        flagSize={flagSize}
      />
      <UI.Caption
        i18n={option.label}
        modifiers={`center, ${isHovering ? 'white' : ''}`}
        variant={textVariant}
      />
    </UI.Container>
  );
};

export enum DropdownViewVariant {
  LABELS_AND_FLAGS = 'LABELS_AND_FLAGS',
  ONLY_LABELS = 'ONLY_LABELS',
  ONLY_FLAGS = 'ONLY_FLAGS',
}

interface Props extends Record<string, any> {
  customTrigger?: 'click' | 'hover' | string;
  listBackground?: Color | Hex;
  customMenuStyle?: Record<string, any>;
  viewVariant?: DropdownViewVariant;
  sizeVariant?: DropdownLangSizeVariant;
  round?: boolean;
}

export const LanguageDropdown: React.FC<Props> = ({
  customTrigger,
  listBackground,
  viewVariant = DropdownViewVariant.LABELS_AND_FLAGS,
  customMenuStyle,
  sizeVariant = DropdownLangSizeVariant.SMALL,
  round,
  ...props
}: Props) => {
  const [hovering, setHovering] = useState(false);
  const language = useLanguage();
  const availableLanguages = useAvailableLanguages();
  const translate = useTranslate();
  const { flagSize } = SIZES[sizeVariant];
  const { textVariant } = SIZES[sizeVariant];
  const selectedLabelI18n = useMemo(
    () => find(availableLanguages, { languageCode: language })?.i18n,
    [availableLanguages, language],
  );
  const options = useMemo(() => {
    const optionsArr = map(availableLanguages, item => ({
      label: item.i18n,
      value: item.languageCode,
      onClick: item.selectLanguage,
      // XXX: This could need some refactor to map more languages in future
      image: `${FLAGS_STATIC_URL}/${item.languageCode === 'en' ? 'gb' : item.languageCode?.toLowerCase()}.png`,
    }));
    const selectedLang = find(optionsArr, { value: language });
    if (selectedLang) {
      return [selectedLang, ...filter(optionsArr, lc => lc.value !== language)];
    }
    return optionsArr;
  }, [availableLanguages, language]);
  const justFlags = viewVariant === DropdownViewVariant.ONLY_FLAGS;
  const menuStyleProps = useMemo(() => {
    if (!listBackground) return EMPTY_OBJECT;
    return {
      bg: listBackground,
      justFlags,
      sizeVariant,
    };
  }, [justFlags, listBackground, sizeVariant]);
  const menuStyle = useMenuStyle(menuStyleProps);
  const finalMenuStyle = useMemo(
    () => ({
      ...menuStyle,
      ...customMenuStyle,
    }),
    [customMenuStyle, menuStyle],
  );
  const MenuItemComponent = useCallback(
    ({
      option,
      isHovering,
      isFirstChild,
      ...flagProps
    }: LabelWithFlagProps) => {
      if (justFlags) {
        if (isFirstChild) {
          return (
            <UI.Container w="100%" align="center">
              <FlagIcon src={option.image} flagSize={flagSize} m="0, 1, 0, 0" />
              <ArrowIcon isHovering={isHovering} size={flagSize} opened />
            </UI.Container>
          );
        }
        return <FlagIcon src={option.image} flagSize={flagSize} />;
      }
      if (isFirstChild) {
        return (
          <UI.Container w="100%" align="center">
            <LabelWithFlag
              flagSize={flagSize}
              textVariant={textVariant}
              option={option}
              m="0, 1, 0, 0"
              isHovering={isHovering}
              {...flagProps}
            />
            <ArrowIcon isHovering={isHovering} size={flagSize} opened />
          </UI.Container>
        );
      }
      return (
        <LabelWithFlag
          flagSize={flagSize}
          textVariant={textVariant}
          option={option}
          isHovering={isHovering}
          {...flagProps}
        />
      );
    },
    [flagSize, textVariant, justFlags],
  );

  const dropdownContent = useMemo(
    () => ({
      LABELS_AND_FLAGS: (
        <UI.Container align="center" gap={1}>
          <FlagIcon
            src={find(options, o => o.value === language)?.image}
            langCode={language === 'en' ? 'gb' : language}
            langName={translate(selectedLabelI18n)}
            flagSize={flagSize}
          />
          <UI.Caption
            i18n={selectedLabelI18n}
            variant={textVariant}
            modifiers={hovering ? 'white' : 'black'}
          />
        </UI.Container>
      ),
      ONLY_LABELS: (
        <UI.Caption modifiers="uppercase" i18n={selectedLabelI18n} />
      ),
      ONLY_FLAGS: (
        <FlagIcon
          src={find(options, o => o.value === language)?.image}
          langName={translate(selectedLabelI18n)}
          flagSize={flagSize}
        />
      ),
    }),
    [
      options,
      language,
      translate,
      selectedLabelI18n,
      flagSize,
      textVariant,
      hovering,
    ],
  );

  return (
    <UI.Menu.MenuContainer
      options={options}
      trigger={customTrigger || 'click, hover'}
      style={finalMenuStyle}
      // @ts-ignore
      MenuItemComponent={
        viewVariant === DropdownViewVariant.ONLY_LABELS
          ? undefined
          : MenuItemComponent
      }
      round={round}
    >
      <UI.Container
        align="center"
        justify="space-between"
        p={hovering ? '2, 2' : '0, 2'}
        m="0,0,0,2"
        modifiers="pointer"
        w={justFlags ? '85px' : 'auto'}
        h="100%"
        bg={hovering ? 'primary' : undefined}
        borderRadius={round ? 20 : undefined}
        onMouseOver={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        {...props}
      >
        {dropdownContent[viewVariant]}
        <ArrowIcon isHovering={hovering} size={flagSize} />
      </UI.Container>
    </UI.Menu.MenuContainer>
  );
};
