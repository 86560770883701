import { I18nShape } from '@mmw/constants-i18n';
import { useTranslate } from '@mmw/utils-text-utils/hooks';
import React, { useCallback } from 'react';
import { AddToast, useToasts } from 'react-toast-notifications';
import { F } from 'ts-toolbelt';

import {
  I18N,
  NotificationConfig,
  NotificationConfigObject,
  NotificationOptions,
} from './types';
import { DEFAULT_CONFIG, getI18n, modifiersMap } from './utils';

export type ToastParams = [
  React.ReactNode,
  NotificationOptions,
  (id: string) => void,
];

function setToastParams(
  translate: F.Function<[I18nShape], string>,
  i18n: I18N | I18nShape,
  config: NotificationConfig = DEFAULT_CONFIG,
): ToastParams {
  // config can be a string, a "config object" or undefined
  // @ts-ignore
  const toastOptions: NotificationConfigObject =
    typeof config === 'string'
      ? { ...DEFAULT_CONFIG, appearance: config }
      : { ...DEFAULT_CONFIG, ...config };
  const { appearance } = toastOptions;
  // i18n could be { title: I18nShape, content: I18nShape } or an I18nShape itself
  // in web case we are actually using only one I18nShape prioritizing content
  // TODO ?? we should improve rendered component to deal with title and content
  const { title, content } = getI18n(i18n);
  const modifiers = appearance ? modifiersMap[appearance] : undefined;
  const translatedTitle = title ? translate(title) : null;
  const translatedContent = content ? translate(content) : null;
  return [
    // <Caption1 modifiers={modifiers} i18n={content || title} />,
    translatedContent || translatedTitle,
    toastOptions,
    toastOptions.onDismiss,
  ];
}

// use when you can set i18n and/or config as constant
type UseNotificationCb = F.Function<[], ReturnType<AddToast>>;
const useNotification = (
  i18n: I18N | I18nShape,
  config?: NotificationConfig,
): UseNotificationCb => {
  const translate = useTranslate();
  const { addToast } = useToasts();
  return useCallback(() => {
    addToast(...setToastParams(translate, i18n, config));
  }, [addToast, config, i18n, translate]);
};

// use when you need to set i18n and/or config dynamically
type UseNotificationsCb = F.Function<
  [I18N | I18nShape, NotificationConfig?],
  ReturnType<AddToast>
>;
const useNotifications = (): UseNotificationsCb => {
  const translate = useTranslate();
  const { addToast } = useToasts();
  return useCallback(
    (i18n: I18N | I18nShape, config?: NotificationConfig) => {
      addToast(...setToastParams(translate, i18n, config));
    },
    [addToast, translate],
  );
};

// TODO:
// web components are using useNotifications unnecessarily
// refactor those componentes to useNotification instead

const hooks = {
  useNotification,
  useNotifications,
};

export { hooks as default, useNotification, useNotifications };
