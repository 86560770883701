import { ApiResponse, default as defaultApiV2 } from '@mmw/api-v2';
import { LanguageCode } from '@mmw/constants-languages';
import contextualConfig from '@mmw/contextual-config';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import autoBind from 'auto-bind';

import { getTraderSSOPath } from './apiPaths';
import logger from './log';

type Api = typeof defaultApiV2;

type TraderSSOServiceOptions = {
  apiv2?: Api;
  authenticationService: AuthenticationService;
};

const { applicationId } = contextualConfig.application;

class TraderSSOService {
  api: Api;

  authenticationService: AuthenticationService;

  constructor({ apiv2, authenticationService }: TraderSSOServiceOptions) {
    this.api = apiv2 || defaultApiV2;
    this.authenticationService = authenticationService;
    autoBind(this);
  }

  async authenticateTraderBySSO(
    site: string,
    params: string,
    language: LanguageCode,
  ): Promise<any> {
    logger.debug(`Trying to authenticate trader by SSO`);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<any> = await this.api.post(
        getTraderSSOPath(language),
        {
          site,
          parms: params,
          authApi: true,
          applicationId,
        },
        {
          headers,
        },
      );
      const { data } = response;
      logger.info(`Successfully authenticated trader by SSO`);
      return data;
    } catch (error) {
      logger.error(`Error when authenticating trader by SSO, error=%O`, error);
      throw error;
    }
  }
}

export default TraderSSOService;
