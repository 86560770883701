import qs from 'qs';

const BASE_PATH = '/v1/trader/classofgood';

export const GetClassOfGoodsPath = (
  salesOrgBrand: number,
  classOfGood: number | null,
  language: string,
  onlyWhichHasProducts: boolean,
): string =>
  `${BASE_PATH}?${qs.stringify({
    salesOrgBrand,
    classOfGood,
    language,
    onlyWhichHasProducts,
  })}`;
