import defaultApiV2, { ApiResponse } from '@mmw/api-v2';
import Features from '@mmw/constants-features';
import { notifySuccess } from '@mmw/global-feedback-channel';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import { Pagination } from '@mmw/services-core-common/types';
import autoBind from 'auto-bind';
import { get } from 'lodash';

import {
  AvailableAssignmentsPath,
  CreateEmployeePath,
  DeleteEmployeesPath,
  EmployeeDetailsPath,
  EmployeesPath,
} from './apiPaths';
import {
  CreateEmployeeError,
  DeleteSingleEmployeeError,
  GetAvailableAssignmentsError,
  GetCompanyEmployeesError,
  GetEmployeeDetailsError,
  UpdateEmployeeError,
} from './errors';
import logger from './log';
import {
  CreateEmployee,
  Employee,
  EmployeeDetails,
  UserAssignments,
} from './types';

type Api = typeof defaultApiV2;

type TraderEmployeesServiceOptions = {
  apiv2?: Api;
  authenticationService: AuthenticationService;
};

class TraderEmployeesService {
  api: Api;

  authenticationService: AuthenticationService;

  constructor({ apiv2, authenticationService }: TraderEmployeesServiceOptions) {
    this.api = apiv2 || defaultApiV2;
    this.authenticationService = authenticationService;
    autoBind(this);
  }

  async getCompanyEmployees(): Promise<Pagination<Employee>> {
    logger.debug('Trying to get company employees of logged user');
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Pagination<Employee>> = await this.api.post(
        EmployeesPath(),
        {},
        { headers },
      );
      const { data } = response;
      logger.info('Successfully got company employees');
      return data;
    } catch (error) {
      logger.error('Error when getting company employees, error=%O', error);
      throw new GetCompanyEmployeesError();
    }
  }

  async deleteEmployees(employeeIDs: number[]) {
    logger.debug(`Trying to delete employees ids=${employeeIDs}`);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      await this.api.post(DeleteEmployeesPath, employeeIDs, { headers });
      logger.info(`Successfully deleted employees ids=${employeeIDs}`);
      notifySuccess(Features.RETAIL_CLIENT.DELETE_EMPLOYEE);
    } catch (error) {
      logger.error(
        `Error when trying to delete employees ids=${employeeIDs}`,
        error,
      );
      throw new DeleteSingleEmployeeError();
    }
  }

  async getEmployeeDetails(employeeID: number): Promise<EmployeeDetails> {
    logger.debug(`Trying to retrieve employee details by id=${employeeID}`);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<EmployeeDetails> = await this.api.get(
        EmployeeDetailsPath(employeeID),
        { headers },
      );
      const { data } = response;
      logger.info(`Successfully retrieve employee details id=${employeeID}`);
      return data;
    } catch (error) {
      logger.error(
        `Error when trying to retrieve employee details id=${employeeID}`,
        error,
      );
      throw new GetEmployeeDetailsError();
    }
  }

  async updateEmployeeDetails(
    employeeID: number,
    details: EmployeeDetails,
    language: string,
    loginUrl: string,
  ): Promise<EmployeeDetails> {
    logger.debug(`Trying to update employee details by id=${employeeID}`);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const oldDetails = await this.getEmployeeDetails(employeeID);
      if (oldDetails.email !== details.email) {
        const isEmailValid =
          await this.authenticationService.isEmailValidForRegistration(
            get(details, 'email'),
          );
        if (!isEmailValid) {
          logger.info('Already in use e-mail, cant update employee');
          throw new UpdateEmployeeError('INVALID EMAIL');
        }
      }
      const response: ApiResponse<EmployeeDetails> = await this.api.post(
        EmployeeDetailsPath(employeeID, language, loginUrl),
        details,
        { headers },
      );
      const { data } = response;
      logger.info(`Successfully update employee details id=${employeeID}`);
      notifySuccess(Features.REC_APP.UPDATE_EMPLOYEE);
      return data;
    } catch (error) {
      if (error instanceof UpdateEmployeeError) {
        throw error;
      }
      logger.error(
        `Error when trying to update employee details id=${employeeID}`,
        error,
      );
      throw new UpdateEmployeeError();
    }
  }

  async createEmployee(
    newEmployee: CreateEmployee,
    language: string,
    loginUrl: string,
  ): Promise<Map<string, number>> {
    logger.debug('Trying to create employee', newEmployee);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      // XXX: employee does not need this check, this is NOT a user
      if (newEmployee.details.user) {
        const isEmailValid =
          await this.authenticationService.isEmailValidForRegistration(
            get(newEmployee, 'details.email'),
          );
        if (!isEmailValid) {
          logger.info('Already in use e-mail, cant create employee');
          throw new CreateEmployeeError('INVALID EMAIL');
        }
      }
      const response: ApiResponse<Map<string, number>> = await this.api.post(
        CreateEmployeePath(language, loginUrl),
        newEmployee,
        { headers },
      );
      const { data } = response;
      logger.info('Successfully created employee', data);
      notifySuccess(Features.REC_APP.CREATE_EMPLOYEE);
      return data;
    } catch (error) {
      if (error instanceof UpdateEmployeeError) {
        throw error;
      }
      logger.error('Error when trying to create employee', error);
      throw new CreateEmployeeError();
    }
  }

  async getAvailableAssignments(
    language: string,
    onlyBrandSpecificPositions = false,
  ): Promise<UserAssignments> {
    logger.debug(`Trying to retrieve available salesperson assignments`);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<EmployeeDetails> = await this.api.get(
        AvailableAssignmentsPath(language, onlyBrandSpecificPositions),
        { headers },
      );
      const { data } = response;
      logger.info(`Successfully retrieve available salesperson assignments`);
      return data;
    } catch (error) {
      logger.error(
        `Error when trying to retrieve available salesperson assignments`,
        error,
      );
      throw new GetAvailableAssignmentsError();
    }
  }
}

export default TraderEmployeesService;
