import { F } from '@utils/ts';
import qs from 'qs';

const BASE_PATH = '/v1/trader/wallet';

function generateTraderWalletPath(
  feature: string,
): F.Function<[walletName: string], string> {
  return (walletName: string) => `${BASE_PATH}/${walletName}/${feature}`;
}
export const GetWalletBalancePath = generateTraderWalletPath('balance');

export const GetWalletStatusPath = generateTraderWalletPath('status');

export const GetWalletTransactionsPath =
  generateTraderWalletPath('transactions');

export const GetConvertToVoucherPath =
  generateTraderWalletPath('convert-to-voucher');

export const GetGenerateVoucherQRCodePath = (
  walletName: string,
  token: string,
  headers: Record<string, any>,
): string =>
  `${BASE_PATH}/${walletName}/generate-voucher-qrcode/${encodeURIComponent(
    token,
  )}/?${qs.stringify(headers)}`;

export const GetGetVoucherStatusPath =
  generateTraderWalletPath('voucher-status');

export const GetRedeemVoucherPath = generateTraderWalletPath('use-voucher');

export const GetMoveToWalletPath = generateTraderWalletPath('move-to-wallet');
