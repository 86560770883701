import { I18nShape } from '@mmw/constants-i18n';
import { map } from 'lodash';
import compact from 'lodash/compact';
import concat from 'lodash/concat';
import { F } from 'ts-toolbelt';

export type LocalOrExternalNavigationHandler = F.Function<
  [{ navigate?: F.Function<[route: string]>; route?: string }?],
  void | Promise<void>
>;

export interface NavItemConfigInterface {
  label: I18nShape;
  route?: string;
  onClick?: LocalOrExternalNavigationHandler;
  useOnClick?: () => LocalOrExternalNavigationHandler;
  useIsVisible?: F.Function<[...args: any[]], boolean>;
  subRoutes?: NavItemConfigInterface[];
  matchGroup?: string[];
}

export class NavItemConfig implements NavItemConfigInterface {
  label: I18nShape;

  route?: string;

  onClick?: LocalOrExternalNavigationHandler;

  subRoutes?: NavItemConfig[];

  matchGroup?: string[];

  useOnClick: () => LocalOrExternalNavigationHandler;

  useIsVisible: F.Function<[...args: any[]], boolean>;

  constructor(
    props: NavItemConfigInterface,
    navigate?: F.Function<[route: string]> | undefined,
  ) {
    this.route = props.route;
    this.subRoutes = map(
      props.subRoutes,
      subRoute => new NavItemConfig(subRoute, navigate),
    );
    this.label = props.label;
    const autoMatchGroup = compact(map(this.subRoutes, 'route'));
    this.matchGroup =
      compact(concat(props.matchGroup, autoMatchGroup)) || autoMatchGroup;
    this.onClick = props.onClick;
    this.useIsVisible = props.useIsVisible || (() => true);
    this.useOnClick =
      props.useOnClick ||
      (() => () => {
        if (this.onClick) this.onClick({ navigate, route: this.route });
        else if (this.route && navigate) navigate(this.route);
      });
  }
}
