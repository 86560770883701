import { createAsyncActions } from '@redux-async-module/actions-utils';

import { RetrieveBySobMeta } from './types';

export function createCreateItemSobActions<StartPayload, ItemType>(
  namespace: string,
  actionName: string,
) {
  return createAsyncActions<StartPayload, ItemType, Error, RetrieveBySobMeta>(
    namespace,
    actionName,
  );
}

// XXX: this is to find implicit types
// wrapped has no explicit return type so we can infer it
class Wrapper<StartPayload, ItemType> {
  // eslint-disable-next-line class-methods-use-this
  createCreateItemSobActions(namespace: string, actionName: string) {
    return createCreateItemSobActions<StartPayload, ItemType>(
      namespace,
      actionName,
    );
  }
}

export type CreateItemSobActions<StartPayload, ItemType> = ReturnType<
  Wrapper<StartPayload, ItemType>['createCreateItemSobActions']
>;
