import { I18nShape } from '@mmw/constants-i18n';
import { css, Padding } from '@ui-system/css';
import { Style } from '@ui-system/interfaces';
import { TypographyVariant } from '@ui-system/interfaces-typography';
import { ResponsiveProps, useResponsiveProps } from '@ui-system/media-query';
import { makeStyle, useMergedStyles } from '@ui-system/style';
import UI from '@ui-system/ui';
import React from 'react';
import { F } from 'ts-toolbelt';

export type Props = {
  id?: string;
  title?: I18nShape | string;
  titleModifiers?: string;
  subtitleVariant?: TypographyVariant;
  subtitle?: I18nShape | string;
  onClickSubtitle?: F.Function;
  subtitleModifiers?: string;
  titleVariant?: TypographyVariant;
  Header?: React.FC;
  style?: Style;
  content?: Style;
  children?: React.ReactNode;
  contentPadding?: Padding;
  Actions?: React.FC;
  HeaderActionAreaContent?: React.ReactNode;
  customHeaderHeight?: string | number;
  forceHeaderAsRow?: boolean;
  hideMarker?: boolean;
} & ResponsiveProps<Pick<Props, 'contentPadding'>>;

export const Marker = () => <UI.Container h={15} w={30} bg="primary" />;

const useActionAreaStyle = makeStyle(
  ({ theme }) => css`
    border-top-right-radius: 10px;
    height: 100%;
    background-color: ${theme.colors[theme.mode].primary.main};
  `,
);

interface HeaderActionAreaProps {
  HeaderActionAreaContent: React.ReactNode;
}

const HeaderActionArea: React.FC<HeaderActionAreaProps> = ({
  HeaderActionAreaContent,
}: HeaderActionAreaProps) => {
  const style = useActionAreaStyle();
  return (
    <UI.Container
      style={style}
      align="center"
      justify="center"
      p="3, 4"
      gap={3}
    >
      {HeaderActionAreaContent}
    </UI.Container>
  );
};

const STYLE = css`
  width: 100%;
`;

const EMPTY_STYLE = {};
export const TitleContentActionCard: React.FC<Props> = ({
  title,
  titleModifiers,
  titleVariant,
  subtitle,
  subtitleModifiers,
  subtitleVariant,
  children,
  Actions,
  Header,
  contentPadding,
  HeaderActionAreaContent,
  style,
  content,
  customHeaderHeight,
  id,
  responsive,
  onClickSubtitle,
  forceHeaderAsRow,
  hideMarker,
  ...props
}: Props) => {
  const responsiveProps = useResponsiveProps(responsive, {
    contentPadding,
  });
  const cardStyle = useMergedStyles(STYLE, style || EMPTY_STYLE);
  const mediaQueries = UI.useMediaQueries();
  return (
    <UI.Card style={cardStyle} {...props}>
      <UI.Container id={id} align="center" justify="space-between" w="100%">
        <UI.Container
          align="flex-start"
          justify="space-between"
          w={HeaderActionAreaContent ? 'auto' : '100%'}
          p={HeaderActionAreaContent ? '0' : '3, 0, 0 ,0'}
          responsive="upToSm.align=flex-start"
        >
          <UI.Container align="center" h={customHeaderHeight} gap={2}>
            {!hideMarker && <Marker />}
            <UI.Container f={1} direction="column" p="0, 2">
              <UI.Typography
                i18n={title}
                modifiers={titleModifiers}
                variant={titleVariant}
              />
              <UI.Typography
                i18n={subtitle}
                onClick={onClickSubtitle}
                modifiers={subtitleModifiers}
                variant={subtitleVariant}
              />
            </UI.Container>
          </UI.Container>
          {Header && (!mediaQueries.current.xsToSm || forceHeaderAsRow) && (
            <Header />
          )}
        </UI.Container>
        {HeaderActionAreaContent && !mediaQueries.current.xsToSm && (
          <HeaderActionArea HeaderActionAreaContent={HeaderActionAreaContent} />
        )}
      </UI.Container>
      <UI.Container
        responsive="xsToSm.visible=true"
        visible={false}
        direction="column"
      >
        {Header && !forceHeaderAsRow && <Header />}
        {HeaderActionAreaContent && (
          <HeaderActionArea HeaderActionAreaContent={HeaderActionAreaContent} />
        )}
      </UI.Container>
      <UI.Card.Content style={content}>
        <UI.Container
          h="100%"
          p={responsiveProps.contentPadding}
          direction="column"
        >
          {children}
        </UI.Container>
      </UI.Card.Content>
      {Actions && (
        <UI.Card.Actions>
          <Actions />
        </UI.Card.Actions>
      )}
    </UI.Card>
  );
};

TitleContentActionCard.defaultProps = {
  titleModifiers: 'uppercase',
  contentPadding: '0, 6',
  subtitleVariant: 'subtitle2',
  subtitleModifiers: 'uppercase',
  hideMarker: false,
};
