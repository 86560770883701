import { I18nShape } from '@mmw/constants-i18n';
import { Row } from '@mmw/hybrid-ui-structure-containers';
import { use } from '@mmw/ui-theme/utils';
import { noop } from 'lodash';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { U } from 'ts-toolbelt';

import NavLink from './NavLink';

const ACESSORY_LEFT_SPACING = [0, 1, 0, 0];
const ACESSORY_RIGHT_SPACING = [0, 0, 0, 1];

const StyledNavItem = styled.li(props => use(props, 'navBar.navItem'));

export type Props = {
  i18n: I18nShape;
  active?: boolean;
  onClick?: () => void;
  acessoryLeft?: U.Nullable<React.ReactElement<any>>;
  acessoryRight?: U.Nullable<React.ReactElement<any>>;
  disabled?: boolean;
};

const NavItem: React.FC<Props> = ({
  i18n,
  active,
  onClick,
  acessoryRight,
  acessoryLeft,
  disabled,
  ...props
}: Props) => {
  const activeClass = useMemo(() => (active ? 'active' : ''), [active]);
  const navigates = useMemo(
    () => (active || !onClick ? noop : onClick),
    [active, onClick],
  );
  return (
    <StyledNavItem
      className={activeClass}
      onClick={navigates}
      disabled={disabled}
    >
      <Row align="center">
        {acessoryLeft ? (
          <Row spacing={ACESSORY_LEFT_SPACING} autoWidth>
            {acessoryLeft}
          </Row>
        ) : null}
        <NavLink className={activeClass} i18n={i18n} {...props} />
        {acessoryRight ? (
          <Row spacing={ACESSORY_RIGHT_SPACING} autoWidth>
            {acessoryRight}
          </Row>
        ) : null}
      </Row>
    </StyledNavItem>
  );
};

NavItem.defaultProps = {
  active: false,
  onClick: undefined,
};

export default NavItem;
