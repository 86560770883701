import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import { getAuthenticationService } from '@mmw/services-holder';
import { ActionsObservable } from 'redux-observable';
import { concat, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, timeout } from 'rxjs/operators';
import { U } from 'ts-toolbelt';

import {
  checkAuthWithSystemTokenErrorAction,
  checkAuthWithSystemTokenSuccessAction,
} from '../actions';
import {
  CHECK_AUTHENTICATION_WITH_SYSTEM_TOKEN_START,
  CheckAuthWithSystemTokenErrorAction,
  CheckAuthWithSystemTokenStartAction,
  CheckAuthWithSystemTokenSuccessAction,
} from '../types';

const { logger } = contextualConfig.application;
const { defaultTimeout } = contextualConfig.api;

const EMPTY_RESPONSE = {
  isLoggedIn: false,
  loggedUser: null,
  accessToken: null,
  success: false,
};

async function validateSystemTokenThenAuthenticate({
  applicationId,
  applicationBaseUrl,
  applicationPath,
  applicationContextPath,
}: {
  applicationId?: U.Nullable<string>;
  applicationBaseUrl?: U.Nullable<string>;
  applicationPath?: U.Nullable<string>;
  applicationContextPath?: U.Nullable<string>;
}) {
  const hasValidSystemToken =
    await getAuthenticationService().hasValidSystemToken();

  if (!hasValidSystemToken) {
    return {
      ...EMPTY_RESPONSE,
    };
  }
  const ssoTokenResult =
    await getAuthenticationService().generateSSOTokenBySystemToken({
      applicationId,
      applicationBaseUrl,
      applicationPath,
      applicationContextPath,
    });

  if (!ssoTokenResult.success) {
    logger.info(
      'Fail to generate sso token by system token',
      ssoTokenResult.error,
    );
    return {
      ...EMPTY_RESPONSE,
    };
  }

  const { ssoToken } = ssoTokenResult;

  logger.info('Success to generate sso token by system token');
  try {
    const authBySystemTokenResponse =
      await getAuthenticationService().authenticateBySSOToken({
        ssoToken: ssoToken as string,
        applicationId,
        applicationBaseUrl,
        applicationPath,
        applicationContextPath,
      });

    if (!authBySystemTokenResponse.success) {
      logger.info(
        'Success failed by system token',
        authBySystemTokenResponse.error,
      );
      // XXX: logout since system token couldnt authenticate
      await getAuthenticationService().logout();
      return {
        ...EMPTY_RESPONSE,
      };
    }

    const loggedUser = await getAuthenticationService().getUserDetails();
    const accessToken = await getAuthenticationService().getAccessToken();
    logger.info('Success auth by system token');

    return {
      ...authBySystemTokenResponse,
      loggedUser,
      accessToken,
    };
  } catch (error) {
    logger.error('Error auth by system token', error);
    await getAuthenticationService().logout();
    return {
      ...EMPTY_RESPONSE,
    };
  }
}

type Input = CheckAuthWithSystemTokenStartAction;
type Output =
  | CheckAuthWithSystemTokenSuccessAction
  | CheckAuthWithSystemTokenErrorAction;
const checkAuthWithSystemTokenEpic = (
  action$: ActionsObservable<Input>,
): Observable<Output> =>
  action$.pipe(
    ofType(CHECK_AUTHENTICATION_WITH_SYSTEM_TOKEN_START),
    tap(() => logger.debug('Will check if user is authenticated')),
    switchMap(({ payload }) =>
      from(validateSystemTokenThenAuthenticate({ ...payload })).pipe(
        tap(data => {
          if (data.success) {
            payload.onSuccess(data);
          }
        }),
        timeout(defaultTimeout),
        map(({ loggedUser, accessToken }) =>
          checkAuthWithSystemTokenSuccessAction(loggedUser, accessToken),
        ),
        catchError(error =>
          concat(of(checkAuthWithSystemTokenErrorAction(error))),
        ),
      ),
    ),
  );

export default checkAuthWithSystemTokenEpic;
