import {
  Checkbox as MuiCheckbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';
import { css } from '@ui-system/css';
import componentModifiers from '@ui-system/default-modifiers/form/checkbox';
import { useCheckboxStyle } from '@ui-system/default-styles';
import { useTranslateFunction } from '@ui-system/deps';
import { CheckboxProps } from '@ui-system/interfaces-form';
import Typography from '@ui-system/material-typography';
import UI from '@ui-system/ui';
import { toUpper } from 'lodash';
import React, { useCallback, useMemo } from 'react';

type LabelType = Pick<
  CheckboxProps,
  'labelI18n' | 'caption' | 'required' | 'error'
>;

const Label: React.FC<LabelType> = ({
  labelI18n,
  caption,
  required,
  error,
}: LabelType) => {
  const translate = useTranslateFunction();
  const texts = useMemo(() => {
    const labelText = toUpper(translate(labelI18n));
    return {
      label: labelText,
      caption: translate(caption),
    };
  }, [caption, labelI18n, translate]);
  return (
    <>
      {texts.label || required ? (
        <UI.Overline
          modifiers={`grayA400, ${error || texts.caption ? 'error' : ''}`}
          style={css`
            line-height: 16px;
            height: 16px;
            margin-left: 5px;
          `}
        >
          {`${texts.label || ''}${required ? ' * ' : ''}`}
        </UI.Overline>
      ) : null}
    </>
  );
};

export const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  caption,
  onChange,
  error,
  label,
  labelI18n,
  disabled,
  style,
  checkboxProps,
  required,
  formControlLabelStyle,
  eventStopPropagation = true,
  eventPreventDefault = true,
}: CheckboxProps) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // XXX: Some checkboxes are bugging out when using e.preventDefault
      if (eventStopPropagation) e.stopPropagation();
      if (eventPreventDefault) e.preventDefault();
      if (onChange) onChange(!checked);
    },
    [eventStopPropagation, eventPreventDefault, onChange, checked],
  );
  const finalStyle = useCheckboxStyle(style, '', componentModifiers);

  return (
    <FormControl
      required
      error={error}
      component="fieldset"
      disabled={disabled}
      style={finalStyle}
    >
      <FormControlLabel
        style={formControlLabelStyle}
        control={
          <MuiCheckbox
            checked={checked}
            // XXX: Changed onChange to onClick to use event.stopPropagation() click
            // @ts-ignore
            onClick={handleChange}
            {...checkboxProps}
          />
        }
        label={
          React.isValidElement(label) ? (
            label
          ) : (
            <Label
              labelI18n={labelI18n}
              error={error}
              caption={caption}
              required={required}
            />
          )
        }
      />
      {caption ? (
        <FormHelperText>
          <Typography i18n={caption} />
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};

export default Checkbox;
