const namespace = 'auth';

export const RECOVER_PASSWORD = {
  NEW_PASSWORD_DESCRIPTION: {
    key: `${namespace}.recoverPassword.newPasswordDescription`,
    message:
      'Your access to the <strong>REC ProPortal 2.0</strong> <br/><br/> Please set a new password for your access to the REC ProPortal 2.0. <br/><br/>To ensure maximum security, please be sure to use a strong password with the following minimal requirements: <br/><br/>8 characters <br/>Uppercase and lowercase letter <br/>Number <br/>Special character',
  },
  LINK_EXPIRED_DESCRIPTION: {
    key: `${namespace}.recoverPassword.linkExpiredDescription`,
    message:
      'We are sorry to inform you, but your access link to the <strong>REC ProPortal 2.0</strong> got expired!<br/><br/> Please click in the button below to request a new link.<br/></br>If you wish to continue to use the application without changing your password, it is possible, but later it will be required for you to change your password.',
  },
  LINK_INVALID_DESCRIPTION: {
    key: `${namespace}.recoverPassword.linkInvalidDescription`,
    message:
      'We are sorry to inform you, but your access link to the <strong>REC ProPortal 2.0</strong> got expired!<br/><br/> Please fill in your email on the input below and click in the button to request a new link.',
  },
  LINK_EXPIRED_BUTTON: {
    key: `${namespace}.recoverPassword.linkExpiredButton`,
    message: 'Click here to receive a recover password link',
  },
  LINK_EXPIRED_BUTTON_FEEDBACK: {
    key: `${namespace}.recoverPassword.linkExpiredButtonFeedback`,
    message:
      'A new e-mail was sent to you with the details on how to change your password!',
  },
  IGNORE_CHANGE_PASSWORD: {
    key: `${namespace}.recoverPassword.ignoreChangePassword`,
    message: 'Continue to the application without changing the password',
  },
};

const I18N = { RECOVER_PASSWORD };

export default I18N;
