import { StyledProps } from '@mmw/ui-theme/types';

export default ({ theme }: StyledProps<{}>) => `
  font-size: 1rem;
  font-family: ${theme.fonts.fontFamily};
  color: ${theme.colors.pallet.dark};
  margin: 0;
`;

export const pre = ({ theme }) => `
  font-size: 1rem;
  font-family: ${theme.fonts.fontFamily};
  color: ${theme.colors.pallet.dark};
  overflow-x: auto;
  white-space: pre-wrap;
  margin: 0;
`;

export const a = ({ theme }) => `
  color: ${theme.colors.pallet.primary} !important;
  * {
    color: ${theme.colors.pallet.primary} !important;
  }
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;
