import { enableBatching } from 'redux-batched-actions';
import { IModule } from 'redux-dynamic-modules';

import consumerBankAccountEpics, { Epics } from './epics';
import consumerBankAccountReducer from './reducer';
import { NAMESPACE as confumerBankAccountNamespace, RootState } from './types';

export const reducer = consumerBankAccountReducer;
export const epics = consumerBankAccountEpics;
export const NAMESPACE = confumerBankAccountNamespace;

export function getConsumerBankAccountModule(): IModule<RootState> & {
  epics: Epics;
} {
  return {
    id: NAMESPACE,
    reducerMap: {
      [NAMESPACE]: enableBatching(reducer),
    },
    epics,
  };
}
