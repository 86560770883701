import { StyledProps } from '@mmw/ui-theme/types';

export default {
  style: ({ theme }: StyledProps<unknown>): string => `
    display: flex;
    justify-content: center;
    width: 100%;
    height: ${theme.page.variable.FOOTER_HEIGHT};
    background-color: ${theme.colors.pallet.secondary};    
    margin-top: auto;
    position: relative;
    z-index: 1010;
    bottom: 0 !important;  
  `,
  content: {
    style: ({ theme }: StyledProps<unknown>): string => `
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;      
      max-width: ${theme.page.variable.CONTENT_MAX_WIDTH};
      padding: 0 16px;
      ${theme.media.phone`
        flex-direction: column;
        align-items: center;
      `};
    `,
  },
  linksContainer: {
    style: (): string => `
      display: flex;
      justify-content: space-between;
      a {
        margin-right: 10px;
      }
    `,
  },
  text: {
    style: ({ theme }: StyledProps<unknown>): string => `
      color: ${theme.colors.primary.font};
      margin: 0 3px;
    `,
  },
};
