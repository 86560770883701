import { I18nShape } from '@mmw/constants-i18n';
import { use } from '@mmw/ui-theme/utils';
import Link from '@mmw/ui-web-materials-link';
import { noop } from 'lodash';
import React from 'react';
import styled from 'styled-components';

const StyledNavLink = styled(Link)(props => use(props, 'navBar.navLink'));
const NavLinkText = styled(Link.Text)(props =>
  use(props, 'navBar.navLink.text'),
);

type Props = {
  i18n: I18nShape;
  className?: string;
  onClick?: () => void;
};

const NavLink = ({ i18n, className, onClick, ...props }: Props) => (
  <StyledNavLink className={className} onClick={onClick} {...props}>
    <NavLinkText className={className} i18n={i18n} modifiers="uppercase" />
  </StyledNavLink>
);

NavLink.defaultProps = {
  className: undefined,
  onClick: noop,
};

export default NavLink;
