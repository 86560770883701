import { ConsumerProfileJSON } from '@mmw/services-core-consumer-profile/types';
import { U } from 'ts-toolbelt';

import { NAMESPACE, ProfileOperationType, RootState } from './types';

export const loadingSelector = (state: RootState): boolean =>
  state[NAMESPACE].loading;

export const successSelector = (state: RootState): boolean =>
  state[NAMESPACE].success;

export const consumerProfileDataSelector = (
  state: RootState,
): U.Nullable<ConsumerProfileJSON> => state[NAMESPACE].data;

export const changePasswordSuccessStatusSelector = (
  state: RootState,
): boolean => state[NAMESPACE].changePassword.success;

export const errorSelector = (state: RootState): Error =>
  state[NAMESPACE].error;

export const operationTypeSelector = (state: RootState): ProfileOperationType =>
  state[NAMESPACE].operationType;
