/* eslint-disable no-param-reassign */
import { Builder } from '@redux-basic-module/reducer-utils/ReducerBuilder';
import { RetrieveActions } from '@redux-search-module/actions-utils';
import { enableMapSet } from 'immer';

import { RetrieveState, SearchState } from './state';

enableMapSet();

export function addReducerRetrieveCases<ItemType>(
  actions: RetrieveActions<ItemType>,
  builder: Builder<SearchState<any, ItemType>>,
): void {
  const initialRetrieveState = <RetrieveState>new RetrieveState();

  function resolveRetrieveState(
    draft: SearchState<any, ItemType>,
    itemId: number,
  ): RetrieveState {
    if (!draft.retrievesById[itemId]) {
      draft.retrievesById = {
        ...draft.retrievesById,
        [itemId]: initialRetrieveState,
      };
    }
    return draft.retrievesById[itemId];
  }

  builder
    .addCase(actions.start, (draft, { meta: { itemId } }) => {
      const retrieveState = resolveRetrieveState(draft, itemId);
      retrieveState.loading = true;
      return draft;
    })
    .addCase(actions.success, (draft, { payload, meta: { itemId } }) => {
      draft.itemsById = {
        ...draft.itemsById,
        [itemId]: <ItemType>payload,
      };

      const retrieveState = resolveRetrieveState(draft, itemId);

      retrieveState.loading = false;
      retrieveState.error = null;
      retrieveState.requestAttempted = true;
      return draft;
    })
    .addCase(actions.error, (draft, { payload, meta: { itemId } }) => {
      const retrieveState = resolveRetrieveState(draft, itemId);

      retrieveState.loading = false;
      retrieveState.error = payload;
      retrieveState.requestAttempted = true;
      return draft;
    })
    .addCase(actions.cancel, (draft, { meta: { itemId } }) => {
      const searchState = resolveRetrieveState(draft, itemId);

      searchState.loading = false;
      return draft;
    })
    .addCase(actions.dismissError, (draft, { meta: { itemId } }) => {
      const searchState = resolveRetrieveState(draft, itemId);
      searchState.error = null;
      return draft;
    })
    .addCase(actions.reset, (draft, { meta: { itemId } }) => {
      draft.retrievesById = {
        ...draft.retrievesById,
        [itemId]: initialRetrieveState,
      };
      return draft;
    });
}
