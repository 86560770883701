import { BasePath } from '@di/core';
import { LanguageCode } from '@mmw/constants-languages';
import { accessTokenQuery } from '@mmw/services-auth-api-authentication';
import { formatQuery, languageQuery } from '@mmw/utils-query-utils';
import qs from 'qs';

import { CampaignScoreType } from './types';

export const BASE_PATHS = {
  MANU: '/v1/manu/projects',
  TRADER: '/v1/trader/projects',
};

export const [
  getProjectsServiceBasePath,
  setProjectsServiceBasePath,
  PROJECTS_SERVICE_BASE_PATH_TOKEN,
] = BasePath.createDI<string, 'PROJECTS_SERVICE_BASE_PATH_TOKEN'>(
  'PROJECTS_SERVICE_BASE_PATH_TOKEN',
);

export function setManufacturerProjectsServiceBasePath() {
  setProjectsServiceBasePath(BASE_PATHS.MANU);
}

export function setTraderProjectsServiceBasePath() {
  setProjectsServiceBasePath(BASE_PATHS.TRADER);
}

export function getPaths(basePath: string | null) {
  return {
    RetrieveProjectPath: (projectID: number, language: string): string =>
      `${basePath}/${projectID}?${languageQuery(language)}`,

    DeleteProjectPath: (projectID: number): string =>
      `${basePath}/${projectID}`,

    DownloadAvailableProductsXlsPath: (
      projectID: number,
      accessToken: string,
      language: LanguageCode,
    ): string =>
      `${basePath}/${projectID}/download-available-products-xls?${accessTokenQuery(
        accessToken,
      )}&${languageQuery(language)}`,
    RegisterProjectPath: (
      projectID: number,
      language: string,
      simulate: boolean,
      sendMail: boolean,
      certificateLanguage?: string,
    ): string =>
      `${basePath}/${projectID}/register?${qs.stringify({
        language,
        simulate,
        sendMail,
        certificateLanguage,
      })}`,

    UploadProjectFilePath: (
      projectID: number,
      type: string,
      language: string,
    ): string =>
      `${basePath}/${projectID}/file?&${qs.stringify({
        language,
        type,
      })}`,

    RetrieveProjectPanelsTreePath: (
      projectID: number,
      language: string,
    ): string =>
      `${basePath}/${projectID}/panels-tree?${languageQuery(
        language,
      )}&${qs.stringify({
        fetchChildren: true,
      })}`,

    SaveProjectPanelsTreePath: (projectID: number, language: string): string =>
      `${basePath}/${projectID}/panels?${languageQuery(
        language,
      )}&${qs.stringify({
        fetchChildren: true,
      })}`,

    SaveProjectManualPanelsPath: (
      projectID: number,
      language: string,
    ): string =>
      `${basePath}/${projectID}/panels/manual?${languageQuery(
        language,
      )}&${qs.stringify({
        simulate: false,
      })}`,

    DeleteProjectPanelsTreePath: (
      projectID: number,
      language: string,
    ): string =>
      `${basePath}/${projectID}/panels/delete?${languageQuery(
        language,
      )}&${qs.stringify({
        fetchChildren: true,
        useNotAvailableAsQuery: true,
      })}`,

    GetProjectsPath: (language: string): string =>
      `${basePath}?${languageQuery(language)}`,

    DownloadProjectsPath: (language: string): string =>
      `${basePath}/download-projects?${languageQuery(language)}`,

    SyncProjectsStatusPath: (language: string): string =>
      `${basePath}/projects-status?${languageQuery(language)}`,

    CreateProjectPath: (language: string): string =>
      `${basePath}/create?${languageQuery(language)}`,

    CreateAndRegisterProjectPath: (language: string): string =>
      `${basePath}/create-and-register?${languageQuery(language)}`,

    CreateAndRegisterProjectV2Path: (language: string): string =>
      `${basePath}/create-and-register-v2?${languageQuery(language)}`,

    GetProjectFilePath: (
      projectID: number,
      fileID: number,
      accessToken: string,
    ): string =>
      `${basePath}/${projectID}/file/${fileID}?${accessTokenQuery(
        accessToken,
      )}&${languageQuery('de')}`,

    UpdateProjectFilesTypesPath: (
      projectID: number,
      language: string,
    ): string =>
      `${basePath}/${projectID}/update-file-types?${languageQuery(language)}`,

    DeleteProjectFilePath: (
      projectID: number,
      fileID: number,
      language: string,
    ): string =>
      `${basePath}/${projectID}/file/${fileID}?${languageQuery(language)}`,

    UpdateProjectNamePath: (projectID: number, language: string): string =>
      `${basePath}/${projectID}/name?${languageQuery(language)}`,

    UpdateProjectDescriptionPath: (
      projectID: number,
      language: string,
    ): string =>
      `${basePath}/${projectID}/description?${languageQuery(language)}`,

    UpdateProjectSystemSizePath: (
      projectID: number,
      language: string,
    ): string =>
      `${basePath}/${projectID}/system-size?${languageQuery(language)}`,

    UpdateProjectInstallationPlacePath: (
      projectID: number,
      language: string,
    ): string =>
      `${basePath}/${projectID}/installation-place?${languageQuery(language)}`,

    UpdateProjectInstallationTypePath: (
      projectID: number,
      language: string,
    ): string =>
      `${basePath}/${projectID}/installation-type?${languageQuery(language)}`,

    UpdateProjectInstallationSitePath: (
      projectID: number,
      language: string,
    ): string =>
      `${basePath}/${projectID}/installation-site?${languageQuery(language)}`,

    UpdateProjectInstallationDatePath: (
      projectID: number,
      language: string,
    ): string =>
      `${basePath}/${projectID}/installation-date?${languageQuery(language)}`,

    UpdateProjectInvoiceDatePath: (
      projectID: number,
      language: string,
    ): string =>
      `${basePath}/${projectID}/invoice-date?${languageQuery(language)}`,

    ChangeProjectConsumerPath: (
      projectID: number,
      consumerID: number,
      language: string,
    ): string =>
      `${basePath}/${projectID}/consumer?${languageQuery(
        language,
      )}&${qs.stringify({
        consumerID,
      })}`,

    ChangeProjectInstallerPath: (
      projectID: number,
      installerID: number,
      language: string,
    ): string =>
      `${basePath}/${projectID}/installer?${languageQuery(
        language,
      )}&${qs.stringify({
        installerID,
      })}`,

    SetProjectDistributorPath: (
      projectID: number,
      distributorID: number,
      language: string,
    ): string =>
      `${basePath}/${projectID}/distributor?${languageQuery(
        language,
      )}&${qs.stringify({
        distributorID,
      })}`,

    // SearchDeliveriesPath: (): string =>
    //   `${basePath}/tree?${qs.stringify({
    //     fetchChildren: true,
    //   })}`,

    SearchInstallersPath: (language: string): string =>
      `${basePath}/installers?${languageQuery(language)}`,

    SaveProjectInstallerPath: (projectID: number, language: string): string =>
      `${basePath}/${projectID}/installer?${languageQuery(language)}`,

    UploadPanelsSpreadSheetPath: (
      projectID: number,
      language: string,
      format: string,
    ): string =>
      `${basePath}/${projectID}/panels/upload?${languageQuery(
        language,
      )}&${formatQuery(format)}`,

    DownloadRegistrationCertificatePath: (
      projectID: number,
      accessToken: string,
      language: string,
    ): string =>
      `${basePath}/${projectID}/certificate?${accessTokenQuery(
        accessToken,
      )}&${languageQuery(language)}`,

    MultiDownloadRegistrationCertificatePath: (language: string): string =>
      `${basePath}/certificate?${languageQuery(language)}`,

    DownloadProductsReportingXlsPath: (
      projectID: number,
      accessToken: string,
      language: LanguageCode,
    ): string =>
      `${basePath}/${projectID}/download-panels-xls?${accessTokenQuery(
        accessToken,
      )}&${languageQuery(language)}`,

    SavePanelsFromSpreadSheetUpload: (
      projectID: number,
      language: string,
    ): string =>
      `${basePath}/${projectID}/panels/upload/save?${languageQuery(language)}`,

    GetDrawCampaignsPath: (
      projectID: number,
      language: string,
      simulate: boolean,
    ): string =>
      `${basePath}/${projectID}/draw-campaigns?${qs.stringify({
        language,
        simulate,
      })}`,

    GetCampaignScoresPath: (
      projectID?: number,
      scoreType?: CampaignScoreType,
      orgunitID?: number,
    ): string =>
      `${basePath}/campaign-scores?${qs.stringify({
        projectID,
        scoreType,
        orgunitID,
      })}`,

    GetDrawFinishPath: (
      language: string,
      projectID: number,
      certificateLanguage?: string,
    ): string =>
      `${basePath}/${projectID}/draw-finish?${qs.stringify({
        language,
        certificateLanguage,
      })}`,

    UpdateQuestionsPath: (
      projectID: number,
      language: string,
      simulate: boolean,
    ): string =>
      `${basePath}/${projectID}/update-campaigns?${qs.stringify({
        language,
        simulate,
      })}`,
    GetDistributorsListPath: (language: string): string =>
      `${basePath}/distributors?${qs.stringify({
        language,
      })}`,

    GetRankedCampaignScoresByInstallerPath: (): string =>
      '/v1/public/trader/projects/ranked-campaign-scores-by-installer',

    GetSumOfSystemSizesPath: (year: string): string =>
      `${basePath}/system-sizes?${qs.stringify({
        year,
      })}`,
    GetSumOfSystemSizesPathByOrgnuitID: (
      orgunitID: number,
      year: string,
    ): string =>
      `${basePath}/system-sizes?${qs.stringify({
        year,
        orgunitID,
      })}`,
    GetDownloadFlasherDataPath: (language: string): string =>
      `${basePath}/flasher-data?${languageQuery(language)}`,

    GetRevalidateProjectPath: (language: string): string =>
      `${basePath}/revalidate?${languageQuery(language)}`,

    GetReverseProjectPath: (language: string): string =>
      `${basePath}/reverse?${languageQuery(language)}`,

    // TODO implement updateSystemSize for manufacturer only
    GetUpdateSystemSizePath: (id: number, language: string): string =>
      `${basePath}/${id}/system-size?${qs.stringify({
        language,
      })}`,
  };
}
