const namespace = 'campaigns';

export default {
  CURRENT_CAMPAIGNS_TITLE: {
    key: `${namespace}.current.title`,
    message: 'Current Campaigns',
  },
  CURRENT_CAMPAIGNS_SUBTITLE: {
    key: `${namespace}.current.subTitle`,
    message:
      'Select the campaign you would like to take part in or have taken part in',
  },
  CAMPAIGNS_FILTER_DESC: {
    key: `${namespace}.filterDescription`,
    message:
      'Here you can see all campaigns that are currently active in { country }',
  },
  NO_CAMPAIGNS_FOUND: {
    SUB_TITLE: {
      key: `${namespace}.noCampaignsFound.subTitle`,
      message: 'No campaigns found on your country, try again later',
    },
  },
};
