import ConnectedI18nProvider from '@mmw/redux-store-i18n/components/ConnectedI18nProvider';
import NotificationProvider from '@mmw/ui-web-providers-notifications';
import { AppStoreInitializer } from '@retail/app-store-initializer';
import { MetaTags } from '@retail/configure-meta-tags';
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { Store } from 'redux';

export interface BaseProps {
  store: Store;
  children: JSX.Element | JSX.Element[];
}

const AppBase = ({ store, children }: BaseProps) => (
  <ReduxProvider store={store}>
    <AppStoreInitializer>
      <ConnectedI18nProvider>
        <NotificationProvider>
          <MetaTags />
          {children}
        </NotificationProvider>
      </ConnectedI18nProvider>
    </AppStoreInitializer>
  </ReduxProvider>
);

export default AppBase;
