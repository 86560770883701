import style from './paper';

const variable = {
  PADDING: '10px',
};
export default {
  style,
  modifiers: undefined,
  variable,
};
