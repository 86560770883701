import { use } from '@mmw/ui-theme/utils';
import styled from 'styled-components';

const Content = styled.div(props => use(props, 'page.content'));

export const ContentContainer = styled.div(
  ({ theme }) => `
  margin: 10px auto 30px auto;
  ${theme.media.phone(`
    max-width: 425px;
  `)}
`,
);

export const NoContentContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const NoCampaignsImage = styled.img(
  ({ theme }) => `
  width: 400px;
  height: 400px;
  opacity: 0.1;
  ${theme.media.upToTablet`
    width: 100%;
    height: 100%;
  `}
`,
);

export default Content;
