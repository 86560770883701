import { useTranslate } from '@mmw/utils-text-utils/hooks';
import { AUTHENTICATION as I18N } from '@retail/constants-i18n';
import { useMemo } from 'react';

const RECIPIENT = 'retail@mmw.ag';

const useEmailContact = (): string => {
  const translate = useTranslate();
  const subject = translate(I18N.AUTHENTICATION.CONTACT_EMAIL_SUBJECT);
  const body = translate(I18N.AUTHENTICATION.CONTACT_EMAIL_BODY);
  return useMemo(
    () => `mailto:${RECIPIENT}?subject=${subject}&body=${body}`,
    [body, subject],
  );
};

export default useEmailContact;
