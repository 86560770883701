// eslint-disable-next-line max-classes-per-file
import { PerformIssueActionJSONWithId } from '@issue/context';
import { CampaignStatus } from '@mmw/constants-campaign-status';
import { CampaignType } from '@mmw/constants-campaign-types';
import { LanguageCode } from '@mmw/constants-languages';
import { Roles } from '@mmw/constants-roles';
import { ValidationError } from '@mmw/constants-validation-errors';
import { UserScopeJSON } from '@mmw/services-core-admin-user/types';
import {
  AddressJSON,
  ConsumerJSON,
  OwnerJSON,
  PersonJSON,
} from '@mmw/services-core-common/types';
import { ProductJSON } from '@mmw/services-core-product/types';
import { OrgunitJSON } from '@mmw/services-core-projects/types';
import {
  AvailableThirdPartyCampaignJSON,
  CampaignItemJSON,
  PurchaseSelection,
  RegistrationStatusModelJSON,
} from '@mmw/services-core-trader-registration/types';
// eslint-disable-next-line max-classes-per-file
import { U } from '@utils/ts';

type RegistrationActionRequestJSON = {
  ordernumbers: string[];
  comment?: U.Nullable<string>;
  commentShownInEmail?: boolean;
  campaignIds?: U.Nullable<number[]>;
  campaignItemIds?: U.Nullable<number[]>;
  registrationItemIds?: U.Nullable<number[]>;
  manualValidationErrors?: U.Nullable<number>;
};

type RegistrationActionWithIssuesRequestJSON = {
  language: LanguageCode;
  createIssues?: CreateIssueJSON[];
  updateIssues?: PerformIssueActionJSONWithId[];
} & RegistrationActionRequestJSON;

export type RegistrationActionWithStatusRequestJSON = {
  campaignStatus: CampaignStatus;
  closeIssuesInProgress?: boolean;
  closeAllIssues?: boolean;
} & RegistrationActionWithIssuesRequestJSON;

export type RegistrationActionWithValidationErrorRequestJSON = {
  addValidationError: U.Nullable<ValidationError>;
  removeValidationErrors: U.Nullable<ValidationError[]>;
} & RegistrationActionWithIssuesRequestJSON;

type ChangeRegistrationMonitoring = {
  registrationItemIDs: number[];
  campaignItemIDs: number[];
  issueIDs: number[];
  registrationIDs: number[];
};

type RegistrationChangeErrorJSON = {
  code: string; // TODO
  description: string;
};

type RegistrationChangeMeta = {
  registrationItemIDs: number[];
  campaignItemIDs: number[];
  relevantIssueIDs: number[];
  campaignIDs: number[];
  ordernumbers: string[];
};

type FieldValueChangeRequestJSON = {
  field: string; // TODO
  current: any;
  next: any;
};

type RegistrationChangeJSON = {
  actionPerformed: string; // todo: IssueActionRequired
  changeType: string; // TODO
  meta: RegistrationChangeMeta;
  data: FieldValueChangeRequestJSON;
  logType: string; // TODO
  error: RegistrationChangeErrorJSON;
  success: boolean;
};

type RegistrationChangeItemOperationsJSON = {
  startTime: Date;
  endTime: Date;
  successfullChanges: RegistrationChangeJSON[];
  unsuccessfullChanges: RegistrationChangeJSON[];
  error: string; // TODO
  itemName: string;
  itemType: string; // TODO
};

export type OperationsByRegistrationStatus = {
  successfullChangesTotal: number;
  unsuccessfullChangesTotal: number;
  startTime: Date;
  endTime: Date;
  ordernumber: string;
  registrationID: number;
  position: number;
  campaignItemIDs: number[];
  startedRegistrationsMonitoring: ChangeRegistrationMonitoring;
  finishedRegistrationsMonitoring: ChangeRegistrationMonitoring;
  subOperations: RegistrationChangeItemOperationsJSON;
  error: string; // TODO
  success: boolean;
};

export type ChangeRegistrationStatusOperations = {
  actionType: string; // TODO
  startTime: Date;
  endTime: Date;
  success: boolean;
  operationsByRegistrationStatus: OperationsByRegistrationStatus[];
};

export interface EndTime {
  date: number;
  day: number;
  hours: number;
  minutes: number;
  month: number;
  nanos: number;
  seconds: number;
  time: number;
  timezoneOffset: number;
  year: number;
}

export type CampaignItemIDs = string[];

export interface Error {
  code: string;
  description: string;
}

export interface FinishedRegistrationsMonitoring {
  campaignItemIDs: number[];
  issueIDs: number[];
  registrationIDs: number[];
  registrationItemIDs: number[];
}

export interface LogEntry {
  date: Date;
  message: string;
  type: string;
}

export interface StartTime {
  date: number;
  day: number;
  hours: number;
  minutes: number;
  month: number;
  nanos: number;
  seconds: number;
  time: number;
  timezoneOffset: number;
  year: number;
}

export interface StartedRegistrationsMonitoring {
  campaignItemIDs: number[];
  issueIDs: number[];
  registrationIDs: number[];
  registrationItemIDs: number[];
}

export type Current = string[];

export type Next = string[];

export interface Data {
  current: Current;
  field: string;
  next: Next;
}

export type CampaignIDs = string[];

export type Ordernumbers = string[];

export type RegistrationItemIDs = string[];

export type RelevantIssueIDs = string[];

export interface Meta {
  campaignIDs: CampaignIDs;
  campaignItemIDs: CampaignItemIDs;
  ordernumbers: Ordernumbers;
  registrationItemIDs: RegistrationItemIDs;
  relevantIssueIDs: RelevantIssueIDs;
}

export interface SuccessfullChange {
  actionPerformed: string;
  changeType: string;
  data: Data;
  error: Error;
  logType: string;
  meta: Meta;
  success: boolean;
}

export interface UnsuccessfullChange {
  actionPerformed: string;
  changeType: string;
  data: Data;
  error: Error;
  logType: string;
  meta: Meta;
  success: boolean;
}

export interface SubOperation {
  endTime: EndTime;
  error: Error;
  itemName: string;
  itemType: string;
  startTime: StartTime;
  successfullChanges: SuccessfullChange[];
  unsuccessfullChanges: UnsuccessfullChange[];
}

export interface OperationsByRegistrationStatu {
  campaignItemIDs: CampaignItemIDs;
  endTime: EndTime;
  error: Error;
  finishedRegistrationsMonitoring: FinishedRegistrationsMonitoring;
  logEntries: LogEntry[];
  ordernumber: string;
  position: number;
  registrationID: number;
  startTime: StartTime;
  startedRegistrationsMonitoring: StartedRegistrationsMonitoring;
  subOperations: SubOperation[];
  success: boolean;
  successfullChangesTotal: number;
  unsuccessfullChangesTotal: number;
}

export interface CreateIssueResponseJSON {
  actionType: string;
  endTime: EndTime;
  operationsByRegistrationStatus: OperationsByRegistrationStatu[];
  startTime: StartTime;
  success: boolean;
}

export enum IssueStatus {
  TODO,
  IN_PROGRESS,
  CLOSED,
}

export const ALL_STATUS = [
  IssueStatus.TODO,
  IssueStatus.IN_PROGRESS,
  IssueStatus.CLOSED,
];

export enum IssueResolutionMode {
  UNRESOLVED,
  RESOLVED,
}

export enum IssueActionRequired {
  NONE, // Just for the 0
  CORRECT_QUESTION_ANSWER,
  CORRECT_SHIPPING_ADDRESS,
  CORRECT_INVOICE_FILE,
  CORRECT_AFFIDAVIT_FILE,
  CORRECT_DATA_PRIVACY_FILE,
  CORRECT_IBV1_FILE,
  CORRECT_IBV2_FILE,
  CORRECT_MINUTES_OF_THE_CONSULTATION_FILE,
  CORRECT_PROOF_OF_SERVICE_FILE,
  CORRECT_SERVICE_DOCUMENT_FILE,
  CORRECT_SERIAL_NUMBER,
  CORRECT_IMEI,
  CORRECT_INVOICE_NUMBER,
  CORRECT_BANK_ACCOUNT,
  CORRECT_DATA_PRIVACY_CONSENT,
  CORRECT_ADDED_VALUE,
  CORRECT_COUPON_NUMBER,
  CORRECT_PROMOTION_CODE,
  CORRECT_GIVEN_EAN,
  CORRECTED_PARTICIPATION_DATA,
  CORRECT_PRODUCT,
  CORRECT_QUANTITY,
  CORRECT_SALES_CHANNEL_TYPE,
  CORRECT_TRADER,
  CORRECT_QUESTION_ATTACHMENT_FILE,
  CORRECT_INVOICE_AMOUNT,
  CORRECT_INVOICE_AMOUNT_TYPE,
  CORRECT_IBV0_FILE,
}

export enum IssueActionType {
  ISSUE_CREATED = 'ISSUE_CREATED',
  ISSUE_UPDATED = 'ISSUE_UPDATED',
  AUTOMATIC_TRANSITION_DUE_TO_CORRECTION = 'AUTOMATIC_TRANSITION_DUE_TO_CORRECTION',
  ACTION_REQUIRED_DONE = 'ACTION_REQUIRED_DONE',
  ACTION_REQUIRED_UNDONE = 'ACTION_REQUIRED_UNDONE',
  AUTOMATIC_TRANSITION_DUE_TO_CHANGE_STATUS = 'AUTOMATIC_TRANSITION_DUE_TO_CHANGE_STATUS',
}

export enum IssueType {
  DATA_CORRECTION,
}

interface IssueHistoryEntryJSON {
  date: Date;
  createdBy: number;
  actionType: string;
  previousStatus: IssueStatus;
  currentStatus: IssueStatus;
  previousAssignee: number;
  currentAssignee: number;
  message: string;
  technicalMessage: string;
  actorvisibility: string;
}

export class IssueDataJSON {
  message = '';

  entries: IssueHistoryEntryJSON[] = [];

  configuration: Partial<IssueConfigurationJSON> = {};

  campaignItemIDs: number[] = [];

  registrationItemIDs: number[] = [];

  fileIDs: number[] = [];

  questionGroupCodes: string[] = [];

  questionCodes: string[] = [];
}

export interface IssueValueObject {
  id: number;
  createdBy: number;
  created: Date;
  updatedBy: number;
  updated: Date;
  summary: string;
  status: IssueStatus;
  actionRequired: IssueActionRequired;
  type: IssueType;
  dueDate: U.Nullable<Date>;
  actorvisibility: U.Nullable<number>;
  assigneeID: U.Nullable<number>;
  assigneeUserid: U.Nullable<string>;
  data: IssueDataJSON;
  resolutionMode: IssueResolutionMode;
}

interface IssueConfigurationJSON {
  associatedToCampaignItems: boolean;
  associatedToRegistrationItems: boolean;
  amountOfActionsRequiredToResolve: U.Nullable<number>;
}

export class CreateIssueJSON {
  summary: U.Nullable<string> = '';

  actorvisibility: U.Nullable<number> = null;

  actionRequired: IssueActionRequired = IssueActionRequired.NONE;

  type: IssueType = IssueType.DATA_CORRECTION;

  dueDate: U.Nullable<Date> = null;

  assigneeID: U.Nullable<number> = null;

  data: IssueDataJSON = new IssueDataJSON();

  initialStatus: U.Nullable<IssueStatus> = null;

  initialResolutionMode: U.Nullable<IssueResolutionMode> = null;
}

export interface CreateIssueRequestJSON {
  campaignIds: CampaignIDs;
  campaignItemIds: CampaignItemIDs;
  comment: string;
  commentShownInEmail: boolean;
  createIssues: CreateIssueJSON[];
  language?: string;
  ordernumbers: string[];
  registrationItemIds: RegistrationItemIDs;
}

export type PerformIssueActionJSON<D> = {
  actionType: IssueActionType;

  newActionTypeRequired?: IssueActionRequired;

  message?: string;

  technicalMessage?: string;

  data?: D;

  actorvisibility?: number;

  newStatus?: IssueStatus;

  newResolutionMode?: IssueResolutionMode;

  newAssignee?: number;

  newDueDate?: Date;
};

export type RegistrationHistoryResponseJSON = {
  registrationHistoryID: number;
  registrationItemID: number;
  product: ProductJSON;
  serialnumber: string;
  imei: string;
  modificationDate: string;
  modificationUser: string;
  reversed: boolean;
  reason: string;
  productDescription: string;
  coupons: string[];
  owner: OwnerJSON;
  campaignItems: CampaignItemJSON[];
  addresses: string;
  returncodes: string;
  invoicenumber: string;
  invoiceAmount: number;
  invoiceAmountType: string;
};

export type UserJSON = {
  group: string;
  id: number;
  lastLogin: string;
  orgunitID: number;
  person: PersonJSON;
  roles: Roles[];
  userScope: UserScopeJSON;
  userid: string;
};

export type ReverseRegistrationRequestJSON = {
  campaignIds: CampaignIDs;
  campaignItemIds: CampaignItemIDs;
  registrationItemIds: RegistrationItemIDs;
  ordernumbers: Ordernumbers;
  manualValidationErrors: number;
  comment: string;
  commentShownInEmail: boolean;
};

export type RegistrationsRequestJSON = {
  limit: number;
  offset: number;
  orderNumber: string;
  registrationDateFrom: string;
  registrationDateTo: string;
  registrationType: number;
  purchaseDateFrom: string;
  purchaseDateTo: string;
  serialNumber: string;
  productName: string;
  couponNumber: string;
  ean: string;
  imei: string;
  brandID: number;
  consumerFirstName: string;
  consumerLastName: string;
  membernumber: string;
  consumerEmail: string;
  country: string;
  ownerErp: string;
  ownerName: string;
  ownerZipcode: string;
  ownerCity: string;
  campaignCode: string;
  campaignCodes: string[];
  campaignStatus: number;
  campaignStatusList: number[];
  campaignID: number;
  campaignType: CampaignType;
  reversedItems: boolean;
  onlyReversedItems: boolean;
  emptyserialnumbers: boolean;
  branchIDs: number[];
  branchID: number;
  classOfGoods: number;
  invoiceNumber: string;
  validationError: number;
  disableCount: boolean;
};

export type ProductSelectionJSON = {
  serialnumber: string;
  productID: number;
  imei: string;
  coupon: string;
  promotionCode: string;
  givenEan: string;
  quantity: number;
  uuid: string;
  kitUuid: string;
  comment: string;
  id: number;
  product: ProductJSON;
  reversed: boolean;
  statusModel: RegistrationStatusModelJSON;
};

export type RegistrationJSON = {
  storeID: number;
  registrationType: number;
  purchaseSelection: PurchaseSelection;
  campaignItems: CampaignItemJSON[];
  availableThirdPartyCampaigns: AvailableThirdPartyCampaignJSON[];
  shippingAddress: AddressJSON;
  consumer: ConsumerJSON;
  salesOrgBrandID: number;
  dataPrivacyConsent: boolean;
  ordernumber: string;
  externalOrdernumber: string;
  id: number;
  registrationDate: string;
  userid: string;
  statusModel: RegistrationStatusModelJSON;
  productSelection: ProductSelectionJSON[];
  frozen: boolean;
  comment: string;
  store: OrgunitJSON;
  thirdPartyCampaignRegistrationResponse: {
    errors: Array<string>;
    registered: boolean;
  };
  operation: ChangeRegistrationStatusOperations;
  issues: IssueValueObject[];
  reversed: boolean;
  premiumRegistration: boolean;
  basicRegistration: boolean;
  consumerRegistration: boolean;
  externalRegistration: boolean;
  autoRegistration: boolean;
  demoDeviceRegistration: boolean;
  auditRegistration: boolean;
};

export type RegistrationsResponseJSON = {
  offset: number;
  limit: number;
  list: RegistrationJSON[];
  total: number;
};

export interface RegistrationItemCommentJSON {
  userid: string;
  enduserID: number;
  comment: string;
  userComment: string;
  shownInEmail: boolean;
  type: string;
  timestamp: string;
  date: string;
  extraParameters: ExtraParameters;
  registrationItemIDs: number[];
  historyId: number;
  campaignItemIDs: number[];
  billCheck: BillCheck;
  valueProcess: ValueProcess;
}

export interface ExtraParameters {
  additionalProp1: AdditionalProp;
  additionalProp2: AdditionalProp;
  additionalProp3: AdditionalProp;
}

export interface AdditionalProp {}

export interface BillCheck {
  triggeredValidationErrors: string;
  processStatus: string;
  additionalInfo: string;
}

export interface ValueProcess {
  jobId: number;
  processStatus: string;
  triggeredValidationErrors: string[];
}
