import {
  USER_AND_PASS_LOGIN_INITIAL_VALUES,
  USERNAME_AND_PASSWORD_VALIDATION_SCHEMA,
} from '@auth/form-data';
import { AuthErrorHandler } from '@auth/view-error';
import { createDI } from '@di/core';
import { useLoginByUserAndPass } from '@mmw/redux-store-auth-api-login-by-password/hooks';
import { FastFormProvider } from '@mmw/redux-store-fast-form';
import { SecurityScopeNames } from '@mmw/services-auth-api-authentication/types';
import { css } from '@ui-system/css';
import UI from '@ui-system/ui';
import isEmpty from 'lodash/isEmpty';
import React, { PropsWithChildren, useCallback } from 'react';
import { F, U } from 'ts-toolbelt';

// const AUTH_ERROR_HANDLER_CONFIG = {
//   [PossibleErrors.BAD_CREDENTIALS]: ErrorNotification,
// };

export const [getAuthScopeNames, setAuthScopeNames] =
  createDI<U.Nullable<SecurityScopeNames[]>>('AUTH_SCOPE_NAMES');

type Props = {
  onSuccess?: F.Function;
  customErrorConfigs?: Record<string, React.FC>;
};

const Form: React.FC<PropsWithChildren<Props>> = ({
  onSuccess,
  customErrorConfigs,
  children,
}: PropsWithChildren<Props>) => {
  const loginByUserAndPass = useLoginByUserAndPass(onSuccess);
  const onSubmitSuccess = useCallback(
    data => {
      const authScopeNames = getAuthScopeNames();
      if (authScopeNames && !isEmpty(authScopeNames)) {
        loginByUserAndPass(data, authScopeNames);
        return;
      }
      loginByUserAndPass(data);
    },
    [loginByUserAndPass],
  );

  // const errorHandlerConfigs = useMemo(() => {
  //   if (!isEmpty(customErrorConfigs)) {
  //     return {
  //       ...AUTH_ERROR_HANDLER_CONFIG,
  //       ...customErrorConfigs,
  //     };
  //   }
  //   return AUTH_ERROR_HANDLER_CONFIG;
  // }, [customErrorConfigs]);

  return (
    <FastFormProvider
      // @ts-ignore
      validationSchema={USERNAME_AND_PASSWORD_VALIDATION_SCHEMA}
      initialValues={USER_AND_PASS_LOGIN_INITIAL_VALUES}
      onSubmitSuccess={onSubmitSuccess}
    >
      <UI.Container direction="column" gap={3}>
        {children}
        <AuthErrorHandler
          style={css`
            border-radius: 20px;
            margin-bottom: 20px;
          `}
          config={customErrorConfigs}
        />
      </UI.Container>
    </FastFormProvider>
  );
};

export default Form;
