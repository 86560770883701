import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import {
  OperationResult,
  OperationsEnum,
} from '@mmw/services-auth-api-authentication/types';
import { getAuthenticationService } from '@mmw/services-holder';
import { ActionsObservable } from 'redux-observable';
import { concat, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, timeout } from 'rxjs/operators';

import {
  requestEmailVerificationErrorAction,
  requestEmailVerificationSuccessAction,
} from '../actions';
import {
  REQUEST_EMAIL_VERIFICATION_START,
  RequestEmailVerificationErrorAction,
  RequestEmailVerificationStartAction,
  RequestEmailVerificationSuccessAction,
} from '../types';

const { logger } = contextualConfig.application;
const { defaultTimeout } = contextualConfig.api;

async function requestEmailVerification(
  email: string,
  operationId: OperationsEnum,
  recaptchaToken?: string,
): Promise<OperationResult> {
  const result = await getAuthenticationService().requestEmailVerification({
    email,
    operationId,
    recaptchaResponse: recaptchaToken,
    recaptchaType: 'V3',
  });
  return result;
}

const requestEmailVerificationEpic = (
  action$: ActionsObservable<RequestEmailVerificationStartAction>,
): Observable<
  RequestEmailVerificationSuccessAction | RequestEmailVerificationErrorAction
> =>
  action$.pipe(
    ofType(REQUEST_EMAIL_VERIFICATION_START),
    tap(() => logger.debug('Trying to request a email verification code')),
    switchMap(({ payload: { email, operationId, recaptchaToken } }) =>
      from(requestEmailVerification(email, operationId, recaptchaToken)).pipe(
        timeout(defaultTimeout),
        map(data => requestEmailVerificationSuccessAction(data)),
        catchError(error =>
          concat(of(requestEmailVerificationErrorAction(error))),
        ),
      ),
    ),
  );

export default requestEmailVerificationEpic;
