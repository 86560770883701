import { StyledProps } from '../../types';

const svgColorModifiers = {
  primary: ({ theme }: StyledProps<{}>) => `
    fill: ${theme.colors.pallet.primary};
  `,
  secondary: ({ theme }: StyledProps<{}>) => `
    fill: ${theme.colors.pallet.secondary};
  `,
  basic: ({ theme }: StyledProps<{}>) => `
    fill: ${theme.colors.pallet.basic};
  `,
  basicLight: ({ theme }: StyledProps<{}>) => `
    fill: ${theme.colors.pallet.basicLight};
  `,
  basicDark: ({ theme }: StyledProps<{}>) => `
    fill: ${theme.colors.pallet.basicDark};
  `,
  dark: ({ theme }: StyledProps<{}>) => `
    fill: ${theme.colors.pallet.dark};
  `,
  light: ({ theme }: StyledProps<{}>) => `
    fill: ${theme.colors.pallet.light};
  `,
  success: ({ theme }: StyledProps<{}>) => `
    fill: ${theme.colors.pallet.success};
  `,
  alert: ({ theme }: StyledProps<{}>) => `
    fill: ${theme.colors.pallet.alert};
  `,
  error: ({ theme }: StyledProps<{}>) => `
    fill: ${theme.colors.pallet.error};
  `,
  background: ({ theme }: StyledProps<{}>) => `
    fill: ${theme.colors.pallet.background};
  `,
};

export default svgColorModifiers;
