import { use } from '@mmw/ui-theme/utils';
import Icon from '@mmw/ui-web-elements-icon';
import React from 'react';
import styled from 'styled-components';

const StyledSpan = styled.span(props => use(props, 'inputGroup.span'));

const StyledIcon = styled(Icon)(props => use(props, 'inputGroup.span.icon'));

type Props = {
  icon: SVGMatrix;
};

const Span = ({ icon, ...props }: Props) => (
  <StyledSpan {...props}>
    <StyledIcon src={icon} {...props} />
  </StyledSpan>
);

export default Span;
