import { ThemeMode } from '@ui-system/theme';

export const TYPOGRAPHY = {
  fontFamily: {
    regular: "'Helvetica', Regular",
    bold: "'Helvetica', Bold",
    italic: "'Helvetica', Italic",
    thin: "'Helvetica', Thin",
    boldItalic: "'Helvetica', BoldItalic",
    thinItalic: "'Helvetica', LightItalic",
  },
  colors: {
    [ThemeMode.LIGHT]: {
      onBackground: '#000',
    },
  },
};
