import { NAMESPACE, RootState } from './types';

export const loadingSelector = (state: RootState): boolean =>
  state[NAMESPACE].loading;

export const unblockSuccessStatusSelector = (state: RootState): boolean =>
  state[NAMESPACE].unblockStatus;

export const errorSelector = (state: RootState): Error =>
  state[NAMESPACE].error;
