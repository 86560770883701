import { createComponentModifiers } from '@ui-system/modifiers';

const componentModifiers = createComponentModifiers(
  ({ theme, css, nullifyInReactNative }) => ({
    border: css`
      border: 1px solid ${theme.colors[theme.mode].primary.main};
    `,
    fade: css`
      opacity: 0.3;
    `,
    pointer: css`
      cursor: pointer;
    `,
    nowrap: css`
      flex-wrap: nowrap;
    `,
    overflowvisible: css`
      overflow: visible;
    `,
    grid: nullifyInReactNative(css`
      width: 100%;
      display: grid;
    `),
    gridgap4: nullifyInReactNative(css`
      grid-row-gap: ${theme.spacing[4]}px;
      grid-column-gap: ${theme.spacing[4]}px;
    `),
    gridgap6: nullifyInReactNative(css`
      grid-row-gap: ${theme.spacing[6]}px;
      grid-column-gap: ${theme.spacing[6]}px;
    `),
    grid2Columns: nullifyInReactNative(css`
      grid-template-columns: 1fr 1fr;
    `),
    gridRowGap4: nullifyInReactNative(css`
      grid-row-gap: ${theme.spacing[4]}px;
    `),
    gridRowGap2: nullifyInReactNative(css`
      grid-row-gap: ${theme.spacing[2]}px;
    `),
    gridColGap4: nullifyInReactNative(css`
      grid-column-gap: ${theme.spacing[4]}px;
    `),
    gridColGap2: nullifyInReactNative(css`
      grid-column-gap: ${theme.spacing[2]}px;
    `),
    grid1col: nullifyInReactNative(css`
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
    `),
    grid2col: nullifyInReactNative(css`
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: ${theme.spacing[2]}px;
      grid-row-gap: ${theme.spacing[2]}px;
    `),
    grid1fr3frcol: nullifyInReactNative(css`
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 3fr;
      grid-column-gap: ${theme.spacing[2]}px;
      grid-row-gap: ${theme.spacing[2]}px;
    `),
    grid3col: nullifyInReactNative(css`
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: ${theme.spacing[2]}px;
    `),
    grid4col: nullifyInReactNative(css`
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-column-gap: ${theme.spacing[2]}px;
    `),
    grid5col: nullifyInReactNative(css`
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-column-gap: ${theme.spacing[2]}px;
    `),
    grid6col: nullifyInReactNative(css`
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-column-gap: ${theme.spacing[2]}px;
    `),
    grid1row: nullifyInReactNative(css`
      width: 100%;
      display: grid;
      grid-template-rows: 1fr;
    `),
    grid2row: nullifyInReactNative(css`
      width: 100%;
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-column-gap: ${theme.spacing[2]}px;
      grid-row-gap: ${theme.spacing[2]}px;
    `),
    grid3row: nullifyInReactNative(css`
      width: 100%;
      display: grid;
      grid-template-rows: 1fr 1fr 1fr;
      grid-column-gap: ${theme.spacing[2]}px;
    `),
    grid4row: nullifyInReactNative(css`
      width: 100%;
      display: grid;
      grid-template-rows: 1fr 1fr 1fr 1fr;
      grid-column-gap: ${theme.spacing[2]}px;
    `),
    grid5row: nullifyInReactNative(css`
      width: 100%;
      display: grid;
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
      grid-column-gap: ${theme.spacing[2]}px;
    `),
    gridJustifyCenter: nullifyInReactNative(css`
      justify-items: center;
    `),
  }),
);

export default componentModifiers;
