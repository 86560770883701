import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import { orgunitBranchesByAuthSelector } from '@mmw/redux-store-core-orgunit/selectors';
import { ActionsObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  tap,
  timeout,
  withLatestFrom,
} from 'rxjs/operators';

import {
  getAvailableStoresErrorAction,
  getAvailableStoresSuccessAction,
} from '../actions';
import { RootState } from '../stateSelector';
import {
  GET_AVAILABLE_STORES_START,
  GetAvailableStoresErrorAction,
  GetAvailableStoresStartAction,
  GetAvailableStoresSuccessAction,
} from '../types';

const { logger } = contextualConfig.application;
const { defaultTimeout } = contextualConfig.api;

const getAvailableStoresEpic = (
  action$: ActionsObservable<GetAvailableStoresStartAction>,
  state$: Observable<RootState>,
): Observable<
  GetAvailableStoresSuccessAction | GetAvailableStoresErrorAction
> =>
  action$.pipe(
    ofType(GET_AVAILABLE_STORES_START),
    withLatestFrom(state$.pipe(map(orgunitBranchesByAuthSelector))),
    tap(() => logger.debug('Get Logged in trader data Start')),
    switchMap(([, getStores]) =>
      from(getStores() || []).pipe(
        timeout(defaultTimeout),
        tap(() => logger.debug('Got logged user data')),
        map(data => getAvailableStoresSuccessAction(data)),
        catchError(error => of(getAvailableStoresErrorAction(error))),
      ),
    ),
  );

export default getAvailableStoresEpic;
