const namespace = 'registration';
const projectDataNamespace = `${namespace}.projectData`;
const informedSystemSizeNamespace = `${projectDataNamespace}.informedSystemSize`;
const productNamespace = `${namespace}.products`;
const productsButtonsNamespace = `${productNamespace}.buttons`;
const productsErrorsNamespace = `${productNamespace}.errors`;
const productsWarningsNamespace = `${productNamespace}.warnings`;
const projectFilesNamespace = `${namespace}.projectFiles`;
const endCustomerNamespace = `${namespace}.endCustomer`;
const installerNamespace = `${namespace}.installer`;
const installerButtonsNamespace = `${installerNamespace}.buttons`;
const installationNamespace = `${namespace}.installation`;
const installerAdvancedSearchNamespace = `${installerNamespace}.advancedSearch`;
const installerCrudNamespace = `${installerNamespace}.crudModal`;
const installerSearchLabelsNamespace = `${installerAdvancedSearchNamespace}.labels`;
const completeRegistrationNamespace = `${namespace}.completeRegistration`;
const registrationWarrantyNamespace = `${completeRegistrationNamespace}.warranty`;
const productModalNamespace = `${productNamespace}.modal`;

export const PROJECT_DATA = {
  TITLE: {
    key: `${projectDataNamespace}.title`,
    message: 'Registration data',
  },
  PROJECT_NAME: {
    key: `${projectDataNamespace}.projectName`,
    message: 'Registration name',
  },
  LOCATION: {
    key: `${projectDataNamespace}.location`,
    message: 'Location',
  },
  PROJECT_DESCRIPTION: {
    key: `${projectDataNamespace}.projectDescription`,
    message: 'Registration description',
  },
  PROJECT_COUNT: {
    key: `${projectDataNamespace}.projectCount`,
    message: 'Projects',
  },
  PROJECT_SYSTEM_SIZE: {
    key: `${projectDataNamespace}.projectSystemSize`,
    message: 'Registration system size',
  },
  INSTALLATION_PLACE: {
    key: `${projectDataNamespace}.installationPlace`,
    message: 'Installation site',
  },
  ROOFTOP_MOUNTED: {
    key: `${projectDataNamespace}.rooftopMounted`,
    message: 'Rooftop',
  },
  GROUNDED_MOUNTED: {
    key: `${projectDataNamespace}.groundedMounted`,
    message: 'Ground mount',
  },
  LESS_THAN_25KWP: {
    key: `${informedSystemSizeNamespace}.lessThan25`,
    message: 'up to 25 kWp',
  },
  MORE_THAN_25KWP: {
    key: `${informedSystemSizeNamespace}.moreThan25`,
    message: 'has more than 25 kWp',
  },
  INSTALLATION_TYPE: {
    key: `${projectDataNamespace}.installationType`,
    message: 'Installation type',
  },
  RESIDENTIAL: {
    key: `${projectDataNamespace}.residential`,
    message: 'Residential',
  },
  COMMERCIAL: {
    key: `${projectDataNamespace}.commercial`,
    message: 'Commercial',
  },
  ERRORS: {
    REQUIRED_SYSTEM_SIZE: {
      key: `${projectDataNamespace}.requiredSystemSize`,
      message: 'Please enter the system size to complete this registration.',
    },
  },
};

const PRODUCT = {
  TITLE: {
    key: `${productNamespace}.title`,
    message: 'Panels',
  },
  DESCRIPTION: {
    key: `${productNamespace}.description`,
    message: 'Enter the quantity of panels and the respective model',
  },
  ERRORS: {
    REQUIRED: {
      key: `${productsErrorsNamespace}.requiredError`,
      message: 'You have to add panels in order to complete the registration.',
    },
    REQUIRED_SERIAL_NO: {
      key: `${productsErrorsNamespace}.requiredSerialNo`,
      message:
        'Please enter the missing serial numbers. Entered serial numbers need to be saved for validation.',
    },
    REQUIRED_DISTRIBUTOR: {
      key: `${productsErrorsNamespace}.requiredDistributor`,
      message: 'Inform the distributor of the panels.',
    },
  },
  WARNINGS: {
    REQUIRED_SERIAL_NO: {
      key: `${productsWarningsNamespace}.requiredSerialNo`,
      message:
        '* In order to receive extended warranty for projects with a system size greate than 25 KWh it is required to enter the serial numbers of all panels.',
    },
  },
  QUANTITY: {
    key: `${productNamespace}.quantity`,
    message: 'Quantity',
  },
  NAME: {
    key: `${productNamespace}.name`,
    message: 'Model name',
  },
  ORDER_NO: {
    key: `${productNamespace}.orderNo`,
    message: 'Order number',
  },
  DELIVERY_NOTE_NO: {
    key: `${productNamespace}.deliveryNoteNo`,
    message: 'Delivery note number',
  },
  DELIVERY_NO: {
    key: `${productNamespace}.deliveryNo`,
    message: 'Delivery number',
  },
  MODEL: {
    key: `${productNamespace}.model`,
    message: 'Model of panel',
  },
  PALLET_NO: {
    key: `${productNamespace}.palletNo`,
    message: 'Pallet number',
  },
  SERIAL_NUMBER: {
    key: `${productNamespace}.serialNumber`,
    message: 'Serial number',
  },
  ADD_PRODUCTS_TITLE: {
    key: `${productNamespace}.addProductsBy`,
    message: 'Add panels searching by',
  },
  KNOW_PANELS_TITLE: {
    key: `${productNamespace}.validPanelsTitle`,
    message: 'Verified serial numbers',
  },
  UNKNOW_PANELS_TITLE: {
    key: `${productNamespace}.unknowPanelsTitle`,
    message: 'Unknown serial numbers',
  },
  HELP: {
    key: `${productNamespace}.help`,
    message: `<article>You can find the panels of your power plant by searching for one of the following criterias:
    <br /><br />
    <p>1. Order number: If you have the number of your order, you can load the complete order by entering it in the respective field;</p>
    <p>2. Delivery note: You can load a delivery by entering the delivery note;</p>
    <p>3. Pallet: All panels of a given pallet can be loaded by entering the pallet number;</p>
    <p>4. Serialnumber: A single panel can be loaded by entering the given serial number. You can repeat this search as many times as necessary;</p>
    </article>`,
  },
  UPLOAD_PANEL_SPREADSHEET: {
    key: `${productNamespace}.uploadPanelSpreadsheet`,
    message: 'Upload panels using spreadsheet template',
  },
  ADDED_PANELS_INFO: {
    key: `${productNamespace}.addedPanelsInfo`,
    message:
      '{count, plural, one {# panel} other {# panels}} was added to your registration.',
  },
  ADDED_PANELS_INFO_IE11: {
    key: `${productNamespace}.addedPanelsInfo.ie11`,
    message: 'Quantity of panels added to project:',
  },
  SEARCH_FOUND_NO_RESULTS: {
    key: `${productNamespace}.searchFoundNoResults`,
    message: 'No results were found for your query.',
  },
  PANEL_SERIAL: {
    key: `${productNamespace}.panelSerial`,
    message: 'Serial number of panel',
  },
  BUTTONS: {
    SAVE_SERIALS: {
      key: `${productsButtonsNamespace}.saveSerials`,
      message: 'Save serial numbers',
      DUPLICATES_ALERT: {
        key: `${productsButtonsNamespace}.saveSerials.duplicatesAlert`,
        message:
          'You are possibly trying to save an already informed or duplicate serial number',
      },
    },
  },
  SYSTEM_SIZE: {
    key: `${productNamespace}.systemSize`,
    message: 'System size',
  },
  SYSTEM_SIZE_IN_MW: {
    key: `${productNamespace}.systemSizeInMW`,
    message: 'System size in MW',
  },
  INFORMED_BY_USER: {
    key: `${productNamespace}.informedByUser`,
    message: 'Entered by user',
  },
  CALCULATED: {
    key: `${productNamespace}.calculated`,
    message: 'Calculated',
  },
  PANELS: {
    key: `${productNamespace}.panels`,
    message: 'Panels',
  },
  DELETE_SELECTED: {
    key: `${productNamespace}.deleteSelected`,
    message: 'Delete Selected',
  },
  DOWNLOAD_REPORTING: {
    key: `${productNamespace}.downloadReporting`,
    message: 'Download Reporting',
  },
  LEAVE_WITHOUT_SAVING: {
    key: `${productNamespace}.leaveWithoutSaving`,
    message:
      "Are you sure you want to leave without saving the serial numbers? Please click `SAVE SERIALS` before leaving if you don't want to loose your input",
  },
  ALERT: {
    key: `${productNamespace}.productInput.alert`,
    message:
      'In order to choose another model you need to delete the previous selected one first.',
  },
  BUTTON_ALERT: {
    key: `${productNamespace}.productInput.buttonAlert`,
    message:
      'To select a model, please start typing the name and click on the respective model in the list below.',
  },
  RESET_PANELS_ALERT: {
    key: `${productModalNamespace}.resetPanelsAlert`,
    message: 'Are you sure you want to remove all panels from this project?',
  },
  RESET_PANELS_CONFIRMATION: {
    key: `${productModalNamespace}.resetPanelsConfirmation`,
    message:
      'If you want to remove all panels from this project please click CONFIRM.',
  },
  DELETE_SELECTION_PANELS_ALERT: {
    key: `${productModalNamespace}.deleteSelectionAlert`,
    message:
      'Are you sure you want to remove the panels that you selected from this project?',
  },
  DELETE_SELECTION_PANELS_CONFIRMATION: {
    key: `${productModalNamespace}.deleteSelectionConfirmation`,
    message: 'If you want to continue please click CONFIRM.',
  },
};

const PROJECT_FILES = {
  TITLE: {
    key: `${projectFilesNamespace}.title`,
    message: 'Files',
  },
  DESCRIPTION: {
    key: `${projectFilesNamespace}.description`,
    message: 'You can attach pictures, drawings or documents to the project',
  },
  HELP: {
    key: `${projectFilesNamespace}.help`,
    message:
      'Here you can upload files related to this project. This upload is optional. The warranty certificate will automatically be stored here after your project is successfully registered.',
  },
};

const END_CONSUMER = {
  TITLE: {
    key: `${endCustomerNamespace}.title`,
    message: 'End customer',
  },
  DESCRIPTION: {
    key: `${endCustomerNamespace}.description`,
    message: 'Please enter the data of the end customer',
  },
  HELP: {
    key: `${endCustomerNamespace}.help`,
    message: `Here you can load registered customers by typing the first or last name in the search field. If you can't find the given customer
    , you may need to create a new entry by selecting the New Customer option.`,
  },
  ERRORS: {
    REQUIRED: {
      key: `${endCustomerNamespace}.requiredError`,
      message: 'A customer is required to complete the registration.',
    },
  },
};

const INSTALLER = {
  TITLE: {
    key: `${installerNamespace}.title`,
    message: 'Installer',
  },
  DESCRIPTION: {
    key: `${installerNamespace}.description`,
    message: 'Please enter the installer company',
  },
  HELP: {
    key: `${installerNamespace}.help`,
    message: `Here you can choose the installer of this project. If you wish to change, just click the pen button,
    and you will be able to search or even create a new installer. If your own company is the installer,
    click the respective button.`,
  },
  ERRORS: {
    REQUIRED: {
      key: `${installerNamespace}.requiredError`,
      message: 'Installer is required to complete the registration.',
    },
  },
  BUTTONS: {
    NEW: {
      key: `${installerButtonsNamespace}.newInstaller`,
      message: 'New Installer',
    },
    USE_MY_ORGANIZATION: {
      key: `${installerButtonsNamespace}.useMyOrganization`,
      message: 'Use My Organization',
    },
  },
  CRUD_MODAL: {
    NEW: {
      TITLE: {
        key: `${installerCrudNamespace}.title`,
        message: 'New installer registration',
      },
      DESCRIPTION: {
        key: `${installerCrudNamespace}.description`,
        message: 'Here you can register a new intaller company.',
      },
    },
  },
  ADVANCED_SEARCH: {
    TITLE: {
      key: `${installerAdvancedSearchNamespace}.modalTitle`,
      message: 'Installer search',
    },
    DESCRIPTION: {
      key: `${installerAdvancedSearchNamespace}.modalDescription`,
      message: 'Here you can search for already registered installers',
    },
    LABELS: {
      INSTALLERS: {
        key: `${installerSearchLabelsNamespace}.installers`,
        message: 'Installers',
      },
      INSTALLERS_NOT_FOUND: {
        key: `${installerSearchLabelsNamespace}.notFound`,
        message: 'No installers found',
      },
    },
  },
};

const INSTALLATION = {
  TITLE: {
    key: `${installationNamespace}.title`,
    message: 'Installation',
  },
  DESCRIPTION: {
    key: `${installationNamespace}.description`,
    message: 'Please specify the address of the installation site',
  },
  DATE_DESCRIPTION: {
    key: `${installationNamespace}.dateDescription`,
    message: 'Please specify the relevant dates of the installation',
  },
  HELP: {
    key: `${installationNamespace}.help`,
    message:
      'Here you can fill in the installation site of this project. Optionally, you can use the consumer address, by clicking the Use Consumer Address button.',
  },
  ERRORS: {
    REQUIRED: {
      key: `${installationNamespace}.requiredError`,
      message:
        'The installation site and installation date is required to complete the registration.',
    },
  },
  USE_END_CUSTOMER_ADDRESS: {
    key: `${installationNamespace}.useEndCustomerAddress`,
    message: 'Use end customer address',
  },
  NEW_ADDRESS: {
    key: `${installationNamespace}.newAddress`,
    message: 'New Address',
  },
  PLACEHOLDER: {
    FINAL_INSTALLATION_DATE: {
      key: `${installationNamespace}.placeholder.finalInstalllationDate`,
      message: 'Date of final installation',
    },
    FINAL_INVOICE_DATE: {
      key: `${installationNamespace}.placeholder.finalInvoiceDate`,
      message: 'Date of final invoice',
    },
  },
  MODAL: {
    EDIT_TITLE: {
      key: `${installationNamespace}.modal.editTitle`,
      message: 'Edit Installation Site',
    },
    NEW_TITLE: {
      key: `${installationNamespace}.modal.newTitle`,
      message: 'New Installation Site',
    },
    EDIT_DESCRIPTION: {
      key: `${installationNamespace}.modal.editDescription`,
      message: 'Here you can edit installation site of this project.',
    },
    NEW_DESCRIPTION: {
      key: `${installationNamespace}.modal.newDescription`,
      message: 'Here you can create the installation site of this project.',
    },
    DUPLICATED_PROJECT_TITLE: {
      key: `${installationNamespace}.modal.duplicatedProjectTitle`,
      message: 'Duplicate Registration Alert',
    },
    DUPLICATED_PROJECT_DESCRIPTION: {
      key: `${installationNamespace}.modal.duplicatedProjectDescription`,
      message:
        'Please review your recent registrations and verify the information provided.',
    },
  },
};

const COMPLETE_REGISTRATION = {
  TITLE: {
    key: `${completeRegistrationNamespace}.title`,
    message: 'Registration',
  },
  DOWNLOAD_CERTIFICATE: {
    key: `${completeRegistrationNamespace}.downloadCertificate`,
    message: 'Download Certificate',
  },
  SEND_DOCUMENT_TO_CONSUMER: {
    key: `${completeRegistrationNamespace}.sendDocumentToConsumer`,
    message: 'Send Document to Consumer',
  },
  REGISTER: {
    key: `${completeRegistrationNamespace}.justRegister`,
    message: 'Okay, if you entered all required fields, now just register.',
  },
  HELP: {
    key: `${completeRegistrationNamespace}.help`,
    message: `Here you can view the added values your projects got, and errors associated to it.
    If you havent registered this project yet, you can do it by cicking the Register button below.`,
  },
  COMPLETE: {
    key: `${completeRegistrationNamespace}.complete`,
    message: 'Complete Registration',
  },
  LANGUAGE_SELECT: {
    key: `${completeRegistrationNamespace}.languageSelect`,
    message: 'Choose a Language',
  },
  COMPLETED: {
    key: `${completeRegistrationNamespace}.completed`,
    message: 'Completed Registration',
  },
  INVALID: {
    key: `${completeRegistrationNamespace}.invalid`,
    message: 'Invalid Registration',
  },
  WARRANTY: {
    VALIDATION_ERRORS_LABEL: {
      key: `${registrationWarrantyNamespace}.validationErrors`,
      message: 'Warranty Validation Errors',
    },
    NO_CAMPAIGN: {
      key: `${registrationWarrantyNamespace}.noCampaign`,
      message: 'No Campaign',
    },
    NO_CAMPAIGN_LABEL: {
      key: `${registrationWarrantyNamespace}.noCampaignLabel`,
      message:
        'The products that you informed are not eligible for any campaign.',
    },
    WARRANTY_CERTIFICATE_ALERT_MODAL: {
      TITLE: {
        key: `${registrationWarrantyNamespace}.certificateAlertModal.title`,
        message: 'Warranty certificate alert',
      },
      CONTENT: {
        key: `${registrationWarrantyNamespace}.certificateAlertModal.content`,
        message:
          'The generation of warranty certificates is currently not possible. You can complete your registration normally and will receive the certificate on 29th of January. Thank you for your understanding.',
      },
    },
  },
};

const I18N = {
  PROJECT_DATA,
  END_CONSUMER,
  PRODUCT,
  INSTALLER,
  PROJECT_FILES,
  INSTALLATION,
  COMPLETE_REGISTRATION,
};

export default I18N;
