import { EMPTY_ARRAY, EMPTY_OBJECT } from '@mmw/constants-utils';
import { BaseAsyncState } from '@redux-async-module/reducer-utils';

export enum LastSearchAction {
  SEARCH = 'SEARCH',
  PAGINATE = 'PAGINATE',
}

export class SearchByUuidState<RequestFields> extends BaseAsyncState {
  requestFields: Partial<RequestFields> =
    EMPTY_OBJECT as Partial<RequestFields>;

  visibleIds: number[] = EMPTY_ARRAY;

  lastSearchAction: LastSearchAction = LastSearchAction.SEARCH;

  infinityIds: number[] = EMPTY_ARRAY;

  total = 0;

  limit = 10;

  currentPage = 1;
}
