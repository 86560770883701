export default {
  step: {
    style: ({ theme }) => `
      display: flex;      
      ${theme.media.desktop`
        padding: 20px 20px 20px 0;
      `}
      ${theme.media.upToTablet`
        margin: 20px;
      `}
    `,
  },
  stepper: {
    style: ({ theme }) => `
      display: flex;      
      ${theme.media.desktop`
        padding: 0 20px;
      `}
    `,
  },
  wizard: {
    style: () => `
      display: flex;
      flex-direction: column;
      padding-bottom: 14px;
    `,
  },
};
