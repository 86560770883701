const NEXT_BUTTON_HEIGHT = '45PX';
const BACK_BUTTON_HEIGHT = '35PX';

const theme = {
  buttons: {
    next: {
      style: ({ theme }) => `
        background-color: ${theme.colors.pallet.success};
        width: 200px;
        height: ${NEXT_BUTTON_HEIGHT};
      `,
      modifiers: undefined,
      divider: {
        style: () => `
          height: ${NEXT_BUTTON_HEIGHT};
        `,
        modifiers: undefined,
      },
    },
    back: {
      style: ({ theme }) => `
        background-color: ${theme.colors.pallet.primary};
        width: 200px;
        height: ${BACK_BUTTON_HEIGHT};
      `,
      modifiers: undefined,
      divider: {
        style: ({ theme }) => `
          height: ${BACK_BUTTON_HEIGHT};
        `,
        modifiers: undefined,
      },
    },
  },
};

export default theme;
