import { I18nShape } from '@mmw/constants-i18n';
import { DEFAULT_SELECT_PLACEHOLDER } from '@mmw/constants-i18n-common';
import { Column, Row } from '@mmw/hybrid-ui-structure-containers';
import { ContainersProps } from '@mmw/hybrid-ui-structure-containers/types';
import useIsVisible from '@mmw/ui-hooks-use-is-visible';
import { Theme } from '@mmw/ui-theme/types';
import { use } from '@mmw/ui-theme/utils';
import Text from '@mmw/ui-web-elements-typography';
import isObject from 'lodash/isObject';
import map from 'lodash/map';
import noop from 'lodash/noop';
import React, { useCallback, useRef } from 'react';
import styled, { useTheme } from 'styled-components';
import { F, U } from 'ts-toolbelt';

const RELATIVE_STYLE = { position: 'relative' };

interface ListItemsContainerProps extends Partial<ContainersProps> {
  theme?: Theme;
  isVisible: boolean;
}

const ListItemsContainer: React.FC<ListItemsContainerProps> = styled(Column)(
  props => use(props, 'dropdownList.itemsContainer'),
);

const SelectedItemContainer = styled(Row)(props =>
  use(props, 'dropdownList.field'),
);

interface ListItemProps extends Partial<ContainersProps> {
  theme?: Theme;
  disabled?: boolean;
}

const ListItem: React.FC<ListItemProps> = styled(Row)(props =>
  use(props, 'dropdownList.item'),
);

const ListContainer = styled(Column)`
  overflow-y: scroll;
  max-height: 250px;
`;

const FooterContainer = styled(Row)(
  ({ theme }) => `
  background-color: ${theme.dropdownList.itemsContainer.backgroundColor};
  padding: 20px 10px;
`,
);

interface ArrowProps extends Partial<React.HTMLAttributes<HTMLImageElement>> {
  src?: string | undefined;
  isOpened: boolean;
}

const Arrow: React.FC<ArrowProps> = styled.img(
  ({ isOpened }: ArrowProps) => `
  cursor: pointer;
  pointer-events: inherit;  
  right: 0;
  box-sizing: content-box;
  ${isOpened && 'transform: rotate(180deg)'}
`,
);

export interface DropdownListProps<ItemType> {
  itemList: any[];
  onChange: F.Function<[any], void>;
  value: any;
  renderItem: F.Function<
    [ItemType, U.Nullable<number>?, string?],
    React.ReactNode
  >;
  footerContent: React.ReactNode;
  placeholder?: I18nShape | string;
  children?: React.ReactNode;
  disabled?: boolean;
}

const renderPlaceholer = (placeholder: I18nShape | string) => {
  if (isObject(placeholder)) {
    return <Text i18n={placeholder} modifiers="basic" />;
  }
  return <Text modifiers="basicLight">{placeholder}</Text>;
};

const DropdownList = <ItemType,>({
  itemList,
  onChange,
  value,
  renderItem,
  footerContent,
  placeholder,
  children,
  disabled,
}: DropdownListProps<ItemType>): React.ReactElement<
  DropdownListProps<ItemType>
> => {
  const { isVisible, toggle, hide } = useIsVisible(false);
  const containerRef = useRef(null);
  const onSelectItem = useCallback(
    v => {
      onChange(v);
      hide();
    },
    [hide, onChange],
  );
  const theme = useTheme() as Theme;

  const closeDropdownList = useCallback(
    e => {
      if (
        containerRef.current &&
        isVisible &&
        !containerRef.current.contains(e.target)
      ) {
        hide();
      }
    },
    [hide, isVisible],
  );

  document.addEventListener('mousedown', closeDropdownList);

  if (!isVisible) {
    document.removeEventListener('mousedown', noop);
  }

  return (
    <Column style={RELATIVE_STYLE} innerRef={containerRef}>
      <SelectedItemContainer
        align="center"
        justify="space-between"
        onClick={toggle}
      >
        {value ? renderItem(value, null) : renderPlaceholer(placeholder)}
        <Arrow src={theme?.assets?.DROP_ICON as string} isOpened={isVisible} />
      </SelectedItemContainer>
      <ListItemsContainer isVisible={isVisible}>
        <ListContainer>
          {map(itemList, (item, index) => (
            <ListItem
              key={item}
              align="center"
              disabled={disabled}
              onClick={() => {
                if (disabled) return null;
                return onSelectItem(item);
              }}
            >
              {renderItem(item, index)}
            </ListItem>
          ))}
        </ListContainer>
        <FooterContainer align="center" justify="flex-end" autoWidth>
          {footerContent}
        </FooterContainer>
      </ListItemsContainer>
      {children}
    </Column>
  );
};

DropdownList.defaultProps = {
  placeholder: DEFAULT_SELECT_PLACEHOLDER,
};

export default DropdownList;
