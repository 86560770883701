import '@mmw/web-utils-compatibility-polyfills';
import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import { unregister } from './serviceWorkers';

unregister();

const container = document.getElementById('root');

const root = createRoot(container!);

if (root !== null) {
  root.render(<App />);
}
