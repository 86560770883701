import align from './align';
import color from './color';
import font from './font';
import size from './size';
import typography from './typography';
import { add, apply, has, mount } from './utils';

const modifiers = {
  align,
  font,
  size,
  color,
  typography,
  apply,
  mount,
  add,
  has,
};

export {
  add,
  align,
  apply,
  color,
  modifiers as default,
  font,
  has,
  mount,
  size,
  typography,
};
