import {
  IssueActionRequired,
  IssueDataJSON,
  IssueResolutionMode,
  IssueStatus,
  IssueType,
} from '@issue/interfaces';
import { U } from '@utils/ts';

export enum IssueLocation {
  LOCAL,
  API,
}
export interface ContextIssueType {
  location: IssueLocation;
  id: number;
  summary: string;
  type: IssueType;
  status: IssueStatus;
  actionRequired: IssueActionRequired;
  data: IssueDataJSON;
  resolutionMode: IssueResolutionMode;
  dueDate: U.Nullable<Date>;
  created: U.Nullable<Date>;
  assigneeID: U.Nullable<number>;
}
