import { executeRecaptchaAction } from '@mmw/ui-libraries-recaptcha';
import { EMPTY_OBJECT } from '@shared-utils/object';
import { useCallback, useState } from 'react';

import { ReCaptchaV3ContextValue } from './Context';
import { Config } from './types';

export function useReCaptchaProviderValue(
  config: Config,
): ReCaptchaV3ContextValue {
  const { applicationId, siteKey } = config;
  const [loading, setLoading] = useState(EMPTY_OBJECT);
  const getToken = useCallback(
    async (action: string) => {
      setLoading(prevState => ({ ...prevState, [action]: true }));
      const token = await executeRecaptchaAction(action, {
        applicationId,
        publickey: siteKey,
      });
      setLoading(prevState => ({ ...prevState, [action]: false }));
      return token;
    },
    [applicationId, siteKey],
  );
  const isGettingToken = useCallback(
    (action: string) => loading[action],
    [loading],
  );
  return { getToken, isGettingToken };
}
