export enum CampaignType {
  CONSUMERCASHBACK = 'CONSUMERCASHBACK',
  TRADERCASHBACK = 'TRADERCASHBACK',
  STOCKTAKING = 'STOCKTAKING',
  WARRANTY = 'WARRANTY',
  GIFT = 'GIFT',
  DEFAULT = 'DEFAULT',
  SURVEY = 'SURVEY',
  MAILING = 'MAILING',
  CERTIFICATION = 'CERTIFICATION',
  SELLOUT = 'SELLOUT',
  COUPONCASHBACK = 'COUPONCASHBACK',
  SELLTHROUGH = 'SELLTHROUGH',
  RETURNCODE = 'RETURNCODE',
  CASHRETURNCODE = 'CASHRETURNCODE',
  CONTRACT = 'CONTRACT',
  THIRDPARTY = 'THIRDPARTY',
  SNYCSGIFT = 'SNYCSGIFT',
  DOCUMENTUPLOAD = 'DOCUMENTUPLOAD',
  PARENT = 'PARENT',
  SERVICECONTRACT = 'SERVICECONTRACT',
  CARD = 'CARD',
  THIRDPARTY_MMW = 'THIRDPARTY_MMW',
  SONY_EXTENDED_WARRANTY = 'SONY_EXTENDED_WARRANTY',
  HIDDEN = 'HIDDEN',
  DEMODEVICE = 'DEMODEVICE',
  RETURNCODEALWAYS = 'RETURNCODEALWAYS',
  DRAW = 'DRAW',
  CREDITCARD = 'CREDITCARD',
  SCORE = 'SCORE',
  RETURNCODE_BY_PRODUCT = 'RETURNCODE_BY_PRODUCT',
  AUDIT = 'AUDIT',
}
