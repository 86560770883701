import { mergeStyle } from '@mmw/ui-theme/utils';
import frameworkStyle from '@mmw/ui-web-materials-form-field/theme';

import errorMsgStyle from './errorMsgStyle';
import labelContainerStyle from './labelContainerStyle';
import labelStyle from './labelStyle';
import requiredStyle from './requiredStyle';
import formFieldStyle from './style';

const modifiers = {
  error: ({ theme }) => `
    color: ${theme.colors.pallet.error};
  `,
};

const samsungFormStyle = {
  style: formFieldStyle,
  labelContainer: {
    style: labelContainerStyle,
    label: {
      style: labelStyle,
      modifiers,
    },
  },
  required: {
    style: requiredStyle,
  },
  errorMsg: {
    style: errorMsgStyle,
    modifiers,
  },
  modifiers,
};

export default mergeStyle(frameworkStyle, samsungFormStyle);
