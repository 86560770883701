import { isDevelopment } from '@mmw/environment';
import { css } from '@ui-system/css';
import UI from '@ui-system/ui';
import { isArray, isObject } from 'lodash';
import React, { useMemo } from 'react';
// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import ReactJson from 'react-json-view';

import { useDevMode } from './dev-mode';

const STYLE = css`
  position: absolute;
  top: 10vh;
  left: 10vw;
  width: 80vw;
  height: 80vh;
  z-index: 99999;
  overflow: scroll;
`;

export const JsonView = () => {
  const { json } = useDevMode();
  const value = useMemo(() => {
    if (isObject(json) || isArray(json)) return json;
    if (json) return { value: json };
    return null;
  }, [json]);

  if (!isDevelopment()) return null;
  return (
    <UI.Modal visible={!!value} hideCloseButton>
      <UI.Container align="center">
        <UI.Icon
          size={45}
          name="close"
          onClick={() => useDevMode.setState({ json: null })}
        />
        {/*  @ts-ignore */}
        <ReactJson src={value} theme="monokai" style={STYLE} />
      </UI.Container>
    </UI.Modal>
  );
};
