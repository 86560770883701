import { flatten, map, uniq } from 'lodash';
import includes from 'lodash/includes';
import { U } from 'ts-toolbelt';

import AMERICA_COUNTRIES from './regions/amer.json';
import APAC_COUNTRIES from './regions/apac.json';
import EMEA_COUNTRIES from './regions/emea.json';

export { default as AMERICA_COUNTRIES } from './regions/amer.json';
export { default as APAC_COUNTRIES } from './regions/apac.json';
export { default as EMEA_COUNTRIES } from './regions/emea.json';

export const AMERICA_COUNTRY_CODES = map(AMERICA_COUNTRIES, 'id');
export const APAC_COUNTRY_CODES = map(APAC_COUNTRIES, 'id');
export const EMEA_COUNTRY_CODES = map(EMEA_COUNTRIES, 'id');

export enum WorldRegion {
  EMEA = 'EMEA',
  APAC = 'APAC',
  AMER = 'AMER',
}

export function getCountryCodesByRegion(
  region?: WorldRegion | string,
): string[] {
  switch (region) {
    case WorldRegion.EMEA:
      return EMEA_COUNTRY_CODES;
    case WorldRegion.APAC:
      return APAC_COUNTRY_CODES;
    case WorldRegion.AMER:
      return AMERICA_COUNTRY_CODES;
    default:
      return [];
  }
}

export function getCountryCodesByRegions(...regions: (WorldRegion | string)[]) {
  return uniq(flatten(map(regions, getCountryCodesByRegion)));
}

export function getRegionByCountry(
  country: U.Nullable<string>,
): string[] | null {
  if (!country) return null;
  if (includes(AMERICA_COUNTRY_CODES, country)) {
    return AMERICA_COUNTRY_CODES;
  }
  if (includes(APAC_COUNTRY_CODES, country)) {
    return APAC_COUNTRY_CODES;
  }
  return EMEA_COUNTRY_CODES;
}

export function getRegionNameByCountry(
  country: U.Nullable<string>,
): WorldRegion | null {
  if (!country) return null;
  if (includes(AMERICA_COUNTRY_CODES, country)) {
    return WorldRegion.AMER;
  }
  if (includes(APAC_COUNTRY_CODES, country)) {
    return WorldRegion.APAC;
  }
  return WorldRegion.EMEA;
}

export function useWorldRegionByCountry(
  country: U.Nullable<string>,
): WorldRegion | null {
  return getRegionNameByCountry(country);
}
export function useIsAPAC(country: U.Nullable<string>): boolean {
  return getRegionNameByCountry(country) === WorldRegion.APAC;
}

export function useIsEMEA(country: U.Nullable<string>): boolean {
  return getRegionNameByCountry(country) === WorldRegion.EMEA;
}

export function useIsAMER(country: U.Nullable<string>): boolean {
  return getRegionNameByCountry(country) === WorldRegion.AMER;
}
