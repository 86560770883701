import config from '@mmw/config';
import contextualConfig from '@mmw/contextual-config';
import log from '@mmw/logging-logger';
import { EMERG, ERROR, FATAL } from '@mmw/logging-logger/constants';
import { Message, subject } from '@mmw/logging-logger/logger';
import { strigifyParams } from '@mmw/logging-logger/utils';
import { find, map } from 'lodash';
import { empty, from } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { bufferTime, catchError, filter, switchMap } from 'rxjs/operators';
import StackTrace from 'stacktrace-js';

import { GetLabelsFn } from './types';

const TIME_TO_WAIT = 10000;

const logger = log.extend('common').extend('api-logger');

const EMPTY_OBJECT = {};

function formatText(msg: Message) {
  return msg.message;
}

function getLogParams(msg: Message) {
  if (msg.messageArgs) {
    return strigifyParams(msg.messageArgs);
  }
  return null;
}

async function getStacktrace(msg: Message) {
  try {
    const args = msg.messageArgs;
    if (!args) {
      return null;
    }
    const err = find(args, arg => arg instanceof Error);
    if (!err) {
      return null;
    }
    const stackframes = await StackTrace.fromError(err);
    if (!stackframes) {
      return null;
    }
    return stackframes.map(sf => sf.toString()).join('\n');
  } catch (error) {
    // eslint-disable-next-line no-console
    // console.error(error); XXX: Crashing Android?
  }
  return null;
}

async function mapMessageToLog(msg: Message) {
  const stacktrace = await getStacktrace(msg);
  return {
    date: msg.date,
    text: formatText(msg),
    level: msg.level,
    labels: {
      applicationModule: msg.group,
      messageParams: getLogParams(msg),
      stacktrace,
    },
  };
}

function logError(error) {
  // eslint-disable-next-line no-console
  // console.error('Api error', error); XXX: Crashing Android?
  const res = error ? error.response : error;
  logger.error('Error when trying to log messages', res);
  return empty();
}

function sendLog(msgs: Array<Message>, getLabels: GetLabelsFn) {
  const prepareLog = async (): Promise<Object> => {
    const labels = getLabels ? getLabels() : EMPTY_OBJECT;
    const logs = await Promise.all(map(msgs, mapMessageToLog));
    const applicationId =
      contextualConfig.logs != null
        ? contextualConfig.logs.applicationId
        : contextualConfig.application.applicationId;
    return {
      applicationId,
      labels,
      logs,
    };
  };
  const WRITE_LOGS_URL = `${config.api.v2.baseURI}/v1/log`;
  logger.debug('Sending logs');
  return from(prepareLog()).pipe(
    switchMap(json =>
      ajax.post(`${WRITE_LOGS_URL}`, json, {
        'Content-Type': 'application/json',
      }),
    ),
  );
}

function logResult() {
  logger.debug('Sent logs with success');
}

function logsFromOtherComponents(msg: Message) {
  return msg.group.indexOf('common:api-logger') === -1;
}

function hasLogs(msgs: Array<Message>) {
  return msgs && msgs.length > 0;
}

function immediateLog(msg: Message) {
  return (
    msg && (msg.level === ERROR || msg.level === FATAL || msg.level === EMERG)
  );
}

function notImmediateLog(msg: Message) {
  return msg && !immediateLog(msg);
}

export default (getLabels: GetLabelsFn) => {
  logger.debug('Registering api logger');
  subject
    .pipe(
      filter(Boolean),
      filter(logsFromOtherComponents),
      filter(notImmediateLog),
      bufferTime(TIME_TO_WAIT),
      filter(hasLogs),
      switchMap(msgs => sendLog(msgs, getLabels).pipe(catchError(logError))),
      catchError(logError),
    )
    .subscribe(logResult);

  subject
    .pipe(
      filter(Boolean),
      filter(logsFromOtherComponents),
      filter(immediateLog),
      switchMap(msg => sendLog([msg], getLabels).pipe(catchError(logError))),
      catchError(logError),
    )
    .subscribe(logResult);
};
