import defaultApiV2, { ApiResponse } from '@mmw/api-v2';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import autoBind from 'auto-bind';

import { GetProductGroupsByCampaignPath } from './apiPaths';
import logger from './log';
import { ProductTagJSON } from './types';

type Api = typeof defaultApiV2;

type TraderProductGroupServiceOptions = {
  apiv2?: Api;
  authenticationService: AuthenticationService;
};

class TraderProductGroupService {
  api: Api;

  authenticationService: AuthenticationService;

  constructor({
    apiv2,
    authenticationService,
  }: TraderProductGroupServiceOptions) {
    this.api = apiv2 || defaultApiV2;
    this.authenticationService = authenticationService;
    autoBind(this);
  }

  async getProductGroupsByCampaign(
    campaignId: number,
    language: string,
  ): Promise<ProductTagJSON[]> {
    logger.debug(
      'Trying to get available product groups by campaign id=%d',
      campaignId,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ProductTagJSON[]> = await this.api.get(
        GetProductGroupsByCampaignPath(campaignId, language),
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully got available product groups by campaign');
      return data;
    } catch (error) {
      logger.error(
        'Error when getting available product groups, error=%O',
        error,
      );
      throw error;
    }
  }
}

export default TraderProductGroupService;
