import baseTheme, { ThemeType } from '@mmw/web-theme';

import content from './content';

export default {
  style: ({
    theme,
    disableClick,
    roundWidgetBorder,
  }: ThemeType & { disableClick?: boolean }) => `
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.pallet.light};
    height: ${theme.campaignCard.variables.height}px;
    width: ${theme.campaignCard.variables.width}px;
    box-sizing: border-box;
    ${
      disableClick
        ? ''
        : `
      : hover {
        background-color: ${theme.colors.pallet.primary};
        span {
          fill: ${theme.colors.pallet.light};
          color: ${theme.colors.pallet.light};
        }
        cursor: pointer;
        footer {
          text-decoration: underline;
        }
      }
    `
    }
    ${
      roundWidgetBorder
        ? `
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    `
        : ''
    };
  `,
  image: {
    style: () => `
    max-height: 240px;
    `,
  },
  content: {
    style: () => `
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 10px;
    `,
    title: content.title,
  },
  oneSnapWidget: {
    style: () => `
      position: relative;    
      background-color: ${baseTheme.colors.pallet.primary};
      padding: 10px;
      height: 60px;
      border-bottom-right-radius: 30px;
      border-bottom-left-radius: 30px;
      :hover {
        background-color: ${baseTheme.colors.primary.lighten(0.3)};
      }     
  `,
  },
  variables: {
    height: 335,
    width: 295,
    contentPadding: '10px',
  },
};
