import { isStaging } from '@mmw/environment';

// RETAIL
export const RETAIL_PORTAL = '/retail-portal';
export const RETAIL_CLIENT = '/retail-client';
export const RETAIL_CLIENT_AEG = '/retail-client-aeg';
export const RETAIL_CLIENT_KWS = '/kws-client';
export const RETAIL_CLIENT_ORALB = '/retail-client-oralb';
export const RETAIL_CLIENT_JURA = '/retail-client-jura';
export const RETAIL_CLIENT_ROMMELSBACHER = '/retail-client-rommelsbacher';
export const RETAIL_CLIENT_MIELE = '/retail-client-miele';
export const RETAIL_CLIENT_SAMSUNG = '/retail-client-samsung';
export const RETAIL_CLIENT_BOSCH = '/retail-client-bosch';
export const RETAIL_CLIENT_BOSCH_AT = '/retail-client-bosch-at';
export const RETAIL_CLIENT_BOSCH_CH = '/retail-client-bosch-ch';
export const RETAIL_CLIENT_BOSCH_LU = '/retail-client-bosch-lu';
export const RETAIL_CLIENT_GROHE = '/retail-client-grohe';
export const RETAIL_CLIENT_GROHE_AT = '/retail-client-grohe-at';
export const RETAIL_CLIENT_DELONGHI = '/retail-client-delonghi';
export const RETAIL_CLIENT_SHARKNINJA = '/retail-client-sharkninja';
export const RETAIL_CLIENT_MEGABRAND = '/retail-client-megabrand';
export const RETAIL_CLIENT_SIEMENS = '/retail-client-siemens';
export const RETAIL_CLIENT_SIEMENS_AT = '/retail-client-siemens-at';
export const RETAIL_CLIENT_SIEMENS_CH = '/retail-client-siemens-ch';
export const RETAIL_CLIENT_SIEMENS_LU = '/retail-client-siemens-lu';
export const RETAIL_CLIENT_CANON = '/retail-client-canon';
export const RETAIL_CLIENT_CANON_AT = '/retail-client-canon-at';
export const RETAIL_CLIENT_CANON_CH = '/retail-client-canon-ch';
export const RETAIL_CLIENT_SONY = '/retail-client-sony';
export const RETAIL_CLIENT_ZEISS = '/retail-client-zeiss';
export const RETAIL_CLIENT_REC = isStaging() ? '/retail-client-rec' : '';
export const RETAIL_CLIENT_GIGASET = '/retail-client-gigaset';
// "https://proportal.recgroup.com",

// MANUFACTURER CLIENT
export const MANUFACTURER_CLIENT = '/brand';
export const MANUFACTURER_CLIENT_JURA = '/manufacturer-client-jura';
export const MANUFACTURER_CLIENT_ORALB = '/manufacturer-client-oralb';
export const MANUFACTURER_CLIENT_AEG = '/manufacturer-client-aeg';
export const MANUFACTURER_CLIENT_REC = isStaging()
  ? '/manufacturer-client-rec'
  : '';
// "https://proportal-admin.recgroup.com"

// CONSUMER CLIENT
export const CONSUMER_CLIENT = '/consumer';

// STORE LOCATOR
export const MAINNHEIM_STORE_LOCATOR = isStaging()
  ? '/mannheim-store-locator/'
  : '/'; // www.city-betriebe.de
export const AEG_STORE_LOCATOR = '/aeg-store-locator';
export const DELONGHI_STORE_LOCATOR = '/delonghi-store-locator';
export const SHARKNINJA_STORE_LOCATOR = '/sharkninja-store-locator';
export const DENON_STORE_LOCATOR = '/denon-store-locator';
export const REC_STORE_LOCATOR = '/';

// BRAND
export const GROHE_CASHBACK_DE = '';
