import { ChangeAuditStatusView } from '@audit/change-audit-status';
import { ResourceKeyTooltip } from '@mmw/resource-key-tooltip';
import SwitchSelector from '@ui-system/common-switch-selector';
import { styled } from '@ui-system/css';
import {
  ButtonProps,
  ButtonType,
  Components,
  FormType,
  SwitchProps,
  SwitchType,
} from '@ui-system/interfaces';
import { components } from '@ui-system/material';
import { Theme } from '@ui-system/theme';
import includes from 'lodash/includes';

const { Button, Form } = components;
const { Switch, Input } = Form;

const NewButton: ButtonType = styled(Button)`
  background-color: ${({
    theme,
    variant,
    disabled,
  }: { theme: Theme } & ButtonProps) => {
    if (disabled) return theme.colors[theme.mode].gray.A200;
    if (variant === 'contained') return theme.colors[theme.mode].primary.main;
    if (variant === 'text') return 'transparent';
    if (variant === 'outlined') return 'transparent';
    if (variant === 'white') return theme.colors[theme.mode].white;

    return theme.colors[theme.mode].primary.main;
  }};
  ${({ theme, disabled }: { theme: Theme } & ButtonProps) => {
    if (disabled)
      return `
        border-color: ${theme.colors[theme.mode].gray.A900} !important;
        * {
          color: ${theme.colors[theme.mode].gray.A500} !important;
        }
        svg {
            fill: ${theme.colors[theme.mode].gray.A500};
        }
      `;
    return '';
  }}
  &:hover {
    background-color: ${({ theme }: { theme: Theme } & ButtonProps) =>
      theme.colors[theme.mode].gray.A600};
    svg {
      fill: ${({ theme, modifiers }: { theme: Theme } & ButtonProps) => {
        if (includes(modifiers, 'dark')) return 'unset';
        return theme.colors[theme.mode].white;
      }};
    }
  }
` as unknown as ButtonType;

const NewSwitch: SwitchType = styled(Switch)`
  .MuiSwitch-track {
    background-color: ${({
      theme,
      checked,
    }: { theme: Theme } & SwitchProps) => {
      if (checked) return theme.colors[theme.mode].gray.A400;
      return theme.colors[theme.mode].gray.A300;
    }};
  }
  span {
    span {
      color: ${({ theme, checked }: { theme: Theme } & SwitchProps) => {
        if (checked) return theme.colors[theme.mode].gray.A500;
        return theme.colors[theme.mode].gray.A200;
      }};
    }
  }
` as unknown as SwitchType;

NewSwitch.defaultProps = {
  ...components.Form.Switch.defaultProps,
  color: 'default',
};

NewButton.defaultProps = {
  ...components.Button.defaultProps,
  iconSize: 24,
  variant: 'contained',
  iconColor: 'white',
  textModifiers: 'none',
};

Input.defaultProps = {
  ...components.Form.Input.defaultProps,
  labelColor: 'secondary',
};

ChangeAuditStatusView.defaultProps = {
  ...ChangeAuditStatusView.defaultProps,
  iconColor: 'white',
};

components.Typography.CustomWrapper = ResourceKeyTooltip;

export const CUSTOM_COMPONENTS: Components = {
  ...components,
  Button: NewButton,
  SwitchSelector,
  Form: {
    ...components.Form,
    Switch: NewSwitch,
  } as unknown as FormType,
};
