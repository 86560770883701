export default ({ theme }) => `
  font-family: ${theme.fonts.fontFamily};
  display: flex;
  flex: 1;
  justify-content: center;
  &:first-child {
    justify-content: flex-start;
    margin: 0 10px 0 0;
  }
  &:last-child {
    justify-content: flex-end;
    margin: 0 0 0 10px;
  }
`;
