import useHtmlOrTextComponent from '@mmw/clients-ui-hooks-use-html-or-text-component';
import { ResourceKeyTooltip } from '@mmw/resource-key-tooltip';
import { use } from '@mmw/ui-theme/utils';
import { P } from '@mmw/ui-web-elements-typography';
import React from 'react';
import styled from 'styled-components';

import { CustomContentProp, I18nProp } from './types';

export const TooltipText = styled(P)(props => use(props, 'helpTooltip.text'));

export const CustomContent = ({
  content,
  i18n,
}: CustomContentProp & I18nProp) => {
  const parsedContent = useHtmlOrTextComponent(content, TooltipText);
  return (
    <ResourceKeyTooltip i18n={i18n || content}>
      {parsedContent}
    </ResourceKeyTooltip>
  );
};
