import { UserLoginConfiguration } from '@mmw/services-auth-api-authentication/types';
import { F, U } from 'ts-toolbelt';

export const GET_LOGIN_CONFIG_BY_EMAIL_OR_USERID_START =
  'GET_LOGIN_CONFIG_BY_EMAIL_OR_USERID_START';
export const GET_LOGIN_CONFIG_BY_EMAIL_OR_USERID_SUCCESS =
  'GET_LOGIN_CONFIG_BY_EMAIL_OR_USERID_SUCCESS';
export const GET_LOGIN_CONFIG_BY_EMAIL_OR_USERID_ERROR =
  'GET_LOGIN_CONFIG_BY_EMAIL_OR_USERID_ERROR';

export type GetLoginConfigByEmailOrUseridStartAction = {
  type: 'GET_LOGIN_CONFIG_BY_EMAIL_OR_USERID_START';
  payload: {
    emailOrUserid: string;
    onSuccess?: F.Function<[UserLoginConfiguration]>;
  };
};

export type GetLoginConfigByEmailOrUseridSuccessAction = {
  type: 'GET_LOGIN_CONFIG_BY_EMAIL_OR_USERID_SUCCESS';
  payload: {
    config: UserLoginConfiguration;
  };
};

export type GetLoginConfigByEmailOrUseridErrorAction = {
  type: 'GET_LOGIN_CONFIG_BY_EMAIL_OR_USERID_ERROR';
  payload: {
    error: Error;
  };
};

export type State = {
  loading: boolean;
  foundUserConfig: U.Nullable<UserLoginConfiguration>;
  error: U.Nullable<Error>;
};

export const NAMESPACE = 'authenticationFindUser';

export type RootState = {
  [NAMESPACE]: State;
};

export type Action =
  | GetLoginConfigByEmailOrUseridStartAction
  | GetLoginConfigByEmailOrUseridSuccessAction
  | GetLoginConfigByEmailOrUseridErrorAction;

export type Reducer = (state: State, action: Action) => State;
