import { createActions } from '@redux-async-module/actions-utils';
import { createEpic } from '@redux-async-module/epic-utils';
import { fixEpicArrayToString } from '@redux-async-module/epic-utils/fixEpicToString';
import { createHandlers } from '@redux-async-module/handlers-utils';
import {
  AsyncModule,
  AsyncModuleConfig,
  INITIAL_STATE,
  Selectors,
  State,
} from '@redux-async-module/interfaces';
import {
  createDispatchHook,
  createDispatchHookOnMount,
  createSelectorHook,
} from '@redux-basic-module/hooks-utils';
import {
  Action,
  ErrorPayload,
  GetDynamicModule,
} from '@redux-basic-module/interfaces';
import { createReducer } from '@redux-basic-module/reducer-utils';
import { createSelector } from '@redux-basic-module/selector-utils';
import defaultTo from 'lodash/defaultTo';
import map from 'lodash/map';
import merge from 'lodash/merge';

export function createModule<RootState, StartPayload, SuccessPayload>(
  config: AsyncModuleConfig<RootState, StartPayload, SuccessPayload>,
): AsyncModule<RootState, StartPayload, SuccessPayload> {
  const {
    namespace,
    actionName,
    buildExtraEpics,
    buildExtraHandlers,
    extraEpicConfigs,
    mainEpicConfig,
  } = config;
  // create actions kit start, success, error, etc
  const actions = createActions<StartPayload, SuccessPayload>(
    namespace,
    actionName,
  );

  const selectors: Selectors<StartPayload, SuccessPayload> = {
    isLoading: createSelector<boolean>([namespace, 'isLoading']),
    success: createSelector<boolean>([namespace, 'success']),
    data: createSelector<SuccessPayload>([namespace, 'data']),
    error: createSelector<Error | null>([namespace, 'error']),
    lastStartPayload: createSelector<StartPayload>([
      namespace,
      'lastStartPayload',
    ]),
  };
  // create data hooks
  const useData = createSelectorHook<SuccessPayload | null>(selectors.data);
  const useLastStartPayload = createSelectorHook<StartPayload | null>(
    selectors.lastStartPayload,
  );
  const useIsLoading = createSelectorHook<boolean>(selectors.isLoading);
  const useSuccess = createSelectorHook<boolean>(selectors.success);
  const useError = createSelectorHook<Error | null>(selectors.error);

  // create action hooks
  const useAsyncStart = createDispatchHook<ReturnType<typeof actions.start>>(
    actions.start,
  );

  const useAsyncStartOnMount = createDispatchHookOnMount<StartPayload>(
    actions.start,
  );

  const useDismissError = createDispatchHook<ErrorPayload>(
    actions.dismissError,
  );
  const useCancelRequest = createDispatchHook(actions.cancel);
  const useResetModule = createDispatchHook(actions.reset);

  // create the handlers (handlers are the same as the switch case inside the reducer)
  const handlers = merge(
    createHandlers<
      State<StartPayload, SuccessPayload>,
      StartPayload,
      SuccessPayload
    >(actions, INITIAL_STATE),
    buildExtraHandlers ? buildExtraHandlers(actions, selectors) : {},
  );

  // create the reducer
  const reducer = createReducer<
    State<StartPayload, SuccessPayload>,
    Action<string, StartPayload | SuccessPayload>
  >(namespace, INITIAL_STATE, handlers);

  // create the main epic based on the actions kit
  const mainEpic = createEpic({
    actions,
    ...mainEpicConfig,
  });

  // Merge other epic configs you may pass
  const epics = [
    mainEpic,
    ...map(extraEpicConfigs, createEpic),
    ...fixEpicArrayToString(
      defaultTo(
        buildExtraEpics ? buildExtraEpics(actions, selectors) : undefined,
        [],
      ),
    ),
  ];

  // return the getDynamicModuleFunction ready to use it, just rename it
  const getDynamicModule: GetDynamicModule<RootState> = () => ({
    id: namespace,
    reducerMap: {
      [namespace]: reducer,
    },
    epics,
  });

  return {
    getDynamicModule,
    dispatchHooks: {
      useAsyncStart,
      useAsyncStartOnMount,
      useDismissError,
      useResetModule,
      useCancelRequest,
    },
    selectorHooks: {
      useSuccess,
      useData,
      useLastStartPayload,
      useIsLoading,
      useError,
    },
    actions,
    selectors,
  };
}
