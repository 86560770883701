import defaultTheme from '@mmw/ui-web-blocks-nav-bar/theme';

export default {
  ...defaultTheme,
  style: ({ theme }) => `
    display: flex;
    align-items: center;    
    width: 100%;    
    max-height: ${theme.page.variable.NAV_MENU_HEIGHT};   
    background-color: ${theme.colors.pallet.primary};
    justify-content: center;
  `,
  container: {
    style: ({ theme }) => `
      display: flex;
      justify-content: space-between;
      align-items: center;
      ${theme.media.phone`
        overflow-x: scroll;
      `};
    `,
  },
  nav: {
    style: ({ theme }) => `
      display: flex;
      align-items: center;
      list-style: none;
      flex-direction: row;      
      width: 100%;
      max-width: 1320px;
      height: ${theme.page.variable.NAV_MENU_HEIGHT};
      background-color: ${theme.colors.pallet.primary};
      color: ${theme.colors.pallet.light};      
      padding: 0;
    `,
  },
  navItem: {
    style: ({ theme, disabled }) => `    
    cursor: ${disabled ? 'normal' : 'pointer'};
    opacity: ${disabled ? 0.4 : 1};
    padding: 10px;
    background-color: ${theme.colors.pallet.primary};
    color: ${theme.colors.pallet.light};
    transition: 0.4s;
    ${
      disabled
        ? ''
        : `
        &:hover {
          background-color: ${theme.colors.pallet.light};
          color: ${theme.colors.pallet.secondary};
          a, span, p {
            color: ${theme.colors.pallet.secondary};
          }
        }
        `
    }
    &.active {
      background-color: ${theme.colors.pallet.light};
      color: ${theme.colors.pallet.secondary};
      a, span, p {        
        color: ${theme.colors.pallet.secondary};
        font-weight: bold;
      }
    }
    `,
  },
  navLink: {
    ...defaultTheme.navLink,
    text: {
      style: ({ theme }) => `
      color: ${theme.colors.pallet.light};
      font-size: 0.975rem;      
    `,
    },
  },
};
