import borderSchemas from '@mmw/ui-theme/modifiers/color/border';
import { StyledProps } from '@mmw/ui-theme/types';
import iconModifiers from '@mmw/ui-web-elements-icon/theme/modifiers';
import buttonStyle from '@mmw/ui-web-materials-input-group/theme/buttonStyle';
import spanStyle from '@mmw/ui-web-materials-input-group/theme/spanStyle';
import inputGroupStyle from '@mmw/ui-web-materials-input-group/theme/style';

import retailInputStyle from './input';

const modifiers = borderSchemas; // border color modifiers

const inputStyle = ({ theme }: StyledProps<unknown>) => `
  ${retailInputStyle.style({ theme })};
  ${theme.form.variable.baseElementStyle({ theme })}
  ${theme.form.variable.border({ theme })}
  flex: 1;
`;

export default {
  style: inputGroupStyle,
  input: {
    style: inputStyle,
  },
  button: {
    style: buttonStyle,
  },
  span: {
    style: spanStyle,
    icon: {
      style: ({ theme }: StyledProps<unknown>) => `
        color: ${theme.colors.pallet.light};
        fill: ${theme.colors.pallet.light};
        padding: 0;
        margin: 10px;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      `,
      modifiers: iconModifiers,
    },
  },
  modifiers,
};
