export default {
  style: ({ onClick }) => `
    cursor: ${(onClick && 'pointer') || 'auto'};
    background-color: transparent;
    appearance: none;
    display: inline;
    width: auto;
    height: auto;
    margin 0;
    padding 0;
  `,
  list: {
    style: ({ theme }) => `
      list-style: none;
      padding-left: 10px;
      ${theme.media.desktop`
        max-width: 280px;
      `}
    `,
  },
  text: {
    style: () => `
    `,
  },
};
