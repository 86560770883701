import qs from 'qs';
// import { accessTokenQuery } from '@mmw/services-auth-api-authentication';

const BASE_PATH = '/v1/trader/campaign';

export const GetCampaignsPath = (branchID: number, language: string): string =>
  `${BASE_PATH}?${qs.stringify({
    branchID,
    language,
  })}`;
