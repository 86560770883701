import { Feature } from '@mmw/constants-features/types';
import { BehaviorSubject } from 'rxjs';

import logger from './log';

export type FeedbackType = 'SUCCESS' | 'WARNING';

export type NotifiedFeedback = {
  type: FeedbackType;
  feature: Feature;
};

const FEEDBACK_CHANNEL = new BehaviorSubject<NotifiedFeedback | void>();

export const notifySuccess = (feature: Feature) => {
  logger.trace('Notifying success feature=%s', feature);
  FEEDBACK_CHANNEL.next({
    type: 'SUCCESS',
    feature,
  });
};

export const notifyWarning = (feature: Feature) => {
  logger.trace('Notifying warning feature=%s', feature);
  FEEDBACK_CHANNEL.next({
    type: 'WARNING',
    feature,
  });
};

export const onFeedback = (
  callback: (error: NotifiedFeedback | void) => any,
) => {
  FEEDBACK_CHANNEL.subscribe(callback);
};

export const subject = FEEDBACK_CHANNEL;
