import { use } from '@mmw/ui-theme/utils';
import styled from 'styled-components';

import Icon from './elements/Icon';
import Text from './elements/Text';

export interface ButtonStatics {
  Text: typeof Text;
  Icon: typeof Icon;
}

const Button = styled.button.attrs(props => ({
  type: props.type || 'button',
}))(props => use(props, 'button'));

// @ts-ignore
Button.Text = Text;
// @ts-ignore
Button.Icon = Icon;

export type ButtonType = typeof Button;

export default Button as ButtonType & ButtonStatics;
