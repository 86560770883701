import { createDI } from '@di/core';
import defaultApiV2 from '@mmw/api-v2';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import autoBind from 'auto-bind';

import { changePasswordByOperationTokenPath } from './apiPaths';
import logger from './log';
import { UserChangePasswordByToken } from './types';

type Api = typeof defaultApiV2;

type PasswordServiceOptions = {
  apiv2?: Api;
  authenticationService: AuthenticationService;
};

class PasswordService {
  api: Api;

  authenticationService: AuthenticationService;

  static createDI = createDI<PasswordService>;

  constructor({ apiv2, authenticationService }: PasswordServiceOptions) {
    this.api = apiv2 || defaultApiV2;
    this.authenticationService = authenticationService;
    autoBind(this);
  }

  async changePasswordByOperationToken(
    requestData: UserChangePasswordByToken,
  ): Promise<boolean> {
    logger.debug('Trying to update password by operation token');
    try {
      await this.api.post(changePasswordByOperationTokenPath(), requestData);
      logger.info('Successfully updated password');
      return true;
    } catch (error) {
      logger.error(
        'Error when trying to update password by operation token, error=%O',
        error,
      );

      throw error;
    }
  }
}

export default PasswordService;
