// import colors from '../colors';

export default ({ theme }) => ({
  // pathColor: theme.colors.pallet.primary,
  // trailColor: theme.colors.pallet.basicLight,
  // strokeLinecap: 'butt',
  path: {
    stroke: theme.colors.pallet.primary,
    strokeLinecap: 'butt',
  },
  trail: {
    stroke: theme.colors.pallet.basicLight,
    strokeLinecap: 'butt',
  },
  text: {
    fill: theme.colors.pallet.secondary,
    fontSize: theme.typography.modifiers.body1.fontSize,
    fontFamily: theme.fonts.fontFamily,
    fontWeight: 'bold',
  },
});
