import { getLogger } from '@mmw/contextual-config';
import { UserLoginConfiguration } from '@mmw/services-auth-api-authentication/types';
import { getFieldPaths } from '@mmw/utils-object-utils';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { F, U } from 'ts-toolbelt';

import { getLoginConfigByEmailOrUseridStartAction } from '../actions';

export class GetLoginConfigFormData {
  email?: U.Nullable<string> = '';

  emailOrUserid?: U.Nullable<string> = null;
}

export const FIELD_PATHS = getFieldPaths(new GetLoginConfigFormData());

const useGetLoginConfigSubmitHandler = (
  onSuccess: F.Function<[UserLoginConfiguration]>,
): F.Function<[GetLoginConfigFormData]> => {
  const dispatch = useDispatch();
  return useCallback(
    (data: GetLoginConfigFormData) => {
      const emailOrUserid = data.email || data.emailOrUserid;
      if (!emailOrUserid) {
        getLogger().error('No email informed, cannot proceed');
        return;
      }
      dispatch(
        getLoginConfigByEmailOrUseridStartAction(emailOrUserid, onSuccess),
      );
    },
    [dispatch, onSuccess],
  );
};

export default useGetLoginConfigSubmitHandler;
