import { EMEA_COUNTRY_CODES } from '@mmw/constants-country-regions';
import { EMPTY_ARRAY } from '@mmw/constants-utils';
import contextualConfig from '@mmw/contextual-config';
import { RootState as AuthenticationState } from '@mmw/redux-store-auth-api-authentication/types';
import { currentSalesOrgBrandSelector } from '@mmw/redux-store-current-salesorgbrand/stateSelector';
import { RootState as SobRootState } from '@mmw/redux-store-current-salesorgbrand/types';
import { languageSelector } from '@mmw/redux-store-i18n/stateSelector';
import { LoggedUserData } from '@mmw/services-core-logged-user-data-service/types';
import { AvailableStore } from '@mmw/services-core-trader-orgunit/types';
import find from 'lodash/find';
import includes from 'lodash/includes';
import { createSelector } from 'reselect';
import { U } from 'ts-toolbelt';

import {
  EDIT_COMPANY_PERMISSION,
  NAMESPACE,
  RootState as LocalRootState,
  State,
  VIEW_EMPLOYEES_PERMISSION,
} from './types';

export type RootState = LocalRootState & SobRootState & AuthenticationState;

const { defaultCountry } = contextualConfig.application;

export const loggedTraderDataSelector = (
  state: RootState,
): State['loggedTraderData'] => state[NAMESPACE].loggedTraderData;

// xxx: whenever you need to fake the orgunit country
// export const loggedTraderDataSelector = (
//   state: RootState,
// ): State['loggedTraderData'] => ({
//   ...state[NAMESPACE].loggedTraderData,
//   orgunitCountry: 'DE',
// });

export const loggedTraderDataFieldPath = [NAMESPACE, 'loggedTraderData'];

export const selectedStoreSelector = <S = RootState>(
  state: S,
): State['selectedStore'] => state[NAMESPACE].selectedStore;

export const selectedStoreFieldPath = [NAMESPACE, 'selectedStore'];

export const selectedStoreSalesOrgBrandAndLanguageSelector = createSelector(
  selectedStoreSelector,
  currentSalesOrgBrandSelector,
  languageSelector,
  (storeID, salesOrgBrandID, language) => ({
    storeID,
    salesOrgBrandID,
    language,
  }),
);

export const loadingSelector = (state: RootState): State['loading'] =>
  state[NAMESPACE].loading;

export const updatePictureSuccessSelector = (
  state: RootState,
): State['updatePicture']['success'] => state[NAMESPACE].updatePicture.success;

export const updatePictureErrorSelector = (
  state: RootState,
): State['updatePicture']['error'] => state[NAMESPACE].updatePicture.error;

export const availableStoresSelector = (
  state: RootState,
): State['availableStores'] => state[NAMESPACE].availableStores;

export const salesmenSelector = (state: RootState): State['salesmen'] =>
  state[NAMESPACE].salesmen;

export const orgunitGroupNamesSelector = (
  state: RootState,
): State['orgunitGroupNames'] => state[NAMESPACE].orgunitGroupNames;

export const selectedStoreNameSelector = createSelector(
  selectedStoreSelector,
  availableStoresSelector,
  (
    selectedStore: U.Nullable<number>,
    availableStores: Array<AvailableStore>,
  ): string | null => {
    if (!selectedStore) {
      return null;
    }
    const foundStore = find(
      availableStores,
      (store: AvailableStore) => store.id === selectedStore,
    );
    return foundStore != null ? foundStore.name : null;
  },
);

export const userAuthoritiesSelector = createSelector(
  loggedTraderDataSelector,
  (loggedTraderData: U.Nullable<LoggedUserData>) =>
    loggedTraderData ? loggedTraderData.authorities : EMPTY_ARRAY,
);

export const canEditCompanySelector = createSelector(
  userAuthoritiesSelector,
  (authorities: Array<string>) => authorities.includes(EDIT_COMPANY_PERMISSION),
);

export const canViewEmployeesSelector = createSelector(
  userAuthoritiesSelector,
  (authorities: Array<string>) =>
    authorities.includes(VIEW_EMPLOYEES_PERMISSION),
);

export const loggedTraderCompanyIdSelector = createSelector(
  loggedTraderDataSelector,
  (loggedTraderData: U.Nullable<LoggedUserData>) =>
    loggedTraderData ? loggedTraderData.companyId : null,
);

export const loggedTraderDataOrgunitCountryFieldPath = [
  ...loggedTraderDataFieldPath,
  'orgunitCountry',
];

export const loggedTraderDataOrgunitCountrySelector = createSelector(
  loggedTraderDataSelector,
  (loggedTraderData: U.Nullable<LoggedUserData>) =>
    loggedTraderData ? loggedTraderData.orgunitCountry : null,
);

export const loggedTraderOrgunitIsFromEmeaCountrySelector = createSelector(
  loggedTraderDataOrgunitCountrySelector,
  (country: string | null) =>
    includes(EMEA_COUNTRY_CODES, country || defaultCountry),
);

export const selectedStoreDataSelector = createSelector(
  selectedStoreSelector,
  availableStoresSelector,
  (
    selectedStore: U.Nullable<number>,
    availableStores: Array<AvailableStore>,
  ): AvailableStore | null => {
    if (!selectedStore) {
      return null;
    }
    const foundStore = find(
      availableStores,
      (store: AvailableStore) => store.id === selectedStore,
    );
    return foundStore != null ? foundStore : null;
  },
);
