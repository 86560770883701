import { size } from '@mmw/ui-theme/modifiers';
import svgColorModifiers from '@mmw/ui-theme/modifiers/color/svgColors';

import variable from './variable';

const sizeModifiers = size({
  xxlarge: {
    width: variable.measure.xxlarge,
    height: variable.measure.xxlarge,
  },
  xlarge: {
    width: variable.measure.xlarge,
    height: variable.measure.xlarge,
  },
  large: {
    width: variable.measure.large,
    height: variable.measure.large,
  },
  medium: {
    width: variable.measure.medium,
    height: variable.measure.medium,
  },
  small: {
    width: variable.measure.small,
    height: variable.measure.small,
  },
  smaller: {
    width: variable.measure.smaller,
    height: variable.measure.smaller,
  },
  inherit: {
    width: 'inherit',
    height: 'inherit',
  },
});

export default {
  ...sizeModifiers,
  ...svgColorModifiers,
};
