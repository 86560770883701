import { IssueValueObject } from '@issue/interfaces';
import { CampaignStatus } from '@mmw/constants-campaign-status';
import { CampaignType } from '@mmw/constants-campaign-types';
import { CountryCode } from '@mmw/constants-country-codes';
import { LanguageCode } from '@mmw/constants-languages';
import { RegistrationType } from '@mmw/constants-registration-types';
import { SalesType } from '@mmw/constants-sales-types';
import { SalesOrgBrand } from '@mmw/constants-salesorgbrand-ids';
import {
  AddressJSON,
  CampaignJSON,
  ConsumerJSON,
  FileJSON as StoredFileJSON,
  QuestionGroupJSON,
  UploadableFileJSON,
  UploadFilesResultJSON,
} from '@mmw/services-core-common/types';
import { ProductJSON } from '@mmw/services-core-product/types';
import { F, U } from 'ts-toolbelt';

export const DATA_PRIVACY_FILE_PREFIX = 'data-privacy_';

export type ProductForOneSnapAnalysis = {
  id: number;
  ean: string;
};

export type TraderForOneSnapAnalysis = {
  id: number;
};

export type AddressForOneSnapAnalysis = {
  street: U.Nullable<string>;
  nr: U.Nullable<string>;
  zipcode: U.Nullable<string>;
  city: U.Nullable<string>;
  state: U.Nullable<string>;
  country: U.Nullable<string>;
};

export type ConsumerForOneSnapAnalysis = {
  firstName: U.Nullable<string>;
  lastName: U.Nullable<string>;
  address: U.Nullable<AddressForOneSnapAnalysis>;
  shippingAddress: U.Nullable<AddressForOneSnapAnalysis>;
};

export type OneSnapRegistrationItemData = {
  product: U.Nullable<ProductForOneSnapAnalysis>;
  trader: U.Nullable<TraderForOneSnapAnalysis>;
  consumer: U.Nullable<ConsumerForOneSnapAnalysis>;
  invoiceNumber: U.Nullable<string>;
  purchaseDate: U.Nullable<Date | string>;
};

export type OneSnapProcessingResult = {
  updateResult: {
    selectedData: OneSnapRegistrationItemData;
  };
};

export enum OneSnapAlgorithmMode {
  FAST = 'FAST',
  SLOW = 'SLOW',
  MEDIUM = 'MEDIUM',
}

export enum TraderSearchFilterMode {
  ZIPCODE = 'ZIPCODE',
  VAT_ID = 'VAT_ID',
}

export enum OneSnapStep {
  CONVERTING_FILE = 'CONVERTING_FILE',
  READING_FILE = 'READING_FILE',
  CALCULATING_COUNTRIES = 'CALCULATING_COUNTRIES',
  CALCULATING_CITIES = 'CALCULATING_CITIES',
  DETECTING_CITIES_1 = 'DETECTING_CITIES_1',
  DETECTING_CITIES_2 = 'DETECTING_CITIES_2',
  EXTRACTING_FIELDS = 'EXTRACTING_FIELDS',
  DETECTING_COUNTRY_CODES = 'DETECTING_COUNTRY_CODES',
  EXTRACT_STREET_NUMBERS = 'EXTRACT_STREET_NUMBERS',
  DETECTING_STREET_NAMES = 'DETECTING_STREET_NAMES',
  DETECTING_TRADER = 'DETECTING_TRADER',
  DETECTING_PRODUCT = 'DETECTING_PRODUCT',
  DETECTING_INVOICE_NUMBER = 'DETECTING_INVOICE_NUMBER',
  DETECTING_CUSTOMER_NUMBER = 'DETECTING_CUSTOMER_NUMBER',
  DETECTING_PURCHASE_DATE = 'DETECTING_PURCHASE_DATE',
  DETECTING_CONSUMER_ADDRESSES = 'DETECTING_CONSUMER_ADDRESSES',
  AGREGGATING_RESULTS = 'AGREGGATING_RESULTS',
  UPDATING_REGISTRATION_ITEM = 'UPDATING_REGISTRATION_ITEM',
  UPDATING_CAMPAIGN_ITEM_STATUS = 'UPDATING_CAMPAIGN_ITEM_STATUS',
  LOADING_REGISTRATION_ITEM = 'LOADING_REGISTRATION_ITEM',
  DOWNLOADING_FILE = 'DOWNLOADING_FILE',
}

export enum OneSnapProgressStatusType {
  DONE_WITH_SUCCESS = 'DONE_WITH_SUCCESS',
  STOPPED_WITH_ERROR = 'STOPPED_WITH_ERROR',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum OneSnapErrorType {
  OCR_FAILED = 'OCR_FAILED',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
}

export type OneSnapError = {
  type: OneSnapErrorType;
  technicalMessage: string;
};

export type OneSnapStepProgressStatus = {
  type: OneSnapStep;
  startDate: Date;
  endDate: Date;
  elapsedTimeSec: number;
  elapsedTimeMs: number;
  finished: boolean;
  error: U.Nullable<OneSnapError>;
  algorithmMode: OneSnapAlgorithmMode;
};

export type OneSnapProgressStatus = {
  currentStep: OneSnapStepProgressStatus;
  startDate: Date;
  percentage: number;
  totalElapsedTimeSec: number;
  totalElapsedTimeMs: number;
  status: OneSnapProgressStatusType;
  endDate: U.Nullable<Date>;
  currentAlgorithmMode: OneSnapAlgorithmMode;
};

export type OneSnapProgressCallback = F.Function<[OneSnapProgressStatus]>;

export type OneSnapBaseHook<
  HookType extends OneSnapHookType,
  ParamsType extends object | null,
> = {
  type: HookType;
  parameters: ParamsType;
};

export type OneSnapChangeCampaignItemStatusHookParams = null;
export type OneSnapTraderConsumerResolutionHookParams = null;
export type OneSnapTraderProductResolutionHookParams = {
  salesOrgBrandID: number;
  language: string;
};
export type OneSnapUpdateRegistrationItemHookParams = {
  updateRegistrationItem: boolean;
};

export type OneSnapTraderSimulationHookParams = {
  source: number;
  language: string;
  registrationType: RegistrationType;
  storeID: number;
  salesOrgBrandID: number;
  selectedCampaignCodes?: U.Nullable<string[]>;
  excludedCampaignCodes?: U.Nullable<string[]>;
};

export type OneSnapUpdateRegistrationItemHook = OneSnapBaseHook<
  OneSnapHookType.UPDATE_REGISTRATION_ITEM,
  OneSnapUpdateRegistrationItemHookParams
>;

export type OneSnapPrepareTraderRegistrationHookParams = {
  registrationSource: number;
  branchID: number;
};

export type OneSnapPrepareTraderRegistrationHook = OneSnapBaseHook<
  OneSnapHookType.PREPARE_TRADER_REGISTRATION,
  OneSnapPrepareTraderRegistrationHookParams
>;

export type OneSnapSelectRegistrationDataFromResultsHook = OneSnapBaseHook<
  OneSnapHookType.SELECT_REGISTRATION_DATA_FROM_RESULTS,
  null
>;

export type OneSnapChangeCampaignItemStatusHook = OneSnapBaseHook<
  OneSnapHookType.CHANGE_CAMPAIGN_ITEM_STATUS,
  OneSnapChangeCampaignItemStatusHookParams
>;

export type OneSnapBillcheckChangeCampaignItemStatusHook = OneSnapBaseHook<
  OneSnapHookType.BILLCHECK_CHANGE_CAMPAIGN_ITEM_STATUS,
  OneSnapChangeCampaignItemStatusHookParams
>;

export type OneSnapFileVerificationHook = OneSnapBaseHook<
  OneSnapHookType.FILE_VERIFICATION,
  null
>;

export type OneSnapTraderSimulationHook = OneSnapBaseHook<
  OneSnapHookType.SIMULATE_TRADER_REGISTRATION,
  OneSnapTraderSimulationHookParams
>;

export type OneSnapTraderConsumerResolutionHook = OneSnapBaseHook<
  OneSnapHookType.RESOLVE_CONSUMER_FOR_TRADER_REGISTRATION,
  OneSnapTraderConsumerResolutionHookParams
>;

export type OneSnapTraderProductResolutionHook = OneSnapBaseHook<
  OneSnapHookType.RESOLVE_PRODUCT_FOR_TRADER_REGISTRATION,
  OneSnapTraderProductResolutionHookParams
>;

export type OneSnapHook =
  | OneSnapSelectRegistrationDataFromResultsHook
  | OneSnapUpdateRegistrationItemHook
  | OneSnapPrepareTraderRegistrationHook
  | OneSnapChangeCampaignItemStatusHook
  | OneSnapFileVerificationHook
  | OneSnapTraderSimulationHook
  | OneSnapTraderConsumerResolutionHook
  | OneSnapTraderProductResolutionHook
  | OneSnapBillcheckChangeCampaignItemStatusHook;

export type OneSnapAlgorithmParametersV1 = {
  allowedModes?: U.Nullable<OneSnapAlgorithmMode[]>; // if null or empty, run all modes if needed
  processingCountries?: string[];
  updateRegistrationItem?: boolean;
  changeCampaignItemStatus?: boolean;
  ocrAlgorithm?: {
    useInvoiceExtractorToLimitCities: boolean;
  };
  traderAlgorithm?: {
    ignore?: boolean;
    legalEntityID?: U.Nullable<number>;
    orgunitIDs?: U.Nullable<number[]>;
    useInvoiceExtractorToLimitTraders?: TraderSearchFilterMode[];
    useOcrToLimitTraders?: TraderSearchFilterMode[];
  };
  callbacks?: {
    progress?: OneSnapProgressCallback;
  };
  hooks?: OneSnapHook[];
};

export type OneSnapRegistrationRequest = {
  file: UploadableFileJSON;
  campaignID: U.Nullable<number>;
  parameters?: OneSnapAlgorithmParametersV1;
};

export type OneSnapStreamProgressJSON = {
  stepStatus: OneSnapProgressStatus;
};

export type EngineProgressStatus = {
  totalElapsedTimeSec: number;
  totalElapsedTimeMs: number;
};

export enum OCREngineEnum {
  DOCTR = 'doctr',
  TESSERACT = 'tesseract',
  INVOICE_EXTRACTOR = 'invoice_extractor',
  ALL = 'all',
}

export type OneSnapProgressStateStatus = {
  steps: OneSnapStepProgressStatus[];
  endDate: Date;
  percentage: number;
  status: OneSnapProgressStatusType;
  startDate: Date;
  totalElapsedTimeSec: number;
  totalElapsedTimeMs: number;
  error: U.Nullable<OneSnapError>;
  engines: Array<
    EngineProgressStatus & {
      engine: OCREngineEnum;
    }
  >;
};

export enum OneSnapHookType {
  SELECT_REGISTRATION_DATA_FROM_RESULTS = 'SELECT_REGISTRATION_DATA_FROM_RESULTS',
  UPDATE_REGISTRATION_ITEM = 'UPDATE_REGISTRATION_ITEM',
  CHANGE_CAMPAIGN_ITEM_STATUS = 'CHANGE_CAMPAIGN_ITEM_STATUS',
  BILLCHECK_CHANGE_CAMPAIGN_ITEM_STATUS = 'BILLCHECK_CHANGE_CAMPAIGN_ITEM_STATUS',
  PREPARE_TRADER_REGISTRATION = 'PREPARE_TRADER_REGISTRATION',
  SIMULATE_TRADER_REGISTRATION = 'SIMULATE_TRADER_REGISTRATION',
  RESOLVE_CONSUMER_FOR_TRADER_REGISTRATION = 'RESOLVE_CONSUMER_FOR_TRADER_REGISTRATION',
  RESOLVE_PRODUCT_FOR_TRADER_REGISTRATION = 'RESOLVE_PRODUCT_FOR_TRADER_REGISTRATION',
  FILE_VERIFICATION = 'FILE_VERIFICATION',
}

export enum OneSnapHookExecutionStatus {
  SUCCESS = 'SUCCESS',
  IGNORED = 'IGNORED',
  FAILED = 'FAILED',
}

export type OneSnapHookExecutionResult<ResultType extends object> = {
  status: OneSnapHookExecutionStatus;
  errorMessage?: string;
  data?: U.Nullable<ResultType>;
};

export type OneSnapHookResult<Data extends object> = {
  type: OneSnapHookType;
  result: OneSnapHookExecutionResult<Data>;
};

// export type OneSnapAlgorithmResultV1 = {
//   trader: DataObjectClassificationResults<TraderSearchResultJSON>;
//   products: DataObjectClassificationResults<ProductForCampaignAnalysis>[];
//   invoiceNumber: DataObjectClassificationResults<string>;
//   customerNumber: DataObjectClassificationResults<string>;
//   purchaseDate: DataObjectClassificationResults<Date>;
//   consumerAddresses: DataObjectClassificationResult<Address>[];
//   algorithmState: {
//     general: {
//       countryCodes: U.Nullable<string[]>;
//       filteredCities: U.Nullable<string[]>;
//       citiesSize: U.Nullable<number>;
//       streetsSize: U.Nullable<number>;
//       lastAlgorithmMode: OneSnapAlgorithmMode;
//     };
//     trader: {
//       lastRunLimitedTraders: boolean;
//       lastRunLimitedTradersIDs: number[];
//       lastRunLimitedTradersType: U.Nullable<LimitedTraderRunType>;
//     };
//   };
// };
export type OneSnapFileStatus = {
  name: string;
  ext: string;
  hash: U.Nullable<string>;
};

export type OneSnapResultV1<T extends object> = {
  // analysisResult: U.Nullable<OneSnapAlgorithmResultV1>;
  // ocrResult: U.Nullable<OCRMappedResult>; // XXX: TODO, map to a JSON compatible object ... only pojo
  // iaResult: U.Nullable<InvoiceExtractorModelResult>;
  status: OneSnapProgressStateStatus & {
    file: U.Nullable<OneSnapFileStatus>;
  };
  hooksResults: OneSnapHookResult<T>[];
};

export type OneSnapStreamFinalProgressJSON<T extends object> = {
  processStatus: OneSnapProgressStateStatus;
  result: U.Nullable<OneSnapResultV1<T>>;
};

export type PurchaseSelection = {
  purchaseDate: Date;
  invoiceFiles: UploadableFileJSON[];
  salesType: SalesType;
  invoiceNumber?: string | null;
  externalOrdernumber?: string | null;
};

type StoredPurchaseSelection = PurchaseSelection & {
  invoiceFiles: StoredFileJSON[];
};

export type CampaignItemAddedValueSelectionJSON = {
  approved: boolean;
  code: string;
  name: string;
  campaignAddedValueID: number;
};

export type ValidationStatusModel = {
  dataCorrection: {
    hasSerialError: boolean;
    hasImeiError: boolean;
    hasBankAccError: boolean;
    hasShippingAddressError: boolean;
    hasInvoiceError: boolean;
    hasWarrantyError: boolean;
    hasAddedValueError: boolean;
    hasInvoiceNumberError: boolean;
    hasDataPrivacyError: boolean;
    canUpdate: boolean;
    errors: string[];
  };
  addedValueNotAvailable: boolean;
  purchaseErrorMessages: string[];
  purchaseCorrectionMessages: string[];
  campaignErrorMessages: string[];
  addedValueErrorMessages: string[];
  addedValueCorrectionMessages: string[];
  productErrorMessages: string[];
  productCorrectionMessages: string[];
  questionsErrorMessages: string[];
  questionsCorrectionMessages: string[];
  consumerErrorMessages: string[];
  consumerCorrectionMessages: string[];
  bankAccountErrorMessages: string[];
  bankAccountCorrectionMessages: string[];
  dataPrivacyErrorMessages: string[];
  dataPrivacyCorrectionMessages: string[];
  campaignCorrectionMessages: string[];
  registrationBlockedMessage: null;
  hasNonVerifyingErrors: boolean;
  termsNotAcceptedMessage: string | null;
  warrantyMissing: boolean;
  serviceDocumentMissing: boolean;
  imeiInvalid: boolean;
  serialnumberMissing: boolean;
  serialnumberDuplicated: boolean;
  serialnumberNotDelivered: boolean;
  serialnumberPatternWrong: boolean;
  couponnumberMissing: boolean;
  couponnumberNotFound: boolean;
  couponnumberUsed: boolean;
  participationLimitExceeded: boolean;
  registrationDateWrong: boolean;
  purchaseDateWrong: boolean;
  invoicenumberMissing: boolean;
  bankAccountMissing: boolean;
  bankAccountNotPlausible: boolean;
  bankAccountNotValid: boolean;
  bankAgencyMissing: boolean;
  bankAgencyNotValid: boolean;
  bankOwnerMissing: boolean;
  bankBICMissing: boolean;
  bankCountryMissing: boolean;
  bankIBANMissing: boolean;
  bankIBANNotValid: boolean;
  registrationTimeLimitExceeded: boolean;
  updateTimeLimitExceeded: boolean;
  productMissing: boolean;
  purchaseDateMissing: boolean;
  traderMissing: boolean;
  cashBackCodeNotValid: boolean;
  undefinedError: boolean;
  allErrors: number;
  channelError: boolean;
  consumerEmailMissing: boolean;
  consumerAddressIncomplete: boolean;
  termsNotAccepted: boolean;
  logisticTypeMissing: boolean;
  storageTimeLimitExceeded: boolean;
  questionNotAnswered: boolean;
  purchaseToRegistrationTimeLimitExceeded: boolean;
  salesReceiptMissing: boolean;
  requiredVerification: boolean;
  salesChannelMissing: boolean;
  imeiDuplicated: boolean;
  campaignNotAllowed: boolean;
  kitValidationPending: boolean;
  returncodePending: boolean;
  registrationBlocked: boolean;
  bankBICNotValid: boolean;
  eanMissing: boolean;
  eanNotValid: boolean;
  allManualErrors: number;
  imeiMissing: boolean;
  bankErrors: number;
  registrationMarked: boolean;
};

export type ThirdPartyContractJSON = {
  contractID: number;
  coveredMonths: number;
  contractNo: string;
};

export type CampaignItemJSON = {
  campaignitemID: U.Nullable<number>;
  campaignStatus: CampaignStatus;
  campaign: CampaignJSON;
  questionGroups: Array<QuestionGroupJSON>;
  termsAccepted: boolean;
  documents: Array<StoredFileJSON>;
  campaignStatusLabel: string;
  validationErrors: number;
  validationErrorsString: string;
  manualValidationErrors: number;
  errorLabels: string[];
  possibleErrorCorrections: string[];
  hasAccount: boolean;
  consumerInfo: U.Nullable<string>;
  visible: boolean;
  productPosition: number; // used to detect which registration item this belongs to,
  productID: number; // used to detect which registration item this belongs to,
  trackingNumber: U.Nullable<string>;
  cashback: U.Nullable<number>;
  bonusCashback: U.Nullable<number>;
  selectedAddedValue: U.Nullable<CampaignItemAddedValueSelectionJSON>;
  validationStatusModel: ValidationStatusModel;
  thirdPartyContract: ThirdPartyContractJSON | null;
};

export type AvailableThirdPartyPackage = {
  code: string;
  description: string;
  monthsCovered: number;
};

export type AvailableThirdPartyCampaignJSON = {
  campaign: CampaignJSON;
  packages: Array<AvailableThirdPartyPackage>;
  selectedPackage: U.Nullable<string>;
  productID: number;
  productPosition: number;
  productName: string;
  productModel: string;
  serialnumber: string;
  termsAccepted: boolean;
};

export type RegistrationCommonJSON = {
  salesOrgBrandID: SalesOrgBrand;
  storeID: number;
  registrationType: RegistrationType;
  campaignItems?: CampaignItemJSON[];
  availableThirdPartyCampaigns?: U.Nullable<AvailableThirdPartyCampaignJSON[]>;
  shippingAddress?: U.Nullable<AddressJSON>;
  consumer?: U.Nullable<ConsumerJSON>;
  dataPrivacyConsent?: boolean;
};

export type ConsumerAccountJSON = {
  accountowner: U.Nullable<string>;
  account: U.Nullable<string>;
  country: CountryCode;
  bic: U.Nullable<string>;
  iban: U.Nullable<string>;
  agency: U.Nullable<string>;
  manualbankname: U.Nullable<string>;
};

export type EmailConfigurationJSON = {
  send: boolean;
};

export type RegistrationItemSelectionJSON = {
  uuid: string; // XXX: internal id just for render logic,
  product: ProductJSON; // XXX: FAKE FIELD, NOT USED IN API, JUST FOR CLIENT,
  productID: number;
  serialnumber: U.Nullable<string>;
  imei: U.Nullable<string>;
  coupon: U.Nullable<string>;
  promotionCode: U.Nullable<string>;
  givenEan: U.Nullable<string>;
  // XXX: quantity saves into db as product description, used to save ProductSoldQuantity value
  quantity: U.Nullable<number>;
  kitUuid: U.Nullable<string>;
  comment: U.Nullable<string>;
  serialHelpImage: U.Nullable<string>;
  serialHelpText: U.Nullable<string>;
};

export type RegistrationRequestJSON = {
  account?: U.Nullable<ConsumerAccountJSON>;
  campaignID: U.Nullable<number>;
  selectedCampaign: boolean;
  selectedCampaignCodes?: U.Nullable<string[]>;
  excludedCampaignCodes?: U.Nullable<string[]>;
  language: LanguageCode;
  emailConfiguration?: EmailConfigurationJSON;
  productSelection?: RegistrationItemSelectionJSON[];
  createConsumerAsEnduser?: boolean;
  purchaseSelection: PurchaseSelection;
  consumerMaxDistanceConfirmation?: boolean;
} & RegistrationCommonJSON;

export type SimulationRequestJSON = {
  language: LanguageCode;
  emailConfiguration: EmailConfigurationJSON;
  campaignID?: number | null;
  selectedCampaign: boolean;
  salesOrgBrandID: SalesOrgBrand;
  storeID: number;
  registrationType: RegistrationType;
  purchaseSelection: PurchaseSelection;
};

export type DataCorrectionModelJSON = {
  errors: string[];
  hasSerialError: boolean;
  hasImeiError: boolean;
  hasBankAccError: boolean;
  hasShippingAddressError: boolean;
  hasInvoiceError: boolean;
  hasWarrantyError: boolean;
  hasIbv1Error: boolean;
  hasIbv2Error: boolean;
  hasIbvError: boolean;
  hasAddedValueError: boolean;
  hasInvoiceNumberError: boolean;
  hasDataPrivacyError: boolean;
  canUpdate: boolean;
};

export type RegistrationStatusModelJSON = {
  dataCorrection: DataCorrectionModelJSON;
  hasSerialNumberErrors: boolean;
  hasCouponNumberErrors: boolean;
  hasImeiErrors: boolean;
  registrationBlocked: boolean;
  purchaseErrorMessages: string[];
  hasPurchaseErrors: boolean;
  purchaseCorrectionMessages: string[];
  hasPurchaseCorrections: boolean;
  hasCampaignErrors: boolean;
  campaignErrorMessages: string[];
  hasCampaignCorrections: boolean;
  campaignErrorCorrections: string[];
  hasAddedValueErrors: boolean;
  addedValueErrorMessages: string[];
  hasAddedValueCorrections: boolean;
  addedValueCorrectionMessages: string[];
  hasProductErrors: boolean;
  productErrorMessages: string[];
  hasProductCorrections: boolean;
  productCorrectionMessages: string[];
  hasQuestionsErrors: boolean;
  questionsErrorMessages: string[];
  hasQuestionsCorrections: boolean;
  questionsCorrectionMessages: string[];
  hasConsumerErrors: boolean;
  consumerErrorMessages: string[];
  hasConsumerCorrections: boolean;
  consumerCorrectionMessages: string[];
  hasBankAccountErrors: boolean;
  bankAccountErrorMessages: string[];
  hasBankAccountCorrections: boolean;
  bankAccountCorrectionMessages: string[];
  hasDataPrivacyErrors: boolean;
  dataPrivacyErrorMessages: string[];
  hasDataPrivacyCorrections: boolean;
  dataPrivacyCorrectionMessages: string[];
  hasUploadErrors: boolean;
  registrationMarked: boolean;
};

export type RegistrationItemSelectionResponseJSON = {
  id: number;
  product: ProductJSON; // metadata only,
  reversed: boolean; // CANCELED by user later,,
  statusModel: RegistrationStatusModelJSON;
} & RegistrationItemSelectionJSON;

type StoreJSON = {
  orgunitID: number;
  participant: number;
  owner: string;
  address: AddressJSON;
  homePage: U.Nullable<string>;
  displayname: U.Nullable<string>;
  gln: U.Nullable<string>;
  salesType: U.Nullable<SalesType>;
  erpnumber: U.Nullable<string>;
};

export type RegistrationResponseJSON = {
  ordernumber: string;
  externalOrdernumber: string;
  id: number;
  erpnumber: string;
  registrationDate: Date;
  userid: string; // who did the registration,,
  statusModel: RegistrationStatusModelJSON;
  productSelection: Array<RegistrationItemSelectionResponseJSON>;
  frozen: boolean;
  comment: U.Nullable<string>;
  purchaseSelection: StoredPurchaseSelection;
  store: StoreJSON;
  issues: IssueValueObject[];
} & RegistrationCommonJSON;

export type CampaignScoreJSON = {
  score: number;
  totalScoredRegistrations: number;
};

export type CampaignRankingJSON = {
  currentRankingPosition: number;
  currentScore: number;
  participating: boolean;
  totalActiveParticipants: number;
  totalCampaignParticipants: number;
};

export type RegisterScoreJSON = {
  score: number;
};

export class SearchRegistrationRequestFiltersJSON {
  registrationType?: U.Nullable<RegistrationType> = null;

  campaignType?: U.Nullable<CampaignType> = null;

  salesOrgBrandID?: U.Nullable<SalesOrgBrand> = null;

  // TODO: map other properties
  // http://poseidon/mmw-srv/mmw/blob/rc/mmw-webservices-v2/src/main/java/de/kybeidos/mmw/controller/trader/registration/json/RegistrationRequestJSON.java
  registrationDateFrom?: Date = undefined;

  registrationDateTo?: Date = undefined;

  purchaseDateFrom?: Date = undefined;

  purchaseDateTo?: Date = undefined;

  orderNumber?: string = undefined;

  serialNumber?: string = undefined;

  productName?: string = undefined;

  couponNumber?: string = undefined;

  ean?: string = undefined;

  imei?: string = undefined;

  consumerFirstName?: string = undefined;

  consumerLastName?: string = undefined;

  membernumber?: string = undefined;

  consumerEmail?: string = undefined;

  country?: string = undefined;

  ownerErp?: string = undefined;

  ownerName?: string = undefined;

  ownerZipcode?: string = undefined;

  ownerCity?: string = undefined;

  campaignCode?: string = undefined;

  campaignCodes?: string[] = undefined;

  invoiceNumber?: string = undefined;

  reversedItems?: boolean = undefined;

  onlyReversedItems?: boolean = undefined;

  emptyserialnumbers?: boolean = undefined;

  brandID?: number = undefined;

  campaignID?: number = undefined;

  classOfGoods?: number = undefined;

  campaignStatus?: number = undefined;

  campaignStatusList?: number[] = undefined;

  branchIDs?: number[] = undefined;
}

export type SearchRegistrationRequestJSON = {
  limit?: number;
  offset?: number;
} & SearchRegistrationRequestFiltersJSON;

export type UploadRegistrationFilesResultJSON = {
  registration: RegistrationResponseJSON;
} & UploadFilesResultJSON;
