import { I18nShape } from '@mmw/constants-i18n';
import { AUTHENTICATION as I18N } from '@retail/constants-i18n';
import { ContainerProps } from '@ui-system/interfaces-container';
import React, { useCallback } from 'react';
import { F, U } from 'ts-toolbelt';

import Action from './Action';

interface Props extends ContainerProps {
  i18n?: I18nShape;
  recoverPasswordUrl?: U.Nullable<string>;
  textModifiers?: string;
  style?: Record<string, any>;
  containerStyle?: Record<string, any>;
  onClick?: F.Function<[...any]>;
}

const PasswordForgottenAction: React.FC<Props> = ({
  i18n,
  useLink,
  recoverPasswordUrl,
  linkPrefix,
  linkSuffix,
  textModifiers,
  style,
  containerStyle,
  onClick,
  ...props
}: Props) => {
  const onRecoverByUrl = useCallback(async () => {
    if (!recoverPasswordUrl) return;
    window.open(recoverPasswordUrl);
  }, [recoverPasswordUrl]);
  return (
    <Action
      style={style}
      containerStyle={containerStyle}
      i18n={i18n}
      onClick={onClick || onRecoverByUrl}
      useLink={useLink}
      linkPrefix={linkPrefix}
      linkSuffix={linkSuffix}
      textModifiers={textModifiers}
      {...props}
    />
  );
};

PasswordForgottenAction.defaultProps = {
  i18n: I18N.AUTHENTICATION.PASSWORD_FORGOTTEN,
};

export default PasswordForgottenAction;
