import {
  createDispatchHook,
  createSelectorHook,
} from '@redux-basic-module/hooks-utils';
import isEmpty from 'lodash/isEmpty';
import { useLayoutEffect } from 'react';

import {
  getConsumerBankData,
  resetStatusAction,
  saveConsumerBankData,
} from './actions';
import {
  consumerPersonalDataSelector,
  errorSelector,
  loadingSelector,
  successSelector,
  updateDataAttemptedSelector,
} from './stateSelector';

export const useConsumerBankData = createSelectorHook(
  consumerPersonalDataSelector,
);

export const useIsLoading = createSelectorHook(loadingSelector);

export const useSuccessStatus = createSelectorHook(successSelector);

export const useError = createSelectorHook(errorSelector);

export const useSaveDataRequestAttemptedSelector = createSelectorHook(
  updateDataAttemptedSelector,
);

export const useGetConsumerBankDataStart = createDispatchHook<
  ReturnType<typeof getConsumerBankData.start>
>(getConsumerBankData.start);

export const useSaveConsumerBankDataStart = createDispatchHook<
  ReturnType<typeof saveConsumerBankData.start>
>(saveConsumerBankData.start);

export const useResetStatus =
  createDispatchHook<typeof resetStatusAction>(resetStatusAction);

export const useLoadConsumerBankDataOnMount = (): void => {
  const getConsumerBankDataStart = useGetConsumerBankDataStart();
  const consumerBankData = useConsumerBankData();
  const isConsumerDataEmpty = isEmpty(consumerBankData);
  useLayoutEffect(() => {
    if (isConsumerDataEmpty) {
      getConsumerBankDataStart();
    }
  }, [getConsumerBankDataStart]);
};
