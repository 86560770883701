import { I18nShape } from '@mmw/constants-i18n';
import { css } from '@ui-system/css';
import { ContainerProps } from '@ui-system/interfaces-container';
import pxPerfect from '@ui-system/px-perfect';
import UI from '@ui-system/ui';
import React from 'react';
import { F } from 'ts-toolbelt';

import ActionContainer from './elements/ActionContainer';

export interface TroubleActionProps extends Partial<ContainerProps> {
  i18n?: I18nShape;
  onClick: F.Function<any>;
  loading?: boolean;
  useLink?: boolean;
  linkPrefix?: string;
  linkSuffix?: string;
  textModifiers?: string;
  textVariant?: string;
  style?: Record<string, any>;
  containerStyle?: Record<string, any>;
}

const Action: React.FC<TroubleActionProps> = ({
  style,
  containerStyle,
  i18n,
  onClick,
  loading,
  useLink,
  linkPrefix,
  linkSuffix,
  textModifiers,
  textVariant,
  disabled,
  ...props
}: TroubleActionProps) => {
  if (useLink) {
    if (loading)
      return (
        <UI.Skeleton>
          <UI.Container
            style={css`
              height: ${pxPerfect(15)};
              width: ${pxPerfect(120)};
              margin-top: 10px;
              margin-bottom: 10px;
              border-radius: 5px;
            `}
          />
        </UI.Skeleton>
      );
    return (
      <UI.Container style={containerStyle} {...props}>
        <ActionContainer onClick={onClick}>
          <UI.Overline
            style={style}
            i18n={i18n}
            prefix={linkPrefix}
            suffix={linkSuffix}
            modifiers={textModifiers}
            variant={textVariant}
          />
        </ActionContainer>
      </UI.Container>
    );
  }

  return (
    <UI.Container style={containerStyle} {...props}>
      <UI.Button
        style={style}
        onClick={onClick}
        loading={loading}
        i18n={i18n}
        textModifiers={textModifiers}
        disabled={disabled}
      />
    </UI.Container>
  );
};

export default Action;
