import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import { UnblockResponse } from '@mmw/services-auth-api-authentication/types';
import { getAuthenticationService } from '@mmw/services-holder';
import { noop } from 'lodash';
import { ActionsObservable } from 'redux-observable';
import { concat, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, timeout } from 'rxjs/operators';

import {
  requestUnblockErrorAction,
  requestUnblockSuccessAction,
} from '../actions';
import {
  REQUEST_UNBLOCK_START,
  RequestUnblockErrorAction,
  RequestUnblockStartAction,
  RequestUnblockSuccessAction,
} from '../types';

const { logger } = contextualConfig.application;
const { defaultTimeout } = contextualConfig.api;

type EpicConfig = {
  onSuccess?: (response: UnblockResponse) => any;
};

const authenticationRequestUnblock =
  ({ onSuccess }: EpicConfig) =>
  (
    action$: ActionsObservable<RequestUnblockStartAction>,
  ): Observable<RequestUnblockSuccessAction | RequestUnblockErrorAction> =>
    action$.pipe(
      ofType(REQUEST_UNBLOCK_START),
      tap(() => logger.debug('Trying to unblock account')),
      switchMap(
        ({
          payload: {
            username,
            recaptchaResponse,
            language,
            recaptchaType,
            applicationBaseUrl,
            applicationContextPath,
            applicationId,
            redirectUrl,
            scopeName,
          },
        }: RequestUnblockStartAction) =>
          from(
            getAuthenticationService().unblock({
              username,
              recaptchaResponse,
              language,
              recaptchaType,
              applicationId,
              applicationBaseUrl,
              applicationContextPath,
              redirectUrl,
              scopeName,
            }),
          ).pipe(
            timeout(defaultTimeout),
            tap((data: UnblockResponse) => {
              if (data.success) {
                (onSuccess || noop)(data);
              }
            }),
            map((data: UnblockResponse) => requestUnblockSuccessAction(data)),
            catchError(error => concat(of(requestUnblockErrorAction(error)))),
          ),
      ),
    );

export default authenticationRequestUnblock;
