import { set } from 'lodash';
import traverse from 'traverse';
import { DeepRequired } from 'ts-essentials';
import { O } from 'ts-toolbelt';

export type TypedPathKey = string;
export type Chunk = string | symbol | number;

function appendStringSymbolChunkToPath(path: string, chunk: Chunk | symbol) {
  return path + (path === '' ? chunk.toString() : `.${chunk.toString()}`);
}

function appendStringPathChunk(path: string, chunk: Chunk): string {
  if (typeof chunk === 'number') {
    return `${path}[${chunk}]`;
  }
  return appendStringSymbolChunkToPath(path, chunk);
}

function pathToString(path: TypedPathKey[]): string {
  return path.reduce<string>(
    (current, next) => appendStringPathChunk(current, next),
    '',
  );
}

export type TypedPathFunction<ResultType> = (...args: any[]) => ResultType;

export type TypedPathHandlersConfig = Record<
  string,
  <T extends TypedPathHandlersConfig>(
    path: TypedPathKey[],
    additionalHandlers?: T,
  ) => any
>;

const defaultHandlersConfig = {
  $path: (path: TypedPathKey[]) => pathToString(path),
  $rawPath: (path: TypedPathKey[]) => path,
};

export type DefaultHandlers = typeof defaultHandlersConfig;

export type TypedPathHandlers<ConfigType extends TypedPathHandlersConfig> = {
  [key in keyof ConfigType]: ReturnType<ConfigType[key]>;
};

export type TypedPathWrapper<
  OriginalType,
  HandlersType extends TypedPathHandlers<Record<never, string>>,
> = (OriginalType extends Array<infer OriginalArrayItemType>
  ? {
      [index: number]: TypedPathWrapper<OriginalArrayItemType, HandlersType>;
    }
  : OriginalType extends TypedPathFunction<infer OriginalFunctionResultType>
    ? {
        (): TypedPathWrapper<OriginalFunctionResultType, HandlersType>;
      } & {
        [P in keyof Required<OriginalFunctionResultType>]: TypedPathWrapper<
          OriginalFunctionResultType[P],
          HandlersType
        >;
      }
    : {
        [P in keyof Required<OriginalType>]: TypedPathWrapper<
          OriginalType[P],
          HandlersType
        >;
      }) &
  TypedPathHandlers<HandlersType>;

export function getFieldPaths<OriginalObjectType extends O.Object>(
  originalObject: OriginalObjectType,
): TypedPathWrapper<DeepRequired<OriginalObjectType>, DefaultHandlers> {
  const fieldPaths = {} as TypedPathWrapper<
    DeepRequired<OriginalObjectType>,
    DefaultHandlers
  >;
  let lastIndex = 0;
  traverse(originalObject)
    .paths()
    .forEach((path: string[], i: number) => {
      if (i === lastIndex) return;
      lastIndex = i;
      set(fieldPaths, path, {
        $path: defaultHandlersConfig.$path(path),
        $rawPath: defaultHandlersConfig.$rawPath(path),
      });
    });
  return fieldPaths;
}
