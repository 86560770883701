import { useIsPhone } from '@mmw/ui-web-hooks-use-media-query';
import { css as cssStyle } from '@ui-system/css';
import { makeStyle } from '@ui-system/style';
import UI from '@ui-system/ui';
import React from 'react';
import { F } from 'ts-toolbelt';

// border-right: 1px solid ${theme.colors[theme.mode].black};
const useFilterContainerStyle = makeStyle<{ isVisible?: boolean }>(
  ({ css, theme, props, mediaQueries }) => css`
    width: ${props.isVisible ? '275px' : '20px'};
    height: 100%;
    position: relative;
    justify-content: flex-end;
    margin-right: 8px;
    ${mediaQueries.upToSm`
      width: 100%;
      height: auto;      
    `}
  `,
);

interface Props {
  contentVisible?: boolean;
  toggleArea: F.Function;
}

const ExpansibleArea: React.FC<React.PropsWithChildren<Props>> = ({
  contentVisible,
  toggleArea,
  children,
}: React.PropsWithChildren<Props>) => {
  const style = useFilterContainerStyle({ isVisible: contentVisible });
  const isPhone = useIsPhone();
  return (
    <UI.Container
      style={style}
      direction="row"
      responsive="upToSm.m=0,1,4,0;upToSm.direction=column"
    >
      {contentVisible ? (
        <UI.Container
          direction="column"
          p="2"
          m="0, 2"
          w="100%"
          responsive="upToSm.p=0,4;upToSm.m=0"
        >
          <UI.Animation preset="FADE_IN">{children}</UI.Animation>
        </UI.Container>
      ) : null}
      <UI.Container
        direction="column"
        responsive="upToSm.direction=row;upToSm.m=6,0,2,0"
      >
        <UI.Container
          align="center"
          justify="center"
          style={cssStyle`
            position: absolute;
            right: ${isPhone ? '15px' : '-14px'};
            height: 30px;
            width: 30px;
            margin-top: ${isPhone ? '-10px' : '10px'};
            background-color: #eeeeee;
            padding: 10px;
            border-radius: 25px;
          `}
          onClick={toggleArea}
        >
          {isPhone ? (
            <UI.Icon
              name={contentVisible ? 'up' : 'down'}
              color="primary"
              size={15}
            />
          ) : (
            <UI.Icon
              name={contentVisible ? 'previous' : 'next'}
              color="primary"
              size={15}
            />
          )}
        </UI.Container>
        <UI.Container
          w="1px"
          h="100%"
          bg="gray.A200"
          responsive="upToSm.w=100%;upToSm.h=1px"
        />
      </UI.Container>
    </UI.Container>
  );
};

export default ExpansibleArea;
