import useEmailContact from '@retail/authentication-ui-native/hooks/useEmailContact';
import { AUTHENTICATION as I18N } from '@retail/constants-i18n';
import React from 'react';

import Action, { TroubleActionProps } from './Action';

type Props = Omit<TroubleActionProps, 'onClick'>;

const TroubleLoggingInAction: React.FC<Props> = ({
  i18n,
  useLink,
  linkPrefix,
  linkSuffix,
  textModifiers,
  ...props
}: Props) => {
  const mailTo = useEmailContact();
  return (
    <Action
      onClick={() => window.open(mailTo)}
      i18n={i18n}
      useLink={useLink}
      linkPrefix={linkPrefix}
      linkSuffix={linkSuffix}
      textModifiers={textModifiers}
      {...props}
    />
  );
};

TroubleLoggingInAction.defaultProps = {
  i18n: I18N.AUTHENTICATION.TROUBLE_LOGGING_IN,
};

export default TroubleLoggingInAction;
