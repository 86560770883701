import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import { BadCredentialsError } from '@mmw/services-auth-api-authentication/errors';
import { getAuthenticationService } from '@mmw/services-holder';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { ActionsObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, timeout } from 'rxjs/operators';

import { verifyEmailErrorAction, verifyEmailSuccessAction } from '../actions';
import {
  VERIFY_EMAIL_START,
  VerifyEmailErrorAction,
  VerifyEmailResponse,
  VerifyEmailStartAction,
  VerifyEmailSuccessAction,
} from '../types';

const { logger } = contextualConfig.application;
const { defaultTimeout } = contextualConfig.api;

const verifyEmailEpic = (
  action$: ActionsObservable<VerifyEmailStartAction>,
): Observable<VerifyEmailSuccessAction | VerifyEmailErrorAction> =>
  action$.pipe(
    ofType(VERIFY_EMAIL_START),
    tap(() => logger.debug('Trying to verify email')),
    switchMap(
      ({ payload: { email, tan, operationId, callback, recaptchaToken } }) =>
        from(
          getAuthenticationService().verifyEmail({
            email,
            tan,
            operationId,
            recaptchaResponse: recaptchaToken,
            /**
             * XXX executeRecaptchaAction belongs to a web application library,
             * and this package needs to be generic, already implemented the native version
             * in the hook thats calls star action
             */
            // recaptchaToken || (await executeRecaptchaAction('verify-email')),
            recaptchaType: 'V3',
          }),
        ).pipe(
          timeout(defaultTimeout),
          tap((data: VerifyEmailResponse) => {
            if (callback) {
              debounce(() => callback(data), 5000)();
            }
          }),
          map((data: VerifyEmailResponse) => verifyEmailSuccessAction(data)),
          catchError(error => {
            const handledError = isEmpty(error)
              ? new BadCredentialsError('BAD_CREDENTIALS')
              : error;
            return of(verifyEmailErrorAction(handledError));
          }),
        ),
    ),
  );

export default verifyEmailEpic;
