import { I18nShape } from '@mmw/constants-i18n';
import { AUTHENTICATION as AUTH_I18N } from '@retail/constants-i18n';
import { RecoverPasswordByUUID } from '@retail/ui-web-pages-login-and-signup/pages/RecoverPassword';
import { css } from '@ui-system/css/emotion';
import { TitleContentActionCard } from '@ui-system/custom-cards';
import UI from '@ui-system/ui';
import React from 'react';

const BUTTON_STYLE = css`
  width: 100%;
`;

interface Props {
  onSuccessRoute: string;
  descriptionI18n?: I18nShape;
}

const RecoverPasswordUUID: React.FC<Props> = ({
  onSuccessRoute,
  descriptionI18n,
}: Props) => (
  <UI.Container w={500} m={10}>
    <TitleContentActionCard
      title={AUTH_I18N.AUTHENTICATION.RECOVER_PASSWORD}
      contentPadding="0, 8, 8, 8"
    >
      {descriptionI18n ? <UI.Typography i18n={descriptionI18n} /> : null}
      <RecoverPasswordByUUID
        onSuccessRoute={onSuccessRoute}
        buttonStyle={BUTTON_STYLE}
      />
    </TitleContentActionCard>
  </UI.Container>
);

export default RecoverPasswordUUID;
