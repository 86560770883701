import { library } from '@fortawesome/fontawesome-svg-core';
import { faCaretDown, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAvailableLanguages, {
  AvailableLanguage,
} from '@mmw/clients-ui-hooks-use-available-languages';
import { useLanguage } from '@mmw/redux-store-i18n/hooks';
import { Body2 } from '@mmw/ui-web-elements-typography';
import useIsVisible from '@mmw/ui-web-hooks-use-is-visible';
import React from 'react';
import styled from 'styled-components';
import { F } from 'ts-toolbelt';

library.add(faHome, faCaretDown);

const ICON_FAS_CARETDOWN = ['fas', 'caret-down'];
const CaretDownIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.mmw.colors.palette.primary};
`;

const Dropdown = styled.div`
  flex-direction: column;
  margin-left: 2px;
`;

const DropdownButton = styled.div(
  [],
  ({ theme }) => `
  border: 1px solid ${theme.colors.pallet.basic};
  color: ${theme.mmw.colors.palette.primary};
  background-color: ${theme.colors.pallet.light};
  display: flex;
  border-radius: 3px ;
  height: 30px;
  padding: 0 5px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`,
);

const DropdownItems = styled.div`
  position: absolute;
  z-index: 2;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: transparent;
  z-index: 1;
`;

const DropdownItem = styled.div`
  cursor: pointer;
  display: flex;
  background-color: ${({ theme }) => theme.colors.pallet.light};
  padding: 5px;
   ${({ theme }) => `
     border-bottom: 1px solid ${theme.colors.pallet.basic};
     border-left: 1px solid ${theme.colors.pallet.basic};
     border-right: 1px solid ${theme.colors.pallet.basic};
  `};
  }
   :first-child {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    ${({ theme }) => `
     border: 1px solid ${theme.colors.pallet.basic};
  `};
  }
  :last-child {
    border-top: none;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
`;

const Text = styled(Body2)`
  color: ${({ theme }) => theme.mmw.colors.palette.primary};
  width: auto;
  height: 18px;
  margin-right: 2px;
  text-transform: uppercase;
`;

const TextItem = styled(Body2)`
  color: ${({ theme }) => theme.mmw.colors.palette.primary};
  padding: 0 6px;
  text-transform: uppercase;
`;

interface Props {
  toggle: F.Function;
  availableLanguages: Array<AvailableLanguage>;
}

const DropdownContent: React.FC<Props> = ({
  toggle,
  availableLanguages,
}: Props) => (
  <>
    <Overlay onClick={toggle} />
    <DropdownItems>
      {availableLanguages.map(language => (
        <DropdownItem
          key={`${language.languageCode}`}
          onClick={language.selectLanguage}
        >
          <TextItem>{language.languageCode}</TextItem>
        </DropdownItem>
      ))}
    </DropdownItems>
  </>
);

const DropdownLanguage: React.FC = () => {
  const { isVisible, toggle } = useIsVisible();
  const language = useLanguage();
  const availableLanguages = useAvailableLanguages(toggle);
  return (
    <Dropdown>
      <DropdownButton onClick={toggle}>
        <Text>{language}</Text>
        <CaretDownIcon icon={ICON_FAS_CARETDOWN} />
      </DropdownButton>
      {isVisible ? (
        <DropdownContent
          toggle={toggle}
          availableLanguages={availableLanguages}
        />
      ) : null}
    </Dropdown>
  );
};

export default DropdownLanguage;
