import {
  IconName,
  IconPrefix,
  library,
} from '@fortawesome/fontawesome-svg-core';
import { faCaretDown, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import Dropdown from './elements/Dropdown';
import ImageDropdown from './elements/ImageDropdown';
import NavLink from './elements/Link';
import MenuDrawer from './elements/MenuDrawer';
import NavbarContent from './elements/NavbarContent';
import NavItem from './elements/NavItem';

// @ts-ignore
library.add(faHome, faCaretDown);

const MMW_LOGO_SRC =
  'https://www.markenmehrwert.com/static/images/logos/mmw_logo_no_text.png';
const ICON_FA_HOME: [IconPrefix, IconName] = ['fas', 'home'];
const ICON_COLOR = { color: '#004B93' };

const ItemHome = styled.a`
  ${({ theme }) => `
      border: 1px solid ${theme.colors.pallet.basic};
      background: ${theme.colors.pallet.light}
  `};
  display: flex;
  margin: 0 2px;
  border-radius: 3px;
  width: 66px;
  height: 30px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Logo = styled.img`
  width: auto;
  height: 20px;
  margin-right: 6px;
`;

interface HomeLinkProps {
  homeUrl: string;
}

export const HomeLink: React.FC<HomeLinkProps> = ({
  homeUrl,
}: HomeLinkProps) => (
  <ItemHome href={homeUrl}>
    <Logo src={MMW_LOGO_SRC} />
    <FontAwesomeIcon icon={ICON_FA_HOME} style={ICON_COLOR} />
  </ItemHome>
);

const Nav = styled.nav`
  width: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  position: fixed;
  color: #004b93;
  box-shadow: 0 3px 3px 0 rgba(73, 73, 73, 0.4);
  font-family: 'Open Sans', sans-serif;
  z-index: 1024;
  background-color: white;
  height: 55px;
`;

Nav.NavbarContent = NavbarContent;
Nav.ImageDropdown = ImageDropdown;
Nav.NavItem = NavItem;
Nav.NavLink = NavLink;
Nav.Dropdown = Dropdown;
Nav.MenuDrawer = MenuDrawer;

export default Nav;
