import decoration from './decoration';
import style from './style';
import transform from './transform';
import weight from './weight';

const font = {
  style,
  transform,
  weight,
  decoration,
};

export { decoration, font as default, style, transform, weight };
