import { StoreInterface } from '@mmw/common-store';
import autoBind from 'auto-bind';
import store2 from 'store2';
import { F, U } from 'ts-toolbelt';

import logger from './log';

type StoreType = F.Return<typeof store2.namespace> | typeof store2;

class LocalStore implements StoreInterface {
  store: StoreType;

  constructor(namespace: U.Nullable<string>) {
    this.store = namespace ? store2.namespace(namespace) : store2;
    autoBind(this);
  }

  static namespace(namespace: U.Nullable<string>): LocalStore {
    return new LocalStore(namespace);
  }

  async set<T>(
    key: string,
    data: T | null,
    overwrite?: boolean,
  ): Promise<void> {
    try {
      logger.trace(`Trying to store with key=${key}, value=%O`, data);
      if (overwrite === false) {
        const value = await this.get(key);
        if (value) {
          return;
        }
      }
      this.store.set(key, data);
      logger.trace(`Stored successfully into store with key=${key}`);
    } catch (error) {
      logger.error(
        `Error while trying to store with key=${key}, error=%O`,
        error,
      );
      throw error;
    }
  }

  async remove(key: string): Promise<void> {
    try {
      logger.trace(`Trying to remove with key=${key}`);
      await this.store.remove(key);
      logger.trace(`Removed successfully from store with key=${key}`);
    } catch (error) {
      logger.error(
        `Error while trying to remove from store with key=${key}, error=%O`,
        error,
      );
      throw error;
    }
  }

  async get<T>(key: string): Promise<U.Nullable<T>> {
    try {
      logger.trace(`Trying to get with key=${key}`);
      const data = this.store.get(key);
      logger.trace(`Got from store with key=${key}, value=%O`, data);
      return data;
    } catch (error) {
      logger.error(
        `Error while trying to get with key=${key}, error=%O`,
        error,
      );
      throw error;
    }
  }

  async clear(): Promise<void> {
    try {
      const keys = await this.keys();
      keys.map(async key => {
        await this.remove(key);
      });
    } catch (error) {
      logger.error('Error while trying to clear store', error);
      throw error;
    }
  }

  async keys(): Promise<Array<string>> {
    try {
      return this.store.keys();
    } catch (error) {
      logger.error('Error while trying to get keys from store', error);
      throw error;
    }
  }
}

export default LocalStore;
