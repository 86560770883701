import { UserLoginConfiguration } from '@mmw/services-auth-api-authentication/types';
import { useSelector } from 'react-redux';
import { U } from 'ts-toolbelt';

import { foundUserConfigSelector } from '../stateSelector';

const useFoundUserConfig = (): U.Nullable<UserLoginConfiguration> =>
  useSelector(foundUserConfigSelector);

export function useFoundUserId(): U.Nullable<string> {
  const config = useFoundUserConfig();
  return config?.userid;
}

export function useFoundUserEmail(): U.Nullable<string> {
  const config = useFoundUserConfig();
  return config?.email;
}

export default useFoundUserConfig;
