import React from 'react';
import { useStore } from 'zustand';

import { RecoverUserIdStoreContext } from './store';
import { Selector, State } from './types';

const useRecoverUserIdStore = (selector: Selector) => {
  const store = React.useContext(RecoverUserIdStoreContext);
  if (!store) {
    throw new Error('Missing RecoverUserIdStoreContext');
  }
  return useStore(store, selector);
};

export const useIsLoading = () =>
  useRecoverUserIdStore((state: State) => state.isLoading);

export const useSuccess = () =>
  useRecoverUserIdStore((state: State) => state.success);

export const useError = () =>
  useRecoverUserIdStore((state: State) => state.error);

export const useRecoverUserIdStart = (): State['actions']['recoverUserId'] =>
  useRecoverUserIdStore((state: State) => state.actions.recoverUserId);

export const useResetRecoverUserId = (): State['actions']['reset'] =>
  useRecoverUserIdStore((state: State) => state.actions.reset);
