import { INSTALLER as VALIDATION } from '@mmw/constants-fields-validations';
import { string } from 'yup';

const PHONE = string().max(VALIDATION.PHONE.MAX_LENGTH).nullable();

const EMAIL = string().max(VALIDATION.EMAIL.MAX_LENGTH).nullable();

export const INSTALLER = {
  PHONE,
  EMAIL,
};

export default INSTALLER;
