import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import { getAuthenticationService } from '@mmw/services-holder';
import { ActionsObservable } from 'redux-observable';
import { concat, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, timeout } from 'rxjs/operators';

import {
  recoverPasswordErrorAction,
  recoverPasswordSuccessAction,
} from '../actions';
import {
  RECOVER_PASSWORD_START,
  RecoverPasswordErrorAction,
  RecoverPasswordStartAction,
  RecoverPasswordSuccessAction,
} from '../types';

const { logger } = contextualConfig.application;
const { defaultTimeout } = contextualConfig.api;

const recoverPasswordEpic =
  () =>
  (
    // eslint-disable-next-line max-len
    action$: ActionsObservable<RecoverPasswordStartAction>,
  ): Observable<RecoverPasswordSuccessAction | RecoverPasswordErrorAction> =>
    action$.pipe(
      ofType(RECOVER_PASSWORD_START),
      tap(() => logger.debug('Trying to recover password')),
      switchMap(
        ({ payload: { userid, language } }: RecoverPasswordStartAction) =>
          from(
            getAuthenticationService().recoverPassword({
              userid,
              language,
            }),
          ).pipe(
            timeout(defaultTimeout),
            map(response => recoverPasswordSuccessAction(response)),
            catchError(error => concat(of(recoverPasswordErrorAction(error)))),
          ),
      ),
    );

export default recoverPasswordEpic;
