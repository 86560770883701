import defaultApiV2, { ApiResponse } from '@mmw/api-v2';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import autoBind from 'auto-bind';
import qs from 'qs';

import {
  GetConvertToVoucherPath,
  GetGetVoucherStatusPath,
  GetMoveToWalletPath,
  GetRedeemVoucherPath,
  GetWalletBalancePath,
  GetWalletStatusPath,
  GetWalletTransactionsPath,
} from './apiPaths';
import logger from './log';
import {
  ConvertToVoucherRequestJSON,
  GetVoucherStatusJSON,
  MoveToWalletRequestJSON,
  OrgunitWalletStatus,
  OrgunitWalletTransactionsHistory,
  WalletTransactionJSON,
  WalletVoucherJSON,
} from './types';

type Api = typeof defaultApiV2;

type TraderWalletServiceOptions = {
  apiv2?: Api;
  authenticationService: AuthenticationService;
};

class TraderWalletService {
  api: Api;

  authenticationService: AuthenticationService;

  constructor({ apiv2, authenticationService }: TraderWalletServiceOptions) {
    this.api = apiv2 || defaultApiV2;
    this.authenticationService = authenticationService;
    autoBind(this);
  }

  async getWalletBalance(walletName: string): Promise<number> {
    logger.debug('Trying to get wallet balance of=%d', walletName);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<number> = await this.api.get(
        GetWalletBalancePath(walletName),
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully got wallet balance');
      return data;
    } catch (error) {
      logger.error('Error when getting wallet balance, error=%O', error);
      throw error;
    }
  }

  async getWalletTransactions(
    walletName: string,
  ): Promise<OrgunitWalletTransactionsHistory> {
    logger.debug('Trying to get wallet transactions history of=', walletName);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<OrgunitWalletTransactionsHistory> =
        await this.api.get(GetWalletTransactionsPath(walletName), {
          headers,
        });
      const { data } = response;
      logger.info('Successfully got transactions history');
      return data;
    } catch (error) {
      logger.error('Error when getting transactions history, error=%O', error);
      throw error;
    }
  }

  async getWalletStatus(
    walletName: string,
    orgunitID: number,
  ): Promise<OrgunitWalletStatus> {
    logger.debug('Trying to get wallet status of=%d', walletName);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<OrgunitWalletStatus> = await this.api.get(
        `${GetWalletStatusPath(walletName)}/?${qs.stringify(orgunitID)}`,
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully got wallet status');
      return data;
    } catch (error) {
      logger.error('Error when getting wallet status, error=%O', error);
      throw error;
    }
  }

  async convertToVoucher(
    walletName: string,
    request: ConvertToVoucherRequestJSON,
  ): Promise<WalletTransactionJSON> {
    logger.debug('Trying to convert to voucher=%O', request);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<WalletTransactionJSON> = await this.api.post(
        GetConvertToVoucherPath(walletName),
        request,
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully converted to voucher');
      return data;
    } catch (error) {
      logger.error('Error when converting to voucher, error=%O', error);
      throw error;
    }
  }

  async getVoucherStatus(
    walletName: string,
    request: GetVoucherStatusJSON,
  ): Promise<WalletVoucherJSON> {
    logger.debug('Trying to get voucher status=%O', request);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<WalletVoucherJSON> = await this.api.post(
        GetGetVoucherStatusPath(walletName),
        request,
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully get voucher status');
      return data;
    } catch (error) {
      logger.error('Error when get voucher status, error=%O', error);
      throw error;
    }
  }

  async redeemVoucher(
    walletName: string,
    request: GetVoucherStatusJSON,
  ): Promise<WalletTransactionJSON> {
    logger.debug('Trying to redeem voucher=%O', request);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<WalletTransactionJSON> = await this.api.post(
        GetRedeemVoucherPath(walletName),
        request,
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully redeem voucher');
      return data;
    } catch (error) {
      logger.error('Error when redeem voucher, error=%O', error);
      throw error;
    }
  }

  async moveToWallet(
    walletName: string,
    request: MoveToWalletRequestJSON,
  ): Promise<WalletTransactionJSON> {
    logger.debug('Trying to convert to voucher=%O', request);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<WalletTransactionJSON> = await this.api.post(
        GetMoveToWalletPath(walletName),
        request,
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully converted to voucher');
      return data;
    } catch (error) {
      logger.error('Error when converting to voucher, error=%O', error);
      throw error;
    }
  }
}

export default TraderWalletService;
