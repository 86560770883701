import {
  GET_AVAILABLE_SALUTATIONS_ERROR,
  GET_AVAILABLE_SALUTATIONS_START,
  GET_AVAILABLE_SALUTATIONS_SUCCESS,
  Reducer,
} from './types';

const INITIAL_STATE = {
  loading: false,
  availableSalutations: [],
  error: null,
};

const getSalutationsReducer: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_AVAILABLE_SALUTATIONS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_AVAILABLE_SALUTATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        availableSalutations: action.payload.availableSalutations,
        error: null,
      };

    case GET_AVAILABLE_SALUTATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default getSalutationsReducer;
