import { executeRecaptchaAction } from '@mmw/ui-libraries-recaptcha';
import { getFieldPaths } from '@mmw/utils-object-utils';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { F } from 'ts-toolbelt';

import {
  loginByPasswordWithRecaptchaStartAction,
  setUsernameAction,
} from '../actions';
import { AuthenticationResponseWithUser } from '../types';

export class LoginWithPasswordFormData {
  password = '';
}

export const FIELD_PATHS = getFieldPaths(new LoginWithPasswordFormData());

const useLoginByPasswordWithRecaptcha = (
  username: string,
  onLoginSuccess?: F.Function<[AuthenticationResponseWithUser]>,
): F.Function<[LoginWithPasswordFormData]> => {
  const dispatch = useDispatch();
  dispatch(setUsernameAction(username));
  return useCallback(
    async ({ password }: LoginWithPasswordFormData) => {
      if (!password) {
        return;
      }
      const recaptchaResponse = await executeRecaptchaAction(
        'login-by-password',
      );
      dispatch(
        loginByPasswordWithRecaptchaStartAction(
          password,
          recaptchaResponse as string,
          'V3',
          onLoginSuccess,
        ),
      );
    },
    [dispatch, onLoginSuccess],
  );
};

export default useLoginByPasswordWithRecaptcha;
