import { join, map, split } from 'lodash';
import find from 'lodash/find';
import get from 'lodash/get';
import { useMemo } from 'react';

import useFormValidationDescription from './useFormValidationDescription';

export function useMaxAndMinValidationValues(fieldPath: string): {
  max: number | null | undefined;
  min: number | null | undefined;
} {
  const description = useFormValidationDescription();
  return useMemo(() => {
    const fullFieldPath = join(
      map(split(fieldPath, '.'), item => `fields.${item}`),
      '.',
    );

    return {
      min:
        get(
          find(get(description, `${fullFieldPath}.tests`), { name: 'min' }),
          'params.min',
        ) ||
        get(
          find(get(description, `${fullFieldPath}.tests`), {
            name: 'getMinLenghtOrEmpty',
          }),
          'params.min',
        ),
      max: get(
        find(get(description, `${fullFieldPath}.tests`), { name: 'max' }),
        'params.max',
      ),
    };
  }, [description, fieldPath]);
}
