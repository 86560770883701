import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import { getAuthenticationService } from '@mmw/services-holder';
import { ActionsObservable } from 'redux-observable';
import { concat, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, timeout } from 'rxjs/operators';

import {
  validateChangePasswordUuidErrorAction,
  validateChangePasswordUuidSuccessAction,
} from '../actions';
import {
  VALIDATE_CHANGE_PASSWORD_UUID_START,
  ValidateChangePasswordUuidErrorAction,
  ValidateChangePasswordUuidStartAction,
  ValidateChangePasswordUuidSuccessAction,
} from '../types';

const { logger } = contextualConfig.application;
const { defaultTimeout } = contextualConfig.api;

const validateChangePasswordUuidEpic =
  () =>
  (
    // eslint-disable-next-line max-len
    action$: ActionsObservable<ValidateChangePasswordUuidStartAction>,
  ): Observable<
    | ValidateChangePasswordUuidSuccessAction
    | ValidateChangePasswordUuidErrorAction
  > =>
    action$.pipe(
      ofType(VALIDATE_CHANGE_PASSWORD_UUID_START),
      tap(() => logger.debug('Trying to validated uuid for change password')),
      switchMap(
        ({ payload: { uuid } }: ValidateChangePasswordUuidStartAction) =>
          from(
            getAuthenticationService().validateChangePasswordUuid(uuid),
          ).pipe(
            timeout(defaultTimeout),
            map(response => validateChangePasswordUuidSuccessAction(response)),
            catchError(error =>
              concat(of(validateChangePasswordUuidErrorAction(error))),
            ),
          ),
      ),
    );

export default validateChangePasswordUuidEpic;
