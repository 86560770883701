import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { setFieldTouchedAction } from '../../store/actions';
import { FieldPath } from '../../types';
import useFormId from '../useFormId';

function useOnBlur(fieldPath: FieldPath) {
  const dispatch = useDispatch();
  const formId = useFormId();
  return useCallback(
    () => dispatch(setFieldTouchedAction(formId, fieldPath)),
    [formId, fieldPath],
  );
}

export default useOnBlur;
