import getApiConnectionStatusEpic from './epics/getApiConnectionStatusEpic';
import runCheckApiConnectionStatusEpic from './epics/runCheckApiConnectionStatusEpic';
import reducer from './reducer';
import { NAMESPACE } from './types';

function getApiConnectionStatusModule() {
  return {
    id: NAMESPACE,
    reducerMap: {
      [NAMESPACE]: reducer,
    },
    epics: [getApiConnectionStatusEpic, runCheckApiConnectionStatusEpic],
  };
}

export default getApiConnectionStatusModule;
