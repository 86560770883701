import { ConsumerBankDataJSON } from '@mmw/services-core-consumer-bank-account/types';
import { createAsyncActions } from '@redux-async-module/actions-utils';
import { createAction } from '@reduxjs/toolkit';

import {
  BankDataSuccessPayload,
  NAMESPACE,
  RESET_DATA,
  RESET_STATUS,
} from './types';

export const getConsumerBankData = createAsyncActions<
  void,
  BankDataSuccessPayload,
  Error,
  void
>(NAMESPACE, 'get-consumer-bank-data');

export const saveConsumerBankData = createAsyncActions<
  ConsumerBankDataJSON,
  BankDataSuccessPayload,
  Error,
  void
>(NAMESPACE, 'save-consumer-bank-data');

export const resetStatusAction = createAction(
  RESET_STATUS,
  (payload?: void) => ({
    payload,
  }),
);

export const resetBankDataAction = createAction(RESET_DATA);
