import buttonStyle from './buttonStyle';
import inputStyle from './inputStyle';
import spanIconStyle from './spanIconStyle';
import spanStyle from './spanStyle';
import inputGroupStyle from './style';

export default {
  style: inputGroupStyle,
  input: {
    style: inputStyle,
  },
  button: {
    style: buttonStyle,
  },
  span: {
    style: spanStyle,
    icon: {
      style: spanIconStyle,
    },
  },
};
