import { useNavigate } from '@mmw/ui-web-libraries-react-router/hooks';
import { LOGIN_BY_PASSWORD } from '@retail/constants-routes';
import { useCallback } from 'react';

function useNavigateToLoginByPassword() {
  const navigate = useNavigate();
  return useCallback(data => navigate(LOGIN_BY_PASSWORD, data), [navigate]);
}

export default useNavigateToLoginByPassword;
