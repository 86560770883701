import { I18nShape } from '@mmw/constants-i18n';
import {
  DEFAULT as I18N,
  DEFAULT as I18N_VALIDATIONS,
} from '@mmw/constants-i18n-validations';
import { array, boolean, date, number, string } from 'yup';

export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const NUMBER_NULLABLE = number().nullable().typeError(I18N.INVALID.key);
export const NUMBER_REQUIRED = number().required(I18N.REQUIRED.key);
export const POSITIVE_NUMBER_NULLABLE = number().positive().nullable();
export const DATE_NULLABLE = date().nullable();
export const DATE_REQUIRED = date().required(I18N.REQUIRED.key);
export const STRING_NULLABLE = string().nullable();
export const STRING_REQUIRED = string().required(I18N.REQUIRED.key);
export const ARRAY_OF_STRINGS = array().of(string());
export const ARRAY_OF_NUMBERS = array().of(number());
export const BOOLEAN = boolean();
export const TERMS_REQUIRED = boolean().oneOf([true], I18N.REQUIRED.key);
export const EMAIL = string().nullable().email();
export const EMAIL_REQUIRED = string()
  .nullable()
  .email()
  .required(I18N.REQUIRED.key);
export const STRONG_PASSWORD_REQUIRED = string()
  .required(I18N_VALIDATIONS.REQUIRED.key)
  .matches(
    /^(?=.*[A-Z])(?=.*[.!?@#$%^*\\-_-+=])(?=.*[0-9])(?=.*[a-z]).{6,}$/,
    I18N_VALIDATIONS.PASSWORD_STRENGTH_SIX_DIGITS.key,
  );
export const STRONG_PASSWORD = string()
  .nullable()
  .matches(/^(?=.*[A-Z])(?=.*[.!?@#$%^*\\-_-+=])(?=.*[0-9])(?=.*[a-z]).{6,}$/, {
    excludeEmptyString: true,
    message: I18N_VALIDATIONS.PASSWORD_STRENGTH_SIX_DIGITS.key,
  });
interface GetNumberRangeValidationConfig {
  min: number;
  minMessage: I18nShape;
  max: number;
  maxMessage: I18nShape;
}

function getNumberRangeValidation(
  config: GetNumberRangeValidationConfig,
): ReturnType<typeof number> {
  return NUMBER_REQUIRED.min(config.min, config.minMessage.key)
    .max(config.max, config.maxMessage.key)
    .transform(value => {
      if (!value) return 0;
      return value;
    });
}
const UTILS = {
  POSITIVE_NUMBER_NULLABLE,
  NUMBER_NULLABLE,
  NUMBER_REQUIRED,
  DATE_NULLABLE,
  DATE_REQUIRED,
  EMAIL,
  EMAIL_REQUIRED,
  STRING_NULLABLE,
  STRING_REQUIRED,
  ARRAY_OF_STRINGS,
  ARRAY_OF_NUMBERS,
  BOOLEAN,
  TERMS_REQUIRED,
  STRONG_PASSWORD_REQUIRED,
  STRONG_PASSWORD,
  getNumberRangeValidation,
};

export default UTILS;
