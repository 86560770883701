// import { NewAccount } from '@mmw/shared-features';
import NewAccount from '@auth/view-account-creation';
import { LoginContentProps } from '@manufacturer/login-ui';
import { useNavigateToLogin } from '@mmw/common-navigation/hooks';
import Page from '@mmw/ui-page';
import { ReCaptchaV3ContextProvider } from '@recaptcha/v3';
import { useAssets } from '@ui-system/assets';
import { css } from '@ui-system/css';
import { Style } from '@ui-system/interfaces';
import UI from '@ui-system/ui';
import React from 'react';
import { F } from 'ts-toolbelt';
import { ObjectSchema } from 'yup';

interface LoginPageProps extends LoginContentProps {
  contentStyle?: Style | Style[];
  onSuccess: F.Function;
  customValidationSchema?: ObjectSchema<any>;
}

const style = css`
  justify-content: center;
`;

const NewAccountPage: React.FC<LoginPageProps> = ({
  contentStyle = style,
  onSuccess,
  customValidationSchema,
  ...props
}: LoginPageProps) => {
  // const onSuccess = useNavigateToRoute(Routes.HOME, { disableState: true });
  const onClickBack = useNavigateToLogin();
  const assets = useAssets();
  return (
    <Page
      backgroundImageUrl={assets?.loginBackgroundImage}
      backgroundSize="cover"
    >
      <Page.Content contentStyle={contentStyle}>
        <ReCaptchaV3ContextProvider>
          <UI.Container justify="center" w={500} p="4, 0">
            <NewAccount
              onSuccess={onSuccess}
              onClickBack={onClickBack}
              customValidationSchema={customValidationSchema}
              {...props}
            />
          </UI.Container>
        </ReCaptchaV3ContextProvider>
      </Page.Content>
    </Page>
  );
};

export default NewAccountPage;
