import defaultRadioTheme from '@mmw/ui-web-elements-radio/theme';

const field = {
  style: ({ theme }) => `
    ${theme.form.variable.baseElementStyle({ theme })}
    ${theme.form.variable.border({ theme })}
    background-color: ${theme.colors.pallet.basicLight};
    margin-bottom: 5px;
    display: flex;
  `,
  modifiers: {
    inline: () => `
      display: inline;
      padding: 0;
      margin-right: 10px;
    `,
    secondary: ({ theme }) => `
      background-color: ${theme.colors.pallet.light};
    `,
    light: ({ theme }) => `
      background-color: ${theme.colors.pallet.light};
    `,
    borderNone: () => `
      border: none;
    `,
    marginNone: () => `
      margin: 0;
    `,
    noPaddingLeft: () => `
      padding-left: 0;
    `,
    noLayout: () => `
      border: none;
      padding: none;
      background-color: transparent;
    `,
  },
};

const container = {
  style: ({ theme }) => `
    ${theme.form.variable.baseElementStyle({ theme })}
    background-color: ${theme.colors.pallet.light};
    padding: 0;
  `,
  modifiers: {
    inline: () => `
      display: inline-block;
    `,
    defaultFormMargin: ({ theme }) => `
      margin: ${theme.form.variable.ELEMENT_VERTICAL_MARGIN} 0;
    `,
    secondary: ({ theme }) => `
      background-color: ${theme.colors.pallet.light};
    `,
    light: ({ theme }) => `
      background-color: ${theme.colors.pallet.light};
    `,
    inlineFlex: () => `
      display: inline-flex;
    `,
  },
};

const label = {
  style: ({ theme }) => `
    color: ${theme.colors.pallet.secondary};
  `,
  modifiers: {
    error: ({ theme }) => `
      color: ${theme.colors.pallet.error};
    `,
    alert: ({ theme }) => `
      color: ${theme.colors.pallet.alert};
    `,
  },
};

const { modifiers } = defaultRadioTheme;

const radioTheme = {
  style: () => `
    margin-right: 5px;
    min-width: 13px;
    align-self: flex-start;
  `,
  modifiers,
  field,
  label,
  container,
};

export { container, radioTheme as default, field, label };
