import { noop } from 'lodash';
import React, { createContext, ReactNode, useContext, useMemo } from 'react';

type InitialValue = {
  onFocus: () => void;
};

const InputHandlersContext = createContext<InitialValue>({
  onFocus: noop,
});

export const useOnFocus = () => {
  const { onFocus } = useContext(InputHandlersContext);
  return onFocus;
};

type Handlers = {
  onFocus?: () => void;
};

type InputHandlersProps = {
  inputHandlers?: Handlers;
  children: ReactNode;
};

const InputHandlersContextProvider = ({
  inputHandlers: handlers,
  children,
}: InputHandlersProps) => {
  const inputHandlers = useContext(InputHandlersContext);
  const mergedInputHandlers = useMemo(
    () => ({
      ...inputHandlers,
      ...handlers,
    }),
    [handlers],
  );
  return (
    <InputHandlersContext.Provider value={mergedInputHandlers}>
      {children}
    </InputHandlersContext.Provider>
  );
};

InputHandlersContextProvider.defaultProps = {
  inputHandlers: {},
};

export default InputHandlersContextProvider;
