import { BasePath } from '@di/core';
import qs from 'qs';
import { F } from 'ts-toolbelt';

const BASE_PATHS = {
  MANU: '/v1/manu/consumer',
  TRADER: '/v1/trader/consumer',
};

export const [
  getConsumerBasePath,
  setConsumerBasePath,
  CONSUMER_BASE_PATH_TOKEN,
] = BasePath.createDI<string, 'CONSUMER_SERVICE_BASE_PATH_TOKEN'>(
  'CONSUMER_SERVICE_BASE_PATH_TOKEN',
);

export function setManufacturerConsumerBasePath() {
  setConsumerBasePath(BASE_PATHS.MANU);
}

export function setTraderConsumerBasePath() {
  setConsumerBasePath(BASE_PATHS.TRADER);
}

const getPaths = (
  basePath: string | null,
): Record<string, F.Function<[...args: any], string>> => ({
  SaveConsumerPath: (branchID: number): string =>
    `${basePath}/save?${qs.stringify({
      branchID,
    })}`,

  GetConsumersPath: (branchID: number) =>
    `${basePath}/search?${qs.stringify({
      branchID,
    })}`,
  GetConsumerDistanceToStorePath: (
    salesOrgBrandID: number,
    storeID: number,
    consumerID: number,
  ) =>
    `${basePath}/${consumerID}/distance-to-store/${storeID}?${qs.stringify({
      salesOrgBrandID,
    })}`,
});

export default getPaths;
