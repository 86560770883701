import { TokenParseResult } from '@mmw/services-auth-api-token-parser/types';
import { F, U } from 'ts-toolbelt';

export const CHECK_AUTHENTICATION_START =
  '@mmw/authentication/CHECK_AUTHENTICATION_START';
export const CHECK_AUTHENTICATION_SUCCESS =
  '@mmw/authentication/CHECK_AUTHENTICATION_SUCCESS';
export const CHECK_AUTHENTICATION_ERROR =
  '@mmw/authentication/CHECK_AUTHENTICATION_ERROR';

export const CHECK_AUTHENTICATION_WITH_SYSTEM_TOKEN_START =
  '@mmw/authentication/CHECK_AUTHENTICATION_WITH_SYSTEM_TOKEN_START';
export const CHECK_AUTHENTICATION_WITH_SYSTEM_TOKEN_SUCCESS =
  '@mmw/authentication/CHECK_AUTHENTICATION_WITH_SYSTEM_TOKEN_SUCCESS';
export const CHECK_AUTHENTICATION_WITH_SYSTEM_TOKEN_ERROR =
  '@mmw/authentication/CHECK_AUTHENTICATION_WITH_SYSTEM_TOKEN_ERROR';

export const AUTHENTICATE_START = '@mmw/authentication/AUTHENTICATE_START';
export const AUTHENTICATE_SUCCESS = '@mmw/authentication/AUTHENTICATE_SUCCESS';
export const AUTHENTICATE_ERROR = '@mmw/authentication/AUTHENTICATE_ERROR';

export const AUTHENTICATE_BY_TAN_START =
  '@mmw/authentication/AUTHENTICATE_BY_TAN_START';
export const AUTHENTICATE_BY_TAN_SUCCESS =
  '@mmw/authentication/AUTHENTICATE_BY_TAN_SUCCESS';
export const AUTHENTICATE_BY_TAN_ERROR =
  '@mmw/authentication/AUTHENTICATE_BY_TAN_ERROR';

export const AUTHENTICATE_BY_SSO_TOKEN_START =
  '@mmw/authentication/AUTHENTICATE_BY_SSO_TOKEN_START';
export const AUTHENTICATE_BY_SSO_TOKEN_SUCCESS =
  '@mmw/authentication/AUTHENTICATE_BY_SSO_TOKEN_SUCCESS';
export const AUTHENTICATE_BY_SSO_TOKEN_ERROR =
  '@mmw/authentication/AUTHENTICATE_BY_SSO_TOKEN_ERROR';

export const REQUEST_TAN_START = '@mmw/authentication/REQUEST_TAN_START';
export const REQUEST_TAN_SUCCESS = '@mmw/authentication/REQUEST_TAN_SUCCESS';
export const REQUEST_TAN_ERROR = '@mmw/authentication/REQUEST_TAN_ERROR';

export const LOGOUT_START = '@mmw/authentication/LOGOUT_START';
export const LOGOUT_SUCCESS = '@mmw/authentication/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = '@mmw/authentication/LOGOUT_ERROR';
export const LOGOUT_WITH_APP_ID_START =
  '@mmw/authentication/LOGOUT_WITH_APP_ID_START';

export const RESET_AUTHENTICATION_ERRORS =
  '@mmw/authentication/RESET_AUTHENTICATION_ERRORS';

export const RETRIEVE_USERID_BY_EMAIL_START =
  '@mmw/authentication/RETRIEVE_USERID_BY_EMAIL_START';
export const RETRIEVE_USERID_BY_EMAIL_SUCCESS =
  '@mmw/authentication/RETRIEVE_USERID_BY_EMAIL_SUCCESS';
export const RETRIEVE_USERID_BY_EMAIL_ERROR =
  '@mmw/authentication/RETRIEVE_USERID_BY_EMAIL_ERROR';

export const AUTHENTICATE_WITH_GOOGLE_START =
  '@mmw/authentication/AUTHENTICATE_WITH_GOOGLE_START';
export const AUTHENTICATE_WITH_GOOGLE_SUCCESS =
  '@mmw/authentication/AUTHENTICATE_WITH_GOOGLE_SUCCESS';
export const AUTHENTICATE_WITH_GOOGLE_ERROR =
  '@mmw/authentication/AUTHENTICATE_WITH_GOOGLE_ERROR';

export const BYPASS_FORCE_CHANGE_PW =
  '@mmw/authentication/BYPASS_FORCE_CHANGE_PW';

export const AUTHENTICATE_TRADER_BY_SSO_START =
  '@mmw/authentication/trader/sso/AUTHENTICATE_TRADER_BY_SSO_START';
export const AUTHENTICATE_TRADER_BY_SSO_SUCCESS =
  '@mmw/authentication/trader/sso/AUTHENTICATE_TRADER_BY_SSO_SUCCESS';
export const AUTHENTICATE_TRADER_BY_SSO_ERROR =
  '@mmw/authentication/trader/sso/AUTHENTICATE_TRADER_BY_SSO_ERROR';

// TRADER SSO
export type AuthenticateTraderBySSOStartAction = {
  type: '@mmw/authentication/trader/sso/AUTHENTICATE_TRADER_BY_SSO_START';
  payload: {
    site: string;
    params: any;
  };
};

export type AuthenticateTraderBySSOSuccessAction = {
  type: '@mmw/authentication/trader/sso/AUTHENTICATE_TRADER_BY_SSO_SUCCESS';
  payload: {
    loginData: any;
    ssoToken: string;
    success: boolean;
  };
};

export type AuthenticateTraderBySSOErrorAction = {
  type: '@mmw/authentication/trader/sso/AUTHENTICATE_TRADER_BY_SSO_ERROR';
  payload: {
    error: Error;
  };
};

// CHECK
export type CheckAuthenticationStartAction = {
  type: '@mmw/authentication/CHECK_AUTHENTICATION_START';
};

export type CheckAuthenticationSuccessAction = {
  type: '@mmw/authentication/CHECK_AUTHENTICATION_SUCCESS';
  payload: {
    isLoggedIn: boolean;
    loggedUser: U.Nullable<TokenParseResult>;
    accessToken: U.Nullable<string>;
  };
};

export type CheckAuthenticationErrorAction = {
  type: '@mmw/authentication/CHECK_AUTHENTICATION_ERROR';
  payload: {
    error: Error;
  };
};

export type CheckAuthWithSystemTokenStartAction = {
  type: '@mmw/authentication/CHECK_AUTHENTICATION_WITH_SYSTEM_TOKEN_START';
  payload: {
    applicationId: string;
    applicationBaseUrl: U.Nullable<string>;
    applicationPath: U.Nullable<string>;
    applicationContextPath: U.Nullable<string>;
    onSuccess: F.Function;
  };
};

export type CheckAuthWithSystemTokenSuccessAction = {
  type: '@mmw/authentication/CHECK_AUTHENTICATION_WITH_SYSTEM_TOKEN_SUCCESS';
  payload: {
    isLoggedIn: boolean;
    loggedUser: U.Nullable<TokenParseResult>;
    accessToken: U.Nullable<string>;
  };
};

export type CheckAuthWithSystemTokenErrorAction = {
  type: '@mmw/authentication/CHECK_AUTHENTICATION_WITH_SYSTEM_TOKEN_ERROR';
  payload: {
    error: Error;
  };
};

// RESET
export type ResetAuthenticationErrorsAction = {
  type: '@mmw/authentication/RESET_AUTHENTICATION_ERRORS';
};

// AUTH
export type AuthenticateStartAction = {
  type: '@mmw/authentication/AUTHENTICATE_START';
  payload: {
    username: string;
    password: string;
  };
};

export type AuthenticateSuccessAction = {
  type: '@mmw/authentication/AUTHENTICATE_SUCCESS';
  payload: {
    isLoggedIn: boolean;
    loggedUser: U.Nullable<TokenParseResult>;
    accessToken: U.Nullable<string>;
  };
};

export type AuthenticateErrorAction = {
  type: '@mmw/authentication/AUTHENTICATE_ERROR';
  payload: {
    error: Error;
  };
};

// AUTH TAN
export type AuthenticateByTanStartAction = {
  type: '@mmw/authentication/AUTHENTICATE_BY_TAN_START';
  payload: {
    tanCode: string;
  };
};

export type AuthenticateByTanSuccessAction = {
  type: '@mmw/authentication/AUTHENTICATE_BY_TAN_SUCCESS';
  payload: {
    isLoggedIn: boolean;
    loggedUser: U.Nullable<TokenParseResult>;
    accessToken: U.Nullable<string>;
  };
};

export type AuthenticateByTanErrorAction = {
  type: '@mmw/authentication/AUTHENTICATE_BY_TAN_ERROR';
  payload: {
    error: Error;
  };
};

// AUTH BY SSO TOKEN
export type AuthenticateBySSOTokenStartAction = {
  type: '@mmw/authentication/AUTHENTICATE_BY_SSO_TOKEN_START';
  payload: {
    ssoToken: string;
    options?: {
      applicationId?: U.Nullable<string>;
      applicationBaseUrl?: U.Nullable<string>;
      applicationPath?: U.Nullable<string>;
      applicationContextPath?: U.Nullable<string>;
    };
  };
};

export type AuthenticateBySSOTokenSuccessAction = {
  type: '@mmw/authentication/AUTHENTICATE_BY_SSO_TOKEN_SUCCESS';
  payload: {
    isLoggedIn: boolean;
    loggedUser: U.Nullable<TokenParseResult>;
    accessToken: U.Nullable<string>;
  };
};

export type AuthenticateBySSOTokenErrorAction = {
  type: '@mmw/authentication/AUTHENTICATE_BY_SSO_TOKEN_ERROR';
  payload: {
    error: Error;
  };
};

// REQUEST TAN
export type RequestTanStartAction = {
  type: '@mmw/authentication/REQUEST_TAN_START';
  payload: {
    username: string;
    isReSend: boolean;
  };
};

export type RequestTanSuccessAction = {
  type: '@mmw/authentication/REQUEST_TAN_SUCCESS';
  payload: {
    isReSend: boolean;
  };
};

export type RequestTanErrorAction = {
  type: '@mmw/authentication/REQUEST_TAN_ERROR';
  payload: {
    error: Error;
    isReSend: boolean;
  };
};

// RETRIEVE USERID BY EMAIL
export type RetrieveUseridByEmailStartAction = {
  type: '@mmw/authentication/RETRIEVE_USERID_BY_EMAIL_START';
  payload: {
    email: string;
    scopeNames?: string[];
  };
};

export type RetrieveUseridByEmailSuccessAction = {
  type: '@mmw/authentication/RETRIEVE_USERID_BY_EMAIL_SUCCESS';
  payload: {
    userid: string;
  };
};

export type RetrieveUseridByEmailErrorAction = {
  type: '@mmw/authentication/RETRIEVE_USERID_BY_EMAIL_ERROR';
  payload: {
    error: Error;
  };
};

// LOGIN WITH GOOGLE
export type AuthenticateWithGoogleStartAction = {
  type: '@mmw/authentication/AUTHENTICATE_WITH_GOOGLE_START';
  payload: {
    applicationId: string;
    googleJwtToken: string;
    applicationBaseUrl: U.Nullable<string>;
    applicationPath: U.Nullable<string>;
  };
};

export type AuthenticateWithGoogleSuccessAction = {
  type: '@mmw/authentication/AUTHENTICATE_WITH_GOOGLE_SUCCESS';
  payload: {
    accessToken: string;
  };
};

export type AuthenticateWithGoogleErrorAction = {
  type: '@mmw/authentication/AUTHENTICATE_WITH_GOOGLE_ERROR';
  payload: {
    error: Error;
  };
};

// LOGOUT
export type LogoutStartAction = {
  type: '@mmw/authentication/LOGOUT_START';
  payload: {
    onSuccess?: F.Function;
  };
};

export type LogoutSuccessAction = {
  type: '@mmw/authentication/LOGOUT_SUCCESS';
};

export type LogoutErrorAction = {
  type: '@mmw/authentication/LOGOUT_ERROR';
  payload: {
    error: Error;
  };
};

export type LogoutWithAppIdStartAction = {
  type: '@mmw/authentication/LOGOUT_WITH_APP_ID_START';
  payload: {
    applicationId: string;
    applicationBaseUrl: string;
    applicationPath: string;
    applicationContextPath: string;
    global: boolean;
    logoutOthers: Record<string, unknown>;
  };
};

export type BypassForceChangePwAction = {
  type: '@mmw/authentication/BYPASS_FORCE_CHANGE_PW';
  payload: {
    changePwUuid: string;
  };
};

export type State = {
  authenticating: boolean;
  isLoggedIn: boolean;
  loggedUser: U.Nullable<TokenParseResult>;
  accessToken: U.Nullable<string>;
  verificationError: U.Nullable<Error>;
  verificationsCounter: number;
  verifying: boolean;
  authenticationError: U.Nullable<Error>;
  logoutError: U.Nullable<Error>;
  loggingOut: boolean;
  requestTanError: U.Nullable<Error>;
  requestingTan: boolean;
  requestingUseridByEmail: boolean;
  foundUserid: U.Nullable<string>;
  googleAuthFailed?: boolean;
  authWithSystemToken?: boolean;

  bypassForceChangePw: U.Nullable<string>;
};

export const NAMESPACE = 'authentication';

export type RootState = {
  [NAMESPACE]: State;
};

export type Action =
  | CheckAuthenticationStartAction
  | CheckAuthenticationSuccessAction
  | CheckAuthenticationErrorAction
  | CheckAuthWithSystemTokenStartAction
  | CheckAuthWithSystemTokenSuccessAction
  | CheckAuthWithSystemTokenErrorAction
  | AuthenticateStartAction
  | AuthenticateSuccessAction
  | AuthenticateErrorAction
  | AuthenticateByTanStartAction
  | AuthenticateByTanSuccessAction
  | AuthenticateByTanErrorAction
  | AuthenticateBySSOTokenStartAction
  | AuthenticateBySSOTokenSuccessAction
  | AuthenticateBySSOTokenErrorAction
  | RequestTanStartAction
  | RequestTanSuccessAction
  | RequestTanErrorAction
  | LogoutStartAction
  | LogoutSuccessAction
  | LogoutErrorAction
  | LogoutWithAppIdStartAction
  | RetrieveUseridByEmailStartAction
  | RetrieveUseridByEmailSuccessAction
  | RetrieveUseridByEmailErrorAction
  | ResetAuthenticationErrorsAction
  | AuthenticateWithGoogleStartAction
  | AuthenticateWithGoogleSuccessAction
  | AuthenticateWithGoogleErrorAction
  | BypassForceChangePwAction
  | AuthenticateTraderBySSOStartAction
  | AuthenticateTraderBySSOSuccessAction
  | AuthenticateTraderBySSOErrorAction;

export type Reducer = (state: State, action: Action) => State;
