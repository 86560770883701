/* eslint-disable no-param-reassign */
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@mmw/constants-utils';
import { Builder } from '@redux-basic-module/reducer-utils/ReducerBuilder';
import { SearchActions } from '@redux-search-module/actions-utils';
import { enableMapSet } from 'immer';
import compact from 'lodash/compact';
import defaultTo from 'lodash/defaultTo';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import uniq from 'lodash/uniq';

import { LastSearchAction, SearchByUuidState, SearchState } from './state';
import { IdGetterFn } from './types';

enableMapSet();

export function addReducerSearchCases<RequestFields, ItemType>(
  actions: SearchActions<RequestFields, ItemType>,
  builder: Builder<SearchState<RequestFields, ItemType>>,
  idGetter: IdGetterFn<ItemType>,
): void {
  const initialSearchByUuidState = <SearchByUuidState<RequestFields>>(
    new SearchByUuidState<RequestFields>()
  );

  function resolveSearchState(
    draft: SearchState<RequestFields, ItemType>,
    searchUuid: string,
  ): SearchByUuidState<RequestFields> {
    if (!draft.searchesByUuid[searchUuid]) {
      draft.searchesByUuid = {
        ...draft.searchesByUuid,
        [searchUuid]: initialSearchByUuidState,
      };
    }
    draft.currentSearchUuid = searchUuid;
    return draft.searchesByUuid[searchUuid];
  }

  builder
    .addCase(actions.start, (draft, { payload, meta: { searchUuid } }) => {
      const { requestFields, page, limit } = payload;
      const searchState = resolveSearchState(draft, searchUuid);
      searchState.requestFields = <RequestFields>requestFields;
      searchState.loading = true;
      searchState.currentPage = page || 1;
      searchState.limit = limit || searchState.limit;
      searchState.lastSearchAction = LastSearchAction.SEARCH;
      return draft;
    })
    .addCase(actions.success, (draft, { payload, meta: { searchUuid } }) => {
      draft.itemsById = {
        ...draft.itemsById,
        ...keyBy(get(payload, 'list', EMPTY_ARRAY), idGetter),
      };

      const searchState = resolveSearchState(draft, searchUuid);

      searchState.loading = false;
      searchState.error = null;
      searchState.total = get(payload, 'total', 0);
      searchState.visibleIds = map(get(payload, 'list', EMPTY_ARRAY), idGetter);
      searchState.infinityIds =
        searchState.lastSearchAction === LastSearchAction.PAGINATE
          ? uniq(
              compact([
                ...defaultTo(searchState.infinityIds, []),
                ...map(get(payload, 'list', EMPTY_ARRAY), idGetter),
              ]),
            )
          : compact(map(get(payload, 'list', EMPTY_ARRAY), idGetter));
      return draft;
    })
    .addCase(actions.error, (draft, { payload, meta: { searchUuid } }) => {
      const searchState = resolveSearchState(draft, searchUuid);

      searchState.loading = false;
      searchState.error = payload;
      return draft;
    })
    .addCase(actions.paginate, (draft, { payload, meta: { searchUuid } }) => {
      const searchState = resolveSearchState(draft, searchUuid);
      searchState.lastSearchAction = LastSearchAction.PAGINATE;
      searchState.loading = true;
      searchState.currentPage = payload.page || searchState.currentPage + 1; // WITH THE PAGINATION BEING TRIGGERED BY INFINITE SCROLL
      searchState.limit = payload?.limit || searchState.limit;
      return draft;
    })
    .addCase(actions.cancel, (draft, { meta: { searchUuid } }) => {
      const searchState = resolveSearchState(draft, searchUuid);

      searchState.loading = false;
      return draft;
    })
    .addCase(actions.dismissError, (draft, { meta: { searchUuid } }) => {
      const searchState = resolveSearchState(draft, searchUuid);
      searchState.error = null;
      return draft;
    })
    .addCase(actions.reset, (draft, { meta: { searchUuid } }) => {
      draft.searchesByUuid = {
        ...draft.searchesByUuid,
        [searchUuid]: initialSearchByUuidState,
      };
      draft.itemsById = EMPTY_OBJECT;
      return draft;
    })
    .addCase(actions.resetRequestFields, (draft, { meta: { searchUuid } }) => {
      draft.searchesByUuid = {
        ...draft.searchesByUuid,
        [searchUuid]: initialSearchByUuidState,
      };
      return draft;
    })
    .addCase(
      actions.changeLimit,
      (draft, { payload: limit, meta: { searchUuid } }) => {
        const searchState = resolveSearchState(draft, searchUuid);
        searchState.limit = limit;
        return draft;
      },
    );
}
