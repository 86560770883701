import { mount } from './utils';

export type Sizes = {
  [key: string]: {
    [key: string]: string;
  };
};

export default (sizes: Sizes) => mount(sizes);

/* Sizes Example
defaultSizes = {
  modifierName: {
    sizeCssProperty: 'value',
  },
};
measureSizes = {
  small: {
    width: '10px',
    height: '5px',
  },
  large: {
    width: '20px',
    height: '15px',
  },
};

its safe to use camelcase since add modifier inner function will kebab it

fontSizes = {
  body1: {
    fontSize: '1rem',
  },
  body2: {
    fontSize: '0.875rem',
  },
  caption1: {
    fontSize: '0.825rem',
  },
  caption2: {
    fontSize: '0.75rem',
  },
};
*/
