import { StyledElement } from '@mmw/ui-theme/types';
import { use } from '@mmw/ui-theme/utils';
import React from 'react';
import styled from 'styled-components';
import { F } from 'ts-toolbelt';

import Icon from './elements/Icon';
import Text from './elements/Text';

const StyledLink: StyledElement<'a', any> = styled.a(props =>
  use(props, 'link'),
);

interface LinkStatics {
  Text: React.FC<any>;
  Icon: React.FC<any>;
}
interface Props extends Partial<Omit<HTMLAnchorElement, 'children'>> {
  onClick?: F.Function;
  children: React.ReactNode;
}

type LinkType = React.FC<Props> & LinkStatics;

const Link: LinkType = ({ href, children, ...props }: Props) => (
  // eslint-disable-next-line no-script-url
  <StyledLink href={props?.onClick ? 'javascript:void(0);' : href} {...props}>
    {children}
  </StyledLink>
);

Link.Text = Text;
Link.Icon = Icon;

export default Link;
