export default ({ theme }) => `
  width: auto;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.pallet.primary};
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 0;
  padding-bottom: 0;
`;
