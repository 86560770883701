import defaultApiV2, { ApiResponse } from '@mmw/api-v2';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import autoBind from 'auto-bind';

import {
  getGenerateDocumentByRequestPath,
  getGenerateLinkToDocumentByRequestPath,
} from './apiPaths';
import logger from './log';
import { DocumentRequestJSON } from './types';

type Api = typeof defaultApiV2;

type DocumentGeneratorServiceOptions = {
  apiv2?: Api;
  authenticationService: AuthenticationService;
};

class DocumentGeneratorService {
  api: Api;

  authenticationService: AuthenticationService;

  constructor({
    apiv2,
    authenticationService,
  }: DocumentGeneratorServiceOptions) {
    this.api = apiv2 || defaultApiV2;
    this.authenticationService = authenticationService;
    autoBind(this);
  }

  async generateDocumentByRequest(
    request: Array<DocumentRequestJSON>,
  ): Promise<Blob> {
    logger.debug('Trying to generate document by request', request);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Blob> = await this.api.post(
        getGenerateDocumentByRequestPath(),
        request,
        {
          headers,
          responseType: 'blob',
        },
      );
      logger.info('Successfully generated document by request');
      return new Blob([response.data]);
    } catch (error) {
      logger.error(
        'Error when generating document by request, error=%O',
        error,
      );
      throw error;
    }
  }

  async generateNodejsDocumentByRequest(
    request: Array<DocumentRequestJSON>,
  ): Promise<ArrayBuffer> {
    logger.debug('Trying to generate document by request', request);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ArrayBuffer> = await this.api.post(
        getGenerateDocumentByRequestPath(),
        request,
        {
          headers,
          responseType: 'arraybuffer',
        },
      );
      logger.info('Successfully generated document by request');
      return response.data;
    } catch (error) {
      logger.error(
        'Error when generating document by request, error=%O',
        error,
      );
      throw error;
    }
  }

  async generateLinkToDocumentByRequest(
    request: Array<DocumentRequestJSON>,
  ): Promise<string> {
    logger.debug('Trying to get document link by request', request);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<string> = await this.api.post(
        getGenerateLinkToDocumentByRequestPath(),
        request,
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully got document link by request', data);
      return data;
    } catch (error) {
      logger.error(
        'Error when generating document link by request, error=%O',
        error,
      );
      throw error;
    }
  }
}

export default DocumentGeneratorService;
