import {
  Step as MUIStep,
  StepButton as MUIStepButton,
  StepLabel as MUIStepLabel,
  Stepper as MUIStepper,
} from '@material-ui/core';
import { I18nShape } from '@mmw/constants-i18n';
import { useTranslate } from '@mmw/utils-text-utils/hooks';
import {
  StepperProps,
  StepperStep,
  StepperType,
} from '@ui-system/interfaces-stepper';
import UI from '@ui-system/ui';
import { indexOf, map, noop } from 'lodash';
import React, { useCallback, useMemo } from 'react';

const Label = ({ i18n }: { i18n: I18nShape }) => {
  const translate = useTranslate();
  const label = translate(i18n);
  return <MUIStepLabel>{label}</MUIStepLabel>;
};

const Stepper: StepperType = ({
  steps,
  onLastStepComplete = noop,
  alternativeLabel = true,
  nonLinear = true,
  initialStep = 0,
}: StepperProps) => {
  const [activeStep, setActiveStep] = React.useState<StepperStep>(
    steps[initialStep],
  );

  const totalSteps = useMemo(() => steps.length, [steps]);

  const activeStepIndex = useMemo(
    () => indexOf(steps, activeStep),
    [activeStep, steps],
  );

  const isActiveStep = useCallback(
    (index: number) => activeStepIndex === index,
    [activeStepIndex],
  );

  const isLastStep = useMemo(
    () => activeStepIndex === totalSteps - 1,
    [activeStepIndex, totalSteps],
  );

  const nextStep = useCallback(() => {
    const newActiveStep = isLastStep ? activeStep : steps[activeStepIndex + 1];
    setActiveStep(newActiveStep);
  }, [activeStep, activeStepIndex, isLastStep, steps]);

  const backStep = useCallback(() => {
    setActiveStep(steps[activeStepIndex - 1]);
  }, [activeStepIndex, steps]);

  const selectStep = useCallback(step => {
    setActiveStep(step);
  }, []);

  const ActiveContent = () => useMemo(() => activeStep.Component, []);

  return (
    <UI.Container w="100%" direction="column" gap={1}>
      <MUIStepper
        alternativeLabel={alternativeLabel}
        nonLinear={nonLinear}
        activeStep={activeStepIndex}
      >
        {map(steps, (step, index) => (
          <MUIStep key={step.label.key} completed={false}>
            <MUIStepButton
              onClick={() => selectStep(step)}
              icon={
                step.icon &&
                step.icon({
                  color: isActiveStep(index) ? 'primary' : 'inherit',
                })
              }
            >
              <Label i18n={step.label} />
            </MUIStepButton>
          </MUIStep>
        ))}
      </MUIStepper>
      <ActiveContent />
      <UI.Container justify="space-between">
        <UI.Button disabled={!isLastStep} onClick={backStep}>
          Back
        </UI.Button>
        {!isLastStep && (
          <UI.Button disabled={isLastStep} onClick={nextStep}>
            Next
          </UI.Button>
        )}
        {isLastStep && onLastStepComplete && (
          <UI.Button onClick={onLastStepComplete}>Finish</UI.Button>
        )}
      </UI.Container>
    </UI.Container>
  );
};

export default Stepper;
