import borderSchemas from '@mmw/ui-theme/modifiers/color/border';
import { StyledProps } from '@mmw/ui-theme/types';
import { mergeStyle } from '@mmw/ui-theme/utils';
import frameworkStyle from '@mmw/ui-web-elements-checkbox/theme';
import checkboxStyle from '@mmw/ui-web-elements-checkbox/theme/style';

const modifiers = borderSchemas;

const checkboxLabelStyle = ({ theme }: StyledProps<unknown>) => `
  ${frameworkStyle.label.style({ theme })}
  ${theme.form.variable.border({ theme })}
  ${theme.form.variable.focus({ theme })};
  background-color: ${theme.colors.pallet.basicLight};
  color: ${theme.colors.pallet.secondary};
`;

const labelTextStyle = ({ theme }: StyledProps<unknown>) => `
  color: ${theme.colors.pallet.secondary};
`;

const theme = {
  style: checkboxStyle,
  label: {
    style: checkboxLabelStyle,
    text: {
      style: labelTextStyle,
      modifiers,
    },
    modifiers,
  },
  modifiers,
};

const frameworkTheme = {
  ...theme,
  style: () => `
  ${theme.style({ theme })};
  min-width: 13px;  
  `,
};

export default mergeStyle(frameworkStyle, frameworkTheme);
