import { FORMATS } from '@dates/core';
import { FormControl } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { useLanguage } from '@mmw/redux-store-i18n/hooks';
import { isHexColor } from '@ui-system/color';
import Container from '@ui-system/common-container';
import { FieldError } from '@ui-system/common-form';
import { applyColor, Color, css } from '@ui-system/css';
import componentModifiers from '@ui-system/default-modifiers/form/date-picker';
import { useDatePickerStyle } from '@ui-system/default-styles';
import { useTranslateFunction } from '@ui-system/deps';
import {
  FormDatePickerProps,
  FormDatePickerType,
} from '@ui-system/interfaces-form';
import { useTheme } from '@ui-system/theme';
import { useColors } from '@ui-system/theme/colors';
import UI from '@ui-system/ui';
import get from 'lodash/get';
import noop from 'lodash/noop';
import omit from 'lodash/omit';
import toUpper from 'lodash/toUpper';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useDeepCompareMemo } from 'use-deep-compare';

const Input = props => {
  const mappedProps = useMemo(() => {
    const newProps = omit(props, ['InputProps']);
    const onClickProp = get(props, 'onClick');
    return {
      ...newProps,
      onChange: noop,
      accessoryRight: (
        <UI.Icon
          name="calendar"
          size={20}
          color="primary"
          onClick={onClickProp}
        />
      ),
    };
  }, [props]);
  // @ts-ignore
  return <UI.Form.Input {...mappedProps} />;
};

// const dateValue = (
//   value: U.Nullable<number | Date>,
//   defaultValue: U.Nullable<number | Date>,
// ): Date => {
//   if (value) {
//     return moment(value).toDate();
//   }
//   if (!value && defaultValue) {
//     return moment(defaultValue).toDate();
//   }
//   return moment().toDate();
// };

// const DEFAULT_DATE_FORMAT = 'DD.MM.YYYY';

const DatePickerview: FormDatePickerType = ({
  style,
  value = null,
  onChange,
  disabled,
  error,
  label,
  labelColor,
  modifiers,
  required,
  maximumDate,
  minimumDate,
  caption,
  placeholder,
  InputLabelProps,
  dateFormat,
  ...props
}: FormDatePickerProps) => {
  const colors = useColors();
  const theme = useTheme();
  const language = useLanguage();
  const finalStyle = useDatePickerStyle(
    style,
    error ? `${modifiers}, error` : modifiers,
    componentModifiers,
    {
      disabled,
    },
  );
  const labelColorsStyle = useMemo(
    () => ({
      color: finalStyle.color || colors.primary.main,
    }),
    [colors.primary, finalStyle.color],
  );
  const translate = useTranslateFunction();
  const texts = useMemo(() => {
    const labelText = toUpper(translate(label));
    return {
      placeholder: translate(placeholder),
      label: required ? `${labelText || ''} * ` : labelText,
      caption: translate(caption),
    };
  }, [caption, required, label, placeholder, translate]);
  const MuiLabelColor = isHexColor(labelColor as string)
    ? labelColor
    : applyColor(theme, labelColor as Color, true);
  const MuiLabelInputProps = useDeepCompareMemo(
    () => ({
      ...InputLabelProps,
      style: css`
        position: unset;
        color: ${MuiLabelColor};
      `,
    }),
    [InputLabelProps, MuiLabelColor],
  );

  const handleDateAtOnChange = useCallback(
    date => {
      if (onChange) {
        onChange(date);
      }
    },
    [onChange],
  );

  return (
    <FormControl
      style={css`
        width: 100%;
      `}
      error={error}
      component="fieldset"
      disabled={disabled}
      required={required}
    >
      <Container direction="column">
        <DatePicker
          style={finalStyle}
          label={texts.label}
          InputLabelProps={MuiLabelInputProps}
          value={value}
          inputValue={value || ''}
          onChange={handleDateAtOnChange}
          maxDate={maximumDate}
          minDate={minimumDate}
          TextFieldComponent={Input}
          format={dateFormat || FORMATS[language]}
          {...props}
        />
        {error ? (
          <FieldError style={labelColorsStyle} errorMessage={caption} />
        ) : null}
      </Container>
    </FormControl>
  );
};

export default DatePickerview;
