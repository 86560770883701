export default {
  style: ({ theme }) => `
    width: 100%;    
    margin: 0 auto;
    padding: 0px 20px;
    flex: 1;
    height: 100%;
    max-width: ${theme.page.variable.CONTENT_MAX_WIDTH};    
    background-color: ${theme.colors.pallet.background};
  `,
};
