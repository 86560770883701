import {
  FormControl,
  FormControlLabel,
  Switch as MuiSwitch,
} from '@material-ui/core';
import { makeStyles, Theme as MuiTheme } from '@material-ui/core/styles';
import componentModifiers from '@ui-system/default-modifiers/form/switch';
import defaultProps from '@ui-system/default-props/form/switch';
import { useSwitchStyle } from '@ui-system/default-styles';
import { SwitchProps, SwitchType } from '@ui-system/interfaces-form';
import Typography from '@ui-system/material-typography';
import React, { useMemo } from 'react';

const useStyles = makeStyles<MuiTheme, { activeColor?: string }>(theme => ({
  thumb: {
    color: theme.palette.grey[500],
    backgroundColor: theme.palette.grey[700],
    '.Mui-checked.Mui-checked + &': {
      color: props => props?.activeColor || theme.palette.primary.main,
    },
  },
  checked: {
    '& $thumb': {
      backgroundColor: props =>
        props?.activeColor || theme.palette.primary.main,
    },
  },
  switchBase: {
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: props =>
        props?.activeColor || theme.palette.secondary.main,
    },
  },
}));

export const Switch: SwitchType = ({
  checked,
  onChange,
  error,
  disabled,
  style,
  labelI18n,
  labelStyle,
  labelModifiers,
  labelVariant,
  label,
  value,
  color,
  activeColor,
  ...props
}: SwitchProps) => {
  const finalStyle = useSwitchStyle(style, '', componentModifiers);
  const customStyles = useStyles({ activeColor });
  const classes = useMemo(
    () => ({
      switchBase: customStyles.switchBase,
      thumb: customStyles.thumb,
      checked: customStyles.checked,
    }),
    [customStyles.checked, customStyles.switchBase, customStyles.thumb],
  );
  return (
    <FormControl
      required
      error={error}
      component="fieldset"
      disabled={disabled}
      style={finalStyle}
    >
      <FormControlLabel
        style={labelStyle}
        checked={checked}
        onChange={onChange}
        label={
          (labelI18n && (
            <Typography
              i18n={labelI18n}
              modifiers={labelModifiers}
              variant={labelVariant}
            />
          )) || (
            <Typography modifiers={labelModifiers} variant={labelVariant}>
              {label}
            </Typography>
          )
        }
        control={
          <MuiSwitch classes={classes} value={value} color={color} {...props} />
        }
      />
    </FormControl>
  );
};

Switch.defaultProps = defaultProps;

export default Switch;
