import { FieldPath } from '@mmw/redux-store-fast-form';
import {
  useInputFieldConfigProps,
  useOnBlur,
  useOnClickSubmit,
  useOnInputChange,
  useOnPressEnterSubmit,
  useValue,
} from '@mmw/redux-store-fast-form/hooks/fields';
import InputGroup from '@mmw/ui-web-materials-input-group';
import { useOnFocus } from '@mmw/ui-web-providers-input-handlers';
import { isEmpty, noop } from 'lodash';
import React, { ReactNode, useMemo } from 'react';
import styled from 'styled-components';

const { Input, Button } = InputGroup;

const FastFormInputGroup = styled(InputGroup)``;

const FastFormInputGroupButton = ({ ...props }) => {
  const onClick = useOnClickSubmit();
  return <Button {...props} onClick={onClick} />;
};
FastFormInputGroup.Button = FastFormInputGroupButton;

type SubmitProps = {
  children: ReactNode;
  required?: string;
};

// use required as a path to disable submit on empty value

const FastFormInputGroupSubmit = ({
  children,
  required,
  ...props
}: SubmitProps) => {
  const submit = useOnClickSubmit();
  const value = useValue(required);
  const disabled = required ? isEmpty(value) : false;
  const onClick = disabled ? noop : submit;
  return children
    ? React.Children.map(
        children,
        child =>
          child && React.cloneElement(child, { ...props, onClick, disabled }),
      )
    : null;
};
FastFormInputGroup.Submit = FastFormInputGroupSubmit;

type OwnProps = {
  fieldPath: FieldPath;
};

const FastFormInputGroupInput = ({ fieldPath, ...rest }: OwnProps) => {
  const value = useValue(fieldPath, '');
  const onBlur = useOnBlur(fieldPath);
  const onChange = useOnInputChange(fieldPath);
  const onPressEnterSubmit = useOnPressEnterSubmit();
  const inputFieldConfigProps = useInputFieldConfigProps(fieldPath);
  const onFocus = useOnFocus();
  return useMemo(
    () => (
      <Input
        name={fieldPath}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={onPressEnterSubmit}
        onFocus={onFocus}
        {...inputFieldConfigProps}
        {...rest}
      />
    ),
    [
      fieldPath,
      value,
      onBlur,
      onChange,
      onPressEnterSubmit,
      inputFieldConfigProps,
      rest,
    ],
  );
};
FastFormInputGroup.Input = FastFormInputGroupInput;

FastFormInputGroup.Button.Icon = Button.Icon;

export default FastFormInputGroup;
