import { use } from '@mmw/ui-theme/utils';
import Icon from '@mmw/ui-web-elements-icon';
import { Overlay } from '@mmw/ui-web-hooks-use-click-outside';
import useEventStopPropagation from '@mmw/ui-web-hooks-use-event-stop-propagation';
import useIsVisible from '@mmw/ui-web-hooks-use-is-visible';
import Link from '@mmw/ui-web-materials-link';
import { CLOSE_ICON, HELP_ICON } from '@retail/hybrid-icons';
import React, { useMemo } from 'react';
import ReactDOM from 'react-dom';
import { Manager, Popper, Reference } from 'react-popper';
import styled from 'styled-components';

import { TooltipText } from './Elements';
import ParsedContent from './ParsedContent';
import { SharedProps } from './types';

const HelpButton = styled(Link.Icon)(props => use(props, 'helpTooltip.button'));

const Content = styled.div(props => use(props, 'helpTooltip.content'));
const Tooltip = styled.div(props => use(props, 'helpTooltip.tooltip'));
const Header = styled.header(props => use(props, 'helpTooltip.header'));

const TooltipIcon = styled(Icon)(props => use(props, 'helpTooltip.icon'));

const createTooltipContainer = (): HTMLElement => {
  let element = document.querySelector('#tooltipContainer');
  if (element == null) {
    element = document.createElement('div');
    element.setAttribute('id', 'tooltipContainer');

    document.body.appendChild(element);
  }
  return element;
};

const TitleContainer = styled.div`
  margin: 0 auto 0 10px;
`;

const CloseButton = styled(Link.Icon)`
  cursor: pointer;
  margin: 8px;
`;

type OwnProps = {
  modifiers?: 'small' | 'medium' | 'large' | 'xlarge';
  icon?: string;
  zIndex?: string;
};

type ContentProps = {
  innerRef: any;
  style: any;
  toggle: () => void;
  content?: string;
};

const BOLD_MODIFIERS = ['body1', 'bold', 'left'];

const HelpTooltipContent = ({
  toggle,
  innerRef,
  style,
  placement,
  i18nTitle,
  i18nContent,
  title,
  content,
  children,
}: SharedProps & ContentProps) => {
  const toggleWithStopPropagation = useEventStopPropagation(toggle);
  const tooltipWithStopPropagation = useEventStopPropagation();
  return (
    <>
      <Overlay onClick={toggleWithStopPropagation} />
      <Tooltip
        id="tooltip-overlay"
        ref={innerRef}
        style={style}
        data-placement={placement}
        onClick={tooltipWithStopPropagation}
      >
        <Header>
          <TooltipIcon src={HELP_ICON} modifiers="medium" />
          <TitleContainer>
            {i18nTitle && (
              <TooltipText i18n={i18nTitle} modifiers={BOLD_MODIFIERS} />
            )}
            {title && (
              <TooltipText modifiers={BOLD_MODIFIERS}>{title}</TooltipText>
            )}
          </TitleContainer>
          <CloseButton
            src={CLOSE_ICON}
            modifiers="medium"
            onClick={toggleWithStopPropagation}
          />
        </Header>
        <Content>
          <ParsedContent i18nContent={i18nContent} content={content} />
          {children}
        </Content>
      </Tooltip>
    </>
  );
};

HelpTooltipContent.defaultProps = {
  content: '',
};

const POPPER_MODIFIERS = {
  preventOverflow: {
    enabled: true,
    boundariesElement: 'viewport',
    escapeWithReference: false,
  },
};

const HelpTooltip = ({
  i18nTitle,
  i18nContent,
  placement: innerPlacement = 'left',
  modifiers,
  title,
  content,
  icon,
  zIndex,
  children,
}: SharedProps & OwnProps) => {
  const { isVisible, toggle } = useIsVisible();
  const toggleWithStopPropagation = useEventStopPropagation(toggle);
  const hasContent = useMemo(
    () => !!(i18nTitle || title) && !!(i18nContent || content || children),
    [i18nTitle, title, i18nContent, content, children],
  );
  if (!hasContent) {
    return null;
  }
  return (
    <Manager>
      {isVisible &&
        ReactDOM.createPortal(
          <Popper placement={innerPlacement} modifiers={POPPER_MODIFIERS}>
            {({ style, placement, ref }) => (
              <HelpTooltipContent
                style={{ ...style, zIndex }}
                placement={placement}
                innerRef={ref}
                isVisible={isVisible}
                toggle={toggle}
                i18nTitle={i18nTitle}
                i18nContent={i18nContent}
                title={title}
                content={content}
              >
                {children}
              </HelpTooltipContent>
            )}
          </Popper>,
          createTooltipContainer(),
        )}
      <Reference>
        {({ ref }) => (
          <div ref={ref}>
            <HelpButton
              src={icon || HELP_ICON}
              onClick={toggleWithStopPropagation}
              modifiers={modifiers}
            />
          </div>
        )}
      </Reference>
    </Manager>
  );
};

HelpTooltip.defaultProps = {
  modifiers: 'medium',
  zIndex: '1021',
};

export default HelpTooltip;
