import Select from '@mmw/ui-web-elements-select';
import { compose } from 'recompose';
import styled from 'styled-components';

import { withName, withOnBlur, withOnChange, withValue } from './hocs';

// <Select
//   name={name}
//   value={value}
//   options={options}
//   optionsName={optionsName}
//   optionsValue={optionsValue}
//   onChange={onChange}
//   onBlur={onBlur}
//   width={width}
//   {...selectprops}
// />

const enhanceInput = compose(withValue, withOnBlur, withOnChange, withName);

export default enhanceInput(styled(Select)``);
