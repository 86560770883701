// import { createSelector } from 'reselect';
import { NAMESPACE, RootState, State } from './types';

export const loadingSelector = (state: RootState): State['loading'] =>
  state[NAMESPACE].loading;

export const errorSelector = (state: RootState): State['error'] =>
  state[NAMESPACE].error;

export const usernameSelector = (state: RootState): State['username'] =>
  state[NAMESPACE].username;

export const redirectUrlSelector = (state: RootState): State['redirectUrl'] =>
  state[NAMESPACE].redirectUrl;

export const recaptchaResponseSelector = (
  state: RootState,
): State['recaptchaResponse'] => state[NAMESPACE].recaptchaResponse;
