import {
  NAMESPACE as USER_ACCOUNT_NAMESPACE,
  reducer as userAccountReducer,
} from '@auth/redux-store-user-account';
import { getCampaignsListModule } from '@ccc/redux-campaigns-list';
import {
  NAMESPACE as CONSUMER_BANK_ACCOUNT_NAMESPACE,
  reducer as consumerBankAccountReducer,
} from '@consumer/bank-account-redux-store';
import {
  NAMESPACE as CONSUMER_PROFILE_NAMESPACE,
  reducer as consumerProfileReducer,
} from '@consumer/profile-redux-store';
import getApiConnectionStatusModule from '@mmw/api-connection-status-redux-store';
import {
  NAMESPACE as AUTHENTICATION_NAMESPACE,
  reducer as authenticationReducer,
} from '@mmw/redux-store-auth-api-authentication';
import {
  NAMESPACE as LOGIN_BY_PASSWORD,
  reducer as loginByPasswordReducer,
} from '@mmw/redux-store-auth-api-login-by-password';
import {
  NAMESPACE as AUTHENTICATION_LOGIN_CONFIG,
  reducer as authenticationLoginConfigReducer,
} from '@mmw/redux-store-auth-api-login-config-by-user';
import {
  NAMESPACE as RECOVER_PASSWORD_NAMESPACE,
  reducer as recoverPasswordReducer,
} from '@mmw/redux-store-auth-api-recover-password';
import {
  NAMESPACE as AUTHENTICATION_UNBLOCK_NAMESPACE,
  reducer as authenticationUnblockReducer,
} from '@mmw/redux-store-auth-api-request-unblock';
import {
  NAMESPACE as AVAILABLE_COUNTRIES_NAMESPACE,
  reducer as availableCountriesReducer,
} from '@mmw/redux-store-common-available-countries';
import {
  NAMESPACE as AVAILABLE_SALUTATIONS_NAMESPACE,
  reducer as availableSalutationsReducer,
} from '@mmw/redux-store-common-available-salutations';
import {
  getLoggedTraderDataModule,
  NAMESPACE as LOGGED_USER_NAMESPACE,
  reducer as loggedUserReducer,
} from '@mmw/redux-store-core-trader-logged-user';
import { getCurrentSalesOrgBrandModule } from '@mmw/redux-store-current-salesorgbrand';
import {
  NAMESPACE as EMAIL_VERIFICATION_NAMESPACE,
  reducer as emailVerificationReducer,
} from '@mmw/redux-store-email-verification';
import {
  NAMESPACE as FAST_FORM_NAMESPACE,
  reducer as fastFormReducer,
} from '@mmw/redux-store-fast-form';
import {
  NAMESPACE as I18N_NAMESPACE,
  reducer as i18nReducer,
} from '@mmw/redux-store-i18n';
import { getSalesOrgBrandsModule } from '@mmw/redux-store-salesorgbrands';
import { routerModule } from '@mmw/ui-web-libraries-react-router/module';
import {
  NAMESPACE as SPINNER_NAMESPACE,
  reducer as spinnerReducer,
} from '@retail/spinner-redux-store';
import { combineReducers } from 'redux';

export default combineReducers({
  ...routerModule.reducerMap,
  [I18N_NAMESPACE]: i18nReducer,
  [FAST_FORM_NAMESPACE]: fastFormReducer,
  [AUTHENTICATION_NAMESPACE]: authenticationReducer,
  [LOGIN_BY_PASSWORD]: loginByPasswordReducer,
  [AVAILABLE_COUNTRIES_NAMESPACE]: availableCountriesReducer,
  [AVAILABLE_SALUTATIONS_NAMESPACE]: availableSalutationsReducer,
  [USER_ACCOUNT_NAMESPACE]: userAccountReducer,
  [CONSUMER_BANK_ACCOUNT_NAMESPACE]: consumerBankAccountReducer,
  [CONSUMER_PROFILE_NAMESPACE]: consumerProfileReducer,
  [AUTHENTICATION_LOGIN_CONFIG]: authenticationLoginConfigReducer,
  [AUTHENTICATION_UNBLOCK_NAMESPACE]: authenticationUnblockReducer,
  [LOGGED_USER_NAMESPACE]: loggedUserReducer,
  [EMAIL_VERIFICATION_NAMESPACE]: emailVerificationReducer,
  [SPINNER_NAMESPACE]: spinnerReducer,
  ...getCampaignsListModule().reducerMap,
  ...getApiConnectionStatusModule()?.reducerMap,
  [RECOVER_PASSWORD_NAMESPACE]: recoverPasswordReducer,
  ...getSalesOrgBrandsModule().reducerMap,
  ...getCurrentSalesOrgBrandModule().reducerMap,
  ...getLoggedTraderDataModule().reducerMap,
});
