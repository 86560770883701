import {
  CompanyDetails,
  CreateCompanyDetails,
} from '@mmw/services-core-trader-orgunit/types';
import { createAsyncActions } from '@redux-async-module/actions-utils';
import { F } from 'ts-toolbelt';

import { NAMESPACE } from './types';

export type OnSuccess = { onSuccess: F.Function<[CompanyDetails]> };
export type StartPayload = CreateCompanyDetails & OnSuccess;
export type SuccessPayload = CompanyDetails;
export const createBranch = createAsyncActions<
  StartPayload,
  SuccessPayload,
  Error,
  null
>(NAMESPACE, 'create-branch');
