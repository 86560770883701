import background from '@mmw/ui-theme/modifiers/background';
import { StyledProps } from '@mmw/ui-theme/types';

const style = ({ theme, disabled }: StyledProps<{}>) => `
  min-height: 40px;
  ${theme.media.IE11`
    height: 40px;
  `}
  ${theme.media.smallPhones`
    padding: 5px;
  `}
  ${theme.form.variable.baseElementStyle({ theme, disabled })};
  color: ${disabled ? theme.colors.pallet.basic : theme.colors.pallet.dark};
  ${theme.form.variable.border({ theme })};
  ${theme.form.variable.focus({ theme })};
  :invalid {
    ${theme.form.variable.border({ theme })};
  }
`;

const modifiers = {
  error: ({ theme }: StyledProps<unknown>) => `
    ${background.image(theme.assets.alertIcon, '10')};
    border: 1px solid ${theme.colors.pallet.error};
    background-color: ${theme.colors.pallet.basicLight};
    color: ${theme.colors.pallet.error};
    :invalid {
      border: 1px solid ${theme.colors.pallet.error};
    }
    ${theme.media.smallPhones(
      background.image(theme.assets.alertIcon, '5', '25px'),
    )}
  `,
  errorIcon: ({ theme }: StyledProps<unknown>) => `
    ${background.image(theme.assets.alertIcon, '10', '20px')}
    ${theme.media.smallPhones(
      background.image(theme.assets.alertIcon, '5', '15px'),
    )}
  `,
  successIcon: ({ theme }: StyledProps<unknown>) => `
    ${background.image(theme.assets.greenCheck, '10', '20px')}
    ${theme.media.smallPhones(
      background.image(theme.assets.greenCheck, '5', '15px'),
    )}
  `,
  light: ({ theme }: StyledProps<unknown>) => `
    background-color: ${theme.colors.pallet.light};
    border: none;
  `,
};

export default {
  style,
  modifiers,
};
