import {
  useError,
  useIsLoading,
  useIsPhoneAlreadyInUse,
  useIsPhoneValid,
  usePhoneLookupValidation,
} from '@authentication/sms-phone-verification-store';
import { FastFormSubmitButton } from '@fast-form/ui-system';
import { SMS_VALIDATION as I18N } from '@mmw/constants-i18n-authentication';
import { CANCEL, CONFIRM } from '@mmw/constants-i18n-buttons';
import { PERSON } from '@mmw/constants-validation-field-schemas';
import { STRING_REQUIRED } from '@mmw/constants-validation-field-schemas/utils';
import contextualConfig from '@mmw/contextual-config';
import { FastFormProvider } from '@mmw/redux-store-fast-form';
import UI from '@ui-system/ui';
import { PhoneWithCounterPrefixField } from '@user/person-form-components/phone-number';
import { PERSON_FORM_DATA_FIELDS } from '@user/person-form-data';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { F, U } from 'ts-toolbelt';
import { object } from 'yup';

const {
  application: { defaultCountry },
} = contextualConfig;

const FAST_FORM_OPTIONS = {
  enableReinitialize: false,
  cleanValuesOnReinitialize: false,
};

export class MobilePhoneRegistrationForm {
  constructor(props) {
    this.country = props?.country || '';
    this.phone = props?.phone || '';
  }

  country? = '';

  phone? = '';
}

const VALIDATION_SCHEMA = object<MobilePhoneRegistrationForm>().shape({
  [PERSON_FORM_DATA_FIELDS.country.$path]: STRING_REQUIRED,
  [PERSON_FORM_DATA_FIELDS.phone.$path]: PERSON.PHONEMOBILE_REQUIRED,
});

interface Props {
  initialValues?: U.Nullable<{ country?: string; phone?: string }>;
  onCancel?: F.Function;
}

const RegisterSmsPhoneContent: React.FC<Props> = ({
  initialValues: customInitialValues,
  onCancel,
}: Props) => {
  const isPhoneValid = useIsPhoneValid();
  const isPhoneAlreadyInUse = useIsPhoneAlreadyInUse();
  const error = useError();
  const invalidPhoneStaus =
    isPhoneValid === false || isPhoneAlreadyInUse === true;
  const [failedToValidatePhone, setFailedToValidatePhone] = useState(false);
  const validating = useIsLoading();
  const validatePhoneNumber = usePhoneLookupValidation();
  const onSubmitSuccess = useCallback(
    (data: MobilePhoneRegistrationForm) => {
      setFailedToValidatePhone(false);
      if (data?.phone && data?.country) {
        validatePhoneNumber({ phone: data.phone, country: data.country });
      }
    },
    [validatePhoneNumber],
  );
  const initialValues = useMemo(() => {
    const initial = new MobilePhoneRegistrationForm(customInitialValues);
    if (!initial.country) {
      initial.country = defaultCountry;
    }
    return initial;
  }, [customInitialValues]);

  useEffect(() => {
    if (invalidPhoneStaus) {
      setFailedToValidatePhone(true);
    }
  }, [invalidPhoneStaus]);

  return (
    <UI.Container direction="column" w="100%">
      <UI.Container m="4, 0">
        <UI.Caption i18n={I18N.DESCRIPTION} modifiers="center" />
      </UI.Container>
      <FastFormProvider
        initialValues={initialValues}
        // @ts-ignore
        validationSchema={VALIDATION_SCHEMA}
        onSubmitSuccess={onSubmitSuccess}
        options={FAST_FORM_OPTIONS}
      >
        <PhoneWithCounterPrefixField
          disabled={validating}
          withError={failedToValidatePhone}
        />
        <UI.Container direction="column" w="100%" gap={2}>
          <FastFormSubmitButton
            i18n={CONFIRM}
            textModifiers="uppercase"
            loading={validating || (!error && isPhoneValid)}
          />
          {onCancel ? (
            <UI.Button
              i18n={CANCEL}
              onClick={onCancel}
              modifiers="secondary"
              textModifiers="uppercase"
              disabled={validating || (!error && isPhoneValid)}
            />
          ) : null}
        </UI.Container>
      </FastFormProvider>
    </UI.Container>
  );
};

export default RegisterSmsPhoneContent;
