import {
  BAD_CREDENTIALS,
  CAPTCHA_BLOCKED,
  EMAIL_BLOCKED,
  INVALID_APPLICATION,
  MISSING_AUTHORITY,
  UNKNOWN_ERROR,
} from '@mmw/redux-store-payload-error/constants';
import { useNotifications } from '@mmw/ui-hybrid-libraries-notifications/hooks';
import { useEffect } from 'react';

import { useError } from '../hooks';

function ErrorNotification(): null {
  const notify = useNotifications();
  const error = useError();
  useEffect(() => {
    switch (true) {
      case error instanceof MISSING_AUTHORITY.ERROR: {
        notify(
          {
            title: MISSING_AUTHORITY.I18N.TITLE,
            content: MISSING_AUTHORITY.I18N.DESCRIPTION,
          },
          { appearance: 'error' },
        );
        break;
      }
      case error instanceof BAD_CREDENTIALS.ERROR: {
        notify(
          {
            title: BAD_CREDENTIALS.I18N.TITLE,
            content: BAD_CREDENTIALS.I18N.DESCRIPTION,
          },
          { appearance: 'error' },
        );
        break;
      }
      case error instanceof CAPTCHA_BLOCKED.ERROR: {
        notify(
          {
            title: CAPTCHA_BLOCKED.I18N.TITLE,
            content: CAPTCHA_BLOCKED.I18N.DESCRIPTION,
          },
          { appearance: 'error' },
        );
        break;
      }
      case error instanceof EMAIL_BLOCKED.ERROR: {
        notify(
          {
            title: EMAIL_BLOCKED.I18N.TITLE,
            content: EMAIL_BLOCKED.I18N.DESCRIPTION,
          },
          { appearance: 'error' },
        );
        break;
      }
      case error instanceof UNKNOWN_ERROR.ERROR: {
        notify(
          {
            title: UNKNOWN_ERROR.I18N,
            content: UNKNOWN_ERROR.I18N,
          },
          { appearance: 'error' },
        );
        break;
      }
      case error instanceof INVALID_APPLICATION.ERROR: {
        notify(
          {
            title: INVALID_APPLICATION.I18N,
            content: INVALID_APPLICATION.I18N,
          },
          { appearance: 'error' },
        );
        break;
      }
      default:
    }
  }, [error, notify]);
  return null;
}

export default ErrorNotification;
