import { Action } from '@redux-basic-module/reducer-utils/types';
import { AnyAction } from 'redux';
import { IModule } from 'redux-dynamic-modules';

export const NAMESPACE = '@@use-redux-action';

export const reducer = (state: RootState, action: AnyAction) => ({
  ...state,
  type: action.type,
  payload: action.payload,
  meta: action.meta,
});

interface RootState {
  [NAMESPACE]: Action;
}
export const REDUX_ACTION_MODULE: IModule<RootState> = {
  id: NAMESPACE,
  reducerMap: {
    [NAMESPACE]: (state, action) => ({
      ...state,
      type: action.type,
      payload: action.payload,
      meta: action.meta,
    }),
  },
};
