import LANGUAGE_CODES from '@mmw/constants-languages';

const namespace = 'language';

const I18N = {
  LANGUAGES: {
    key: `${namespace}.languages`,
    message: 'Languages',
  },
  [LANGUAGE_CODES.EN]: {
    key: `${namespace}.${LANGUAGE_CODES.EN.toLowerCase()}`,
    message: 'English',
  },
  [LANGUAGE_CODES.DE]: {
    key: `${namespace}.${LANGUAGE_CODES.DE.toLowerCase()}`,
    message: 'German',
  },
  [LANGUAGE_CODES.NL]: {
    key: `${namespace}.${LANGUAGE_CODES.NL.toLowerCase()}`,
    message: 'Dutch',
  },
  [LANGUAGE_CODES.FR]: {
    key: `${namespace}.${LANGUAGE_CODES.FR.toLowerCase()}`,
    message: 'French',
  },
  [LANGUAGE_CODES.IT]: {
    key: `${namespace}.${LANGUAGE_CODES.IT.toLowerCase()}`,
    message: 'Italian',
  },
  [LANGUAGE_CODES.ES]: {
    key: `${namespace}.${LANGUAGE_CODES.ES.toLowerCase()}`,
    message: 'Spanish',
  },
  [LANGUAGE_CODES.PL]: {
    key: `${namespace}.${LANGUAGE_CODES.PL.toLowerCase()}`,
    message: 'Polish',
  },
  [LANGUAGE_CODES.JA]: {
    key: `${namespace}.${LANGUAGE_CODES.JA.toLowerCase()}`,
    message: 'Japanese',
  },
};

export default I18N;
