import { STRING_HTML_REGEX } from '@mmw/constants-utils';
import { useI18nShapeWithPrefixKey } from '@mmw/ui-providers-resources';
import { useTranslate } from '@mmw/utils-text-utils/hooks';
import { isString } from 'lodash';
import React from 'react';

import { CustomContent, TooltipText } from './Elements';
import { ParsedContentProps } from './types';

const ParsedContent: React.FC<ParsedContentProps> = ({
  i18nContent,
  content,
}: ParsedContentProps) => {
  const translate = useTranslate();
  const customContent = isString(content)
    ? { message: content }
    : { ...content };

  const i18nWithPrefix = useI18nShapeWithPrefixKey(i18nContent);
  if (!i18nContent) return <CustomContent content={customContent} />;
  const translatedText = translate(i18nWithPrefix); // get(intl.messages, i18nWithPrefix.key as string);
  if (STRING_HTML_REGEX.test(translatedText as string)) {
    return (
      <CustomContent
        i18n={i18nWithPrefix}
        content={{ message: translatedText as string }}
      />
    );
  }
  return <TooltipText i18n={i18nWithPrefix} />;
};

ParsedContent.defaultProps = {
  i18nContent: undefined,
};

export default ParsedContent;
