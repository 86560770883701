import { connect as formikConnect } from '@mmw/ui-libraries-formik-v2';
import { compose, HOC, mapProps, withHandlers } from 'recompose';

import { WithFieldPathProps } from '../types';

// when you use a custom component that formik cant auto handleChange
const withValue: HOC<unknown, WithFieldPathProps> = compose(
  formikConnect,
  withHandlers({
    onChange:
      ({ formik: { setFieldValue }, fieldPath }) =>
      value => {
        setFieldValue(fieldPath, value);
      },
  }),
  mapProps(({ formik, onChange, ...rest }) => ({
    ...rest,
    onChange,
  })),
);

export default withValue;
