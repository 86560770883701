import { setAuthScopeNames } from '@auth/view-login/Form';
import { RETAIL_CLIENT } from '@mmw/constants-application-ids';
import {
  AUTHENTICATION_EMAIL_APPLICATION_BASE_URL,
  SET_USER_APPLICATION_ID,
} from '@mmw/constants-application-paths';
import { Mails } from '@mmw/constants-bundle-names';
import {
  AUTHENTICATION_EMAIL_BODY_RESOURCE_KEY_TOKEN,
  AUTHENTICATION_EMAIL_BUNDLE_TOKEN,
  AUTHENTICATION_EMAIL_SUBJECT_RESOURCE_KEY_TOKEN,
  RECOVER_PASSWORD_EMAIL_BODY_RESOURCE_KEY_TOKEN,
  RECOVER_PASSWORD_EMAIL_SUBJECT_RESOURCE_KEY_TOKEN,
  SET_USER_BUNDLE_TOKEN,
  UNBLOCK_ACCOUNT_EMAIL_BODY_RESOURCE_KEY_TOKEN,
  UNBLOCK_ACCOUNT_EMAIL_SUBJECT_RESOURCE_KEY_TOKEN,
} from '@mmw/constants-emails';
import { AVAILABLE_SCOPE_NAMES } from '@retail/constants-general';
import {
  setHeaderAuthenticatedContent,
  setNavMenuRoutes,
} from '@ui-system/custom-nav-menu';
import { Container } from 'typedi';

Container.set(SET_USER_BUNDLE_TOKEN, Mails);

Container.set(
  AUTHENTICATION_EMAIL_APPLICATION_BASE_URL,
  process.env.REACT_APP_APPLICATION_BASE_URL || window.location.hostname,
);

Container.set(AUTHENTICATION_EMAIL_BUNDLE_TOKEN, Mails);

Container.set(SET_USER_APPLICATION_ID, RETAIL_CLIENT);

Container.set(
  RECOVER_PASSWORD_EMAIL_BODY_RESOURCE_KEY_TOKEN,
  'mmw.recoverPasswordEmailWithUUID.content',
);
Container.set(
  RECOVER_PASSWORD_EMAIL_SUBJECT_RESOURCE_KEY_TOKEN,
  'mmw.recoverPasswordEmail.subject',
);

Container.set(
  AUTHENTICATION_EMAIL_BODY_RESOURCE_KEY_TOKEN,
  'mmw.recoverPasswordEmail.content',
);
Container.set(
  AUTHENTICATION_EMAIL_SUBJECT_RESOURCE_KEY_TOKEN,
  'mmw.recoverPasswordEmail.subject',
);

Container.set(
  UNBLOCK_ACCOUNT_EMAIL_SUBJECT_RESOURCE_KEY_TOKEN,
  'mmw.unblockAccount.subject',
);
Container.set(
  UNBLOCK_ACCOUNT_EMAIL_BODY_RESOURCE_KEY_TOKEN,
  'mmw.unblockAccount.content',
);

setNavMenuRoutes([]);

setHeaderAuthenticatedContent(() => null);

setAuthScopeNames(AVAILABLE_SCOPE_NAMES);
