import { I18nShape } from '@mmw/constants-i18n';
import { css, Padding } from '@ui-system/css';
import { ModalCard } from '@ui-system/custom-cards';
import { Style } from '@ui-system/interfaces';
import { ResponsiveProps, useResponsiveProps } from '@ui-system/media-query';
import { makeStyle, useMergedStyles } from '@ui-system/style';
import UI from '@ui-system/ui';
import React from 'react';
import { F } from 'ts-toolbelt';

const STYLE = css`
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
`;

interface CustomModalProps {
  visible: boolean;
  hide?: F.Function;
  children: React.ReactNode;
  title?: I18nShape | string;
  contentPadding?: Padding;
  modalCardStyle?: Style;
  style?: Style;
  contentStyle?: Style;
  titleModifiers?: string;
}

export const CustomModal: React.FC<CustomModalProps> = ({
  visible,
  hide,
  children,
  title,
  titleModifiers,
  contentPadding,
  contentStyle,
  modalCardStyle,
  style,
}: CustomModalProps) => {
  const modalContainerStyle = useMergedStyles(STYLE, style);
  return (
    <UI.Modal visible={visible} hideCloseButton toggle={hide}>
      <UI.Container style={modalContainerStyle}>
        <ModalCard
          title={title}
          titleModifiers={titleModifiers}
          onClose={hide}
          contentPadding={contentPadding}
          contentStyle={contentStyle}
          style={modalCardStyle}
        >
          {children}
        </ModalCard>
      </UI.Container>
    </UI.Modal>
  );
};

interface FullScreenProps {
  height?: string;
  width?: string;
}
const useFullScreenStyle = makeStyle<FullScreenProps>(
  ({ props }) => css`
    position: absolute;
    height: ${props.height || '100vh'};
    width: ${props.width || '100vw'};
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  `,
);
interface FullScreenModalProps
  extends CustomModalProps,
    ResponsiveProps<FullScreenProps>,
    FullScreenProps {
  contentPadding?: string;
}

export const FullScreenModal: React.FC<FullScreenModalProps> = ({
  visible,
  hide,
  children,
  title,
  height,
  width,
  responsive,
  contentPadding,
}: FullScreenModalProps) => {
  const responsiveProps = useResponsiveProps(responsive, { height, width });
  const style = useFullScreenStyle(responsiveProps);
  return (
    <UI.Modal visible={visible} hideCloseButton toggle={hide}>
      <UI.Container style={style}>
        <ModalCard title={title} onClose={hide} contentPadding={contentPadding}>
          <UI.Scroll
            height={responsiveProps.height || '100vh'}
            maxHeight={window.screen.availHeight - 210}
            width={responsiveProps.width || '100%'}
            maxWidth="100%"
            direction="column"
          >
            {children}
          </UI.Scroll>
        </ModalCard>
      </UI.Container>
    </UI.Modal>
  );
};
