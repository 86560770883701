import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';

import { getApiStatusStartAction } from '../actions';
import { GetApiStatusStartAction } from '../types';

const runCheckApiConnectionStatusEpic =
  (): Observable<GetApiStatusStartAction> =>
    timer(0, 40000).pipe(map(() => getApiStatusStartAction()));

export default runCheckApiConnectionStatusEpic;
