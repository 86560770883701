import { makeStyles } from '@material-ui/styles';
import { useIsMediaQueryUpToMD } from '@ui-system/media-query';
import UI from '@ui-system/ui';
import {
  SnackbarKey,
  SnackbarProvider as MUISnackbarProvider,
  useSnackbar,
} from 'notistack';
import { useCallback } from 'react';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 100,
  },
}));

interface CloseBtnProps {
  snackbarKey: SnackbarKey;
}

const CloseIcon: React.FC<CloseBtnProps> = ({ snackbarKey }: CloseBtnProps) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <UI.Icon
      name="close"
      size={15}
      onClick={() => closeSnackbar(snackbarKey)}
    />
  );
};

interface ProviderProps {
  maxSnacks?: number;
}

const ToastNotificationsProvider: React.FC<
  React.PropsWithChildren<ProviderProps>
> = ({
  children,
  maxSnacks = 3,
  ...props
}: React.PropsWithChildren<ProviderProps>) => {
  const isMobile = useIsMediaQueryUpToMD();
  const renderCloseBtn = useCallback(
    snackbarKey => <CloseIcon snackbarKey={snackbarKey} />,
    [],
  );
  const classes = useStyles();
  return (
    <MUISnackbarProvider
      action={renderCloseBtn}
      maxSnack={maxSnacks}
      classes={isMobile ? classes : undefined}
      {...props}
    >
      {children}
    </MUISnackbarProvider>
  );
};

export default ToastNotificationsProvider;
