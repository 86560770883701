/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Pagination } from '@mmw/services-core-common/types';
import { createAsyncActions } from '@redux-async-module/actions-utils';
import { createAction } from '@reduxjs/toolkit';

import {
  createSearchTypes,
  SearchModuleActionTypes,
} from './createSearchTypes';
import { PaginatePayload, RequestStartPayload, SearchMeta } from './types';

export function createSearchActionsByTypes<
  RequestFields,
  ItemType,
  Meta extends SearchMeta = SearchMeta,
>(namespace: string, actionName: string, types: SearchModuleActionTypes) {
  const asyncActions = createAsyncActions<
    RequestStartPayload<RequestFields>,
    Pagination<ItemType>,
    Error,
    Meta
  >(namespace, actionName);

  const paginate = createAction(
    types.PAGINATE,
    (payload: PaginatePayload, meta: Meta) => ({
      payload,
      meta,
    }),
  );

  const resetRequestFields = createAction(
    types.RESET_REQUEST_FIELDS,
    (meta: Meta) => ({
      payload: undefined,
      meta,
    }),
  );

  const changeLimit = createAction(
    types.CHANGE_LIMIT,
    (limit: number, meta: Meta) => ({
      payload: limit,
      meta,
    }),
  );

  return {
    ...asyncActions,
    paginate,
    resetRequestFields,
    changeLimit,
  };
}

export function createSearchActions<
  RequestFields,
  ItemType,
  Meta extends SearchMeta = SearchMeta,
>(namespace: string, actionName: string) {
  const types = createSearchTypes(namespace, actionName);
  return createSearchActionsByTypes<RequestFields, ItemType, Meta>(
    namespace,
    actionName,
    types,
  );
}

// XXX: this is to find implicit types
// wrapped has no explicit return type so we can infer it
class Wrapper<RequestFields, ItemType, Meta extends SearchMeta = SearchMeta> {
  // eslint-disable-next-line class-methods-use-this
  createSearchActions(namespace: string, actionName: string) {
    return createSearchActions<RequestFields, ItemType, Meta>(
      namespace,
      actionName,
    );
  }
}

export type SearchActions<
  RequestFields,
  ItemType,
  Meta extends SearchMeta = SearchMeta,
> = ReturnType<Wrapper<RequestFields, ItemType, Meta>['createSearchActions']>;
