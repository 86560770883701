import leftArrowStyle from './arrowLeftStyle';
import rightArrowStyle from './arrowRightStyle';
import itemsCarouselStyle from './style';

export default {
  style: itemsCarouselStyle,
  leftArrow: {
    style: leftArrowStyle,
  },
  rightArrow: {
    style: rightArrowStyle,
  },
};
