import { useContext } from 'react';
import { __RouterContext, ContextRouter } from 'react-router';
import invariant from 'tiny-invariant';

const useRouter = function useRouter(): ContextRouter {
  const context: ContextRouter = useContext(__RouterContext);
  invariant(
    context,
    'You should not use useRouter() or other hooks outside a <Router>',
  );
  return context;
};

export default useRouter;
