// import { createSelector } from 'reselect';
import { NAMESPACE, RootState, State } from './types';

export const loadingSelector = (state: RootState): State['loading'] =>
  state[NAMESPACE].loading;

export const errorSelector = (state: RootState): State['error'] =>
  state[NAMESPACE].error;

export const useSendMailStatusSelector = (
  state: RootState,
): State['sendEmailStatus'] => state[NAMESPACE].sendEmailStatus;

export const notAuthenticatedOperationTokenSelector = (
  state: RootState,
): State['notAuthenticatedOperationToken'] =>
  state[NAMESPACE].notAuthenticatedOperationToken;
