export default () => `
  cursor: pointer;
  text-decoration: none;
  outline: 0;
  transition: 0.4s;
  & span, p {
    transition: 0.4s;
  }
  &:visited {
    color: inherit;
  }
  &:hover {
    text-decoration: none;
  }
`;
