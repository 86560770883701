import defaultApiV2, { ApiResponse } from '@mmw/api-v2';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import autoBind from 'auto-bind';

import {
  getAgreementBranchStatusUrl,
  getAgreementStatusPath,
  subscribeAgreementPath,
  subscribeBranchAgreementUrl,
} from './apiPaths';
import logger from './log';
import { Agreement } from './types';

type Api = typeof defaultApiV2;

type TraderAgreementServiceOptions = {
  apiv2?: Api;
  authenticationService: AuthenticationService;
};

class TraderAgreementService {
  api: Api;

  authenticationService: AuthenticationService;

  constructor({ apiv2, authenticationService }: TraderAgreementServiceOptions) {
    this.api = apiv2 || defaultApiV2;
    this.authenticationService = authenticationService;
    autoBind(this);
  }

  async getBranchAgreement(
    language: string,
    campaignId: number,
    storeId: number,
  ): Promise<Agreement> {
    logger.debug(`Trying to get campaign agreement status=${campaignId}`);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Agreement> = await this.api.get(
        getAgreementBranchStatusUrl(language, campaignId, storeId),
        {
          headers,
        },
      );
      const { data } = response;
      logger.info(`Successfully got campaign agreement status=${campaignId}`);
      return data;
    } catch (error) {
      logger.error(
        `Error when getting campaign agreement status=${campaignId}, error=%O`,
        error,
      );
      throw error;
    }
  }

  async subscribeBranchAgreement(
    language: string,
    campaignId: number,
    storeId: number,
  ): Promise<Agreement> {
    logger.debug(`Trying to subscribe campaign agreement=${campaignId}`);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Agreement> = await this.api.post(
        subscribeBranchAgreementUrl(language, campaignId, storeId),
        {},
        {
          headers,
        },
      );
      const { data } = response;
      logger.info(`Successfully subscribed campaign agreement=${campaignId}`);
      return data;
    } catch (error) {
      logger.error(
        `Error when trying to subscribe campaign agreement=${campaignId}, error=%O`,
        error,
      );
      throw error;
    }
  }

  async subscribeAgreement(
    language: string,
    contractName: string,
    orgunitID: number,
  ): Promise<Agreement> {
    logger.debug(`Trying to subscribe agreement=${contractName}`);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Agreement> = await this.api.post(
        subscribeAgreementPath(language, contractName, orgunitID),
        {},
        {
          headers,
        },
      );
      const { data } = response;
      logger.info(`Successfully subscribed agreement=${contractName}`);
      return data;
    } catch (error) {
      logger.error(
        `Error when trying to subscribe agreement=${contractName}, error=%O`,
        error,
      );
      throw error;
    }
  }

  async getAgreementStatus(
    language: string,
    contractName: string,
    orgunitID: number,
  ): Promise<Agreement> {
    logger.debug(`Trying to get agreement status=${contractName}`);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Agreement> = await this.api.get(
        getAgreementStatusPath(language, contractName, orgunitID),
        {
          headers,
        },
      );
      const { data } = response;
      logger.info(`Successfully got agreement status=${contractName}`);
      return data;
    } catch (error) {
      logger.error(
        `Error when getting agreement status=${contractName}, error=%O`,
        error,
      );
      throw error;
    }
  }
}

export default TraderAgreementService;
