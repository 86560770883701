import checkboxLabelStyle from './checkboxLabelStyle';
import labelTextStyle from './labelTextStyle';
import modifiers from './modifiers';
import style from './style';
import textContainer from './textContainer';

export default {
  style,
  label: {
    style: checkboxLabelStyle,
    text: {
      style: labelTextStyle,
      modifiers,
    },
    container: {
      style: textContainer,
    },
    modifiers,
  },
  modifiers,
};
