import { O } from 'ts-toolbelt';
// eslint-disable-next-line import/prefer-default-export
export const NAMESPACE = 'spinner';

export type State = {
  globalVisible: boolean;
};

export type RootState = {
  spinner: State;
};

export type SpinnerMeta<T extends O.Object | null = O.Object> = {
  showGlobalSpinner?: boolean;
} & T;

export type WithSpinnerMeta = {
  meta?: SpinnerMeta;
};

export type Action<Payload> = {
  type: string;
  payload: Payload;
} & WithSpinnerMeta;

export type Reducer = (state: State, action: Action<unknown>) => State;
