import { Title } from '@mmw/services-core-common/types';

export const GET_AVAILABLE_TITLES_START =
  '@mmw/available-salutations/GET_AVAILABLE_TITLES_START';
export const GET_AVAILABLE_TITLES_SUCCESS =
  '@mmw/available-salutations/GET_AVAILABLE_TITLES_SUCCESS';
export const GET_AVAILABLE_TITLES_ERROR =
  '@mmw/available-salutations/GET_AVAILABLE_TITLES_ERROR';

export type GetAvailableTitlesStartAction = {
  type: '@mmw/available-salutations/GET_AVAILABLE_TITLES_START';
};

export type GetAvailableTitlesSuccessAction = {
  type: '@mmw/available-salutations/GET_AVAILABLE_TITLES_SUCCESS';
  payload: {
    availableTitles: Array<Title>;
  };
};

export type GetAvailableTitlesErrorAction = {
  type: '@mmw/available-salutations/GET_AVAILABLE_TITLES_ERROR';
  payload: {
    error: Error;
  };
};

export type State = {
  loading: boolean;
  availableTitles: Array<Title>;
  error: Error | null;
};

export type Action =
  | GetAvailableTitlesStartAction
  | GetAvailableTitlesSuccessAction
  | GetAvailableTitlesErrorAction;

export type Reducer = (state: State, action: Action) => State;
