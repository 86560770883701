import { createDI } from '@di/core';
import defaultApiV2 from '@mmw/api-v2';
import logger from '@mmw/logging-logger';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import autoBind from 'auto-bind';
import { AxiosResponse } from 'axios';

import {
  GetManuProductsCampaignsPath,
  GetManuProductsClassOfGoods,
  GetManuProductsPath,
} from './apiPaths';
import { Product, ProductCampaign } from './types';

type Api = typeof defaultApiV2;

type ManuProductsServiceOptions = {
  apiv2?: Api;
  authenticationService: AuthenticationService;
};

class ManuProductsService {
  api: Api;

  authenticationService: AuthenticationService;

  static createDI = createDI<ManuProductsService>;

  constructor({ apiv2, authenticationService }: ManuProductsServiceOptions) {
    this.api = apiv2 || defaultApiV2;
    this.authenticationService = authenticationService;
    autoBind(this);
  }

  async searchProducts(request, language): Promise<any> {
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: AxiosResponse<any> = await this.api.post(
        GetManuProductsPath(request, language),
        request,
        { headers },
      );
      const products: Product[] = response.data.list;

      if (!products) return [];

      const productIds = products.map(product => product.productID);

      const campaignsResponse: AxiosResponse<any> = await this.api.post(
        GetManuProductsCampaignsPath({ productIDs: productIds }, language),
        { productIDs: productIds },
        { headers },
      );
      const campaigns: ProductCampaign[][] = campaignsResponse.data;

      if (!campaigns) return products;

      const productsWithCampaigns = products.map((product, index) => ({
        ...product,
        campaigns: campaigns[index].filter(
          campaign => campaign.type === 'DRAW',
        ),
      }));

      return productsWithCampaigns;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  }

  async searchProductsCampaigns(request, language): Promise<any> {
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: AxiosResponse<any> = await this.api.post(
        GetManuProductsCampaignsPath(request, language),
        request,
        { headers },
      );
      return response.data.list;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  }

  async searchProductsClassOfGoods(request, language): Promise<any> {
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: AxiosResponse<any> = await this.api.get(
        GetManuProductsClassOfGoods(request, language),
        { headers },
      );
      return response.data;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  }

  async searchProductsGood(request, language): Promise<any> {
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: AxiosResponse<any> = await this.api.post(
        GetManuProductsPath(request, language),
        request,
        { headers },
      );
      const { data } = response;
      return data;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  }
}

export default ManuProductsService;
