export default {
  overlay: {
    style: ({ visible }) => `
      height: 100%;
      width: 100%;
      z-index: 1050;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      align-items: center;
      justify-content: center;
      background-color: rgba(0,0,0,0.5);
      display: ${visible ? 'flex' : 'none'}
    `,
  },
  svg: {
    style: ({ theme }) => `
      animation: rotate 2s linear infinite;
      margin: -25px 0 0 -25px;
      width: 100px;
      height: 100px;
      & .path {
        stroke: ${theme.colors.pallet.light};
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
      }
      @keyframes rotate {
        100% {
          transform: rotate(360deg);
        }
      }
      @keyframes dash {
        0% {
          stroke-dasharray: 1, 150;
          stroke-dashoffset: 0;
        }
        50% {
          stroke-dasharray: 90, 150;
          stroke-dashoffset: -35;
        }
        100% {
          stroke-dasharray: 90, 150;
          stroke-dashoffset: -124;
        }
      }
    `,
  },
};
