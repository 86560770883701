import { use } from '@mmw/ui-theme/utils';
import * as React from 'react';
import styled from 'styled-components';

const StyledNavbar = styled.div(props => use(props, 'page.navbar'));

const NavbarContent = styled.div(props => use(props, 'page.navbar.content'));

type Props = {
  children?: React.ReactNode;
};

const NavBar: React.FC<Props> = ({ children }: Props) => (
  <StyledNavbar>
    <NavbarContent>{children}</NavbarContent>
  </StyledNavbar>
);

export default NavBar;
