import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import { getConsumerMembershipService } from '@mmw/services-holder';
import { ActionsObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import {
  deleteConsumerAccountErrorAction,
  deleteConsumerAccountSuccessAction,
} from '../actions';
import {
  DELETE_CONSUMER_ACCOUNT_START,
  DeleteConsumerAccountErrorAction,
  DeleteConsumerAccountStartAction,
  DeleteConsumerAccountSuccessAction,
} from '../types';

const { logger } = contextualConfig.application;

type Output =
  | DeleteConsumerAccountSuccessAction
  | DeleteConsumerAccountErrorAction;

const deleteConsumerAccountEpic = (
  action$: ActionsObservable<DeleteConsumerAccountStartAction>,
): Observable<Output> =>
  action$.pipe(
    ofType(DELETE_CONSUMER_ACCOUNT_START),
    tap(() => logger.debug('Will try to delete consumer account')),
    switchMap(() =>
      from(getConsumerMembershipService().deleteConsumerAccount()).pipe(
        map(data => deleteConsumerAccountSuccessAction(data.success)),
        catchError(error => of(deleteConsumerAccountErrorAction(error))),
      ),
    ),
  );

export default deleteConsumerAccountEpic;
