import contextualConfig from '@mmw/contextual-config';
import { APP_START, AppStartAction } from '@mmw/redux-action-app-start';
import ofType from '@mmw/redux-rx-of-type-operator';
import { checkAuthenticationStartAction } from '@mmw/redux-store-auth-api-authentication/actions';
import { getAvailableCountriesStartAction } from '@mmw/redux-store-common-available-countries/actions';
import { getAvailableSalutationsStartAction } from '@mmw/redux-store-common-available-salutations/actions';
import { getAvailableTitlesStartAction } from '@mmw/redux-store-common-available-titles/actions';
import { getLegalFormListStartAction } from '@mmw/redux-store-common-legal-form-list/actions';
import { ActionsObservable } from 'redux-observable';
import { empty, interval, Observable, of } from 'rxjs';
import { concat, map, startWith, switchMap, tap } from 'rxjs/operators';

const { checkAuthenticationTimeout: CHECK_AUTHENTICATION_TIMEOUT, logger } =
  contextualConfig.application;

const onAppStartEpic = (
  action$: ActionsObservable<AppStartAction>,
): Observable<any> =>
  action$.pipe(
    ofType(APP_START),
    tap(() => logger.info('App Started, will load data')),
    switchMap(() =>
      empty().pipe(
        concat(
          of(getAvailableCountriesStartAction()),
          of(getAvailableSalutationsStartAction()),
          of(getAvailableTitlesStartAction()),
          of(getLegalFormListStartAction()),
          interval(CHECK_AUTHENTICATION_TIMEOUT).pipe(
            startWith(0),
            map(() => checkAuthenticationStartAction()),
          ),
        ),
      ),
    ),
  );

export default onAppStartEpic;
