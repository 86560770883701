import {
  CREATE_CONSUMER_ACCOUNT_ERROR,
  CREATE_CONSUMER_ACCOUNT_START,
  CREATE_CONSUMER_ACCOUNT_SUCCESS,
  DELETE_CONSUMER_ACCOUNT_ERROR,
  DELETE_CONSUMER_ACCOUNT_START,
  DELETE_CONSUMER_ACCOUNT_SUCCESS,
  ORGUNIT_SIGNUP_ERROR,
  ORGUNIT_SIGNUP_START,
  ORGUNIT_SIGNUP_SUCCESS,
  Reducer,
  RESET_ACTION,
} from './types';

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: null,
  success: null,
};

const accountCreationReducer: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ORGUNIT_SIGNUP_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ORGUNIT_SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ORGUNIT_SIGNUP_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case CREATE_CONSUMER_ACCOUNT_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_CONSUMER_ACCOUNT_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: null,
      };
    case CREATE_CONSUMER_ACCOUNT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case DELETE_CONSUMER_ACCOUNT_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_CONSUMER_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
      };
    case DELETE_CONSUMER_ACCOUNT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case RESET_ACTION:
      return {
        ...state,
        loading: false,
        error: null,
        success: null,
      };
    default:
      return state;
  }
};

export default accountCreationReducer;
