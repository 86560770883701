import mmwConfig from '@mmw/config';
import contextualConfig from '@mmw/contextual-config';
import * as React from 'react';

import { ReCaptchaV3Context } from './Context';
import { Config } from './types';
import { useReCaptchaProviderValue } from './useReCaptchaProviderValue';

interface Props {
  config?: Config;
  children: React.ReactNode;
}
export const ReCaptchaV3ContextProvider: React.FC<Props> = ({
  config,
  children,
}: Props) => {
  const value = useReCaptchaProviderValue(config as Config);
  return (
    <ReCaptchaV3Context.Provider value={value}>
      {children}
    </ReCaptchaV3Context.Provider>
  );
};

const { applicationId } = contextualConfig.application;
const { publickey } = mmwConfig.recaptcha;

ReCaptchaV3ContextProvider.defaultProps = {
  config: {
    siteKey: publickey,
    applicationId,
    captchaDomain: mmwConfig.api.host,
  },
};
