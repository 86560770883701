import { Color } from '@ui-system/css';
import {
  SwitchSelectorProps,
  SwitchSelectorType,
} from '@ui-system/interfaces-switch-selector';
import { makeStyle } from '@ui-system/style';
import UI from '@ui-system/ui';
import { get } from 'lodash';
import React, { useMemo } from 'react';

const SIZE = 24;

const useOutterStyle = makeStyle<{ length: number; bordered: boolean }>(
  ({ css, props: { length, bordered }, theme }) => css`
    position: absolute;
    width: ${SIZE * length}px;
    height: ${SIZE}px;
    border-radius: ${SIZE}px;
    ${bordered &&
    `box-shadow: 0 0 0 1.5pt ${theme.colors.lightMode.gray.A400};`}
  `,
);
const useInnerStyle = makeStyle<{ offset: number }>(
  ({ css, props: { offset }, theme }) => css`
    position: absolute;
    left: ${offset}%;
    width: ${SIZE}px;
    height: ${SIZE}px;
    border-radius: ${SIZE}px;
  `,
);

const SwitchSelector: SwitchSelectorType = ({
  options,
  value,
  bordered,
  onClick,
}: SwitchSelectorProps) => {
  const { length } = options;
  const offset = (value * 100) / length;
  const outterStyle = useOutterStyle({ length, bordered });
  const innerStyle = useInnerStyle({ offset });
  const activeColor = useMemo(
    () =>
      get(
        options.find(o => o.value === value),
        'activeColor',
        'info',
      ),
    [options, value],
  );
  // XXX: This component was wrongly implement and did not follow the interface
  return (
    <UI.Container h={SIZE} w={SIZE * length} onClick={() => onClick(0)}>
      <UI.Container style={outterStyle} bg="white">
        <UI.Container style={innerStyle} bg={activeColor as Color} />
      </UI.Container>
    </UI.Container>
  );
};

export default SwitchSelector;
