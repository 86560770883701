import validations from '@mmw/common-validations';
// getNotAvoidedCharacters(without(INVALID_INPUT_CHARACTERS, '&'))
import { INVALID_INPUT_CHARACTERS } from '@mmw/common-validations/constants';
import I18N, { I18nShape } from '@mmw/constants-i18n';
import { isEmpty, size } from 'lodash';
import without from 'lodash/without';
import { TestConfig, TestContext } from 'yup';

const {
  VALIDATIONS: { DEFAULT: MESSAGE },
} = I18N;

const {
  check: {
    hasNumbers,
    hasThreeRepetitions,
    hasAvoidedWords,
    hasAvoidedCharacters,
    hasOnlyZeros,
    hasAvoidedAloneCharacter,
    moreThanOneNotRepeatedChar,
    getHasAvoidedCharacters,
  },
} = validations;

export const SPECIAL_ALONE_CHARACTERS = {
  name: 'validationTestSpecialAloneCharacters',
  message: MESSAGE.HAS_AVOIDED_ALONE_CHARACTER.key,
  test: function specialAloneCharacters(value: string) {
    return !hasAvoidedAloneCharacter(value);
  },
};

export const SPECIAL_CHARACTERS = {
  name: 'validationTestSpecialCharacters',
  message: MESSAGE.HAS_AVOIDED_CHARACTERS.key,
  test: function specialCharacters(value: string) {
    return !hasAvoidedCharacters(value);
  },
};

export const ANONIMIZED_DATA = {
  name: 'validationTestAnonimizedData',
  message: MESSAGE.INVALID_INPUTS.key,
  test: function anonimizedData(value: string) {
    return !hasAvoidedCharacters(value, ['*']);
  },
};

export const HAS_MORE_THAN_ONE_NOT_REPEATED_CHAR = {
  name: 'hasMoreThanOneNotRepeatedChar',
  message: MESSAGE.MIN.key,
  test: function test(value: string): boolean {
    return !moreThanOneNotRepeatedChar(value);
  },
  params: { min: 3 },
};

export const SPECIAL_WORDS = {
  name: 'validationTestSpecialWords',
  message: MESSAGE.HAS_AVOIDED_WORDS.key,
  test: function specialWords(value: string) {
    return !hasAvoidedWords(value);
  },
};

export const SPECIAL_CHARACTERS_AND_WORDS = {
  name: 'validationTestSpecialCharactersAndWords',
  message: MESSAGE.HAS_AVOIDED_CHARACTERS_AND_WORDS.key,
  test: function specialCharactersAndWords(value: string) {
    return !hasAvoidedCharacters(value) && !hasAvoidedWords(value);
  },
};

export const REQUIRED_FOR_US = {
  name: 'requiredForUS',
  message: MESSAGE.REQUIRED.key,
  test: function requiredForUS(value: string, obj: TestContext) {
    if (!obj.parent?.country) {
      return true;
    }
    const { country } = obj.parent;
    if (country === 'US') {
      return !isEmpty(value);
    }
    return true;
  },
};

export const SPECIAL_CHARACTERS_AND_WORDS_FOR_ADDRESS_NR: TestConfig = {
  name: 'validationTestSpecialCharactersAndWords',
  message: MESSAGE.HAS_AVOIDED_CHARACTERS_AND_WORDS.key,
  test: function specialCharactersAndWords(value: string) {
    return (
      !getHasAvoidedCharacters(without(INVALID_INPUT_CHARACTERS, '/'))(value) &&
      !hasAvoidedWords(value)
    );
  },
};

export const SPECIAL_CHARACTERS_AND_WORDS_FOR_ADDRESS_STREET: TestConfig = {
  name: 'validationTestSpecialCharactersAndWords',
  message: MESSAGE.HAS_AVOIDED_CHARACTERS_AND_WORDS.key,
  test: function specialCharactersAndWords(value: string) {
    return (
      !getHasAvoidedCharacters(without(INVALID_INPUT_CHARACTERS, '/'))(value) &&
      !hasAvoidedWords(value)
    );
  },
};

export const SPECIAL_CHARACTERS_AND_WORDS_FOR_ADDRESS_CITY: TestConfig = {
  name: 'validationTestSpecialCharactersAndWords',
  message: MESSAGE.HAS_AVOIDED_CHARACTERS_AND_WORDS.key,
  test: function specialCharactersAndWords(value: string) {
    return (
      !getHasAvoidedCharacters(
        without(INVALID_INPUT_CHARACTERS, '/', '(', ')'),
      )(value) && !hasAvoidedWords(value)
    );
  },
};

type GetMinLenghtOrEmpty = ({
  messageKey,
  min,
}: {
  messageKey: I18nShape['key'];
  min: number;
}) => TestConfig<unknown, any>;

export const getMinLenghtOrEmpty: GetMinLenghtOrEmpty = ({
  messageKey,
  min,
}) => ({
  name: 'getMinLenghtOrEmpty',
  message: messageKey,
  params: { min },
  test: function test(value: string) {
    if (!value) return true;
    return size(value) >= min;
  },
});

export const NUMBERS = {
  name: 'validationTestNumber',
  message: MESSAGE.HAS_NUMBER.key,
  test: function hasNumber(value: string) {
    return !hasNumbers(value);
  },
};

export const CHARACTER_REPETITION = {
  name: 'validationTestRepeatCharacter',
  message: MESSAGE.HAS_CHARACTER_REPETITION.key,
  test: function repeatCharacter(value: string) {
    return !hasThreeRepetitions(value);
  },
  params: { maxRepetition: 3 },
};

export const ONLY_ZEROS = {
  name: 'validationTestOnlyZeros',
  message: MESSAGE.HAS_ONLY_ZEROS.key,
  test: function onlyZeros(value: string) {
    return !hasOnlyZeros(value);
  },
};
