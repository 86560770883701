import arrowLeftStyle from './arrowLeftStyle';
import arrowRightStyle from './arrowRightStyle';
import modifiers from './modifiers';
import style from './style';
import wrapperStyle from './wrapperStyle';

export default {
  style,
  wrapper: {
    style: wrapperStyle,
  },
  leftArrow: {
    style: arrowLeftStyle,
  },
  rightArrow: {
    style: arrowRightStyle,
  },
  modifiers,
};
