import arrowLeftStyle from './arrowLeftStyle';
import arrowRightStyle from './arrowRightStyle';
import navLinkStyle from './navLinkStyle';
import style from './style';

export default {
  style,
  navLink: {
    style: navLinkStyle,
  },
  leftArrow: {
    style: arrowLeftStyle,
  },
  rightArrow: {
    style: arrowRightStyle,
  },
};
