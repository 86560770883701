import { StyledProps } from '@mmw/web-theme';

export default ({ theme, disabled }: StyledProps<{ disabled: boolean }>) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  height: 100%;
  padding: 30px;
  cursor: ${disabled ? 'normal' : 'pointer'};
  opacity: ${disabled ? 0.3 : 1};
  box-sizing: border-box;
  background-color: ${theme.colors.pallet.basicLight};
  color: ${theme.colors.pallet.dark};
  transition: 0.4s;
  ${
    !disabled
      ? `
    &:hover {
      background-color: ${theme.colors.pallet.primary};
      color: ${theme.colors.pallet.light};
      a, span, p {
        color: ${theme.colors.pallet.light};
      }
    }
  `
      : ''
  }
  &.active {
    background-color: ${theme.colors.pallet.primary};
    color: ${theme.colors.pallet.light};
    a, span, p {
      background-color: ${theme.colors.pallet.primary};
      color: ${theme.colors.pallet.light};
    }
  }
`;
