import { enableBatching } from 'redux-batched-actions';

import createGetLoginConfigByEmailOrUserid from './epics-creator/getLoginConfigByEmailOrUserid';
import authenticationReducer from './reducer';
import { NAMESPACE as authenticationNamespace } from './types';

export const reducer = authenticationReducer;
export const NAMESPACE = authenticationNamespace;

export function getLoginConfigByUserModule() {
  return {
    id: NAMESPACE,
    reducerMap: {
      // @ts-ignore
      [NAMESPACE]: enableBatching(reducer),
    },
    epics: [createGetLoginConfigByEmailOrUserid],
  };
}
