import background from '@mmw/ui-theme/modifiers/background';
import { StyledProps } from '@mmw/ui-theme/types';
import selectTheme from '@mmw/ui-web-elements-select/theme';

const style = ({ theme }: StyledProps<{}>) => `
  ${theme.form.variable.baseElementStyle({ theme })};
  ${theme.form.variable.border({ theme })};
  ${theme.form.variable.focus({ theme })};
  ${background.image(theme.assets.DROP_ICON)};
  ${selectTheme.style()};
  border-radius: 0;
  appearance: none;
  min-height: 40px;
`;

const modifiers = {
  error: ({ theme }: StyledProps<unknown>) => `
    color: ${theme.colors.pallet.error};
    ${background.image(
      [theme.assets.DROP_ICON, theme.assets.alertIcon],
      ['0', '40'],
    )}
    ${theme.media.smallPhones(
      background.image(
        [theme.assets.DROP_ICON, theme.assets.alertIcon],
        ['0', '40'],
        'contain, 25px',
      ),
    )}
  `,
  light: ({ theme }: StyledProps<unknown>) => `
    background-color: ${theme.colors.pallet.light};
  `,
};

export default {
  style,
  modifiers,
};
