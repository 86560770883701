import map from 'lodash/map';
import size from 'lodash/size';
import some from 'lodash/some';
import split from 'lodash/split';
import toLower from 'lodash/toLower';
import uniq from 'lodash/uniq';

import {
  INVALID_INPUT_ALONE_CHARACTERS,
  INVALID_INPUT_CHARACTERS,
  MAX_CHARACTER_REPETITION,
  NO_INFO_WORDS,
  NUMBERS,
} from './constants';
import { composedBySequence, includes, repeat } from './string';

export function hasNumbers(str: string): boolean {
  return includes(str, NUMBERS);
}

export function getHasAvoidedWords(words: string[]) {
  return function hasAvoidWords(str: string): boolean {
    const input = map(split(str, ' '), toLower);
    const wordsToCheck = map(words, toLower);
    return some(wordsToCheck, item =>
      some(input, inputItem => inputItem === item),
    );
  };
}

export function hasAvoidedWords(
  str: string,
  avoid: string[] = NO_INFO_WORDS,
): boolean {
  return getHasAvoidedWords(avoid)(str);
}

export function hasThreeRepetitions(str: string): boolean {
  return repeat(str, MAX_CHARACTER_REPETITION);
}

export function getHasAvoidedCharacters(chars: string[]) {
  return function hasAvoidedChars(str: string): boolean {
    return includes(str, chars);
  };
}

export function hasAvoidedCharacters(
  str: string,
  avoid: string[] = INVALID_INPUT_CHARACTERS,
): boolean {
  return getHasAvoidedCharacters(avoid)(str);
}

export function hasAvoidedAloneCharacter(
  str: string,
  avoid: string[] = INVALID_INPUT_ALONE_CHARACTERS,
): boolean {
  if (!str) return false;
  return str.length === 1 && includes(str, avoid);
}

export function moreThanOneNotRepeatedChar(str: string): boolean {
  if (!str) return false;
  return size(uniq(str)) === 1;
}

export function hasOnlyZeros(str: string): boolean {
  return composedBySequence(str, '0');
}
