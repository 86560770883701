import { CountryCode } from '@mmw/constants-country-codes';

type IbanRegex = Partial<Record<CountryCode, RegExp>>;
export type IbanMask = (RegExp | string)[];

type IbanMasks = Partial<Record<CountryCode, IbanMask>>;

const IBAN_REGEX: IbanRegex = {
  DE: /^DE\d{2}\s?([0-9a-zA-Z]{4}\s?){4}[0-9a-zA-Z]{2}$/i,
  AT: /^AT\d{2}\s?([0-9a-zA-Z]{4}\s?){4}$/i,
};

const IBAN_MASKS: IbanMasks = {
  DE: [
    /(d|D)/,
    /(e|E)/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
  ],
  AT: [
    /(a|A)/,
    /(t|T)/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
};

export { IBAN_MASKS, IBAN_REGEX };

// module.exports =
//   patterns =
//     #Austria - Example: AT61 1904 3002 3457 3201
//     'AT': '^AT[0-9]{2} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}$'
//     #UAE - Example: AE29 3704 0044 0532 0130 000
//     'AE': 'AE[0-9]{2} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{3}'
//     #Belgium - Example: BE68 5390 0754 7034
//     'BE': '^BE[0-9]{2} [0-9]{4} [0-9]{4} [0-9]{4}$'
//     #Bulgaria - Example: BG80 BNBG 9661 1020 3456 78
//     'BG': 'BG[0-9]{2} [A-Za-z]{4} [0-9]{4} [0-9]{2}[0-9A-Za-z]{2} [0-9A-Za-z]{4} [0-9A-Za-z]{2}'
//     #Croatia - Example: HR12 1001 0051 8630 0016 0
//     'HR': 'HR[0-9]{2} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{1}'
//     #Cyprus - Example: CY17 0020 0128 0000 0012 0052 7600
//     'CY': 'CY[0-9]{2} [0-9]{4} [0-9]{4} [0-9A-Za-z]{4} [0-9A-Za-z]{4} [0-9A-Za-z]{4} [0-9A-Za-z]{4}'
//     #Czech Republic - Example: CZ65 0800 0000 1920 0014 5399
//     'CZ': 'CZ[0-9]{2} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}'
//     #Denmark - Example: DK50 0040 0440 1162 43
//     'DK': 'DK[0-9]{2} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{2}'
//     #Estonia - Example: EE38 2200 2210 2014 5685
//     'EE': 'EE[0-9]{2} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}'
//     #Finland - Example: FI21 1234 5600 0007 85
//     'FI': 'FI[0-9]{2} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{2}'
//     #France - Example: FR14 2004 1010 0505 0001 3M02 606
//     'FR': 'FR[0-9]{2} [0-9]{4} [0-9]{4} [0-9]{2}[0-9A-Za-z]{2} [0-9A-Za-z]{4} [0-9A-Za-z]{4} [0-9A-Za-z]{1}[0-9]{2}'
//     #Germany - Example: DE89 3704 0044 0532 0130 00
//     'DE': 'DE[0-9]{2} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{2}'
//     #Greece - Example: GR16 0110 1250 0000 0001 2300 695
//     'GR': 'GR[0-9]{2} [0-9]{4} [0-9]{3}[0-9A-Za-z]{1} [0-9A-Za-z]{4} [0-9A-Za-z]{4} [0-9A-Za-z]{4} [0-9A-Za-z]{3}'
//     #Hungary - Example: HU42 1177 3016 1111 1018 0000 0000
//     'HU': 'HU[0-9]{2} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}'
//     #Ireland - Example: IE29 AIBK 9311 5212 3456 78
//     'IE': 'IE[0-9]{2} [A-Za-z]{4} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{2}'
//     #Italy - Example: IT60 X054 2811 1010 0000 0123 456
//     'IT': 'IT[0-9]{2} [A-Za-z]{1}[0-9]{3} [0-9]{4} [0-9]{3}[0-9A-Za-z]{1} [0-9A-Za-z]{4} [0-9A-Za-z]{4} [0-9A-Za-z]{3}'
//     #Latvia - Example: LV80 BANK 0000 4351 9500 1
//     'LV': 'LV[0-9]{2} [A-Za-z]{4} [0-9A-Za-z]{4} [0-9A-Za-z]{4} [0-9A-Za-z]{4} [0-9A-Za-z]{1}'
//     #Liechtenstein - Example: LI21 0881 0000 2324 013A A
//     'LI': 'LI[0-9]{2} [0-9]{4} [0-9]{1}[0-9A-Za-z]{3} [0-9A-Za-z]{4} [0-9A-Za-z]{4} [0-9A-Za-z]{4}{1}'
//     #Lithuania - Example: LT12 1000 0111 0100 1000
//     'LT': 'LT[0-9]{2} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}'
//     #Luxembourg - Example: LU28 0019 4006 4475 0000
//     'LU': 'LU[0-9]{2} [0-9]{3}[0-9A-Za-z]{1} [0-9A-Za-z]{4} [0-9A-Za-z]{4} [0-9A-Za-z]{4}'
//     #Malta - Example: MT84 MALT 0110 0001 2345 MTLC AST0 01S
//     'MT': 'MT[0-9]{2} [A-Za-z]{4} [0-9]{4} [0-9]{1}[0-9A-Za-z]{3} [0-9A-Za-z]{4} [0-9A-Za-z]{4} [0-9A-Za-z]{4} [0-9A-Za-z]{3}'
//     #The Netherlands - Example: NL91 ABNA 0417 1643 00
//     'NL': 'NL[0-9]{2} [A-Za-z]{4} [0-9]{4} [0-9]{4} [0-9]{2}'
//     #Poland - Example: PL61 1090 1014 0000 0712 1981 2874
//     'PL': 'PL[0-9]{2} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}'
//     #Portugal - Example: PT50 0002 0123 1234 5678 9015 4
//     'PT': 'PT[0-9]{2} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{1}'
//     #Romania - Example: RO49 AAAA 1B31 0075 9384 0000
//     'RO': 'RO[0-9]{2} [A-Za-z]{4} [0-9A-Za-z]{4} [0-9A-Za-z]{4} [0-9A-Za-z]{4} [0-9A-Za-z]{4}'
//     #Slovakia - Example: SK31 1200 0000 1987 4263 7541
//     'SK': 'SK[0-9]{2} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}'
//     #Slovenia - Example: SI56 2633 0001 2039 086
//     'SI': 'SI[0-9]{2} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{3}'
//     #Spain - Example: ES91 2100 0418 4502 0005 1332
//     'ES': 'ES[0-9]{2} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}'
//     #Sweden - Example: SE45 5000 0000 0583 9825 7466
//     'SE': 'SE[0-9]{2} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}'
//     #Switzerland - Example: CH93 0076 2011 6238 5295 7
//     'CH': 'CH[0-9]{2} [0-9]{4} [0-9]{1}[0-9A-Za-z]{3} [0-9A-Za-z]{4} [0-9A-Za-z]{4} [0-9A-Za-z]{1}'
//     #United Kingdom - Example: GB29 NWBK 6016 1331 9268 19
//     'GB': 'GB[0-9]{2} [A-Za-z]{4} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{2}'
//     #Jordan - Example: JO94 CBJO 0010 0000 0000 0131 0003 02
//     'JO': 'JO[0-9]{2} [A-Za-z]{4} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{2}'
//     #Monaco - Example: MC58 1122 2000 0101 2345 6789 030
//     'MC': 'MC[0-9]{2} [0-9]{4} [0-9]{4} [0-9]{2}[0-9A-Za-z]{2} [0-9A-Za-z]{4} [0-9A-Za-z]{4} [0-9A-Za-z]{1}[0-9]{2}'
//     #Andorra - Example: AD12 0001 2030 2003 5910 0100
//     'AD': 'AD[0-9]{2} [0-9]{4} [0-9]{4} [0-9A-Za-z]{4} [0-9A-Za-z]{4} [0-9A-Za-z]{4}'
//     #San Marino - Example: SM86 U032 2509 8000 0000 0270 100
//     'SM': 'SM[0-9]{2} [A-Za-z]{1}[0-9]{3} [0-9]{4} [0-9]{3}[0-9A-Za-z]{1} [0-9A-Za-z]{4} [0-9A-Za-z]{4} [0-9A-Za-z]{3}'
//     #Norway - Example: NO93 8601 1117 947
//     'NO': 'NO[0-9]{2} [0-9]{4} [0-9]{4} [0-9]{3}'
//     #Iceland - Example: IS14 0159 2600 7654 5510 7303 39
//     'IS': 'IS[0-9]{2} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{2}'
