import { isDate, isString } from 'lodash';
import moment from 'moment';

const readValueAsString = value => {
  const momentDate = moment(value, 'YYYY-MM-DD');
  if (momentDate.isValid()) {
    return momentDate.toDate();
  }
  return null;
};

export const convertValueToValidDate = (
  value: Date | number | void | string | null,
) => {
  if (!value) {
    return null;
  }
  if (isDate(value)) {
    return value;
  }
  if (isString(value)) {
    return readValueAsString(value);
  }
  return value;
};

export default convertValueToValidDate;
