import { I18nShape } from '@mmw/constants-i18n';
import { InputProps } from '@ui-system/interfaces-form';
import UI from '@ui-system/ui';
import React, { useCallback, useState } from 'react';
import { U } from 'ts-toolbelt';

import FastFormInput from './FastFormInput';

const ACCESSORY_VIEW_ID = 'buttonId';
const ICON_STYLE = { position: 'absolute', right: 10 };
interface Props {
  fieldPath: string;
  placeholder?: U.Nullable<string | I18nShape>;
  [props: string]: any;
}
const FastFormPasswordInput: React.FC<Props & Partial<InputProps>> = ({
  fieldPath,
  placeholder,
  ...rest
}: Props & Partial<InputProps>) => {
  const [passSecurity, setPassSecurity] = useState(true);
  const togglePassSecurity = useCallback(
    () => setPassSecurity(prevState => !prevState),
    [setPassSecurity],
  );

  return (
    <>
      <FastFormInput
        fieldPath={fieldPath}
        placeholder={placeholder}
        placeholderSizeLimit={28}
        autoCapitalize="none"
        autoCorrect={false}
        secureTextEntry={passSecurity}
        inputAccessoryViewID={ACCESSORY_VIEW_ID}
        textContentType="none"
        clearTextOnFocus={false}
        accessoryRight={
          <UI.Container
            style={ICON_STYLE}
            justify="center"
            align="center"
            p="2, 0, 2, 3"
            onClick={togglePassSecurity}
          >
            <UI.Icon name={passSecurity ? 'hide' : 'view'} size={20} />
          </UI.Container>
        }
        {...rest}
      />
    </>
  );
};

export default FastFormPasswordInput;
