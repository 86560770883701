import {
  CreateIssueJSON,
  IssueActionRequired,
  IssueResolutionMode,
  IssueStatus,
  IssueValueObject,
} from '@issue/interfaces';
import { U } from '@utils/ts';
import filter from 'lodash/filter';
import find from 'lodash/find';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';

import { ContextIssueType, IssueLocation } from './types';

type Issue = U.Nullable<CreateIssueJSON | ContextIssueType | IssueValueObject>;

export function hasFileIDInThisIssue(issue: Issue, fileID: number): boolean {
  return !issue ? false : includes(issue?.data?.fileIDs, fileID);
}
export function hasFileIDInThisIssues(
  issues: Issue[],
  fileID: number,
): boolean {
  return !isEmpty(find(issues, issue => hasFileIDInThisIssue(issue, fileID)));
}

export function returnIssuesWithoutFileID<I extends Issue>(
  issues: I[],
  fileID: number,
): I[] {
  return filter(issues, issue => !includes(issue?.data?.fileIDs, fileID));
}

export function hasQuestionGroupCodeInThisIssue(
  issue: Issue,
  questionGroupCode: string,
): boolean {
  return !issue
    ? false
    : includes(issue?.data?.questionGroupCodes, questionGroupCode);
}

export function hasCampaignItemIDInThisIssue(
  issue: Issue,
  campaignItemID: number,
): boolean {
  return !issue
    ? false
    : includes(issue?.data?.campaignItemIDs, campaignItemID);
}

export function hasRegistrationItemIDInThisIssue(
  issue: Issue,
  registrationItemID: number,
): boolean {
  return !issue
    ? false
    : includes(issue?.data?.registrationItemIDs, registrationItemID);
}

export function findIssueByFileID(
  issues: ContextIssueType[],
  fileID: number,
  possibleStatus: IssueStatus[],
) {
  return find(
    issues,
    issue =>
      hasFileIDInThisIssue(issue, fileID) &&
      includes(possibleStatus, issue?.status),
  );
}

type FindIssueByQuestionGroupCodeOptions = {
  isActionRequired?: boolean;
  actionRequired?: IssueActionRequired;
};

const EMPTY_OBJECT = {
  isActionRequired: true,
  actionRequired: IssueActionRequired.CORRECT_QUESTION_ANSWER,
};

export function findIssueByQuestionGroupCode(
  issues: ContextIssueType[],
  questionGroupCode: string,
  possibleStatus: IssueStatus[],
  options: FindIssueByQuestionGroupCodeOptions = EMPTY_OBJECT,
) {
  const { isActionRequired, actionRequired } = options;
  const checkActionRequired = issue =>
    isActionRequired ? issue?.actionRequired === actionRequired : true;
  return find(
    issues,
    issue =>
      hasQuestionGroupCodeInThisIssue(issue, questionGroupCode) &&
      includes(possibleStatus, issue?.status) &&
      checkActionRequired(issue),
  );
}

export function filterIssueByQuestionGroupCode(
  issues: ContextIssueType[],
  questionGroupCode: string,
  possibleStatus: IssueStatus[],
  options: FindIssueByQuestionGroupCodeOptions = EMPTY_OBJECT,
): ContextIssueType[] {
  const { isActionRequired, actionRequired } = options;
  const checkActionRequired = issue =>
    isActionRequired ? issue?.actionRequired === actionRequired : true;
  return filter(
    issues,
    issue =>
      hasQuestionGroupCodeInThisIssue(issue, questionGroupCode) &&
      includes(possibleStatus, issue?.status) &&
      checkActionRequired(issue),
  );
}

export function filterIssueByUserId(
  issues: ContextIssueType[],
  loggedUserId: number,
  possibleStatus: IssueStatus[],
): ContextIssueType[] {
  return filter(
    issues,
    issue =>
      issue?.assigneeID === loggedUserId &&
      includes(possibleStatus, issue.status),
  );
}

export function convertCreatedIssueToContextIssue(
  issue: IssueValueObject,
): ContextIssueType {
  return {
    location: IssueLocation.API,
    id: issue.id,
    data: issue.data,
    summary: issue.summary,
    status: issue.status,
    type: issue.type,
    actionRequired: issue.actionRequired,
    resolutionMode: issue.resolutionMode,
    dueDate: (issue.dueDate as unknown as Date) || null,
    created: (issue.created as unknown as Date) || null,
    assigneeID: issue.assigneeID || null,
  };
}

export function convertCreateIssueToContextIssue(
  issue: CreateIssueJSON,
): ContextIssueType {
  return {
    location: IssueLocation.LOCAL,
    id: Date.now(),
    data: { ...issue.data },
    summary: issue.summary,
    status: issue.initialStatus || IssueStatus.TODO,
    type: issue.type,
    actionRequired: issue.actionRequired,
    resolutionMode:
      issue.initialResolutionMode || IssueResolutionMode.UNRESOLVED,
    dueDate: (issue.dueDate as unknown as Date) || null,
    created: new Date(),
    assigneeID: issue.assigneeID || null,
  };
}
