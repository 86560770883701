/* eslint-disable @typescript-eslint/ban-types */
import { FastFormProvider } from '@mmw/redux-store-fast-form';
import React, { ReactElement, ReactNode } from 'react';
import { ObjectSchema } from 'yup';

type SignInPropsType<T extends object> = {
  children: ReactNode;
  validationSchema: ObjectSchema<T>;
  onSubmitSuccess: (values: T) => void;
  initialValues: T;
};

function SignIn<T extends object>({
  children,
  validationSchema,
  onSubmitSuccess,
  initialValues,
}: SignInPropsType<T>): ReactElement {
  return (
    <FastFormProvider<T>
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmitSuccess={onSubmitSuccess}
    >
      {children}
    </FastFormProvider>
  );
}

export default SignIn;
