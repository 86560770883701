import { I18nShape } from '@mmw/constants-i18n';
import { css, Padding } from '@ui-system/css';
import { Style } from '@ui-system/interfaces';
import { TypographyVariant } from '@ui-system/interfaces-typography';
import { makeStyle } from '@ui-system/style';
import UI from '@ui-system/ui';
import deepMerge from 'deepmerge';
import React from 'react';

interface Props {
  description: I18nShape | string;
  icon?: string;
  descriptionModifiers?: string;
  descriptionVariant?: TypographyVariant;
  style?: Style;
  children?: React.ReactNode;
  contentPadding?: Padding;
  Action?: React.FC;
}

const STYLE = css`
  width: 100%;
`;

const CONTENT_STYLE = css`
  height: 100%;
`;

const useStyle = makeStyle<Partial<Props>>(({ props: { style } }) => {
  if (!style) return STYLE;
  return deepMerge(STYLE, style);
});
export const IconDescriptionAction: React.FC<Props> = ({
  description,
  descriptionModifiers,
  descriptionVariant,
  children,
  Action,
  contentPadding,
  style,
  icon,
}: Props) => {
  const cardStyle = useStyle({ style });
  return (
    <UI.Card style={cardStyle}>
      <UI.Card.Content style={CONTENT_STYLE}>
        <UI.Container
          h="100%"
          p={contentPadding}
          direction="column"
          justify="space-between"
        >
          <UI.Container>
            <UI.Container gap={2}>
              <UI.Container>
                <UI.Icon size={50} name={icon} color="primary" />
              </UI.Container>
              <UI.Typography
                i18n={description}
                modifiers={descriptionModifiers}
                variant={descriptionVariant}
              />
            </UI.Container>
          </UI.Container>
          {children && children}
        </UI.Container>
      </UI.Card.Content>
      {Action && (
        <UI.Card.Actions>
          <Action />
        </UI.Card.Actions>
      )}
    </UI.Card>
  );
};

IconDescriptionAction.defaultProps = {
  icon: 'question',
  contentPadding: '0',
  descriptionVariant: 'caption',
};
