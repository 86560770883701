import setupApiLogger from '@mmw/configure-api-logging';
import { getRetailClientVersion } from '@mmw/contextual-config';
import noop from 'lodash/noop';
import { F } from 'ts-toolbelt';

const versionDescriptionObject = {
  version: getRetailClientVersion(),
};

export default (store: { dispatch: F.Function; getState: F.Function }) => {
  // @ts-ignore
  setupApiLogger({ store, versionDescriptionObject }).then(noop);
};
