import { SimpleAsyncState } from '@redux-async-module/reducer-utils';

import { SuccessPayload } from './actions';

export type State = SimpleAsyncState<SuccessPayload>;

export const NAMESPACE = 'update-files-by-type';

export type RootState = {
  [NAMESPACE]: State;
};
