// PNGs
import greenCheck from './green-check.png';
import logo from './logo.png';
import alertIcon from './red-alert.png';

// SVGs
export * from './icons';

const assets = {
  logo,
  greenCheck,
  alertIcon,
};

// export assets you want to override from framework
export { assets as default, logo };
