import { CacheInterface } from '@mmw/common-cache';
import { StoreInterface } from '@mmw/common-store';
import contextualConfig from '@mmw/contextual-config';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import { AuthenticationServiceOptions } from '@mmw/services-auth-api-authentication/types';
import {
  getClientFingerprintService,
  setAuthenticationService,
} from '@mmw/services-holder';
import { U } from 'ts-toolbelt';

const { applicationId, defaultLanguage } = contextualConfig.application;

type Options = {
  cache: CacheInterface | StoreInterface;
  bundleName: string;
  TAN_EMAIL_SUBJECT_RESOURCEKEY?: U.Nullable<string>;
  TAN_EMAIL_BODY_RESOURCEKEY?: U.Nullable<string>;
  REQUEST_EMAIL_VERIFICATION_SUBJECT_RESOURCEKEY?: U.Nullable<string>;
  REQUEST_EMAIL_VERIFICATION_EMAIL_BODY_RESOURCEKEY?: U.Nullable<string>;
  REQUEST_PHONE_VERIFICATION_BY_SMS_RESOURCEKEY?: U.Nullable<string>;
  RECOVER_PASSWORD_EMAIL_BODY_RESOURCEKEY?: U.Nullable<string>;
  RECOVER_PASSWORD_EMAIL_SUBJECT_RESOURCEKEY?: U.Nullable<string>;
  UNBLOCK_ACCOUNT_EMAIL_BODY_RESOURCEKEY?: U.Nullable<string>;
  UNBLOCK_ACCOUNT_EMAIL_SUBJECT_RESOURCEKEY?: U.Nullable<string>;
  RECOVER_USERNAME_EMAIL_BODY_RESOURCEKEY?: U.Nullable<string>;
  RECOVER_USERNAME_EMAIL_SUBJECT_RESOURCEKEY?: U.Nullable<string>;
} & Partial<AuthenticationServiceOptions>;

const setupAuthentication = ({
  cache,
  bundleName,
  TAN_EMAIL_SUBJECT_RESOURCEKEY,
  TAN_EMAIL_BODY_RESOURCEKEY,
  REQUEST_EMAIL_VERIFICATION_SUBJECT_RESOURCEKEY,
  REQUEST_EMAIL_VERIFICATION_EMAIL_BODY_RESOURCEKEY,
  REQUEST_PHONE_VERIFICATION_BY_SMS_RESOURCEKEY,
  RECOVER_PASSWORD_EMAIL_BODY_RESOURCEKEY,
  RECOVER_PASSWORD_EMAIL_SUBJECT_RESOURCEKEY,
  UNBLOCK_ACCOUNT_EMAIL_BODY_RESOURCEKEY,
  UNBLOCK_ACCOUNT_EMAIL_SUBJECT_RESOURCEKEY,
  RECOVER_USERNAME_EMAIL_SUBJECT_RESOURCEKEY,
  RECOVER_USERNAME_EMAIL_BODY_RESOURCEKEY,
  ...rest
}: Options): void => {
  const authenticationService = new AuthenticationService({
    applicationId,
    cache,
    clientFingerprint: getClientFingerprintService(),
    tanEmailConfiguration:
      TAN_EMAIL_SUBJECT_RESOURCEKEY != null &&
      TAN_EMAIL_BODY_RESOURCEKEY != null
        ? {
            subjectResourcekey: TAN_EMAIL_SUBJECT_RESOURCEKEY,
            bodyResourcekey: TAN_EMAIL_BODY_RESOURCEKEY,
            bundleName,
            language: defaultLanguage,
          }
        : undefined,
    requestEmailVerificationEmailConfiguration:
      REQUEST_EMAIL_VERIFICATION_SUBJECT_RESOURCEKEY != null &&
      REQUEST_EMAIL_VERIFICATION_EMAIL_BODY_RESOURCEKEY != null
        ? {
            subjectResourcekey: REQUEST_EMAIL_VERIFICATION_SUBJECT_RESOURCEKEY,
            bodyResourcekey: REQUEST_EMAIL_VERIFICATION_EMAIL_BODY_RESOURCEKEY,
            bundleName,
            language: defaultLanguage,
          }
        : undefined,

    requestPhoneVerificationSmsConfiguration:
      REQUEST_PHONE_VERIFICATION_BY_SMS_RESOURCEKEY != null
        ? {
            resourcekey: REQUEST_PHONE_VERIFICATION_BY_SMS_RESOURCEKEY,
            bundleName,
            language: defaultLanguage,
          }
        : undefined,
    recoverPasswordEmailConfiguration:
      RECOVER_PASSWORD_EMAIL_SUBJECT_RESOURCEKEY != null &&
      RECOVER_PASSWORD_EMAIL_BODY_RESOURCEKEY != null
        ? {
            subjectResourcekey: RECOVER_PASSWORD_EMAIL_SUBJECT_RESOURCEKEY,
            bodyResourcekey: RECOVER_PASSWORD_EMAIL_BODY_RESOURCEKEY,
            bundleName,
            language: defaultLanguage,
          }
        : undefined,
    recoverUsernameEmailConfiguration:
      RECOVER_USERNAME_EMAIL_SUBJECT_RESOURCEKEY != null &&
      RECOVER_USERNAME_EMAIL_BODY_RESOURCEKEY != null
        ? {
            subjectResourcekey: RECOVER_USERNAME_EMAIL_SUBJECT_RESOURCEKEY,
            bodyResourcekey: RECOVER_USERNAME_EMAIL_BODY_RESOURCEKEY,
            bundleName,
            language: defaultLanguage,
          }
        : undefined,
    unblockAccountEmailConfiguration:
      UNBLOCK_ACCOUNT_EMAIL_SUBJECT_RESOURCEKEY != null &&
      UNBLOCK_ACCOUNT_EMAIL_BODY_RESOURCEKEY != null
        ? {
            subjectResourcekey: UNBLOCK_ACCOUNT_EMAIL_SUBJECT_RESOURCEKEY,
            bodyResourcekey: UNBLOCK_ACCOUNT_EMAIL_BODY_RESOURCEKEY,
            bundleName,
            language: defaultLanguage,
          }
        : undefined,
    allowedRoleStatus: {
      consumer: false,
      trader: true,
      manufacturer: false,
      mmw: false,
      solar: false,
    },
    ...rest,
  });
  setAuthenticationService(authenticationService);
};

export default setupAuthentication;
