import qs from 'qs';

const BASE_PATH = '/v1/trader/productgroup';

export const GetProductGroupsByCampaignPath = (
  campaignId: number,
  language: string,
) =>
  `${BASE_PATH}/campaign/${campaignId}?${qs.stringify({
    language,
  })}`;
