import { createReducer } from '@redux-basic-module/reducer-utils/ReducerBuilder';

import {
  changePasswordByOperationToken,
  getConsumerProfileData,
  resetProfileDataAction,
  resetSuccessFlagsAction,
  saveConsumerProfileData,
} from './actions';
import { ProfileOperationType, Reducer } from './types';

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: null,
  requestAttempted: false,
  changePassword: {
    success: false,
  },
  success: false,
  operationType: null,
};

const reducer: Reducer = createReducer(INITIAL_STATE, builder => {
  builder
    .addCase(getConsumerProfileData.start, draft => ({
      ...draft,
      loading: true,
      requestAttempted: true,
      operationType: ProfileOperationType.getProfileData,
    }))
    .addCase(getConsumerProfileData.success, (draft, { payload }) => ({
      ...draft,
      data: payload,
      loading: false,
    }))
    .addCase(getConsumerProfileData.error, (draft, { payload }) => ({
      ...draft,
      error: payload,
      loading: false,
    }))
    .addCase(saveConsumerProfileData.start, draft => ({
      ...draft,
      loading: true,
      requestAttempted: true,
      success: false,
      operationType: ProfileOperationType.saveProfileData,
    }))
    .addCase(saveConsumerProfileData.success, (draft, { payload }) => ({
      ...draft,
      data: payload,
      loading: false,
      success: true,
    }))
    .addCase(saveConsumerProfileData.error, (draft, { payload }) => ({
      ...draft,
      error: payload,
      loading: false,
    }))
    .addCase(changePasswordByOperationToken.start, draft => ({
      ...draft,
      changePassword: {
        success: false,
      },
      loading: true,
      requestAttempted: true,
      operationType: ProfileOperationType.changePassword,
    }))
    .addCase(changePasswordByOperationToken.success, (draft, { payload }) => ({
      ...draft,
      changePassword: {
        success: payload,
      },
      error: null,
      loading: false,
    }))
    .addCase(changePasswordByOperationToken.error, (draft, { payload }) => ({
      ...draft,
      error: payload,
      loading: false,
    }))
    .addCase(resetSuccessFlagsAction, draft => ({
      ...draft,
      requestAttempted: false,
      success: false,
      changePassword: {
        success: false,
      },
      error: null,
      operationType: null,
    }))
    .addCase(resetProfileDataAction, () => INITIAL_STATE);
});

export default reducer;
