import { apply } from '@mmw/ui-theme/modifiers';
import borderSchemas from '@mmw/ui-theme/modifiers/color/border';
import { StyledProps } from '@mmw/ui-theme/types';
import dateInputStyle from '@mmw/ui-web-materials-date-input/theme/style';
import wrapperStyleBuilder from '@mmw/ui-web-materials-date-input/theme/wrapperStyleBuilder';

const modifiers = apply(borderSchemas);

const wrapperCustomStyle = ({
  theme,
  datePanelPosition,
}: StyledProps<unknown>) =>
  wrapperStyleBuilder({
    wrapper: {
      fontFamily: theme.fonts.fontFamily,
      backgroundColor: theme.colors.pallet.light,
      color: theme.colors.pallet.secondary,
    },
    caption: {
      backgroundColor: theme.colors.pallet.background,
    },
    weekday: {
      color: theme.colors.pallet.primary,
    },
    todayButton: {
      backgroundColor: theme.colors.pallet.primary,
      color: theme.colors.pallet.basicLight,
      borderRadius: 0,
    },
    day: {
      color: theme.colors.basicLight.darken(0.5),
      borderRadius: 0,
      today: {
        color: theme.colors.pallet.primary,
      },
      outside: {
        color: theme.colors.pallet.basicDark,
        hover: {
          backgroundColor: theme.colors.pallet.primary,
          color: theme.colors.pallet.basicLight,
        },
      },
      disabled: {
        color: theme.colors.pallet.basic,
        // hover: {
        //   color: theme.colors.pallet.basic,
        // },
      },
      sunday: {
        backgroundColor: theme.colors.pallet.primary,
        not: {
          color: theme.colors.pallet.primary,
        },
      },
      selected: {
        backgroundColor: theme.colors.pallet.primary,
        color: theme.colors.pallet.basicLight,
        hover: {
          color: theme.colors.pallet.primary,
          backgroundColor: theme.colors.pallet.basicLight,
        },
      },
    },
    interactionDisabled: {
      backgroundColor: theme.colors.pallet.primary,
      color: theme.colors.pallet.basicLight,
    },
    overlay: {
      position: datePanelPosition,
      backgroundColor: theme.colors.pallet.light,
    },
  });

export default {
  style: dateInputStyle,
  wrapper: {
    style: wrapperCustomStyle,
  },
  leftArrow: {
    style: ({ theme }) => `
      background: url(${theme.assets.DROP_ICON}) no-repeat transparent;
      float: left;
      width: 40px;
      height: 40px;
      border: none;
      position: absolute;
      left: 0;
      transform: rotate(90deg);
      cursor: pointer;
  `,
  },
  rightArrow: {
    style: ({ theme }) => `
      background: url(${theme.assets.DROP_ICON}) no-repeat transparent;
      float: right;
      width: 40px;
      height: 40px;
      border: none;
      position: absolute;
      right: 0;
      transform: rotate(-90deg);
      cursor: pointer;
  `,
  },
  modifiers,
};
