import {
  TAN_CODE_VALIDATION_SCHEMA,
  TanCodeValidationForm,
} from '@auth/form-data';
import ResendTanCode from '@auth/view-form-components/actions/ResendTanCode';
import TanCodeField from '@auth/view-form-components/TanCodeField';
import { FastFormSubmitButton } from '@fast-form/ui-system';
import { I18nShape } from '@mmw/constants-i18n';
import { CANCEL, NEXT } from '@mmw/constants-i18n-buttons';
import { FastFormProvider } from '@mmw/redux-store-fast-form';
import { InputProps } from '@ui-system/interfaces/form';
import { ContainerProps } from '@ui-system/interfaces-container';
import { useIsMediaQueryUpToSM } from '@ui-system/media-query';
import UI from '@ui-system/ui';
import toUpper from 'lodash/toUpper';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { F, U } from 'ts-toolbelt';

const INITIAL_VALUES = new TanCodeValidationForm();
const FORM_OPTIONS = {
  enableReinitialize: false,
};
const RESEND_CODE_STYLE = {
  alignSelf: 'flex-start',
};

export interface Props extends ContainerProps {
  fieldLabel?: U.Nullable<I18nShape | string>;
  fieldPlaceholder?: U.Nullable<I18nShape | string>;
  buttonI18n?: U.Nullable<I18nShape>;
  buttonStyle?: Record<string, any>;
  onSuccess: F.Function<[any]>;
  onResendRequest: F.Function;
  loading?: boolean;
  onCancel?: F.Function;
  inputProps?: Partial<InputProps>;
  operationInProcess?: boolean;
  successStatus?: boolean;
}

const TanCodeValidation: React.FC<Props> = ({
  fieldLabel,
  fieldPlaceholder,
  buttonStyle,
  onSuccess,
  onResendRequest,
  loading,
  onCancel,
  inputProps,
  operationInProcess,
  successStatus,
  buttonI18n = NEXT,
  ...props
}: Props) => {
  const [resendCodeClicked, setResendClicked] = useState(false);
  const isMobile = useIsMediaQueryUpToSM();
  const shouldBlockActions =
    resendCodeClicked || loading || operationInProcess || successStatus;
  const mergedInputProps = useMemo(() => {
    if (inputProps?.disabled) {
      return {
        disabled: inputProps?.disabled || shouldBlockActions,
        ...inputProps,
      };
    }
    return {
      disabled: shouldBlockActions,
      ...inputProps,
    };
  }, [inputProps, shouldBlockActions]);

  const onClickResend = useCallback(() => {
    onResendRequest();
    setResendClicked(true);
  }, [setResendClicked, onResendRequest]);

  const onSubmitTanCodeSuccess = useCallback(
    data => {
      onSuccess({ tanCode: toUpper(data.tanCode) });
    },
    [onSuccess],
  );

  useEffect(() => {
    if (shouldBlockActions) {
      setResendClicked(false);
    }
  }, [operationInProcess, shouldBlockActions]);

  return (
    <FastFormProvider<TanCodeValidationForm>
      // @ts-ignore
      validationSchema={TAN_CODE_VALIDATION_SCHEMA}
      initialValues={INITIAL_VALUES}
      onSubmitSuccess={onSubmitTanCodeSuccess}
      options={FORM_OPTIONS}
    >
      <UI.Container w="100%" direction="column" {...props}>
        <TanCodeField
          label={fieldLabel}
          placeholder={fieldPlaceholder}
          textAlign="center"
          required={false}
          {...mergedInputProps}
        />
        <UI.Container w="100%" justify="flex-end">
          <ResendTanCode
            style={RESEND_CODE_STYLE}
            useLink
            p="2, 0, 4, 0"
            textModifiers="uppercase, bold"
            loading={shouldBlockActions}
            onClick={onClickResend}
          />
        </UI.Container>
        <UI.Container
          direction={isMobile && onCancel ? 'column' : 'row'}
          justify={isMobile && onCancel ? 'flex-start' : 'space-between'}
          gap={isMobile && onCancel ? 2 : 0}
        >
          {onCancel ? (
            <UI.Button
              i18n={CANCEL}
              onClick={onCancel}
              modifiers="secondary"
              disabled={shouldBlockActions}
            />
          ) : null}
          <FastFormSubmitButton
            style={buttonStyle}
            i18n={buttonI18n}
            loading={shouldBlockActions}
          />
        </UI.Container>
      </UI.Container>
    </FastFormProvider>
  );
};

export default TanCodeValidation;
