export default ({ theme }) => `
  padding: 0;
  fill: ${theme.colors.pallet.primary};
  &:first-child {
    margin-right: 5px;
  }
  &:last-child {
    margin-left: 5px;
  }
  &:not(:first-child):not(:last-child) {
    margin: 0 5px;
  }
  :hover {
    cursor: pointer;
  };
`;
