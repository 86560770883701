import { languageQuery, loginUrlQuery } from '@mmw/utils-query-utils';
import qs from 'qs';

const BASE_PATH = '/v1/trader/employees';
const PROFILE_BASE_PATH = '/v1/trader/profile';

export const EmployeesPath = (): string => `${BASE_PATH}`;

export const DeleteEmployeesPath = `${BASE_PATH}/delete-users`;

export const EmployeeDetailsPath = (
  employeeID: number,
  language?: string,
  loginUrl?: string,
): string =>
  `${BASE_PATH}/${employeeID}/details?${languageQuery(
    language,
  )}&${loginUrlQuery(loginUrl)}`;

export const CreateEmployeePath = (
  language: string,
  loginUrl: string,
): string =>
  `${BASE_PATH}/create?${languageQuery(language)}&${loginUrlQuery(loginUrl)}`;

export const AvailableAssignmentsPath = (
  language: string,
  onlyBrandSpecificPositions?: boolean,
): string =>
  `${PROFILE_BASE_PATH}/available-assignments?${languageQuery(
    language,
  )}&${qs.stringify({
    onlyBrandSpecificPositions,
  })}`;
