import contextualConfig from '@mmw/contextual-config';
import React from 'react';
import MetaTagsLib from 'react-meta-tags';

interface Props {
  fontUrl?: string;
}
export const MetaTags: React.FC<Props> = ({ fontUrl }) => {
  const { displayName } = contextualConfig.application;
  return (
    <MetaTagsLib>
      {displayName && <title>{displayName}</title>}
      <meta charSet="utf-8" />
      <meta
        httpEquiv="Cache-Control"
        content="no-cache, no-store, must-revalidate"
      />
      <meta httpEquiv="Pragma" content="no-cache" />
      <meta httpEquiv="Expires" content="0" />
      <link rel="shortcut icon" href="favicon.ico" />
      {fontUrl && <link href={fontUrl} rel="stylesheet" />}
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no"
      />
      <meta name="theme-color" content="#000000" />
    </MetaTagsLib>
  );
};
