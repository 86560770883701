import { PageProps } from '@ui-system/interfaces-page';
import UI from '@ui-system/ui';
import React, { PropsWithChildren } from 'react';

import Content from './elements/Content';
import ExpansibleArea from './elements/ExpansibleArea';
import Footer from './elements/Footer';
import Header from './elements/Header';

type Props = PropsWithChildren<PageProps>;

type StaticPage = {
  Header: typeof Header;
  Content: typeof Content;
  Footer: typeof Footer;
  ExpansibleArea: typeof ExpansibleArea;
};

const Page: React.FC<Props> & StaticPage = ({ children, ...props }: Props) => (
  <UI.Page {...props}>
    <UI.Page.Header>
      <Header />
    </UI.Page.Header>
    {children}
    <UI.Page.Footer h={460} responsive="upToSm.h=auto">
      <Footer />
    </UI.Page.Footer>
  </UI.Page>
);

Page.Header = Header;
Page.Content = Content;
Page.Footer = Footer;
Page.ExpansibleArea = ExpansibleArea;

export default Page;
