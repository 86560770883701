import { UploadRegistrationFilesResultJSON } from '@mmw/services-core-trader-registration/types';
import { createAsyncActions } from '@redux-async-module/actions-utils';

import { NAMESPACE } from './types';

export interface StartPayload {
  fileID: number;
  registrationID: number;
}

export type SuccessPayload = UploadRegistrationFilesResultJSON & StartPayload;

export const removeFileById = createAsyncActions<
  StartPayload,
  SuccessPayload,
  Error,
  null
>(NAMESPACE, 'remove-file-by-id');
