export enum FileType {
  AFFIDAVIT = 'AFFIDAVIT',
  COVER_PHOTO = 'COVER_PHOTO',
  DATA_PRIVACY = 'DATA_PRIVACY',
  DOCUMENT = 'DOCUMENT',
  IBV0 = 'IBV0',
  IBV1 = 'IBV1',
  IBV2 = 'IBV2',
  INVOICE = 'INVOICE',
  MINUTES_OF_THE_CONSULTATION = 'MINUTES_OF_THE_CONSULTATION',
  ONE_SNAP_INVOICE = 'ONE_SNAP_INVOICE',
  PHOTO = 'PHOTO',
  PROOF_OF_SELLOUT = 'PROOF_OF_SELLOUT',
  PROOF_OF_SERVICE = 'PROOF_OF_SERVICE',
  QUESTION_ATTACHMENT = 'QUESTION_ATTACHMENT',
  SERVICE_DOCUMENT = 'SERVICE_DOCUMENT',
  UNKNOWN = 'UNKNOWN',
  WARRANTY = 'WARRANTY',
  WARRANTYCERT = 'WARRANTYCERT',
  PHYSICAL_CONTRACT = 'PHYSICAL_CONTRACT',
}

export const {
  AFFIDAVIT,
  COVER_PHOTO,
  DATA_PRIVACY,
  DOCUMENT,
  IBV0,
  IBV1,
  IBV2,
  INVOICE,
  MINUTES_OF_THE_CONSULTATION,
  ONE_SNAP_INVOICE,
  PHOTO,
  PROOF_OF_SELLOUT,
  PROOF_OF_SERVICE,
  SERVICE_DOCUMENT,
  UNKNOWN,
  WARRANTY,
  WARRANTYCERT,
  PHYSICAL_CONTRACT,
} = FileType;

export const VALUES = [
  AFFIDAVIT,
  COVER_PHOTO,
  DATA_PRIVACY,
  DOCUMENT,
  IBV0,
  IBV1,
  IBV2,
  INVOICE,
  MINUTES_OF_THE_CONSULTATION,
  ONE_SNAP_INVOICE,
  PHOTO,
  PROOF_OF_SELLOUT,
  PROOF_OF_SERVICE,
  SERVICE_DOCUMENT,
  UNKNOWN,
  WARRANTY,
  WARRANTYCERT,
  PHYSICAL_CONTRACT,
];
