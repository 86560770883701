import { isIE11 } from '@mmw/constants-devices';
import { I18nShape } from '@mmw/constants-i18n';
import { H6 } from '@mmw/ui-web-elements-typography';
import React from 'react';
import styled from 'styled-components';

import {
  Overlay as GlobalOverlay,
  SvgSpinner as GlobalSvgSpinner,
} from './elements/Global';
import {
  Overlay as InternalOverlay,
  SvgSpinner as InternalSvgSpinner,
} from './elements/Internal';
import SpinnerGIF from './elements/spinner.gif';

const CustomGif = styled.img`
  width: 80px;
  height: 80px;
`;

const Message = styled(H6)`
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 1);
  color: #fff;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 100%;
`;

const Circle = () => (
  <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
);

type Props = {
  visible: boolean;
};

type InternalSpinnerProps = {
  height?: string;
  color?: string;
};

const GifSpinner = () => <CustomGif src={SpinnerGIF} alt="loading spinner" />;

const InternalSpinner = ({
  visible,
  height,
  color,
}: Props & InternalSpinnerProps) => (
  <>
    {visible && (
      <InternalOverlay visible={visible} height={height}>
        {(isIE11 && <GifSpinner />) || (
          <InternalSvgSpinner viewBox="0 0 50 50" color={color}>
            <Circle />
          </InternalSvgSpinner>
        )}
      </InternalOverlay>
    )}
  </>
);
InternalSpinner.defaultProps = {
  height: undefined,
};
const GlobalSpinner: React.FC<Props & { loadingMessageI18n?: I18nShape }> = ({
  visible,
  loadingMessageI18n,
}: Props & { loadingMessageI18n?: I18nShape }) => (
  <GlobalOverlay visible={visible}>
    <ContentContainer>
      {(isIE11 && <GifSpinner />) || (
        <GlobalSvgSpinner viewBox="0 0 50 50">
          <Circle />
        </GlobalSvgSpinner>
      )}
      {loadingMessageI18n ? <Message i18n={loadingMessageI18n} /> : null}
    </ContentContainer>
  </GlobalOverlay>
);

const Spinner = {
  InternalSpinner,
  GlobalSpinner,
};

export { Spinner as default, GlobalSpinner, InternalSpinner };
