import retailIcon from '@retail/web-theme/styles/icon';
import retailTypography from '@retail/web-theme/styles/typography';

export default {
  fileName: {
    ...retailTypography,
    style: ({ theme }) => `
      ${retailTypography.style({ theme })}
      color: ${theme.colors.pallet.basicDark};
      text-decoration: underline;
    `,
  },
  fileImage: {
    icon: {
      ...retailIcon,
      style: ({ theme }) => `
        ${retailIcon.style({ theme })}
        width: 160px;
        height: 100px;
        ${theme.media.IEOrEDGE`
          width: 100px;
        `}
      `,
    },
  },
};
