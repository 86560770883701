import noop from 'lodash/noop';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
} from 'react';

const INITIAL_VALUE = {
  editInProgress: false,
  setEditInProgress: noop,
};

interface ContextValue {
  editInProgress: boolean;
  setEditInProgress: Dispatch<SetStateAction<boolean>>;
}

export const ProfileManagementContext =
  createContext<ContextValue>(INITIAL_VALUE);

const useContextValue = () => {
  const [editInProgress, setEditInProgress] = useState(false);

  return {
    editInProgress,
    setEditInProgress,
  };
};

const ProfileManagementProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const value = useContextValue();

  return (
    <ProfileManagementContext.Provider value={value}>
      {children}
    </ProfileManagementContext.Provider>
  );
};

export default ProfileManagementProvider;
