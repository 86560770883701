import { epics as userAccountEpics } from '@auth/redux-store-user-account';
import { getCampaignsListModule } from '@ccc/redux-campaigns-list';
import { epics as consumerBankAccountEpics } from '@consumer/bank-account-redux-store';
import { epics as consumerProfileEpics } from '@consumer/profile-redux-store';
import getApiConnectionStatusModule from '@mmw/api-connection-status-redux-store';
import { EMPTY_OBJECT } from '@mmw/constants-utils';
import { createRootEpic } from '@mmw/redux-rx-of-type-operator';
import { epics as authenticationEpics } from '@mmw/redux-store-auth-api-authentication';
import createLoginByPassword from '@mmw/redux-store-auth-api-login-by-password/epics-creator/getLoginByPassword';
import createGetLoginConfigByEmailOrUserid from '@mmw/redux-store-auth-api-login-config-by-user/epics-creator/getLoginConfigByEmailOrUserid';
import { epics as recoverPasswordEpic } from '@mmw/redux-store-auth-api-recover-password';
import { epics as authenticationUnblockEpics } from '@mmw/redux-store-auth-api-request-unblock';
import { epics as availableCountriesEpic } from '@mmw/redux-store-common-available-countries';
import { epics as salutationsEpic } from '@mmw/redux-store-common-available-salutations';
import {
  getLoggedTraderDataModule,
  updateLoggedUserProfilePicture,
} from '@mmw/redux-store-core-trader-logged-user';
import { getCurrentSalesOrgBrandModule } from '@mmw/redux-store-current-salesorgbrand';
import { epics as emailVerificationEpics } from '@mmw/redux-store-email-verification';
import { epics as fastFormEpics } from '@mmw/redux-store-fast-form';
import { epics as i18nEpics } from '@mmw/redux-store-i18n';
import { getSalesOrgBrandsModule } from '@mmw/redux-store-salesorgbrands';
import onAppStartEpic from '@retail/configure-store/app-integrations/onAppStartEpic';

export default createRootEpic(
  ...i18nEpics,
  ...fastFormEpics,
  ...authenticationEpics,
  ...salutationsEpic,
  ...availableCountriesEpic,
  onAppStartEpic,
  createLoginByPassword(EMPTY_OBJECT),
  // onPayloadErrorEpic,
  ...emailVerificationEpics,
  ...authenticationUnblockEpics,
  ...consumerProfileEpics,
  ...consumerBankAccountEpics,
  ...userAccountEpics,
  ...(getCampaignsListModule().epics as []),
  ...getApiConnectionStatusModule().epics,
  updateLoggedUserProfilePicture,
  createGetLoginConfigByEmailOrUserid,
  ...recoverPasswordEpic,
  ...getSalesOrgBrandsModule().epics,
  ...getCurrentSalesOrgBrandModule().epics,
  ...getLoggedTraderDataModule().epics,
);
