import { compose, HOC, withProps } from 'recompose';

import { WithFieldPathProps } from '../types';

const withName: HOC<unknown, unknown> = compose(
  withProps(
    ({
      fieldPath,
    }: WithFieldPathProps): {
      name: string;
    } => ({
      name: fieldPath,
    }),
  ),
);

export default withName;
