import {
  filter,
  includes,
  isArray,
  isString,
  join,
  map,
  replace,
  size,
  split,
} from 'lodash';

import { ThemeType } from './types';

export type Spacing = string | string[];

const spacingValues = [0, 5, 10, 15, 20, 25, 30, 35, 40];
export const NAMESPACE = 'spacing';

export type Factor = number | number[] | string;

// TODO: Fix Types
function returnRealValues(
  values: number[],
  factor: string[] | number[],
): number[] {
  return map(
    filter(
      factor,
      (f: string | number) =>
        f !== null &&
        f !== undefined &&
        values[f] !== null &&
        values[f] !== undefined,
    ),
    (item: string | number) => values[item],
  ) as unknown as number[];
}
const spacing = (factor: Factor = 0, suffix = '', theme: ThemeType): string => {
  if (factor > size(theme.spacing.values)) {
    throw new Error('You tried to use a spacing that s not defined on theme');
  }
  if (isArray(factor)) {
    const realValues = returnRealValues(theme.spacing.values, factor);
    const style = join(realValues, 'px ');
    return `margin: ${style}px;`;
  }
  if (isString(factor) && includes(factor, ',')) {
    const values = split(replace(factor, RegExp(' ', 'g'), ''), ',');

    const realValues = returnRealValues(theme.spacing.values, values);

    const style = join(realValues, 'px ');

    return `margin: ${style}px;`;
  }
  const margin = theme.spacing.values[factor];
  return `margin: ${margin}px ${suffix};`;
};

spacing.values = spacingValues;

export default spacing;
