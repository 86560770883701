import { BadCredentialsError } from '@mmw/services-auth-api-authentication/errors';

import {
  Reducer,
  REQUEST_EMAIL_VERIFICATION_ERROR,
  // SET_USER_EMAIL,
  REQUEST_EMAIL_VERIFICATION_START,
  REQUEST_EMAIL_VERIFICATION_SUCCESS,
  RESET,
  VERIFY_EMAIL_ERROR,
  VERIFY_EMAIL_START,
  VERIFY_EMAIL_SUCCESS,
} from './types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  sendEmailStatus: null,
  notAuthenticatedOperationToken: null,
};

const authenticationReducer: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_EMAIL_VERIFICATION_START:
      return {
        ...state,
        loading: true,
        error: null,
        notAuthenticatedOperationToken: null,
        sendEmailStatus: false,
      };
    case REQUEST_EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        sendEmailStatus: action.payload.success,
      };
    case REQUEST_EMAIL_VERIFICATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case VERIFY_EMAIL_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.payload.error || null,
        notAuthenticatedOperationToken: action.payload.accessToken,
      };
    case VERIFY_EMAIL_ERROR:
      return {
        ...state,
        loading: false,
        // error: action.payload.error,
        error: new BadCredentialsError('BAD_CREDENTIALS'),
      };
    case RESET:
      return {
        ...state,
        error: null,
        sendEmailStatus: null,
      };
    default:
      return state;
  }
};

export default authenticationReducer;
