import { I18nShape } from '@mmw/constants-i18n';
import { css, Padding } from '@ui-system/css';
import { Style } from '@ui-system/interfaces';
import { TypographyVariant } from '@ui-system/interfaces-typography';
import { useMergedStyles } from '@ui-system/style';
import UI from '@ui-system/ui';
import React from 'react';
import { F } from 'ts-toolbelt';

import { Marker } from '../title-content-action';

export interface ModalCardProps {
  title?: I18nShape | string;
  id?: string;
  titleModifiers?: string;
  titleVariant?: TypographyVariant;
  style?: Style;
  contentStyle?: Style;
  children: React.ReactNode;
  contentPadding?: Padding;
  Actions?: React.FC;
  onClose?: F.Function;
}

const STYLE = css`
  width: auto;
  margin: 0 auto;
  margin-top: 5rem;
`;

export const ModalCard: React.FC<ModalCardProps> = ({
  title,
  titleModifiers,
  titleVariant,
  children,
  Actions,
  contentPadding,
  style,
  contentStyle,
  id,
  onClose,
}: ModalCardProps) => {
  const cardStyle = useMergedStyles(STYLE, style);
  return (
    <UI.Card style={cardStyle}>
      <UI.Container
        id={id}
        align="center"
        justify="space-between"
        w="100%"
        bg="background"
        h={50}
      >
        <UI.Container align="center" justify="space-between" w="100%">
          <UI.Container align="center" gap={2}>
            <Marker />
            <UI.Typography
              i18n={title}
              modifiers={titleModifiers}
              variant={titleVariant}
            />
          </UI.Container>
          {onClose && (
            <UI.Container
              onClick={onClose}
              bg="primary"
              h={50}
              w={50}
              align="center"
              justify="center"
            >
              <UI.Icon size={40} name="clear" />
            </UI.Container>
          )}
        </UI.Container>
      </UI.Container>
      <UI.Card.Content style={contentStyle}>
        <UI.Container p={contentPadding} direction="column">
          {children}
        </UI.Container>
      </UI.Card.Content>
      {Actions && (
        <UI.Card.Actions>
          <Actions />
        </UI.Card.Actions>
      )}
    </UI.Card>
  );
};

ModalCard.defaultProps = {
  titleModifiers: 'uppercase',
  contentPadding: '0, 6',
};
