import {
  CreateDispatchHookOnMountReturn,
  CreateDispatchHookReturn,
  GetDynamicModule,
  Handlers,
  Selector,
} from '@redux-basic-module/interfaces';
import { Epic as _Epic } from 'redux-observable';

export type Action<Payload, Meta = void> = {
  type: string;
  payload: Payload;
  meta?: Meta | void;
};

export type Epic<
  StartPayload = null,
  SuccessPayload = null,
  State = any,
> = _Epic<
  Action<string, StartPayload>,
  Action<string, SuccessPayload | any>,
  State
>;

export const INITIAL_STATE = {
  data: null,
  isLoading: false,
  error: null,
  success: false,
  lastStartPayload: null,
  lastCacheDate: null,
};

export type LastStartPayload<StartPayload> = StartPayload | null;

export interface RequestStartMeta {
  cache?: {
    msTtl?: string;
    ignoreCache?: boolean;
  };
}

export interface State<StartPayload, SuccessPayload> {
  data: SuccessPayload | null;
  isLoading: boolean;
  success: boolean;
  error: Error | null;
  lastStartPayload: StartPayload | null;
  lastCacheDate: number | null;
}

export interface Selectors<StartPayload, SuccessPayload> {
  data: Selector<SuccessPayload | null>;
  isLoading: Selector<boolean>;
  success: Selector<boolean>;
  error: Selector<Error | null>;
  lastStartPayload: Selector<StartPayload | null>;
  lastCacheDate: Selector<number | null>;
}

export interface GetHeaders<P, R> {
  (payload: P, state: R): Record<string, any>;
}
export interface GetBody<P, R> {
  (payload: P, state: R): Record<string, any>;
}
export interface GetPath<P, R> {
  (payload: P, state: R): string;
}

export interface Filter<P, R> {
  (payload: P, state: R): boolean;
}

export enum RequestType {
  post = 'POST',
  get = 'GET',
}

export interface EpicConfig<
  RootState,
  StartPayload,
  SuccessPayload,
  Meta = null,
> {
  actions: Actions<StartPayload, SuccessPayload, Meta>;
  filter?: Filter<StartPayload, RootState>;
  // cache?: {
  //   msTtl?: string; // ms format
  // };
  requestOptions: {
    type: RequestType;
    getPath: GetPath<StartPayload, RootState>;
    getHeaders?: GetHeaders<StartPayload, RootState>;
    getBody?: GetBody<StartPayload, RootState>;
  };
  // logs?: {
  //   levels?: {
  //     start: LogLevel;
  //     success: LogLevel;
  //     error: LogLevel;
  //   };
  //   requestParams: boolean | ((payload: StartPayload) => any);
  //   result: boolean | ((payload: SuccessPayload) => any);
  // };
}

export interface AsyncModule<
  RootState,
  StartPayload,
  SuccessPayload,
  Meta = null,
> {
  getDynamicModule: GetDynamicModule<RootState>;
  selectorHooks: SelectorHooks<StartPayload, SuccessPayload>;
  dispatchHooks: DispatchHooks<StartPayload>;
  selectors: Selectors<StartPayload, SuccessPayload>;
  actions: Actions<StartPayload, SuccessPayload, Meta>;
}

export interface DispatchHooks<StartPayload> {
  useAsyncStart: CreateDispatchHookReturn<StartPayload>;
  useAsyncStartOnMount: CreateDispatchHookOnMountReturn<StartPayload>;
  useDismissError: CreateDispatchHookReturn;
  useCancelRequest: CreateDispatchHookReturn;
  useResetModule: CreateDispatchHookReturn;
}

export interface useData<SuccessPayload> {
  (): SuccessPayload;
}
export interface UseLastStartPayload<StartPayload> {
  (): LastStartPayload<StartPayload>;
}
export interface UseIsLoading {
  (): boolean;
}
export interface UseSuccess {
  (): boolean;
}
export interface UseError {
  (): Error | null;
}

export interface SelectorHooks<StartPayload, SuccessPayload> {
  useData: useData<SuccessPayload>;
  useLastStartPayload: UseLastStartPayload<StartPayload>;
  useIsLoading: UseIsLoading;
  useSuccess: UseSuccess;
  useError: UseError;
}

export interface AsyncModuleConfig<
  RootState,
  StartPayload,
  SuccessPayload,
  Meta = null,
> {
  namespace: string;
  actionName: string;
  mainEpicConfig: Omit<
    EpicConfig<RootState, StartPayload, SuccessPayload>,
    'actions'
  >;
  extraEpicConfigs?: EpicConfig<RootState, StartPayload, SuccessPayload>[];
  buildExtraEpics?: (
    actions: Actions<StartPayload, SuccessPayload, Meta>,
    selectors: Selectors<StartPayload, SuccessPayload>,
  ) => Epic<StartPayload>[];
  buildExtraHandlers?: (
    actions: Actions<StartPayload, SuccessPayload, Meta>,
    selectors: Selectors<StartPayload, SuccessPayload>,
  ) => Handlers<State<StartPayload, SuccessPayload>, any>;
}
