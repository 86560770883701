import {
  BAD_CREDENTIALS,
  UNKNOWN_ERROR_I18N,
} from '@mmw/redux-store-payload-error/constants';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { errorSelector } from '../stateSelector';

const useError = () => useSelector(errorSelector);

export function useErrorI18n() {
  const error = useError();
  const badCredentialsError = error instanceof BAD_CREDENTIALS.ERROR;

  const title = badCredentialsError
    ? BAD_CREDENTIALS.I18N.TITLE
    : UNKNOWN_ERROR_I18N;

  const description = badCredentialsError
    ? BAD_CREDENTIALS.I18N.DESCRIPTION
    : UNKNOWN_ERROR_I18N;

  return useMemo(
    () => ({
      title,
      description,
      errorVisible: !!error,
    }),
    [title, description, error],
  );
}
export default useError;
