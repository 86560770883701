import styled from 'styled-components';

const NavLink = styled.a`
  color: ${({ colorItem }) => colorItem};
  padding: 15px 18px;
  text-decoration: none;
  display: flex;
  align-items: center;
  :hover {
    cursor: pointer;
    background-color: ${({ bgHover }) => bgHover};
    color: #fff;
  }

  ${({ theme }) => theme.media.phone`
    width: 100%;
  `}
`;

NavLink.defaultProps = {
  colorItem: '#004b93',
  bgHover: '#004b93',
};

export default NavLink;
