import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { F } from 'ts-toolbelt';

import { regListByOrgunitSelector } from '../stateSelector';

const useAuditsByStore = (
  orgunitId: number,
): F.Return<typeof regListByOrgunitSelector> => {
  const selector = useCallback(
    state => regListByOrgunitSelector(state, orgunitId),
    [orgunitId],
  );
  return useSelector(selector);
};

export default useAuditsByStore;
