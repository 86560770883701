import { createAction } from '@reduxjs/toolkit';

import { createCarouselSearchTypes } from './createCarouselSearchTypes';
import { createSearchActionsByTypes } from './createSearchActions';
import { CarouselInitPayload, SearchMeta } from './types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createCarouselSearchActions<
  RequestFields,
  ItemType,
  Meta extends SearchMeta = SearchMeta,
>(namespace: string, actionName: string) {
  const types = createCarouselSearchTypes(namespace, actionName);

  const searchActions = createSearchActionsByTypes<
    RequestFields,
    ItemType,
    Meta
  >(namespace, actionName, types.search);

  const init = createAction(
    types.carousel.INIT,
    (payload: CarouselInitPayload<RequestFields>, meta: Meta) => ({
      payload,
      meta,
    }),
  );

  const next = createAction(types.carousel.NEXT, (meta: Meta) => ({
    payload: undefined,
    meta,
  }));

  const previous = createAction(types.carousel.PREVIOUS, (meta: Meta) => ({
    payload: undefined,
    meta,
  }));

  return {
    search: searchActions,
    select: {
      init,
      next,
      previous,
    },
  };
}

// XXX: this is to find implicit types
// wrapped has no explicit return type so we can infer it
class Wrapper<RequestFields, ItemType, Meta extends SearchMeta = SearchMeta> {
  // eslint-disable-next-line class-methods-use-this
  createSearchCarouselActions(namespace: string, actionName: string) {
    return createCarouselSearchActions<RequestFields, ItemType, Meta>(
      namespace,
      actionName,
    );
  }
}

export type CarouselSearchActions<
  RequestFields,
  ItemType,
  Meta extends SearchMeta = SearchMeta,
> = ReturnType<
  Wrapper<RequestFields, ItemType, Meta>['createSearchCarouselActions']
>;
