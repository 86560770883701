import { fastHash } from '@hasher/object-hash';
import { useNavigate } from '@mmw/ui-web-libraries-react-router/hooks';
import { css, Padding, styled } from '@ui-system/css';
import { ContainerProps } from '@ui-system/interfaces-container';
import { MenuOption } from '@ui-system/interfaces-menu';
import { makeStyle } from '@ui-system/style';
import { Theme } from '@ui-system/theme';
import UI from '@ui-system/ui';
import { useBoolean, useHover } from 'ahooks';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React, { useMemo, useRef, useState } from 'react';
import { F } from 'ts-toolbelt';

import { NavItemConfig, NavItemConfigInterface } from './types';
import { useNavItemHandler } from './useNavItemHandler';

export interface NavItemProps extends NavItemConfigInterface {
  itemPadding?: Padding;
}

interface NavContainerProps extends ContainerProps {
  theme?: Theme;
  active?: boolean;
  onClick?: F.Function;
}

export type NavItemType = React.FC<NavItemProps>;

const NavContainer: React.FC<NavContainerProps> = styled(UI.Container)`
  background-color: ${({ theme, active }: NavContainerProps) =>
    active
      ? theme?.colors[theme.mode].primary.main
      : theme?.colors[theme.mode].black};
  :hover {
    background-color: ${({ theme }) => theme.colors[theme.mode].primary.main};
    color: ${({ theme }) => theme.colors[theme.mode].black};
    fill: ${({ theme }) => theme.colors[theme.mode].black};
  }
`;

const useMenuStyle = makeStyle(
  ({ theme, utils }) => css`
    ${utils.applyBackgroundColor(theme, 'primary')}
  `,
);
export const NavItem: NavItemType = ({
  itemPadding,
  ...config
}: NavItemProps) => {
  const [isSubMenuOpened, setOpened] = useState(false);
  const active = useNavItemHandler({
    route: config.route,
    matchGroup: config.matchGroup,
  });
  const menuStyle = useMenuStyle();
  const navigate = useNavigate();
  const item = useMemo(
    () => new NavItemConfig(config, navigate),
    [config, navigate],
  );
  const onClickHandler = item.useOnClick();

  const menuOptions = useMemo(() => {
    if (isEmpty(item.subRoutes)) return null;
    return map(
      item.subRoutes,
      subRoute =>
        new MenuOption({
          label: subRoute.label,
          useOnClick: subRoute.useOnClick,
          useIsVisible: subRoute.useIsVisible,
        }),
    );
  }, [item.subRoutes]);
  const ref = useRef(null);
  const hover = useHover(ref);

  const activeOrHover = useMemo(() => active || hover, [active, hover]);
  const visible = item.useIsVisible();
  if (!visible) return null;
  if (!menuOptions)
    return (
      <div
        ref={ref}
        style={css`
          height: 100%;
          width: 100%;
        `}
      >
        <NavContainer
          onClick={onClickHandler}
          active={active}
          p={itemPadding}
          align="center"
          justify="center"
          h="100%"
          w="100%"
        >
          <UI.Caption
            i18n={item.label}
            modifiers={`uppercase, center, nowrap, ${
              activeOrHover ? 'black' : 'white'
            }`}
            variant="caption"
          />
        </NavContainer>
      </div>
    );
  return (
    <UI.Menu.MenuContainer
      style={menuStyle}
      options={menuOptions}
      onToggleSubMenu={isOpen => setOpened(isOpen)}
      menuItemStyle={css`
        width: 200px;
        text-align: center;
      `}
    >
      <div
        ref={ref}
        style={css`
          height: 100%;
          width: 100%;
        `}
      >
        <NavContainer
          onClick={onClickHandler}
          active={isSubMenuOpened || activeOrHover}
          p={itemPadding}
          align="center"
          justify="center"
          h="100%"
          w="100%"
          gap={1}
        >
          <UI.Caption
            i18n={item.label}
            modifiers={`center, uppercase, nowrap, ${
              activeOrHover ? 'black' : 'white'
            }`}
            variant="caption"
          />
          <UI.Icon
            size={10}
            name="down"
            color={activeOrHover ? 'black' : 'white'}
          />
        </NavContainer>
      </div>
    </UI.Menu.MenuContainer>
  );
};

const MobileOption = ({
  option,
  itemPadding,
}: {
  option: MenuOption;
  itemPadding: NavItemProps['itemPadding'];
}) => {
  const { label, useOnClick } = option;
  const onClick = useOnClick();
  return (
    <UI.Container w="90%" p={itemPadding}>
      <UI.Button onClick={onClick} modifiers="fullWidth">
        <UI.Caption
          i18n={label}
          modifiers="center, uppercase, nowrap"
          variant="caption"
        />
      </UI.Button>
    </UI.Container>
  );
};

export const MobileNavItem: NavItemType = ({
  label,
  route,
  onClick,
  matchGroup,
  subRoutes,
  itemPadding,
}: NavItemProps) => {
  const navigate = useNavigate();
  const item = useMemo(
    () =>
      new NavItemConfig(
        {
          label,
          route,
          onClick,
          matchGroup,
          subRoutes,
        },
        navigate,
      ),
    [label, route, onClick, matchGroup, subRoutes, navigate],
  );
  const onClickHandler = item.useOnClick();

  const menuOptions = useMemo(() => {
    if (isEmpty(item.subRoutes)) return null;
    return map(
      item.subRoutes,
      config =>
        new MenuOption({
          label: config.label,
          useOnClick: config.useOnClick,
        }),
    );
  }, [item.subRoutes]);

  const [open, { setTrue, setFalse }] = useBoolean();

  const visible = item.useIsVisible();
  if (!visible) return null;
  if (!menuOptions)
    return (
      <UI.Container w="100%" p={itemPadding}>
        <UI.Button onClick={onClickHandler} modifiers="fullWidth">
          <UI.Caption
            i18n={label}
            modifiers="uppercase, center, nowrap"
            variant="caption"
          />
        </UI.Button>
      </UI.Container>
    );
  return (
    <UI.Container direction="column">
      <UI.Container w="100%" p={itemPadding}>
        <UI.Button onClick={onClickHandler} modifiers="fullWidth">
          <UI.Caption
            i18n={label}
            modifiers="center, uppercase, nowrap"
            variant="caption"
          />
        </UI.Button>
        <UI.Container align="center" bg="primary.light" justify="center">
          {!open ? (
            <UI.Icon size={15} name="down" onClick={setTrue} />
          ) : (
            <UI.Icon size={20} name="clear" onClick={setFalse} />
          )}
        </UI.Container>
      </UI.Container>
      <UI.Container direction="column" align="flex-end">
        {open &&
          map(menuOptions, option => (
            <MobileOption
              option={option}
              key={fastHash(option)}
              itemPadding={itemPadding}
            />
          ))}
      </UI.Container>
    </UI.Container>
  );
};

NavItem.defaultProps = {
  itemPadding: '0',
};
