import AUTH_I18N from '@mmw/constants-i18n-authentication';
import * as AUTHENTICATION_ERRORS from '@mmw/services-auth-api-authentication/errors';

/*  TODO
  - ApiBadRequestError: Bad Request Found
*/

const namespace = 'payloadErrorStore';

export const USER_NOT_FOUND = {
  key: `${namespace}.userNotFound`,
  message: 'user not found.',
};

export const UNKNOWN_ERROR_I18N = {
  key: `${namespace}.unknownError`,
  message: 'Unknown error.',
};

export const INVALID_APPLICATION_ERROR_I18N = {
  key: `${namespace}.invalidApplication`,
  message: 'Invalid Application.',
};

export const {
  BadCredentialsError,
  UserNotFoundError,
  EmailBlockedError,
  CaptchaBlockedError,
  MissingSalesOrgBusinessRelationError,
  MissingAuthorityError,
  UnknownError,
  InvalidApplicationError,
} = AUTHENTICATION_ERRORS;

export const MISSING_AUTHORITY = {
  ERROR: MissingAuthorityError,
  I18N: AUTH_I18N.USER_MISSING_AUTHORITY,
};

export const BAD_CREDENTIALS = {
  ERROR: BadCredentialsError,
  I18N: AUTH_I18N.BAD_CREDENTIALS,
};

export const USER_NOT_FOUND_ERROR = {
  ERROR: UserNotFoundError,
  I18N: USER_NOT_FOUND,
};

export const EMAIL_BLOCKED = {
  ERROR: EmailBlockedError,
  I18N: AUTH_I18N.EMAIL_BLOCKED,
};

export const CAPTCHA_BLOCKED = {
  ERROR: CaptchaBlockedError,
  I18N: AUTH_I18N.CAPTCHA_BLOCKED,
};

export const UNKNOWN_ERROR = {
  ERROR: UnknownError,
  I18N: UNKNOWN_ERROR_I18N,
};

export const INVALID_APPLICATION = {
  ERROR: InvalidApplicationError,
  I18N: INVALID_APPLICATION_ERROR_I18N,
};

export const MISSING_SALES_ORG_BRAND_RELATION = {
  ERROR: MissingSalesOrgBusinessRelationError,
  I18N: AUTH_I18N.USER_MISSING_AUTHORITY,
};
