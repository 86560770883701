import { ConsumerBankDataJSON } from '@mmw/services-core-consumer-bank-account/types';
import { U } from 'ts-toolbelt';

import { NAMESPACE, RootState } from './types';

export const loadingSelector = (state: RootState): boolean =>
  state[NAMESPACE].loading;

export const consumerPersonalDataSelector = (
  state: RootState,
): U.Nullable<ConsumerBankDataJSON[]> => state[NAMESPACE].data;

export const errorSelector = (state: RootState): Error =>
  state[NAMESPACE].error;

export const successSelector = (state: RootState): boolean =>
  state[NAMESPACE].success;

export const requestAttemptedSelector = (state: RootState): boolean =>
  state[NAMESPACE].requestAttempted;

export const updateDataAttemptedSelector = (state: RootState): boolean =>
  state[NAMESPACE].updateDataAttempted;
