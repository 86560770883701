export default {
  style: ({ theme, autoHeight }) => `
      padding: 20px 30px;
      background-color: ${theme.colors.pallet.background};
      ${autoHeight ? '' : 'max-height: fit-content'};
      box-sizing: border-box;
      position: sticky;
      top: 110px;
      z-index: 0;
    `,
};
