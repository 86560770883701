import { AuditStatusOption } from '@audit/utils';
import { CampaignStatus } from '@mmw/constants-campaign-status';
import { I18nShape } from '@mmw/constants-i18n';
import {
  APPROVED,
  APPROVED_FOR_INITIAL_ORDER,
  APPROVED_PENDING,
  IN_VERFICATION,
  NEEDS_REVISION,
  OPEN,
  REJECTED,
  TRANSMITTED,
} from '@mmw/constants-i18n-audit-campaign-status';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@mmw/constants-utils';
import { O } from 'ts-toolbelt';

import { OralbAuditState, OralbAuditStatus } from './types';

export const ORALB_AUDIT_I18N_MAPPING: Record<string, I18nShape> = {
  OPEN,
  APPROVED,
  REJECTED,
  APPROVED_PENDING,
  APPROVED_FOR_INITIAL_ORDER,
  IN_VERFICATION,
  TRANSMITTED,
  NEEDS_REVISION,
};

export const ORALB_CAMPAIGN_STATUS_MAPPING: Record<
  OralbAuditStatus,
  CampaignStatus
> = {
  [OralbAuditStatus.OPEN]: CampaignStatus.INVALID_DATA,
  [OralbAuditStatus.TRANSMITTED]: CampaignStatus.VERIFYING,
  [OralbAuditStatus.APPROVED_PENDING]: CampaignStatus.VERIFIED_INCOMPLETE,
  [OralbAuditStatus.APPROVED_FOR_INITIAL_ORDER]:
    CampaignStatus.VERIFIED_INCOMPLETE,
  [OralbAuditStatus.REJECTED]: CampaignStatus.REJECTED,
  [OralbAuditStatus.IN_VERFICATION]: CampaignStatus.READY_TO_PROCESS,
  [OralbAuditStatus.APPROVED]: CampaignStatus.TRANSACTION_FINISHED,
  [OralbAuditStatus.NEEDS_REVISION]: CampaignStatus.VALIDATION_FAILED,
};
export const ORALB_CAMPAIGN_STATUS_KEYS = Object.keys(
  ORALB_CAMPAIGN_STATUS_MAPPING,
) as OralbAuditStatus[];

export const ORALB_STATUS_COLOR_MAPPING = {
  OPEN: '#fd5c40',
  APPROVED: '#0a6645',
  REJECTED: '#bd2812',
  APPROVED_PENDING: '#fd9a35',
  APPROVED_FOR_INITIAL_ORDER: '#0a6645',
  IN_VERFICATION: '#fd9a35',
  TRANSMITTED: '#fd9a35',
  NEEDS_REVISION: '#fd9a35',
};

export const ORALB_ICON_MAPPING = {
  [OralbAuditStatus.OPEN]: 'check',
  [OralbAuditStatus.APPROVED]: 'check',
  [OralbAuditStatus.REJECTED]: 'close',
  [OralbAuditStatus.APPROVED_PENDING]: 'check',
  [OralbAuditStatus.APPROVED_FOR_INITIAL_ORDER]: 'check',
  [OralbAuditStatus.IN_VERFICATION]: 'check',
  [OralbAuditStatus.TRANSMITTED]: 'check',
  [OralbAuditStatus.NEEDS_REVISION]: 'edit',
};

export const ORALB_ALLOWED_STATUS_TO_CREATE_ISSUES = [
  OralbAuditStatus.OPEN,
  OralbAuditStatus.NEEDS_REVISION,
];

export const ORALB_EMPTY_AUDIT_STATE: OralbAuditState = {
  registration: undefined,
  id: null,
  created: false,
  status: null,
  canOrderProducts: false,
  canCompleteAudit: false,
  canUploadSellout: false,
  canShowFilesCard: false,
  canStartAudit: false,
  isRejected: false,
  allowedStatusTransitions: EMPTY_ARRAY,
  allowedStatusToCreateIssues: ORALB_ALLOWED_STATUS_TO_CREATE_ISSUES,
  createIssuesOnTransition: EMPTY_OBJECT,
  canCreateIssues: false,
};

export const ORALB_STATUS_CHANGE_OPTIONS: O.Omit<AuditStatusOption, 'name'>[] =
  [
    {
      value: OralbAuditStatus.OPEN,
    },
    {
      value: OralbAuditStatus.APPROVED,
      canCreateApprovalIssues: true,
    },
    {
      value: OralbAuditStatus.REJECTED,
      canCreateCorrectionIssues: true,
    },
    {
      value: OralbAuditStatus.APPROVED_PENDING,
      hideButton: true,
    },
    {
      value: OralbAuditStatus.APPROVED_FOR_INITIAL_ORDER,
      canCreateApprovalIssues: true,
    },
    {
      value: OralbAuditStatus.IN_VERFICATION,
    },
    {
      value: OralbAuditStatus.TRANSMITTED,
    },
    {
      value: OralbAuditStatus.NEEDS_REVISION,
      requiresToCreateCorrectionIssues: true,
      canCreateCorrectionIssues: true,
      canCreateApprovalIssues: true,
    },
  ];
