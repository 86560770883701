import {
  useClearError,
  useError,
} from '@mmw/redux-store-auth-api-login-by-password/hooks';
import React from 'react';

import { AuthErrorCardUI } from './CardContent';

export * from './CardContent';

type Props = Record<string, any>;

export const AuthErrorCard: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  ...props
}: React.PropsWithChildren<Props>) => {
  const error = useError();
  const clear = useClearError();
  if (!error) return null;
  return (
    <AuthErrorCardUI error={error} onDismiss={clear} {...props}>
      {children}
    </AuthErrorCardUI>
  );
};

export default AuthErrorCard;
