import { useTranslate } from '@mmw/utils-text-utils/hooks';
import isEmpty from 'lodash/isEmpty';
import { useCallback } from 'react';
import { U } from 'ts-toolbelt';

import { getSobAuditStatusI18n } from './auditStatusBySob';

function useAuditTranslatedStatus(status?: U.Nullable<string>) {
  const translate = useTranslate();
  return useCallback(
    (cbStatus?: U.Nullable<string>) => {
      const mappedStatusObj = getSobAuditStatusI18n();
      if (isEmpty(mappedStatusObj) || (!cbStatus && !status)) return null;
      if (status) {
        return translate(mappedStatusObj[status]);
      }
      return translate(mappedStatusObj[cbStatus as string]);
    },
    [status, translate],
  );
}

// export function useTranslateAuditStatus(){
//   const translate = useTranslate();
//   return useCallback((status: U.Nullable<string>) => {
//     const mappedStatusObj = getSobAuditStatusI18n();
//     return translate(mappedStatusObj[status]);
//   }, []);
// const translateStatus: TranslateStatusCb = useCallback(
//   (s: string) => translate(AUDIT_STATUS_I18N[s]),
//   [translate],
// );
// if (predef) return translateStatus(predef) as string;
// return translateStatus as TranslateStatusCb;
// }

export default useAuditTranslatedStatus;
