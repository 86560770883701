import useGetLoginConfigSubmitHandler from '@mmw/redux-store-auth-api-login-config-by-user/hooks/useGetLoginConfigSubmitHandler';
import { useRecoverPassword } from '@mmw/redux-store-auth-api-recover-password/hooks';
import { useNotifications } from '@mmw/ui-hybrid-libraries-notifications/hooks';
import { AUTHENTICATION as AUTH_I18N } from '@retail/constants-i18n';
import { EnterEmailContent } from '@retail/ui-web-pages-login-and-signup/pages/EnterEmail';
import {
  RecoverPasswordRequestSuccessContent,
  ReturnToLoginButton,
} from '@retail/ui-web-pages-login-and-signup/pages/RecoverPassword';
import { css } from '@ui-system/css';
import { TitleContentActionCard } from '@ui-system/custom-cards';
import UI from '@ui-system/ui';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useState } from 'react';
import { F } from 'ts-toolbelt';

export { default as RecoverPasswordUUID } from './RecoverPasswordUUID';

const BUTTON_STYLE = css`
  width: 100%;
`;

interface Props {
  navigationCallback: F.Function;
}

const RecoverPassword: React.FC<Props> = ({ navigationCallback }: Props) => {
  const [successfullRequestData, setSuccessfullData] = useState();
  const recoverPassword = useRecoverPassword();
  const notify = useNotifications();
  const getLoginConfigSubmitHandler =
    useGetLoginConfigSubmitHandler(recoverPassword);
  const onSubmitSuccess = useCallback(
    values => {
      if (isEmpty(successfullRequestData)) {
        setSuccessfullData(values);
      }
      getLoginConfigSubmitHandler(values);
      notify(AUTH_I18N.AUTHENTICATION.SENT_CHANGE_PASSWORD_EMAIL, {
        appearance: 'success',
      });
    },
    [
      getLoginConfigSubmitHandler,
      setSuccessfullData,
      successfullRequestData,
      notify,
    ],
  );

  return (
    <UI.Container
      w={500}
      m={10}
      responsive="upToSm.w=350px;upToSm.h=350px;upToSm.m=0;upToSm.align=center"
    >
      <TitleContentActionCard
        title={AUTH_I18N.AUTHENTICATION.RECOVER_PASSWORD}
        contentPadding="0, 8, 8, 8"
      >
        {!isEmpty(successfullRequestData) ? (
          <RecoverPasswordRequestSuccessContent
            askAgain={() => onSubmitSuccess(successfullRequestData)}
            handleGoBack={navigationCallback}
          />
        ) : (
          <UI.Container direction="column" gap={2}>
            <EnterEmailContent
              onSubmitSuccess={onSubmitSuccess}
              buttonStyle={BUTTON_STYLE}
            />
            <ReturnToLoginButton goBack={navigationCallback} />
          </UI.Container>
        )}
      </TitleContentActionCard>
    </UI.Container>
  );
};

export default RecoverPassword;
