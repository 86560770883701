import { useCallback } from 'react';

const useEventStopPropagation = (callback?: Function) =>
  useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation();
      if (callback) {
        callback();
      }
    },
    [callback],
  );

export default useEventStopPropagation;
