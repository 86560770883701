import {
  ChangeRegistrationStatusOperations,
  CreateIssueRequestJSON,
  PerformIssueActionJSON,
  RegistrationActionWithStatusRequestJSON,
  RegistrationActionWithValidationErrorRequestJSON,
} from '@mmw/services-core-manufacturer-registration/types';
import {
  RegistrationResponseJSON,
  SearchRegistrationRequestFiltersJSON,
} from '@mmw/services-core-trader-registration/types';
import { createAsyncActions } from '@redux-async-module/actions-utils';
import {
  createCarouselSearchActions,
  createRetrieveActions,
  createSearchActions,
} from '@redux-search-module/actions-utils';
import { createCreateItemSobActions } from '@redux-sob-module/actions-utils';
import { Action, createAction } from '@reduxjs/toolkit';

import { CHANGE_LIMIT, NAMESPACE } from './types';

export const searchAudits = createSearchActions<
  SearchRegistrationRequestFiltersJSON,
  RegistrationResponseJSON
>(NAMESPACE, 'search-audits');

export const retrieveAudit = createRetrieveActions<RegistrationResponseJSON>(
  NAMESPACE,
  'retrieve-audit',
);

export const carouselSearchAudits = createCarouselSearchActions<
  SearchRegistrationRequestFiltersJSON,
  RegistrationResponseJSON
>(NAMESPACE, 'carousel-search-audits');

type CreateAuditPayload = {
  storeID: number;
  campaignCode: string;
  checkAndRetrieveAlreadyOpenAudit?: boolean;
};

export const createAudit = createCreateItemSobActions<
  CreateAuditPayload,
  RegistrationResponseJSON
>(NAMESPACE, 'create-audits');

export const changeLimitAction = createAction(
  CHANGE_LIMIT,
  (payload: number, meta: { searchUuid: string }) => ({
    payload,
    meta,
  }),
);

export const changeStatus = createAsyncActions<
  RegistrationActionWithStatusRequestJSON,
  ChangeRegistrationStatusOperations,
  Error,
  null
>(NAMESPACE, 'change-status');

export const removeValidationErrors = createAsyncActions<
  RegistrationActionWithValidationErrorRequestJSON & { next?: Action },
  ChangeRegistrationStatusOperations,
  Error,
  null
>(NAMESPACE, 'remove-validation-error');

export const createIssue = createAsyncActions<
  { request: CreateIssueRequestJSON; registrationId: number },
  RegistrationResponseJSON,
  Error,
  null
>(NAMESPACE, 'create-issue');

export const performIssueAction = createAsyncActions<
  {
    request: PerformIssueActionJSON<any>;
    registrationId: number;
  },
  ChangeRegistrationStatusOperations,
  Error,
  { issueId: number }
>(NAMESPACE, 'perform-issue-action');
