export default {
  style: ({ theme, selected }) => `
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
    max-width: 100%;
    height: auto;
    background-color: ${theme.colors.pallet.basicLight};
    margin: 5px 0;
    cursor: pointer;
    transform: translate3d(0, 0, 0);
    ${selected ? `border: 1px solid ${theme.colors.pallet.primary};` : ''};
  `,
  title: {
    style: () => `
      width: 100%;
      font-weight: bold;
    `,
  },
  section: {
    style: () => `
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      padding: 10px 0;
      box-sizing: border-box;
      margin-left: 5px;
    `,
  },
  imgSection: {
    style: () => `
      display: flex;
      width: 90px;
      max-height: 90px;
      margin: 5px;
      justify-content: center;
      align-self: center;
    `,
  },
  item: {
    style: () => `
      display: flex;
      flex-direction: column;
      margin: 5px 0;
    `,
    label: {
      style: ({ theme }) => `
        color: ${theme.colors.pallet.primary};
      `,
    },
    text: {
      style: () => '',
    },
  },
  arrow: {
    style: ({ theme }) => `
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px;
      background-color: ${theme.colors.pallet.primary};
      box-sizing: border-box;
      margin-left: 10px;
    `,
    icon: {
      style: ({ theme }) => `
        fill: ${theme.colors.pallet.light};
      `,
    },
  },
};
