import { Typography as MuiTypography } from '@material-ui/core';
import limitString from '@mmw/constants-string-utils';
import { STRING_HTML_REGEX } from '@mmw/constants-utils';
import { usePrefixKey } from '@mmw/ui-providers-resources';
import {
  createTypographySet,
  useI18nWithValues,
} from '@ui-system/common-typography';
import componentModifiers from '@ui-system/default-modifiers/typography';
import { useTypographyStyle } from '@ui-system/default-styles';
import { useFormatedDate, useTranslatedMessage } from '@ui-system/deps';
import HtmlParser from '@ui-system/html-parser';
import {
  ContentType,
  SafeTypographyType,
  TypographyProps,
  TypographyType,
} from '@ui-system/interfaces-typography';
import { pleaseImplement } from '@ui-system/utils';
import { compact, isObject, join } from 'lodash';
import React, { useMemo } from 'react';

const SafeTypography =
  pleaseImplement<SafeTypographyType>('SafeTypographyType');

function addPrefixSuffix(...texts: ContentType[]): string {
  return join(compact(texts), '');
}

const Typography: TypographyType = ({
  i18n,
  children,
  style,
  modifiers,
  date,
  prefix,
  suffix,
  values,
  variant,
  charLimit,
  hideLastChars,
  ...rest
}: TypographyProps) => {
  const prefixKey = usePrefixKey();

  const i18nWithPrefixKey = useMemo(() => {
    if (!prefixKey || !isObject(i18n)) return i18n;
    if (isObject(i18n))
      return {
        ...i18n,
        key: `${prefixKey}.${i18n.key}`,
      };
    return i18n;
  }, [i18n, prefixKey]);
  const formattedDate = useFormatedDate(date);
  const i18nWithValues = useI18nWithValues(i18nWithPrefixKey, values);
  const text = useTranslatedMessage(i18nWithValues);
  let content;
  const finalStyle = useTypographyStyle(style, modifiers, componentModifiers);

  const finalValue = useMemo(() => {
    if (text) {
      const textWithPrefix = addPrefixSuffix(
        prefix,
        text as ContentType,
        suffix,
      );
      if (charLimit) {
        return limitString(textWithPrefix, charLimit, '...', hideLastChars);
      }
      return textWithPrefix;
    }
    const textWithPrefix = addPrefixSuffix(
      prefix,
      children as ContentType,
      suffix,
    );
    if (charLimit) {
      return limitString(textWithPrefix, charLimit, '...', hideLastChars);
    }
    return textWithPrefix;
  }, [charLimit, children, hideLastChars, prefix, suffix, text]);

  if (STRING_HTML_REGEX.test(text as string)) {
    content = HtmlParser(text, { modifiers, style: finalStyle });
  }

  if (Typography.CustomWrapper) {
    return (
      <Typography.CustomWrapper
        i18n={i18nWithValues}
        prefix={prefix}
        suffix={suffix}
        {...rest}
      >
        <MuiTypography variant={variant} {...rest} style={finalStyle}>
          {formattedDate || content || finalValue || children}
        </MuiTypography>
      </Typography.CustomWrapper>
    );
  }
  return (
    <MuiTypography variant={variant} style={finalStyle} {...rest}>
      {formattedDate || content || finalValue || children}
    </MuiTypography>
  );
};

Typography.Safe = SafeTypography;
export const {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Subtitle1,
  Subtitle2,
  Body1,
  Body2,
  Caption,
  ButtonText,
  Overline,
} = createTypographySet(Typography, SafeTypography);

export default Typography;
