import { QUESTION_GROUP as VALIDATION } from '@mmw/constants-fields-validations';
import { DEFAULT as I18N } from '@mmw/constants-i18n-validations';

import {
  BOOLEAN,
  NUMBER_NULLABLE,
  NUMBER_REQUIRED,
  STRING_NULLABLE,
  STRING_REQUIRED,
} from './utils';

const ANSWER = STRING_NULLABLE.max(VALIDATION.ANSWER.MAX_LENGTH);

const ANSWER_REQUIRED = ANSWER.required(I18N.REQUIRED.key);

export const QUESTION = {
  ID_REQUIRED: NUMBER_REQUIRED,
  TEXT: STRING_NULLABLE,
  ANSWER,
  ANSWER_REQUIRED,
  EXPECTED_ANSWER: STRING_NULLABLE,
  ANSWER_ID: NUMBER_NULLABLE,
  POSITION_REQUIRED: NUMBER_REQUIRED,
  CODE_REQUIRED: STRING_REQUIRED,
  OTHER_ANSWER: STRING_NULLABLE,
  IS_OTHER: BOOLEAN,
};

export default QUESTION;
