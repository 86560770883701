import { I18nShape } from '@mmw/constants-i18n';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import * as React from 'react';
import { useContext, useState } from 'react';
import invariant from 'tiny-invariant';
import { F, U } from 'ts-toolbelt';

export interface NavRouteConfig {
  route: string;
  titleI18n: I18nShape;
  leftNavItemAcessory?: U.Nullable<React.ReactElement<any>>;
  rightNavItemAcessory?: U.Nullable<React.ReactElement<any>>;
  disabledRoute?: boolean;
  routeParams?: string | number;
}

export interface DownloadItem {
  url: string;
  titleI18n: I18nShape;
  descriptionI18n: I18nShape;
}

export class ExtraConfigContextValue {
  hideMmwMenu?: boolean = false;

  navRoutes?: U.Nullable<NavRouteConfig[]> = null;

  downloadsList?: U.Nullable<DownloadItem[]> = null;

  CustomComponent?: U.Nullable<React.FC> = null;

  drawerItems?: U.Nullable<React.FC[]> = null;

  setNavRoutes: F.Function<[NavRouteConfig[]]> = noop;
}

const INITIAL_VALUE = new ExtraConfigContextValue();

export const ExtraConfigContext =
  React.createContext<ExtraConfigContextValue>(INITIAL_VALUE);

export function useExtraConfigContext() {
  const context = useContext(ExtraConfigContext);
  invariant(
    !isEmpty(context),
    'You must declare <ExtraConfigContextProvider> before using this feature, no context found.',
  );
  return context;
}

function useContextValue(value: ExtraConfigContextValue) {
  const [customValue, setContextValue] = useState(value || INITIAL_VALUE);
  const [navRoutes, setNavRoutes] =
    useState<U.Nullable<NavRouteConfig[]>>(null);

  return {
    ...value,
    ...customValue,
    setContextValue,
    navRoutes: navRoutes || value?.navRoutes,
    setNavRoutes,
  };
}

interface Props {
  value: ExtraConfigContextValue;
}

export const ExtraConfigContextProvider: React.FC<
  React.PropsWithChildren<Props>
> = ({ children, value }: React.PropsWithChildren<Props>) => {
  const providerValue = useContextValue(value);
  return (
    <ExtraConfigContext.Provider value={providerValue}>
      {children}
    </ExtraConfigContext.Provider>
  );
};
