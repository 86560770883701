import { LINK_UUID, LinkProps } from '@ui-system/interfaces';
import { makeStyle } from '@ui-system/style';

import { makeFinalStyleHook } from './internal';

const useLinkDefaultStyle = makeStyle<Partial<LinkProps>>(
  ({ css, theme }) => css`
    color: ${theme.typography.colors[theme.mode].onBackground};
    cursor: pointer;
    text-decoration: none;
    text-decoration-color: ${theme.typography.colors[theme.mode].onBackground};
    width: fit-content;
    outline: 0;
    display: flex;    
    :hover {
      * {
        text-decoration: underline; !important  
      }
      text-decoration: underline; !important
    }
    &:visited {
      color: inherit;
    }
  `,
);

export const useLinkStyle = makeFinalStyleHook<Partial<LinkProps>>(
  LINK_UUID,
  useLinkDefaultStyle,
);
