export default ({ theme, disabled }) => `
  width: 100%;
  font-family: ${theme.fonts.fontFamily};
  font-size: 1rem;
  padding: 10px;
  color: ${disabled ? theme.colors.pallet.basicLight : theme.colors.pallet.dark};
  background-color: ${theme.colors.pallet.light};
  border-style: solid;
  border-color: ${theme.colors.primary.darken(0.3)};
  border-width: 1px;
  background-clip: padding-box;
  box-sizing: border-box;
  -moz-appearance: textfield;  
  ${theme.form.variable.focus({ theme })};
`;
