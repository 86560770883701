import { FieldPath } from '@mmw/redux-store-fast-form';
import {
  useOnBlur,
  useOnChange,
  useOnPressEnterSubmit,
  useValue,
} from '@mmw/redux-store-fast-form/hooks/fields';
import withFormatDate, {
  WithFormatDateProps,
} from '@mmw/ui-hoc-with-format-date';
import DateInput, { DateInputProps } from '@mmw/ui-web-materials-date-input';
import { useOnFocus } from '@mmw/ui-web-providers-input-handlers';
import React from 'react';

export type OwnProps = {
  fieldPath: FieldPath;
} & Partial<DateInputProps> &
  WithFormatDateProps;

const FastFormDateInput: React.FC<OwnProps> = ({
  fieldPath,
  formatDate,
  ...rest
}: OwnProps) => {
  const value = useValue<Date | number>(fieldPath, new Date());
  const onBlur = useOnBlur(fieldPath);
  const onChange = useOnChange(fieldPath);
  const onPressEnterSubmit = useOnPressEnterSubmit();
  const onFocus = useOnFocus();
  return (
    <DateInput
      name={fieldPath}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      onKeyDown={onPressEnterSubmit}
      formatDate={formatDate}
      {...rest}
    />
  );
};

export default withFormatDate(FastFormDateInput);
