import { StyledProps } from '@mmw/web-theme';

export default {
  style: ({ theme }: StyledProps<unknown>) => `
    display: flex;
    background-color: ${theme.colors.pallet.basicLight};
    border: 1px dashed ${theme.colors.pallet.basic};
    width: 100%;
    height: auto;
    padding: 10px;
    box-sizing: border-box;
    margin: 10px 0;
  `,
  modifiers: undefined,
  block: {
    style: () => `
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
    `,
    modifiers: undefined,
  },
  actions: {
    style: ({ theme }: StyledProps<unknown>) => `
      display: flex;
      width: auto;
      ${theme.media.IE11`height: 20px;`}
    `,
    modifiers: undefined,
  },
  left: {
    style: ({ theme }: StyledProps<unknown>) => `
      height: auto;
      ${theme.media.IE11`height: 20px;`}
    `,
  },
  container: {
    style: ({ theme }: StyledProps<unknown>) => `
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
      width: 100%;
      height: auto;
      margin: 12px;
      ${theme.media.phone`
        margin: 0;
      `}
    `,
  },
  icon: {
    style: ({ theme }: StyledProps<unknown>) => `
      fill: ${theme.colors.pallet.secondary};
    `,
    modifiers: undefined,
  },
  text: {
    style: ({ theme }: StyledProps<unknown>) => `
      color: ${theme.colors.pallet.secondary};
    `,
    modifiers: undefined,
  },
};
