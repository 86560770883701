import { LanguageCode } from '@mmw/constants-languages';
import contextualConfig from '@mmw/contextual-config';
import qs from 'qs';

const BASE_PATH = '/v1/trader/orgunit';

const { salesOrgBrandID } = contextualConfig.application;

export const GetFindOrgunitPath = (language: LanguageCode): string =>
  `${BASE_PATH}?${qs.stringify({
    language,
  })}`;

export const GetCompanyDetailsPath = (orgunitId: number): string =>
  `${BASE_PATH}/${orgunitId}/details`;

export const GetAvailableStoresPath = (): string => `${BASE_PATH}/branches`;

export const GetSalesmenPath = (): string => `${BASE_PATH}/salesmen`;

export const GetOrgunitGroupNamesPath = (): string =>
  `${BASE_PATH}/groups-names-of-orgunit-family`;

export const GetSalesTypePath = (orgunitId: number): string =>
  `${BASE_PATH}/${orgunitId}/sales-type`;

export const CreateBranchPath = (): string => `${BASE_PATH}/branch`;

export const DeleteBranchPath = (id: number): string =>
  `${BASE_PATH}/${id}/delete`;

export const GetCompanyLogoPath = (orgunitId: number): string =>
  `${BASE_PATH}/${orgunitId}/logo`;

export const UpdateMasterDataPath = (id: number): string =>
  `${BASE_PATH}/${id}/master-data`;

export const GetCompanyUserPath = (
  orgunitId: number,
  language: LanguageCode,
  loginUrl: string,
): string =>
  `${BASE_PATH}/${orgunitId}/user?${qs.stringify({
    language,
    loginUrl,
  })}`;

export const GetDeleteCompanyUserPath = (
  orgunitId: number,
  userId: number,
): string => `${BASE_PATH}/${orgunitId}/user/${userId}/delete`;

export const GetStoreLocatorStatusForIdsPath = (id: number): string =>
  `${BASE_PATH}/${id}/store-locator/status?${qs.stringify({
    salesOrgBrandID,
  })}`;

export const GetStoreLocatorActivatePath = (id: number): string =>
  `${BASE_PATH}/${id}/store-locator/activate?${qs.stringify({
    salesOrgBrandID,
  })}`;

export const GetStoreLocatorDeactivatePath = (id: number): string =>
  `${BASE_PATH}/${id}/store-locator/deactivate?${qs.stringify({
    salesOrgBrandID,
  })}`;

export const GetAddOrgunitToGroupPath = (
  id: number,
  groupName: string,
): string => `${BASE_PATH}/${id}/orgunit-group/${groupName}`;
