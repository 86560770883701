import { Epic } from '@redux-async-module/interfaces';
import map from 'lodash/map';
import uuid from 'uuid-random';

// XXX: done to avoid epic redux dynamic module to consider all epics equal
export function fixEpicToString<E extends Function = Epic>(epic: E): E {
  const id = uuid();
  // eslint-disable-next-line no-param-reassign
  epic.toString = function customToString() {
    return id;
  };
  return epic;
}

export function fixEpicArrayToString(epics: Epic[]): Epic[] {
  return map(epics, fixEpicToString);
}
