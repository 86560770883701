export default {
  columns: {
    width: '85%',
    justifyContent: 'space-between',
  },
  toggleIcon: {
    width: '40px',
    justifyContent: 'center',
  },
};
