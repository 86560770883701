import { StyledProps } from '@mmw/ui-theme/types';
import defaultTheme from '@mmw/ui-web-blocks-images-carousel/theme';

const bulletCircleStyle = ({ theme }: StyledProps<unknown>) => `
  width: 26px;
  height: 26px;
  border-radius: 50px;
  border: 1px solid ${theme.colors.pallet.basic};
  background-color: transparent;
  display: flex;
  justify-content: center;
`;

const bulletPinStyle = ({ theme }: StyledProps<unknown>) => `
  width: 20px;
  height: 20px;
  display: flex;
  align-self: center;
  border-radius: 50px;
  background-color: ${theme.colors.pallet.primary};
`;

export default {
  ...defaultTheme,
  style: () => `
  `,
  bullet: {
    circle: {
      style: bulletCircleStyle,
    },
    pin: {
      style: bulletPinStyle,
    },
  },
};
