import { useGetLoginConfigSubmitHandler } from '@mmw/redux-store-auth-api-login-config-by-user/hooks';
import { GetLoginConfigFormData } from '@mmw/redux-store-auth-api-login-config-by-user/hooks/useGetLoginConfigSubmitHandler';
import { useRequestEmailVerification } from '@mmw/redux-store-email-verification/hooks';
import {
  OperationsEnum,
  UserLoginConfiguration,
} from '@mmw/services-auth-api-authentication/types';
import { useCallback } from 'react';
import { F } from 'ts-toolbelt';

import useNavigateToInformToken from './useNavigateToInformToken';
import useNavigateToLoginByPassword from './useNavigateToLoginByPassword';

function useHandleUserLogin(
  showDataPrivacy?: F.Function,
): F.Function<[GetLoginConfigFormData]> {
  const navigateToInformToken = useNavigateToInformToken();
  const navigateToInformPassword = useNavigateToLoginByPassword();
  const requestEmailVerification = useRequestEmailVerification();
  const onSuccess = useCallback(
    (config: UserLoginConfiguration) => {
      if (!config.found && config.allowLoginByTan) {
        requestEmailVerification(OperationsEnum.ORGUNIT_SIGNUP, config);
        if (showDataPrivacy) {
          return showDataPrivacy();
        }
        return navigateToInformToken(config);
      }
      return navigateToInformPassword(config);
    },
    [
      navigateToInformPassword,
      requestEmailVerification,
      showDataPrivacy,
      navigateToInformToken,
    ],
  );
  return useGetLoginConfigSubmitHandler(onSuccess);
}

export default useHandleUserLogin;
