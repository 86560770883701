import { useCallback } from 'react';

import useNavigateTo from './useNavigateTo';

export type UseNavigateToRouteReturn<S = Record<string, any>> = (
  state?: S,
) => void;

type UseNavigateToRoute = (
  route: string,
  options?: {
    disableState?: boolean;
  },
) => UseNavigateToRouteReturn;

const useNavigateToRoute: UseNavigateToRoute = (
  route: string,
  options: {
    disableState?: boolean;
  } = {},
) => {
  const navigate = useNavigateTo();
  return useCallback(
    state => {
      if (options.disableState) {
        navigate(route);
      } else {
        navigate(route, state);
      }
    },
    [navigate, options.disableState, route],
  );
};

export default useNavigateToRoute;
