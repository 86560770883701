import { useNavigate } from '@mmw/ui-web-libraries-react-router/hooks';
import { useCallback } from 'react';
import { F } from 'ts-toolbelt';

import { HELP } from '../constants';

function useNavigateHelp(): F.Function {
  const navigate = useNavigate();
  return useCallback(() => navigate(HELP), [navigate]);
}
export default useNavigateHelp;
