import { Theme } from '@mmw/ui-theme/types';

type Params = {
  theme: Theme;
};

export default ({ theme }: Params): string => `
  justify-content: space-between;
  align-content: stretch;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 0;
  padding-bottom: 0;
  height: auto;
  border-radius: ${theme.dividedButton.variable.BORDER_RADIUS};
  white-space: nowrap;
  ${theme.media.phone`
    white-space: normal;
  `}
`;
