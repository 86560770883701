import { Reducer } from './types';

const INITIAL_STATE = {
  globalVisible: false,
};

const spinnerReducer: Reducer = (state = INITIAL_STATE, action) => {
  if (!action.meta || action.meta.showGlobalSpinner === undefined) {
    return state;
  }

  const { showGlobalSpinner } = action.meta;
  let newCounter = 0;

  if (showGlobalSpinner) {
    newCounter = 1;
  }
  return {
    globalVisible: newCounter > 0,
  };
};

export default spinnerReducer;
