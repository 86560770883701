import { SalesType } from '@mmw/constants-sales-types';
import { AuthenticationResponseWithUser } from '@mmw/redux-store-auth-api-login-by-password/types';
import { SecurityScopeNames } from '@mmw/services-auth-api-authentication/types';
import { ValidateConsumerMailStatus } from '@mmw/services-auth-api-consumer-account/types';
import {
  CreateConsumerLevel4RequestJSON,
  CreateConsumerResponseJSON,
} from '@mmw/services-core-consumer-membership/types';
import { OrgunitSignupResultJSON } from '@mmw/services-core-trader-contracting/types';
import { BusinessRelation } from '@mmw/services-core-trader-orgunit/types';
import { getFieldPaths } from '@mmw/utils-object-utils';
import { F, L, U } from 'ts-toolbelt';

export const ORGUNIT_SIGNUP_START =
  '@mmw/auth-api/user-account/ORGUNIT_SIGNUP_START';
export const ORGUNIT_SIGNUP_SUCCESS =
  '@mmw/auth-api/user-account/ORGUNIT_SIGNUP_SUCCESS';
export const ORGUNIT_SIGNUP_ERROR =
  '@mmw/auth-api/user-account/ORGUNIT_SIGNUP_ERROR';
export const CHECK_CONSUMER_EMAIL_AVAILABILITY_START =
  '@mmw/auth-api/user-account/CHECK_CONSUMER_EMAIL_AVAILABILITY_START';
export const CHECK_CONSUMER_EMAIL_AVAILABILITY_SUCCESS =
  '@mmw/auth-api/user-account/CHECK_CONSUMER_EMAIL_AVAILABILITY_SUCCESS';
export const CHECK_CONSUMER_EMAIL_AVAILABILITY_ERROR =
  '@mmw/auth-api/user-account/CHECK_CONSUMER_EMAIL_AVAILABILITY_ERROR';
export const CREATE_CONSUMER_ACCOUNT_START =
  '@mmw/auth-api/user-account/CREATE_CONSUMER_ACCOUNT_START';
export const CREATE_CONSUMER_ACCOUNT_SUCCESS =
  '@mmw/auth-api/user-account/CREATE_CONSUMER_ACCOUNT_SUCCESS';
export const CREATE_CONSUMER_ACCOUNT_ERROR =
  '@mmw/auth-api/user-account/CREATE_CONSUMER_ACCOUNT_ERROR';
export const DELETE_CONSUMER_ACCOUNT_START =
  '@mmw/auth-api/user-account/DELETE_CONSUMER_ACCOUNT_START';
export const DELETE_CONSUMER_ACCOUNT_SUCCESS =
  '@mmw/auth-api/user-account/DELETE_CONSUMER_ACCOUNT_SUCCESS';
export const DELETE_CONSUMER_ACCOUNT_ERROR =
  '@mmw/auth-api/user-account/DELETE_CONSUMER_ACCOUNT_ERROR';
export const RESET_ACTION = '@mmw/auth-api/user-account/RESET_ACTION';

export class OrgunitSignupFormData {
  email: U.Nullable<string> = null;

  password: U.Nullable<string> = null;

  confirmPassword: U.Nullable<string> = null;

  tan: U.Nullable<string> = null;

  firstName = '';

  lastName = '';

  company = '';

  street = '';

  nr = '';

  // eslint-disable-next-line react/static-property-placement
  displayName = '';

  zipcode = '';

  city = '';

  country = '';

  legalForm = 0;

  taxnumber = '';

  salestaxid = '';

  phone = '';

  terms = false;

  salesType: U.Nullable<SalesType> = null;

  createAcceptedBusinessRelations: L.Nullable<BusinessRelation[]> = [
    {
      offerorrole: '',
      acceptorrole: '',
      salesOrgBrandID: 0,
    },
  ];

  registrationWithoutSalestaxid = false;
}

export const ORGUNIT_SIGNUP_FIELD_PATHS = getFieldPaths(
  new OrgunitSignupFormData(),
);

export type OrgunitSignupStartAction = {
  type: '@mmw/auth-api/user-account/ORGUNIT_SIGNUP_START';
  payload: {
    data: OrgunitSignupFormData;
    onLoginSuccess: F.Function<[AuthenticationResponseWithUser]>;
  };
};

export type OrgunitSignupSuccessAction = {
  type: '@mmw/auth-api/user-account/ORGUNIT_SIGNUP_SUCCESS';
  payload: OrgunitSignupResultJSON;
};

export type OrgunitSignupErrorAction = {
  type: '@mmw/auth-api/user-account/ORGUNIT_SIGNUP_ERROR';
  payload: {
    error: U.Nullable<Error>;
  };
};

export type CheckConsumerMailAvailabilityStartAction = {
  type: '@mmw/auth-api/user-account/CHECK_CONSUMER_EMAIL_AVAILABILITY_START';
  payload: {
    email: string;
    scopeName: SecurityScopeNames;
  };
};

export type CheckConsumerMailAvailabilitySuccessAction = {
  type: '@mmw/auth-api/user-account/CHECK_CONSUMER_EMAIL_AVAILABILITY_SUCCESS';
  payload: ValidateConsumerMailStatus;
};

export type CheckConsumerMailAvailabilityErrorAction = {
  type: '@mmw/auth-api/user-account/CHECK_CONSUMER_EMAIL_AVAILABILITY_ERROR';
  payload: {
    error: U.Nullable<Error>;
  };
};

export type CreateConsumerAccountStartAction = {
  type: '@mmw/auth-api/user-account/CREATE_CONSUMER_ACCOUNT_START';
  payload: Partial<CreateConsumerLevel4RequestJSON>;
};

export type CreateConsumerAccountSuccessAction = {
  type: '@mmw/auth-api/user-account/CREATE_CONSUMER_ACCOUNT_SUCCESS';
  payload: {
    data: CreateConsumerResponseJSON;
  };
};

export type CreateConsumerAccountErrorAction = {
  type: '@mmw/auth-api/user-account/CREATE_CONSUMER_ACCOUNT_ERROR';
  payload: {
    error: U.Nullable<Error>;
  };
};

export type DeleteConsumerAccountStartAction = {
  type: '@mmw/auth-api/user-account/DELETE_CONSUMER_ACCOUNT_START';
};

export type DeleteConsumerAccountSuccessAction = {
  type: '@mmw/auth-api/user-account/DELETE_CONSUMER_ACCOUNT_SUCCESS';
  payload: {
    success: boolean;
  };
};

export type DeleteConsumerAccountErrorAction = {
  type: '@mmw/auth-api/user-account/DELETE_CONSUMER_ACCOUNT_ERROR';
  payload: {
    error: Error;
  };
};

export type ResetAction = {
  type: '@mmw/auth-api/user-account/RESET_ACTION';
};

export type State = {
  data?: U.Nullable<CreateConsumerResponseJSON>;
  loading: boolean;
  error: U.Nullable<Error>;
  success?: U.Nullable<boolean>;
};

export const NAMESPACE = 'userAccount';

export type RootState = {
  userAccount: State;
};

export type Action =
  | OrgunitSignupStartAction
  | OrgunitSignupSuccessAction
  | OrgunitSignupErrorAction
  | CreateConsumerAccountStartAction
  | CreateConsumerAccountSuccessAction
  | CreateConsumerAccountErrorAction
  | CheckConsumerMailAvailabilityStartAction
  | CheckConsumerMailAvailabilitySuccessAction
  | CheckConsumerMailAvailabilityErrorAction
  | DeleteConsumerAccountStartAction
  | DeleteConsumerAccountSuccessAction
  | DeleteConsumerAccountErrorAction
  | ResetAction;

export type Reducer = (state: State, action: Action) => State;
