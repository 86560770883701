import { StyledProps } from '@mmw/ui-theme/types';

export default {
  style: () => `
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    margin: 10px 0;
    width: 100%;
  `,
  icon: {
    style: ({ theme }: StyledProps<{}>) => `
      fill: ${theme.colors.pallet.secondary};
      padding-top: 0;
    `,
    modifiers: undefined,
  },
  iconContainer: {
    style: ({ theme }) => `
      ${theme.media.IE11`
        height: 40px;
      `}
    `,
  },
};
