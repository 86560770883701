import leftArrow from '../assets/PREVIOUS.svg';

export default ({ theme, disabled }) => `
  mask-image: url(${leftArrow});
  width: 25px;
  height: 25px;
  border: none;
  background-color: ${theme.colors.pallet.primary};
  opacity: ${disabled ? '0.3' : '1'};
  cursor: ${disabled ? 'default' : 'pointer'};
  &:focus {
    outline: none;
  }
  ::-moz-focus-inner {
    border: 0;
  }
`;
