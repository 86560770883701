const image = {
  style: () => `
    object-fit: cover;
    max-height: 360px;
    width: 100%;
  `,
};

export default {
  image,
};
