export default () => `
  cursor: pointer;
  text-decoration: none;
  width: fit-content;
  outline: 0;
  display: flex;
  align-items: center;
  &:visited {
    color: inherit;
  }
  &:hover {
    text-decoration: underline;
  }
`;
