import defaultApiV2, { ApiResponse } from '@mmw/api-v2';
import { Brand } from '@mmw/constants-brands';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import autoBind from 'auto-bind';

import {
  ALL_NEWS_PATH,
  BASE_PATH,
  GetReadNewsPath,
  GetUnreadNewsPath,
} from './apiPaths';
import logger from './log';
import { NewsJSON } from './types';

type Api = typeof defaultApiV2;

type NewsServiceOptions = {
  apiv2?: Api;
  authenticationService: AuthenticationService;
};

class NewsService {
  api: Api;

  authenticationService: AuthenticationService;

  constructor({ apiv2, authenticationService }: NewsServiceOptions) {
    this.api = apiv2 || defaultApiV2;
    this.authenticationService = authenticationService;
    autoBind(this);
  }

  async getLatestNews(brand: Brand): Promise<NewsJSON[]> {
    logger.debug(`Trying to get latest news for brand=${brand}`);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<NewsJSON> = await this.api.get(BASE_PATH, {
        params: {
          brand,
        },
        headers,
      });
      logger.info(`Successfully got latest news for brand=${brand}`);
      return Array.isArray(response.data) ? response.data : [response.data];
    } catch (error) {
      logger.error(
        `Error when getting latest news for brand=${brand}, error=%O`,
        error,
      );
      throw error;
    }
  }

  async getAllNewsByRequest(brand: Brand): Promise<NewsJSON[]> {
    logger.debug(`Trying to getAllNewsByRequest from ${ALL_NEWS_PATH}`);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<NewsJSON[]> = await this.api.get(
        ALL_NEWS_PATH,
        {
          params: {
            brand,
          },
          headers,
        },
      );
      logger.info(`Successfully getAllNewsByRequest from ${ALL_NEWS_PATH}`);
      return response.data;
    } catch (error) {
      logger.error(
        `Error on getAllNewsByRequest from ${ALL_NEWS_PATH}, error=%O`,
        error,
      );
      throw error;
    }
  }

  async markNewsAsRead(newsID: string): Promise<NewsJSON> {
    logger.debug(`Trying to mark news as read for newsID=${newsID}`);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<NewsJSON> = await this.api.post(
        GetReadNewsPath(newsID),
        {
          headers,
        },
      );
      logger.info(`Successfully mark news as read for newsID=${newsID}`);
      return response.data;
    } catch (error) {
      logger.error(
        `Error when marking news as read for newsID=${newsID}, error=%O`,
        error,
      );
      throw error;
    }
  }

  async markNewsAsUnread(newsID: string): Promise<NewsJSON> {
    logger.debug(`Trying to mark news as unread for newsID=${newsID}`);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<NewsJSON> = await this.api.post(
        GetUnreadNewsPath(newsID),
        {
          headers,
        },
      );
      logger.info(`Successfully mark news as unread for newsID=${newsID}`);
      return response.data;
    } catch (error) {
      logger.error(
        `Error when marking news as unread for newsID=${newsID}, error=%O`,
        error,
      );
      throw error;
    }
  }
}

export default NewsService;
