import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import { getAuthenticationService } from '@mmw/services-holder';
import { ActionsObservable } from 'redux-observable';
import { concat, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, timeout } from 'rxjs/operators';

import {
  checkAuthenticationErrorAction,
  checkAuthenticationSuccessAction,
} from '../actions';
import {
  CHECK_AUTHENTICATION_START,
  CheckAuthenticationErrorAction,
  CheckAuthenticationStartAction,
  CheckAuthenticationSuccessAction,
} from '../types';

const { logger } = contextualConfig.application;
const { defaultTimeout } = contextualConfig.api;

async function getAuthenticationDetails() {
  const isLoggedIn = await getAuthenticationService().isAuthenticated({
    fetchApi: true,
  });
  if (!isLoggedIn) {
    return {
      isLoggedIn,
      loggedUser: null,
      accessToken: null,
    };
  }
  const loggedUser = await getAuthenticationService().getUserDetails();
  const accessToken = await getAuthenticationService().getAccessToken();
  return {
    isLoggedIn,
    loggedUser,
    accessToken,
  };
}

type Input = CheckAuthenticationStartAction;
type Output = CheckAuthenticationSuccessAction | CheckAuthenticationErrorAction;
const checkAuthentication = (
  action$: ActionsObservable<Input>,
): Observable<Output> =>
  action$.pipe(
    ofType(CHECK_AUTHENTICATION_START),
    tap(() => logger.debug('Will check if user is authenticated')),
    switchMap(() =>
      from(getAuthenticationDetails()).pipe(
        timeout(defaultTimeout),
        map(data =>
          checkAuthenticationSuccessAction(data.loggedUser, data.accessToken),
        ),
        catchError(error => concat(of(checkAuthenticationErrorAction(error)))),
      ),
    ),
  );

export default checkAuthentication;
