import contextualConfig from '@mmw/contextual-config';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { F } from 'ts-toolbelt';

import { createAudit } from '../actions';

const { salesOrgBrandID } = contextualConfig.application;

interface CreateAuditParams {
  storeID: number;
  campaignCode: string;
  checkAndRetrieveAlreadyOpenAudit?: boolean;
  sobID?: number;
}

export function useCreateAudit(): F.Function<[CreateAuditParams]> {
  const dispatch = useDispatch();
  return useCallback(
    ({
      storeID,
      campaignCode,
      checkAndRetrieveAlreadyOpenAudit,
      sobID,
    }: CreateAuditParams) => {
      dispatch(
        createAudit.start(
          { storeID, campaignCode, checkAndRetrieveAlreadyOpenAudit },
          {
            salesOrgBrandID: sobID || salesOrgBrandID,
            showGlobalSpinner: true,
          },
        ),
      );
    },
    [dispatch],
  );
}
