import borderSchemas from '@mmw/ui-theme/modifiers/color/border';
import typographyModifiers from '@mmw/ui-web-elements-typography/theme/modifiers';

import iconStyle from './iconStyle';
import buttonStyle from './style';
import textStyle from './textStyle';

const modifiers = borderSchemas;

const buttonThemeStyle = {
  style: buttonStyle,
  icon: {
    style: iconStyle,
    modifiers,
  },
  text: {
    style: textStyle,
    modifiers: typographyModifiers,
  },
  modifiers,
  variable: {
    BORDER_RADIUS: 0,
  },
};

export default buttonThemeStyle;
