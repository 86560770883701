import { AUTH_TAN_REQUEST } from '@mmw/constants-i18n-authentication';
import { CONFIRM } from '@mmw/constants-i18n-buttons';
import {
  useEmailVerifiedToken,
  useIsLoading,
} from '@mmw/redux-store-email-verification/hooks';
import { useIsGettingToken } from '@recaptcha/v3';
import React from 'react';
import { F } from 'ts-toolbelt';

import TanCodeValidationField from './TanCodeValidationField';

const { TITLE: LABEL_I18N } = AUTH_TAN_REQUEST;

interface Props {
  onVerificationSuccess: F.Function;
  onResendRequest: F.Function;
  operationInProcess?: boolean;
}

const ValidateEmailByTAN: React.FC<Props> = ({
  onVerificationSuccess,
  onResendRequest,
  operationInProcess,
}: Props) => {
  const isLoading = useIsLoading();
  const successStatus = !!useEmailVerifiedToken();
  const isGettingToken = useIsGettingToken('email-verification-request');
  return (
    <>
      <TanCodeValidationField
        fieldLabel={LABEL_I18N}
        buttonI18n={CONFIRM}
        onSuccess={onVerificationSuccess}
        loading={isLoading}
        onResendRequest={onResendRequest}
        operationInProcess={operationInProcess || isGettingToken}
        successStatus={successStatus}
      />
    </>
  );
};

export default ValidateEmailByTAN;
