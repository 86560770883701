import { ApiResponse, default as defaultApiV2 } from '@mmw/api-v2';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import autoBind from 'auto-bind';

import { GetLoggedUserDataPath } from './apiPaths';
import logger from './log';
import { LoggedUserData } from './types';

type Api = typeof defaultApiV2;

type LoggedUserDataServiceOptions = {
  apiv2?: Api;
  authenticationService: AuthenticationService;
};

class LoggedUserDataService {
  api: Api;

  authenticationService: AuthenticationService;

  constructor({ apiv2, authenticationService }: LoggedUserDataServiceOptions) {
    this.api = apiv2 || defaultApiV2;
    this.authenticationService = authenticationService;
    autoBind(this);
  }

  async getLoggedUserData(): Promise<LoggedUserData> {
    logger.debug('Trying to get user logged in data');
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<LoggedUserData> = await this.api.post(
        GetLoggedUserDataPath(),
        {},
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully got logged user data');
      return data;
    } catch (error) {
      logger.error('Error when getting logged user data, error=%O', error);
      throw error;
    }
  }
}

export default LoggedUserDataService;
