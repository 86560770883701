const HOME = '/';
const HELP = '/help';
const PRODUCT_REGISTRATION_ROOT = '/product-registration';
const ONE_SNAP_REGISTRATION_ROOT = '/one-snap-registration';
const PRODUCT_REGISTRATION = `${PRODUCT_REGISTRATION_ROOT}/:campaignId`;
const ONE_SNAP_REGISTRATION = `${ONE_SNAP_REGISTRATION_ROOT}/:campaignId`;
const ONE_SNAP_REGISTRATION_SUCCESS = `${ONE_SNAP_REGISTRATION_ROOT}/success`;
const REGISTRATION_SUCCESS_ROOT = `${PRODUCT_REGISTRATION}/success`;
const REGISTRATION_SUCCESS = `${REGISTRATION_SUCCESS_ROOT}/:registrationId`;
const POS_DOCU_ROOT = '/pos-docu';
const POS_DOCU = '/pos-docu/:campaignId';

const DATA_CORRECTION = '/data-correction/:campaignId/:registrationId';
const MASS_PRODUCT_REGISTRATION = '/mass-product-registration';
const ENTER_EMAIL = '/enter-email';
const LOGIN_BY_PASSWORD = '/login-by-password';
const INFORM_TOKEN = '/inform-token';
const SIGN_UP = '/signup';
const BANK_ACCOUNT = '/bank-account';
const RECOVER_PASSWORD_ROOT = '/recover-password';
const RECOVER_USERNAME = '/recover-username';
const RECOVER_PASSWORD = `${RECOVER_PASSWORD_ROOT}/:uuid`;
const SALES_TYPE = '/sales-type/:storeID';
const AUDIT = '/audit/:storeID';
const PRODUCT_INFO = '/product-info';
const CREATE_BRANCH = '/create-branch';
const AGREEMENTS = '/agreements';
const AGREEMENT = '/agreement/:id';
const DOWNLOADS = '/downloads';
const INFORMATION = '/information';
const NEW_ACCOUNT = '/new-account';

const TERMS = '/terms';
const TAN_CODE_VALIDATION = '/validate-tan';
const LOGOUT = '/logout';
const PROFILE = '/profile';
const LOGIN = '/login';
const SSO = '/sso';
const REGISTRATIONS = '/registrations';
const REGISTRATION = '/registration/:id';

const DEMONSTRATION_DEVICES = '/demonstration-devices';

const PATHS = {
  REGISTRATIONS,
  REGISTRATION,
  SSO,
  PROFILE,
  LOGIN,
  RECOVER_USERNAME,
  TERMS,
  TAN_CODE_VALIDATION,
  LOGOUT,
  HOME,
  HELP,
  PRODUCT_REGISTRATION,
  PRODUCT_REGISTRATION_ROOT,
  ONE_SNAP_REGISTRATION,
  ONE_SNAP_REGISTRATION_ROOT,
  REGISTRATION_SUCCESS,
  ONE_SNAP_REGISTRATION_SUCCESS,
  MASS_PRODUCT_REGISTRATION,
  ENTER_EMAIL,
  LOGIN_BY_PASSWORD,
  INFORM_TOKEN,
  SIGN_UP,
  RECOVER_PASSWORD,
  RECOVER_PASSWORD_ROOT,
  SALES_TYPE,
  AUDIT,
  PRODUCT_INFO,
  CREATE_BRANCH,
  AGREEMENTS,
  AGREEMENT,
  DOWNLOADS,
  INFORMATION,
  DATA_CORRECTION,
  DEMONSTRATION_DEVICES,
  POS_DOCU,
  POS_DOCU_ROOT,
  NEW_ACCOUNT,
};

const configPathTo = (path: string, to: string | number): string =>
  `${path}/${to}`;

const pathToRegistrationSuccess = (
  campaignID: number,
  registrationID: number,
): string =>
  `${PRODUCT_REGISTRATION_ROOT}/${campaignID}/success/${registrationID}`;

export {
  AGREEMENT,
  AGREEMENTS,
  AUDIT,
  BANK_ACCOUNT,
  configPathTo,
  CREATE_BRANCH,
  DATA_CORRECTION,
  PATHS as default,
  DEMONSTRATION_DEVICES,
  DOWNLOADS,
  ENTER_EMAIL,
  HELP,
  HOME,
  INFORM_TOKEN,
  INFORMATION,
  LOGIN,
  LOGIN_BY_PASSWORD,
  LOGOUT,
  MASS_PRODUCT_REGISTRATION,
  NEW_ACCOUNT,
  ONE_SNAP_REGISTRATION,
  ONE_SNAP_REGISTRATION_ROOT,
  ONE_SNAP_REGISTRATION_SUCCESS,
  pathToRegistrationSuccess,
  POS_DOCU,
  POS_DOCU_ROOT,
  PRODUCT_INFO,
  PRODUCT_REGISTRATION,
  PRODUCT_REGISTRATION_ROOT,
  PROFILE,
  RECOVER_PASSWORD,
  RECOVER_PASSWORD_ROOT,
  RECOVER_USERNAME,
  REGISTRATION,
  REGISTRATION_SUCCESS,
  REGISTRATIONS,
  SALES_TYPE,
  SIGN_UP,
  SSO,
  TAN_CODE_VALIDATION,
  TERMS,
};
