import { css } from '@ui-system/css';
import { ContainerProps, Style } from '@ui-system/interfaces';
import { makeStyle } from '@ui-system/style';
import { useCurrentHeaderHeight } from '@ui-system/styles';
import UI from '@ui-system/ui';
import { U } from '@utils/ts';
import React, { useMemo } from 'react';

const innerDefaultStyle = css`
  width: 100%;
  max-width: inherit;
  height: 100%;
`;

interface InnerProps {
  innerStyle?: Style | Style[];
}

export const Inner: React.FC<
  React.PropsWithChildren<InnerProps & ContainerProps>
> = ({
  children,
  innerStyle = innerDefaultStyle,
  ...props
}: React.PropsWithChildren<InnerProps & ContainerProps>) => (
  <UI.Container direction="column" style={innerStyle} {...props}>
    {children}
  </UI.Container>
);

interface StaticContent {
  Inner: typeof Inner;
}

type ContentStyleProps = { headerHeight: U.Nullable<number> };

const useContentDefaultStyle = makeStyle<ContentStyleProps>(
  ({ props }) => css`
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1505px;
    min-height: calc(100vh - ${props?.headerHeight}px);
    overflow-x: hidden;
  `,
);

interface ContentProps {
  contentStyle?: Style | Style[];
}

const Content: React.FC<React.PropsWithChildren<ContentProps>> &
  StaticContent = ({
  children,
  contentStyle,
}: React.PropsWithChildren<ContentProps>) => {
  const headerHeight = useCurrentHeaderHeight();
  const contentDefaultStyle = useContentDefaultStyle({ headerHeight });
  const containerStyle = useMemo(
    () => ({
      ...contentDefaultStyle,
      ...contentStyle,
    }),
    [contentDefaultStyle, contentStyle],
  );
  return <UI.Container style={containerStyle}>{children}</UI.Container>;
};

Content.Inner = Inner;

export default Content;
