import { useJuraAuditStatusChangeOptions } from '@jura/audit-status/hooks';
import {
  ManufacturerAuditState,
  ManufacturerAuditStatusOption,
} from '@manufacturer/audits-ui/types';
import { getConfig } from '@manufacturer/contextual-config';
import { SalesOrgBrand } from '@mmw/constants-salesorgbrand-ids';
import { useOralbAuditStatusChangeOptions } from '@oralb/audit-status/hooks';
import { U } from 'ts-toolbelt';

const {
  application: { salesOrgBrandID },
} = getConfig();

type AuditStatusChangeOptionsHook = (
  auditState: U.Nullable<ManufacturerAuditState>,
) => ManufacturerAuditStatusOption[];

const AUDIT_STATUS_CHANGE_OPTIONS_BY_SOB: Partial<
  Record<SalesOrgBrand, AuditStatusChangeOptionsHook>
> = {
  [SalesOrgBrand.ORALB_DE]: useOralbAuditStatusChangeOptions,
  [SalesOrgBrand.JURA]: useJuraAuditStatusChangeOptions,
};
export const useAuditStatusChangeOptions =
  AUDIT_STATUS_CHANGE_OPTIONS_BY_SOB[salesOrgBrandID];
