import { map } from 'lodash';

type Matches = string | string[];

export function includes(str: string, matches: Matches): boolean {
  if (!str) return false;
  if (typeof matches === 'string') return str.includes(matches);
  if (!Array.isArray(matches)) return false;
  return map(matches, match => str.includes(match)).includes(true);
}

export function repeat(str: string, times: number): boolean {
  return new RegExp(`(.)\\1{${times}}`).test(str);
}

export function composedBySequence(str: string, matches: Matches): boolean {
  if (!str) return false;
  let result = str;
  if (typeof matches === 'string') {
    while (result.indexOf(matches) === 0) result = result.slice(matches.length);
    if (result.length === 0) return true;
    return false;
  }
  if (!Array.isArray(matches)) return false;
  return map(matches, match => {
    while (result.indexOf(match) === 0) result = result.slice(match.length);
    if (result.length === 0) return true;
    return false;
  }).includes(true);
}

export default (str: string) => ({
  includes: (matches: Matches) => includes(str, matches),
  repeat: (times: number) => repeat(str, times),
  composedBySequence: (matches: Matches) => composedBySequence(str, matches),
});
