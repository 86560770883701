import { CountryCode, GERMANY } from '@mmw/constants-country-codes';
import {
  ACCOUNT as VALIDATION,
  COMMON as COMMON_VALIDATION,
} from '@mmw/constants-fields-validations';
import {
  ACCOUNT as I18N_ACCOUNT,
  DEFAULT as I18N,
} from '@mmw/constants-i18n-validations';
import { IBAN_REGEX } from '@mmw/constants-iban';
import { ANONIMIZED_DATA } from '@mmw/constants-validation-yup-tests';
import { get, isEmpty, replace, toUpper } from 'lodash';
import { boolean, string, StringSchema } from 'yup';

const ACCOUNTOWNER = string()
  .nullable()
  .max(VALIDATION.ACCOUNTOWNER.MAX_LENGTH)
  .test(ANONIMIZED_DATA);

const ACCOUNT = string().nullable().max(VALIDATION.ACCOUNT.MAX_LENGTH);

const COUNTRY: StringSchema<CountryCode> = string().max(
  VALIDATION.COUNTRY.MAX_LENGTH,
);

const BIC = string().nullable().max(VALIDATION.BIC.MAX_LENGTH);

const BIC_REQUIRED_OUTSIDE_GERMANY = BIC.test({
  name: 'bicRequiredOutsideGermany',
  message: I18N.REQUIRED.key,
  test: function testBicOutsideGermany(value) {
    const bankAccount = this.parent;
    const country = get(bankAccount, 'country');
    if (GERMANY !== country) {
      return !isEmpty(value);
    }
    return true;
  },
});

export function testIbanRegexByCountry(country: string, iban: string): boolean {
  if (!country) {
    return true;
  }
  const ibanRegex = IBAN_REGEX[toUpper(country)];
  if (!ibanRegex) {
    return true;
  }
  const cleanIban = replace(toUpper(iban), /[\n\r\s\t_]+/g, '');
  return ibanRegex.test(cleanIban);
}

const IBAN = string()
  .nullable()
  .test({
    name: 'ibanPatternInvalid',
    message: I18N_ACCOUNT.IBAN.PATTERN.key,
    test: function testIbanPattern(iban) {
      if (isEmpty(iban)) {
        return true;
      }
      const bankAccount = this.parent;
      const country = get(bankAccount, 'country');
      return testIbanRegexByCountry(country, iban);
    },
  })
  .transform(value => replace(toUpper(value), /[\n\r\s\t]+/g, ''))
  .max(VALIDATION.IBAN.MAX_LENGTH);

const AGENCY = string().nullable().max(VALIDATION.AGENCY.MAX_LENGTH);

const MANUALBANKNAME = string()
  .nullable()
  .max(VALIDATION.MANUALBANKNAME.MAX_LENGTH);

const SEND_DOCUMENT_TO_EMAIL = boolean();

const DOCUMENT_EMAIL = string()
  .nullable()
  .max(COMMON_VALIDATION.EMAIL.MAX_LENGTH)
  .email(I18N.EMAIL.key);

const ACCOUNT_TO_ALL_BRANCHES = boolean();

export const ACCOUNT_OBJ = {
  ACCOUNTOWNER,
  ACCOUNTOWNER_REQUIRED: ACCOUNTOWNER.required(I18N.REQUIRED.key),
  ACCOUNT,
  ACCOUNT_REQUIRED: ACCOUNT.required(I18N.REQUIRED.key),
  COUNTRY,
  COUNTRY_REQUIRED: COUNTRY.required(I18N.REQUIRED.key),
  BIC,
  BIC_REQUIRED: BIC.required(I18N.REQUIRED.key),
  BIC_REQUIRED_OUTSIDE_GERMANY,
  IBAN,
  IBAN_REQUIRED: IBAN.required(I18N.REQUIRED.key),
  AGENCY,
  AGENCY_REQUIRED: AGENCY.required(I18N.REQUIRED.key),
  MANUALBANKNAME,
  MANUALBANKNAME_REQUIRED: MANUALBANKNAME.required(I18N.REQUIRED.key),
  SEND_DOCUMENT_TO_EMAIL,
  DOCUMENT_EMAIL,
  ACCOUNT_TO_ALL_BRANCHES,
};

export default ACCOUNT_OBJ;
