import { Theme } from '../../types';

/*
TODO
it's actually hard-coded
could create a factory function
*/

type Props = {
  theme: Theme;
};

const primary = ({ theme }: Props): string => `
  background-color: ${theme.colors.primary.fill};
  color: ${theme.colors.primary.font};
  border-color: ${theme.colors.primary.darken(0.3)};
`;
const secondary = ({ theme }: Props): string => `
  background-color: ${theme.colors.secondary.fill};
  color: ${theme.colors.secondary.font};
  border-color: ${theme.colors.secondary.darken(0.3)};
`;
const dark = ({ theme }: Props): string => `
  background-color: ${theme.colors.dark.fill};
  color: ${theme.colors.dark.font};
  border-color: ${theme.colors.dark.darken(0.3)};
`;
const light = ({ theme }: Props): string => `
  background-color: ${theme.colors.light.fill};
  border-color: ${theme.colors.light.darken(0.3)};
  color: ${theme.colors.light.font};
`;
const basic = ({ theme }: Props): string => `
  background-color: ${theme.colors.basic.fill};
  color: ${theme.colors.basic.font};
  border-color: ${theme.colors.basic.darken(0.3)};
`;
const basicDark = ({ theme }: Props): string => `
  background-color: ${theme.colors.basicDark.fill};
  color: ${theme.colors.basicDark.font};
  border-color: ${theme.colors.basicDark.darken(0.3)};
`;
const basicLight = ({ theme }: Props): string => `
  background-color: ${theme.colors.basicLight.fill};
  color: ${theme.colors.basicLight.font};
  border-color: ${theme.colors.basicLight.darken(0.3)};
`;
const error = ({ theme }: Props): string => `
  border-color: ${theme.colors.error.fill};
  color: ${theme.colors.error.fill};
`;
const success = ({ theme }: Props): string => `
  background-color: ${theme.colors.light.fill};
  border-color: ${theme.colors.success.fill};
  color: ${theme.colors.success.fill};
`;

const borderSchemas = {
  primary,
  secondary,
  dark,
  light,
  basic,
  basicDark,
  basicLight,
  error,
  success,
};

export {
  basic,
  basicDark,
  basicLight,
  dark,
  borderSchemas as default,
  error,
  light,
  primary,
  secondary,
  success,
};
