const TYPES = {
  SMS_REGISTRATION_STATUS_DOUBLE_OPT_IN: 0,
  NEWSLETTER_DOUBLE_OPT_IN: 1,
  REGISTRATION_DOUBLE_OPT_IN: 2,
  BRAND_DATA_PRIVACY: 3,
  ORDER_DATA_PROCESSING: 4,
  CAMPAIGN_ACTIVATION_TERMS: 5,
  PROVISIONAL_ORDER_DATA_PROCESSING: 6,
  RETAIL_CLIENT_CONDITIONS: 7,
  CONTRACT: 10,
  DIGITAL_CONTRACT: 10,
  PHYSICAL_CONTRACT: 11, // RENAMED contract to
};

const AGREEMENT = {
  TYPES,
};

export type AgreementTypesType = typeof TYPES;

export { AGREEMENT as default, TYPES };
