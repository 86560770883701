import styled from '@emotion/styled';
import componentModifiers from '@ui-system/default-modifiers/link';
import { useLinkStyle } from '@ui-system/default-styles/link';
import { LinkProps } from '@ui-system/interfaces-link';
import { Theme } from '@ui-system/theme';
import UI from '@ui-system/ui';
import React from 'react';

const StyledLink = styled.a(
  ({ theme, modifiers }: { theme?: Theme; modifiers?: string }) => `
  &:hover {
    * {
      text-decoration: underline;
      ${
        !modifiers
          ? `
      
        color: ${theme?.colors[theme.mode].primary.main} !important;
      `
          : ''
      }
    }
    text-decoration: underline;    
  }
`,
);

const Link: React.FC<LinkProps> = ({
  href,
  style,
  children,
  i18n,
  modifiers,
  onClick,
  textVariant,
  ...props
}: LinkProps) => {
  const stl = useLinkStyle(style, modifiers, componentModifiers);
  return (
    <StyledLink
      rel="noreferrer"
      href={href}
      style={stl}
      onClick={onClick}
      target="_blank"
      modifiers={modifiers}
      {...props}
    >
      {children || (
        <UI.Body2 i18n={i18n} modifiers={modifiers} variant={textVariant} />
      )}
    </StyledLink>
  );
};

export default Link;
