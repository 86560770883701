import { BRAND_MAPPING } from '@mmw/constants-salesorgbrand-ids';
import contextualConfig from '@mmw/contextual-config';

export const getBrandId = () => {
  const { application } = contextualConfig;
  const { salesOrgBrandID } = application;
  return BRAND_MAPPING[salesOrgBrandID];
};

export const getSalesOrgBrandId = () => {
  const { application } = contextualConfig;
  const { salesOrgBrandID } = application;
  return salesOrgBrandID;
};
