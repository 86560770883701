import { createIssuesForApprovedForInitialOrder } from '@audit/utils/createIssuesForApprovedForInitialOrder';
import { hasEverHadUploadIssues } from '@audit/utils/hasEverHadUploadIssues';
import { CampaignStatus } from '@mmw/constants-campaign-status';
import { CampaignType } from '@mmw/constants-campaign-types';
import { EMPTY_ARRAY } from '@mmw/constants-utils';
import { RegistrationResponseJSON } from '@mmw/services-core-trader-registration/types';
import { findCampaignItemByCampaignType } from '@mmw/utils-campaign-item';
import { isEmpty } from 'lodash';
import { U } from 'ts-toolbelt';

import {
  JURA_ALLOWED_STATUS_TO_CREATE_ISSUES,
  JURA_CAMPAIGN_STATUS_MAPPING,
  JURA_EMPTY_AUDIT_STATE,
} from './constants';
import { JuraAuditState, JuraAuditStatus } from './types';

// XXX:DANGER, ABD LOGIC MODIFIED
// just the creator of this can fix properly, for now its working
export function mapJuraCampaignToAuditStatus(
  s: CampaignStatus,
): JuraAuditStatus {
  // if (s === CampaignStatus.INVALID_DATA ) return JuraAuditStatus.OPEN;
  // return findKey(JURA_CAMPAIGN_STATUS_MAPPING, v => s === v) as JuraAuditStatus;
  return JURA_CAMPAIGN_STATUS_MAPPING[s] as JuraAuditStatus;
}

export function getAllowedStatusTransitions(
  status: JuraAuditStatus,
): JuraAuditStatus[] {
  switch (status) {
    case JuraAuditStatus.OPEN:
      return [JuraAuditStatus.REJECTED];
    case JuraAuditStatus.NEEDS_REVISION:
      return [JuraAuditStatus.REJECTED, JuraAuditStatus.APPROVED];
    case JuraAuditStatus.TRANSMITTED:
      return [
        JuraAuditStatus.REJECTED,
        JuraAuditStatus.APPROVED,
        JuraAuditStatus.NEEDS_REVISION,
      ];
    case JuraAuditStatus.REJECTED:
      return EMPTY_ARRAY;
    case JuraAuditStatus.APPROVED:
      return EMPTY_ARRAY;
    default:
      return EMPTY_ARRAY;
  }
}

export function juraCreateAuditState(
  registration: U.Nullable<RegistrationResponseJSON>,
): JuraAuditState {
  if (!registration) {
    return JURA_EMPTY_AUDIT_STATE;
  }
  const campaignItem = findCampaignItemByCampaignType(
    registration.campaignItems,
    CampaignType.AUDIT,
  );
  const status: JuraAuditStatus = mapJuraCampaignToAuditStatus(
    campaignItem?.campaignStatus,
  );

  return {
    registration,
    id: registration.id,
    created: true,
    status,
    canOrderProducts:
      status === JuraAuditStatus.APPROVED &&
      !isEmpty(registration.externalOrdernumber),
    canStartAudit: status === JuraAuditStatus.OPEN,
    canShowFilesCard:
      status === JuraAuditStatus.APPROVED ||
      status === JuraAuditStatus.TRANSMITTED ||
      (status === JuraAuditStatus.NEEDS_REVISION &&
        hasEverHadUploadIssues(registration)),
    canCompleteAudit:
      status === JuraAuditStatus.APPROVED ||
      status === JuraAuditStatus.NEEDS_REVISION,
    canUploadSellout: status === JuraAuditStatus.APPROVED,
    allowedStatusTransitions: getAllowedStatusTransitions(status),
    isRejected: status === JuraAuditStatus.REJECTED,
    allowedStatusToCreateIssues: JURA_ALLOWED_STATUS_TO_CREATE_ISSUES,
    canCreateIssues: status === JuraAuditStatus.OPEN,
    createIssuesOnTransition: {
      [JuraAuditStatus.APPROVED]:
        createIssuesForApprovedForInitialOrder(registration),
    },
  };
}
