export default ({ theme }) => `
padding: 1.5rem;
transition: 0.4s;
cursor: pointer;
&:hover {
  background-color: ${theme.colors.pallet.primary};
  color: ${theme.colors.pallet.light};
  a, span, p {
    color: ${theme.colors.pallet.light};
  }
}
&.active {
  background-color: ${theme.colors.pallet.primary};
  color: ${theme.colors.pallet.light};
  a, span, p {
    background-color: ${theme.colors.pallet.primary};
    color: ${theme.colors.pallet.light};
  }
}
`;
