import {
  SecurityScopeNames,
  UnblockResponse,
} from '@mmw/services-auth-api-authentication/types';
import { U } from 'ts-toolbelt';

export const REQUEST_UNBLOCK_START =
  '@mmw/auth-api/request-unblock/REQUEST_UNBLOCK_START';
export const REQUEST_UNBLOCK_SUCCESS =
  '@mmw/auth-api/request-unblock/REQUEST_UNBLOCK_SUCCESS';
export const REQUEST_UNBLOCK_ERROR =
  '@mmw/auth-api/request-unblock/REQUEST_UNBLOCK_ERROR';
export const RESET_ACTION = '@mmw/auth-api/request-unblock/RESET_ACTION';

export type RequestUnblockPayload = {
  username: string;
  recaptchaResponse: string;
  recaptchaType: 'V2' | 'V3';
  language: string;
  applicationId?: U.Nullable<string>;
  redirectUrl?: U.Nullable<string>;
  scopeName?: SecurityScopeNames;
  applicationBaseUrl?: U.Nullable<string>;
  applicationContextPath?: U.Nullable<string>;
};

export type RequestUnblockStartAction = {
  type: '@mmw/auth-api/request-unblock/REQUEST_UNBLOCK_START';
  payload: RequestUnblockPayload;
};

export type RequestUnblockSuccessAction = {
  type: '@mmw/auth-api/request-unblock/REQUEST_UNBLOCK_SUCCESS';
  payload: UnblockResponse;
};

export type RequestUnblockErrorAction = {
  type: '@mmw/auth-api/request-unblock/REQUEST_UNBLOCK_ERROR';
  payload: {
    error: Error | null;
  };
};

export type ResetRequestUnblockStatusAction = {
  type: '@mmw/auth-api/request-unblock/RESET_ACTION';
};

export type State = {
  loading: boolean;
  error: Error | null;
  unblockStatus: boolean;
};

export const NAMESPACE = 'authenticationRequestUnblock';

export type RootState = {
  authenticationRequestUnblock: State;
};

export type Action =
  | RequestUnblockStartAction
  | RequestUnblockSuccessAction
  | RequestUnblockErrorAction
  | ResetRequestUnblockStatusAction;

export type Reducer = (state: State, action: Action) => State;
