import { LanguageCode } from '@mmw/constants-languages';
import { RegistrationSource } from '@mmw/constants-registration-sources';
import { accessTokenQuery } from '@mmw/services-auth-api-authentication';
import { languageQuery } from '@mmw/utils-query-utils';
import qs from 'qs';
import { U } from 'ts-toolbelt';

const BASE_PATH = '/v1/trader/registration';

export const PerformRegistrationPath = (
  simulate: boolean,
  source: RegistrationSource,
): string =>
  `${BASE_PATH}?${qs.stringify({
    simulate,
    source,
  })}`;

export const GetRegistrationPath = (
  registrationId: number,
  language: LanguageCode,
): string =>
  `${BASE_PATH}/${registrationId}?${qs.stringify({
    language,
  })}`;

export const GetSearchRegistrationsPath = (language: LanguageCode): string =>
  `${BASE_PATH}/search?${qs.stringify({
    language,
  })}`;

export const UploadDataPrivacyFilePath = (
  registrationId: number,
  language: LanguageCode,
): string =>
  `${BASE_PATH}/${registrationId}/data-privacy-file?${qs.stringify({
    language,
  })}`;

export const SetDataPrivacyConsentPath = (
  registrationId: number,
  value: boolean,
  language: LanguageCode,
): string =>
  `${BASE_PATH}/${registrationId}/data-privacy-consent?${qs.stringify({
    language,
    value,
  })}`;

export const GetCampaignScoresForTraderPath = (
  campaignCode: string,
  salesOrgBrandID: number,
): string =>
  `${BASE_PATH}/score-campaigns/trader-score?${qs.stringify({
    campaignCode,
    salesOrgBrandID,
  })}`;

export const RegisterThirdPartyCampaignItemPath = (
  registrationId: number,
  language: LanguageCode,
): string =>
  `${BASE_PATH}/${registrationId}/third-party-campaign?${qs.stringify({
    language,
  })}`;

export const UpdateCampaignItemsQuestionsPath = (
  registrationId: number,
  language: LanguageCode,
): string =>
  `${BASE_PATH}/${registrationId}/questions?${qs.stringify({
    language,
  })}`;

export const UpdateFilesByTypePath = (
  registrationId: number,
  language: LanguageCode,
  ignoreStatusChangeAfterCorrection = true,
): string =>
  `${BASE_PATH}/${registrationId}/files-by-type?${qs.stringify({
    language,
    ignoreStatusChangeAfterCorrection,
  })}`;

export const GetInvoiceFilePath = (
  fileID: number,
  registrationId: number,
  language: LanguageCode,
  headers: Record<string, U.Nullable<string>>,
): string =>
  `${BASE_PATH}/${registrationId}/invoice-file/${fileID}?${qs.stringify({
    language,
    ...headers,
  })}`;

export const RemoveFileByIdPath = (
  registrationId: number,
  fileId: number,
  language: LanguageCode,
  ignoreStatusChangeAfterCorrection = true,
): string =>
  `${BASE_PATH}/${registrationId}/invoice-file/${fileId}/delete?${qs.stringify({
    language,
    ignoreStatusChangeAfterCorrection,
  })}`;

export const DownloadThirdPartyCampaignItemContractPath = (
  registrationId: number,
  thirdPartyCampaignItemId: number,
  accessToken: string,
  language: LanguageCode,
): string =>
  `${BASE_PATH}/${registrationId}/third-party-campaign/${thirdPartyCampaignItemId}/contract?${accessTokenQuery(
    accessToken,
  )}&${qs.stringify({ language })}`;

export const DownloadRegistrationDocumentFilePath = (
  registrationId: number,
  accessToken: string,
  language: LanguageCode,
): string =>
  `${BASE_PATH}/${registrationId}/document?${accessTokenQuery(
    accessToken,
  )}&${languageQuery(language)}`;

export const CampaignImagePath = (campaignID: number): string =>
  `/v1/campaign/image/${campaignID}`;

export const ChangeRegistrationStatusPath = (): string =>
  `${BASE_PATH}/change-status`;

export const ChangeRegistrationValidationErrorPath = (): string =>
  `${BASE_PATH}/change-validation-errors`;

export const PerformIssueActionPath = (
  registrationID: number,
  issueID: number,
  language: LanguageCode,
): string =>
  `${BASE_PATH}/${registrationID}/issue/${issueID}?language=${language}`;

export const GetCreateIssuesPath = (): string => `${BASE_PATH}/issues`;

export const GetCampaignRankingForTraderPath = (
  campaignCode: string,
  salesOrgBrandID: number,
  orgunitID: number,
): string =>
  `${BASE_PATH}/score-campaigns/trader-ranking?${qs.stringify({
    campaignCode,
    salesOrgBrandID,
    orgunitID,
  })}`;

export const GetProductSelectionPath = (
  registrationId: number,
  language: LanguageCode,
): string =>
  `${BASE_PATH}/${registrationId}/product-selection?${qs.stringify({
    language,
  })}`;

export const GetPurchaseSelectionPath = (
  registrationId: number,
  language: LanguageCode,
): string =>
  `${BASE_PATH}/${registrationId}/purchase-selection?${qs.stringify({
    language,
  })}`;

export const DownloadRegistrationAsXLSPath = (language: LanguageCode): string =>
  `${BASE_PATH}/download-xls?${qs.stringify({
    language,
  })}`;

export const ReverseRegistrationPath = (language: LanguageCode): string =>
  `${BASE_PATH}/reverse?${qs.stringify({
    language,
  })}`;

export const RevalidateRegistrationPath = (language: LanguageCode): string =>
  `${BASE_PATH}/revalidate?${qs.stringify({
    language,
  })}`;

export const GetOneSnapRegistrationPath = () => '/one-snap/read-json';

export const GetOneSnapRegistrationWithStreamPath = () =>
  '/one-snap/read-json-stream';
