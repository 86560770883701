const namespace = 'consumerProfile';

const PROFILE = {
  DELETE_ACCOUNT: {
    TITLE: {
      key: `${namespace}.deleteAccountTitle`,
      message: 'Delete account',
    },
    ACTION: {
      key: `${namespace}.deleteUserAccountAction`,
      message: 'Delete my account',
    },
    CONFIRMATION: {
      key: `${namespace}.deleteUserAccountConfirmation`,
      message:
        'Are you sure you want to delete the account associated with { email }',
    },
    WARNING: {
      key: `${namespace}.deleteUserAccountWarning`,
      message: 'This action cannot be undone',
    },
    SUCCESS: {
      key: `${namespace}.deleteAccountSuccess`,
      message: 'Account deletion process completed with sucess',
    },
    ERROR: {
      key: `${namespace}.deleteAccountFailed`,
      message:
        'For some reason your delete account request failed, please try again later',
    },
  },
};

export default PROFILE;
