import { enableBatching } from 'redux-batched-actions';

import authenticationRequestUnblockEpic from './epics/authenticationRequestUnblock';
import authenticationReducer from './reducer';
import { NAMESPACE as authenticationNamespace } from './types';

export const reducer = authenticationReducer;
export const NAMESPACE = authenticationNamespace;
export const epics = [authenticationRequestUnblockEpic({})];

export function authenticationRequestUnblockModule(props) {
  return {
    id: NAMESPACE,
    reducerMap: {
      [NAMESPACE]: enableBatching(reducer),
    },
    epics: [authenticationRequestUnblockEpic(props)],
  };
}
