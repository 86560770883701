import { IssueActionRequired } from '@issue/interfaces';
import { RegistrationResponseJSON } from '@mmw/services-core-trader-registration/types';
import find from 'lodash/find';
import { U } from 'ts-toolbelt';

export function hasEverHadUploadIssues(
  registration: U.Nullable<RegistrationResponseJSON>,
): boolean {
  if (!registration) return false;
  return (
    find(
      registration.issues,
      issue =>
        issue.actionRequired ===
        IssueActionRequired.CORRECT_QUESTION_ATTACHMENT_FILE,
    ) != null
  );
}
