import { Title } from '@mmw/services-core-common/types';

import {
  GET_AVAILABLE_TITLES_ERROR,
  GET_AVAILABLE_TITLES_START,
  GET_AVAILABLE_TITLES_SUCCESS,
  GetAvailableTitlesErrorAction,
  GetAvailableTitlesStartAction,
  GetAvailableTitlesSuccessAction,
} from './types';

export const getAvailableTitlesStartAction =
  (): GetAvailableTitlesStartAction => ({
    type: GET_AVAILABLE_TITLES_START,
  });

type GetAvailableTitlesSuccess = (
  availableTitles: Array<Title>,
) => GetAvailableTitlesSuccessAction;

export const getAvailableTitlesSuccessAction: GetAvailableTitlesSuccess =
  availableTitles => ({
    type: GET_AVAILABLE_TITLES_SUCCESS,
    payload: {
      availableTitles,
    },
  });

export const getAvailableTitlesErrorAction = (
  error: Error,
): GetAvailableTitlesErrorAction => ({
  type: GET_AVAILABLE_TITLES_ERROR,

  payload: {
    error,
  },
});

const initialActions = [getAvailableTitlesStartAction()];

export default initialActions;
