import { State } from './types';

export const NAMESPACE = 'availableSalutations';

type RootState = {
  availableSalutations: State;
};

export const availableSalutationsSelector = (state: RootState) =>
  state[NAMESPACE].availableSalutations;
