import { use } from '@mmw/ui-theme/utils';
import Button from '@mmw/ui-web-materials-button';
import React from 'react';
import styled from 'styled-components';

const LeftArrow = styled(Button)(props => use(props, 'dateInput.leftArrow'));

const RightArrow = styled(Button)(props => use(props, 'dateInput.rightArrow'));

type Props = {
  onPreviousClick?: Function;
  onNextClick?: Function;
};

const DatePickerNavbar = ({ onPreviousClick, onNextClick }: Props) => (
  <>
    <LeftArrow onClick={onPreviousClick && (() => onPreviousClick())} />
    <RightArrow onClick={onNextClick && (() => onNextClick())} />
  </>
);

export default DatePickerNavbar;
