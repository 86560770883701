type ImageSource = string | Array<string>;

const images = (src: ImageSource) =>
  Array.isArray(src)
    ? src.map(imageSource => `url(${imageSource})`).join(', ')
    : `url(${src})`;

const positions = (padding?: ImageSource, from?: string) =>
  Array.isArray(padding)
    ? padding
        .map(
          imagePadding => `${from || 'right'} ${imagePadding || '0'}px top 50%`,
        )
        .join(', ')
    : `${from || 'right'} ${padding || '0'}px top 50%`;

const image = (
  src: ImageSource,
  padding?: ImageSource,
  size?: ImageSource,
  from?: string,
) => `
  background-image: ${images(src)};
  background-position: ${positions(padding, from)};
  background-repeat: no-repeat;
  background-size: ${size || 'contain'};
`;

const background = {
  image,
};

export { background as default, image };
