import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { authenticateTraderBySSOTokenStartAction } from '../actions';

const useAuthenticateTraderBySSOStart = () => {
  const dispatch = useDispatch();
  return useCallback(
    (site: string, params: any) => {
      dispatch(authenticateTraderBySSOTokenStartAction(site, params));
    },
    [dispatch],
  );
};

export default useAuthenticateTraderBySSOStart;