import { connect as formikConnect, getIn } from '@mmw/ui-libraries-formik-v2';
import { compose, HOC, mapProps } from 'recompose';

import { WithDefaultValueProps, WithFieldPathProps } from '../types';

const withValue: HOC<unknown, unknown> = compose(
  formikConnect,
  mapProps(
    ({
      formik: { values },
      fieldPath,
      defaultValue,
      ...rest
    }: WithFieldPathProps & WithDefaultValueProps) => ({
      ...rest,
      value: getIn(values, fieldPath, defaultValue || ''),
      fieldPath,
    }),
  ),
);

export default withValue;
