import { StyledProps } from '@mmw/ui-theme/types';
import iconModifiers from '@mmw/ui-web-elements-icon/theme/modifiers';
import iconStyle from '@mmw/ui-web-materials-link/theme/iconStyle';
import { linkTextsModifiers } from '@mmw/ui-web-materials-link/theme/modifiers';

export default {
  style: ({ theme }: StyledProps<unknown>) => `
    cursor: pointer;
    text-decoration: none;
    width: fit-content;
    outline: 0;
    display: flex;
    align-items: center;
    &:visited {
      color: inherit;
    }
    &:hover {
      text-decoration: underline;
      color: ${theme.colors.pallet.light};
    }
  `,
  icon: {
    style: iconStyle,
    modifiers: iconModifiers,
  },
  text: {
    style: ({ theme }: StyledProps<{}>) => `
      color: ${theme.colors.pallet.light};
    `,
    modifiers: linkTextsModifiers,
  },
};
