import { BAD_CREDENTIALS } from '@mmw/constants-i18n-authentication';
import { DEFAULT } from '@mmw/constants-i18n-validations';
import { EMAIL_REGEX } from '@mmw/constants-validation-field-schemas/utils';
import { SecurityScopeNames } from '@mmw/services-auth-api-authentication/types';
import ConsumerAccountService from '@mmw/services-auth-api-consumer-account';
import debounce from 'lodash/debounce';
import { U } from 'ts-toolbelt';
import { AnyObject, TestFunction } from 'yup';

const MESSAGE_KEY = DEFAULT.ACCOUNT_CREATION.EMAIL_NOT_AVAILABLE.key;
const TEST_NAME = 'consumer-email-availability';

const service = new ConsumerAccountService({});

const debouncedEmailValidation = debounce(
  async (value, scopeNames) =>
    service.validateConsumerMailAvailability(value as string, scopeNames),
  1500,
  { leading: false },
);

export function getEmailAvailabilityTest(
  scopeNames: SecurityScopeNames[],
  message: string = MESSAGE_KEY,
): {
  name: string;
  message: string;
  test: TestFunction<U.Nullable<string>, AnyObject>;
} {
  return {
    name: TEST_NAME,
    message,
    async test(value: U.Nullable<string>): Promise<boolean> {
      if (this.parent.id && this.parent.email === this.parent.userid) {
        return true;
      }

      if (value && !EMAIL_REGEX.test(value)) {
        return true;
      }

      if (!value) return true;

      const result = await debouncedEmailValidation(value, scopeNames);
      return result?.success as boolean;
    },
  };
}

export function checkIfEmailExists(
  scopeNames: SecurityScopeNames[],
  message: string = BAD_CREDENTIALS.DESCRIPTION.key,
): {
  name: string;
  message: string;
  test: TestFunction<U.Nullable<string>, AnyObject>;
} {
  return {
    name: 'is-a-valid-consumer-email',
    message,
    async test(value: U.Nullable<string>): Promise<boolean> {
      if (value && !EMAIL_REGEX.test(value)) {
        return true;
      }

      if (!value) return true;

      const result = await service.validateConsumerMailAvailability(
        value as string,
        scopeNames,
      );
      return !result?.success as boolean;
    },
  };
}
