import { addReducerSimpleAsyncCases } from '@redux-async-module/reducer-utils';
import { createReducer } from '@redux-basic-module/reducer-utils/ReducerBuilder';
import unionBy from 'lodash/unionBy';

import {
  getConsumerBankData,
  resetBankDataAction,
  resetStatusAction,
  saveConsumerBankData,
} from './actions';
import { BankDataSuccessPayload, Reducer } from './types';

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: null,
  requestAttempted: false,
  success: false,
  updateDataAttempted: false,
};

const reducer: Reducer = createReducer(INITIAL_STATE, builder => {
  addReducerSimpleAsyncCases<void, BankDataSuccessPayload>(
    INITIAL_STATE,
    getConsumerBankData,
    builder,
  );
  builder
    .addCase(saveConsumerBankData.start, draft => {
      draft.loading = true;
      draft.error = null;
      draft.updateDataAttempted = true;
      return draft;
    })
    .addCase(saveConsumerBankData.success, (draft, { payload }) => {
      draft.loading = false;
      draft.data = unionBy([payload], draft.data, 'accountID');
      draft.success = true;
      draft.updateDataAttempted = false;
      return draft;
    })
    .addCase(saveConsumerBankData.error, (draft, { payload }) => {
      draft.loading = false;
      draft.error = payload;
      return draft;
    })
    .addCase(resetStatusAction, draft => ({
      ...draft,
      success: false,
      error: null,
      requestAttempted: false,
    }))
    .addCase(resetBankDataAction, () => INITIAL_STATE);
});

export default reducer;
