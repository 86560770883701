import { StyledProps } from '@mmw/web-theme';

export default ({ theme }: StyledProps<unknown>) => `
  display: flex;
  list-style: none;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.pallet.basicLight};
  color: ${theme.colors.pallet.dark};
  box-shadow: 0 4px 20px rgba(0,0,0,0.19),0 0 6px 0 rgba(0,0,0,0.23);
  padding: 0;
  margin: 0;
`;
