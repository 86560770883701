import ofType, { BatchAction } from '@mmw/redux-rx-of-type-operator';
import { refreshAccessToken } from '@mmw/redux-store-auth-api-authentication/actions';
import { createBranch } from '@mmw/redux-store-core-trader-create-branch/actions';
import { batchActions } from 'redux-batched-actions';
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  getAvailableStoresStartAction,
  getLoggedTraderDataStartAction,
} from '../actions';

const watchBranchCreation = (
  action$: ActionsObservable<any>,
): Observable<BatchAction<any>> =>
  action$.pipe(
    ofType(createBranch.start.match),
    map(
      () =>
        <BatchAction<any>>(
          batchActions([
            getLoggedTraderDataStartAction(),
            getAvailableStoresStartAction(),
            refreshAccessToken.start(null, null),
          ])
        ),
    ),
  );

export default watchBranchCreation;
