import { UserField } from '@auth/view-form-components';
import {
  RecoverUserIdStoreProvider,
  useError,
  useIsLoading,
  useRecoverUserIdStart,
  useResetRecoverUserId,
  useSuccess,
} from '@authentication/recover-mmw-userid-store';
import { FastFormSubmitButton } from '@fast-form/ui-system';
import { BACK, CANCEL, CONFIRM } from '@mmw/constants-i18n-buttons';
import { PERSON as PERSON_I18N } from '@mmw/constants-i18n-fields';
import { UTILS } from '@mmw/constants-validation-field-schemas';
import { FastFormProvider } from '@mmw/redux-store-fast-form';
import { useLanguage } from '@mmw/redux-store-i18n/hooks';
import Page from '@mmw/ui-page';
import I18N from '@retail/constants-i18n/authentication';
import { useNavigateHome } from '@retail/constants-routes';
import { css } from '@ui-system/css';
import { makeStyle } from '@ui-system/style';
import UI from '@ui-system/ui';
import React, { useCallback } from 'react';
import { object } from 'yup';

const {
  EMAIL: { LABEL: EMAIL_LABEL_I18N },
} = PERSON_I18N;

const INITIAL_VALUES = { username: '' };

const EMAIL_VALIDATION_SCHEMA = object().shape({
  username: UTILS.EMAIL_REQUIRED,
});

const TITLE_FONT_STYLE = { fontSize: 36 };

const Title = () => (
  <UI.Container direction="column" align="center" p="4, 0">
    <UI.Container>
      <UI.Typography
        style={TITLE_FONT_STYLE}
        i18n={I18N.RECOVER_USERNAME.INFORM_YOUR_EMAIL.TITLE}
        modifiers="thin, uppercase, center"
      />
    </UI.Container>
    <UI.Container>
      <UI.Caption
        i18n={I18N.RECOVER_USERNAME.INFORM_YOUR_EMAIL.SUB_TITLE}
        modifiers="center"
      />
    </UI.Container>
  </UI.Container>
);

const Form = () => {
  const navigate = useNavigateHome();
  const language = useLanguage();

  const onInformUserMailSuccess = useRecoverUserIdStart();
  const onSuccess = useCallback(
    ({ username }: { username: string }) =>
      onInformUserMailSuccess(username, language),
    [onInformUserMailSuccess, language],
  );

  const isLoading = useIsLoading();

  return (
    <FastFormProvider
      initialValues={INITIAL_VALUES}
      validationSchema={EMAIL_VALIDATION_SCHEMA}
      onSubmitSuccess={onSuccess}
    >
      <UI.Container direction="column" w="100%" gap={6}>
        <UserField
          label={EMAIL_LABEL_I18N}
          labelModifiers="primary, uppercase"
          accessoryLeft={
            <UI.Icon name="person" size={20} color="primary.light" />
          }
        />
        <UI.Container direction="column" gap={2}>
          <FastFormSubmitButton
            i18n={CONFIRM}
            loading={isLoading}
            textModifiers="uppercase"
          />
          <UI.Button
            i18n={CANCEL}
            onClick={navigate}
            modifiers="secondary"
            textModifiers="uppercase"
            disabled={isLoading}
          />
        </UI.Container>
      </UI.Container>
    </FastFormProvider>
  );
};

const useCardStyle = makeStyle(
  ({ theme }) => css`
    background-color: ${theme.colors[theme.mode].success};
  `,
);

const Card = () => {
  const navigate = useNavigateHome();
  const cardStyle = useCardStyle();

  const reset = useResetRecoverUserId();

  const onBack = useCallback(() => {
    navigate();
    reset();
  }, [navigate, reset]);

  return (
    <UI.Animation f={1}>
      <UI.Container direction="column" gap={2}>
        <UI.Card style={cardStyle}>
          <UI.Card.Content>
            <UI.Container w={400} p={4} direction="column" align="center">
              <UI.Typography
                modifiers="white"
                i18n={I18N.RECOVER_USERNAME.SUCCESS}
              />
            </UI.Container>
          </UI.Card.Content>
        </UI.Card>
        <UI.Button onClick={onBack} i18n={BACK} textModifiers="uppercase" />
      </UI.Container>
    </UI.Animation>
  );
};

const Content = () => {
  const error = useError();
  const success = useSuccess();

  return (
    <UI.Container
      direction="column"
      align="center"
      w="500px"
      p="4, 6, 9, 6"
      gap={2}
    >
      {!success ? (
        <>
          <Title />
          <Form />
        </>
      ) : (
        <Card />
      )}
    </UI.Container>
  );
};

const RecoverUsername: React.FC = () => (
  <RecoverUserIdStoreProvider>
    <Page>
      <Page.Content>
        <UI.Container direction="column" align="center" w="100%">
          <Content />
        </UI.Container>
      </Page.Content>
    </Page>
  </RecoverUserIdStoreProvider>
);

export default RecoverUsername;
