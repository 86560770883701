import { BasePath } from '@di/core';
import { languageQuery } from '@mmw/utils-query-utils';
import qs from 'qs';
import { F } from 'ts-toolbelt';

const BASE_PATHS = {
  MANU: '/v1/manu/product',
  TRADER: '/v1/trader/product',
};

export const [
  getProductBasePath,
  setProductBasePath,
  PRODUCTS_BASE_PATH_TOKEN,
] = BasePath.createDI<string, 'PRODUCT_SERVICE_BASE_PATH_TOKEN'>(
  'PRODUCT_SERVICE_BASE_PATH_TOKEN',
);

export function setManufacturerProductBasePath() {
  setProductBasePath(BASE_PATHS.MANU);
}

export function setTraderProductBasePath() {
  setProductBasePath(BASE_PATHS.TRADER);
}

const getPaths = (
  basePath: string | null,
): Record<string, F.Function<[...args: any], string>> => ({
  GetProductsPath: (language: string): string =>
    `${basePath}?${languageQuery(language)}`,
  GetProductComponentsPath: (productID: number, language: string): string =>
    `${basePath}/${productID}/components?${languageQuery(language)}`,
  ValidateSerialnumberPatternPath: (id: number, serialnumber: string): string =>
    `${basePath}/${id}/validate-serialnumber-pattern?${qs.stringify({
      serialnumber,
    })}`,
  ValidateCouponPath: (
    id: number,
    coupon: string,
    campaignCode?: string,
  ): string =>
    `${basePath}/${id}/validate-coupon?${qs.stringify({
      coupon,
      campaignCode,
    })}`,
  ProductEnabledPath: (id: number): string =>
    `${basePath}/${id}/enabled-on-campaign`,
});

export default getPaths;
