export default ({ theme }) => `
  font-weight: normal;
  font-size: 1.1rem;
  color: ${theme.colors.pallet.secondary};
  &.active {
    color: ${theme.colors.pallet.primary};
    font-weight: bold;
  }
  &:visited {
    color: inherit;
  }
  margin: 0 5px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
