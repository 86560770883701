import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import { getConsumerBankAccountService } from '@mmw/services-holder';
import { Action } from '@reduxjs/toolkit';
import { ActionsObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { getConsumerBankData } from '../actions';

const { logger } = contextualConfig.application;

type Output =
  | ReturnType<typeof getConsumerBankData.success>
  | ReturnType<typeof getConsumerBankData.error>;

const getConsumerBankDataEpic = (
  action$: ActionsObservable<Action>,
): Observable<Output> =>
  action$.pipe(
    ofType(getConsumerBankData.start.match),
    tap(() => logger.debug('Trying to get consumer personal data')),
    switchMap(() =>
      from(getConsumerBankAccountService().getConsumerBankData()).pipe(
        map(data => getConsumerBankData.success(data)),
        catchError(error => of(getConsumerBankData.error(error))),
      ),
    ),
  );

export default getConsumerBankDataEpic;
