import defaultApiV2, { ApiResponse } from '@mmw/api-v2';
import { getDefaultApiHostUrl } from '@mmw/config';
import { RETAIL_CLIENT_REC } from '@mmw/constants-context-paths';
import { LanguageCode } from '@mmw/constants-languages';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import {
  Pagination,
  UploadableFileJSON,
  UserJSON,
} from '@mmw/services-core-common/types';
import {
  OperationResultJSON,
  OrgUnitDetailsJSON,
  OrgUnitJSON,
  OrgUnitRequest,
  UpdateOrgUnitDetailsResponse,
} from '@mmw/services-core-manu-orgunit/types';
import { UploadFileResultJSON } from '@mmw/services-core-projects/types';
import Aigle from 'aigle';
import autoBind from 'auto-bind';
import keyBy from 'lodash/keyBy';

import {
  CreateBranchPath,
  DeleteBranchPath,
  GetAddOrgunitToGroupPath,
  GetAvailableStoresPath,
  GetCompanyDetailsPath,
  GetCompanyLogoPath,
  GetCompanyUserPath,
  GetDeleteCompanyUserPath,
  GetFindOrgunitPath,
  GetOrgunitGroupNamesPath,
  GetSalesmenPath,
  GetSalesTypePath,
  GetStoreLocatorActivatePath,
  GetStoreLocatorDeactivatePath,
  GetStoreLocatorStatusForIdsPath,
  UpdateMasterDataPath,
} from './apiPaths';
import logger from './log';
import {
  AvailableStore,
  CompanyDetails,
  CreateCompanyDetails,
  Salesman,
} from './types';

type Api = typeof defaultApiV2;

type TarderOrgunitServiceOptions = {
  apiv2?: Api;
  authenticationService: AuthenticationService;
};

class TarderOrgunitService {
  api: Api;

  authenticationService: AuthenticationService;

  constructor({ apiv2, authenticationService }: TarderOrgunitServiceOptions) {
    this.api = apiv2 || defaultApiV2;
    this.authenticationService = authenticationService;
    autoBind(this);
  }

  async getCompanyDetails(id: number): Promise<CompanyDetails> {
    logger.debug('Trying to get company data of logged user by id=%d', id);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<CompanyDetails> = await this.api.get(
        GetCompanyDetailsPath(id),
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully got company data');
      return data;
    } catch (error) {
      logger.error('Error when getting company data, error=%O', error);
      throw error;
    }
  }

  async setCompanyUser(
    orgunitId: number,
    user: UserJSON,
    language: LanguageCode,
    loginUrl = `${getDefaultApiHostUrl()}/${RETAIL_CLIENT_REC}`,
  ): Promise<UserJSON> {
    try {
      logger.debug('Trying to set user data by id=%d', orgunitId);
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<UserJSON> = await this.api.post(
        GetCompanyUserPath(orgunitId, language, loginUrl),
        user,
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully set user data');
      return data;
    } catch (error) {
      logger.error('Error when setting user data, error=%O', error);
      throw error;
    }
  }

  async deleteCompanyUser({
    orgunitId,
    user,
  }: {
    orgunitId: number;
    user: UserJSON;
  }): Promise<UserJSON> {
    try {
      logger.debug('Trying to delete orgunit user by id=%d', orgunitId);
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<UserJSON> = await this.api.post(
        GetDeleteCompanyUserPath(orgunitId, user.id),
        user.id,
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully delete orgunit user');
      return data;
    } catch (error) {
      logger.error('Error when deleting orgunit user, error=%O', error);
      throw error;
    }
  }

  async downloadCompanyLogo(id: number): Promise<string | null> {
    // PERM_WEBSERVICE_TRADER_ORGUNIT_DETAILS_EDIT
    logger.debug('Trying to get company logo, orgunitId=%d', id);
    try {
      if (!id) return null;
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<string> = await this.api.get(
        GetCompanyLogoPath(id),
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully got company logo');
      return data;
    } catch (error) {
      logger.error('Error when getting company logo, error=%O', error);
      throw error;
    }
  }

  async uploadCompanyLogo(
    id: number,
    file: UploadableFileJSON,
  ): Promise<UploadFileResultJSON> {
    // WEBSERVICE_TRADER_ORGUNIT_DETAILS_EDIT
    logger.debug('Trying to set company logo, orgunitId=%d', id);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<UploadFileResultJSON> = await this.api.post(
        GetCompanyLogoPath(id),
        file,
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully updated company logo');
      return data;
    } catch (error) {
      logger.error('Error when updating company logo, error=%O', error);
      throw error;
    }
  }

  async getAllCompanyDetails(
    storeIDs: number[],
  ): Promise<Record<number, CompanyDetails>> {
    logger.debug(
      'Trying to get company data of logged user by ids=%d',
      storeIDs,
    );

    const array = await Aigle.resolve(storeIDs).map(storeID =>
      this.getCompanyDetails(storeID),
    );
    return keyBy(array, 'companyId');
  }

  async getAvailableStores(): Promise<Array<AvailableStore>> {
    logger.debug('Trying to get available stores for company');
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Array<AvailableStore>> = await this.api.get(
        GetAvailableStoresPath(),
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully got stores');
      return data;
    } catch (error) {
      logger.error('Error when getting stores, error=%O', error);
      throw error;
    }
  }

  async getSalesmen(): Promise<Array<Salesman>> {
    logger.debug('Trying to get salesmen for company');
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Array<Salesman>> = await this.api.get(
        GetSalesmenPath(),
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully got salesmen');
      return data;
    } catch (error) {
      logger.error('Error when getting salesmen, error=%O', error);
      throw error;
    }
  }

  async getOrgunitGroupNames(): Promise<string[]> {
    logger.debug('Trying to get orgunit group names');
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<string[]> = await this.api.get(
        GetOrgunitGroupNamesPath(),
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully got orgunit group names');
      return data;
    } catch (error) {
      logger.error('Error when getting orgunit group names, error=%O', error);
      throw error;
    }
  }

  async saveCompanyDetails(
    company: CompanyDetails,
    id: number,
  ): Promise<CompanyDetails> {
    logger.debug('Trying to get company data of logged user by id=%d', id);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<CompanyDetails> = await this.api.post(
        GetCompanyDetailsPath(id),
        company,
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully updated company data');
      return data;
    } catch (error) {
      logger.error('Error on update company data, error=%O', error);
      throw error;
    }
  }

  async updateSalesType(
    salesType: number,
    id: number,
  ): Promise<CompanyDetails> {
    logger.debug(
      'Trying to update company sales type of logged user by id=%d',
      id,
    );
    // XXX: Added conditional sales type value to avoid 0 value
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const companyToUpdate = {
        salesType,
      };
      const response: ApiResponse<CompanyDetails> = await this.api.post(
        GetSalesTypePath(id),
        companyToUpdate,
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully updated company sales type');
      return data;
    } catch (error) {
      logger.error('Error on update company sales type, error=%O', error);
      throw error;
    }
  }

  async createBranch(company: CreateCompanyDetails): Promise<CompanyDetails> {
    logger.debug('Trying to create a new branch');
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<CompanyDetails> = await this.api.post(
        CreateBranchPath(),
        company,
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully created branch');
      return data;
    } catch (error) {
      logger.error('Error on create branch, error=%O', error);
      throw error;
    }
  }

  async deleteBranch({ id }: { id: number }): Promise<{ success: boolean }> {
    logger.debug('Trying to delete a new branch');
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<{ success: boolean }> = await this.api.post(
        DeleteBranchPath(id),
        {},
        {
          headers,
        },
      );
      const { data } = response;
      logger.info('Successfully deleted branch');
      return data;
    } catch (error) {
      logger.error('Error on delete branch, error=%O', error);
      throw error;
    }
  }

  async updateMasterData(
    id: number,
    request: OrgUnitDetailsJSON,
  ): UpdateOrgUnitDetailsResponse {
    logger.debug('Trying to update orgunit details to id=O%', id);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<UpdateOrgUnitDetailsResponse> =
        await this.api.post(
          UpdateMasterDataPath(id),
          { ...request },
          {
            headers,
          },
        );
      const { data } = response;
      logger.info(`Successfully updated orgunit details, ${data}`);
      return data;
    } catch (error) {
      logger.error('Error while updating orgunit details, error=%O', error);
      throw error;
    }
  }

  async getOrgUnits(
    request: OrgUnitRequest,
    language: LanguageCode,
  ): Promise<Pagination<OrgUnitJSON>> {
    logger.debug('Trying to find trader orgunit data');
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Pagination<OrgUnitJSON>> =
        await this.api.post(
          GetFindOrgunitPath(language),
          {},
          {
            headers,
          },
        );
      const { data } = response;
      logger.info(`Successfully found trader orgunit data, ${data}`);
      return data;
    } catch (error) {
      logger.error('Error while searching for orgunit data, error=%O', error);
      throw error;
    }
  }

  async activateOrgunitStoreLocator(id: number) {
    logger.debug('Trying to activate orgunit store locator to id=O%', id);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<OperationResultJSON> = await this.api.post(
        GetStoreLocatorActivatePath(id),
        null,
        {
          headers,
        },
      );
      const { data } = response;
      logger.info(`Successfully activate orgunit store locator, ${data}`);
      return data;
    } catch (error) {
      logger.error(
        'Error while activate orgunit store locator, error=%O',
        error,
      );
      throw error;
    }
  }

  async deactivateOrgunitStoreLocator(id: number) {
    logger.debug('Trying to deactivate orgunit store locator to id=O%', id);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<OperationResultJSON> = await this.api.post(
        GetStoreLocatorDeactivatePath(id),
        null,
        {
          headers,
        },
      );
      const { data } = response;
      logger.info(
        `Successfully deactivate orgunit store locator, ${JSON.stringify(
          data,
        )}`,
      );
      return data;
    } catch (error) {
      logger.error(
        'Error while deactivate orgunit store locator, error=%O',
        error,
      );
      throw error;
    }
  }

  async activateDeactivateStoreLocator(id: number, status: boolean) {
    logger.debug(
      'Trying to update activate or deactivate store locator to id=O%',
      status,
    );
    if (status) {
      return this.deactivateOrgunitStoreLocator(id);
    }
    return this.activateOrgunitStoreLocator(id);
  }

  async getStoreLocatorStatus(id: number): Promise<{ success: boolean }> {
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<{ success: boolean }> = await this.api.get(
        GetStoreLocatorStatusForIdsPath(id),
        {
          headers,
        },
      );
      const { data } = response;
      return data;
    } catch (error) {
      logger.error(
        'Error while getting orgunit store locator status, error=%O',
        error,
      );
      throw error;
    }
  }

  async addOrgunitToGroup(
    id: number,
    groupName: string,
  ): Promise<{ success: boolean }> {
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<{ success: boolean }> = await this.api.post(
        GetAddOrgunitToGroupPath(id, groupName),
        null,
        {
          headers,
        },
      );
      const { data } = response;
      return data;
    } catch (error) {
      logger.error(
        'Error while trying to add orgunit to group, error=%O',
        error,
      );
      throw error;
    }
  }
}

export default TarderOrgunitService;
