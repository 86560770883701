import { REGISTRATION_ITEM as VALIDATION } from '@mmw/constants-fields-validations';
import {
  COMMON,
  DEFAULT as I18N,
  PRODUCT,
} from '@mmw/constants-i18n-validations';
import { includes } from 'lodash';
import { number, string } from 'yup';

const UUID = string().nullable();
const UUID_REQUIRED = UUID.required();

const PRODUCT_ID_REQUIRED = number().positive().required(I18N.REQUIRED.key);

const SERIALNUMBER = string()
  .nullable()
  .max(VALIDATION.SERIALNUMBER.MAX_LENGTH);

const SERIALNUMBER_REQUIRED = SERIALNUMBER.required(I18N.REQUIRED.key);

const IMEI = string().nullable().max(VALIDATION.IMEI.MAX_LENGTH);

const IMEI_REQUIRED = IMEI.required(I18N.REQUIRED.key);

const GIVEN_EAN = string().nullable().max(VALIDATION.GIVEN_EAN.MAX_LENGTH);

const GIVEN_EAN_REQUIRED = GIVEN_EAN.required(I18N.REQUIRED.key);

const PROMOTION_CODE = string()
  .nullable()
  .max(VALIDATION.PROMOTION_CODE.MAX_LENGTH);

const PROMOTION_CODE_REQUIRED = PROMOTION_CODE.required(I18N.REQUIRED.key);

const COUPON = string().nullable().max(VALIDATION.COUPON.MAX_LENGTH);

const COUPON_REQUIRED = COUPON.required(I18N.REQUIRED.key);

const SOLD_QUANTITY = number().nullable();

const SOLD_QUANTITY_REQUIRED = SOLD_QUANTITY.required(I18N.REQUIRED.key)
  // @ts-ignore
  .min(1, PRODUCT.QUANTITY.MIN)
  // @ts-ignore
  .max(10000, PRODUCT.QUANTITY.MAX)
  // @ts-ignore
  .typeError(COMMON.NAN);

const COMMENT = string().nullable();

const SERIALNUMBER_OPTIONAL_BY_EANS = (eans: string[], fieldPath: string) =>
  string().when(fieldPath, {
    is: (ean: string) => includes(eans, ean),
    then: schema => schema.nullable().max(VALIDATION.SERIALNUMBER.MAX_LENGTH),
    otherwise: schema =>
      schema
        .nullable()
        .max(VALIDATION.SERIALNUMBER.MAX_LENGTH)
        .required(I18N.REQUIRED.key),
  });

export const REGISTRATION = {
  UUID,
  UUID_REQUIRED,
  PRODUCT_ID_REQUIRED,
  SERIALNUMBER,
  SERIALNUMBER_REQUIRED,
  IMEI,
  IMEI_REQUIRED,
  GIVEN_EAN,
  GIVEN_EAN_REQUIRED,
  COUPON,
  COUPON_REQUIRED,
  PROMOTION_CODE,
  PROMOTION_CODE_REQUIRED,
  COMMENT,
  SOLD_QUANTITY,
  SOLD_QUANTITY_REQUIRED,
  SERIALNUMBER_OPTIONAL_BY_EANS,
};

export default REGISTRATION;
