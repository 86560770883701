import {
  SecurityScopeNames,
  // UnblockRequest,
  UnblockResponse,
} from '@mmw/services-auth-api-authentication/types';
import { U } from 'ts-toolbelt';

import {
  REQUEST_UNBLOCK_ERROR,
  REQUEST_UNBLOCK_START,
  REQUEST_UNBLOCK_SUCCESS,
  RequestUnblockErrorAction,
  RequestUnblockStartAction,
  RequestUnblockSuccessAction,
  RESET_ACTION,
  ResetRequestUnblockStatusAction,
} from './types';

export const requestUnblockStartAction = (
  username: string,
  recaptchaResponse: string,
  language: string,
  recaptchaType?: 'V2' | 'V3',
  applicationId?: U.Nullable<string>,
  redirectUrl?: U.Nullable<string>,
  scopeName?: SecurityScopeNames,
  applicationBaseUrl?: U.Nullable<string>,
  applicationContextPath?: U.Nullable<string>,
): RequestUnblockStartAction => ({
  type: REQUEST_UNBLOCK_START,
  payload: {
    username,
    recaptchaResponse,
    language,
    recaptchaType: recaptchaType || 'V2',
    applicationId,
    redirectUrl,
    scopeName,
    applicationBaseUrl,
    applicationContextPath,
  },
});

export const requestUnblockSuccessAction = (
  response: UnblockResponse,
): RequestUnblockSuccessAction => ({
  type: REQUEST_UNBLOCK_SUCCESS,
  payload: response,
});

export const requestUnblockErrorAction = (
  error: Error | null,
): RequestUnblockErrorAction => ({
  type: REQUEST_UNBLOCK_ERROR,
  payload: {
    error,
  },
});

export const resetAction = (): ResetRequestUnblockStatusAction => ({
  type: RESET_ACTION,
});
