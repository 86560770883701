import { FastFormSelect } from '@fast-form/ui-system';
import { COUNTRY_CALLING_CODES_PREFIX } from '@mmw/constants-country-codes';
import { PERSON } from '@mmw/constants-i18n-fields';
import { InputProps } from '@ui-system/interfaces-form';
import UI from '@ui-system/ui';
import { PERSON_FORM_DATA_FIELDS } from '@user/person-form-data';
import map from 'lodash/map';
import React from 'react';

import PhoneNumberField from './PhoneNumberField';

const SELECT_OPTIONS = map(COUNTRY_CALLING_CODES_PREFIX, item => ({
  label: `${item.flag} ${item.name} (+${item.callingPrefix})`,
  value: item.code,
}));

interface Props {
  withError: boolean;
}

const PhoneWithCounterPrefixField: React.FC<Props & Partial<InputProps>> = ({
  disabled,
  withError,
  ...props
}: Props & Partial<InputProps>) => (
  <UI.Container direction="column" w="100%" m="0, 0, 4, 0">
    <FastFormSelect
      fieldPath={PERSON_FORM_DATA_FIELDS.country.$path}
      label={PERSON.ADDRESS.COUNTRY.LABEL}
      options={SELECT_OPTIONS}
      optionsName="label"
      optionsValue="value"
      disabled={disabled}
    />
    <PhoneNumberField showWithError={withError} {...props} />
  </UI.Container>
);

export default PhoneWithCounterPrefixField;
