import { useRouteMatch } from '@lib/navigation/hooks';
import { includes } from 'lodash';

export function useNavItemHandler({
  route,
  matchGroup,
}: {
  route?: string;
  matchGroup?: string[];
}) {
  const match = useRouteMatch();
  return match.path === route || includes(matchGroup, match.path);
}
