import { EMPTY_OBJECT } from '@mmw/constants-utils';

import { CarouselSearchByUuidState } from './CarouselSearchByUuidState';
import { RetrieveState } from './RetrieveState';
import { SearchByUuidState } from './SearchByUuidState';

export class SearchState<RequestFields, ItemType> {
  itemsById: Record<number, ItemType> = EMPTY_OBJECT;

  searchesByUuid: Record<string, SearchByUuidState<RequestFields>> =
    EMPTY_OBJECT;

  carouselSearchesByUuid: Record<
    string,
    CarouselSearchByUuidState<RequestFields>
  > = EMPTY_OBJECT;

  retrievesById: Record<number, RetrieveState> = EMPTY_OBJECT;

  currentSearchUuid: string;
}
