import logo from '@mmw/web-assets/logo.png';

export default {
  colors: {
    palette: {
      primary: '#004B93',
    },
  },
  assets: {
    logo,
  },
};
