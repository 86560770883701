import { use } from '@mmw/ui-theme/utils';
import { ButtonType } from '@mmw/ui-web-materials-button';
import styled from 'styled-components';

import Button from './elements/Button';
import Input from './elements/Input';
import Span from './elements/Span';

export type InputGroupType = ReturnType<typeof styled.div> & {
  Input: typeof Input;
  Button: ButtonType;
  Span: typeof Span;
};

const InputGroup: InputGroupType = styled.div(props =>
  use(props, 'inputGroup'),
) as InputGroupType;

InputGroup.Input = Input;
InputGroup.Button = Button;
InputGroup.Span = Span;
InputGroup.Button.Icon = Button.Icon;

export default InputGroup;
