import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import { AuthenticationResponse } from '@mmw/services-auth-api-authentication/types';
import { getAuthenticationService } from '@mmw/services-holder';
import { ActionsObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, timeout } from 'rxjs/operators';

import {
  authenticateBySSOTokenErrorAction,
  authenticateBySSOTokenSuccessAction,
} from '../actions';
import {
  AUTHENTICATE_BY_SSO_TOKEN_START,
  AuthenticateBySSOTokenErrorAction,
  AuthenticateBySSOTokenStartAction,
  AuthenticateBySSOTokenSuccessAction,
} from '../types';

const { logger } = contextualConfig.application;
const { defaultTimeout } = contextualConfig.api;

function handleSuccess({ accessToken }: AuthenticationResponse) {
  return from(getAuthenticationService().getUserDetails()).pipe(
    map(loggedUser =>
      authenticateBySSOTokenSuccessAction(loggedUser, accessToken),
    ),
    catchError(error => of(authenticateBySSOTokenErrorAction(error))),
  );
}

type Input = AuthenticateBySSOTokenStartAction;
type Output =
  | AuthenticateBySSOTokenSuccessAction
  | AuthenticateBySSOTokenErrorAction;
const authenticateBySSOTokenEpic = (
  action$: ActionsObservable<Input>,
): Observable<Output> =>
  action$.pipe(
    ofType(AUTHENTICATE_BY_SSO_TOKEN_START),
    tap(() => logger.debug(`Trying to authenticate user by ssoToken`)),
    switchMap(action =>
      from(
        getAuthenticationService().authenticateBySSOToken({
          ssoToken: action.payload.ssoToken,
          ...(action.payload.options || {}),
        }),
      ).pipe(
        timeout(defaultTimeout),
        tap(({ success }: AuthenticationResponse) =>
          logger.debug(`Result from authentication success=${success}`),
        ),
        switchMap(handleSuccess),
        catchError(error => of(authenticateBySSOTokenErrorAction(error))),
      ),
    ),
  );

export default authenticateBySSOTokenEpic;
