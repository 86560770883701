import { COLOR_SCHEMA, createColorSchemaForPallet } from '@mmw/ui-theme/color';
import { Pallet } from '@mmw/ui-theme/color/types';

const pallet: Pallet = {
  primary: '#666D7B',
  secondary: '#F0F0F0',
  basic: '#DDDCD7', // BACKGROUND
  dark: '#000', // BLACK
  light: '#FFF', // WHITE
  basicDark: '#313A3E', // NOT IN USE?
  basicLight: '#E8E6D4', // NOT IN USE?
  success: '#66B100',
  error: '#FF0000',
  alert: '#FF6200',
};

export default createColorSchemaForPallet(pallet, {
  ...COLOR_SCHEMA,
  alert: {
    fill: 'alert',
    font: 'light',
    icon: 'light',
  },
});
