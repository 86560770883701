import {
  GET_LOGIN_CONFIG_BY_EMAIL_OR_USERID_ERROR,
  GET_LOGIN_CONFIG_BY_EMAIL_OR_USERID_START,
  GET_LOGIN_CONFIG_BY_EMAIL_OR_USERID_SUCCESS,
  Reducer,
} from './types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  foundUserConfig: null,
};

const authenticationReducer: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LOGIN_CONFIG_BY_EMAIL_OR_USERID_START:
      return {
        ...state,
        loading: true,
        foundUserConfig: null,
        error: null,
      };
    case GET_LOGIN_CONFIG_BY_EMAIL_OR_USERID_SUCCESS:
      return {
        ...state,
        loading: false,
        foundUserConfig: action.payload.config,
        error: null,
      };
    case GET_LOGIN_CONFIG_BY_EMAIL_OR_USERID_ERROR:
      return {
        ...state,
        loading: false,
        foundUserConfig: null,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default authenticationReducer;
