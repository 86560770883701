import {
  useIsAMER,
  useIsAPAC,
  useIsEMEA,
  useWorldRegionByCountry,
  WorldRegion,
} from '@mmw/constants-country-regions';
import get from 'lodash/get';
import { useMemo } from 'react';
import { U } from 'ts-toolbelt';

import useLoggedTraderData from './useLoggedTraderData';

export const useLoggedTraderOrgunitCountry = (): U.Nullable<string> => {
  const loggedTraderData = useLoggedTraderData();
  return useMemo(
    () => get(loggedTraderData, 'orgunitCountry'),
    [loggedTraderData],
  );
};
export function useLoggedUserWorldRegion(): U.Nullable<WorldRegion> {
  const country = useLoggedTraderOrgunitCountry();
  return useWorldRegionByCountry(country);
}
export function useIsLoggedUserAPAC() {
  const country = useLoggedTraderOrgunitCountry();
  const data = useLoggedTraderData();
  return useIsAPAC(country) || data?.group === WorldRegion.APAC;
}
export function useIsLoggedUserEMEA() {
  const country = useLoggedTraderOrgunitCountry();
  const data = useLoggedTraderData();
  return useIsEMEA(country) || data?.group === WorldRegion.EMEA;
}
export function useIsLoggedUserAMER() {
  const country = useLoggedTraderOrgunitCountry();
  const data = useLoggedTraderData();
  return useIsAMER(country) || data?.group === WorldRegion.AMER;
}
export function useIsLoggedUserFromAUNZ() {
  const country = useLoggedTraderOrgunitCountry();
  return country === 'AU' || country === 'NZ';
}

export default useLoggedTraderOrgunitCountry;
