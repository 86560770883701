import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import { getAuthenticationService } from '@mmw/services-holder';
import { ActionsObservable } from 'redux-observable';
import { concat, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, timeout } from 'rxjs/operators';

import {
  getLoginConfigByEmailOrUseridErrorAction,
  getLoginConfigByEmailOrUseridSuccessAction,
} from '../actions';
import {
  GET_LOGIN_CONFIG_BY_EMAIL_OR_USERID_START,
  GetLoginConfigByEmailOrUseridErrorAction,
  GetLoginConfigByEmailOrUseridStartAction,
  GetLoginConfigByEmailOrUseridSuccessAction,
} from '../types';

const { logger } = contextualConfig.application;
const { defaultTimeout } = contextualConfig.api;

const createGetLoginConfigByEmailOrUserid = (
  action$: ActionsObservable<GetLoginConfigByEmailOrUseridStartAction>,
): Observable<
  | GetLoginConfigByEmailOrUseridSuccessAction
  | GetLoginConfigByEmailOrUseridErrorAction
> =>
  action$.pipe(
    ofType(GET_LOGIN_CONFIG_BY_EMAIL_OR_USERID_START),
    tap(() => logger.debug('Trying to retrieve userid by email')),
    switchMap(
      ({
        payload: { emailOrUserid, onSuccess },
      }: GetLoginConfigByEmailOrUseridStartAction) =>
        from(
          getAuthenticationService().getLoginConfigForEmailOrUserid(
            emailOrUserid,
          ),
        ).pipe(
          timeout(defaultTimeout),
          map(data => getLoginConfigByEmailOrUseridSuccessAction(data)),
          tap((action: GetLoginConfigByEmailOrUseridSuccessAction) => {
            if (onSuccess) onSuccess(action.payload.config);
          }),
          catchError(error =>
            concat(of(getLoginConfigByEmailOrUseridErrorAction(error))),
          ),
        ),
    ),
  );

export default createGetLoginConfigByEmailOrUserid;
