import { enableBatching } from 'redux-batched-actions';

import requestEmailVerificationEpic from './epics/requestEmailVerificationEpic';
import verifyEmailEpic from './epics/verifyEmailEpic';
import REDUCER from './reducer';
import { NAMESPACE as namespace } from './types';

export * from './types';

export const reducer = REDUCER;
export const NAMESPACE = namespace;
export { default as epics } from './epics';

export function emailVerificationModule() {
  return {
    id: NAMESPACE,
    reducerMap: {
      // @ts-ignore
      [NAMESPACE]: enableBatching(reducer),
    },
    epics: [requestEmailVerificationEpic, verifyEmailEpic],
  };
}
