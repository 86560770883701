/* eslint-disable no-param-reassign */
import { removeFileById } from '@audit/remove-registration-file-redux-store/actions';
import { updateAudit } from '@audit/update-audit-redux-store/actions';
import { updateFilesByType } from '@audit/update-files-by-type-redux-store/actions';
import {
  RegistrationResponseJSON,
  SearchRegistrationRequestFiltersJSON,
} from '@mmw/services-core-trader-registration/types';
import { BaseAsyncState } from '@redux-async-module/reducer-utils';
import { createReducer } from '@redux-basic-module/reducer-utils/ReducerBuilder';
import {
  addReducerCarouselSearchCases,
  addReducerRetrieveCases,
  addReducerSearchCases,
  CarouselSearchByUuidState,
  SearchByUuidState,
} from '@redux-search-module/reducer-utils';
import { RetrieveState } from '@redux-search-module/reducer-utils/state/RetrieveState';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import unionBy from 'lodash/unionBy';
import { U } from 'ts-toolbelt';

import {
  carouselSearchAudits,
  changeLimitAction,
  changeStatus,
  createAudit,
  createIssue,
  performIssueAction,
  removeValidationErrors,
  retrieveAudit,
  searchAudits,
} from './actions';
import { AuditState } from './state/AuditState';
import { ChangeStatusState } from './state/ChangeStatusState';
import { CreateIssueState } from './state/CreateIssueState';
import { PerformIssueState } from './state/PerformIssueState';

export const INITIAL_STATE = new AuditState<
  SearchRegistrationRequestFiltersJSON,
  RegistrationResponseJSON
>();

export const INITIAL_SEARCH_BY_UUID_STATE =
  new SearchByUuidState<SearchRegistrationRequestFiltersJSON>();
export const INITIAL_CAROUSEL_SEARCH_BY_UUID_STATE =
  new CarouselSearchByUuidState<SearchRegistrationRequestFiltersJSON>();
export const INITIAL_RETRIEVE_STATE = new RetrieveState();
const INITIAL_STATE_CHANGE_STATUS = new ChangeStatusState();
const INITIAL_STATE_CREATE_ISSUE = new CreateIssueState();
const INITIAL_STATE_PERFORM_ISSUE = new PerformIssueState();
const INITIAL_CREATE_AUDIT_STATE = new BaseAsyncState();

const idGetter = (item: RegistrationResponseJSON) => get(item, 'id');
const orgunitIdGetter = (item: RegistrationResponseJSON) =>
  get(item, 'storeID');

const reducer = createReducer(INITIAL_STATE, builder => {
  function updateRegistrationIfNeeded(
    draft: AuditState<
      SearchRegistrationRequestFiltersJSON,
      RegistrationResponseJSON
    >,
    success: boolean,
    audit: U.Nullable<RegistrationResponseJSON>,
  ): AuditState<
    SearchRegistrationRequestFiltersJSON,
    RegistrationResponseJSON
  > {
    if (!success || !audit) {
      return draft;
    }
    const { storeID, id } = audit;
    draft.itemsById[id] = audit;
    draft.regListByOrgunit = {
      ...draft.regListByOrgunit,
      [storeID]: unionBy([audit], get(draft.regListByOrgunit, storeID), 'id'),
    };
    return draft;
  }

  addReducerSearchCases<
    SearchRegistrationRequestFiltersJSON,
    RegistrationResponseJSON
  >(searchAudits, builder, idGetter);

  addReducerRetrieveCases<RegistrationResponseJSON>(retrieveAudit, builder);
  addReducerCarouselSearchCases<
    SearchRegistrationRequestFiltersJSON,
    RegistrationResponseJSON
  >(carouselSearchAudits, builder, idGetter);
  builder.addCase(changeLimitAction, (draft, { payload, meta }) => {
    draft.searchesByUuid[meta.searchUuid].limit = payload;
    return draft;
  });
  builder
    .addCase(updateAudit.success, (draft, { payload: { audit, updated } }) =>
      updateRegistrationIfNeeded(draft, updated, audit),
    )
    .addCase(
      updateFilesByType.success,
      (draft, { payload: { success, registration: audit } }) =>
        updateRegistrationIfNeeded(draft, success, audit),
    )
    .addCase(
      removeFileById.success,
      (draft, { payload: { success, registration: audit } }) =>
        updateRegistrationIfNeeded(draft, success, audit),
    )
    .addCase(createAudit.start, draft => {
      draft.auditCreation = INITIAL_CREATE_AUDIT_STATE;
      draft.auditCreation.loading = true;
      return draft;
    })
    .addCase(createAudit.success, (draft, { payload }) => {
      draft.auditCreation.loading = false;
      draft.itemsById = {
        ...draft.itemsById,
        [idGetter(payload)]: payload,
      };

      const orgunitId = orgunitIdGetter(payload);
      draft.regListByOrgunit = {
        ...draft.regListByOrgunit,
        [orgunitId]: unionBy(
          [payload],
          get(draft.regListByOrgunit, orgunitId),
          'id',
        ),
      };
      return draft;
    })
    .addCase(createAudit.error, (draft, { payload }) => {
      draft.auditCreation.loading = false;
      draft.auditCreation.error = payload;
      return draft;
    })
    .addCase(changeStatus.start, draft => {
      draft.changeStatus = INITIAL_STATE_CHANGE_STATUS;
      draft.changeStatus.loading = true;
      return draft;
    })
    .addCase(changeStatus.success, (draft, { payload }) => {
      draft.changeStatus = {
        ...INITIAL_STATE_CHANGE_STATUS,
        ...draft.changeStatus,
        loading: false,
        requestAttempted: true,
        data: payload,
        error: null,
      };
      return draft;
    })
    .addCase(changeStatus.error, (draft, { payload }) => {
      draft.changeStatus = {
        ...INITIAL_STATE_CHANGE_STATUS,
        loading: false,
        requestAttempted: true,
        error: payload,
      };
      return draft;
    })
    .addCase(changeStatus.cancel, draft => {
      draft.changeStatus = {
        ...INITIAL_STATE_CHANGE_STATUS,
        ...draft.changeStatus,
        loading: false,
        requestAttempted: false,
        error: null,
      };
      return draft;
    })
    .addCase(changeStatus.dismissError, draft => {
      draft.changeStatus = {
        ...INITIAL_STATE_CHANGE_STATUS,
        ...draft.changeStatus,
        loading: false,
        error: null,
      };
      return draft;
    })
    .addCase(removeValidationErrors.start, draft => {
      draft.changeStatus = INITIAL_STATE_CHANGE_STATUS;
      draft.changeStatus.loading = true;
      return draft;
    })
    .addCase(removeValidationErrors.success, (draft, { payload }) => {
      draft.changeStatus = {
        ...INITIAL_STATE_CHANGE_STATUS,
        ...draft.changeStatus,
        loading: false,
        requestAttempted: true,
        data: payload,
        error: null,
      };
      return draft;
    })
    .addCase(removeValidationErrors.error, (draft, { payload }) => {
      draft.changeStatus = {
        ...INITIAL_STATE_CHANGE_STATUS,
        loading: false,
        requestAttempted: true,
        error: payload,
      };
      return draft;
    })
    .addCase(removeValidationErrors.cancel, draft => {
      draft.changeStatus = {
        ...INITIAL_STATE_CHANGE_STATUS,
        ...draft.changeStatus,
        loading: false,
        requestAttempted: false,
        error: null,
      };
      return draft;
    })
    .addCase(removeValidationErrors.dismissError, draft => {
      draft.changeStatus = {
        ...INITIAL_STATE_CHANGE_STATUS,
        ...draft.changeStatus,
        loading: false,
        error: null,
      };
      return draft;
    })
    .addCase(createIssue.start, draft => {
      draft.createIssue = INITIAL_STATE_CREATE_ISSUE;
      draft.createIssue.loading = true;
      return draft;
    })
    .addCase(createIssue.success, (draft, { payload }) => {
      draft.createIssue = {
        ...INITIAL_STATE_CREATE_ISSUE,
        ...draft.createIssue,
        loading: false,
        requestAttempted: true,
        data: payload,
        error: null,
      };

      draft.itemsById = {
        ...draft.itemsById,
        [idGetter(payload)]: payload,
      };

      const orgunitId = orgunitIdGetter(payload);
      draft.regListByOrgunit = {
        ...draft.regListByOrgunit,
        [orgunitId]: unionBy(
          [payload],
          get(draft.regListByOrgunit, orgunitId),
          'id',
        ),
      };
      return draft;
    })
    .addCase(createIssue.error, (draft, { payload }) => {
      draft.createIssue = {
        ...INITIAL_STATE_CREATE_ISSUE,
        loading: false,
        requestAttempted: true,
        error: payload,
      };
      return draft;
    })
    .addCase(createIssue.cancel, draft => {
      draft.createIssue = {
        ...INITIAL_STATE_CREATE_ISSUE,
        ...draft.createIssue,
        loading: false,
        requestAttempted: false,
        error: null,
      };
      return draft;
    })
    .addCase(createIssue.dismissError, draft => {
      draft.createIssue = {
        ...INITIAL_STATE_CREATE_ISSUE,
        ...draft.createIssue,
        loading: false,
        error: null,
      };
      return draft;
    })
    .addCase(performIssueAction.start, (draft, { meta: { issueId } }) => {
      draft.performIssue = {
        ...INITIAL_STATE_PERFORM_ISSUE,
        ...draft.performIssue,
        issuesById: {
          ...draft.performIssue.issuesById,
          [issueId]: { loading: true, error: null },
        },
      };
      return draft;
    })
    .addCase(performIssueAction.success, (draft, { meta: { issueId } }) => {
      draft.performIssue = {
        ...INITIAL_STATE_PERFORM_ISSUE,
        ...draft.performIssue,
        issuesById: {
          ...draft.performIssue.issuesById,
          [issueId]: { loading: false, error: null },
        },
      };
      return draft;
    })
    .addCase(
      performIssueAction.error,
      (draft, { payload: error, meta: { issueId } }) => {
        draft.performIssue = {
          ...INITIAL_STATE_PERFORM_ISSUE,
          ...draft.performIssue,
          issuesById: {
            ...draft.performIssue.issuesById,
            [issueId]: { loading: false, error },
          },
        };
        return draft;
      },
    )
    .addCase(performIssueAction.cancel, (draft, { meta: { issueId } }) => {
      draft.performIssue = {
        ...INITIAL_STATE_PERFORM_ISSUE,
        ...draft.performIssue,
        issuesById: {
          ...draft.performIssue.issuesById,
          [issueId]: { loading: false, error: null },
        },
      };
      return draft;
    })
    .addCase(
      performIssueAction.dismissError,
      (draft, { meta: { issueId } }) => {
        draft.performIssue = {
          ...INITIAL_STATE_PERFORM_ISSUE,
          ...draft.performIssue,
          issuesById: {
            ...draft.performIssue.issuesById,
            [issueId]: { loading: false, error: null },
          },
        };
        return draft;
      },
    )
    .addMatcher<typeof searchAudits.success>(
      action => action.type === searchAudits.success.type,
      (draft, { payload }) => {
        const { list } = payload;
        draft.regListByOrgunit = {
          ...draft.regListByOrgunit,
          ...groupBy(list, orgunitIdGetter),
        };
        return draft;
      },
    )
    .addMatcher<typeof carouselSearchAudits.search.success>(
      action => action.type === carouselSearchAudits.search.success.type,
      (draft, { payload }) => {
        const { list } = payload;
        draft.regListByOrgunit = {
          ...draft.regListByOrgunit,
          ...groupBy(list, orgunitIdGetter),
        };
        return draft;
      },
    )
    .addMatcher<typeof retrieveAudit.success>(
      action => action.type === retrieveAudit.success.type,
      (draft, { payload }) => {
        const orgunitId = orgunitIdGetter(payload);
        draft.regListByOrgunit = {
          ...draft.regListByOrgunit,
          [orgunitId]: unionBy(
            [payload],
            get(draft.regListByOrgunit, orgunitId),
            'id',
          ),
        };
        return draft;
      },
    );
});

export default reducer;
export type Reducer = typeof reducer;
export type State = typeof INITIAL_STATE;
