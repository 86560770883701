import { FILE as VALIDATION } from '@mmw/constants-fields-validations';
import { VALUES as FILE_TYPES } from '@mmw/constants-file-types';
import { DEFAULT, FILE as I18N } from '@mmw/constants-i18n-validations';
import { string } from 'yup';

const TYPE = string().oneOf(FILE_TYPES, I18N.TYPE.INVALID.key).nullable();

const TYPE_REQUIRED = TYPE.required(DEFAULT.REQUIRED.key);

const NAME = string().max(VALIDATION.NAME.MAX_LENGTH).nullable();

const NAME_REQUIRED = NAME.required(DEFAULT.REQUIRED.key);

const FORMAT = string().max(VALIDATION.FORMAT.MAX_LENGTH).nullable();

const FORMAT_REQUIRED = FORMAT.required(DEFAULT.REQUIRED.key);

const DATA = string().nullable();

const DATA_REQUIRED = DATA.required(DEFAULT.REQUIRED.key);

export const FILE = {
  TYPE,
  TYPE_REQUIRED,
  NAME,
  NAME_REQUIRED,
  FORMAT,
  FORMAT_REQUIRED,
  DATA,
  DATA_REQUIRED,
};

export default FILE;
