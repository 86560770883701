import { StyledProps } from '@mmw/ui-theme/types';

export default ({ theme }: StyledProps<unknown>) => `
  color: ${theme.colors.pallet.light};
  fill: ${theme.colors.pallet.light};
  padding: 0;
  margin: 0 10px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;
