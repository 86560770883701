// import { CAMPAIGN as I18N } from '@mmw/constants-i18n-validations';
import { DEFAULT as I18N } from '@mmw/constants-i18n-validations';
import { number } from 'yup';

const ID = number().nullable();

// address table on mmw database
export const ADDRESS = {
  ID,
  ID_REQUIRED: ID.required(I18N.REQUIRED.key),
};

export default ADDRESS;
