const CONTENT_MAX_WIDTH = '1320px';
const HEADER_MIN_HEIGHT = '110px';
const NAVBAR_HEIGHT = '20px';
const BANNER_HEIGHT = '360px';
const FOOTER_HEIGHT = '45px';
const LOGO_WIDTH = '120px';
const LOGO_HEIGHT = '100px';
const TITLE_MOBILE_PADDING = '0 15px';
const NAV_MENU_HEIGHT = '0px';

const variable = {
  CONTENT_MAX_WIDTH,
  HEADER_MIN_HEIGHT,
  NAVBAR_HEIGHT,
  BANNER_HEIGHT,
  FOOTER_HEIGHT,
  LOGO_WIDTH,
  LOGO_HEIGHT,
  TITLE_MOBILE_PADDING,
  NAV_MENU_HEIGHT,
};

export {
  BANNER_HEIGHT,
  CONTENT_MAX_WIDTH,
  variable as default,
  FOOTER_HEIGHT,
  HEADER_MIN_HEIGHT,
  LOGO_HEIGHT,
  LOGO_WIDTH,
  NAV_MENU_HEIGHT,
  NAVBAR_HEIGHT,
  TITLE_MOBILE_PADDING,
};
