import { AxiosRequestConfig, AxiosResponse as ApiResponseType } from 'axios';
import isEmpty from 'lodash/isEmpty';

import {
  ApiBadRequestError,
  ApiError,
  ApiForbiddenError,
  ApiNotFoundError,
  ApiUnauthorizedError,
} from './errors';
import logger from './log';

const mapError = (response: ApiResponseType, error: any) => {
  const status = !isEmpty(response) ? response.status : -1;
  const data = !isEmpty(response) ? response.data : null;
  logger.warn('Error from api', status, data);
  switch (response?.status) {
    case 400:
      return new ApiBadRequestError(undefined, data);
    case 404:
      return new ApiNotFoundError();
    case 401:
      return new ApiUnauthorizedError(undefined, data);
    case 403:
      return new ApiForbiddenError(undefined, data);
    default:
      return new ApiError(status, error);
  }
};

export const goodRequestInterceptor = (
  config: AxiosRequestConfig,
): AxiosRequestConfig => config;

export const goodResponseInterceptor = (
  response: ApiResponseType,
): ApiResponseType => {
  logger.trace(
    `Got response from api==${response.status} and data=%O`,
    response.data,
  );
  return response;
};

export const badResponseInterceptor = (error: {
  response: ApiResponseType;
}): Promise<Error> => {
  const { response } = error;
  const err = mapError(response, error);
  logger.error('Error on request response from the api=%O', err);
  return Promise.reject(err);
};
