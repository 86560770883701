import { isArray } from 'lodash';
import { ArrayOrSingle } from 'ts-essentials';
import { U } from 'ts-toolbelt';

export function arrayOrSingleToArray<T>(
  array: U.Nullable<ArrayOrSingle<T>>,
): T[] {
  if (!array) return [];
  if (isArray(array)) return array;
  return [array];
}
