import { StyledProps } from '@mmw/web-theme';

export default ({
  theme,
  absolute,
}: StyledProps<{
  absolute: boolean;
}>) => `
  padding: 0.2rem;
  display: block;
  color: ${theme.colors.pallet.error};
  ${absolute ? 'position: absolute;' : ''}
`;
