import { I18nShape } from '@mmw/constants-i18n';
import { getErrorI18n } from '@mmw/services-auth-api-authentication/utils';
import UI from '@ui-system/ui';
import React from 'react';
import { F, U } from 'ts-toolbelt';

interface AuthErrorCardUI {
  error: U.Nullable<Error>;
  onDismiss?: F.Function;
  children?: React.ReactNode;
}

interface AuthErrorCardUIContentProps {
  title: I18nShape;
  description: I18nShape;
  onDismiss?: F.Function;
}
export const AuthErrorCardUIContent: React.FC<AuthErrorCardUIContentProps> = ({
  title,
  description,
  onDismiss,
  children,
  ...rest
}) => (
  <UI.Animation f={1}>
    <UI.Card {...rest}>
      <UI.Card.Header
        i18nTitle={title}
        titleModifiers="uppercase, error, bold"
        action={
          onDismiss && (
            <UI.Icon name="close" size={40} onClick={onDismiss} color="error" />
          )
        }
      />
      <UI.Card.Content>
        <UI.Container direction="column">
          <UI.Typography i18n={description} modifiers="error" />
        </UI.Container>
        {children}
      </UI.Card.Content>
    </UI.Card>
  </UI.Animation>
);

export const AuthErrorCardUI: React.FC<AuthErrorCardUI> = ({
  error,
  onDismiss,
  children,
  ...rest
}: AuthErrorCardUI) => {
  if (!error) return null;
  return (
    <AuthErrorCardUIContent
      title={getErrorI18n(error)?.TITLE}
      description={getErrorI18n(error)?.DESCRIPTION}
      onDismiss={onDismiss}
      {...rest}
    >
      {children}
    </AuthErrorCardUIContent>
  );
};
