import { getCurrentSobIdByCountry } from '@mmw/constants-salesorgbrand-utils';
import contextualConfig from '@mmw/contextual-config';
import ofType, { BatchAction } from '@mmw/redux-rx-of-type-operator';
import {
  loginByPasswordStartAction,
  setUsernameAction,
} from '@mmw/redux-store-auth-api-login-by-password/actions';
import { RootState } from '@mmw/redux-store-auth-api-login-by-tan/types';
import { notAuthenticatedOperationTokenSelector } from '@mmw/redux-store-email-verification/stateSelector';
import { RootState as VerifState } from '@mmw/redux-store-email-verification/types';
import {
  languageSelector,
  RootState as LangState,
} from '@mmw/redux-store-i18n/stateSelector';
import { OrgunitSignupResultJSON } from '@mmw/services-core-trader-contracting/types';
import { getTraderContractingService } from '@mmw/services-holder';
import { executeRecaptchaAction } from '@mmw/ui-libraries-recaptcha';
import { batchActions } from 'redux-batched-actions';
import { ActionsObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  tap,
  timeout,
  withLatestFrom,
} from 'rxjs/operators';
import { U } from 'ts-toolbelt';

import {
  orgunitSignupErrorAction,
  orgunitSignupSuccessAction,
} from '../actions';
import {
  ORGUNIT_SIGNUP_START,
  OrgunitSignupErrorAction,
  OrgunitSignupFormData,
  OrgunitSignupStartAction,
  OrgunitSignupSuccessAction,
} from '../types';

const { logger, salesOrgBrandID } = contextualConfig.application;
const { defaultTimeout } = contextualConfig.api;

async function mappedSignupRequest(
  data: OrgunitSignupFormData,
  notAuthenticatedOperationToken: U.Nullable<string>,
  language: string,
): Promise<OrgunitSignupResultJSON> {
  const mappedData = {
    personDetails: {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      domkeysalutation: 'EMPTY',
    },
    orgunit: {
      taxnumber: data.taxnumber,
      salestaxid: data.salestaxid,
      legalform: data.legalForm,
      displayName: data.displayName,
      address: {
        company: data.company,
        street: data.street,
        zipcode: data.zipcode,
        city: data.city,
        country: data.country,
        nr: data.nr,
      },
    },
    emailConfiguration: null,
    businessRelation: {
      salesOrgBrand:
        getCurrentSobIdByCountry(salesOrgBrandID, data.country) ||
        salesOrgBrandID,
    },
    notAuthenticatedOperationToken: notAuthenticatedOperationToken as string,
    recaptchaResponse: (await executeRecaptchaAction(
      'orgunit-signup',
    )) as string,
    password: data.password,
  };
  return getTraderContractingService().signupOrgunit(mappedData, language);
}

const orgunitSignupEpic = (
  action$: ActionsObservable<OrgunitSignupStartAction>,
  state$: Observable<RootState & LangState & VerifState>,
): Observable<
  BatchAction<OrgunitSignupSuccessAction> | OrgunitSignupErrorAction
> =>
  action$.pipe(
    ofType(ORGUNIT_SIGNUP_START),
    tap(() => logger.debug('Trying to signup')),
    withLatestFrom(
      state$.pipe(map(notAuthenticatedOperationTokenSelector)),
      state$.pipe(map(languageSelector)),
    ),
    switchMap(
      ([
        {
          payload: { data, onLoginSuccess },
        },
        notAuthenticatedOperationToken,
        language,
      ]) =>
        from(
          mappedSignupRequest(data, notAuthenticatedOperationToken, language),
        ).pipe(
          timeout(defaultTimeout),
          map(
            response =>
              <BatchAction<OrgunitSignupSuccessAction>>(
                batchActions([
                  orgunitSignupSuccessAction(response),
                  setUsernameAction(response.userid),
                  loginByPasswordStartAction(data.password, onLoginSuccess),
                ])
              ),
          ),
          catchError(error => of(orgunitSignupErrorAction(error))),
        ),
    ),
  );

export default orgunitSignupEpic;
