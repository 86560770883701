import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import { AuthenticationResponse } from '@mmw/services-auth-api-authentication/types';
import { getAuthenticationService } from '@mmw/services-holder';
import { ActionsObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, timeout } from 'rxjs/operators';

import { authenticateErrorAction, authenticateSuccessAction } from '../actions';
import {
  AUTHENTICATE_START,
  AuthenticateErrorAction,
  AuthenticateStartAction,
  AuthenticateSuccessAction,
} from '../types';

const { logger } = contextualConfig.application;
const { defaultTimeout } = contextualConfig.api;

function handleSuccess({ accessToken }: AuthenticationResponse) {
  return from(getAuthenticationService().getUserDetails()).pipe(
    map(loggedUser => authenticateSuccessAction(loggedUser, accessToken)),
    catchError(error => of(authenticateErrorAction(error))),
  );
}

type Input = AuthenticateStartAction;
type Output = AuthenticateSuccessAction | AuthenticateErrorAction;
const authenticateEpic = (
  action$: ActionsObservable<Input>,
): Observable<Output> =>
  action$.pipe(
    ofType(AUTHENTICATE_START),
    tap(action =>
      logger.debug(
        `Trying to authenticate username=${action.payload.username}`,
      ),
    ),
    switchMap(action =>
      from(
        getAuthenticationService().authenticate({
          username: action.payload.username,
          password: action.payload.password,
        }),
      ).pipe(
        timeout(defaultTimeout),
        tap(({ success }: AuthenticationResponse) =>
          logger.debug(`Result from authentication success=${success}`),
        ),
        switchMap(handleSuccess),
        catchError(error => of(authenticateErrorAction(error))),
      ),
    ),
  );

export default authenticateEpic;
