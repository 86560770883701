import defaultApiV2, { ApiResponse } from '@mmw/api-v2';
import { LanguageCode } from '@mmw/constants-languages';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import autoBind from 'auto-bind';
import { CancelToken } from 'axios';

import { getAccountUrl, getSaveAccountUrl } from './apiPaths';
import logger from './log';
import {
  AccountTypes,
  EditedTraderAccountJSON,
  EditTraderAccountJSON,
} from './types';

type Api = typeof defaultApiV2;

type TraderAgreementServiceOptions = {
  apiv2?: Api;
  authenticationService: AuthenticationService;
};

class TraderBankAccountService {
  api: Api;

  authenticationService: AuthenticationService;

  constructor({ apiv2, authenticationService }: TraderAgreementServiceOptions) {
    this.api = apiv2 || defaultApiV2;
    this.authenticationService = authenticationService;
    autoBind(this);
  }

  async saveBankAccount(
    account: EditTraderAccountJSON,
    branchID: number,
    language: LanguageCode,
  ): Promise<EditedTraderAccountJSON> {
    logger.debug(`Trying to save bank account for branch=${branchID}`);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<EditedTraderAccountJSON> =
        await this.api.post(getSaveAccountUrl(branchID, language), account, {
          headers,
        });
      const { data } = response;
      logger.info(`Successfully saved bank account for branch=${branchID}`);
      return data;
    } catch (error) {
      logger.error(
        `Error when trying to save bank account for branch=${branchID}, error=%O`,
        error,
      );
      throw error;
    }
  }

  async getBankAccount(
    branchID: number,
    accountType: AccountTypes,
    language: LanguageCode,
    cancelToken: CancelToken,
  ): Promise<EditedTraderAccountJSON> {
    logger.debug(`Trying to get bank account for branch=${branchID}`);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<EditedTraderAccountJSON> = await this.api.get(
        getAccountUrl(branchID, accountType, language),
        {
          headers,
          cancelToken,
        },
      );
      const { data } = response;
      logger.info(`Successfully got bank account for branch=${branchID}`);
      return data;
    } catch (error) {
      logger.error(
        `Error when trying to get bank account for branch=${branchID}, error=%O`,
        error,
      );
      throw error;
    }
  }
}

export default TraderBankAccountService;
