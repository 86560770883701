import { StyledProps } from '@mmw/ui-theme/types';
import defaultTheme from '@mmw/ui-web-structures-table/theme';
import Color from 'color';

const tableTheme = {
  ...defaultTheme,
  header: {
    ...defaultTheme.header,
    style: ({ theme, noWrap }: StyledProps<unknown>) => `
      ${defaultTheme.header.style({ theme })}
      td {
        border-color: ${theme.colors.pallet.primary};
        white-space: ${noWrap ? 'nowrap' : ''};
      }
    `,
    row: {
      ...defaultTheme.header.row,
      style: ({ theme }: StyledProps<unknown>) => `
        ${defaultTheme.header.row.style({ theme })}
        background-color: ${theme.colors.pallet.primary};
        color: ${theme.colors.pallet.light};
        span {
          color: ${theme.colors.pallet.light};
        }        
      `,
    },
  },
  body: {
    ...defaultTheme.body,
    style: ({ theme }: StyledProps<unknown>) => `
      ${defaultTheme.body.style({ theme })}
      background-color: ${theme.colors.pallet.basicLight};
      color: ${theme.colors.pallet.dark};
    `,
  },
  column: {
    ...defaultTheme.column,
    style: ({ theme, noWrap }: StyledProps<unknown>) => `
      ${defaultTheme.column.style({ theme })}
      border-color: ${theme.colors.pallet.basicLight};
      white-space: ${noWrap ? 'nowrap' : ''};
    `,
  },
  row: {
    ...defaultTheme.row,
    style: ({ theme, error, disableHover, selected }: StyledProps<unknown>) => `
      ${defaultTheme.row.style({ theme })};
      background-color: ${
        selected ? theme.colors.pallet.primary : theme.colors.pallet.basicLight
      };
      color: ${
        selected ? theme.colors.pallet.basicLight : theme.colors.pallet.dark
      };
      span {
        text-overflow: ellipsis;
        color: ${
          selected ? theme.colors.pallet.basicLight : theme.colors.pallet.dark
        };
      }
      ${
        error &&
        ` 
          background-color: ${Color(theme.colors.pallet.error)
            .fade(0.5)
            .string()};          
       `
      };
      ${
        !disableHover &&
        `
        &:hover {
        background-color: ${theme.colors.pallet.primary};
        color: ${theme.colors.pallet.basicLight};
        cursor: pointer;
        span {
          color: ${theme.colors.pallet.basicLight};
        }
        svg {
          fill: ${theme.colors.pallet.basicLight};
        }
        td {
          border-color: ${theme.colors.pallet.primary};
        }
      `
      }   
      }
    `,
  },
  icon: {
    ...defaultTheme.icon,
    style: ({ theme }: StyledProps<unknown>) => `
      ${defaultTheme.icon.style({ theme })}
      fill: ${theme.colors.pallet.light};
    `,
  },
};

export default tableTheme;
