import borderSchemas from '@mmw/ui-theme/modifiers/color/border';
import textModifiers from '@mmw/ui-web-elements-typography/theme/modifiers';

import errorMsgStyle from './errorMsgStyle';
import helpIconStyle from './helpIconStyle';
import labelContainerStyle from './labelContainerStyle';
import labelStyle from './labelStyle';
import requiredStyle from './requiredStyle';
import formFieldStyle from './style';

const modifiers = borderSchemas;

export default {
  style: formFieldStyle,
  labelContainer: {
    style: labelContainerStyle,
    label: {
      style: labelStyle,
      modifiers: textModifiers,
    },
  },
  required: {
    style: requiredStyle,
  },
  errorMsg: {
    style: errorMsgStyle,
    modifiers,
  },
  helpIcon: {
    style: helpIconStyle,
  },
  modifiers,
};
