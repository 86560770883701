import { I18nShape } from '@mmw/constants-i18n';
import { IconProps } from '@ui-system/interfaces-icon';
import { nanoid } from 'nanoid';
import * as React from 'react';

export type StepperStep = {
  label: I18nShape;
  optional: boolean;
  Component?: React.ReactNode;
  icon?: (props: IconProps) => React.ReactNode;
};

export interface StepperProps {
  steps: StepperStep[];
  onLastStepComplete?: () => void;
  alternativeLabel?: boolean;
  nonLinear?: boolean;
  initialStep?: number;
}

export const STEPPER_UUID = nanoid();

export type StepperType = React.FC<StepperProps>;
