import createApi, {
  ApiResponse as ApiResponseType,
  Interceptor as InterceptorType,
} from '@mmw/common-api-client';
import config from '@mmw/contextual-config';

export default createApi({
  baseURI: config.api.v2.baseURI,
});

export const apiV2Jobs = createApi({
  baseURI: config.api.jobs.baseURI,
});

export const oneSnapApi = createApi({
  baseURI: config.api.fileEvaluator.baseURI,
});

export const apiV2JobsInternalIP = createApi({
  baseURI: process.env.API_V2_JOBS_INTERNAL_IP
    ? `${process.env.API_V2_JOBS_INTERNAL_IP}/api-v2`
    : config.api.jobs.baseURI,
});

export type ApiResponse<T> = ApiResponseType<T>;
export type Interceptor = InterceptorType;
