import { SerializedStyles } from '@emotion/serialize';
import {
  Color,
  Margin,
  OpacityRange,
  Padding,
  Shadow,
} from '@ui-system/css/utils/types';
import { Style } from '@ui-system/interfaces/types';
import { ResponsiveProps } from '@ui-system/media-query';
import { nanoid } from 'nanoid';
import React, { ReactNode } from 'react';
import { LayoutChangeEvent } from 'react-native';
import { F, U } from 'ts-toolbelt';

export type Align = 'center' | 'flex-start' | 'flex-end' | 'stretch';
export type Justify =
  | 'center'
  | 'flex-start'
  | 'flex-end'
  | 'space-between'
  | 'space-around'
  | 'space-evenly';

export type ContainerDirection =
  | 'row'
  | 'column'
  | 'row-reverse'
  | 'column-reverse';
export type ContainerPosition =
  | 'static'
  | 'relative'
  | 'absolute'
  | 'fixed'
  | 'sticky'
  | 'inherit'
  | 'initial'
  | 'revert'
  | 'unset';
export interface InnerShadowProps {
  left?: boolean;
  top?: boolean;
  right?: boolean;
  bottom?: boolean;
  shadowColor?: Color | string;
  shadowOpacity?: number;
  shadowOffset?: number;
  shadowRadius?: number;
  elevation?: number;
  containerStyle?: Record<string, any>;
}
export interface ContainerInnerShadowProps {
  innerShadow?: boolean;
  innerShadowProps?: InnerShadowProps;
}

export interface ContainerProps
  extends Record<string, any>,
    ContainerInnerShadowProps,
    ResponsiveProps<ContainerProps> {
  children?: ReactNode;
  style?: Style | Style[] | SerializedStyles;
  className?: string;
  m?: Margin;
  p?: Padding;
  align?: Align;
  f?: number; // FLEX
  bg?: Color;
  border?: string;
  shadow?: Shadow;
  opacity?: OpacityRange;
  justify?: Justify;
  w?: number | string;
  h?: number | string;
  gap?: number;
  childCustomStyleProp?: string;
  onClick?: U.Nullable<F.Function>;
  direction?: ContainerDirection;
  position?: ContainerPosition;
  borderRadius?: number | string;
  borderBottomRightRadius?: number;
  borderBottomLeftRadius?: number;
  borderTopRightRadius?: number;
  borderTopLeftRadius?: number;
  lastChildStyle?: Style;
  firstChildStyle?: Style;
  ___dev?: boolean;
  onLayout?: (e: LayoutChangeEvent) => void;
  visible?: boolean;
  innerRef?: React.Ref<any>;
  modifiers?: string;
}

export interface GradientCoordinates {
  x: number;
  y: number;
}

export enum GradientEnum {
  Linear = 'linear',
  LinearReversed = 'linearReversed',
  Horizontal = 'horizontal',
  Diagonal = 'diagonal',
}
export type GradientType =
  | 'linear'
  | 'linearReversed'
  | 'horizontal'
  | 'diagonal';

export interface LinearGradientProps extends Omit<ContainerProps, 'children'> {
  colors: string | string[];
  start?: GradientCoordinates;
  end?: GradientCoordinates;
  height?: number | string; // DOES NOT ACCEPT px, only %
  children?: ReactNode;
  gradientType?: GradientType;
}
interface ContainerStatics {
  LinearGradient: LinearGradientType;
}

export const CONTAINER_UUID = nanoid();

export type ContainerType = React.FC<ContainerProps> & ContainerStatics;
export type LinearGradientType = React.FC<LinearGradientProps>;

export enum ShadowPositionsEnum {
  left = 'left',
  right = 'right',
  top = 'top',
  bottom = 'bottom',
}

// export interface ShadowProps extends ShadowStyleProps {
//   type?: ShadowPositionsEnum;
// }

// export type ShadowsMapProps = InnerShadowProps & ShadowStyleProps;
