/* eslint-disable no-param-reassign */
import { BasePath } from '@di/core';
import defaultApiV2, { ApiResponse } from '@mmw/api-v2';
import { LanguageCode } from '@mmw/constants-languages';
import { SalesOrgBrand } from '@mmw/constants-salesorgbrand-ids';
import { EMPTY_OBJECT } from '@mmw/constants-utils';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import autoBind from 'auto-bind';
import createCache, { Cache } from 'keshi';
import { reduce } from 'lodash';

import logger from './log';
import getPaths, { PRODUCTS_BASE_PATH_TOKEN } from './paths';
import {
  OperationResult,
  Pagination,
  ProductJSON,
  ProductParticipationRequestJSON,
  ProductParticipationResultJSON,
  ProductRequestObject,
} from './types';

type Api = typeof defaultApiV2;

type ProductsServiceOptions = {
  apiv2?: Api;
  authenticationService: AuthenticationService;
};

type ProductsMap = Record<string, ProductJSON>;

class ProductsService extends BasePath {
  api: Api;

  authenticationService: AuthenticationService;

  cache: Cache;

  constructor({ apiv2, authenticationService }: ProductsServiceOptions) {
    super(PRODUCTS_BASE_PATH_TOKEN);
    this.api = apiv2 || defaultApiV2;
    this.authenticationService = authenticationService;
    this.cache = createCache();
    autoBind(this);
  }

  async loadAndGetAllProductsMap(
    salesOrgBrand: SalesOrgBrand,
    language: LanguageCode,
    campaignCodes?: Array<string>,
  ): Promise<ProductsMap> {
    const campaignCodesCacheKeys = campaignCodes?.join('-');
    const cacheKey = `all-products-map:${salesOrgBrand}-${language}-${campaignCodesCacheKeys}`;
    const productsMap = await this.cache.resolve(
      cacheKey,
      async () => {
        const results = await this.getProducts(
          {
            salesOrgBrand,
            campaignCodes,
            limit: 100000,
            offset: 0,
          },
          language,
        );
        return reduce(
          results.list,
          (result, p) => {
            result[p.ean] = p;
            return result;
          },
          <Record<string, ProductJSON>>{},
        );
      },
      '4h',
    );
    return productsMap || EMPTY_OBJECT;
  }

  async getProducts(
    options: ProductRequestObject,
    language: string,
  ): Promise<Pagination<ProductJSON>> {
    logger.debug(
      'Trying to get products by lang=%s request=%O',
      language,
      options,
    );
    const cacheKey = `get-products:${JSON.stringify(options)}-${language}`;
    return this.cache.resolve(
      cacheKey,
      async () => {
        try {
          const headers =
            await this.authenticationService.getAuthenticationHttpHeaders();
          const response: ApiResponse<Pagination<ProductJSON>> =
            await this.api.post(
              getPaths(await this.getBasePath()).GetProductsPath(language),
              options,
              {
                headers,
              },
            );
          const { data } = response;
          logger.info(`Successfully got products, size ${data.total}`);
          return data;
        } catch (error) {
          logger.error('Error when getting products, error=%O', error);
          throw error;
        }
      },
      '5m',
    );
  }

  async retrieveProductByEan(
    ean: string,
    salesOrgBrand: SalesOrgBrand,
    language: string,
  ): Promise<ProductJSON | null> {
    logger.debug('Trying to retrieve product by ean=%O', ean);
    try {
      const data = await this.getProducts(
        {
          salesOrgBrand,
          ean,
          limit: 1,
          offset: 0,
          ignoreActorVisibility: true,
        },
        language,
      );
      logger.info(
        `Successfully retrieved product by ean=${ean}, total=${data.total}`,
      );
      return data.total > 0 ? data.list[0] : null;
    } catch (error) {
      logger.error('Error when retrieving product by ean, error=%O', error);
      throw error;
    }
  }

  async getProductComponents(
    productID: number,
    language: string,
  ): Promise<Array<ProductJSON>> {
    logger.debug('Trying to get products component by id', productID);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Array<ProductJSON>> = await this.api.get(
        getPaths(await this.getBasePath()).GetProductComponentsPath(
          productID,
          language,
        ),
        {
          headers,
        },
      );
      const { data } = response;
      logger.info(`Successfully got product components, size ${data.length}`);
      return data;
    } catch (error) {
      logger.error('Error when getting product components, error=%O', error);
      throw error;
    }
  }

  async isSerialnumberPatternValid(
    productID: number,
    serialnumber: string,
  ): Promise<boolean> {
    logger.debug('Trying to verify serial pattern', productID, serialnumber);
    const cacheKey = `serial-pattern:${productID}-${serialnumber}`;
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const success = await this.cache.resolve(
        cacheKey,
        async () => {
          const response: ApiResponse<OperationResult> = await this.api.post(
            getPaths(await this.getBasePath()).ValidateSerialnumberPatternPath(
              productID,
              serialnumber,
            ),
            {},
            { headers },
          );
          const { data } = response;
          return data.success;
        },
        '30 mins',
      );
      logger.info(`Validated serialnumber pattern as ${String(success)}`);
      return success;
    } catch (error) {
      logger.error(
        `Error validating serialnumber pattern with product=${productID} and sn=${serialnumber} error=%O`,
        error,
      );
      throw error;
    }
  }

  async isCouponValid(
    productID: number,
    coupon: string,
    campaignCode?: string,
  ): Promise<boolean> {
    logger.debug('Trying to verify coupon', productID, coupon);
    const cacheKey = `coupon-validation:${productID}-${coupon}`;
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const success = await this.cache.resolve(
        cacheKey,
        async () => {
          const response: ApiResponse<OperationResult> = await this.api.post(
            getPaths(await this.getBasePath()).ValidateCouponPath(
              productID,
              coupon,
              campaignCode,
            ),
            {},
            { headers },
          );
          const { data } = response;
          return data.success;
        },
        '30 mins',
      );
      logger.info(`Validated coupon as ${String(success)}`);
      return success;
    } catch (error) {
      logger.error(
        `Error validating coupon pattern with product=${productID} and sn=${coupon} error=%O`,
        error,
      );
      throw error;
    }
  }

  async isProductEnabledOnCampaign(
    productID: number,
    request: ProductParticipationRequestJSON,
  ): Promise<ProductParticipationResultJSON> {
    logger.debug('Trying to verify product enabled', productID, request);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ProductParticipationResultJSON> =
        await this.api.post(
          getPaths(await this.getBasePath()).ProductEnabledPath(productID),
          request,
          {
            headers,
          },
        );
      const { data } = response;
      return data;
    } catch (error) {
      logger.error(
        `Error validating product enabled=${productID} error=%O`,
        error,
      );
      throw error;
    }
  }
}

export default ProductsService;
