import { I18nShape } from '@mmw/constants-i18n';
import { Modifiers } from '@mmw/ui-theme/modifiers/types';
import { use } from '@mmw/ui-theme/utils';
import { Caption2 } from '@mmw/ui-web-elements-typography';
import { noop } from 'lodash';
import * as React from 'react';
import { ChangeEvent, ReactNode } from 'react';
import styled from 'styled-components';
import { F, U } from 'ts-toolbelt';

import { useRadioState } from './hooks';
import Radio from './Radio';

const Container = styled.div<{ modifiers?: Modifiers }>(props =>
  use(props, 'radio.container'),
);

const Field = styled.label<{ modifiers?: Modifiers }>(props =>
  use(props, 'radio.field'),
);

export const Label = styled(Caption2)(props => use(props, 'radio.label'));

export type Option = {
  name?: I18nShape;
  value: string | number;
  label?: U.Nullable<ReactNode>;
  decorator?: U.Nullable<ReactNode>;
  content?: U.Nullable<ReactNode>;
};

export type ControlledRadioGroupProps = {
  onChange: F.Function<[ChangeEvent<HTMLInputElement>]>;
  options: Option[];
  name: string;
  value?: any;
  modifiers?: Modifiers;
  labelModifiers?: Modifiers;
  disabled?: boolean;
  onFocus?: F.Function;
};

export const ControlledRadioGroup: React.FC<ControlledRadioGroupProps> = ({
  name,
  value,
  onChange,
  options,
  modifiers,
  labelModifiers,
  disabled,
  onFocus,
}: ControlledRadioGroupProps) => (
  <Container modifiers={modifiers}>
    {options.map((option: Option) => (
      <React.Fragment key={`${name}.${option.value}`}>
        <Field modifiers={modifiers}>
          {option.decorator && option.decorator}
          <Radio
            modifiers={modifiers}
            name={name}
            value={option.value}
            onChange={onChange}
            checked={value === option.value}
            disabled={disabled}
            onFocus={onFocus}
          />
          {option.label ? (
            option.label
          ) : (
            <Label modifiers={labelModifiers} i18n={option.name} />
          )}
        </Field>
        {option.content && option.content}
      </React.Fragment>
    ))}
  </Container>
);

type RadioGroupProps = {
  onChange: F.Function<[string]>;
  options: Option[];
  name: string;
  initialValue: any;
  modifiers?: Modifiers;
  labelModifiers?: Modifiers;
  disabled?: boolean;
  onFocus?: F.Function;
};

const RadioGroup: React.FC<RadioGroupProps> = ({
  name,
  initialValue,
  onChange,
  options,
  modifiers,
  labelModifiers,
  disabled,
  onFocus,
}: RadioGroupProps) => {
  // XXX: I THINK THIS IS BUGGED ... WHY ON CHANGE ON useRadioState? wtf
  const [value, setValue] = useRadioState(initialValue, onChange);
  return (
    <ControlledRadioGroup
      name={name}
      options={options}
      modifiers={modifiers}
      labelModifiers={labelModifiers}
      disabled={disabled}
      onChange={setValue}
      value={value}
      onFocus={onFocus}
    />
  );
};

RadioGroup.defaultProps = {
  onFocus: noop,
};

export default RadioGroup;
