import { I18nShape } from '@mmw/constants-i18n';
import { Modifiers } from '@mmw/ui-theme/modifiers/types';
import { StyledElement } from '@mmw/ui-theme/types';
import { use } from '@mmw/ui-theme/utils';
import { P } from '@mmw/ui-web-elements-typography';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import Checkbox from './Checkbox';

const ContainerLabel: StyledElement<'label', unknown> = styled.label(props =>
  use(props, 'checkbox.label'),
);
const LabelText = styled(P)(props => use(props, 'checkbox.label.text'));

const ContainerText: StyledElement<'div', unknown> = styled.div(props =>
  use(props, 'checkbox.label.container'),
);

type Props = {
  name?: string;
  onChange?: (checked: boolean) => any;
  checked?: boolean;
  label?: React.ReactElement<unknown> | null | void;
  labelI18n?: I18nShape | null | void;
  modifiers?: Modifiers;
  labelModifiers?: Modifiers;
  onFocus?: () => void;
  disabled?: boolean;
  hideLabel?: boolean;
};

const CheckboxLabel: React.FC<Props> = ({
  name,
  checked,
  onChange,
  labelI18n,
  labelModifiers,
  label,
  onFocus,
  hideLabel,
  ...otherProps
}: Props) => {
  const [value, setValue] = useState<boolean>(!!checked);
  const handleChange = useCallback(
    () =>
      setValue(prev => {
        if (onChange) {
          onChange(!prev);
        }
        return !prev;
      }),
    [onChange],
  );

  // useEffect(() => {
  //   setValue(!!checked);
  // }, [checked]);

  if (hideLabel) {
    return (
      <Checkbox
        name={name}
        checked={value}
        onChange={handleChange}
        onFocus={onFocus}
        {...otherProps}
      />
    );
  }

  return (
    <ContainerLabel>
      <Checkbox
        name={name}
        checked={value}
        onChange={handleChange}
        onFocus={onFocus}
        {...otherProps}
      />
      <ContainerText>
        {label || <LabelText i18n={labelI18n} modifiers={labelModifiers} />}
      </ContainerText>
    </ContainerLabel>
  );
};

export default CheckboxLabel;
