import withIsVisible from '@mmw/ui-hoc-with-is-visible';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';
import styled, { css } from 'styled-components';

import Dropdown from './Dropdown';

export const Item = styled.li`
  list-style: none;
  font-size: 0.9rem;
  position: relative;
  width: auto;

  ${({ theme }) => theme.media.phone`
    display: flex;
    border-bottom: 1px solid #ccc;
    width: 100%;
    justify-content: center;
  `}

  ${({ menuOpen }) =>
    menuOpen &&
    css`
      background-color: ${({ bgHover }) => bgHover};
      color: #fff;
    `}
`;

class NavItem extends React.Component {
  componentWillMount() {
    document.addEventListener('mousedown', this.onClickOut, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onClickOut, false);
  }

  onClickOut = e => {
    const { hide } = this.props;
    if (this.nodeRef.contains(e.target)) {
      return;
    }
    hide();
  };

  mountMenu() {
    const { options, alignRight } = this.props;

    const menuList = options.map(option => (
      <Dropdown.Item
        icon={option.icon}
        href={option.href}
        title={option.title}
        defaultMsg={option.defaultMsg}
        msgKey={option.msgKey}
        onClick={option.onClick}
      />
    ));

    return <Dropdown alignRight={alignRight}>{menuList}</Dropdown>;
  }

  render() {
    const { children, isVisible, toggle } = this.props;

    return (
      <Item
        // eslint-disable-next-line no-return-assign
        ref={node => (this.nodeRef = node)}
        onClick={toggle}
        menuOpen={isVisible}
      >
        {children}
        {isVisible && this.mountMenu()}
      </Item>
    );
  }
}

NavItem.propTypes = {
  hide: PropTypes.func,
  children: PropTypes.node,
  isVisible: PropTypes.bool,
  toggle: PropTypes.func,
  options: PropTypes.arrayOf(Object),
  alignRight: PropTypes.bool,
};

NavItem.defaultProps = {
  hide: noop,
  isVisible: false,
  toggle: noop,
  children: null,
  options: [],
  alignRight: false,
};

export default compose(withIsVisible)(NavItem);
