import CONSTANT from '@mmw/constants-campaign-agreement';
import qs from 'qs';
// import { accessTokenQuery } from '@mmw/services-auth-api-authentication';

const BASE_PATH = '/v1/trader/orgunit-agreement';
const BRANCH_STATUS_PATH = '/branch/status';
const BRANCH_SUBSCRIBE_PATH = '/branch/subscribe';
const STATUS_PATH = `${BASE_PATH}/status`;
const SUBSCRIBE_PATH = `${BASE_PATH}/subscribe`;

export const getAgreementBranchStatusUrl = (
  language: string,
  campaignId: number,
  branchID: number,
): string =>
  `${BASE_PATH}${BRANCH_STATUS_PATH}?${qs.stringify({
    language,
    agreementType: CONSTANT.TYPES.CAMPAIGN_ACTIVATION_TERMS,
    campaignId,
    branchID,
  })}`;

export const subscribeBranchAgreementUrl = (
  language: string,
  campaignId: number,
  branchID: number,
): string =>
  `${BASE_PATH}${BRANCH_SUBSCRIBE_PATH}?${qs.stringify({
    language,
    agreementType: CONSTANT.TYPES.CAMPAIGN_ACTIVATION_TERMS,
    campaignId,
    branchID,
  })}`;

export const getAgreementStatusPath = (
  language: string,
  contractName: string,
  orgunitID: number,
): string =>
  `${STATUS_PATH}?${qs.stringify({
    language,
    contractName,
    orgunitID,
  })}`;

export const subscribeAgreementPath = (
  language: string,
  contractName: string,
  orgunitID: number,
): string =>
  `${SUBSCRIBE_PATH}?${qs.stringify({
    language,
    contractName,
    orgunitID,
  })}`;
