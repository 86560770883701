import { EMPTY_OBJECT } from '@mmw/constants-utils';
import { BaseAsyncState } from '@redux-async-module/reducer-utils';
import { SearchState } from '@redux-search-module/reducer-utils';

import { ChangeStatusState } from './ChangeStatusState';
import { CreateIssueState } from './CreateIssueState';
import { PerformIssueState } from './PerformIssueState';

export class AuditState<RequestFields, ItemType> extends SearchState<
  RequestFields,
  ItemType
> {
  regListByOrgunit: Record<number, ItemType[]> = EMPTY_OBJECT;

  changeStatus: ChangeStatusState = new ChangeStatusState();

  createIssue: CreateIssueState = new CreateIssueState();

  performIssue: PerformIssueState = new PerformIssueState();

  auditCreation: BaseAsyncState;
}
