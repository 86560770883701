import { LanguageCode } from '@mmw/constants-languages';
import { RegistrationSource } from '@mmw/constants-registration-sources';
import qs from 'qs';
import { U } from 'ts-toolbelt';

const BASE_PATH = '/v1/manu/registration';

export const PerformRegistrationPath = (
  simulate: boolean,
  source: RegistrationSource,
): string =>
  `${BASE_PATH}?${qs.stringify({
    simulate,
    source,
  })}`;

export const GetCreateIssuesPath = (): string => `${BASE_PATH}/issues`;

export const GetRegistrationPath = (
  registrationId: number,
  language: LanguageCode,
): string =>
  `${BASE_PATH}/registration/${registrationId}?${qs.stringify({
    language,
  })}`;

export const GetSearchRegistrationsPath = (language: LanguageCode): string =>
  `${BASE_PATH}/registrations?${qs.stringify({
    language,
  })}`;

export const RetrieveRegistrationPath = (
  registrationID: number,
  language: LanguageCode,
): string =>
  `${BASE_PATH}/registration/${registrationID}?${qs.stringify({
    language,
  })}`;

export const ChangeRegistrationStatusPath = (): string =>
  `${BASE_PATH}/change-status`;

export const ChangeRegistrationValidationErrorPath = (): string =>
  `${BASE_PATH}/change-validation-errors`;

export const PerformIssueActionPath = (
  registrationID: number,
  issueID: number,
  language: LanguageCode,
): string =>
  `${BASE_PATH}/${registrationID}/issue/${issueID}?language=${language}`;

export const UpdateCampaignItemsQuestionsPath = (
  registrationId: number,
  language: LanguageCode,
): string =>
  `${BASE_PATH}/${registrationId}/questions?${qs.stringify({
    language,
  })}`;

// GET /v1/manu/registration/downloadFile/{regItem}/{idFile}
export const GetRegistrationFilePath = (
  fileID: number,
  registrationItemID: number,
  headers: Record<string, U.Nullable<string>>,
): string =>
  `${BASE_PATH}/downloadFile/${registrationItemID}/${fileID}?${qs.stringify({
    ...headers,
  })}`;

export const UpdateFilesByTypePath = (
  registrationId: number,
  language: LanguageCode,
  ignoreStatusChangeAfterCorrection = true,
): string =>
  `${BASE_PATH}/${registrationId}/files-by-type?${qs.stringify({
    language,
    ignoreStatusChangeAfterCorrection,
  })}`;

export const RemoveFileByIdPath = (
  registrationId: number,
  fileId: number,
  language: LanguageCode,
  ignoreStatusChangeAfterCorrection = true,
): string =>
  `${BASE_PATH}/${registrationId}/invoice-file/${fileId}/delete?${qs.stringify({
    language,
    ignoreStatusChangeAfterCorrection,
  })}`;

export const GetRegistrationItemHistoryPath = (
  registrationItemId: number,
  language: LanguageCode,
): string =>
  `${BASE_PATH}/registrationitem/history/${registrationItemId}?${qs.stringify({
    language,
  })}`;

export const GetRegistrationUserPath = (
  registrationItemId: number,
  language: LanguageCode,
): string =>
  `${BASE_PATH}/${registrationItemId}/user?${qs.stringify({
    language,
  })}`;

export const DownloadRegistrationAsXLSPath = (language: LanguageCode): string =>
  `${BASE_PATH}/download-xls?${qs.stringify({
    language,
  })}`;

export const ReverseRegistrationPath = (language: LanguageCode): string =>
  `${BASE_PATH}/reverse?${qs.stringify({
    language,
  })}`;

export const RevalidateRegistrationPath = (language: LanguageCode): string =>
  `${BASE_PATH}/revalidate?${qs.stringify({
    language,
  })}`;

export const GetRegistrationItemCommentsPath = (
  registrationItemId: number,
  language: LanguageCode,
): string =>
  `${BASE_PATH}/${registrationItemId}/comments?${qs.stringify({
    language,
  })}`;

export const GetInvoiceFilePath = (
  fileID: number,
  registrationId: number,
  language: LanguageCode,
  headers: Record<string, U.Nullable<string>>,
): string =>
  `${BASE_PATH}/${registrationId}/invoice-file/${fileID}?${qs.stringify({
    language,
    ...headers,
  })}`;
