import { useNavigationStateItem } from '@lib/navigation/hooks/useNavigationStateItem';
import { EMAIL_BLOCKED } from '@mmw/constants-i18n-authentication';
import { SOMETHING_WENT_WRONG_TRY_AGAIN } from '@mmw/constants-i18n-messages';
import { EMAIL_REGEX } from '@mmw/constants-validation-field-schemas/utils';
import {
  useClearError,
  useUsername,
} from '@mmw/redux-store-auth-api-login-by-password/hooks';
import {
  useAuthenticationRequestUnblock,
  useIsLoading,
} from '@mmw/redux-store-auth-api-request-unblock/hooks';
import { SecurityScopeNames } from '@mmw/services-auth-api-authentication/types';
import { getAuthenticationService } from '@mmw/services-holder';
import { useNotifications } from '@mmw/ui-hybrid-libraries-notifications/hooks';
import { useGetToken, useIsGettingToken } from '@recaptcha/v3';
import UI from '@ui-system/ui';
import delay from 'lodash/delay';
import isObject from 'lodash/isObject';
import React, { useCallback, useMemo } from 'react';

import AuthErrorCard from './AuthErrorCard';

interface CommonProps {
  redirectUrl?: string;
}

export function useRequestUnblockAccount({
  redirectUrl,
  scopeNames,
}: CommonProps & { scopeNames?: SecurityScopeNames[] }) {
  const username = useNavigationStateItem<string>('username');
  const loginByPassUsername = useUsername();
  const notify = useNotifications();
  const unblock = useAuthenticationRequestUnblock();
  const getToken = useGetToken();
  const clear = useClearError();
  const hasUserEmail = useMemo(() => {
    if (username) {
      return EMAIL_REGEX.test(username);
    }
    if (loginByPassUsername) {
      return EMAIL_REGEX.test(loginByPassUsername);
    }
    return false;
  }, [loginByPassUsername, username]);
  return useCallback(async () => {
    try {
      let retrievedUserInfo;
      const recaptchaResponse = await getToken('request-unblock');
      if (hasUserEmail) {
        retrievedUserInfo = await getAuthenticationService().retrieveUserid(
          (username || loginByPassUsername) as string,
          scopeNames,
        );
      }
      const scopeName =
        (isObject(retrievedUserInfo) &&
          (
            retrievedUserInfo as {
              userid: string;
              scopeName: SecurityScopeNames;
            }
          )?.scopeName) ||
        SecurityScopeNames.NON_CONSUMERS;

      unblock({
        username: username || loginByPassUsername,
        recaptchaResponse,
        recaptchaType: 'V3',
        scopeName,
        redirectUrl,
      });
      notify(EMAIL_BLOCKED.EXPLANATION, 'success');
      delay(clear, 500);
    } catch (e) {
      notify(SOMETHING_WENT_WRONG_TRY_AGAIN, 'error');
    }
  }, [
    clear,
    getToken,
    hasUserEmail,
    loginByPassUsername,
    notify,
    redirectUrl,
    scopeNames,
    unblock,
    username,
  ]);
}

export const RequestUnblockAccountInternal: React.FC<CommonProps> = ({
  redirectUrl,
  scopeNames,
  ...props
}: { scopeNames?: SecurityScopeNames[] } & CommonProps) => {
  const loading = useIsLoading();
  const isGettingToken = useIsGettingToken('request-unblock');
  const onClick = useRequestUnblockAccount({ redirectUrl, scopeNames });
  return (
    <UI.Container justify="center" direction="column" {...props}>
      <AuthErrorCard>
        <UI.Container justify="center" m="4, 0, 2, 0">
          <UI.Button
            onClick={onClick}
            i18n={EMAIL_BLOCKED.ACTION}
            loading={loading || isGettingToken}
          />
        </UI.Container>
      </AuthErrorCard>
    </UI.Container>
  );
};

export const RequestUnblockAccountWeb: React.FC<CommonProps> = ({
  redirectUrl,
}: CommonProps) => {
  const loading = useIsLoading();
  const isGettingToken = useIsGettingToken('request-unblock');
  const onClick = useRequestUnblockAccount({ redirectUrl });
  return (
    <UI.Container justify="center" direction="column">
      <AuthErrorCard>
        <UI.Container justify="center" m="4, 0, 2, 0">
          <UI.Button
            onClick={onClick}
            i18n={EMAIL_BLOCKED.ACTION}
            loading={loading || isGettingToken}
          />
        </UI.Container>
      </AuthErrorCard>
    </UI.Container>
  );
};

export const RequestUnblockAccount: React.FC = ({ ...props }) => (
  <RequestUnblockAccountInternal {...props} />
);
