import { css } from '@ui-system/css';
import { ImageProps } from '@ui-system/interfaces-image';
import UI from '@ui-system/ui';
import React from 'react';

import { Props, TitleContentActionCard } from '../title-content-action';

interface TitleContentActionImageHeaderCard extends Omit<Props, 'style'> {
  src?: ImageProps['src'];
  width?: ImageProps['width'];
  height?: ImageProps['height'];
  resizeMode?: ImageProps['resizeMode'];

  imageResponsive?: ImageProps['responsive'];
}

const STYLE = css`
  margin-top: -10px;
  flex: 1;
`;

const IMAGE_RADIUS = css`
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
`;

export const TitleContentActionImageHeaderCard: React.FC<
  TitleContentActionImageHeaderCard
> = ({
  src,
  width,
  height,
  resizeMode,
  children,
  imageResponsive,
  ...props
}: TitleContentActionImageHeaderCard) => (
  <UI.Container direction="column">
    <UI.Image
      src={src}
      height={height}
      width={width}
      resizeMode={resizeMode}
      // @ts-ignore
      style={IMAGE_RADIUS}
      responsive={imageResponsive}
    />
    <TitleContentActionCard style={STYLE} {...props}>
      {children}
    </TitleContentActionCard>
  </UI.Container>
);

TitleContentActionImageHeaderCard.defaultProps = {
  height: 'auto',
  width: '100%',
  resizeMode: 'contain',
};
