import { CreateIssueJSON } from '@issue/interfaces';
import { ManufacturerAuditStatusOption } from '@manufacturer/audits-ui/types';
import { RegistrationResponseJSON } from '@mmw/services-core-trader-registration/types';
import { U } from 'ts-toolbelt';

// import { CampaignStatus } from '@mmw/constants-campaign-status';
export enum JuraAuditStatus {
  OPEN = 'OPEN', // CampaignStatus.INCOMPLETE_DATA,
  TRANSMITTED = 'TRANSMITTED', // CampaignStatus.VERIFYING,
  NEEDS_REVISION = 'NEEDS_REVISION', // CampaignStatus.VALIDATION_FAILED,
  REJECTED = 'REJECTED', // CampaignStatus.REJECTED,
  APPROVED = 'APPROVED', // CampaignStatus.TRANSACTION_FINISHED,
}

export type JuraAuditState = {
  created: boolean;
  registration: U.Nullable<RegistrationResponseJSON>;
  id: U.Nullable<number>;
  status: U.Nullable<JuraAuditStatus>;
  canOrderProducts: boolean;
  canStartAudit: boolean;
  canCompleteAudit: boolean;
  canShowFilesCard: boolean;
  canUploadSellout: boolean;
  isRejected: boolean;
  allowedStatusTransitions: JuraAuditStatus[];
  allowedStatusToCreateIssues: JuraAuditStatus[];
  canCreateIssues: boolean;
  createIssuesOnTransition: Partial<Record<JuraAuditStatus, CreateIssueJSON[]>>;
};

export type JuraAuditStatusOption = ManufacturerAuditStatusOption;
