import {
  Action,
  ActionCreator,
  DispatchAnActionCallback,
} from '@redux-basic-module/reducer-utils/types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Any } from 'ts-toolbelt';
import { useDeepCompareEffect } from 'use-deep-compare';

export function createDispatchHook<A extends Action<string, null, null>>(
  actionCreator: ActionCreator<
    Any.At<A, 'type'>,
    Any.At<A, 'payload'>,
    Any.At<A, 'meta'>
  >,
): () => DispatchAnActionCallback<A> {
  return function useDispatchHook(): DispatchAnActionCallback<A> {
    const dispatch = useDispatch();
    return useCallback(
      (payload: Any.At<A, 'payload'>, meta: Any.At<A, 'meta'>) => {
        // @ts-ignore
        dispatch(actionCreator(payload, meta));
      },
      [dispatch],
    ) as DispatchAnActionCallback<A>;
  };
}

export function createDispatchHookOnMount<A extends Action<string, null, null>>(
  actionCreator: ActionCreator<
    Any.At<A, 'type'>,
    Any.At<A, 'payload'>,
    Any.At<A, 'meta'>
  >,
): DispatchAnActionCallback<A> {
  const useDispatchHook = createDispatchHook<A>(actionCreator);
  return function useDispatchHookOnMount(
    payload: Any.At<A, 'payload'>,
    meta: Any.At<A, 'meta'>,
  ) {
    const callback = useDispatchHook();
    useDeepCompareEffect(() => {
      // @ts-ignore
      callback(payload, meta);
    }, [callback, payload]);
  } as DispatchAnActionCallback<A>;
}
