// removed prop height: 245px;
export default {
  style: ({ theme }) => `
    background-color:${theme.colors.pallet.light};
    max-height: 360px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  `,
  image: {
    style: ({ theme }) => `
      max-height: 360px;      
      width: 100%;
      max-width: ${theme.page.variable.CONTENT_MAX_WIDTH};
      object-fit: cover;
    `,
  },
};
