import contextualConfig from '@mmw/contextual-config';
import ofType, { BatchAction } from '@mmw/redux-rx-of-type-operator';
import { authenticateBySSOTokenStartAction } from '@mmw/redux-store-auth-api-authentication/actions';
import { AuthenticateBySSOTokenStartAction } from '@mmw/redux-store-auth-api-authentication/types';
import { notAuthenticatedOperationTokenSelector } from '@mmw/redux-store-email-verification/stateSelector';
import { RootState as VerifState } from '@mmw/redux-store-email-verification/types';
import {
  languageSelector,
  RootState as LangState,
} from '@mmw/redux-store-i18n/stateSelector';
import { getConsumerMembershipService } from '@mmw/services-holder';
import get from 'lodash/get';
import { batchActions } from 'redux-batched-actions';
import { ActionsObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import {
  createConsumerAccountErrorAction,
  createConsumerAccountSuccessAction,
} from '../actions';
import {
  CREATE_CONSUMER_ACCOUNT_START,
  CreateConsumerAccountErrorAction,
  CreateConsumerAccountStartAction,
  CreateConsumerAccountSuccessAction,
  RootState,
} from '../types';

const { logger } = contextualConfig.application;

type Output =
  | CreateConsumerAccountSuccessAction
  | CreateConsumerAccountErrorAction
  | AuthenticateBySSOTokenStartAction;

const createConsumerAccountEpic = (
  action$: ActionsObservable<CreateConsumerAccountStartAction>,
  state$: Observable<RootState & LangState & VerifState>,
): Observable<Output | BatchAction<any>> =>
  action$.pipe(
    ofType(CREATE_CONSUMER_ACCOUNT_START),
    tap(() => logger.debug('Will try to create consumer level 4 account')),
    withLatestFrom(
      state$.pipe(map(notAuthenticatedOperationTokenSelector)),
      state$.pipe(map(languageSelector)),
    ),
    switchMap(([{ payload }, notAuthenticatedOperationToken, language]) =>
      from(
        getConsumerMembershipService().createConsumerLevel4MembershipAccount(
          { ...payload, notAuthenticatedOperationToken },
          language,
        ),
      ).pipe(
        map(data =>
          batchActions([
            createConsumerAccountSuccessAction(data),
            authenticateBySSOTokenStartAction(get(data, 'ssoToken')),
          ]),
        ),
        catchError(error => of(createConsumerAccountErrorAction(error))),
      ),
    ),
  );

export default createConsumerAccountEpic;
