import qs from 'qs';

const BASE_PATH = '/v1/manu/flow-of-goods';

export const GetManuFlowOfGoodsPath = (request, language): string =>
  `${BASE_PATH}?${qs.stringify({
    ...request,
    language,
  })}`;
export const GetManuFlowOfGoodsProductsPath = (request, language): string =>
  `${BASE_PATH}/products?${qs.stringify({
    ...request,
    language,
  })}`;
