import { connect as formikConnect, getIn } from '@mmw/ui-libraries-formik-v2';
import { compose, HOC, mapProps } from 'recompose';

import { WithFieldPathProps } from '../types';

const withErrorMessage: HOC<unknown, WithFieldPathProps> = compose(
  formikConnect,
  mapProps(
    ({
      formik: { errors, touched, submitCount },
      fieldPath,
      defaultValue,
      ...rest
    }) => ({
      ...rest,
      errorI18n: {
        key: getIn(errors, fieldPath),
        message: getIn(errors, fieldPath),
      },
      error:
        getIn(errors, fieldPath) &&
        (getIn(touched, fieldPath) || submitCount > 0),
      fieldPath,
    }),
  ),
);

export default withErrorMessage;
