import { use } from '@mmw/ui-theme/utils';
import { GlobalSpinner } from '@mmw/ui-web-blocks-spinner';
import { useExtraConfigContext } from '@retail/configure-extra-configs';
import { useGlobalSpinnerVisible } from '@retail/spinner-redux-store/hooks';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import styled, { CSSProperties } from 'styled-components';

import Banner from './elements/Banner';
import Content from './elements/Content';
import Footer from './elements/Footer';
import Header from './elements/Header';
import Navbar from './elements/Navbar';

type Props = {
  children: React.ReactNode;
  containerStyle?: CSSProperties;
  navBarContent?: React.ReactNode;
  showNavBar?: boolean;
  navMenuIsPresent?: boolean;
};

export type PageProps = Omit<Props, 'children'>;

const Container = styled.div<
  Pick<Props, 'navMenuIsPresent' | 'showNavBar' | 'containerStyle'>
>(props => use(props, 'page'));

interface StaticPage {
  Header: typeof Header;
  Navbar: typeof Navbar;
  Banner: typeof Banner;
  Content: typeof Content;
  Footer: typeof Footer;
}

const Page: React.FC<Props> & StaticPage = ({
  children,
  containerStyle,
  showNavBar,
  navBarContent,
  navMenuIsPresent,
}: Props) => {
  const globalSpinnerVisible = useGlobalSpinnerVisible();
  const { navRoutes } = useExtraConfigContext();
  return (
    <>
      <GlobalSpinner visible={globalSpinnerVisible} />
      <Page.Header showNavBar={showNavBar} navBarContent={navBarContent} />
      <Container
        style={containerStyle}
        showNavBar={showNavBar}
        navMenuIsPresent={navMenuIsPresent || !isEmpty(navRoutes)}
      >
        {children}
        <Page.Footer />
      </Container>
    </>
  );
};

Page.Header = Header;
Page.Navbar = Navbar;
Page.Banner = Banner;
Page.Content = Content;
Page.Footer = Footer;

Page.defaultProps = {
  showNavBar: false,
};

export default Page;
