import {
  useChangeStatusByOrdernumber,
  useChangeStatusLoading,
  useRetrieveRequestLoading,
} from '@audit/audits-redux-store/hooks';
import {
  AuditState,
  AuditStatus,
  getAuditToCampaignStatusMappingBySob,
} from '@audit/utils';
import { useIssuesToSave } from '@issue/context';
import { JuraAuditStatus } from '@jura/audit-status/types';
import { useAuditStatusChangeOptions } from '@manufacturer/audits-ui/hooks';
import { CampaignStatus } from '@mmw/constants-campaign-status';
import { U } from '@utils/ts';
import filter from 'lodash/filter';
import findKey from 'lodash/findKey';
import size from 'lodash/size';
import * as React from 'react';
import { useCallback, useMemo } from 'react';

import { ChangeAuditStatusView } from './ChangeAuditStatusView';

// TODO: useAuditStatusChangeOptions imported from manufacturer, move package?

export function useIsLoading(auditID: U.Nullable<number>): boolean {
  const retrieveRequestLoading = useRetrieveRequestLoading(auditID);
  const changeStatusLoading = useChangeStatusLoading();
  return retrieveRequestLoading || changeStatusLoading;
}

type Props = {
  auditState: AuditState;
  ordernumber: U.Nullable<string>;
};

export const ChangeAuditStatus: React.FC<Props> = ({
  auditState,
  ordernumber,
}: Props) => {
  const {
    status,
    id,
    allowedStatusTransitions,
    allowedStatusToCreateIssues,
    createIssuesOnTransition,
  } = auditState;
  const options = useAuditStatusChangeOptions(auditState);
  const changeStatus = useChangeStatusByOrdernumber();
  const createdIssuesByUser = useIssuesToSave();
  const loading = useIsLoading(id);
  const onChange = useCallback(
    (newStatus: AuditStatus) => {
      if (!ordernumber || status === newStatus) return;

      const createIssues = allowedStatusToCreateIssues.includes(newStatus)
        ? createIssuesOnTransition[newStatus] || createdIssuesByUser
        : null;
      const mappedCampaignStatus =
        getAuditToCampaignStatusMappingBySob<
          Record<CampaignStatus, JuraAuditStatus>
        >();
      const campaignStatus = findKey(
        mappedCampaignStatus,
        v => v === newStatus,
      );
      changeStatus(
        ordernumber,
        // @ts-ignore
        campaignStatus as CampaignStatus,
        undefined,
        createIssues,
      );
    },
    [
      ordernumber,
      status,
      allowedStatusToCreateIssues,
      createIssuesOnTransition,
      createdIssuesByUser,
      changeStatus,
    ],
  );
  const filteredOptions = useMemo(
    () => filter(options, op => op.value !== status && !op.hideButton),
    [options, status],
  );
  if (!ordernumber || !status || size(filteredOptions) === 0) return null;
  return (
    <ChangeAuditStatusView
      status={status}
      onChange={onChange}
      options={filteredOptions}
      loading={loading}
      createdIssuesByUser={createdIssuesByUser}
    />
  );
};
