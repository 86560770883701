import {
  ChangeRegistrationStatusOperations,
  RegistrationActionWithStatusRequestJSON,
} from '@mmw/services-core-manufacturer-registration/types';
import { SimpleAsyncState } from '@redux-async-module/reducer-utils';
import { U } from 'ts-toolbelt';

export class ChangeStatusState extends SimpleAsyncState<ChangeRegistrationStatusOperations> {
  request: U.Nullable<RegistrationActionWithStatusRequestJSON> = null;
}
