import useLanguage from '@mmw/redux-store-i18n/hooks/useLanguage';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { F } from 'ts-toolbelt';

import { requestUnblockStartAction } from '../actions';
import { RequestUnblockPayload } from '../types';

const useAuthenticationRequestUnblock = (): F.Function => {
  const dispatch = useDispatch();
  const language = useLanguage();
  return useCallback(
    (data: Partial<RequestUnblockPayload>) => {
      if (!data) return;
      const {
        username,
        recaptchaResponse,
        recaptchaType,
        scopeName,
        applicationId,
        applicationBaseUrl,
        applicationContextPath,
        redirectUrl,
      } = data;
      dispatch(
        requestUnblockStartAction(
          username as string,
          recaptchaResponse as string,
          language,
          recaptchaType,
          applicationId,
          redirectUrl,
          scopeName,
          applicationBaseUrl,
          applicationContextPath,
        ),
      );
    },
    [dispatch, language],
  );
};

export default useAuthenticationRequestUnblock;
