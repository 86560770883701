import { ADDRESS, PERSON } from '@mmw/constants-validation-field-schemas';
import { STRING_NULLABLE } from '@mmw/constants-validation-field-schemas/utils';
import { getFieldPaths } from '@mmw/utils-object-utils';
import { object, ObjectSchema } from 'yup';

export class BasePersonDataForm {
  domkeysalutation = '';

  firstname = '';

  lastname = '';

  birthdate = null;

  countryCallingCode = '';

  phone = '';

  street = '';

  nr = '';

  zipcode = '';

  city = '';

  country = '';
}

export const PERSON_FORM_DATA_FIELDS = getFieldPaths(new BasePersonDataForm());
export const PERSON_DATA_VALIDATION_SCHEMA = object<BasePersonDataForm>().shape(
  {
    [PERSON_FORM_DATA_FIELDS.domkeysalutation.$path]: PERSON.DOMKEYSALUTATION,
    [PERSON_FORM_DATA_FIELDS.firstname.$path]: PERSON.FIRSTNAME_REQUIRED,
    [PERSON_FORM_DATA_FIELDS.lastname.$path]: PERSON.LASTNAME_REQUIRED,
    [PERSON_FORM_DATA_FIELDS.birthdate.$path]: PERSON.BIRTHDATE_REQUIRED,
    [PERSON_FORM_DATA_FIELDS.phone.$path]: STRING_NULLABLE,
    [PERSON_FORM_DATA_FIELDS.phone.$path]: PERSON.PHONE,
    [PERSON_FORM_DATA_FIELDS.street.$path]: ADDRESS.STREET_REQUIRED,
    [PERSON_FORM_DATA_FIELDS.nr.$path]: ADDRESS.NR_REQUIRED,
    [PERSON_FORM_DATA_FIELDS.zipcode.$path]:
      ADDRESS.ZIPCODE_REQUIRED_BY_COUNTRY,
    [PERSON_FORM_DATA_FIELDS.city.$path]: ADDRESS.CITY_REQUIRED,
    [PERSON_FORM_DATA_FIELDS.country.$path]: ADDRESS.COUNTRY_REQUIRED,
  },
) as ObjectSchema<BasePersonDataForm>;

export const PERSON_DATA_OPTIONAL_VALIDATION_SCHEMA =
  object<BasePersonDataForm>().shape({
    [PERSON_FORM_DATA_FIELDS.domkeysalutation.$path]: STRING_NULLABLE,
    [PERSON_FORM_DATA_FIELDS.firstname.$path]: PERSON.FIRSTNAME,
    [PERSON_FORM_DATA_FIELDS.lastname.$path]: PERSON.LASTNAME,
    [PERSON_FORM_DATA_FIELDS.birthdate.$path]: PERSON.BIRTHDATE,
    [PERSON_FORM_DATA_FIELDS.phone.$path]: PERSON.PHONE,
    [PERSON_FORM_DATA_FIELDS.street.$path]: ADDRESS.STREET,
    [PERSON_FORM_DATA_FIELDS.nr.$path]: ADDRESS.NR,
    [PERSON_FORM_DATA_FIELDS.zipcode.$path]: ADDRESS.ZIPCODE,
    [PERSON_FORM_DATA_FIELDS.city.$path]: STRING_NULLABLE,
    [PERSON_FORM_DATA_FIELDS.country.$path]: ADDRESS.COUNTRY,
  }) as ObjectSchema<BasePersonDataForm>;
