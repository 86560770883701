import { PhotoFileType } from '@lib/camera/interfaces';
import { UploadableFileJSON } from '@mmw/services-core-common/types';
import { LoggedUserData } from '@mmw/services-core-logged-user-data-service/types';
import {
  AvailableStore,
  Salesman,
} from '@mmw/services-core-trader-orgunit/types';

import {
  CHANGE_SELECTED_STORE,
  ChangeSelectedStoreAction,
  GET_AVAILABLE_STORES_ERROR,
  GET_AVAILABLE_STORES_START,
  GET_AVAILABLE_STORES_SUCCESS,
  GET_LOGGED_TRADER_DATA_ERROR,
  GET_LOGGED_TRADER_DATA_START,
  GET_LOGGED_TRADER_DATA_SUCCESS,
  GET_ORGUNIT_GROUP_NAMES_ERROR,
  GET_ORGUNIT_GROUP_NAMES_START,
  GET_ORGUNIT_GROUP_NAMES_SUCCESS,
  GET_SALESMEN_ERROR,
  GET_SALESMEN_START,
  GET_SALESMEN_SUCCESS,
  GetAvailableStoresErrorAction,
  GetAvailableStoresStartAction,
  GetAvailableStoresSuccessAction,
  GetLoggedTraderDataErrorAction,
  GetLoggedTraderDataStartAction,
  GetLoggedTraderDataSuccessAction,
  GetOrgunitGroupNamesErrorAction,
  GetOrgunitGroupNamesStartAction,
  GetOrgunitGroupNamesSuccessAction,
  GetSalesmenErrorAction,
  GetSalesmenStartAction,
  GetSalesmenSuccessAction,
  RESET_LOGGED_TRADER_DATA,
  RESET_UPDATE_PICTURE_STATUS,
  ResetLoggedTraderDtataAction,
  ResetUpdatePictureStatus,
  UPDATE_LOGGED_USER_PROFILE_PICTURE_ERROR,
  UPDATE_LOGGED_USER_PROFILE_PICTURE_START,
  UPDATE_LOGGED_USER_PROFILE_PICTURE_SUCCESS,
  UpdateLoggedUserProfilePictureErrorAction,
  UpdateLoggedUserProfilePictureStartAction,
  UpdateLoggedUserProfilePictureSuccessAction,
} from './types';

export const getLoggedTraderDataStartAction =
  (): GetLoggedTraderDataStartAction => ({
    type: GET_LOGGED_TRADER_DATA_START,
  });

type GetLoggedTraderDataSuccess = (
  loggedTraderData: LoggedUserData,
) => GetLoggedTraderDataSuccessAction;

export const getLoggedTraderDataSuccessAction: GetLoggedTraderDataSuccess =
  loggedTraderData => ({
    type: GET_LOGGED_TRADER_DATA_SUCCESS,
    payload: {
      loggedTraderData,
    },
  });

export const getLoggedTraderDataErrorAction = (
  error: Error,
): GetLoggedTraderDataErrorAction => ({
  type: GET_LOGGED_TRADER_DATA_ERROR,

  payload: {
    error,
  },
});

export const getAvailableStoresStartAction =
  (): GetAvailableStoresStartAction => ({
    type: GET_AVAILABLE_STORES_START,
    payload: {},
  });

type GetAvailableStoresSuccess = (
  availableStores: Array<AvailableStore>,
) => GetAvailableStoresSuccessAction;

export const getAvailableStoresSuccessAction: GetAvailableStoresSuccess =
  availableStores => ({
    type: GET_AVAILABLE_STORES_SUCCESS,
    payload: {
      availableStores,
    },
  });

export const getAvailableStoresErrorAction = (
  error: Error,
): GetAvailableStoresErrorAction => ({
  type: GET_AVAILABLE_STORES_ERROR,

  payload: {
    error,
  },
});

export const changeSelectedStoreAction = (
  selectedStore: number,
): ChangeSelectedStoreAction => ({
  type: CHANGE_SELECTED_STORE,

  payload: {
    selectedStore,
  },
});

export const getSalesmenStartAction = (): GetSalesmenStartAction => ({
  type: GET_SALESMEN_START,
});

type GetSalesmenSuccess = (
  salesmen: Array<Salesman>,
) => GetSalesmenSuccessAction;

export const getSalesmenSuccessAction: GetSalesmenSuccess = salesmen => ({
  type: GET_SALESMEN_SUCCESS,
  payload: {
    salesmen,
  },
});

export const getSalesmenErrorAction = (
  error: Error,
): GetSalesmenErrorAction => ({
  type: GET_SALESMEN_ERROR,

  payload: {
    error,
  },
});

export const getOrgunitGroupNamesStartAction =
  (): GetOrgunitGroupNamesStartAction => ({
    type: GET_ORGUNIT_GROUP_NAMES_START,
  });

type GetOrgunitGroupNamesSuccess = (
  groupNames: Array<string>,
) => GetOrgunitGroupNamesSuccessAction;

export const getOrgunitGroupNamesSuccessAction: GetOrgunitGroupNamesSuccess =
  groupNames => ({
    type: GET_ORGUNIT_GROUP_NAMES_SUCCESS,
    payload: {
      groupNames,
    },
  });

export const getOrgunitGroupNamesErrorAction = (
  error: Error,
): GetOrgunitGroupNamesErrorAction => ({
  type: GET_ORGUNIT_GROUP_NAMES_ERROR,

  payload: {
    error,
  },
});

export const updateLoggedUserProfilePictureStartAction = (
  photo: PhotoFileType | UploadableFileJSON,
): UpdateLoggedUserProfilePictureStartAction => ({
  type: UPDATE_LOGGED_USER_PROFILE_PICTURE_START,
  payload: {
    photo,
  },
});

export const updateLoggedUserProfilePictureSuccessAction = (
  success: boolean,
): UpdateLoggedUserProfilePictureSuccessAction => ({
  type: UPDATE_LOGGED_USER_PROFILE_PICTURE_SUCCESS,
  payload: {
    success,
  },
});

export const updateLoggedUserProfilePictureErrorAction = (
  error: Error,
): UpdateLoggedUserProfilePictureErrorAction => ({
  type: UPDATE_LOGGED_USER_PROFILE_PICTURE_ERROR,

  payload: {
    error,
  },
});

export const resetUpdatePictureStatus = (): ResetUpdatePictureStatus => ({
  type: RESET_UPDATE_PICTURE_STATUS,
});

export const resetLoggedTraderDataAction =
  (): ResetLoggedTraderDtataAction => ({
    type: RESET_LOGGED_TRADER_DATA,
  });
