import { enableBatching } from 'redux-batched-actions';
import { IModule } from 'redux-dynamic-modules';

import createLoginByPassword, {
  EpicCreatorConfig,
  EpicType,
} from './epics-creator/getLoginByPassword';
import authenticationReducer from './reducer';
import { NAMESPACE as authenticationNamespace, RootState } from './types';

export const reducer = authenticationReducer;
export const NAMESPACE = authenticationNamespace;

export function getLoginByPasswordModule(
  props: EpicCreatorConfig,
): IModule<RootState> & { epics: EpicType[] } {
  return {
    id: NAMESPACE,
    reducerMap: {
      [NAMESPACE]: enableBatching(reducer),
    },
    epics: [createLoginByPassword(props)],
  };
}
