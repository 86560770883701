import { createContext } from 'react';
import { U } from 'ts-toolbelt';

export class ReCaptchaV3ContextValue {
  // eslint-disable-next-line class-methods-use-this
  getToken: (action: string) => Promise<U.Nullable<string>> = async () => null;

  // eslint-disable-next-line class-methods-use-this
  isGettingToken: (action: string) => boolean = () => false;
}

const INITIAL_VALUE = new ReCaptchaV3ContextValue();

export const ReCaptchaV3Context =
  createContext<ReCaptchaV3ContextValue>(INITIAL_VALUE);
