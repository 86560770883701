import { I18nShape } from '@mmw/constants-i18n';
import limitString from '@mmw/constants-string-utils';
import { use } from '@mmw/ui-theme/utils';
import { useTranslateShape } from '@mmw/utils-text-utils/hooks';
import { isEmpty, size, toString } from 'lodash';
import React, { ReactElement, useEffect } from 'react';
import styled from 'styled-components';
import { F } from 'ts-toolbelt';

const StyledSelect = styled.select(props => use(props, 'select'));

export type OptionsProps<
  Option extends Record<string, unknown>,
  P1 extends string,
  P2 extends string,
> = {
  options: Option[];
  optionsName?: F.AutoPath<Option, P1>;
  optionsValue?: F.AutoPath<Option, P2>;
  maxOptionLength?: number;
};

function Options<
  Option extends Record<string, unknown>,
  P1 extends string,
  P2 extends string,
>({
  options,
  optionsName,
  optionsValue,
  maxOptionLength,
}: OptionsProps<Option, P1, P2>): JSX.Element {
  return (
    <>
      {options.map(option => {
        if (option.id === 'EMPTY') {
          return (
            <option key="EMPTY" value="EMPTY">
              -
            </option>
          );
        }
        return (
          <option
            key={toString(option[optionsValue])}
            value={toString(option[optionsValue])}
          >
            {limitString(
              toString(option[optionsName]),
              maxOptionLength as number,
            )}
          </option>
        );
      })}
    </>
  );
}

type OnChangeProp = {
  onChange: F.Function<[React.ChangeEvent<HTMLSelectElement>]>;
};

export interface SelectProps<
  Option extends Record<string, unknown>,
  P1 extends string,
  P2 extends string,
> extends OptionsProps<Option, P1, P2>,
    OnChangeProp,
    Record<string, any> {
  value?: string;
  placeholder?: string | I18nShape;
  placeholderI18n?: I18nShape;
}

function Select<
  Option extends Record<string, unknown>,
  P1 extends string,
  P2 extends string,
>({
  value,
  options,
  optionsName,
  optionsValue,
  onChange,
  placeholder,
  placeholderI18n,
  maxOptionLength = 300,
  ...otherProps
}: SelectProps<Option, P1, P2>): ReactElement {
  const translatedPlaceholder = useTranslateShape(
    placeholderI18n || placeholder,
  );
  useEffect(() => {
    // auto select if only one option and no placeholder informed, otherwise user can never select
    if (translatedPlaceholder) {
      return;
    }
    if (size(options) !== 1) {
      return;
    }
    if (!isEmpty(value)) {
      return;
    }
    const firstOption = options[0];
    // @ts-ignore
    onChange({
      // @ts-ignore
      target: {
        // @ts-ignore
        value: firstOption[optionsValue],
      },
    });
  }, [
    placeholder,
    value,
    options,
    optionsValue,
    onChange,
    translatedPlaceholder,
  ]);
  // avoid console error, cant set default and value at same time
  const selectValue = value ? toString(value) : undefined;
  const defaultValue = value ? '' : undefined;
  return (
    <StyledSelect
      value={selectValue}
      defaultValue={defaultValue}
      onChange={onChange}
      {...otherProps}
    >
      {translatedPlaceholder ? (
        <option value="" selected>
          {limitString(translatedPlaceholder, maxOptionLength as number)}
        </option>
      ) : null}
      {Array.isArray(options) ? (
        <Options<Option, P1, P2>
          options={options}
          optionsName={optionsName}
          optionsValue={optionsValue}
          maxOptionLength={maxOptionLength}
        />
      ) : null}
    </StyledSelect>
  );
}

Select.defaultProps = {
  optionsName: 'label',
  optionsValue: 'value',
};

export default Select;
