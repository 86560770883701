import {
  CreateSelectorHookReturn,
  CreateSelectorHookWithIdReturn,
  Selector,
  SelectorWithId,
} from '@redux-basic-module/interfaces';
import { createSelector } from '@redux-basic-module/selector-utils';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { U } from 'ts-toolbelt';

export function createSelectorHookFromEntries<ItemType>(
  entries: string[],
): CreateSelectorHookReturn<ItemType> {
  return function useSelectorHook(): ItemType {
    const selector = createSelector<ItemType>(entries);
    return useSelector(selector);
  };
}

export function createSelectorHook<
  ItemType,
  RootState extends Record<string, unknown> = Record<string, any>,
>(selector: Selector<ItemType, RootState>): CreateSelectorHookReturn<ItemType> {
  return function useSelectorHook(): ItemType {
    return useSelector(selector);
  };
}

export function createSelectorHookWithId<
  IdType extends string | number | symbol,
  ItemType,
  RootState extends Record<string, unknown> = Record<string, any>,
>(
  selectorById: SelectorWithId<IdType, ItemType, RootState>,
): CreateSelectorHookWithIdReturn<IdType, ItemType> {
  return function useSelectorHook(
    id: U.Nullable<IdType>,
  ): U.Nullable<ItemType> {
    const selector = useCallback(
      (state: RootState) => (id ? selectorById(state, id) : null),
      [id],
    );
    return useSelector(selector);
  };
}
