import { getFieldPaths } from '@mmw/utils-object-utils';
import { SimpleAsyncState } from '@redux-async-module/reducer-utils';

import { SuccessPayload } from './actions';

export type State = SimpleAsyncState<SuccessPayload>;

export const FIELDPATHS = getFieldPaths(new SimpleAsyncState<SuccessPayload>());

export const NAMESPACE = 'traderCreateBranch';

export type RootState = {
  [NAMESPACE]: State;
};
