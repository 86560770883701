import navItemStyle from './navItemStyle';
import navLinkStyle from './navLinkStyle';
import navStyle from './navStyle';
import style from './style';

export default {
  style,
  nav: {
    style: navStyle,
  },
  navItem: {
    style: navItemStyle,
  },
  navLink: {
    style: navLinkStyle,
    text: {
      style: ({ theme }) => `
      color: ${theme.colors.pallet.dark};
      font-size: 0.9rem;
    `,
    },
  },
};
