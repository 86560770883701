import { REGISTRATION as VALIDATION } from '@mmw/constants-fields-validations';
import { DEFAULT, REGISTRATION as I18N } from '@mmw/constants-i18n-validations';
import { VALUES as REGISTRATION_TYPES } from '@mmw/constants-registration-types';
import {
  NOT_INFORMED as SALES_TYPE_NOT_INFORMED,
  VALUES as SALES_TYPES,
} from '@mmw/constants-sales-types';
import { date, number, string } from 'yup';

const PURCHASE_DATE = date().nullable();
const PURCHASE_DATE_REQUIRED = PURCHASE_DATE.default(new Date()).required(
  DEFAULT.REQUIRED.key,
);

const REGISTRATION_TYPE = number()
  .nullable()
  // @ts-ignore
  .oneOf(REGISTRATION_TYPES, I18N.REGISTRATION_TYPE.INVALID.key);

const REGISTRATION_TYPE_REQUIRED = REGISTRATION_TYPE.required(
  DEFAULT.REQUIRED.key,
);

const STORE_ID = number().nullable();
const STORE_ID_REQUIRED = STORE_ID.required(DEFAULT.REQUIRED.key)
  // XXX: since some places it might set -1, or 0, only positive number
  .positive(I18N.STORE_ID.REQUIRED.key);

const SALES_TYPE = number()
  .oneOf(SALES_TYPES, I18N.SALES_TYPE.INVALID.key)
  .default(SALES_TYPE_NOT_INFORMED);

const SALES_TYPE_REQUIRED = SALES_TYPE
  // xxx the SALES_TYPE_NOT_INFORMED is 0, in order to make the
  // field required, it must have a positive value.
  .positive(DEFAULT.REQUIRED.key)
  .required(DEFAULT.REQUIRED.key);

const INVOICE_NUMBER = string()
  .nullable()
  .max(VALIDATION.INVOICE_NUMBER.MAX_LENGTH);

const INVOICE_NUMBER_REQUIRED = INVOICE_NUMBER.required(DEFAULT.REQUIRED.key);

const EXTERNAL_ORDERNUMBER = string()
  .nullable()
  .max(VALIDATION.EXTERNAL_ORDERNUMBER.MAX_LENGTH);

const EXTERNAL_ORDERNUMBER_REQUIRED = EXTERNAL_ORDERNUMBER.required(
  DEFAULT.REQUIRED.key,
);

const SALESPERSON = number().nullable();
const SALESPERSON_REQUIRED = SALESPERSON.required(DEFAULT.REQUIRED.key);

export const REGISTRATION = {
  PURCHASE_DATE,
  PURCHASE_DATE_REQUIRED,
  REGISTRATION_TYPE,
  REGISTRATION_TYPE_REQUIRED,
  STORE_ID,
  STORE_ID_REQUIRED,
  SALES_TYPE,
  SALES_TYPE_REQUIRED,
  INVOICE_NUMBER,
  INVOICE_NUMBER_REQUIRED,
  EXTERNAL_ORDERNUMBER,
  EXTERNAL_ORDERNUMBER_REQUIRED,
  SALESPERSON,
  SALESPERSON_REQUIRED,
};

export default REGISTRATION;
