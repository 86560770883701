import { enableBatching } from 'redux-batched-actions';
import { IModule } from 'redux-dynamic-modules';

import epics, { Epics } from './epics';
import getLoggedTraderDataReducer from './reducer';
import { NAMESPACE, RootState } from './types';

export * from './epics';
export { default as epics } from './epics';
export { NAMESPACE } from './types';

export const reducer = getLoggedTraderDataReducer;

export function getLoggedTraderDataModule(
  customEpics?: Partial<Epics>,
): IModule<RootState> & {
  epics: Epics;
} {
  return {
    id: NAMESPACE,
    reducerMap: {
      [NAMESPACE]: enableBatching(reducer),
    },
    epics: customEpics || epics,
  };
}
