import { COMPANY as VALIDATION } from '@mmw/constants-fields-validations';
import {
  COMPANY as I18N_COMPANY,
  DEFAULT as I18N,
} from '@mmw/constants-i18n-validations';
import { getVatTest } from '@mmw/yup-validation-vat';
import { string } from 'yup';

const FAX = string().nullable().max(VALIDATION.FAX.MAX_LENGTH);

const PHONE = string().nullable().max(VALIDATION.PHONE.MAX_LENGTH);

const SALESTAXID = string().nullable().max(VALIDATION.SALESTAXID.MAX_LENGTH);
const SALESTAXID_REQUIRED = SALESTAXID.required(I18N.REQUIRED.key);
const SALESTAXID_REQUIRED_VAT = SALESTAXID_REQUIRED.test(
  getVatTest(I18N_COMPANY.TAXNUMBER.VAT.key),
);

const TAXNUMBER = string().nullable().max(VALIDATION.TAXNUMBER.MAX_LENGTH);

const TAXNUMBER_REQUIRED = TAXNUMBER.required(I18N.REQUIRED.key);
const TAXNUMBER_REQUIRED_VAT = TAXNUMBER_REQUIRED.test(
  getVatTest(I18N_COMPANY.TAXNUMBER.VAT.key),
);

const HOMEPAGE = string().nullable().max(VALIDATION.HOMEPAGE.MAX_LENGTH);

const CRNUMBER = string().nullable().max(VALIDATION.CRNUMBER.MAX_LENGTH);

const CRCITY = string().nullable().max(VALIDATION.CRCITY.MAX_LENGTH);

const EMAIL = string().nullable().max(VALIDATION.EMAIL.MAX_LENGTH);

const COMPANY = string().nullable().max(VALIDATION.COMPANY.MAX_LENGTH);

const COMPANY_REQUIRED = COMPANY.required(I18N.REQUIRED.key);

export const COMPANY_OBJ = {
  FAX,
  PHONE,
  SALESTAXID,
  TAXNUMBER,
  HOMEPAGE,
  CRNUMBER,
  CRCITY,
  EMAIL,
  COMPANY,
  COMPANY_REQUIRED,
  TAXNUMBER_REQUIRED,
  SALESTAXID_REQUIRED,
  TAXNUMBER_REQUIRED_VAT,
  SALESTAXID_REQUIRED_VAT,
};

export default COMPANY_OBJ;
