import { useError } from '@mmw/redux-store-auth-api-login-by-password/hooks';
import {
  BAD_CREDENTIALS as BC,
  CAPTCHA_BLOCKED as CB,
  EMAIL_BLOCKED as EB,
  INVALID_APPLICATION as IA,
  MISSING_AUTHORITY as MA,
  MISSING_SALES_ORG_BRAND_RELATION as MSOBR,
  UNKNOWN_ERROR as UN,
  USER_NOT_FOUND_ERROR as UNF,
} from '@mmw/redux-store-payload-error/constants';
import get from 'lodash/get';
import merge from 'lodash/merge';
import React, { useEffect, useMemo, useState } from 'react';

import AuthErrorCard from './AuthErrorCard';
import { RequestUnblockAccount } from './RequestUnblockAccount';

export enum PossibleErrors {
  BAD_CREDENTIALS = 'BAD_CREDENTIALS',
  CAPTCHA_BLOCKED = 'CAPTCHA_BLOCKED',
  EMAIL_BLOCKED = 'EMAIL_BLOCKED',
  MISSING_AUTHORITY = 'MISSING_AUTHORITY',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  NONE = 'NONE',
  INVALID_APPLICATION = 'INVALID_APPLICATION',
  MISSING_SALES_ORG_BRAND_RELATION = 'MISSING_SALES_ORG_BRAND_RELATION',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
}

interface Config {
  [PossibleErrors.BAD_CREDENTIALS]: React.FC;
  [PossibleErrors.NONE]: React.FC;
  [PossibleErrors.EMAIL_BLOCKED]: React.FC;
  [PossibleErrors.MISSING_AUTHORITY]: React.FC;
  [PossibleErrors.CAPTCHA_BLOCKED]: React.FC;
  [PossibleErrors.UNKNOWN_ERROR]: React.FC;
  [PossibleErrors.INVALID_APPLICATION]: React.FC;
  [PossibleErrors.MISSING_SALES_ORG_BRAND_RELATION]?: React.FC;
}
interface AuthErrorHandlerProps extends Record<string, any> {
  config?: Partial<Config>;
}

const DEFAULT_CONFIG = {
  [PossibleErrors.NONE]: () => null,
  [PossibleErrors.BAD_CREDENTIALS]: AuthErrorCard,
  [PossibleErrors.USER_NOT_FOUND]: AuthErrorCard,
  [PossibleErrors.MISSING_AUTHORITY]: AuthErrorCard,
  [PossibleErrors.CAPTCHA_BLOCKED]: AuthErrorCard,
  [PossibleErrors.UNKNOWN_ERROR]: AuthErrorCard,
  [PossibleErrors.INVALID_APPLICATION]: AuthErrorCard,
  [PossibleErrors.MISSING_SALES_ORG_BRAND_RELATION]: AuthErrorCard,
  [PossibleErrors.EMAIL_BLOCKED]: ({ ...props }) => (
    <RequestUnblockAccount {...props} />
  ),
};

export const AuthErrorHandler: React.FC<AuthErrorHandlerProps> = ({
  config,
  ...props
}: AuthErrorHandlerProps) => {
  const error = useError();
  const [trigger, setTrigger] = useState<PossibleErrors>(PossibleErrors.NONE);
  const mergedConfig = useMemo(() => merge(DEFAULT_CONFIG, config), [config]);

  useEffect(() => {
    switch (true) {
      case error instanceof BC.ERROR:
        setTrigger(PossibleErrors.BAD_CREDENTIALS);
        break;
      case error instanceof CB.ERROR:
        setTrigger(PossibleErrors.CAPTCHA_BLOCKED);
        break;
      case error instanceof UNF.ERROR:
        setTrigger(PossibleErrors.USER_NOT_FOUND);
        break;
      case error instanceof EB.ERROR:
        setTrigger(PossibleErrors.EMAIL_BLOCKED);
        break;
      case error instanceof MA.ERROR:
        setTrigger(PossibleErrors.MISSING_AUTHORITY);
        break;
      case error instanceof UN.ERROR:
        setTrigger(PossibleErrors.UNKNOWN_ERROR);
        break;
      case error instanceof IA.ERROR:
        setTrigger(PossibleErrors.INVALID_APPLICATION);
        break;
      case error instanceof MSOBR.ERROR:
        setTrigger(PossibleErrors.MISSING_SALES_ORG_BRAND_RELATION);
        break;
      default:
        setTrigger(PossibleErrors.NONE);
    }
  }, [error]);
  const Component = get(mergedConfig, trigger);
  return <Component {...props} />;
};

AuthErrorHandler.defaultProps = {
  config: DEFAULT_CONFIG,
};
