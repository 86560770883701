import {
  useIsPhoneAlreadyInUse,
  useIsPhoneValid,
  useVerificationCodeRequestStatus,
} from '@authentication/sms-phone-verification-store';
import { AUTH_TAN_REQUEST } from '@mmw/constants-i18n-authentication';
import { CONFIRM } from '@mmw/constants-i18n-buttons';
import { PERSON as PERSON_I18N } from '@mmw/constants-i18n-fields';
import { useIsGettingToken } from '@recaptcha/v3';
import UI from '@ui-system/ui';
import React, { useEffect } from 'react';
import { F, U } from 'ts-toolbelt';

import TanCodeValidationField from '../TanCodeValidationField';
import RegisterSmsPhoneContent, {
  MobilePhoneRegistrationForm,
} from './RegisterSmsPhoneContent';
import useAnonymizedPhone from './useAnonymizedPhone';

const { TITLE: LABEL_I18N } = AUTH_TAN_REQUEST;

const SentToPhoneLabel: React.FC = () => {
  const phone = useAnonymizedPhone();

  if (!phone) return null;

  return (
    <UI.Container align="center" gap={1}>
      <UI.Typography i18n={PERSON_I18N.PHONE.LABEL} suffix=":" />
      <UI.Typography modifiers="bold">{phone}</UI.Typography>
    </UI.Container>
  );
};

interface Props {
  onSendVerificationCodeSuccess: F.Function;
  onRequestNewValidationCode: F.Function;
  onCancel?: F.Function;
  operationInProcess?: boolean;
  phoneRegistrationInitialValues?: U.Nullable<MobilePhoneRegistrationForm>;
  shouldShowNewPhoneForm?: boolean;
}

const ValidatePhoneByTAN: React.FC<Props> = ({
  onSendVerificationCodeSuccess,
  onRequestNewValidationCode,
  onCancel,
  operationInProcess,
  phoneRegistrationInitialValues,
  shouldShowNewPhoneForm,
}: Props) => {
  const isGettingToken = useIsGettingToken('phone-verification-request');
  const isPhoneValid = useIsPhoneValid();
  const isPhoneAlreadyInUse = useIsPhoneAlreadyInUse();
  const smsSendStatus = useVerificationCodeRequestStatus();

  useEffect(() => {
    if (isPhoneValid && !smsSendStatus && !isPhoneAlreadyInUse) {
      if (onRequestNewValidationCode) {
        onRequestNewValidationCode();
      }
    }
  }, [isPhoneValid]);

  return (
    <>
      {shouldShowNewPhoneForm ? (
        <RegisterSmsPhoneContent
          initialValues={phoneRegistrationInitialValues}
          onCancel={onCancel}
        />
      ) : (
        <UI.Container direction="column" align="center" gap={2}>
          <SentToPhoneLabel />
          <TanCodeValidationField
            fieldLabel={LABEL_I18N}
            buttonI18n={CONFIRM}
            onSuccess={onSendVerificationCodeSuccess}
            onResendRequest={onRequestNewValidationCode}
            onCancel={onCancel}
            operationInProcess={operationInProcess || isGettingToken}
          />
        </UI.Container>
      )}
    </>
  );
};

export default ValidatePhoneByTAN;
