import { EMPTY_ARRAY } from '@mmw/constants-utils';
import {
  Selector,
  SelectorWithId,
  SelectorWithIds,
  SelectorWithIdWithDefault,
} from '@redux-basic-module/interfaces';
import get from 'lodash/get';
import join from 'lodash/join';
import { U } from 'ts-toolbelt';

export function createSelector<ItemType>(
  entries: string[],
): Selector<ItemType> {
  return function selector(state: Record<string, any>): ItemType {
    return get(state, join(entries, '.'));
  };
}

export function createSelectorById<
  IdType extends string | number | symbol,
  ItemType,
>(
  entries: string[],
  postIdEntries: string[] = EMPTY_ARRAY,
): SelectorWithId<IdType, ItemType> {
  return function selector(
    state: Record<string, any>,
    id: IdType,
  ): U.Nullable<ItemType> {
    return get(state, join([...entries, id, ...postIdEntries], '.'));
  };
}

export function createSelectorByIdWithDefault<
  IdType extends string | number | symbol,
  ItemType,
>(
  entries: string[],
  postIdEntries: string[] = EMPTY_ARRAY,
  defaultValue: ItemType,
): SelectorWithIdWithDefault<IdType, ItemType> {
  return function selector(state: Record<string, any>, id: IdType): ItemType {
    return (
      get(state, join([...entries, id, ...postIdEntries], '.')) || defaultValue
    );
  };
}

export function createSelectorByIds<
  Id1Type extends string | number | symbol,
  Id2Type extends string | number | symbol,
  ItemType,
>(
  entries: string[],
  postId1Entries: string[] = EMPTY_ARRAY,
  postId2Entries: string[] = EMPTY_ARRAY,
): SelectorWithIds<Id1Type, Id2Type, ItemType> {
  return function selector(
    state: Record<string, any>,
    id1: Id1Type,
    id2: Id2Type,
  ): U.Nullable<ItemType> {
    return get(
      state,
      join([...entries, id1, ...postId1Entries, id2, ...postId2Entries], '.'),
    );
  };
}
