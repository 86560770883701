import {
  GET_API_STATUS_ERROR,
  GET_API_STATUS_START,
  GET_API_STATUS_SUCCESS,
  GetApiStatusErrorAction,
  GetApiStatusStartAction,
  GetApiStatusSuccessAction,
} from './types';

export const getApiStatusStartAction = (): GetApiStatusStartAction => ({
  type: GET_API_STATUS_START,
});

export const getApiStatusSuccessAction = (): GetApiStatusSuccessAction => ({
  type: GET_API_STATUS_SUCCESS,
});

export const getApiStatusErrorAction = (
  error: Error,
): GetApiStatusErrorAction => ({
  type: GET_API_STATUS_ERROR,
  payload: {
    error,
  },
});
