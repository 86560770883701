import { ThemeColors } from '../../color/types';
import { StyledProps } from '../../types';
import { mount } from '../utils';

const backgroundModifiers = {
  primary: ({ theme }: StyledProps<{}>) => `
    background-color: ${theme.colors.pallet.primary};
  `,
  secondary: ({ theme }: StyledProps<{}>) => `
    background-color: ${theme.colors.pallet.secondary};
  `,
  basic: ({ theme }: StyledProps<{}>) => `
    background-color: ${theme.colors.pallet.basic};
  `,
  basicLight: ({ theme }: StyledProps<{}>) => `
    background-color: ${theme.colors.pallet.basicLight};
  `,
  basicDark: ({ theme }: StyledProps<{}>) => `
    background-color: ${theme.colors.pallet.basicDark};
  `,
  dark: ({ theme }: StyledProps<{}>) => `
    background-color: ${theme.colors.pallet.dark};
  `,
  light: ({ theme }: StyledProps<{}>) => `
    background-color: ${theme.colors.pallet.light};
  `,
  alert: ({ theme }: StyledProps<{}>) => `
    background-color: ${theme.colors.pallet.alert};
  `,
  error: ({ theme }: StyledProps<{}>) => `
    background-color: ${theme.colors.pallet.error};
  `,
  background: ({ theme }: StyledProps<{}>) => `
    background-color: ${theme.colors.pallet.background};
  `,
};

export default (colors?: ThemeColors) =>
  colors ? mount(colors) : backgroundModifiers;
