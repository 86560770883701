import { U } from '@utils/ts';
import React from 'react';

export function createMock<
  Props extends Record<string, any>,
  Type extends React.FC<Props> = React.FC<Props>,
>(Component: Type, props: U.Nullable<Props> = null): React.FC {
  // @ts-ignore
  return () => <Component {...props} />;
}
