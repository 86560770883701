import {
  OperationResult,
  OperationsEnum,
} from '@mmw/services-auth-api-authentication/types';
import { F, U } from 'ts-toolbelt';

import {
  REQUEST_EMAIL_VERIFICATION_ERROR,
  REQUEST_EMAIL_VERIFICATION_START,
  REQUEST_EMAIL_VERIFICATION_SUCCESS,
  RequestEmailVerificationErrorAction,
  RequestEmailVerificationStartAction,
  RequestEmailVerificationSuccessAction,
  RESET,
  ResetAction,
  VERIFY_EMAIL_ERROR,
  VERIFY_EMAIL_START,
  VERIFY_EMAIL_SUCCESS,
  VerifyEmailErrorAction,
  VerifyEmailResponse,
  VerifyEmailStartAction,
  VerifyEmailSuccessAction,
} from './types';

export const requestEmailVerificationStartAction = (
  email: string,
  operationId: OperationsEnum,
  recaptchaToken?: string,
): RequestEmailVerificationStartAction => ({
  type: REQUEST_EMAIL_VERIFICATION_START,

  payload: {
    email,
    operationId,
    recaptchaToken,
  },
});

export const requestEmailVerificationSuccessAction = (
  result: OperationResult,
): RequestEmailVerificationSuccessAction => ({
  type: REQUEST_EMAIL_VERIFICATION_SUCCESS,
  payload: {
    success: result.success,
    error: result.errorCode,
  },
});

export const requestEmailVerificationErrorAction = (
  error: Error,
): RequestEmailVerificationErrorAction => ({
  type: REQUEST_EMAIL_VERIFICATION_ERROR,

  payload: {
    error,
  },
});

export const verifyEmailStartAction = (
  email: string,
  tan: string,
  operationId: OperationsEnum,
  callback: F.Function<[VerifyEmailResponse]>,
  recaptchaToken?: U.Nullable<string>,
): VerifyEmailStartAction => ({
  type: VERIFY_EMAIL_START,

  payload: {
    email,
    tan,
    operationId,
    callback,
    recaptchaToken,
  },
});

export const verifyEmailSuccessAction = (
  data: VerifyEmailResponse,
): VerifyEmailSuccessAction => ({
  type: VERIFY_EMAIL_SUCCESS,
  payload: data,
});

export const verifyEmailErrorAction = (
  error: Error,
): VerifyEmailErrorAction => ({
  type: VERIFY_EMAIL_ERROR,

  payload: {
    error,
  },
});

export const resetAction = (): ResetAction => ({
  type: RESET,
});
