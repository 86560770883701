import 'moment/locale/ja';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';

import { I18nShape } from '@mmw/constants-i18n';
import { DATEPICKER_TODAY } from '@mmw/constants-i18n-common';
import { useMemo } from '@mmw/redux-store-i18n/hooks';
import { FormatDateType } from '@mmw/ui-hoc-with-format-date';
import withTranslatedPlaceholder from '@mmw/ui-hoc-with-translated-placeholder';
import { EMPTY_OBJECT, use } from '@mmw/ui-theme/utils';
import { Body2 } from '@mmw/ui-web-elements-typography';
import InputGroup from '@mmw/ui-web-materials-input-group';
import CALENDAR from '@mmw/web-assets/calendar.svg';
import React, { MouseEvent, useRef } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';
import {
  DayPickerInputProps,
  DayPickerProps,
  // eslint-disable-next-line import/no-unresolved
} from 'react-day-picker/types/Props';
import styled from 'styled-components';

import CustomNavbar from './elements/CustomNavbar';
import Wrapper from './elements/Wrapper';

const StyledDateInput = styled(InputGroup.Input)(props =>
  use(props, 'dateInput'),
);

const DayPickerSpan = styled(InputGroup.Span)`
  cursor: pointer;
`;

export type DisabledDaysConfig = {
  before?: Date;
  after?: Date;
};

export type DateInputProps = {
  style?: Record<string, any>;
  locale?: string;
  formatDate?: FormatDateType;
  inputProps?: Record<string, any>;
  onChange: (value: Date | void) => any;
  value: Date | number | string;
  placeholder?: string | I18nShape;
  spanIcon?: SVGMatrix | string;
  spanOnRight: boolean;
  useTodayButton?: boolean;
  showOutsideDays?: boolean;
  customNav?: boolean;
  disabledDays?: DisabledDaysConfig | DisabledDaysConfig[];
  dateFormat?: {
    year: string;
    month: string;
    day: string;
  };
  modifiers?: string | string[];
} & Partial<DayPickerInputProps>;

const setDatePanelPosition = (onRight: boolean): string =>
  onRight ? 'right: 0;' : 'left: 0;';

const DateInput: React.FC<DateInputProps> = ({
  style,
  locale,
  onChange,
  placeholder,
  formatDate,
  dateFormat,
  value,
  spanIcon,
  spanOnRight = false,
  useTodayButton,
  showOutsideDays,
  customNav,
  disabledDays,
  inputProps,
  modifiers,
  ...rest
}: Partial<DateInputProps>) => {
  const datePickerRef = useRef(null);
  // @ts-ignore
  const dayPickerProps: Partial<DayPickerProps> = useMemo(
    () => ({
      locale,
      localeUtils: MomentLocaleUtils,
      selectedDays: value,
      navbarElement: customNav ? <CustomNavbar /> : undefined,
      todayButton: useTodayButton ? (
        <Body2 className="DayPicker-TodayButton" i18n={DATEPICKER_TODAY} />
      ) : undefined,
      showOutsideDays,
      disabledDays,
    }),
    [locale, value, useTodayButton, showOutsideDays, customNav, disabledDays],
  );
  const formattedValue = useMemo(
    () => (value && formatDate ? formatDate(value, dateFormat) : value),
    [dateFormat, formatDate, value],
  );
  const cachedInputProps = useMemo(
    () => ({
      ...rest,
      ...(inputProps || EMPTY_OBJECT),
    }),
    [inputProps, rest],
  );

  return (
    <InputGroup style={style}>
      {!spanOnRight && (
        <InputGroup.Span
          icon={spanIcon}
          onClick={(e: MouseEvent) =>
            datePickerRef && datePickerRef?.current?.handleInputFocus(e)
          }
        />
      )}
      <Wrapper datePanelPosition={setDatePanelPosition(spanOnRight)}>
        <DayPickerInput
          component={(props: Record<string, any>) => (
            <StyledDateInput
              {...props}
              value={formattedValue}
              modifiers={modifiers}
            />
          )}
          inputProps={cachedInputProps}
          value={formattedValue}
          dayPickerProps={dayPickerProps}
          placeholder={placeholder}
          onDayChange={onChange}
          ref={datePickerRef}
        />
      </Wrapper>
      {spanOnRight && (
        <DayPickerSpan
          icon={spanIcon}
          onClick={(e: MouseEvent) =>
            datePickerRef && datePickerRef?.current?.handleInputFocus(e)
          }
        />
      )}
    </InputGroup>
  );
};

DateInput.defaultProps = {
  locale: 'en',
  spanIcon: CALENDAR,
  spanOnRight: false,
  useTodayButton: false,
  showOutsideDays: false,
  customNav: false,
  dateFormat: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  },
};
export const MAX_TODAY_DISABLED_DAYS = [
  {
    after: new Date(),
  },
];
export default withTranslatedPlaceholder(DateInput);
