import {
  usePreviousValidPhone,
  useUserVerifiedPhone,
} from '@authentication/sms-phone-verification-store';
import { anonymizeString } from '@lib/utils-string';
import { parsePhoneNumber } from 'awesome-phonenumber';
import drop from 'lodash/drop';
import get from 'lodash/get';
import trim from 'lodash/trim';
import { useMemo } from 'react';

function useAnonymizedPhone() {
  const userVerifiedPhone = useUserVerifiedPhone();
  const previousValidPhone = usePreviousValidPhone();
  return useMemo(() => {
    if (!userVerifiedPhone?.phone && !previousValidPhone?.phone) return null;
    const currentPhone = get(userVerifiedPhone, 'phone');
    const parsedPhone = parsePhoneNumber(currentPhone as string, {
      regionCode: get(userVerifiedPhone, 'country') as string,
    });
    const splittedNumArr = parsedPhone?.number?.international.split(' ');
    if (splittedNumArr && splittedNumArr?.length < 3) {
      const anonymizedPhone = anonymizeString(
        (splittedNumArr[1] || currentPhone) as string,
        4,
      );

      return trim(`${splittedNumArr?.[0] || '***'} ${anonymizedPhone}`);
    }

    const phonePartToHide = drop(splittedNumArr, 2).join(' ');
    const anonymizedPhone = anonymizeString(
      (phonePartToHide || currentPhone) as string,
      4,
    );
    return trim(
      `${splittedNumArr?.[0] || ''} ${
        splittedNumArr?.[1] ? `${splittedNumArr?.[1]}` : ''
      } ${anonymizedPhone}`,
    );
  }, [previousValidPhone?.phone, userVerifiedPhone]);
}

export default useAnonymizedPhone;
