import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import { getCommonService } from '@mmw/services-holder';
import { ActionsObservable } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { getApiStatusErrorAction, getApiStatusSuccessAction } from '../actions';
import { GET_API_STATUS_START, GetApiStatusStartAction } from '../types';

const { logger } = contextualConfig.application;

const getApiConnectionStatusEpic = (
  action$: ActionsObservable<GetApiStatusStartAction>,
) =>
  action$.pipe(
    ofType(GET_API_STATUS_START),
    tap(() => logger.debug('Trying to connect to api')),
    switchMap(() => {
      let result;
      let count = 0;
      function delay(time: number) {
        return new Promise(resolve => setTimeout(resolve, time));
      }
      function checkData(data: boolean) {
        if (!data) throw new Error();
      }
      const apiConnectionAttempt = async () => {
        result = await getCommonService().getApiConnectionStatus();
        try {
          if (!result && count <= 2) {
            count += 1;
            return checkData(result);
          }
        } catch (e) {
          if (count > 2) {
            logger.debug('Failed to communicate with api server');
            throw new Error('Failed to communicate with api server');
          }
          logger.debug('Retrying to connect to api server...');
          await delay(3000);
          await apiConnectionAttempt();
        }
        return result;
      };
      return from(apiConnectionAttempt()).pipe(
        map(() => getApiStatusSuccessAction()),
        catchError(error => concat(of(getApiStatusErrorAction(error)))),
      );
    }),
  );

export default getApiConnectionStatusEpic;
