import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Image = styled.img`
  width: 170px;
  margin: 2px 5px;
  height: 45px;
  background: url(${({ src }) => src});

  ${({ theme }) => theme.media.phone`
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  `}
`;

const Icon = styled(FontAwesomeIcon)`
  align-self: center;
  margin-left: auto;
  margin-right: 25px;
  display: none;
  ${({ theme }) => theme.media.phone`
    display: unset;
  `}
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1;
  z-index: 1020;
`;

const ImageDropdown = ({ onClick, src, icon }) => (
  <Container>
    <Image src={src} />
    <Icon icon={['fas', icon]} size="2x" onClick={onClick} />
  </Container>
);

ImageDropdown.propTypes = {
  onClick: PropTypes.func,
  src: PropTypes.string,
  icon: PropTypes.string,
};

ImageDropdown.defaultProps = {
  onClick: noop,
  src: '',
  icon: null,
};

export default ImageDropdown;
