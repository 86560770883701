import { PRODUCT as VALIDATION } from '@mmw/constants-fields-validations';
import { DEFAULT as I18N } from '@mmw/constants-i18n-validations';
import { string } from 'yup';

const NAME = string().max(VALIDATION.NAME.MAX_LENGTH);

const SERIALNUMBER = string().max(VALIDATION.SERIALNUMBER.MAX_LENGTH);

const EAN = string().max(VALIDATION.EAN.MAX_LENGTH);

export const PRODUCT = {
  NAME,
  NAME_REQUIRED: NAME.required(I18N.REQUIRED.key),
  SERIALNUMBER,
  SERIALNUMBER_REQUIRED: SERIALNUMBER.required(I18N.REQUIRED.key),
  EAN,
  EAN_REQUIRED: EAN.required(I18N.REQUIRED.key),
};

export default PRODUCT;
