import modifiers from './modifiers';
import style, { a, pre } from './style';

export default {
  style,
  pre: {
    style: pre,
  },
  a: {
    style: a,
  },
  modifiers,
};
