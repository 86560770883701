import { useNavigate } from '@mmw/ui-web-libraries-react-router/hooks';
import { SIGN_UP } from '@retail/constants-routes';
import { useCallback } from 'react';

function useNavigateToSignUp() {
  const navigate = useNavigate();
  return useCallback(() => navigate(SIGN_UP), [navigate]);
}

export default useNavigateToSignUp;
