import '@mmw/yup-validation-zipcode';

import { COUNTRIES_WITHOUT_ZIPCODE } from '@mmw/constants-country-codes';
import { ADDRESS as VALIDATION } from '@mmw/constants-fields-validations';
import { DEFAULT as I18N } from '@mmw/constants-i18n-validations';
import {
  CHARACTER_REPETITION,
  getMinLenghtOrEmpty,
  HAS_MORE_THAN_ONE_NOT_REPEATED_CHAR,
  NUMBERS,
  ONLY_ZEROS,
  REQUIRED_FOR_US,
  SPECIAL_ALONE_CHARACTERS,
  SPECIAL_CHARACTERS_AND_WORDS,
  SPECIAL_CHARACTERS_AND_WORDS_FOR_ADDRESS_CITY,
  SPECIAL_CHARACTERS_AND_WORDS_FOR_ADDRESS_NR,
  SPECIAL_CHARACTERS_AND_WORDS_FOR_ADDRESS_STREET,
} from '@mmw/constants-validation-yup-tests';
import includes from 'lodash/includes';
import { string } from 'yup';

const COMPANY = string().nullable().max(VALIDATION.COMPANY.MAX_LENGTH);

const COMPANY_REQUIRED = COMPANY.required(I18N.REQUIRED.key);

const STREET = string()
  .nullable()
  .max(VALIDATION.STREET.MAX_LENGTH)
  .test(SPECIAL_ALONE_CHARACTERS)
  .test(SPECIAL_CHARACTERS_AND_WORDS_FOR_ADDRESS_STREET)
  .test(HAS_MORE_THAN_ONE_NOT_REPEATED_CHAR);

const STREET_REQUIRED = STREET.required(I18N.REQUIRED.key);

const ADDRESS_LINE_1 = string()
  .nullable()
  .max(VALIDATION.STREET.MAX_LENGTH)
  .test(SPECIAL_ALONE_CHARACTERS)
  .test(SPECIAL_CHARACTERS_AND_WORDS_FOR_ADDRESS_STREET)
  .test(HAS_MORE_THAN_ONE_NOT_REPEATED_CHAR);

const ADDRESS_LINE_1_REQUIRED = ADDRESS_LINE_1.required(I18N.REQUIRED.key);

const ADDRESS_LINE_2 = string()
  .nullable()
  .max(VALIDATION.STREET.MAX_LENGTH)
  .test(SPECIAL_ALONE_CHARACTERS)
  .test(SPECIAL_CHARACTERS_AND_WORDS_FOR_ADDRESS_STREET)
  .test(HAS_MORE_THAN_ONE_NOT_REPEATED_CHAR);

const NR = string()
  .test(ONLY_ZEROS)
  .test(SPECIAL_ALONE_CHARACTERS)
  .test(SPECIAL_CHARACTERS_AND_WORDS_FOR_ADDRESS_NR)
  .nullable()
  .max(VALIDATION.NR.MAX_LENGTH);

const NR_REQUIRED = NR.required(I18N.REQUIRED.key);

const COMPLEMENT = string()
  .nullable()
  .max(VALIDATION.COMPLEMENT.MAX_LENGTH)
  .test(ONLY_ZEROS)
  .test(SPECIAL_ALONE_CHARACTERS)
  .test(SPECIAL_CHARACTERS_AND_WORDS);

const COMPLEMENT_REQUIRED = COMPLEMENT.required(I18N.REQUIRED.key);

const ZIPCODE = string()
  .nullable()
  .max(VALIDATION.ZIPCODE.MAX_LENGTH)
  .zipcode() // XXX: comes from @mmw/yup-validation-zipcode
  .test(ONLY_ZEROS)
  .test(SPECIAL_CHARACTERS_AND_WORDS)
  .default('');

const ZIPCODE_REQUIRED = ZIPCODE.nullable().required(I18N.REQUIRED.key);

// ISSUE https://lagoasoft.atlassian.net/browse/MRCR-150
// ZIPCODE_REQUIRED_BY_COUNTRY avoids rendering required character somehow
export function getZipcodeRequiredByCountry(path: string) {
  return string().when(path, {
    is: country => !includes(COUNTRIES_WITHOUT_ZIPCODE, country),
    then: () => ZIPCODE.nullable().required(I18N.REQUIRED.key),
    otherwise: () =>
      string()
        .nullable()
        .max(VALIDATION.ZIPCODE.MAX_LENGTH)
        .zipcode() // XXX: comes from @mmw/yup-validation-zipcode
        .test(ONLY_ZEROS)
        .test(SPECIAL_CHARACTERS_AND_WORDS),
  });
}

const ZIPCODE_REQUIRED_BY_COUNTRY = getZipcodeRequiredByCountry('country');

const CITY = string()
  .nullable()
  .max(VALIDATION.CITY.MAX_LENGTH)
  .test(NUMBERS)
  .test(CHARACTER_REPETITION)
  .test(SPECIAL_ALONE_CHARACTERS)
  .test(
    getMinLenghtOrEmpty({
      messageKey: I18N.MIN.key,
      min: VALIDATION.CITY.MIN_LENGTH,
    }),
  )
  .test(SPECIAL_CHARACTERS_AND_WORDS_FOR_ADDRESS_CITY);

const CITY_REQUIRED = CITY.required(I18N.REQUIRED.key);

const STATE = string()
  .nullable()
  .max(VALIDATION.STATE.MAX_LENGTH)
  .test(NUMBERS)
  .test(CHARACTER_REPETITION)
  .test(SPECIAL_ALONE_CHARACTERS)
  .test(SPECIAL_CHARACTERS_AND_WORDS)
  .test(REQUIRED_FOR_US);

const STATE_REQUIRED = STATE.required(I18N.REQUIRED.key);

const COUNTRY = string()
  .nullable()
  .max(VALIDATION.COUNTRY.MAX_LENGTH)
  .test(NUMBERS)
  .test(SPECIAL_ALONE_CHARACTERS);

const COUNTRY_REQUIRED = COUNTRY.ensure().required(I18N.REQUIRED.key);

const NAME1 = string()
  .nullable()
  .max(VALIDATION.NAME1.MAX_LENGTH)
  .test(NUMBERS)
  .test(CHARACTER_REPETITION)
  .test(SPECIAL_ALONE_CHARACTERS)
  .test(SPECIAL_CHARACTERS_AND_WORDS);

const NAME1_REQUIRED = NAME1.required(I18N.REQUIRED.key);

const NAME2 = NAME1;

const NAME2_REQUIRED = NAME2.required(I18N.REQUIRED.key);

// address table on mmw database
export const ADDRESS = {
  COMPANY,
  COMPANY_REQUIRED,
  STREET,
  STREET_REQUIRED,
  NR,
  NR_REQUIRED,
  COMPLEMENT,
  COMPLEMENT_REQUIRED,
  ZIPCODE,
  ZIPCODE_REQUIRED,
  CITY,
  CITY_REQUIRED,
  STATE,
  STATE_REQUIRED,
  COUNTRY,
  COUNTRY_REQUIRED,
  NAME1,
  NAME1_REQUIRED,
  NAME2,
  NAME2_REQUIRED,
  ADDRESS_LINE_1,
  ADDRESS_LINE_1_REQUIRED,
  ADDRESS_LINE_2,
  ZIPCODE_REQUIRED_BY_COUNTRY,
  getZipcodeRequiredByCountry,
};

export default ADDRESS;
