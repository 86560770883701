import Color from 'color';

import {
  ColoredFunctions,
  ColoredFunctionSignature,
  ColorFunctionNames,
  ColorFunctions,
  ColorFunctionSignature,
  ColorName,
  ColorSchema,
  Pallet,
  ThemeColors,
} from './types';

const proxyFn: (
  colorFunctionNames: ColorFunctionNames,
) => ColorFunctionSignature =
  (fnName: ColorFunctionNames) =>
  (color: string, fraction?: number): string =>
    Color(color)
      [fnName](fraction || 0.5)
      .hex();

const proxyColorFn =
  (fnName: ColorFunctionNames) =>
  (color: string, defaultFraction: number): ColoredFunctionSignature =>
  (fraction: number = defaultFraction) =>
    proxyFn(fnName)(color, fraction);

export const createColorFunctionsForColor = (
  color: string,
): ColoredFunctions => ({
  darken: proxyColorFn('darken')(color, 0.3),
  lighten: proxyColorFn('lighten')(color, 0.3),
  negate: proxyColorFn('negate')(color, 0.3),
  saturate: proxyColorFn('saturate')(color, 0.3),
  desaturate: proxyColorFn('desaturate')(color, 0.3),
  grayscale: proxyColorFn('grayscale')(color, 0.3),
  whiten: proxyColorFn('whiten')(color, 0.3),
  blacken: proxyColorFn('blacken')(color, 0.3),
});

export const getColorFunctions = (): ColorFunctions => ({
  darken: proxyFn('darken'),
  lighten: proxyFn('lighten'),
  negate: proxyFn('negate'),
  saturate: proxyFn('saturate'),
  desaturate: proxyFn('desaturate'),
  grayscale: proxyFn('grayscale'),
  whiten: proxyFn('whiten'),
  blacken: proxyFn('blacken'),
});

export const COLOR_SCHEMA: ColorSchema = {
  primary: {
    fill: 'primary',
    font: 'light',
    icon: 'basicLight',
  },
  secondary: {
    fill: 'secondary',
    font: 'primary',
    icon: 'primary',
  },
  dark: {
    fill: 'dark',
    font: 'light',
    icon: 'light',
  },
  light: {
    fill: 'light',
    font: 'dark',
    icon: 'primary',
  },
  basic: {
    fill: 'basic',
    font: 'dark',
    icon: 'light',
  },
  basicDark: {
    fill: 'dark',
    font: 'light',
    icon: 'basic',
  },
  basicLight: {
    fill: 'basicLight',
    font: 'dark',
    icon: 'primary',
  },
  success: {
    fill: 'success',
    font: 'light',
    icon: 'light',
  },
  error: {
    fill: 'error',
    font: 'light',
    icon: 'light',
  },
  alert: {
    fill: 'alert',
    font: 'light',
    icon: 'light',
  },
  background: {
    fill: 'background',
    font: 'dark',
    icon: 'dark',
  },
};

export const createColorSchemaForPallet = (
  pallet: Pallet,
  schema: ColorSchema = COLOR_SCHEMA, // override schema to change it
) =>
  Object.keys(pallet).reduce<ThemeColors>(
    (colors: Record<string, any>, key: ColorName): ThemeColors => ({
      ...colors,
      [key]: {
        fill: pallet[schema[key].fill],
        font: pallet[schema[key].font],
        icon: pallet[schema[key].icon],
        ...createColorFunctionsForColor(pallet[key]),
      },
    }),
    {
      pallet,
      ...getColorFunctions(),
    },
  );

export default {
  proxyFn,
  proxyColorFn,
  createColorFunctionsForColor,
  getColorFunctions,
};
