import { EMPTY_ARRAY } from '@mmw/constants-utils';

import {
  CHANGE_SELECTED_STORE,
  GET_AVAILABLE_STORES_ERROR,
  GET_AVAILABLE_STORES_START,
  GET_AVAILABLE_STORES_SUCCESS,
  GET_LOGGED_TRADER_DATA_ERROR,
  GET_LOGGED_TRADER_DATA_START,
  GET_LOGGED_TRADER_DATA_SUCCESS,
  GET_ORGUNIT_GROUP_NAMES_ERROR,
  GET_ORGUNIT_GROUP_NAMES_START,
  GET_ORGUNIT_GROUP_NAMES_SUCCESS,
  GET_SALESMEN_ERROR,
  GET_SALESMEN_START,
  GET_SALESMEN_SUCCESS,
  Reducer,
  RESET_LOGGED_TRADER_DATA,
  RESET_UPDATE_PICTURE_STATUS,
  UPDATE_LOGGED_USER_PROFILE_PICTURE_ERROR,
  UPDATE_LOGGED_USER_PROFILE_PICTURE_START,
  UPDATE_LOGGED_USER_PROFILE_PICTURE_SUCCESS,
} from './types';

const INITIAL_STATE = {
  loading: false,
  loggedTraderData: null,
  selectedStore: null,
  availableStores: EMPTY_ARRAY,
  salesmen: EMPTY_ARRAY,
  orgunitGroupNames: EMPTY_ARRAY,
  error: null,
  updatePicture: {
    success: false,
    error: null,
  },
};

const loggedTraderDataReducer: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LOGGED_TRADER_DATA_START:
      return {
        ...state,
        loading: true,
        error: null,
        updatePicture: {
          success: false,
          error: null,
        },
      };

    case GET_LOGGED_TRADER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        loggedTraderData: action.payload.loggedTraderData,
        selectedStore:
          state.selectedStore &&
          action.payload.loggedTraderData.orgunitFamily.indexOf(
            state.selectedStore,
          ) !== -1
            ? state.selectedStore
            : action.payload.loggedTraderData.companyId,
        error: null,
      };

    case GET_LOGGED_TRADER_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case GET_AVAILABLE_STORES_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_AVAILABLE_STORES_SUCCESS:
      return {
        ...state,
        loading: false,
        availableStores: action.payload.availableStores,
        error: null,
      };

    case GET_AVAILABLE_STORES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case CHANGE_SELECTED_STORE:
      return {
        ...state,
        selectedStore: action.payload.selectedStore,
      };

    case GET_SALESMEN_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_SALESMEN_SUCCESS:
      return {
        ...state,
        loading: false,
        salesmen: action.payload.salesmen,
        error: null,
      };

    case GET_SALESMEN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case GET_ORGUNIT_GROUP_NAMES_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_ORGUNIT_GROUP_NAMES_SUCCESS:
      return {
        ...state,
        loading: false,
        orgunitGroupNames: action.payload.groupNames,
        error: null,
      };

    case GET_ORGUNIT_GROUP_NAMES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_LOGGED_USER_PROFILE_PICTURE_START:
      return {
        ...state,
        loading: true,
        error: null,
        updatePicture: {
          success: false,
          error: null,
        },
      };

    case UPDATE_LOGGED_USER_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        updatePicture: {
          ...state.updatePicture,
          success: action.payload.success,
        },
      };

    case UPDATE_LOGGED_USER_PROFILE_PICTURE_ERROR:
      return {
        ...state,
        loading: false,
        updatePicture: {
          ...state.updatePicture,
          error: action.payload.error,
        },
      };
    case RESET_UPDATE_PICTURE_STATUS:
      return {
        ...state,
        updatePicture: {
          success: false,
          error: null,
        },
      };
    case RESET_LOGGED_TRADER_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default loggedTraderDataReducer;
