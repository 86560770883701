import { css } from '@ui-system/css';
import { createComponentModifiers } from '@ui-system/modifiers';

const componentModifiers = createComponentModifiers(({ theme }) => ({
  error: css`
    border-bottom-color: ${theme.colors[theme.mode].error};
  `,
  disabled: css`
    color: ${theme.colors[theme.mode].gray.A200};
    border-color: ${theme.colors[theme.mode].gray.A200};
  `,
}));

export default componentModifiers;
