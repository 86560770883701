import { CreateIssueJSON } from '@issue/interfaces';
import { JuraAuditStatus } from '@jura/audit-status/types';
import { RegistrationResponseJSON } from '@mmw/services-core-trader-registration/types';
import { OralbAuditStatus } from '@oralb/audit-status/types';
// import { AuditStatus as RetailAuditStatus } from '@retail/audits-status';
import { U } from 'ts-toolbelt';

// TODO: Option copy paste from @retail/audits-status, review it
export type ChangeStatusOption<
  SV = any,
  N extends string = 'name',
  V extends string = 'value',
> = Record<N, string> & Record<V, SV>;

export enum KnownAuditStatus {
  OPEN = 'OPEN',
  TRANSMITTED = 'TRANSMITTED',
  NEEDS_REVISION = 'NEEDS_REVISION',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  APPROVED_FOR_INITIAL_ORDER = 'APPROVED_FOR_INITIAL_ORDER',
  APPROVED_PENDING = 'APPROVED_PENDING',
  IN_VERFICATION = 'IN_VERFICATION',
}

export type AuditStatus =
  // | RetailAuditStatus
  JuraAuditStatus | OralbAuditStatus | KnownAuditStatus;

export type AuditStatusOption = ChangeStatusOption<AuditStatus> & {
  hideButton?: boolean;
  canCreateCorrectionIssues?: boolean;
  requiresToCreateCorrectionIssues?: boolean;
  canCreateApprovalIssues?: boolean;
};

export type AuditState = {
  created: boolean;
  registration: U.Nullable<RegistrationResponseJSON>;
  id: U.Nullable<number>;
  status: U.Nullable<AuditStatus>;
  canOrderProducts: boolean;
  canStartAudit: boolean;
  canCompleteAudit: boolean;
  canShowFilesCard: boolean;
  canUploadSellout: boolean;
  isRejected: boolean;
  allowedStatusTransitions: AuditStatus[];
  allowedStatusToCreateIssues: AuditStatus[];
  canCreateIssues: boolean;
  createIssuesOnTransition: Partial<Record<AuditStatus, CreateIssueJSON[]>>;
};
