import { I18nShape } from '@mmw/constants-i18n';
import { STRING_HTML_REGEX } from '@mmw/constants-utils';
import HtmlParser from '@mmw/ui-libraries-html-react-parser';
import Typography, { P } from '@mmw/ui-web-elements-typography';
import { useTranslate } from '@mmw/utils-text-utils/hooks';
import React, { ComponentType, useMemo } from 'react';
import { U } from 'ts-toolbelt';

const useHtmlOrTextComponent = (
  content: I18nShape,
  Component?: U.Nullable<ComponentType<unknown> | string>,
  modifiers?: string | Array<string>,
) =>
  STRING_HTML_REGEX.test(content.message) ? (
    HtmlParser(content.message, Component, Typography, modifiers)
  ) : (
    <P i18n={content} modifiers={modifiers} />
  );

export default useHtmlOrTextComponent;

export function useTranslateAsHtml(
  content: I18nShape,
  Component?: U.Nullable<ComponentType<unknown> | string>,
  modifiers?: string | Array<string>,
) {
  const translate = useTranslate();
  const translatedMessage = translate(content);
  return useMemo(
    () => HtmlParser(translatedMessage, Component, Typography, modifiers),
    [translatedMessage, Component, modifiers],
  );
}
