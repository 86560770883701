export default {
  center: () => `
    justify-content: center;
  `,
  start: () => `
    justify-content: start;
  `,
  end: () => `
    justify-content: end;
  `,
  flexStart: () => `
    justify-content: flex-start;
`,
  flexEnd: () => `
    justify-content: flex-end;
  `,
  left: () => `
    justify-content: left;
  `,
  right: () => `
    justify-content: right;
  `,
  spaceAround: () => `
    justify-content: space-around;
  `,
  spaceBetween: () => `
    justify-content: space-between;
  `,
};
