import type { I18nShape } from '@mmw/constants-i18n';
import { CONTINUE } from '@mmw/constants-i18n-buttons';
import { COMMON as COMMON_VALIDATIONS } from '@mmw/constants-validation-field-schemas';
import { Column } from '@mmw/hybrid-ui-structure-containers';
import { LOGIN_BY_PASSWORD_FIELD_PATHS as FIELD_PATHS } from '@mmw/redux-store-auth-api-login-by-password/types';
import { GetLoginConfigFormData } from '@mmw/redux-store-auth-api-login-config-by-user/hooks/useGetLoginConfigSubmitHandler';
import { use } from '@mmw/ui-theme/utils';
import Auth from '@retail/login-or-signup-ui-web';
import { useHandleUserLogin } from '@retail/login-or-signup-ui-web/hooks';
import Page from '@retail/ui-web-pages-login-and-signup/elements/PageStructure';
import React, { ReactNode, useCallback } from 'react';
import styled from 'styled-components';
import { F } from 'ts-toolbelt';
import { object } from 'yup';

const { EMAIL_REQUIRED } = COMMON_VALIDATIONS;

const EmailContainer = styled(Column)(props =>
  use(props, 'page.customPages.loginOrSignUp.emailContainer'),
);

const INITIAL_VALUES = new GetLoginConfigFormData();

const VALIDATION_SCHEMA = object().shape({
  [FIELD_PATHS.email.$path]: EMAIL_REQUIRED,
});

type Props = {
  leftSideContent?: ReactNode;
  rightSideContent?: ReactNode;
  bottomContent?: ReactNode;
  imageContent?: ReactNode;
  backgroundColor?: string;
  textsColorModifiers?: string | string[];
  customLabelI18n?: I18nShape;
  pageContainerStyle?: React.CSSProperties;
  customStyle?: React.CSSProperties;
  showDataPrivacy?: F.Function;
  onSubmitSuccess?: (values: GetLoginConfigFormData) => any;
};

type EnterEmailContentProps = Pick<
  Props,
  | 'customLabelI18n'
  | 'textsColorModifiers'
  | 'rightSideContent'
  | 'onSubmitSuccess'
  | 'showDataPrivacy'
> & { buttonStyle?: Record<string, any> };

export const EnterEmailContent: React.FC<EnterEmailContentProps> = ({
  customLabelI18n,
  textsColorModifiers,
  rightSideContent,
  onSubmitSuccess,
  showDataPrivacy,
  buttonStyle,
}: EnterEmailContentProps) => {
  const onFindUser = useHandleUserLogin(showDataPrivacy);
  const onSuccess = useCallback(
    (values: GetLoginConfigFormData) => {
      if (onSubmitSuccess) onSubmitSuccess(values);
      else {
        onFindUser(values);
      }
    },
    [onFindUser, onSubmitSuccess],
  );
  return (
    <>
      <Auth.SignIn
        initialValues={INITIAL_VALUES}
        validationSchema={VALIDATION_SCHEMA}
        onSubmitSuccess={onSuccess}
      >
        <Auth.Email
          labelI18n={customLabelI18n}
          labelModifiers={textsColorModifiers}
        />
        <Auth.SubmitButton i18n={CONTINUE} style={buttonStyle} />
        {rightSideContent}
      </Auth.SignIn>
    </>
  );
};
const EnterEmail: React.FC<Props> = ({
  showDataPrivacy,
  customLabelI18n,
  textsColorModifiers,
  leftSideContent,
  backgroundColor,
  bottomContent,
  pageContainerStyle,
  rightSideContent,
  customStyle,
  onSubmitSuccess,
  imageContent,
}: Props) => (
  <Page
    leftSideContent={leftSideContent}
    backgroundColor={backgroundColor}
    bottomContent={bottomContent}
    pageContainerStyle={pageContainerStyle}
    customStyle={customStyle}
  >
    <EmailContainer>
      <EnterEmailContent
        rightSideContent={rightSideContent}
        onSubmitSuccess={onSubmitSuccess}
        customLabelI18n={customLabelI18n}
        showDataPrivacy={showDataPrivacy}
        textsColorModifiers={textsColorModifiers}
      />
    </EmailContainer>
    {imageContent}
  </Page>
);

EnterEmail.defaultProps = {
  showDataPrivacy: undefined,
  leftSideContent: undefined,
  backgroundColor: undefined,
};

export default EnterEmail;
