import { F } from '@utils/ts';

const BASE_PATH = '/v1/trader/wallet-by-employee';

function generateTraderWalletByEmployeePath(
  feature: string,
): F.Function<[walletName: string], string> {
  return (walletName: string) => `${BASE_PATH}/${walletName}/${feature}`;
}
export const GetWalletBalancePath =
  generateTraderWalletByEmployeePath('balance');

export const GetWalletTransactionsPath =
  generateTraderWalletByEmployeePath('transactions');
