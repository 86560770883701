import { USERNAME_AND_TERMS_FIELDPATHS } from '@auth/form-data';
import { FastFormInput } from '@fast-form/ui-system';
import { FastFormInputProps } from '@fast-form/ui-system/FastFormInput';
import React from 'react';

const UserField: React.FC<Partial<FastFormInputProps>> = ({
  ...props
}: Partial<FastFormInputProps>) => (
  <FastFormInput
    fieldPath={USERNAME_AND_TERMS_FIELDPATHS.username.$path}
    placeholderSizeLimit={30}
    autoCapitalize="none"
    autoCorrect={false}
    keyboardType="email-address"
    {...props}
  />
);

export default UserField;
