import { I18nShape } from '@mmw/constants-i18n';
import {
  WattPointErrorReason,
  WattPointStatus,
  WattPointValueError,
} from '@rec-group/marketing-tools-sso/types';
import { camelCase, keys, map } from 'lodash';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';

const namespace = 'recCoinsManagement';

const REC_SELECT_ERROR_REASONS = keyBy(
  map(keys(WattPointErrorReason), key => ({
    key: `${namespace}.${camelCase(key)}`,
    message: key,
  })),
  'message',
) as Record<WattPointErrorReason, I18nShape>;

const REC_SELECT_STATUS = keyBy(
  map(keys(WattPointStatus), key => ({
    key: `${namespace}.${camelCase(key)}`,
    message: key,
  })),
  'message',
) as Record<WattPointStatus, I18nShape>;

const REC_SELECT_WATT_POINT_VALUE_ERROR = keyBy(
  map(keys(WattPointValueError), key => ({
    key: `${namespace}.${camelCase(key)}`,
    message: key,
  })),
  'message',
) as Record<WattPointValueError, I18nShape>;

export function getRECCoinsManagementI18n(
  value?: WattPointErrorReason | WattPointStatus | WattPointValueError | null,
) {
  return value
    ? get(
        {
          ...REC_SELECT_ERROR_REASONS,
          ...REC_SELECT_STATUS,
          ...REC_SELECT_WATT_POINT_VALUE_ERROR,
        },
        value,
      )
    : null;
}

const REC_COINS_MANAGEMENT = {
  TITLE: {
    key: `${namespace}.title`,
    message: 'RECoins Management',
  },
  STATUS: {
    key: `${namespace}.status`,
    message: 'REC Select Status',
  },
  EARNED_LABEL: {
    key: `${namespace}.shouldEarn`,
    message: 'Should earn with this registration',
  },
  TRANSACTIONS_SUM: {
    key: `${namespace}.transactionsSum`,
    message: 'And the sum of the transactions is',
  },
  BONUS_EARNED: {
    key: `${namespace}.bonusEarned`,
    message: 'Bonus earned by registration',
  },
  PRODUCT_MULTIPLIER: {
    key: `${namespace}.productMultiplier`,
    message: 'Product multiplier',
  },
  AMOUNT_WARNING: {
    key: `${namespace}.amountWarning`,
    message:
      "RECoin amount doesn't match panel validation. Please revalidate installation or manually adjust the value to match.",
  },
  AGREEMENT_CONTROLLER: {
    NOT_ACCEPTED: {
      key: `${namespace}.notAccepted`,
      message: 'The partner did not accepted the campaign terms and conditions',
    },
    ACCEPTED: {
      key: `${namespace}.accepted`,
      message: 'Partner has accepted the campaign terms and conditions on',
    },
  },
  CAMPAIGN_CONTROLLER: {
    NOT_INCLUDED: {
      key: `${namespace}.not_included`,
      message: "Partner isn't included on the campaign",
    },
    INCLUDED: {
      key: `${namespace}.included`,
      message: 'Partner is included on the campaign',
    },
  },
  RECOINS_STATEMENT: {
    key: `${namespace}.RECoins_statement`,
    message: 'RECoins Statement',
  },
  STATEMENT: {
    key: `${namespace}.statement`,
    message: 'Statement',
  },
  VOUCHERS: {
    key: `${namespace}.vouchers`,
    message: 'Vouchers',
  },
  BALANCE: {
    key: `${namespace}.balance`,
    message: 'Balance',
  },

  REC_SELECT_ERROR_REASONS,
  REC_SELECT_STATUS,
  REC_SELECT_WATT_POINT_VALUE_ERROR,
};

export default REC_COINS_MANAGEMENT;
