import { css } from '@ui-system/css';
import { MENU_ITEM_UUID, MENU_UUID, MenuProps } from '@ui-system/interfaces';
import { makeStyle } from '@ui-system/style';

import { makeFinalStyleHook } from './internal';

const useMenuDefaultStyle = makeStyle<Partial<MenuProps>>(
  ({ theme, utils, props, mediaQueries }) => css`
    ${utils.applyBackgroundColor(theme, 'white')}
    top: -45px;
    right: -10px;
    ${mediaQueries.upToMd`
      top: -60px;
      left: 5px;
    `}
    ${props?.round
      ? `
      border-radius: 20px;
    `
      : ''}
  `,
);

type MenuUIProps = Partial<{
  isHovering: boolean;
  isFirstChild: boolean;
  isLastChild: boolean;
  round?: boolean;
}>;
// ${utils.applyJustify(theme, 'center')};
const useMenuItemDefaultStyle = makeStyle<MenuUIProps>(
  ({ theme, utils, props }) => css`
    ${utils.applyPadding(theme, '2')};
    ${props?.isHovering ? utils.applyBackgroundColor(theme, 'primary') : ''}
    ${props?.isLastChild
      ? `
      ${
        props?.round
          ? `
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
      `
          : ''
      }
    `
      : ''}
    ${props?.isFirstChild
      ? `      
      ${
        props?.round
          ? `
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      `
          : ''
      }
    `
      : ''}
  `,
);

export const useMenuStyle = makeFinalStyleHook<Partial<MenuProps>>(
  MENU_UUID,
  useMenuDefaultStyle,
);

export const useMenuItemStyle = makeFinalStyleHook<
  Partial<{
    isHovering: boolean;
    isLastChild: boolean;
    isFirstChild: boolean;
    round?: boolean;
  }>
>(MENU_ITEM_UUID, useMenuItemDefaultStyle);
