import { I18nShape } from '@mmw/constants-i18n';
import { use } from '@mmw/ui-theme/utils';
import { Body2 } from '@mmw/ui-web-elements-typography';
import { get } from 'lodash';
import * as React from 'react';
import styled from 'styled-components';

import Restrictions from './Restrictions';

// important styles being used to override inherited parent styles (consumer/shipping modals)
const ErrorContainer = styled.div(
  () => `
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 0 !important;
  margin-left: 0 !important;
  flex: unset !important;
  div {
    flex: unset !important;
    :last-child {
      margin-left: 0 !important;
      max-width: 25px;
    }
    :first-child {
      margin-right: 0 !important;
      max-width: 100% !important;
    }
  }
`,
);

const StyledLabel = styled(Body2)(props => use(props, 'formField.errorMsg'));

type Props = {
  i18n?: I18nShape;
};

function ErrorMessage({ i18n }: Props): React.ClassicElement<Props> {
  const restrictions = get(i18n, 'values.restrictions');
  return (
    <ErrorContainer>
      <StyledLabel modifiers="left" i18n={i18n} />
      {restrictions && <Restrictions restrictions={restrictions} />}
    </ErrorContainer>
  );
}

ErrorMessage.defaultProps = {
  i18n: undefined,
};

export default ErrorMessage;
