export default ({ theme }) => `
  font-family: ${theme.fonts.fontFamily};
  flex: 1;
  &:first-child {
    border-top-right-radius: 0
    border-bottom-right-radius: 0
    border-right: 0;
  }
  &:last-child {
    border-top-left-radius: 0
    border-bottom-left-radius: 0
    border-left: 0;
  }
  &:not(:first-child):not(:last-child) {
    border-radius: 0;
    border-right: 0;
    border-left: 0;
  }
`;
