import update from 'immutability-helper';
import { find } from 'lodash';
import { clone } from 'ramda';

interface ActionCreator<P, M> {
  (payload?: P, meta?: M): {
    type: string;
    payload?: P;
    meta?: M;
  };
  type: string;
}

export interface Builder<S> {
  addCase: <AC extends ActionCreator<any, any>>(
    ac: AC,
    reducerCase: Case<
      S,
      {
        type: ReturnType<AC>['type'];
        payload: ReturnType<AC>['payload'];
        meta: ReturnType<AC>['meta'];
      }
    >,
  ) => Reducer<S>['reducer'];

  addMatcher: <AC extends ActionCreator<any, any>>(
    matcher: Matcher<AC>,
    reducerCase: Case<
      S,
      {
        type: ReturnType<AC>['type'];
        payload: ReturnType<AC>['payload'];
        meta: ReturnType<AC>['meta'];
      }
    >,
  ) => Reducer<S>['reducer'];
}

type Case<S, A> = (state: S, action: A) => S | undefined;

type Matcher<AC extends ActionCreator<any, any>> = (
  action: ReturnType<AC>,
) => boolean;

interface Match<S, AC extends ActionCreator<any, any>> {
  matcher: Matcher<AC>;
  reducerCase: Case<
    S,
    {
      type: ReturnType<AC>['type'];
      payload: ReturnType<AC>['payload'];
      meta: ReturnType<AC>['meta'];
    }
  >;
}

class Reducer<S> {
  cases: Record<string, Case<S, any>> = {};

  matchers: Match<S, any>[] = [];

  reducer: ((state: S, action: ReturnType<ActionCreator<any, any>>) => S) &
    Builder<S>;

  constructor(initialState: S) {
    // @ts-ignore
    this.reducer = (state = initialState, action) => {
      const match = find(this.matchers, ({ matcher }) => matcher(action));
      if (action.type in this.cases) {
        const draft = clone(state);
        if (match) {
          const matchedClone = clone(
            update(state, {
              $set: this.cases[action.type](draft, action),
            }),
          );
          return update(state, {
            // @ts-ignore
            $set: match.reducerCase(matchedClone as S, action),
          });
        }
        return update(state, {
          $set: this.cases[action.type](draft, action),
        });
      }
      return state;
    };
    this.reducer.addCase = (ac, reducerCase) => {
      this.cases[ac.type] = reducerCase;
      return this.reducer;
    };

    this.reducer.addMatcher = (matcher, reducerCase) => {
      this.matchers.push({
        matcher,
        reducerCase,
      });
      return this.reducer;
    };
  }
}

export function createReducer<S>(
  initialState: S,
  builder: (builder: Builder<S>) => void,
) {
  const { reducer } = new Reducer(initialState);
  builder(reducer);
  return reducer;
}
