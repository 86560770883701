import bulletCircleStyle from './bulletCircleStyle';
import bulletPinStyle from './bulletPinStyle';
import imgStyle from './imgStyle';

export default {
  style: () => `
  `,
  img: {
    style: imgStyle,
  },
  bullet: {
    circle: {
      style: bulletCircleStyle,
    },
    pin: {
      style: bulletPinStyle,
    },
  },
};
