import { BatchAction } from '@mmw/redux-rx-of-type-operator';
import { loggedUserSelector } from '@mmw/redux-store-auth-api-authentication/stateSelector';
import { RootState } from '@mmw/redux-store-auth-api-authentication/types';
import { TokenParseResult } from '@mmw/services-auth-api-token-parser/types';
import { batchActions } from 'redux-batched-actions';
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import {
  changeSelectedStoreAction,
  getLoggedTraderDataStartAction,
} from '../actions';
import {
  ChangeSelectedStoreAction,
  GetLoggedTraderDataStartAction,
} from '../types';

type Output = ChangeSelectedStoreAction | GetLoggedTraderDataStartAction;
const watchAuthenticationStore = (
  action$: ActionsObservable<any>,
  state$: Observable<RootState>,
): Observable<BatchAction<Output>> =>
  state$.pipe(
    map(loggedUserSelector),
    distinctUntilChanged(),
    filter(d => d != null),
    map(loggedUser => (<TokenParseResult>loggedUser).orgunitID),
    map(
      (orgunitID: number) =>
        <BatchAction<Output>>(
          batchActions([
            changeSelectedStoreAction(orgunitID),
            getLoggedTraderDataStartAction(),
          ])
        ),
    ),
  );

export default watchAuthenticationStore;
