import { BaseUsernameForm, EMAIL_VALIDATION_SCHEMA } from '@auth/form-data';
import { BackToLoginAction, UserField } from '@auth/view-form-components';
import { FastFormSubmitButton } from '@fast-form/ui-system';
import { RECOVER } from '@mmw/constants-i18n-buttons';
import { PERSON as PERSON_I18N } from '@mmw/constants-i18n-fields';
import { FastFormProvider } from '@mmw/redux-store-fast-form';
import { AUTHENTICATION as AUTH_I18N } from '@retail/constants-i18n';
import { css } from '@ui-system/css';
import UI from '@ui-system/ui';
import React from 'react';
import { F } from 'ts-toolbelt';

const INITIAL_VALUES = new BaseUsernameForm();

const {
  EMAIL: { LABEL: EMAIL_LABEL_I18N },
} = PERSON_I18N;

interface Props {
  onInformUserMailSuccess: F.Function<[BaseUsernameForm]>;
  onCancel: F.Function;
  loading?: boolean;
}

const InformEmail: React.FC<Props> = ({
  onInformUserMailSuccess,
  onCancel,
  loading,
}: Props) => (
  <FastFormProvider
    initialValues={INITIAL_VALUES}
    // @ts-ignore
    validationSchema={EMAIL_VALIDATION_SCHEMA}
    onSubmitSuccess={onInformUserMailSuccess}
  >
    <UI.Container justify="center" w="100%">
      <UI.Container bg="white" borderRadius={20} w={565} p={6} shadow={10}>
        <UI.Container direction="column" gap={4} f={1}>
          <UI.Container direction="column" gap={2}>
            <UI.Container justify="space-between">
              <UI.Typography
                modifiers="primary"
                variant="h4"
                i18n={AUTH_I18N.AUTHENTICATION.RECOVER_PASSWORD}
              />
              {onCancel && (
                <UI.Container
                  justify="flex-end"
                  style={css`
                    text-align: right;
                    text-wrap: nowrap;
                  `}
                >
                  <BackToLoginAction
                    onClick={onCancel}
                    textModifiers="capitalize, grayA300"
                    textVariant="caption"
                    useLink
                  />
                </UI.Container>
              )}
            </UI.Container>
            <UI.Typography
              variant="body2"
              i18n={AUTH_I18N.AUTHENTICATION.RECOVER_PASSWORD_DESCRIPTION}
            />
          </UI.Container>
          <UserField
            label={EMAIL_LABEL_I18N}
            accessoryLeft={
              <UI.Icon name="person" size={20} color="primary.light" />
            }
          />
          <UI.Container>
            <FastFormSubmitButton i18n={RECOVER} disabled={loading} />
          </UI.Container>
        </UI.Container>
      </UI.Container>
    </UI.Container>
  </FastFormProvider>
);

export default InformEmail;
