import {
  EUROPE_COUNTRIES,
  isEuropeanCountry,
} from '@mmw/constants-country-codes';
import CommonService from '@mmw/services-core-common';
import { get } from '@mmw/utils-object-utils';
import * as Yup from 'yup';

const METHOD_NAME = 'vat';
const MESSAGE_KEY = 'VAT number must be valid';
const TEST_NAME = 'vatTest';
const DEFAULT_COUNTRY_FIELD_PATH = 'country';
// If you need data to test
// https://github.com/se-panfilov/jsvat/blob/master/test/countries_vat_lists/germany.vat.js

export function getVatTest(
  message: string,
  countryFieldPath = DEFAULT_COUNTRY_FIELD_PATH,
): Yup.TestConfig {
  return {
    name: METHOD_NAME,
    message,
    test(value: string) {
      const country: string = get<
        Record<string, any>,
        typeof countryFieldPath,
        string
      >(this.parent, countryFieldPath, '');
      if (!isEuropeanCountry(country) || !value || !country) return true;
      return CommonService.validateVAT(value, country) as boolean;
    },
  };
}

export default Yup.addMethod(
  Yup.string,
  METHOD_NAME,
  function (customMessageKey?: string, countryFieldPath?: string) {
    return this.test(
      TEST_NAME,
      customMessageKey || MESSAGE_KEY,
      function test(vat) {
        const country =
          this.parent.address[countryFieldPath || DEFAULT_COUNTRY_FIELD_PATH];
        const isEuropean = EUROPE_COUNTRIES.includes(country);
        if (!isEuropean) return true;
        if (!vat || !country) return true;
        return CommonService.validateVAT(vat, country);
      },
    );
  },
);
