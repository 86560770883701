import { use } from '@mmw/ui-theme/utils';
import { Caption2 } from '@mmw/ui-web-elements-typography';
import { Props } from '@mmw/ui-web-elements-typography/Typography';
import React from 'react';
import styled from 'styled-components';

const ASTERISC = '*';
const RequiredContainer = styled(Caption2)(props =>
  use(props, 'formField.required'),
);

const Required: React.FC<Props> = ({ children, ...props }: Props) => (
  <RequiredContainer {...props}>{children || ASTERISC}</RequiredContainer>
);

export default Required;
