import {
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  RECOVER_PASSWORD_ERROR,
  RECOVER_PASSWORD_START,
  RECOVER_PASSWORD_SUCCESS,
  Reducer,
  VALIDATE_CHANGE_PASSWORD_UUID_ERROR,
  VALIDATE_CHANGE_PASSWORD_UUID_START,
  VALIDATE_CHANGE_PASSWORD_UUID_SUCCESS,
} from './types';

const INITIAL_STATE = {
  loading: false,
  error: null,
};

const recoverPasswordReducer: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECOVER_PASSWORD_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case RECOVER_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case RECOVER_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case CHANGE_PASSWORD_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case VALIDATE_CHANGE_PASSWORD_UUID_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case VALIDATE_CHANGE_PASSWORD_UUID_SUCCESS:
      return {
        ...state,
        isValidUuid: action.payload.success,
        loading: false,
        error: null,
      };
    case VALIDATE_CHANGE_PASSWORD_UUID_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default recoverPasswordReducer;
