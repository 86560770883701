import { Hex } from '@ui-system/color';
import { Style } from '@ui-system/interfaces/types';
import { ContainerProps } from '@ui-system/interfaces-container';
import { Theme } from '@ui-system/theme';
import { nanoid } from 'nanoid';
import React from 'react';

export interface PageProps {
  gridTemplateRows?: string;
  backgroundColor?: Hex;
  backgroundImageUrl?: string;
  backgroundSize?: string;
  backgroundImageOffsetY?: string;
  backgroundImageHeight?: string;
  backgroundImageWidth?: string;
  children?: React.ReactNode;
  theme?: Theme;
  showDevInfo?: boolean;
  height?: number;
  style?: Style;
}

export interface HeaderProps extends Partial<ContainerProps> {
  height?: string;
  style?: Style;
  children: React.ReactNode;
}
export interface ContentProps {
  style?: Style;
  bgImageUrl?: string;
  children: React.ReactNode;
  headerHeight?: number;
  height?: number;
}

export interface FooterProps extends Partial<ContainerProps> {
  height?: string;
  children: React.ReactNode;
}

interface PageStatics {
  Header: React.FC<HeaderProps>;
  Content: React.FC<ContentProps>;
  Footer: React.FC<FooterProps>;
}

export const PAGE_UUID = nanoid();
export const PAGE_HEADER_UUID = nanoid();
export const PAGE_FOOTER_UUID = nanoid();

export type PageType = React.FC<PageProps> & PageStatics;
