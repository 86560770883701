import useTheme from '@mmw/ui-web-hooks-use-theme';
import { Breakpoint, Breakpoints } from '@mmw/web-theme/media';
import { useMediaLayout } from 'use-media';

const useThemeBreakpoints = (): Breakpoints => {
  const {
    media: { BREAKPOINTS },
  } = useTheme();
  return BREAKPOINTS;
};

const useMediaQuery = (breakpoint: Breakpoint): boolean => {
  const BREAKPOINTS = useThemeBreakpoints();
  return useMediaLayout(BREAKPOINTS[breakpoint]);
};

export const useIsPhone = (): boolean => useMediaQuery('PHONE');

export const useIsTablet = (): boolean => useMediaQuery('TABLET');

export const useLargeDesktop = (): boolean => useMediaQuery('LARGE_DESKTOP');
export const useIsLargeScreen = (): boolean => {
  const d = useMediaQuery('DESKTOP');
  const dl = useMediaQuery('LARGE_DESKTOP');
  return d || dl;
};

export const useUpToTablet = (): boolean => {
  const BREAKPOINTS = useThemeBreakpoints();
  return useMediaLayout({ maxWidth: BREAKPOINTS.TABLET.maxWidth });
};

export default useMediaQuery;
