import { ConsumerCampaignClientCampaignJSON } from '@ccc/service-campaigns-list/types';
import { getDefaultApiHostUrl } from '@mmw/config';
// import { SOB_MAPPING_BY_COUNTRY } from '@mmw/constants-salesorgbrand-ids';
import { AsyncModuleConfig, RequestType } from '@redux-async-module/interfaces';
import { createModule } from '@redux-async-module/module-utils';
import {
  createDispatchHook,
  createSelectorHook,
} from '@redux-basic-module/hooks-utils';
import { createSelector } from '@redux-basic-module/selector-utils';
import { createAction } from '@reduxjs/toolkit';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { useEffect } from 'react';

import {
  CampaignsListStartPayload,
  CampaignsListSuccessPayload,
  NAMESPACE,
  RootState,
} from './types';

function isCampaignActive(campaign: ConsumerCampaignClientCampaignJSON) {
  return moment().isBetween(campaign?.startdate, campaign?.enddate);
}

function isCampaignArchived(campaign: ConsumerCampaignClientCampaignJSON) {
  return !isCampaignActive(campaign);
}

function isSobFromCurrentCountry(
  campaign: ConsumerCampaignClientCampaignJSON,
  country: string,
) {
  // const sobsByCountry = get(SOB_MAPPING_BY_COUNTRY, country);
  // campaign?.salesOrgBrandID &&
  //   includes(sobsByCountry, campaign?.salesOrgBrandID)
  return (
    !isEmpty(campaign?.registerableCountries) &&
    includes(campaign?.registerableCountries, country)
  );
}

const showActive = createAction(`${NAMESPACE}/SHOW_ACTIVE`);
const showArchived = createAction(`${NAMESPACE}/SHOW_ARCHIVED`);
const setCurrentCampaignsCountry = createAction(
  `${NAMESPACE}/SET_FILTER_BY_SOB`,
  (payload: string) => ({
    payload,
  }),
);

const extraHandlers = {
  [showActive.type]: state => ({
    ...state,
    data: filter(state.rawData, isCampaignActive),
    mode: 'ACTIVE',
  }),
  [showArchived.type]: state => ({
    ...state,
    data: filter(state.rawData, isCampaignArchived),
    mode: 'ARCHIVED',
  }),
  [setCurrentCampaignsCountry.type]: (state, { payload }) => ({
    ...state,
    data: filter(
      state.rawData,
      c => isSobFromCurrentCountry(c, payload) && isCampaignActive(c),
    ),
    currentCampaignsCountry: payload,
  }),
};

const MAIN_EPIC_CONFIG = {
  requestOptions: {
    type: RequestType.get,
    getPath: () => `${getDefaultApiHostUrl()}/campaign/configurations/overview`,
  },
  onSuccess(
    campaigns: CampaignsListSuccessPayload,
    startPayload: CampaignsListStartPayload,
  ) {
    if ((startPayload?.sob as number) <= 0) return campaigns;
    return filter<ConsumerCampaignClientCampaignJSON>(
      campaigns,
      c => c.salesOrgBrandID === startPayload.sob,
    );
  },
};

const CONFIG: AsyncModuleConfig<
  RootState,
  CampaignsListStartPayload,
  CampaignsListSuccessPayload
> = {
  namespace: NAMESPACE,
  actionName: 'LOAD_CAMPAIGNS',
  mainEpicConfig: MAIN_EPIC_CONFIG,
  buildExtraHandlers(actions) {
    return {
      ...extraHandlers,
      [actions.start.type]: (state: RootState) => ({
        ...state,
        isLoading: true,
        alreadySearch: false,
      }),
      [actions.success.type]: (state: RootState, { payload }) => ({
        ...state,
        isLoading: false,
        alreadySearch: true,
        error: null,
        data: filter(
          payload,
          c =>
            isCampaignActive(c) &&
            isSobFromCurrentCountry(c, state.currentCampaignsCountry || 'DE'),
        ),
        rawData: payload,
        mode: 'ACTIVE',
        currentCampaignsCountry: state.currentCampaignsCountry || 'DE',
      }),
      [actions.error.type]: (state: RootState, { payload }) => ({
        ...state,
        alreadySearch: false,
        error: payload,
        isLoading: false,
      }),
    };
  },
};

const { getDynamicModule, selectorHooks, dispatchHooks } = createModule<
  RootState,
  CampaignsListStartPayload,
  CampaignsListSuccessPayload
>(CONFIG);

export const getCampaignsListModule = getDynamicModule;

export const useShowActive = createDispatchHook(showActive);
export const useShowArchived = createDispatchHook(showArchived);
export const useSetCurrentCampaignsCountry = createDispatchHook<
  ReturnType<typeof setCurrentCampaignsCountry>
>(setCurrentCampaignsCountry);
export const useFilterMode = createSelectorHook(
  createSelector([NAMESPACE, 'mode']),
);
export const useCurrentCampaignsCountry: () => string = createSelectorHook(
  createSelector([NAMESPACE, 'currentCampaignsCountry']),
);

export const useAlreadySearch: () => boolean = createSelectorHook(
  createSelector([NAMESPACE, 'alreadySearch']),
);

export const {
  useData: useCampaignsList,
  useIsLoading: useIsCampaignsListLoading,
  useError: useCampaignsListError,
} = selectorHooks;

export const {
  useAsyncStartOnMount: useLoadCampaignsListOnMount,
  useAsyncStart: useUpdateCampaignsList,
} = dispatchHooks;

export const useLoadCampaignsOnMountWhenEmpty = (payload: {
  sob: number;
}): void => {
  const data = useCampaignsList();
  const total = data?.length;
  const loadCampaigns = useUpdateCampaignsList();
  useEffect(() => {
    if (!total || total === 0) {
      loadCampaigns(payload);
    }
  }, [total]);
};
