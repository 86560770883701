import createConsumerAccountEpic from './createConsumerAccountEpic';
import deleteConsumerAccountEpic from './deleteConsumerAccountEpic';
import orgunitSignupEpic from './orgunitSignupEpic';

const epics = [
  orgunitSignupEpic,
  createConsumerAccountEpic,
  deleteConsumerAccountEpic,
];

export {
  createConsumerAccountEpic,
  deleteConsumerAccountEpic,
  orgunitSignupEpic,
};

export type Epics = typeof epics;

export default epics;
