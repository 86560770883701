import {
  ACCOUNT_CREATION_FIELDPATHS,
  USERNAME_AND_PASSWORD_FIELDPATHS,
} from '@auth/form-data';
import FastFormPasswordInput from '@fast-form/ui-system/FastFormPasswordInput';
import { InputProps } from '@ui-system/interfaces-form/input';
import React from 'react';

const PasswordField: React.FC<Partial<InputProps>> = (
  props: Partial<InputProps>,
) => (
  <FastFormPasswordInput
    fieldPath={USERNAME_AND_PASSWORD_FIELDPATHS.password.$path}
    {...props}
  />
);

export const PasswordConfirmationField: React.FC<Partial<InputProps>> = (
  props: Partial<InputProps>,
) => (
  <FastFormPasswordInput
    fieldPath={ACCOUNT_CREATION_FIELDPATHS.confirmPassword.$path}
    {...props}
  />
);

export default PasswordField;
