import { getLogger } from '@mmw/contextual-config';
import { VerifyTanFormData } from '@mmw/redux-store-email-verification';
import { OperationsEnum } from '@mmw/services-auth-api-authentication/types';
import { executeRecaptchaAction } from '@mmw/ui-libraries-recaptcha';
import { useCallback } from 'react';
import { F, U } from 'ts-toolbelt';

import { useVerifyUserPhoneByTanCall } from '../module';
import { PhoneVerificationResponse } from '../types';

const useHandleVerifyPhoneByTan = (
  onSuccess: F.Function<[string]>,
  operationId: OperationsEnum,
  userEmail: U.Nullable<string>,
  recaptchaToken?: U.Nullable<string>,
): F.Function<[VerifyTanFormData]> => {
  const verifyUserPhoneByTan = useVerifyUserPhoneByTanCall();
  const callback = useCallback(
    (data: PhoneVerificationResponse) => {
      if (data.success) {
        return onSuccess(data.notAuthenticatedOperationToken);
      }
      return null;
    },
    [onSuccess],
  );

  return useCallback(
    async ({ tanCode }) => {
      try {
        if (!userEmail) {
          const errorMsg =
            'No email found, cannot continue with phone verification';
          getLogger().error(errorMsg);
          throw new Error(errorMsg);
        }

        await verifyUserPhoneByTan({
          operationId,
          tan: tanCode,
          userEmail,
          recaptchaToken:
            recaptchaToken || (await executeRecaptchaAction('verify-email')),
          callback,
        });
      } catch (error) {
        getLogger().error('Failed on trying to verify email, error =', error);
      }
    },
    [callback, operationId, recaptchaToken, verifyUserPhoneByTan, userEmail],
  );
};

export default useHandleVerifyPhoneByTan;
