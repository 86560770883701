import Login, { LoginContentProps } from '@auth/view-login';
import { useNavigateToRoute } from '@lib/navigation/hooks';
import {
  useNavigateToNewAccount,
  useNavigateToRecoverPassword,
} from '@mmw/common-navigation/hooks';
import Routes from '@mmw/common-navigation/routes';
import { getApplicationId } from '@mmw/contextual-config';
import Page from '@mmw/ui-page';
import { ResourcesContextProvider } from '@mmw/ui-providers-resources';
import { ReCaptchaV3ContextProvider } from '@recaptcha/v3';
import { useAssets } from '@ui-system/assets';
import { css } from '@ui-system/css';
import { Style } from '@ui-system/interfaces';
import UI from '@ui-system/ui';
import React from 'react';
import { F } from 'ts-toolbelt';

interface LoginPageProps extends LoginContentProps {
  contentStyle?: Style | Style[];
  onLoginSuccess?: F.Function;
  onClickRecoverPass?: F.Function;
}

const style = css`
  justify-content: center;
`;

const LoginPage: React.FC<LoginPageProps> = ({
  contentStyle = style,
  onLoginSuccess,
  onClickRecoverPass,
  ...props
}: LoginPageProps) => {
  const onSuccess = useNavigateToRoute(Routes.HOME, { disableState: true });
  const onClickRecover = useNavigateToRecoverPassword();
  const onClickNewAccount = useNavigateToNewAccount();
  const assets = useAssets();
  return (
    <Page
      backgroundImageUrl={assets?.loginBackgroundImage}
      backgroundSize="cover"
    >
      <Page.Content contentStyle={contentStyle}>
        <ReCaptchaV3ContextProvider>
          <UI.Container justify="center">
            <ResourcesContextProvider prefixKey={getApplicationId()}>
              <Login
                onSuccess={onLoginSuccess || onSuccess}
                onClickRecoverPass={onClickRecoverPass || onClickRecover}
                onClickNewAccount={onClickNewAccount}
                {...props}
              />
            </ResourcesContextProvider>
          </UI.Container>
        </ReCaptchaV3ContextProvider>
      </Page.Content>
    </Page>
  );
};

export default LoginPage;
