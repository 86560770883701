import { PhotoFileType } from '@lib/camera/interfaces';
import { UploadableFileJSON } from '@mmw/services-core-common/types';
import { LoggedUserData } from '@mmw/services-core-logged-user-data-service/types';
import {
  AvailableStore,
  Salesman,
} from '@mmw/services-core-trader-orgunit/types';
import { U } from 'ts-toolbelt';

export const GET_LOGGED_TRADER_DATA_START =
  '@mmw/trader/logged-user/GET_LOGGED_TRADER_DATA_START';
export const GET_LOGGED_TRADER_DATA_SUCCESS =
  '@mmw/trader/logged-user/GET_LOGGED_TRADER_DATA_SUCCESS';
export const GET_LOGGED_TRADER_DATA_ERROR =
  '@mmw/trader/logged-user/GET_LOGGED_TRADER_DATA_ERROR';

export const GET_AVAILABLE_STORES_START =
  '@mmw/trader/logged-user/GET_AVAILABLE_STORES_START';
export const GET_AVAILABLE_STORES_SUCCESS =
  '@mmw/trader/logged-user/GET_AVAILABLE_STORES_SUCCESS';
export const GET_AVAILABLE_STORES_ERROR =
  '@mmw/trader/logged-user/GET_AVAILABLE_STORES_ERROR';

export const CHANGE_SELECTED_STORE =
  '@mmw/trader/logged-user/CHANGE_SELECTED_STORE';

export const EDIT_COMPANY_PERMISSION =
  'PERM_WEBSERVICE_TRADER_ORGUNIT_DETAILS_EDIT';
export const VIEW_EMPLOYEES_PERMISSION = 'PERM_WEBSERVICE_TRADER_USERS_SEARCH';
export const EDIT_EMPLOYEES_PERMISSION = 'PERM_WEBSERVICE_TRADER_USERS_UPDATE';

export const GET_SALESMEN_START = '@mmw/trader/logged-user/GET_SALESMEN_START';
export const GET_SALESMEN_SUCCESS =
  '@mmw/trader/logged-user/GET_SALESMEN_SUCCESS';
export const GET_SALESMEN_ERROR = '@mmw/trader/logged-user/GET_SALESMEN_ERROR';

export const GET_ORGUNIT_GROUP_NAMES_START =
  '@mmw/trader/logged-user/GET_ORGUNIT_GROUP_NAMES_START';
export const GET_ORGUNIT_GROUP_NAMES_SUCCESS =
  '@mmw/trader/logged-user/GET_ORGUNIT_GROUP_NAMES_SUCCESS';
export const GET_ORGUNIT_GROUP_NAMES_ERROR =
  '@mmw/trader/logged-user/GET_ORGUNIT_GROUP_NAMES_ERROR';

export const UPDATE_LOGGED_USER_PROFILE_PICTURE_START =
  '@mmw/trader/logged-user/UPDATE_LOGGED_USER_PROFILE_PICTURE_START';
export const UPDATE_LOGGED_USER_PROFILE_PICTURE_SUCCESS =
  '@mmw/trader/logged-user/UPDATE_LOGGED_USER_PROFILE_PICTURE_SUCCESS';
export const UPDATE_LOGGED_USER_PROFILE_PICTURE_ERROR =
  '@mmw/trader/logged-user/UPDATE_LOGGED_USER_PROFILE_PICTURE_ERROR';

export const RESET_UPDATE_PICTURE_STATUS =
  '@mmw/trader/logged-user/RESET_UPDATE_PICTURE_STATUS';
export const RESET_LOGGED_TRADER_DATA =
  '@mmw/trader/logged-user/RESET_LOGGED_TRADER_DATA';

export type GetLoggedTraderDataStartAction = {
  type: '@mmw/trader/logged-user/GET_LOGGED_TRADER_DATA_START';
};

export type GetLoggedTraderDataSuccessAction = {
  type: '@mmw/trader/logged-user/GET_LOGGED_TRADER_DATA_SUCCESS';
  payload: {
    loggedTraderData: LoggedUserData;
  };
};

export type GetLoggedTraderDataErrorAction = {
  type: '@mmw/trader/logged-user/GET_LOGGED_TRADER_DATA_ERROR';
  payload: {
    error: Error;
  };
};

export type GetAvailableStoresStartAction = {
  type: '@mmw/trader/logged-user/GET_AVAILABLE_STORES_START';
  payload: {};
};

export type GetAvailableStoresSuccessAction = {
  type: '@mmw/trader/logged-user/GET_AVAILABLE_STORES_SUCCESS';
  payload: {
    availableStores: AvailableStore[];
  };
};

export type GetAvailableStoresErrorAction = {
  type: '@mmw/trader/logged-user/GET_AVAILABLE_STORES_ERROR';
  payload: {
    error: Error;
  };
};

export type ChangeSelectedStoreAction = {
  type: '@mmw/trader/logged-user/CHANGE_SELECTED_STORE';
  payload: {
    selectedStore: number;
  };
};

export type GetSalesmenStartAction = {
  type: '@mmw/trader/logged-user/GET_SALESMEN_START';
};

export type GetSalesmenSuccessAction = {
  type: '@mmw/trader/logged-user/GET_SALESMEN_SUCCESS';
  payload: {
    salesmen: Array<Salesman>;
  };
};

export type GetSalesmenErrorAction = {
  type: '@mmw/trader/logged-user/GET_SALESMEN_ERROR';
  payload: {
    error: Error;
  };
};

export type GetOrgunitGroupNamesStartAction = {
  type: '@mmw/trader/logged-user/GET_ORGUNIT_GROUP_NAMES_START';
};

export type GetOrgunitGroupNamesSuccessAction = {
  type: '@mmw/trader/logged-user/GET_ORGUNIT_GROUP_NAMES_SUCCESS';
  payload: {
    groupNames: Array<string>;
  };
};

export type GetOrgunitGroupNamesErrorAction = {
  type: '@mmw/trader/logged-user/GET_ORGUNIT_GROUP_NAMES_ERROR';
  payload: {
    error: Error;
  };
};

export type UpdateLoggedUserProfilePictureStartAction = {
  type: '@mmw/trader/logged-user/UPDATE_LOGGED_USER_PROFILE_PICTURE_START';
  payload: {
    photo: PhotoFileType | UploadableFileJSON;
  };
};

export type UpdateLoggedUserProfilePictureSuccessAction = {
  type: '@mmw/trader/logged-user/UPDATE_LOGGED_USER_PROFILE_PICTURE_SUCCESS';
  payload: {
    success: boolean;
  };
};

export type UpdateLoggedUserProfilePictureErrorAction = {
  type: '@mmw/trader/logged-user/UPDATE_LOGGED_USER_PROFILE_PICTURE_ERROR';
  payload: {
    error: Error;
  };
};

export type ResetUpdatePictureStatus = {
  type: '@mmw/trader/logged-user/RESET_UPDATE_PICTURE_STATUS';
};

export type ResetLoggedTraderDtataAction = {
  type: '@mmw/trader/logged-user/RESET_LOGGED_TRADER_DATA';
};

export type State = {
  loading: boolean;
  loggedTraderData: U.Nullable<LoggedUserData>;
  selectedStore: U.Nullable<number>;
  availableStores: AvailableStore[];
  salesmen: Salesman[];
  orgunitGroupNames: U.Nullable<string[]>;
  error: U.Nullable<Error>;
  updatePicture: {
    success: boolean;
    error: U.Nullable<Error>;
  };
};

export const NAMESPACE = 'loggedTraderData';

export type RootState = {
  loggedTraderData: State;
};

export type Action =
  | GetLoggedTraderDataStartAction
  | GetLoggedTraderDataSuccessAction
  | GetLoggedTraderDataErrorAction
  | ChangeSelectedStoreAction
  | GetAvailableStoresStartAction
  | GetAvailableStoresSuccessAction
  | GetAvailableStoresErrorAction
  | GetSalesmenStartAction
  | GetSalesmenSuccessAction
  | GetSalesmenErrorAction
  | GetOrgunitGroupNamesStartAction
  | GetOrgunitGroupNamesSuccessAction
  | GetOrgunitGroupNamesErrorAction
  | UpdateLoggedUserProfilePictureStartAction
  | UpdateLoggedUserProfilePictureSuccessAction
  | UpdateLoggedUserProfilePictureErrorAction
  | ResetUpdatePictureStatus
  | ResetLoggedTraderDtataAction;

export type Reducer = (state: State, action: Action) => State;
