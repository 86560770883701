const ELEMENT_WIDTH = '100%';
const ELEMENT_HEIGHT = 'auto';
const ELEMENT_PADDING = '10px';
const ELEMENT_VERTICAL_MARGIN = '5px';
const ELEMENT_BORDER = 'none';

const ELEMENT_MEASURES = `
  width: ${ELEMENT_WIDTH};
  height: ${ELEMENT_HEIGHT};
  padding: ${ELEMENT_PADDING};
`;

const baseElementStyle = ({ theme }) => `
  ${ELEMENT_MEASURES};
  color: ${theme.colors.pallet.dark};
  background-color: ${theme.colors.pallet.basicLight};
  font-family: ${theme.fonts.fontFamily};
  font-size: 0.875rem;
  box-sizing: border-box;
  border-radius: 0;
`;

const border = () => `
  border: ${ELEMENT_BORDER};
`;

const focus = ({ theme }) => `
  &:focus-within {
    outline: none;
    transition: 0.3s;
    box-shadow: 0 0 2px ${theme.colors.pallet.secondary};
  }
`;

export default {
  variable: {
    ELEMENT_WIDTH,
    ELEMENT_HEIGHT,
    ELEMENT_PADDING,
    ELEMENT_VERTICAL_MARGIN,
    ELEMENT_MEASURES,
    baseElementStyle,
    border,
    focus,
  },
};
