import { EMPTY_OBJECT } from '@mmw/constants-utils';
import { U } from 'ts-toolbelt';

type IssueId = number;

type AsyncState = {
  loading: boolean;
  error: U.Nullable<Error>;
};

export class PerformIssueState {
  issuesById: Record<IssueId, AsyncState> = EMPTY_OBJECT;
}
