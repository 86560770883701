import { useContext } from 'react';

import { ReCaptchaV3Context } from './Context';

const useReCaptchaV3Context = () => useContext(ReCaptchaV3Context);

export const useIsGettingToken = (action: string): boolean => {
  const { isGettingToken } = useReCaptchaV3Context();
  return isGettingToken(action);
};
