import {
  CLEAR_ERROR,
  LOGIN_BY_PASSWORD_ERROR,
  LOGIN_BY_PASSWORD_START,
  LOGIN_BY_PASSWORD_SUCCESS,
  Reducer,
  SET_RECAPTCHA_RESPONSE,
  SET_USERNAME,
} from './types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  username: null,
  recaptchaResponse: null,
  redirectUrl: null,
};

const authenticationReducer: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USERNAME:
      return {
        ...state,
        username: action.payload.username,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        redirectUrl: null,
      };
    case SET_RECAPTCHA_RESPONSE:
      return {
        ...state,
        recaptchaResponse: action.payload.recaptchaResponse,
      };
    case LOGIN_BY_PASSWORD_START:
      return {
        ...state,
        username: action.payload.username || state.username,
        loading: true,
        error: null,
        redirectUrl: null,
      };
    case LOGIN_BY_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        recaptchaResponse: null,
        redirectUrl: action.payload.redirectUrl,
      };
    case LOGIN_BY_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        recaptchaResponse: null,
        redirectUrl: null,
      };
    default:
      return state;
  }
};

export default authenticationReducer;
