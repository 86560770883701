import { css } from '@ui-system/css';
import { createComponentModifiers } from '@ui-system/modifiers';
import { Theme } from '@ui-system/theme';

const underline = css`
  text-decoration: underline;
`;
const uppercase = css`
  text-transform: uppercase;
`;
const lowercase = css`
  text-transform: lowercase;
`;
const capitalize = css`
  text-transform: capitalize;
`;
const link = (theme: Theme) => css`
  color: ${theme.colors[theme.mode].primary.main};
  text-decoration: underline;
  text-decoration-color: ${theme.colors[theme.mode].primary.main};
`;

const primary = (theme: Theme) => css`
  color: ${theme.colors[theme.mode].primary.main};
`;
const primaryLight = (theme: Theme) => css`
  color: ${theme.colors[theme.mode].primary.light};
`;
const error = (theme: Theme) => css`
  color: ${theme.colors[theme.mode].error};
`;
const warning = (theme: Theme) => css`
  color: ${theme.colors[theme.mode].warning};
`;
const success = (theme: Theme) => css`
  color: ${theme.colors[theme.mode].success};
`;
const secondary = (theme: Theme) => css`
  color: ${theme.colors[theme.mode].secondary.main};
`;
const grayA100 = (theme: Theme) => css`
  color: ${theme.colors[theme.mode].gray.A100};
`;
const grayA200 = (theme: Theme) => css`
  color: ${theme.colors[theme.mode].gray.A200};
`;
const grayA300 = (theme: Theme) => css`
  color: ${theme.colors[theme.mode].gray.A300};
`;
const grayA400 = (theme: Theme) => css`
  color: ${theme.colors[theme.mode].gray.A400};
`;
const basicDark = (theme: Theme) => css`
  color: ${theme.colors[theme.mode].basicDark};
`;
const black = (theme: Theme) => css`
  color: ${theme.colors[theme.mode].black};
  text-decoration-color: ${theme.colors[theme.mode].black};
`;
const white = (theme: Theme) => css`
  color: ${theme.colors[theme.mode].white};
  text-decoration-color: ${theme.colors[theme.mode].white};
`;
const yellow = () => css`
  color: #fcc10c;
  text-decoration-color: #fcc10c;
`;
const center = css`
  text-align: center;
`;
const left = css`
  text-align: left;
`;
const right = css`
  text-align: right;
`;
const justify = css`
  text-align: justify;
`;
// XXX: iOS only works bold like this
const bold = (theme: Theme) => css`
  font-weight: 800;
  font-family: ${theme.typography.fontFamily['bold' || 'regular']};
`;
const italic = (theme: Theme) => css`
  font-style: italic;
  font-family: ${theme.typography.fontFamily['italic' || 'regular']};
`;
const thin = (theme: Theme) => css`
  font-weight: 200;
  font-family: ${theme.typography.fontFamily['thin' || 'regular']};
`;
const medium = (theme: Theme) => css`
  font-weight: 300;
  font-family: ${theme.typography.fontFamily['medium' || 'regular']};
`;
const normal = css`
  font-weight: normal;
`;
const thinitalic = (theme: Theme) => css`
  font-weight: 200;
  font-family: ${theme.typography.fontFamily['thinItalic' || 'regular']};
`;
const boldItalic = (theme: Theme) => css`
  font-weight: 800;
  font-family: ${theme.typography.fontFamily['boldItalic' || 'regular']};
`;

const nowrap = css`
  white-space: nowrap;
`;

const xs = css`
  font-size: 10px;
`;

const pointer = css`
  cursor: pointer;
`;
const componentModifiers = createComponentModifiers(({ theme }) => ({
  underline,
  uppercase,
  lowercase,
  capitalize,
  link: link(theme),
  primary: primary(theme),
  primaryLight: primaryLight(theme),
  error: error(theme),
  warning: warning(theme),
  success: success(theme),
  secondary: secondary(theme),
  grayA100: grayA100(theme),
  grayA200: grayA200(theme),
  grayA300: grayA300(theme),
  grayA400: grayA400(theme),
  basicDark: basicDark(theme),
  black: black(theme),
  white: white(theme),
  center,
  left,
  right,
  justify,
  bold: bold(theme),
  italic: italic(theme),
  thin: thin(theme),
  normal,
  thinItalic: thinitalic(theme),
  boldItalic: boldItalic(theme),
  medium: medium(theme),
  nowrap,
  xs,
  pointer,
  yellow,
}));

export default componentModifiers;
