import { useQueryParams } from '@lib/navigation';
import { BAD_CREDENTIALS } from '@mmw/constants-i18n-authentication';
import {
  useAuthenticateTraderBySSOStart,
  useAuthError,
  useIsAuthenticating,
} from '@mmw/redux-store-auth-api-authentication/hooks';
import Page from '@mmw/ui-page';
import { GlobalSpinner } from '@mmw/ui-web-blocks-spinner';
import PATHS from '@retail/constants-routes/constants';
import { css } from '@ui-system/css';
import UI from '@ui-system/ui';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

const { TITLE, DESCRIPTION } = BAD_CREDENTIALS;

const SingleSignOn: React.FC = () => {
  const [alreadyCalled, setCalled] = useState(false);
  const { site, parms } = useQueryParams<{
    site: string;
    parms: any;
  }>();

  const authenticate = useAuthenticateTraderBySSOStart();
  const isAuthenticating = useIsAuthenticating();
  const error = useAuthError() as unknown as string;

  useEffect(() => {
    if (alreadyCalled) {
      return;
    }
    if (site && parms) {
      authenticate(site, parms);
      setCalled(true);
    }
  }, [alreadyCalled, authenticate, parms, site]);

  if (!site || !parms) {
    return <Redirect to={PATHS.HOME} />;
  }

  return (
    <Page>
      {isAuthenticating && <GlobalSpinner visible />}
      {error && (
        <Page.Content
          contentStyle={css`
            margin: auto;
          `}
        >
          <UI.Animation f={1}>
            <UI.Card>
              <UI.Card.Content>
                <UI.Container w={400} direction="column">
                  <UI.Typography modifiers="error">{error}</UI.Typography>
                </UI.Container>
              </UI.Card.Content>
            </UI.Card>
          </UI.Animation>
        </Page.Content>
      )}
    </Page>
  );
};

export default SingleSignOn;
