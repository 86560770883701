import defaultApi, { ApiResponse } from '@mmw/api-v2';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import autoBind from 'auto-bind';

import {
  GetUserSecurePhonePath,
  PhoneNumberLookupPath,
  RegisterUserSecurePhonePath,
} from './apiPaths';
import logger from './log';
import {
  LookupReturType,
  PhoneNumberLookupResponse,
  UserPhoneReturnType,
} from './types';

type Api = typeof defaultApi;

type SmsServiceOptions = {
  authApi?: Api;
  authenticationService: AuthenticationService;
};

class SmsService {
  api: Api;

  authenticationService: AuthenticationService;

  constructor({ authApi, authenticationService }: SmsServiceOptions) {
    this.api = authApi || defaultApi;
    this.authenticationService = authenticationService;
    autoBind(this);
  }

  async lookupPhoneNumber(
    phoneNumber: string,
    country: string,
  ): Promise<LookupReturType> {
    logger.debug(
      `Will try to check if the informed phone number was a valid one, ${phoneNumber}`,
    );
    let result;
    try {
      const response: ApiResponse<PhoneNumberLookupResponse> =
        await this.api.post(PhoneNumberLookupPath(), {
          phoneNumber,
          country,
        });
      result = {
        valid: response.data.valid,
        phoneNumber: response.data.informedPhone,
        countryCode: response.data.countryCode,
        alreadyRegistered: response.data.alreadyRegistered,
      };

      logger.info(
        'Successfully got response from phone number lookup with validation status as valid=%s',
        result,
      );
      return result;
    } catch (error) {
      logger.error('Error when trying to lookup phone number, error=%O', error);
      throw error;
    }
  }

  async getUserSecurePhone(userId: string): Promise<UserPhoneReturnType> {
    logger.debug(
      `Will try to get user registered phone number by userId=%O, ${userId}`,
    );
    let result;
    const headers =
      await this.authenticationService.getAuthenticationHttpHeaders();
    try {
      const response: ApiResponse<UserPhoneReturnType> = await this.api.get(
        GetUserSecurePhonePath(userId),
      );
      result = response.data;
      logger.info(
        'Successfully got response of user registered phone with result=%s',
        result,
        { headers },
      );
      return result;
    } catch (error) {
      logger.error(
        'Error when trying to get user registered phone number, error=%O',
        error,
      );
      throw error;
    }
  }

  async registerSecurePhone(
    phone: string,
    country: string,
    notAuthenticatedOperationToken: string,
  ): Promise<boolean> {
    logger.debug(
      `Will try to register users security phone number=%O, ${phone}`,
    );
    let result;
    const headers =
      await this.authenticationService.getAuthenticationHttpHeaders();
    try {
      const response: ApiResponse<boolean> = await this.api.post(
        RegisterUserSecurePhonePath(),
        {
          phone,
          country,
          notAuthenticatedOperationToken,
        },
        {
          headers,
        },
      );
      result = response.data;
      logger.info(
        'Successfully got response of register security phone request=%s',
        result,
      );
      return result;
    } catch (error) {
      logger.error(
        'Error when trying to get user registered phone number, error=%O',
        error,
      );
      throw error;
    }
  }
}

export default SmsService;
