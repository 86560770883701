import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { P } from '@mmw/ui-web-elements-typography';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import NavLink from './Link';
import { Item } from './NavItem';

const Icon = styled(FontAwesomeIcon)`
  margin-right: 5px;
  color: #3b82c5;
`;

const Dropdown = styled.ul.attrs(props => ({ role: 'menu' }))`
  position: absolute;
  top: 100%;
  z-index: 1000;
  min-width: 160px
  padding: 5px 0;
  display: block;
  margin: 2px 0 2px 0;
  list-style: none;
  text-align: left;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0px 0px 4px 4px;
  background-clip: padding-box;
  left: auto;  
  right: ${({ alignRight }) => (alignRight ? 0 : null)};
  
  > li {
    padding-bottom: 7px;
    padding-top: 7px;
    border-bottom: 1px solid #eee;

    &:first-child {
      padding-top: 0px;
    }
    
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
  
  ${NavLink} {
    font-size: 0.8rem;
    padding: 3px 20px;
    color: #333;
    :hover {
      background: #8fabe7;
      color: #fff;
      ${Icon}, P {
        color: #fff;
      }
    }
  }
`;

Dropdown.Item = ({ href, children, msgKey, defaultMsg, icon, onClick }) => (
  <Item>
    {!onClick ? (
      <NavLink href={href}>
        {icon && <Icon icon={['fas', icon]} />}
        {msgKey && (
          <P
            color="#3B82C5"
            fontSize="inherit"
            modifiers={['primary']}
            msgKey={msgKey}
            defaultMsg={defaultMsg}
          />
        )}
        {children}
      </NavLink>
    ) : (
      <NavLink onClick={onClick}>
        {icon && <Icon icon={['fas', icon]} />}
        {msgKey && (
          <P
            color="#3B82C5"
            fontSize="inherit"
            modifiers={['primary']}
            msgKey={msgKey}
            defaultMsg={defaultMsg}
          />
        )}
        {children}
      </NavLink>
    )}
  </Item>
);

Dropdown.Item.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.string,
  msgKey: PropTypes.string,
  defaultMsg: PropTypes.string,
  onClick: PropTypes.func,
};

Dropdown.Item.defaultProps = {
  href: '#',
  children: null,
  icon: null,
  msgKey: null,
  defaultMsg: null,
  onClick: null,
};

export default Dropdown;
