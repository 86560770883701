export default () => `
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  &:active {
    outline: 0;
  }
  &:-moz-focusring {
    outline: 0;
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
  ::-ms-expand{
    display: none;
  }
`;
