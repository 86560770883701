import { PROJECT as VALIDATION } from '@mmw/constants-fields-validations';
import { PROJECT as I18N } from '@mmw/constants-i18n-validations';
import { number, string } from 'yup';

const NAME = string().max(VALIDATION.NAME.MAX_LENGTH);

const PRODUCT_NAME = string().max(VALIDATION.PRODUCT.NAME.MAX_LENGTH);

const PRODUCT_QUANTITY = number()
  .min(VALIDATION.PRODUCT.QUANTITY.MIN)
  .default(1);

const PRODUCT_ORDER_NO = string();
const PRODUCT_DELIVERY_NO = string();
const PRODUCT_PALLET_NO = string();
const PRODUCT_SERIAL_NO = string();

export const PROJECT = {
  NEW_PROJECT_CARD: {
    NAME,
    NAME_REQUIRED: NAME.required(I18N.NAME.REQUIRED.key),
  },
  PRODUCT: {
    PRODUCT_NAME,
    PRODUCT_NAME_REQUIRED: PRODUCT_NAME.required(
      I18N.PRODUCT.NAME.REQUIRED.key,
    ),
    PRODUCT_QUANTITY,
    PRODUCT_ORDER_NO,
    PRODUCT_DELIVERY_NO,
    PRODUCT_PALLET_NO,
    PRODUCT_SERIAL_NO,
  },
};

export default PROJECT;
