import defaultApiV2, { ApiResponse } from '@mmw/api-v2';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import autoBind from 'auto-bind';
import createCache, { Cache } from 'keshi';

import { GetCampaignsPath } from './apiPaths';
import logger from './log';
import { CampaignJSON } from './types';

type Api = typeof defaultApiV2;

type TraderCampaignsServiceOptions = {
  apiv2?: Api;
  authenticationService: AuthenticationService;
};

class TraderCampaignsService {
  api: Api;

  authenticationService: AuthenticationService;

  cache: Cache;

  constructor({ apiv2, authenticationService }: TraderCampaignsServiceOptions) {
    this.api = apiv2 || defaultApiV2;
    this.authenticationService = authenticationService;
    this.cache = createCache();
    autoBind(this);
  }

  async getCampaigns(
    branchID: number,
    language: string,
  ): Promise<Array<CampaignJSON>> {
    const cacheKey = `get-campaigns-${branchID}-lang-${language}`;
    return this.cache.resolve(
      cacheKey,
      async () => this.internalGetCampaigns(branchID, language),
      '5m',
    );
  }

  async internalGetCampaigns(
    branchID: number,
    language: string,
  ): Promise<Array<CampaignJSON>> {
    logger.debug(`Trying to get campaigns for branch=${branchID}`);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Array<CampaignJSON>> = await this.api.get(
        GetCampaignsPath(branchID, language),
        {
          headers,
        },
      );
      const { data } = response;
      logger.info(`Successfully got campaigns for branch=${branchID}`);
      return data;
    } catch (error) {
      logger.error(
        `Error when getting campaigns for branch=${branchID}, error=%O`,
        error,
      );
      throw error;
    }
  }
}

export default TraderCampaignsService;
