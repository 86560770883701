import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { retrieveUseridByEmailStartAction } from '../actions';

function useGetUserInfoByEmail() {
  const dispatch = useDispatch();
  return useCallback(
    (userMail: string, scopeNames?: string[]) => {
      dispatch(retrieveUseridByEmailStartAction(userMail, scopeNames));
    },
    [dispatch],
  );
}

export default useGetUserInfoByEmail;
