export default {
  title: {
    alignItems: 'center',
    justifyContent: 'space-between',
    maxHeight: '65px',
    padding: '10px 30px 10px 45px',
    gridTemplateArea: `
      "text filter actions interactions"
      "text filter actions interactions";
    `,
    tablet: `
      padding: 0px;
      margin-left: 1.25rem;
      margin-right: 1.25rem;
    `,
    phone: `
      grid-template-areas: 
        "text interactions"
        "actions actions"
        "filter filter";
      grid-template-columns: 1fr;
      padding: 20px 0;
      flex-direction: column;
      align-self: center;
      margin-left: 0;
      margin-right: 0;
    `,
  },
  header: {
    tablet: `
    `,
    phone: `
      height: 10.9rem;
    `,
  },
  sideContent: {
    padding: '25px 45px 0px 25px',
    tablet: `
      padding: 0px;
      margin-left: 1.25rem;
      margin-bottom: 2.5rem;
      margin-right: 1.25rem;
    `,
  },
  content: {
    padding: '0px 30px 20px 45px',
    tablet: `
        padding: 0px;
        margin-left: 1.25rem;
        margin-bottom: 1.25rem;
        margin-right: 1.25rem;
    `,
    phone: `
        padding: 0px;
        margin-left: 0;
        margin-bottom: 1.25rem;
        margin-right: 0;
    `,
  },
  actions: {
    gridGap: '10px',
    justifyContent: 'space-between',
    tablet: `
        padding: 0px;
        margin-top: 1rem;
        margin-left: 1.25rem;
        margin-bottom: 1.25rem;
        margin-right: 1.25rem;
    `,
  },
};
