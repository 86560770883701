import { enableBatching } from 'redux-batched-actions';

import initialActions from './actions';
import getAvailableSalutationsEpic from './epic';
import getAvailableSalutationsReducer from './reducer';
import { NAMESPACE as getAvailableSalutationsNamespace } from './stateSelector';

export const reducer = getAvailableSalutationsReducer;
export const epics = [getAvailableSalutationsEpic];
export const NAMESPACE = getAvailableSalutationsNamespace;

export function getAvailableSalutationsModule() {
  return {
    id: NAMESPACE,
    reducerMap: {
      [NAMESPACE]: enableBatching(reducer),
    },
    initialActions,
    epics,
  };
}
