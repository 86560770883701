import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { checkAuthenticationStartAction, refreshAccessToken } from '../actions';

const { logger } = contextualConfig.application;

const onRefreshTokenSuccess = (
  action$: ActionsObservable<ReturnType<typeof refreshAccessToken.success>>,
): Observable<ReturnType<typeof checkAuthenticationStartAction>> =>
  action$.pipe(
    ofType(refreshAccessToken.success.type),
    tap(() => logger.info('Trying to logout')),
    map(() => checkAuthenticationStartAction()),
    // XXX: to update user details (token claims) after refresh
    // TODO: We could always use the token from refresh action, parse it and update the user details
    // BUT, tests are needed to not break anything
  );

export default onRefreshTokenSuccess;
