import { LanguageCode } from '@mmw/constants-languages';
import qs from 'qs';

const BASE_PATH = '/v1/trader/contracts';

export const GetContractsPath = (
  orgunitID: number,
  agreementTypes: number[],
  language: LanguageCode,
): string =>
  `${BASE_PATH}?${qs.stringify({
    orgunitID,
    agreementTypes: agreementTypes.join(','),
    language,
  })}`;

export const GetContractStatusPath = (
  orgunitID: number,
  agreementType: number,
  offerorID: number,
  language: LanguageCode,
): string =>
  `${BASE_PATH}/status?${qs.stringify({
    orgunitID,
    agreementType,
    offerorID,
    language,
  })}`;

export const RetrieveContractStatusPath = (
  orgunitAgreementID: number,
  language: LanguageCode,
): string =>
  `${BASE_PATH}/${orgunitAgreementID}/status?${qs.stringify({
    language,
  })}`;

export const AcceptContractPath = (
  orgunitID: number,
  agreementType: number,
  offerorID: number,
  authorizedSignatory: string,
  role: string,
  language: LanguageCode,
): string =>
  `${BASE_PATH}/accept?${qs.stringify({
    orgunitID,
    agreementType,
    offerorID,
    authorizedSignatory,
    language,
    role,
  })}`;

export const ConfirmContractPath = (
  orgunitID: number,
  agreementType: number,
  offerorID: number,
  automaticallyApprove: boolean,
  language: LanguageCode,
): string =>
  `${BASE_PATH}/confirm-with-token?${qs.stringify({
    orgunitID,
    agreementType,
    offerorID,
    automaticallyApprove,
    language,
  })}`;

export const DownloadSignedAgreementPath = (
  orgunitAgreementID: number,

  language: LanguageCode,
): string =>
  `${BASE_PATH}/${orgunitAgreementID}/download-signed?${qs.stringify({
    language,
  })}`;
