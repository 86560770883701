import React from 'react';

import InformEmail from '../elements/InformEmail';
import NewPassword from '../elements/NewPassword';
import ValidateToken from '../elements/ValidateToken';
import { ContentProps, PasswordRedefinitionSteps } from '../types';

const Content: React.FC<ContentProps> = ({
  step,
  onInformUserMailSuccess,
  onTanCodeVerifySuccess,
  onResendCodeRequest,
  onSubmitNewPassword,
  onCancelOperation,
  operationInProcess,
}: ContentProps) => (
  <>
    {
      {
        [PasswordRedefinitionSteps.informEmail]: (
          <InformEmail
            onInformUserMailSuccess={onInformUserMailSuccess}
            onCancel={onCancelOperation}
            loading={operationInProcess}
          />
        ),
        [PasswordRedefinitionSteps.tanCodeValidation]: (
          <ValidateToken
            onVerificationSuccess={onTanCodeVerifySuccess}
            onResendRequest={onResendCodeRequest}
            onCancel={onCancelOperation}
            operationInProcess={operationInProcess}
          />
        ),
        [PasswordRedefinitionSteps.newPasswordInput]: (
          <NewPassword
            onSubmitSuccess={onSubmitNewPassword}
            onCancel={onCancelOperation}
          />
        ),
      }[step as PasswordRedefinitionSteps]
    }
  </>
);

export default Content;
