export const NAMESPACE = 'agreementActivationModal';

const agreementStyle = {
  style: ({ theme }) => `
    font-family: ${theme.fonts.fontFamily};
    max-height: 65vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    ${theme.media.upToTablet`
      max-width: 100vw;
      max-height: none;
      table {
        display: inline-block;
        overflow-x: auto;
        max-width: 100%;
      }
    `};
  `,
};

export default agreementStyle;
