import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { setFieldValueAction } from '../../store/actions';
import { FieldPath } from '../../types';
import useFormId from '../useFormId';

function useOnSelectChange(fieldPath: FieldPath) {
  const dispatch = useDispatch();
  const formId = useFormId();
  return useCallback(
    (event: React.SyntheticEvent) => {
      const { value } = event.target;
      dispatch(setFieldValueAction<string>(formId, fieldPath, value));
    },
    [formId, fieldPath],
  );
}

export default useOnSelectChange;
