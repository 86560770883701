import { EMPTY_ARRAY } from '@mmw/constants-utils';
import { concat } from 'lodash';

import { Modifiers } from '../types';

const toArray = entry => {
  if (!entry) {
    return EMPTY_ARRAY;
  }
  return Array.isArray(entry) ? entry : [entry];
};

export default (base: Modifiers, entry: Modifiers): Array<string> =>
  concat(toArray(base), toArray(entry));
