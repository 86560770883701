import { Salutation } from '@mmw/services-core-common/types';

export const GET_AVAILABLE_SALUTATIONS_START =
  '@mmw/available-salutations/GET_AVAILABLE_SALUTATIONS_START';
export const GET_AVAILABLE_SALUTATIONS_SUCCESS =
  '@mmw/available-salutations/GET_AVAILABLE_SALUTATIONS_SUCCESS';
export const GET_AVAILABLE_SALUTATIONS_ERROR =
  '@mmw/available-salutations/GET_AVAILABLE_SALUTATIONS_ERROR';

export type GetAvailableSalutationsStartAction = {
  type: '@mmw/available-salutations/GET_AVAILABLE_SALUTATIONS_START';
};

export type GetAvailableSalutationsSuccessAction = {
  type: '@mmw/available-salutations/GET_AVAILABLE_SALUTATIONS_SUCCESS';
  payload: {
    availableSalutations: Array<Salutation>;
  };
};

export type GetAvailableSalutationsErrorAction = {
  type: '@mmw/available-salutations/GET_AVAILABLE_SALUTATIONS_ERROR';
  payload: {
    error: Error;
  };
};

export type State = {
  loading: boolean;
  availableSalutations: Array<Salutation>;
  error: Error | null;
};

export type Action =
  | GetAvailableSalutationsStartAction
  | GetAvailableSalutationsSuccessAction
  | GetAvailableSalutationsErrorAction;

export type Reducer = (state: State, action: Action) => State;
