import authenticateBySSOTokenEpic from './authenticateBySSOTokenEpic';
import authenticateByTanEpic from './authenticateByTanEpic';
import authenticateEpic from './authenticateEpic';
import authenticateTraderBySSOEpic from './authenticateTraderBySSOEpic';
import authenticateWithGoogleEpic from './authenticateWithGoogleEpic';
import checkAuthenticationEpic from './checkAuthenticationEpic';
import checkAuthWithSystemTokenEpic from './checkAuthWithSystemTokenEpic';
import logoutEpic from './logoutEpic';
import logoutWithAppIdEpic from './logoutWithAppIdEpic';
import onRefreshTokenSuccessEpic from './onRefreshTokenSuccessEpic';
import refreshAccessTokenEpic from './refreshAccessTokenEpic';
import requestTanEpic from './requestTanEpic';
import retrieveUseridByEmailEpic from './retrieveUseridByEmailEpic';
import runCheckAuthenticationEpic from './runCheckAuthenticationEpic';

const epics = [
  authenticateTraderBySSOEpic,
  checkAuthenticationEpic,
  authenticateEpic,
  logoutEpic,
  requestTanEpic,
  authenticateByTanEpic,
  retrieveUseridByEmailEpic,
  refreshAccessTokenEpic,
  authenticateBySSOTokenEpic,
  runCheckAuthenticationEpic,
  authenticateWithGoogleEpic,
  logoutWithAppIdEpic,
  checkAuthWithSystemTokenEpic,
  onRefreshTokenSuccessEpic,
];

export {
  authenticateBySSOTokenEpic,
  authenticateByTanEpic,
  authenticateEpic,
  authenticateTraderBySSOEpic,
  checkAuthenticationEpic,
  logoutEpic,
  onRefreshTokenSuccessEpic,
  refreshAccessTokenEpic,
  requestTanEpic,
  retrieveUseridByEmailEpic,
};

export type Epics = typeof epics;

export default epics;
