import { I18nShape } from '@mmw/constants-i18n';
import { FieldPath } from '@mmw/redux-store-fast-form';
import {
  useInputFieldConfigProps,
  useOnBlur,
  useOnChange,
  useValue,
} from '@mmw/redux-store-fast-form/hooks/fields';
import { useMemo } from '@mmw/redux-store-i18n/hooks';
import { Modifiers } from '@mmw/ui-theme/modifiers/types';
import CheckboxField, { Checkbox } from '@mmw/ui-web-elements-checkbox';
import { useOnFocus } from '@mmw/ui-web-providers-input-handlers';
import merge from 'lodash/merge';
import React from 'react';

type Props = {
  noFieldContainer?: boolean;
  fieldPath: FieldPath;
  labelI18n?: I18nShape | null | void;
  modifiers?: Modifiers;
  labelModifiers?: Modifiers;
};

const FastFormCheckbox: React.FC<Props> = ({
  fieldPath,
  labelI18n,
  modifiers,
  noFieldContainer,
  ...rest
}: Props) => {
  const checked = useValue<boolean>(fieldPath, false);
  const onBlur = useOnBlur(fieldPath);
  const onChange = useOnChange<boolean>(fieldPath);
  const inputFieldConfigProps = useInputFieldConfigProps(fieldPath);
  const onFocus = useOnFocus();

  return useMemo(() => {
    const otherProps = merge(inputFieldConfigProps, rest);
    return noFieldContainer ? (
      <Checkbox
        name={fieldPath}
        checked={checked}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          if (!onChange) {
            return;
          }
          onChange(event.target.checked);
        }}
        onFocus={onFocus}
        {...otherProps}
      />
    ) : (
      <CheckboxField
        name={fieldPath}
        checked={checked}
        onBlur={onBlur}
        onChange={onChange}
        labelI18n={labelI18n}
        onFocus={onFocus}
        modifiers={modifiers}
        {...otherProps}
      />
    );
  }, [
    fieldPath,
    checked,
    onBlur,
    onChange,
    labelI18n,
    inputFieldConfigProps,
    noFieldContainer,
    rest,
  ]);
};

FastFormCheckbox.defaultProps = { noFieldContainer: false };

export default FastFormCheckbox;
