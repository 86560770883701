import { useIsLoading } from '@mmw/redux-store-auth-api-login-by-password/hooks';
import { getFieldPaths } from '@mmw/utils-object-utils';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { F, U } from 'ts-toolbelt';

import { authenticateWithGoogleStartAction } from '../actions';

export type LoginForm = {
  applicationId: string;
  googleIapJwt: string;
  applicationBaseUrl: U.Nullable<string>;
  applicationPath: U.Nullable<string>;
};

export const LOGIN_FORM_INITIAL_VALUES = {
  applicationId: '',
  googleIapJwt: '',
  applicationBaseUrl: '',
  applicationPath: '',
};

export const LOGIN_FORM_FIELD_PATHS = getFieldPaths(LOGIN_FORM_INITIAL_VALUES);

type useLoginWithGoogle = F.Function<[LoginForm]>;

const useLoginWithGoogleStart = (): useLoginWithGoogle => {
  const dispatch = useDispatch();
  const loading = useIsLoading();
  return useCallback(
    (data: LoginForm) => {
      if (loading || !data) return;
      const {
        applicationId,
        googleIapJwt,
        applicationBaseUrl,
        applicationPath,
      } = data;
      dispatch(
        authenticateWithGoogleStartAction(
          applicationId,
          googleIapJwt,
          applicationBaseUrl,
          applicationPath,
        ),
      );
    },
    [loading, dispatch],
  );
};

export default useLoginWithGoogleStart;
