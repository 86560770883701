const namespace = 'jura';
const auditNamespace = 'audit';
const wizardNamespace = 'wizard';

const I18N = {
  STEPS_TITLE: {
    key: `${namespace}.${auditNamespace}.${wizardNamespace}.stepsTitle`,
    message: 'Sales audit at the specialist dealer',
  },
  RESUME_TITLE: {
    key: `${namespace}.${auditNamespace}.registrationResume`,
    message: 'Registration resume',
  },
  SELECT_A_STORE: {
    key: `${namespace}.${auditNamespace}.chooseAStore`,
    message: 'Select a store',
  },
  MAINTENANCE_PPOS: {
    key: `${namespace}.${auditNamespace}.maintenancePpos`,
    message: 'Instandhaltung PPOS',
  },
  NEEDS_REVISION: {
    key: `${namespace}.auditCampaignStatus.needsRevision`,
    message: 'Must be revised',
  },
  COMPLETED_AUDIT_MESSAGE: {
    key: `${namespace}.${auditNamespace}.completedAuditProcessMessage`,
    message:
      'Done! You have successfully completed the audit and the data collected has been sent to sales promotion. Many Thanks!',
  },
  PPOS: {
    GENERAL: {
      TITLE: {
        key: `${namespace}.${auditNamespace}.ppos.general.title`,
        message: 'Allgemeines',
      },
      CONTENT: {
        key: `${namespace}.${auditNamespace}.ppos.general.content`,
        message:
          'Der PPOS wurde dem Fachhändler von JURA in einwandfreiem Zustand übergeben und bestückt. Er ist vom Fachhändler stets in diesem Zustand zu halten.<br /><br />Der JURA Außendienst sowie das Verkaufspersonal überprüfen und dokumentieren dies mittels folgender Checkliste und Fotos monatlich.<br /><br /><strong>Ist der Punkt auf der Checkliste erfüllt / vorhanden: Es wird ein Haken gesetzt.</strong><br /><br /><strong>Ist der Punkt auf der Checkliste nicht erfüllt / fehlt: Das Feld wird frei gelassen. </strong><br /><br />Für den Fall, dass einer der Punkte nicht erfüllt ist, werden umgehend Maßnahmen zur Behebung ergriffen. Dies beinhaltet, ggf. Material nachzubestellen bzw. die Verkaufsförderung zu informieren.',
      },
    },
    GUIDELINES: {
      TITLE: {
        key: `${namespace}.${auditNamespace}.ppos.guidelines.title`,
        message: 'Tichtlinien',
      },
      CONTENT: {
        key: `${namespace}.${auditNamespace}.ppos.guidelines.content`,
        message:
          'Es werden die Richtlinien eingehalten, welche im Folder »JURA Premium POS – Leitfaden für Fach- händler« aufgeführt sind. Dieser liegt dem Premium POS bei.<br /><br />Sollte der Fachhändler weitere JURA Shopmöbel positioniert haben (Shopwände, Präsentationstische, POS-Möbel), so werden die Richtlinien eingehalten, welche im »Leitfaden für Markenshop Partner« aufgeführt sind.',
      },
    },
  },
};

export default I18N;
