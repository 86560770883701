import { PERSON as VALIDATION } from '@mmw/constants-fields-validations';
import {
  DEFAULT as I18N,
  PERSON as PERSON_I18N,
} from '@mmw/constants-i18n-validations';
import { Salutation, Title } from '@mmw/constants-titles-salutations';
import {
  CHARACTER_REPETITION,
  NUMBERS,
  SPECIAL_ALONE_CHARACTERS,
  SPECIAL_CHARACTERS_AND_WORDS,
} from '@mmw/constants-validation-yup-tests';
import isEmpty from 'lodash/isEmpty';
import { date, string } from 'yup';

const FIRSTNAME = string()
  .ensure()
  .max(VALIDATION.FIRSTNAME.MAX_LENGTH)
  // @ts-ignore
  .test(NUMBERS)
  // @ts-ignore
  .test(CHARACTER_REPETITION)
  // @ts-ignore
  .test(SPECIAL_ALONE_CHARACTERS)
  // @ts-ignore
  .test(SPECIAL_CHARACTERS_AND_WORDS);

const LASTNAME = FIRSTNAME;

const BIRTHDATE = date().nullable().default(null);

const EMAIL = string()
  .nullable()
  .max(VALIDATION.EMAIL.MAX_LENGTH)
  .email(PERSON_I18N.EMAIL.INVALID.key);

const PHONE = string().ensure().max(VALIDATION.PHONE.MAX_LENGTH);

const PHONEWORK = string().ensure().max(VALIDATION.PHONEWORK.MAX_LENGTH);

const PHONEMOBILE = string().ensure().max(VALIDATION.PHONEMOBILE.MAX_LENGTH);

const PHONEMOBILE_REQUIRED = PHONEMOBILE.required(I18N.REQUIRED.key);

const PHONEMOBILEWORK = string()
  .ensure()
  .max(VALIDATION.PHONEMOBILEWORK.MAX_LENGTH);

const FAX = string().ensure().max(VALIDATION.FAX.MAX_LENGTH);

const GENDER = string().ensure().max(VALIDATION.FAX.MAX_LENGTH);

const DOMKEYTITLE = string()
  .ensure()
  .default(Title.EMPTY)
  .transform(value => (isEmpty(value) ? Title.EMPTY : value))
  .max(VALIDATION.DOMKEYTITLE.MAX_LENGTH);

const DOMKEYSALUTATION = string()
  .ensure()
  .default(Salutation.EMPTY)
  .required(I18N.REQUIRED.key) // REQUIRED BY DEFAULT ON API
  .max(VALIDATION.DOMKEYSALUTATION.MAX_LENGTH);

const ERP_PERSONKEY = string()
  .ensure()
  .max(VALIDATION.ERP_PERSONKEY.MAX_LENGTH);

// person table on mmw database
export const PERSON = {
  FIRSTNAME,
  FIRSTNAME_REQUIRED: FIRSTNAME.required(I18N.REQUIRED.key),
  LASTNAME,
  LASTNAME_REQUIRED: LASTNAME.required(I18N.REQUIRED.key),
  BIRTHDATE,
  BIRTHDATE_REQUIRED: BIRTHDATE.required(I18N.REQUIRED.key),
  EMAIL,
  EMAIL_REQUIRED: EMAIL.required(I18N.REQUIRED.key),
  PHONE,
  PHONEWORK,
  PHONEMOBILE,
  PHONEMOBILE_REQUIRED,
  PHONEMOBILEWORK,
  FAX,
  GENDER,
  DOMKEYTITLE,
  DOMKEYSALUTATION,
  ERP_PERSONKEY,
};

export default PERSON;
