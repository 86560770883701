import getAvailableStores from './getAvailableStores';
import getLoggedTraderData from './getLoggedTraderData';
import getOrgunitGroupNamesEpic from './getOrgunitGroupNames';
import getSalesmen from './getSalesmen';
import updateLoggedUserProfilePicture from './updateLoggedUserProfilePicture';
import watchAuthentication from './watchAuthentication';
import watchBranchCreation from './watchBranchCreation';

export {
  getAvailableStores,
  getLoggedTraderData,
  getOrgunitGroupNamesEpic,
  getSalesmen,
  updateLoggedUserProfilePicture,
  watchAuthentication,
  watchBranchCreation,
};

const epics = [
  getLoggedTraderData,
  getAvailableStores,
  getSalesmen,
  watchAuthentication,
  watchBranchCreation,
  getOrgunitGroupNamesEpic,
  updateLoggedUserProfilePicture,
];

export type Epics = typeof epics;
export default epics;
