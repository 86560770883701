import { getAuditStatusColor, useAuditTranslatedStatus } from '@audit/utils';
import { Modifiers } from '@ui-system/interfaces';
import * as React from 'react';

import { AuditStatusCardView } from './AuditStatusCardView';

interface Props {
  status: string;
  loading: boolean;
  modifiers?: Modifiers;
}

export const AuditStatusCard: React.FC<Props> = ({
  status,
  loading,
  modifiers,
  ...rest
}: Props) => {
  const getStatusText = useAuditTranslatedStatus(status);
  const color = getAuditStatusColor(status);
  return (
    <AuditStatusCardView
      text={getStatusText()}
      color={color}
      loading={loading}
      modifiers={`white, bold, ${modifiers}`}
      {...rest}
    />
  );
};
