import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { F } from 'ts-toolbelt';

import { carouselSearchAudits } from '../actions';
import useSearchUuid from './useSearchUuid';

const usePreviousCarousel = (): F.Function => {
  const searchUuid = useSearchUuid();
  const dispatch = useDispatch();
  return useCallback(
    () => dispatch(carouselSearchAudits.select.previous({ searchUuid })),
    [dispatch, searchUuid],
  );
};

export default usePreviousCarousel;
