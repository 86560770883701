import { BasePath } from '@di/core';
import defaultApiV2, { ApiResponse } from '@mmw/api-v2';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import { Pagination } from '@mmw/services-core-common/types';
import autoBind from 'auto-bind';

import logger from './log';
import getPaths, { CONSUMER_BASE_PATH_TOKEN } from './paths';
import {
  ConsumerDistanceJSON,
  ConsumerJSON,
  ConsumerRequestJSON,
} from './types';

type Api = typeof defaultApiV2;

type ConsumerServiceOptions = {
  apiv2?: Api;
  authenticationService: AuthenticationService;
};

class ConsumerService extends BasePath {
  api: Api;

  authenticationService: AuthenticationService;

  constructor({ apiv2, authenticationService }: ConsumerServiceOptions) {
    super(CONSUMER_BASE_PATH_TOKEN);
    this.api = apiv2 || defaultApiV2;
    this.authenticationService = authenticationService;
    autoBind(this);
  }

  async saveConsumer(
    consumer: ConsumerJSON,
    branchID: number,
  ): Promise<ConsumerJSON> {
    logger.debug('Trying to save consumer id=%s', consumer.consumerID);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const consumerToUpdate = {
        ...consumer,
        person: {
          ...consumer.person,
          domkeysalutation: consumer.person.domkeysalutation || 'EMPTY',
          domkeytitle: consumer.person.domkeytitle || 'EMPTY',
        },
      };
      const response: ApiResponse<ConsumerJSON> = await this.api.post(
        getPaths(this.getBasePath()).SaveConsumerPath(branchID),
        consumerToUpdate,
        { headers },
      );
      const { data } = response;
      logger.info('Successfully saved consumer, id=%s', consumer.consumerID);
      return data;
    } catch (error) {
      logger.error(
        'Error when updating project id=, error=%O',
        consumer.consumerID,
        error,
      );
      throw error;
    }
  }

  async getConsumersByRequest(
    consumersReq: ConsumerRequestJSON,
    branchID?: number,
  ): Promise<Pagination<ConsumerJSON>> {
    logger.debug('Trying to get consumers by request, branchID=', branchID);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Pagination<ConsumerJSON>> =
        await this.api.post(
          getPaths(this.getBasePath()).GetConsumersPath(branchID),
          consumersReq,
          {
            headers,
          },
        );
      const { data } = response;
      logger.info('Successfully got consumers by request', data.total);
      return data;
    } catch (error) {
      logger.error('Error when getting consumers by request, error=%O', error);
      throw error;
    }
  }

  async editConsumer(
    consumerReq: ConsumerJSON,
    branchID: number,
  ): Promise<ConsumerJSON> {
    logger.debug('Trying to edit consumer id=%s', consumerReq.consumerID);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ConsumerJSON> = await this.api.post(
        getPaths(this.getBasePath()).SaveConsumerPath(branchID),
        consumerReq,
        { headers },
      );
      const { data } = response;
      logger.info('Successfully edit consumer, id=%s', consumerReq.consumerID);
      return data;
    } catch (error) {
      logger.error('Error when getting consumers, error=%O', error);
      throw error;
    }
  }

  async getConsumerDistanceToStore(
    consumerID: number,
    storeID: number,
    salesOrgBrandID: number,
  ): Promise<number> {
    logger.debug(
      `Trying to get consumer=${consumerID} distance to store=%s`,
      storeID,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ConsumerDistanceJSON> = await this.api.get(
        getPaths(this.getBasePath()).GetConsumerDistanceToStorePath(
          salesOrgBrandID,
          storeID,
          consumerID,
        ),
        { headers },
      );
      const { data } = response;

      if (!data.success) {
        logger.error(
          `Consumer distance check fail for consumer=%s`,
          consumerID,
          'and store=%s',
          storeID,
        );
      } else {
        logger.info(
          `Successfully got the consumer=%s${consumerID} distance to store=%s${storeID}`,
          storeID,
        );
      }

      return data.distanceKm;
    } catch (error) {
      logger.error('Error when getting consumer distance to store', error);
      throw error;
    }
  }
}

export default ConsumerService;
