import defaultAuthApi, { ApiResponse } from '@mmw/api-auth';
import { SecurityScopeNames } from '@mmw/services-auth-api-authentication/types';
import autoBind from 'auto-bind';

import logger from './log';
import { ValidateConsumerMailStatus } from './types';

type Api = typeof defaultAuthApi;

type ConsumerAccountServiceOptions = {
  authApi?: Api;
};

class ConsumerAccountService {
  api: Api;

  constructor({ authApi }: ConsumerAccountServiceOptions) {
    this.api = authApi || defaultAuthApi;
    autoBind(this);
  }

  async validateConsumerMailAvailability(
    email: string,
    scopeNames: SecurityScopeNames[],
  ): Promise<ValidateConsumerMailStatus> {
    logger.debug('Trying to valitade informed email');
    try {
      const response: ApiResponse<ValidateConsumerMailStatus> =
        await this.api.post('/status/validate-userid', {
          userid: email,
          scopeNames,
        });
      const { data } = response;
      logger.info(
        'Succefully validated email availability as=%s',
        data?.success,
      );
      return data;
    } catch (error) {
      logger.error('Failed to validate email availability, error=%O', error);
      throw error;
    }
  }
}

export default ConsumerAccountService;
