import {
  RegistrationResponseJSON,
  SearchRegistrationRequestFiltersJSON,
} from '@mmw/services-core-trader-registration/types';

import { AuditState } from './state/AuditState';

export const RESET = '@audit/audits-search/RESET';
export const CHANGE_LIMIT = '@audit/audits-search/CHANGE_LIMIT';
export const RESET_REQUEST_FIELDS = '@audit/audits-search/RESET_REQUEST_FIELDS';
export const PAGINATE = '@audit/audits-search/PAGINATE';

export const NAMESPACE = 'audits';

export interface RootState {
  [NAMESPACE]: AuditState<
    SearchRegistrationRequestFiltersJSON,
    RegistrationResponseJSON
  >;
}
