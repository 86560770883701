import { createAsyncActions } from '@redux-async-module/actions-utils';

import { RetrieveMeta, RetrieveStartPayload } from './types';

export function createRetrieveActions<ItemType>(
  namespace: string,
  actionName: string,
) {
  return createAsyncActions<
    RetrieveStartPayload,
    ItemType,
    Error,
    RetrieveMeta
  >(namespace, actionName);
}

// XXX: this is to find implicit types
// wrapped has no explicit return type so we can infer it
class Wrapper<ItemType> {
  // eslint-disable-next-line class-methods-use-this
  createRetrieveActions(namespace: string, actionName: string) {
    return createRetrieveActions<ItemType>(namespace, actionName);
  }
}

export type RetrieveActions<ItemType> = ReturnType<
  Wrapper<ItemType>['createRetrieveActions']
>;
