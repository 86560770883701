import { createDI } from '@di/core';
import defaultApiV2 from '@mmw/api-v2';
import logger from '@mmw/logging-logger';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import autoBind from 'auto-bind';
import { AxiosResponse } from 'axios';

import {
  GetManuFlowOfGoodsPath,
  GetManuFlowOfGoodsProductsPath,
} from './apiPaths';

type Api = typeof defaultApiV2;

type ManuFlowOfGoodsServiceOptions = {
  apiv2?: Api;
  authenticationService: AuthenticationService;
};

class ManuFlowOfGoodsService {
  api: Api;

  authenticationService: AuthenticationService;

  static createDI = createDI<ManuFlowOfGoodsService>;

  constructor({ apiv2, authenticationService }: ManuFlowOfGoodsServiceOptions) {
    this.api = apiv2 || defaultApiV2;
    this.authenticationService = authenticationService;
    autoBind(this);
  }

  async searchFlowOfGoods(request, language): Promise<any> {
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: AxiosResponse<any> = await this.api.get(
        GetManuFlowOfGoodsPath(request, language),
        {
          headers,
        },
      );
      return response.data;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  }

  async searchProducts(request, language): Promise<any> {
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: AxiosResponse<any> = await this.api.get(
        GetManuFlowOfGoodsProductsPath(request, language),
        {
          headers,
        },
      );
      return response.data;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  }
}

export default ManuFlowOfGoodsService;
