import {
  CHECKBOX,
  INPUT,
  MULTI_SWITCH,
  QuestionGroupDisplayType,
  RADIO,
} from '@mmw/constants-question-group-display-types';
import { ITEM_TERMS } from '@mmw/constants-question-group-types';
import { EMPTY_OBJECT, EMPTY_STRING } from '@mmw/constants-utils';
import contextualConfig from '@mmw/contextual-config';
import {
  QuestionGroupJSON,
  QuestionJSON,
} from '@mmw/services-core-common/types';
import { CampaignItemJSON } from '@mmw/services-core-trader-registration/types';
import {
  concat,
  each,
  filter,
  find,
  get,
  head,
  includes,
  isArray,
  isString,
  map,
  mapKeys,
  toString,
} from 'lodash';

const { logger } = contextualConfig.application;

const CHECKBOX_CHECKED_VALUES = [true, 'true'];

export const isQuestionGroupAnswered = (
  questionGroup?: QuestionGroupJSON,
): boolean => {
  if (!questionGroup) {
    logger.debug('Question group is undefined, this should not happen');
    return false;
  }
  const questionAnswers = map(questionGroup.questions, q => q.answer);
  switch (questionGroup.displayType) {
    case RADIO: {
      const { answer: currentAnswer } = questionGroup;
      const availableQuestionAnswersCodes = map(
        questionGroup.questions,
        q => q.code,
      );
      return includes(availableQuestionAnswersCodes, currentAnswer);
    }
    case CHECKBOX: {
      return (
        filter(questionAnswers, qa => qa === true || qa === 'true').length > 0
      );
    }
    case INPUT: {
      return (
        filter(questionAnswers, qa => isString(qa) && qa.length > 0).length > 0
      );
    }
    case MULTI_SWITCH: {
      return (
        filter(questionAnswers, qa => isString(qa) && qa.length > 0).length > 0
      );
    }
    default: {
      logger.error('Question group type not implemented', questionGroup);
      return false;
    }
  }
};

export function isQuestionGroupAnsweredIfRequired(
  questionGroup?: QuestionGroupJSON,
): boolean {
  const isAnswered = isQuestionGroupAnswered(questionGroup);
  const isRequired = questionGroup != null && questionGroup.required;
  return !isRequired || isAnswered;
}

export const isCheckboxQuestionChecked = (
  answer: string | boolean | null | void,
): boolean => includes(CHECKBOX_CHECKED_VALUES, answer);

export const normalizeAnswer = (
  answer: string | boolean | null | void,
  displayType: QuestionGroupDisplayType,
): string | boolean => {
  switch (displayType) {
    case CHECKBOX:
      return isCheckboxQuestionChecked(answer);
    default:
      return toString(answer);
  }
};

export const normalizeQuestionsInQuestions = (
  questions: QuestionJSON[],
  displayType: QuestionGroupDisplayType,
): QuestionJSON[] =>
  map(questions, q => ({
    ...q,
    answer: normalizeAnswer(q.answer, displayType),
  }));

export const normalizeQuestionsInQuestionGroups = (
  questionGroups: QuestionGroupJSON[],
): QuestionGroupJSON[] =>
  map(questionGroups, qg => ({
    ...qg,
    questions: normalizeQuestionsInQuestions(qg.questions, qg.displayType),
  }));

export const findMatchingQuestionGroup = (
  questionGroup: QuestionGroupJSON,
  questionGroups: Array<QuestionGroupJSON>,
): QuestionGroupJSON | undefined =>
  find(questionGroups, qg => qg.code === questionGroup.code);

export const findMatchingQuestion = (
  question: QuestionJSON,
  questions: Array<QuestionJSON>,
): QuestionJSON | undefined => find(questions, qg => qg.code === question.code);

export const getAnswer = (
  question: QuestionJSON | null | void,
  questionGroup: QuestionGroupJSON,
): boolean | string =>
  normalizeAnswer(get(question, 'answer'), questionGroup.displayType);

export const getQuestionGroupAnswer = (
  questionGroup: QuestionGroupJSON | null | void,
): string => {
  if (!questionGroup) {
    return EMPTY_STRING;
  }
  return toString(
    normalizeAnswer(questionGroup.answer, questionGroup.displayType),
  );
};

type UpdatedPath = Record<string, string | boolean>;

type UpdateResult = {
  updated: boolean;
  updatedPaths: UpdatedPath;
};

const updateQuestionGroupAnswerForRadioOrInput = (
  answer: string | boolean,
  questionGroup: QuestionGroupJSON,
): UpdateResult => {
  if (answer === questionGroup.answer) {
    return {
      updated: false,
      updatedPaths: EMPTY_OBJECT,
    };
  }
  return {
    updated: true,
    updatedPaths: {
      answer: normalizeAnswer(answer, questionGroup.displayType),
    },
  };
};

const updateQuestionGroupAnswerForCheckbox = (
  answer: string | boolean,
  questionGroup: QuestionGroupJSON,
  question: QuestionJSON,
): UpdateResult => {
  let updated = false;
  let updatedPaths = {};
  each<QuestionJSON>(
    questionGroup.questions,
    (q: QuestionJSON, index: number) => {
      if (q.code !== question.code) {
        return;
      }
      if (q.answer === answer) {
        return;
      }
      updated = true;
      updatedPaths = {
        ...updatedPaths,
        [`questions.${index}.answer`]: normalizeAnswer(
          answer,
          questionGroup.displayType,
        ),
      };
    },
  );
  return {
    updated,
    updatedPaths,
  };
};

export const updateQuestionGroupAnswer = (
  answer: string | boolean,
  questionGroup: QuestionGroupJSON,
  // XXX: when you only want to update question group (RADIO)
  question: QuestionJSON | null,
  questionGroups: Array<QuestionGroupJSON>,
): UpdateResult => {
  let updated = false;
  let updatedPaths = {};
  each(questionGroups, (qg, index) => {
    if (questionGroup.code !== qg.code) {
      return;
    }
    let result: UpdateResult = {
      updated: false,
      updatedPaths: EMPTY_OBJECT,
    };
    switch (questionGroup.displayType) {
      case CHECKBOX: {
        if (question == null) {
          throw new Error(
            'Method is being called incorrectly, question needs to be informed',
          );
        }
        result = updateQuestionGroupAnswerForCheckbox(answer, qg, question);
        break;
      }
      default:
        result = updateQuestionGroupAnswerForRadioOrInput(answer, qg);
        break;
    }
    if (result.updated) {
      updated = true;
      updatedPaths = {
        ...updatedPaths,
        ...mapKeys(
          result.updatedPaths,
          (value, key) => `questionGroups.${index}.${key}`,
        ),
      };
    }
  });
  return {
    updated,
    updatedPaths,
  };
};

export const updateTermsQuestion = (
  answer: boolean,
  questionGroups: QuestionGroupJSON[],
): QuestionGroupJSON[] => {
  const otherQuestions = filter(questionGroups, qg => qg.type !== ITEM_TERMS);

  const terms: QuestionGroupJSON[] = filter(
    questionGroups,
    qg => qg.type === ITEM_TERMS,
  );
  const termsAnswer: QuestionJSON | undefined = head(
    get(head(terms), 'questions'),
  );
  if (termsAnswer) {
    termsAnswer.answer = answer;
    // XXX: commented below looks broken
    // terms.questions = [termsAnswer];
  }
  return concat([], terms, otherQuestions);
};

function matchesQuestionCode(
  question: QuestionJSON,
  questionCode: string | string[],
) {
  if (isArray(questionCode)) {
    return includes(questionCode, question.code);
  }
  return question.code === questionCode;
}

export function answerQuestionByCampaignItemsAndCode(
  campaignItems: Array<CampaignItemJSON>,
  questionCode: string | string[],
  answer: string | boolean,
): Array<CampaignItemJSON> {
  return map(campaignItems, ci => {
    const foundQg = find(ci.questionGroups, qg => {
      const foundQ = find(qg.questions, q =>
        matchesQuestionCode(q, questionCode),
      );
      return foundQ != null;
    });
    if (!foundQg) {
      return ci;
    }
    const questionGroups = map(ci.questionGroups, qg => ({
      ...qg,
      questions: map(qg.questions, q => {
        if (matchesQuestionCode(q, questionCode)) {
          return {
            ...q,
            answer,
          };
        }
        return q;
      }),
    }));
    return {
      ...ci,
      questionGroups,
    };
  });
}
