import { use } from '@mmw/ui-theme/utils';
// import { Img } from 'react-image';
import styled from 'styled-components';

const Banner = styled.div(props => use(props, 'page.banner'));

const Image = styled.img(props => use(props, 'page.banner.image'));

interface BannerStatic {
  Image: typeof Image;
}

const BannerWrapper = Banner as typeof Banner & BannerStatic;
BannerWrapper.Image = Image;

export default BannerWrapper;
