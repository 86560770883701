import colors from '@mmw/ui-theme/modifiers/color/background';

import containers from './containers';
import contentStyle from './content';
import dividerStyle from './divider';
import header from './header';

export default {
  content: {
    style: contentStyle,
  },
  divider: {
    style: dividerStyle,
    modifiers: colors(),
  },
  header,
  ...containers,
};
