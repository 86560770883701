import style from './style';

const modifiers = {
  error: undefined,
  alert: undefined,
};

const field = {
  style: () => `
    display: flex;
  `,
  modifiers: undefined,
};

const container = {
  style: undefined,
  modifiers: undefined,
};

const label = {
  style: undefined,
  modifiers: undefined,
};

export default {
  style,
  modifiers,
  field,
  label,
  container,
};
