import { I18nShape } from '@mmw/constants-i18n';
import useDetectUserLeaving from '@mmw/ui-web-hooks-use-detect-user-leaving-app';
import { useTranslate } from '@mmw/utils-text-utils/hooks';
import React from 'react';
import { Prompt } from 'react-router-dom';

type Props = {
  i18n: I18nShape;
  shouldPrompt: boolean;
};

const UserLeavingPrompt: React.FC = () => {
  useDetectUserLeaving();
  return null;
};

const LeaveFormRoutePrompt: React.FC<Props> = ({
  i18n,
  shouldPrompt,
}: Props) => {
  const translate = useTranslate();
  const message = translate(i18n);

  return (
    <>
      {shouldPrompt && <UserLeavingPrompt />}
      <Prompt message={message} when={shouldPrompt} />
    </>
  );
};

export default LeaveFormRoutePrompt;
