import contextualConfig from '@mmw/contextual-config';
import {
  useAccessToken,
  useLoggedUserid,
} from '@mmw/redux-store-auth-api-authentication/hooks';
import { BadCredentialsError } from '@mmw/redux-store-payload-error/constants';
import { singleton as tokenParser } from '@mmw/services-auth-api-token-parser';
import { getAuthenticationService, getSmsService } from '@mmw/services-holder';
import { createStoreModule } from '@zustand-store/core';
import noop from 'lodash/noop';
import { useCallback, useEffect, useMemo } from 'react';
import { U } from 'ts-toolbelt';

import {
  PhoneNumberData,
  PhoneVerificationResponse,
  RequestPhoneVerificationParams,
  RequestVerifyUserPhoneParams,
  SmsPhoneVerificationStore,
} from './types';

const { logger } = contextualConfig.application;

const service = getSmsService();

const INITIAL_STATE = {
  isPhoneValid: null,
  isPhoneAlreadyInUse: null,
  previousValidPhone: null,
  userVerifiedPhone: null,
  userEmail: null,
  sendVerificationCodeSuccess: null,
  notAuthenticatedOperationToken: null,
  loading: false,
  error: null,
  phoneNumberLookup: noop,
  requestSmsVerificationCode: noop,
  resetPhoneNumberValidation: noop,
  resetPhoneVerificationReqStatus: noop,
  resetPhoneRegistrationStatus: noop,
  verifyUserPhoneByTan: noop,
  getUserSecurePhone: noop,
  registerUserSecurePhone: noop,
};

export const useSmsPhoneVerificationStore =
  createStoreModule<SmsPhoneVerificationStore>({
    name: 'sms-phone-verification',
    initialState: INITIAL_STATE,
    disablePersist: true,
    // persistOptions: {
    //   partialize: state => ({
    //     userVerifiedPhone: state.userVerifiedPhone,
    //     userEmail: state.userEmail,
    //   }),
    // },
    initializer: (set, get) => ({
      ...INITIAL_STATE,
      getUserSecurePhone: async (
        accessToken: U.Nullable<string>,
        email?: U.Nullable<string>,
      ) => {
        if (!accessToken) return;
        set(() => ({ loading: true }));
        const parsedToken = await tokenParser.parseToken(accessToken);
        const currentUserEmail = get().userEmail;
        set(() => ({
          userVerifiedPhone: parsedToken.verifiedPhone,
          userEmail: currentUserEmail || email,
          error: null,
          loading: false,
        }));
      },
      phoneNumberLookup: async ({ phone, country }) => {
        set(() => ({ loading: true }));
        try {
          const result = await service.lookupPhoneNumber(phone, country);
          const isPhoneValid = !result.alreadyRegistered && result.valid;
          set(() => ({
            isPhoneValid,
            previousValidPhone: isPhoneValid
              ? {
                  phone: result.phoneNumber,
                  country: result.countryCode,
                }
              : null,
            isPhoneAlreadyInUse: result.alreadyRegistered,
            error: null,
            loading: false,
          }));
        } catch (err) {
          logger.error('Failed on trying to lookup for phone number', err);
          set(() => ({ error: err, loading: false, isPhoneValid: false }));
        }
      },
      requestSmsVerificationCode: async ({
        phone,
        country,
        userEmail,
        operationId,
        recaptchaToken,
      }: RequestPhoneVerificationParams) => {
        set(() => ({ loading: true }));
        const userPhoneInfo =
          get().userVerifiedPhone || get().previousValidPhone;
        try {
          const userPhone = userPhoneInfo?.phone || phone;
          const phoneCountry = userPhoneInfo?.country || country;
          if (!userPhoneInfo?.phone && phone && country) {
            set(() => ({
              userVerifiedPhone: {
                phone,
                country,
              },
            }));
          }
          if (!userPhone || !phoneCountry) {
            set(() => ({
              error: new Error(
                'Request verification error, missing user phone number and/or country',
              ),
              loading: false,
            }));
            return;
          }
          const result =
            await getAuthenticationService().requestPhoneVerificationBySms({
              phone: userPhone,
              country: phoneCountry,
              email: userEmail,
              operationId,
              recaptchaResponse: recaptchaToken,
              recaptchaType: 'V3',
            });
          const givenError = result?.errorCode
            ? new Error(result?.errorCode)
            : null;
          set(() => ({
            isPhoneValid: true,
            sendVerificationCodeSuccess: result?.success,
            error: givenError,
            loading: false,
          }));
          if (!result?.success) {
            set(() => ({
              isPhoneValid: false,
              error: givenError,
            }));
          }
        } catch (err) {
          logger.error('Failed on trying to request phone verification', err);
          set(() => ({ error: err, loading: false, isPhoneValid: false }));
        }
      },
      verifyUserPhoneByTan: async ({
        recaptchaToken,
        tan,
        operationId,
        userEmail,
        callback,
      }: RequestVerifyUserPhoneParams) => {
        set(() => ({ loading: true }));
        const userPhoneInfo =
          get().userVerifiedPhone || get().previousValidPhone;
        try {
          const userPhone = userPhoneInfo?.phone;
          const phoneCountry = userPhoneInfo?.country;
          if (!userPhone || !phoneCountry) {
            set(() => ({
              error: new Error(
                'Verification error, missing user phone number and/or country',
              ),
              loading: false,
            }));
            return;
          }
          const phoneNumberData = {
            phone: userPhone,
            country: phoneCountry,
          };
          const result = await getAuthenticationService().verifyPhone({
            ...phoneNumberData,
            tan,
            operationId,
            email: userEmail,
            recaptchaResponse: recaptchaToken,
            recaptchaType: 'V3',
          });
          if (result.success) {
            set(() => ({
              notAuthenticatedOperationToken: result.accessToken,
              error: null,
              loading: false,
            }));
            if (!get().userVerifiedPhone) {
              await get().registerUserSecurePhone({
                ...phoneNumberData,
                notAuthenticatedOperationToken: result.accessToken,
              });
            }
          } else {
            set(() => ({
              error: new BadCredentialsError(result.error),
              loading: false,
            }));
          }
          if (callback) {
            callback({
              notAuthenticatedOperationToken: result.accessToken,
              success: result.success,
            });
          }
        } catch (err) {
          logger.error('Phone number verification error', err);
          set(() => ({ error: err, loading: false }));
        }
      },
      registerUserSecurePhone: async ({
        phone,
        country,
        notAuthenticatedOperationToken,
      }: PhoneNumberData &
        Pick<PhoneVerificationResponse, 'notAuthenticatedOperationToken'>) => {
        set(() => ({ loading: true }));
        try {
          if (!notAuthenticatedOperationToken) return;
          const result = await service.registerSecurePhone(
            phone,
            country,
            notAuthenticatedOperationToken,
          );
          if (result) {
            await getAuthenticationService().refreshAuthentication();
            set(() => ({
              userVerifiedPhone: {
                phone,
                country,
              },
              error: null,
              loading: false,
            }));
          }
        } catch (error) {
          logger.error('Secure phone registration failed, error=%O', error);
          set(() => ({ error, loading: false }));
        }
      },
      resetPhoneNumberValidation: () => {
        set(() => ({
          error: null,
          loading: false,
          isPhoneValid: null,
          isPhoneAlreadyInUse: null,
          previousValidPhone: null,
        }));
      },
      resetPhoneVerificationReqStatus: () => {
        set(() => ({
          error: null,
          loading: false,
          sendVerificationCodeSuccess: null,
        }));
      },
      resetPhoneRegistrationStatus: () => {
        set(() => ({
          error: null,
          loading: false,
          sendVerificationCodeSuccess: null,
          previousValidPhone: null,
          isPhoneValid: null,
        }));
      },
    }),
  });

export function usePhoneLookupValidation() {
  const phoneLookup = useSmsPhoneVerificationStore(
    state => state.phoneNumberLookup,
  );
  return useCallback(
    (data: PhoneNumberData) => {
      if (data.country && data.phone) {
        phoneLookup(data);
      }
    },
    [phoneLookup],
  );
}

export function useRequestPhoneVerificationCall() {
  const requestPhoneVerification = useSmsPhoneVerificationStore(
    state => state.requestSmsVerificationCode,
  );
  return requestPhoneVerification;
}

export function useVerifyUserPhoneByTanCall() {
  const verifyUserPhone = useSmsPhoneVerificationStore(
    state => state.verifyUserPhoneByTan,
  );
  return verifyUserPhone;
}

export function useIsPhoneValid() {
  const isPhoneValid = useSmsPhoneVerificationStore(
    state => state.isPhoneValid,
  );
  return isPhoneValid;
}

export function useIsPhoneAlreadyInUse() {
  const isPhoneAlreadyInUse = useSmsPhoneVerificationStore(
    state => state.isPhoneAlreadyInUse,
  );
  return isPhoneAlreadyInUse;
}

export function useVerificationCodeRequestStatus() {
  const verificationCodeSent = useSmsPhoneVerificationStore(
    state => state.sendVerificationCodeSuccess,
  );
  return verificationCodeSent;
}

export function useUserVerifiedPhone() {
  const userVerifiedPhone = useSmsPhoneVerificationStore(
    state => state.userVerifiedPhone,
  );
  return userVerifiedPhone;
}

export function usePreviousValidPhone() {
  const userPreviousValidPhone = useSmsPhoneVerificationStore(
    state => state.previousValidPhone,
  );
  return userPreviousValidPhone;
}

export function useIsLoading() {
  const isLoading = useSmsPhoneVerificationStore(state => state.loading);
  return isLoading;
}

export function useError() {
  const error = useSmsPhoneVerificationStore(state => state.error);
  return error;
}

export function useIsPhoneVerified() {
  const notAuthToken = useSmsPhoneVerificationStore(
    state => state.notAuthenticatedOperationToken,
  );
  if (notAuthToken == null) return null;
  return !!notAuthToken;
}

export function useGetUserSecurePhoneOnLogin() {
  const currentUserVerifiedPhone = useUserVerifiedPhone();
  const accessToken = useAccessToken();
  const getUserSecurePhone = useSmsPhoneVerificationStore(
    state => state.getUserSecurePhone,
  );
  const loggedUserEmail = useLoggedUserid();
  const currentUserEmail = useSmsPhoneVerificationStore(
    state => state.userEmail,
  );

  useEffect(() => {
    if (!currentUserVerifiedPhone && loggedUserEmail === currentUserEmail) {
      getUserSecurePhone(accessToken);
    } else if (
      !currentUserVerifiedPhone &&
      loggedUserEmail !== currentUserEmail
    ) {
      getUserSecurePhone(accessToken, loggedUserEmail);
    }
  }, [loggedUserEmail]);
}

export function useReset() {
  const resetPhoneVerificationReqStatus = useSmsPhoneVerificationStore(
    state => state.resetPhoneVerificationReqStatus,
  );
  const resetPhoneRegistrationStatus = useSmsPhoneVerificationStore(
    state => state.resetPhoneRegistrationStatus,
  );
  const resetPhoneNumberValidation = useSmsPhoneVerificationStore(
    state => state.resetPhoneNumberValidation,
  );
  return useMemo(
    () => ({
      resetPhoneNumberValidation,
      resetPhoneVerificationReqStatus,
      resetPhoneRegistrationStatus,
    }),
    [
      resetPhoneNumberValidation,
      resetPhoneVerificationReqStatus,
      resetPhoneRegistrationStatus,
    ],
  );
}
