export default {
  overlay: {
    style: ({ visible, height }) => `
      width: 100%;
      z-index: 1021;
      align-items: center;
      justify-content: center;
      display: ${visible ? 'flex' : 'none'}
      // eslint-disable-next-line no-nested-ternary
      ${height ? `height: ${height}` : ''} 
    `,
  },
  svg: {
    style: ({ theme, color }) => `
      animation: rotate 2s linear infinite;
      width: 100px;
      height: 100px;
      & .path {
        stroke: ${theme.colors.pallet.primary || theme.colors.pallet[color]};
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
      }
      @keyframes rotate {
        100% {
          transform: rotate(360deg);
        }
      }
      @keyframes dash {
        0% {
          stroke-dasharray: 1, 150;
          stroke-dashoffset: 0;
        }
        50% {
          stroke-dasharray: 90, 150;
          stroke-dashoffset: -35;
        }
        100% {
          stroke-dasharray: 90, 150;
          stroke-dashoffset: -124;
        }
      }
    `,
  },
};
