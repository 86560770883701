import { Row } from '@mmw/hybrid-ui-structure-containers';
import { use } from '@mmw/ui-theme/utils';
import Nav from '@mmw/ui-web-blocks-nav-bar';
import { useIsPhone } from '@mmw/ui-web-hooks-use-media-query';
import {
  useNavigate,
  useRouter,
} from '@mmw/ui-web-libraries-react-router/hooks';
import {
  NavRouteConfig,
  useExtraConfigContext,
} from '@retail/configure-extra-configs';
import { configPathTo } from '@retail/constants-routes';
import useNavigateHome from '@retail/constants-routes/hooks/useNavigateHome';
import HeaderMMW, { MobileHeaderMMW } from '@retail/ui-web-blocks-mmw-header';
import compact from 'lodash/compact';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import noop from 'lodash/noop';
import React from 'react';
import styled from 'styled-components';

import NavBar from './Navbar';

const StyledHeader = styled.header(props => use(props, 'page.header'));

const HeaderContent = styled.div(props => use(props, 'page.header.content'));

const Logo = styled.div(props => use(props, 'page.header.logo'));

type Props = {
  children?: React.ReactNode;
  navBarContent?: React.ReactNode;
  showNavBar?: boolean;
};

const NavHeader: React.FC = () => {
  const { hideMmwMenu } = useExtraConfigContext();
  const isPhone = useIsPhone();
  if (hideMmwMenu) return null;
  if (isPhone) return <MobileHeaderMMW />;
  return <HeaderMMW />;
};

function getCurrentRouteMatch(currentRoute: string, configRoute: string) {
  const modCurrentRoute = currentRoute.split('/');
  const modConfigRoute = configRoute.split('/');
  if (currentRoute === configRoute) {
    return true;
  }
  return !!includes(compact(modCurrentRoute), modConfigRoute[1]);
}

const NavMenu: React.FC = () => {
  const { navRoutes, CustomComponent } = useExtraConfigContext();
  const navigate = useNavigate();
  const { match } = useRouter();
  if (isEmpty(navRoutes)) return null;
  return (
    <Nav>
      <Nav.NavContainer>
        <Row autoWidth>
          {map(navRoutes, (nav: NavRouteConfig) => (
            <Nav.NavItem
              key={nav.route}
              onClick={
                nav.disabledRoute
                  ? noop
                  : () => {
                      if (nav.routeParams) {
                        return navigate(
                          configPathTo(nav.route, nav.routeParams),
                        );
                      }
                      return navigate(nav.route);
                    }
              }
              i18n={nav.titleI18n}
              active={getCurrentRouteMatch(match.path, nav.route)}
              acessoryLeft={nav?.leftNavItemAcessory}
              acessoryRight={nav?.rightNavItemAcessory}
              disabled={!!nav.disabledRoute}
            />
          ))}
        </Row>
        {CustomComponent ? <CustomComponent /> : null}
      </Nav.NavContainer>
    </Nav>
  );
};

const Header: React.FC<Props> = ({
  children,
  navBarContent,
  showNavBar,
}: Props) => {
  const goHome = useNavigateHome();
  const isPhone = useIsPhone();

  return (
    <StyledHeader>
      <HeaderContent>
        {!isPhone && <Logo onClick={goHome} />}
        {children}
        <NavHeader />
      </HeaderContent>
      <NavMenu />
      {isPhone && <Logo onClick={goHome} />}
      {showNavBar ? <NavBar>{navBarContent}</NavBar> : null}
    </StyledHeader>
  );
};

export default Header;
