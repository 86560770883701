import { StyledProps } from '@mmw/ui-theme/types';
import frameworkButton from '@mmw/ui-web-materials-button/theme';

const container = {
  style: () => `
    display: flex;
    width: 100%;
  `,
};

const button = {
  style: ({
    theme,
    selected,
  }: StyledProps<{
    selected: boolean;
  }>) => `
  ${frameworkButton.style({ theme })}
    transition: none;
    padding: 10px;
    ${
      !selected
        ? `
      background-color: ${theme.colors.pallet.light};
      span {
         color: ${theme.colors.pallet.primary};
      }
      border: 2px solid ${theme.colors.pallet.primary};
    `
        : `
      background-color: ${theme.colors.pallet.primary};
      span {
         color: ${theme.colors.pallet.dark};
      }
    `
    }
    &:hover {
      ${
        !selected
          ? `
      background-color: ${theme.colors.pallet.dark};
      span {
         color: ${theme.colors.pallet.primary};
      }
      border: 2px solid ${theme.colors.pallet.primary};
    `
          : `
      background-color: ${theme.colors.pallet.dark};
      span {
         color: ${theme.colors.pallet.primary};
      }
    `
      }
    }
  `,
};

export default {
  container,
  button,
};
