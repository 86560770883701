import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { F } from 'ts-toolbelt';

import { clearErrorAction } from '../actions';

const useClearError = (): F.Function => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(clearErrorAction());
  }, [dispatch]);
};

export default useClearError;
