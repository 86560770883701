import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { changeSelectedStoreAction } from '../actions';

type ChangeStoreFn = (storeID: number) => void;

const useChangeStore = (): ChangeStoreFn => {
  const dispatch = useDispatch();
  return useCallback(
    (storeID: number) => {
      dispatch(changeSelectedStoreAction(storeID));
    },
    [dispatch],
  );
};

export default useChangeStore;
