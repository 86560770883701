import { LegalFormJSON } from '@mmw/services-core-common/types';
import find from 'lodash/find';
import { U } from 'ts-toolbelt';

import { State } from './types';

export const NAMESPACE = 'availableLegalForms';

export const legalFormListSelector = (
  state: RootState,
): State['legalFormList'] => state[NAMESPACE].legalFormList;

export type RootState = {
  [NAMESPACE]: State;
};

export const legalFormByIdSelector = (
  state: RootState,
  id: number,
): U.Nullable<LegalFormJSON> =>
  find(state[NAMESPACE].legalFormList, item => item.id === Number(id));
