import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import { ConsumerProfileJSON } from '@mmw/services-core-consumer-profile/types';
import { getConsumerProfileService } from '@mmw/services-holder';
import { Action } from '@reduxjs/toolkit';
import { ActionsObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { saveConsumerProfileData } from '../actions';

const { logger } = contextualConfig.application;

type Output =
  | ReturnType<typeof saveConsumerProfileData.success>
  | ReturnType<typeof saveConsumerProfileData.error>;

const saveConsumerProfileDataEpic = (
  action$: ActionsObservable<Action & { payload: ConsumerProfileJSON }>,
): Observable<Output> =>
  action$.pipe(
    ofType(saveConsumerProfileData.start.match),
    tap(() => logger.debug('Trying to save consumer personal data')),
    mergeMap(({ payload: user }) =>
      from(getConsumerProfileService().saveConsumerProfileData(user)).pipe(
        map(data => saveConsumerProfileData.success(data, null)),
        catchError(error => of(saveConsumerProfileData.error(error, null))),
      ),
    ),
  );

export default saveConsumerProfileDataEpic;
