const measure = {
  xxlarge: '3.5rem',
  xlarge: '2.5rem',
  large: '2.0rem',
  medium: '1.7rem',
  small: '1.2rem',
  smaller: '0.9rem',
};

const variable = {
  measure,
};

export default variable;
