import { CreateIssueJSON } from '@issue/interfaces';
import { ManufacturerAuditStatusOption } from '@manufacturer/audits-ui/types';
import { RegistrationResponseJSON } from '@mmw/services-core-trader-registration/types';
import { U } from 'ts-toolbelt';

// import { CampaignStatus } from '@mmw/constants-campaign-status';
export enum OralbAuditStatus {
  OPEN = 'OPEN', // CampaignStatus.INVALID_DATA,
  TRANSMITTED = 'TRANSMITTED', // CampaignStatus.VERIFYING,
  APPROVED_PENDING = 'APPROVED_PENDING', // CampaignStatus.VERIFIED_INCOMPLETE, // when no external order is found
  APPROVED_FOR_INITIAL_ORDER = 'APPROVED_FOR_INITIAL_ORDER', // CampaignStatus.VERIFIED_INCOMPLETE,
  REJECTED = 'REJECTED', // CampaignStatus.REJECTED,
  IN_VERFICATION = 'IN_VERFICATION', // CampaignStatus.READY_TO_PROCESS,
  APPROVED = 'APPROVED', // CampaignStatus.TRANSACTION_FINISHED,
  NEEDS_REVISION = 'NEEDS_REVISION', // CampaignStatus.VALIDATION_FAILED,
}

export type OralbAuditState = {
  created: boolean;
  registration: U.Nullable<RegistrationResponseJSON>;
  id: U.Nullable<number>;
  status: U.Nullable<OralbAuditStatus>;
  canOrderProducts: boolean;
  canStartAudit: boolean;
  canCompleteAudit: boolean;
  canShowFilesCard: boolean;
  canUploadSellout: boolean;
  isRejected: boolean;
  allowedStatusTransitions: OralbAuditStatus[];
  allowedStatusToCreateIssues: OralbAuditStatus[];
  canCreateIssues: boolean;
  createIssuesOnTransition: Partial<
    Record<OralbAuditStatus, CreateIssueJSON[]>
  >;
};

export type OralbAuditStatusOption = ManufacturerAuditStatusOption;
