import { AuthErrorHandler, PossibleErrors } from '@auth/view-error';
import { FastFormSubmitButton } from '@fast-form/ui-system';
import { SIGNIN } from '@mmw/constants-i18n-buttons';
import { COMMON as COMMON_VALIDATIONS } from '@mmw/constants-validation-field-schemas';
import Container from '@mmw/hybrid-ui-structure-containers';
import { ErrorNotification } from '@mmw/redux-store-auth-api-login-by-password/components';
import {
  useIsLoading,
  useLoginByUserAndPass,
} from '@mmw/redux-store-auth-api-login-by-password/hooks';
import {
  LOGIN_FORM_FIELD_PATHS,
  LOGIN_FORM_INITIAL_VALUES,
  LoginForm,
} from '@mmw/redux-store-auth-api-login-by-password/hooks/useLoginByUserAndPass';
import { FastFormProvider } from '@mmw/redux-store-fast-form';
import { css } from '@ui-system/css';
import { makeStyle } from '@ui-system/style';
import UI from '@ui-system/ui';
import isEmpty from 'lodash/isEmpty';
import React, { useMemo } from 'react';
import { F } from 'ts-toolbelt';
import { object } from 'yup';

import { Password, User } from './Inputs';
import PasswordForgotten from './PasswordForgotten';

export const useBoxStyle = makeStyle<{
  withError?: boolean;
  minHeight?: string;
}>(
  ({ theme, props }) => css`
    box-shadow: 0 0 16px ${theme.colors.lightMode.info};
    border-radius: 20px;
    ${props?.withError ? 'border: 1px solid red' : ''};
  `,
);

const { USER_REQUIRED, PASSWORD_REQUIRED } = COMMON_VALIDATIONS;
const VALIDATION_SCHEMA = object<LoginForm>().shape({
  [LOGIN_FORM_FIELD_PATHS.username.$path]: USER_REQUIRED,
  [LOGIN_FORM_FIELD_PATHS.password.$path]: PASSWORD_REQUIRED,
});

const AUTH_ERROR_HANDLER_CONFIG = {
  [PossibleErrors.BAD_CREDENTIALS]: ErrorNotification,
};

type Props = {
  showRecovery: F.Function;
  onSuccess?: F.Function;
  customErrorConfigs?: Record<string, React.FC>;
  hideRecoverPass?: boolean;
  withError?: boolean;
  boxStyle?: boolean;
};

const LoginFormUI: React.FC<Props> = ({
  showRecovery,
  onSuccess,
  customErrorConfigs,
  hideRecoverPass,
  boxStyle,
  ...props
}: Props) => {
  const login = useLoginByUserAndPass(onSuccess);
  const loading = useIsLoading();
  const cardBoxStyle = useBoxStyle(props);
  const errorHandlerConfigs = useMemo(() => {
    if (!isEmpty(customErrorConfigs)) {
      return {
        ...AUTH_ERROR_HANDLER_CONFIG,
        ...customErrorConfigs,
      };
    }
    return AUTH_ERROR_HANDLER_CONFIG;
  }, [customErrorConfigs]);

  return (
    <FastFormProvider
      validationSchema={VALIDATION_SCHEMA}
      initialValues={LOGIN_FORM_INITIAL_VALUES}
      onSubmitSuccess={login}
    >
      {loading ? (
        <UI.Container
          style={boxStyle ? cardBoxStyle : null}
          align="center"
          w="100%"
          bg="white"
          p={boxStyle ? '6, 8' : undefined}
        >
          <UI.Spinner visible />
        </UI.Container>
      ) : (
        <UI.Container
          style={boxStyle ? cardBoxStyle : null}
          direction="column"
          align="center"
          justify="center"
          w="100%"
          gap={2}
          bg="white"
          p={boxStyle ? '6, 8' : undefined}
        >
          <User />
          <Password />
          <FastFormSubmitButton i18n={SIGNIN} disabled={loading} />
          {!hideRecoverPass ? (
            <Container spacing="4, 0, 2, 0">
              <PasswordForgotten onClick={showRecovery} />
            </Container>
          ) : null}
          <AuthErrorHandler config={errorHandlerConfigs} />
        </UI.Container>
      )}
    </FastFormProvider>
  );
};

export default LoginFormUI;
