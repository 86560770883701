/* eslint-disable import/order */

import frameworkTheme from '@mmw/web-theme';
// assets
import retailAssets from '@retail/hybrid-assets';
// features
import bankAccount from '@retail/product-registration-wizard-ui/fields/bank-account/theme';
import purchaseSelection from '@retail/product-registration-wizard-ui/fields/purchase-selection/theme';
import stepStructure from '@retail/product-registration-wizard-ui/registration-step/theme';
import alertCard from '@retail/ui-hybrid-blocks-alert-card/theme';
import errorCard from '@retail/ui-hybrid-blocks-error-card/theme';
import helpTooltip from '@retail/ui-hybrid-blocks-help-tooltip/theme';
import mobileCard from '@retail/ui-hybrid-blocks-mobile-card/theme';
import acceptanceModal from '@retail/ui-hybrid-blocks-modals-acceptance/theme';
import agreementActivationModal from '@retail/ui-hybrid-blocks-modals-agreement-activation/theme';
import questions from '@retail/ui-hybrid-blocks-questions/theme';
import statusLabel from '@retail/ui-hybrid-blocks-registration-status/registration-status-label/theme';
import modal from '@retail/ui-hybrid-blocks-retail-modal/theme';
import selectionCard from '@retail/ui-hybrid-blocks-selection-card/theme';
import titleWithDescription from '@retail/ui-hybrid-blocks-title-with-description/theme';
import titleWithIcon from '@retail/ui-hybrid-blocks-title-with-icon/theme';
import campaignCard from '@retail/ui-hybrid-components-campaign-card/theme';
import advancedSearch from '@retail/ui-hybrid-structure-advanced-search/theme';
import summaryCard from '@retail/ui-hybrid-structure-summary-card/theme';
import dataProtection from '@retail/ui-product-registration-conclusion/components/blocks/data-protection/theme';
import buttonGroup from '@retail/ui-web-blocks-button-group/theme';
import campaignOverview from '@retail/ui-web-blocks-campaign-overview/theme';
import fileView from '@retail/ui-web-materials-file-view/theme';
// structures
import page from '@retail/ui-web-structure-page/theme';
import { createGlobalStyle } from 'styled-components';

// utils
import colors from './colors';
import fonts from './fonts';
import banner from './styles/banner';
import button from './styles/button';
// blocks
import dividedButton from './styles/dividedButton';
import formButtons from './styles/form/buttons';
import checkbox from './styles/form/checkbox';
import dateInput from './styles/form/dateInput';
import formField from './styles/form/field';
import input from './styles/form/input';
import inputGroup from './styles/form/inputGroup';
import radio from './styles/form/radio';
import select from './styles/form/select';
import icon from './styles/icon';
import imagesCarousel from './styles/imagesCarousel';
import itemsCarousel from './styles/itemsCarousel';
// materials
import link from './styles/link';
// structure
import navBar from './styles/navBar';
import paginator from './styles/paginator';
import stepIndicator from './styles/stepIndicator';
import table from './styles/table';
// elements
import typography from './styles/typography';

const assets = {
  ...frameworkTheme.assets,
  ...retailAssets,
};

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.pallet.background};
    margin: 0;
    overflow-x: hidden;
  }
  .body--modal-open {
    overflow: hidden;
  }
`;

const theme = {
  colors,
  fonts,
  banner,
  modal,
  table,
  helpTooltip,
  campaignCard,
  summaryCard,
  selectionCard,
  icon,
  button,
  link,
  stepStructure,
  page,
  assets,
  GlobalStyle,
  titleWithIcon,
  titleWithDescription,
  advancedSearch,
  questions,
  statusLabel,
  dataProtection,
  errorCard,
  alertCard,
  purchaseSelection,
  bankAccount,
  typography,
  agreementActivationModal,
  stepIndicator,
  itemsCarousel,
  imagesCarousel,
  dividedButton,
  select,
  radio,
  checkbox,
  inputGroup,
  input,
  dateInput,
  formButtons,
  mobileCard,
  paginator,
  fileView,
  buttonGroup,
  formField,
  campaignOverview,
  acceptanceModal,
  navBar,
};

export type RetailThemeType = typeof theme;

export type StyledProps<P = unknown> = P & {
  theme?: RetailThemeType;
};

export default {
  ...frameworkTheme,
  ...theme,
};
