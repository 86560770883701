import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import { orgunitSalesmenByAuthSelector } from '@mmw/redux-store-core-orgunit/selectors';
import { ActionsObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  tap,
  timeout,
  withLatestFrom,
} from 'rxjs/operators';

import { getSalesmenErrorAction, getSalesmenSuccessAction } from '../actions';
import { RootState } from '../stateSelector';
import {
  GET_SALESMEN_START,
  GetSalesmenErrorAction,
  GetSalesmenStartAction,
  GetSalesmenSuccessAction,
} from '../types';

const { logger } = contextualConfig.application;
const { defaultTimeout } = contextualConfig.api;

type Input = GetSalesmenStartAction;
type Output = GetSalesmenSuccessAction | GetSalesmenErrorAction;

const getSalesmenEpic = (
  action$: ActionsObservable<Input>,
  state$: Observable<RootState>,
): Observable<Output> =>
  action$.pipe(
    ofType(GET_SALESMEN_START),
    withLatestFrom(state$.pipe(map(orgunitSalesmenByAuthSelector))),
    filter(([, traderData]) => traderData != null),
    tap(() => logger.debug('Get Logged in trader data Start')),
    switchMap(([, getSalesmen]) =>
      from(getSalesmen() || []).pipe(
        timeout(defaultTimeout),
        tap(() => logger.debug('Got logged user data')),
        map(data => getSalesmenSuccessAction(data)),
        catchError(error => of(getSalesmenErrorAction(error))),
      ),
    ),
  );

export default getSalesmenEpic;
