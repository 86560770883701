import { I18nShape } from '@mmw/constants-i18n';
import { FieldPath } from '@mmw/redux-store-fast-form';
import {
  useInputFieldConfigProps,
  useOnBlur,
  useOnInputChange,
  useOnPressEnterSubmit,
  useValue,
} from '@mmw/redux-store-fast-form/hooks/fields';
import { useMemo } from '@mmw/redux-store-i18n/hooks';
import Input, {
  EnhancedMaskedInput as MaskedInput,
  EnhancedMultilineInput as MultilineInput,
} from '@mmw/ui-web-elements-input';
import { useOnFocus } from '@mmw/ui-web-providers-input-handlers';
import { merge } from 'lodash';
import React, { InputHTMLAttributes } from 'react';
import { O } from 'ts-toolbelt';

export type FastFormInputProps = {
  fieldPath: FieldPath;
  placeholder?: string | I18nShape;
  modifiers?: string | Array<string>;
  disabled?: boolean;
} & O.Pick<
  InputHTMLAttributes<string>,
  'tabIndex' | 'type' | 'autoComplete' | 'name' | 'style'
>;

// XXX: some props are overwriten by the validation, like maxLength, minLength, and required respectively
const FastFormInput: React.FC<FastFormInputProps> = ({
  fieldPath,
  modifiers,
  ...rest
}: FastFormInputProps) => {
  const value = useValue(fieldPath, '');
  const onBlur = useOnBlur(fieldPath);
  const onChange = useOnInputChange(fieldPath);
  const onPressEnterSubmit = useOnPressEnterSubmit();
  const inputFieldConfigProps = useInputFieldConfigProps(fieldPath);
  const onFocus = useOnFocus();
  const otherProps = useMemo(
    () => merge(rest, inputFieldConfigProps),
    [inputFieldConfigProps, rest],
  );

  return (
    <Input
      // @ts-ignore
      name={fieldPath}
      value={value}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={onChange}
      onKeyDown={onPressEnterSubmit}
      modifiers={modifiers}
      {...otherProps}
    />
  );
};

export const MaskedFastFormInput: React.FC<FastFormInputProps> = ({
  fieldPath,
  ...rest
}: FastFormInputProps) => {
  const value = useValue(fieldPath);
  const onBlur = useOnBlur(fieldPath);
  const onChange = useOnInputChange(fieldPath);
  const onPressEnterSubmit = useOnPressEnterSubmit();
  const inputFieldConfigProps = useInputFieldConfigProps(fieldPath);
  const onFocus = useOnFocus();

  return useMemo(() => {
    const otherProps = merge(rest, inputFieldConfigProps);
    return (
      <MaskedInput
        // @ts-ignore
        name={fieldPath}
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        onKeyDown={onPressEnterSubmit}
        {...otherProps}
      />
    );
  }, [
    inputFieldConfigProps,
    rest,
    fieldPath,
    value,
    onBlur,
    onFocus,
    onChange,
    onPressEnterSubmit,
  ]);
};

export const MultilineFormInput: React.FC<FastFormInputProps> = ({
  fieldPath,
  ...rest
}: FastFormInputProps) => {
  const value = useValue(fieldPath);
  const onBlur = useOnBlur(fieldPath);
  const onChange = useOnInputChange(fieldPath);
  const inputFieldConfigProps = useInputFieldConfigProps(fieldPath);
  const onFocus = useOnFocus();

  return useMemo(() => {
    const otherProps = merge(inputFieldConfigProps, rest);
    return (
      <MultilineInput
        // @ts-ignore
        name={fieldPath}
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        {...otherProps}
      />
    );
  }, [
    inputFieldConfigProps,
    rest,
    fieldPath,
    value,
    onBlur,
    onFocus,
    onChange,
  ]);
};
export default FastFormInput;
