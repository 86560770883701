const dashboardNamespace = 'dashboard';
const profileNamespace = 'profile';
const aboutNamespace = 'about';
const changePasswordNamespace = 'changePassword';

export default {
  DASHBAORD: {
    FOOTER: {
      key: `${dashboardNamespace}.footer.moreValue`,
      message: 'More value for consumers',
    },
    ADRESSE: {
      key: `${dashboardNamespace}.footer.adresse`,
      message: 'Adresse',
    },
    ADRESSE_INFO: {
      key: `${dashboardNamespace}.footer.adresseInfo`,
      message: 'marken mehrwert - brand added value AG',
    },
    STREET: {
      key: `${dashboardNamespace}.footer.street`,
      message: `Schildkrötstraße 15 <br /> 68199 Mannheim`,
    },
    RECHTLICHES: {
      key: `${dashboardNamespace}.footer.rechtliches`,
      message: 'Rechtliches',
    },
    AGB: {
      key: `${dashboardNamespace}.footer.agb`,
      message: 'AGB',
    },
    IMPRESSUM: {
      key: `${dashboardNamespace}.footer.impressum`,
      message: 'Impressum',
    },
    DATENSCHUTZ: {
      key: `${dashboardNamespace}.footer.datenschutz`,
      message: 'Datenschutz',
    },
    TEILNAHMEBEDINGUNGEN: {
      key: `${dashboardNamespace}.footer.teilnahmebedingungen`,
      message: 'Teilnahmebedingungen',
    },
    LOB_UND_KRITIK: {
      key: `${dashboardNamespace}.footer.lobUndKritik`,
      message: 'Lob und Kritik',
    },
    COOKIE: {
      key: `${dashboardNamespace}.footer.cokie`,
      message: 'Cookie-Richtlinie (EU)',
    },
    UST_IDNR: {
      key: `${dashboardNamespace}.footer.UstIdnr`,
      message: 'USt-IdNr.DE258032509 <br /> Registericht Mannheim HRB 704123',
    },
    BOARD: {
      key: `${dashboardNamespace}.footer.board`,
      message:
        'Vorstand: Frank Schwarz <br /> Aufsichtsrat (Vorsitz): Prof. Dr. Stefan Jugel <br/> Aufsichtsräte: Prof. Dr. Gerald. Graf, Prof. Dr.Dietmar von Hoyningen-Huene',
    },
    KONTAKT: {
      key: `${dashboardNamespace}.footer.kontakt`,
      message: 'Kontakt',
    },
    KONTAKT_EMAIL: {
      key: `${dashboardNamespace}.footer.kontaktEmail`,
      message: 'E-Mail: info@markenmehrwert.com',
    },
  },
  PROFILE: {
    SUBTITLE: {
      key: `${profileNamespace}.subTitle`,
      message:
        '<p><b>Do not waste Time! </b> Keep your data up-to-date here and save time when you participate in a campaign.</p>',
    },
  },
  ABOUT: {
    DISCOVER_THE_OFFER: {
      TITLE: {
        key: `${aboutNamespace}.discoverTheOffer.title`,
        message: 'Discover the offer!',
      },
      SUB_TITLE: {
        key: `${aboutNamespace}.discoverTheOffer.subTitle`,
        message:
          'What is, where and when? Discover ongoing promotions and great offers',
      },
    },
    BUY_YOUR_DESIRED_PRODUCT: {
      TITLE: {
        key: `${aboutNamespace}.buyYourDesiredProduct.title`,
        message: 'Buy your desired product',
      },
      SUB_TITLE: {
        key: `${aboutNamespace}.buyYourDesiredProduct.subTitle`,
        message:
          "Buy your desired product from a participating retailer and don't forget the receipt or invoice!",
      },
    },
    REGISTER_PRODUCT: {
      TITLE: {
        key: `${aboutNamespace}.registerProduct.title`,
        message: 'Register product',
      },
      SUB_TITLE: {
        key: `${aboutNamespace}.registerProduct.subTitle`,
        message: 'Register your products quickly and easily',
      },
    },
    PERSONAL_ADDED_VALUE: {
      TITLE: {
        key: `${aboutNamespace}.personalAddedValue.title`,
        message: 'Your personal added value',
      },
      SUB_TITLE: {
        key: `${aboutNamespace}.personalAddedValue.subTitle`,
        message:
          "It doesn't matter whether it's a cash back bonus, an extended warranty or an onpack: your personal added value is already on its way to you!",
      },
    },
  },
  CHANGE_PASSWORD: {
    INFORM_YOUR_EMAIL: {
      SUB_TITLE: {
        key: `${changePasswordNamespace}.informYourEmail.subtitle`,
        message:
          'You will receive an e-mail with the confirmation code to validate your account, and start the password redefinition process',
      },
    },
  },
};
