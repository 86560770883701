import defaultApiV2, { ApiResponse } from '@mmw/api-v2';
import AGREEMENT from '@mmw/constants-campaign-agreement';
import { LanguageCode } from '@mmw/constants-languages';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import autoBind from 'auto-bind';

import {
  AcceptContractPath,
  ConfirmContractPath,
  DownloadSignedAgreementPath,
  GetContractsPath,
  GetContractStatusPath,
  RetrieveContractStatusPath,
} from './apiPaths';
import logger from './log';
import { ContractAgreementJSON } from './types';

type Api = typeof defaultApiV2;
type TraderContractsServiceOptions = {
  apiv2?: Api;

  authenticationService: AuthenticationService;
};

class TraderContractsService {
  api: Api;

  authenticationService: AuthenticationService;

  constructor({ apiv2, authenticationService }: TraderContractsServiceOptions) {
    this.api = apiv2 || defaultApiV2;
    this.authenticationService = authenticationService;
    autoBind(this);
  }

  async getContracts(
    orgunitID: number,
    language: LanguageCode,
  ): Promise<ContractAgreementJSON[]> {
    logger.debug('Trying to load contracts for orgunit id=', orgunitID);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ContractAgreementJSON[]> = await this.api.get(
        GetContractsPath(
          orgunitID,
          [AGREEMENT.TYPES.CONTRACT, AGREEMENT.TYPES.PHYSICAL_CONTRACT],
          language,
        ),
        {
          headers,
        },
      );
      logger.info('Successfully got contracts by id=', orgunitID);
      return response.data;
    } catch (error) {
      logger.error('Error when getting contracts, error=%O', error);
      throw error;
    }
  }

  async getContractStatus(
    orgunitID: number,
    offerorID: number,
    language: LanguageCode,
  ): Promise<ContractAgreementJSON> {
    logger.debug('Trying to load contract for orgunit id=', orgunitID);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ContractAgreementJSON> = await this.api.get(
        GetContractStatusPath(
          orgunitID,
          AGREEMENT.TYPES.CONTRACT,
          offerorID,
          language,
        ),
        {
          headers,
        },
      );
      logger.info('Successfully got contract by id=', orgunitID);
      return response.data;
    } catch (error) {
      logger.error('Error when getting contract, error=%O', error);
      throw error;
    }
  }

  async retrieveContractStatus(
    orgunitAgreementID: number,
    language: LanguageCode,
  ): Promise<ContractAgreementJSON> {
    logger.debug(
      'Trying to load contract for orgunit agree id=',
      orgunitAgreementID,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ContractAgreementJSON> = await this.api.get(
        RetrieveContractStatusPath(orgunitAgreementID, language),
        {
          headers,
        },
      );
      logger.info('Successfully got contract by agree id=', orgunitAgreementID);
      return response.data;
    } catch (error) {
      logger.error('Error when getting contract, error=%O', error);
      throw error;
    }
  }

  async acceptContract(
    orgunitID: number,
    offerorID: number,
    authorizedSignatory: string,
    role: string,
    language: LanguageCode,
  ): Promise<ContractAgreementJSON> {
    logger.debug('Trying to accept contract for orgunit id=', orgunitID);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ContractAgreementJSON> = await this.api.post(
        AcceptContractPath(
          orgunitID,
          AGREEMENT.TYPES.CONTRACT,
          offerorID,
          authorizedSignatory,
          role,
          language,
        ),
        null,
        { headers },
      );
      logger.info('Successfully accepted contract by id=', orgunitID);
      return response.data;
    } catch (error) {
      logger.error('Error when accepting contract, error=%O', error);
      throw error;
    }
  }

  async confirmContract(
    orgunitID: number,
    offerorID: number,
    automaticallyApprove: boolean,
    language: LanguageCode,
    notAuthenticatedOperationToken: string,
    recaptchaResponse: string,
  ): Promise<ContractAgreementJSON> {
    logger.debug('Trying to confirm contract for orgunit id=', orgunitID);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ContractAgreementJSON> = await this.api.post(
        ConfirmContractPath(
          orgunitID,
          AGREEMENT.TYPES.CONTRACT,
          offerorID,
          automaticallyApprove,
          language,
        ),
        {
          notAuthenticatedOperationToken,
          recaptchaResponse,
        },
        {
          headers,
        },
      );
      logger.info('Successfully confirmed contract by id=', orgunitID);
      return response.data;
    } catch (error) {
      logger.error('Error when confirming contract, error=%O', error);
      throw error;
    }
  }

  async downloadSignedAgreement(
    orgunitAgreementID: number,
    language: LanguageCode,
  ): Promise<Blob> {
    logger.debug(
      'Trying to download signed agreement for orgunit agreement id=',
      orgunitAgreementID,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Blob> = await this.api.get(
        DownloadSignedAgreementPath(orgunitAgreementID, language),
        {
          headers,
          responseType: 'blob',
        },
      );
      const type = response.headers['content-type'];
      const { data } = response;
      logger.info(
        'Successfully downloading signed agreement orgunit agreement id=',
        orgunitAgreementID,
      );
      return new Blob([data], {
        type,
      });
    } catch (error) {
      logger.error('Error when getting contracts, error=%O', error);
      throw error;
    }
  }
}

export default TraderContractsService;
