import wrapperStyleBuilder from './wrapperStyleBuilder';

export default ({ theme, datePanelPosition }: Record<string, any>) =>
  wrapperStyleBuilder({
    wrapper: {
      fontFamily: theme.fonts.fontFamily,
      backgroundColor: theme.colors.pallet.secondary,
      color: theme.colors.light.font,
    },
    caption: {
      backgroundColor: theme.colors.pallet.primary,
    },
    todayButton: {
      backgroundColor: theme.colors.pallet.primary,
      color: theme.colors.primary.font,
      borderRadius: '0',
    },
    weekday: {
      color: theme.colors.basic.darken(0.2),
    },
    day: {
      color: theme.colors.basic.darken(0.5),
      borderRadius: '0',
      today: {
        color: theme.colors.pallet.primary,
      },
      outside: {
        color: theme.colors.secondary.darken(0.3),
        hover: {
          color: theme.colors.pallet.primary,
          backgroundColor: theme.colors.pallet.primary,
        },
      },
      disabled: {
        color: theme.colors.secondary.font,
      },
      sunday: {
        backgroundColor: theme.colors.pallet.primary,
        not: {
          color: theme.colors.pallet.primary,
        },
      },
      selected: {
        color: theme.colors.primary.font,
        backgroundColor: theme.colors.pallet.primary,
        hover: {
          color: theme.colors.pallet.basic,
          backgroundColor: theme.colors.pallet.primary,
        },
      },
    },
    interactionDisabled: {
      backgroundColor: theme.colors.pallet.primary,
      color: theme.colors.primary.font,
    },
    overlay: {
      position: datePanelPosition,
      backgroundColor: theme.colors.pallet.light,
    },
  });
