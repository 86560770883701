import withTranslatedPlaceholder from '@mmw/ui-hoc-with-translated-placeholder'; // ,{ type WithPlaceholder },
import { use } from '@mmw/ui-theme/utils';
import { memo } from 'react';
import Masked from 'react-text-mask';
import { compose } from 'recompose';
import styled from 'styled-components';

const Input = styled.input.attrs(({ disabled }) => disabled)(props =>
  use(props, 'input'),
);

const MultilineInput = styled.textarea.attrs(({ disabled }) => disabled)(
  props => use(props, 'input'),
);

const MaskedInput = styled(Masked).attrs(({ disabled }) => disabled)(props =>
  use(props, 'input'),
);

// TODO: add this type Input ->
// type Props = WithPlaceholder;

const InputEnhancer = compose(withTranslatedPlaceholder, memo);

const EnhancedInput = InputEnhancer(Input);
const EnhancedMaskedInput = InputEnhancer(MaskedInput);
const EnhancedMultilineInput = InputEnhancer(MultilineInput);

export {
  EnhancedInput as default,
  EnhancedMaskedInput,
  EnhancedMultilineInput,
  Input,
  InputEnhancer,
  MaskedInput,
  MultilineInput,
};
