/* eslint-disable no-param-reassign */
import { Builder } from '@redux-basic-module/reducer-utils/ReducerBuilder';
import { CarouselSearchActions } from '@redux-search-module/actions-utils';
import { enableMapSet } from 'immer';
import indexOf from 'lodash/indexOf';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import size from 'lodash/size';

import { CarouselSearchByUuidState, SearchState } from './state';
import { IdGetterFn } from './types';

enableMapSet();

export function addReducerCarouselSearchCases<RequestFields, ItemType>(
  actions: CarouselSearchActions<RequestFields, ItemType>,
  builder: Builder<SearchState<RequestFields, ItemType>>,
  idGetter: IdGetterFn<ItemType>,
): void {
  const initialCarouselSearchByUuidState = <
    CarouselSearchByUuidState<RequestFields>
  >new CarouselSearchByUuidState<RequestFields>();

  function resolveCarouselSearchState(
    draft: SearchState<RequestFields, ItemType>,
    searchUuid: string,
  ): CarouselSearchByUuidState<RequestFields> {
    if (!draft.carouselSearchesByUuid[searchUuid]) {
      draft.carouselSearchesByUuid = {
        ...draft.carouselSearchesByUuid,
        [searchUuid]: initialCarouselSearchByUuidState,
      };
    }
    return draft.carouselSearchesByUuid[searchUuid];
  }

  function performNextOrPrevCarousel(
    draft: SearchState<RequestFields, ItemType>,
    searchUuid: string,
    type: 'NEXT' | 'PREV',
  ): SearchState<RequestFields, ItemType> {
    const isNext = type === 'NEXT';
    const nextPageSumValue = isNext ? 1 : -1;
    const searchState = resolveCarouselSearchState(draft, searchUuid);
    // BUG, should never happen
    if (searchState.currentItemIndexOnCurrentPage == null) {
      return draft;
    }
    let nextPossibleIndex =
      searchState.currentItemIndexOnCurrentPage + nextPageSumValue;
    let nextPossibleCurrentPage = searchState.currentPage;
    if (
      !searchState.loadedIdsByPage[nextPossibleCurrentPage] ||
      !searchState.loadedIdsByPage[nextPossibleCurrentPage][nextPossibleIndex]
    ) {
      nextPossibleIndex = isNext
        ? 0
        : size(searchState.loadedIdsByPage[nextPossibleCurrentPage]) - 1;
      nextPossibleCurrentPage += nextPageSumValue;
    }
    // END of carousel, action shouldnt have been called
    if (
      !searchState.loadedIdsByPage[nextPossibleCurrentPage] ||
      !searchState.loadedIdsByPage[nextPossibleCurrentPage][nextPossibleIndex]
    ) {
      return draft;
    }

    searchState.currentItemIndexOnCurrentPage = nextPossibleIndex;
    searchState.currentPage = nextPossibleCurrentPage;
    return draft;
  }

  builder
    .addCase(
      actions.select.init,
      (draft, { payload, meta: { searchUuid } }) => {
        const {
          requestFields,
          limit,
          currentPage,
          currentPageVisibleIds,
          itemId,
          total,
        } = payload;

        const searchState = resolveCarouselSearchState(draft, searchUuid);
        searchState.currentPage = currentPage;
        searchState.limit = limit;
        searchState.requestFields = <RequestFields>requestFields;
        searchState.error = null;
        searchState.total = total;
        searchState.loadedIdsByPage = {
          [currentPage]: currentPageVisibleIds,
        };
        searchState.currentItemIndexOnCurrentPage = indexOf(
          currentPageVisibleIds,
          itemId,
        );
        return draft;
      },
    )
    .addCase(actions.select.next, (draft, { meta: { searchUuid } }) =>
      performNextOrPrevCarousel(draft, searchUuid, 'NEXT'),
    )
    .addCase(actions.select.previous, (draft, { meta: { searchUuid } }) =>
      performNextOrPrevCarousel(draft, searchUuid, 'PREV'),
    )
    .addCase(actions.search.start, (draft, { meta: { searchUuid } }) => {
      const searchState = resolveCarouselSearchState(draft, searchUuid);
      searchState.loading = true;
      return draft;
    })
    .addCase(
      actions.search.success,
      (draft, { payload, meta: { searchUuid } }) => {
        const { total, list, limit, offset } = payload;
        const page = offset === 0 ? 1 : offset / limit + 1;

        draft.itemsById = {
          ...draft.itemsById,
          ...keyBy(list, idGetter),
        };

        const searchState = resolveCarouselSearchState(draft, searchUuid);
        searchState.loading = false;
        searchState.error = null;
        searchState.total = total;
        searchState.loadedIdsByPage = {
          ...searchState.loadedIdsByPage,
          [page]: map(list, idGetter),
        };
        return draft;
      },
    )
    .addCase(
      actions.search.error,
      (draft, { payload, meta: { searchUuid } }) => {
        const searchState = resolveCarouselSearchState(draft, searchUuid);

        searchState.loading = false;
        searchState.error = payload;
        return draft;
      },
    )
    .addCase(actions.search.paginate, (draft, { meta: { searchUuid } }) => {
      const searchState = resolveCarouselSearchState(draft, searchUuid);
      searchState.loading = true;
      return draft;
    })
    .addCase(actions.search.cancel, (draft, { meta: { searchUuid } }) => {
      const searchState = resolveCarouselSearchState(draft, searchUuid);

      searchState.loading = false;
      return draft;
    })
    .addCase(actions.search.dismissError, (draft, { meta: { searchUuid } }) => {
      const searchState = resolveCarouselSearchState(draft, searchUuid);
      searchState.error = null;
      return draft;
    });
}
