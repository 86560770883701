import { connect as formikConnect } from '@mmw/ui-libraries-formik-v2';
import { compose, HOC, mapProps, withHandlers } from 'recompose';

const ENTER_KEY_CODE = 13;

const withOnPressEnter: HOC<unknown, unknown> = compose(
  formikConnect,
  withHandlers({
    onKeyDown:
      ({ formik: { handleSubmit } }) =>
      e => {
        if (e.keyCode === ENTER_KEY_CODE) {
          handleSubmit();
        }
      },
  }),
  mapProps(({ formik, onKeyDown, ...rest }) => ({
    ...rest,
    onKeyDown,
  })),
);

export default withOnPressEnter;
