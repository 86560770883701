import createApi from '@mmw/common-api-client';
import { live } from '@mmw/config';
import CommonService from '@mmw/services-core-common';
import TraderConsumer from '@mmw/services-core-consumer';
import DocumentGeneratorService from '@mmw/services-core-document-generator';
import FaqService from '@mmw/services-core-faq';
import LoggedUserDataService from '@mmw/services-core-logged-user-data-service';
import ManufacturerRegistrationService from '@mmw/services-core-manufacturer-registration';
import NewsService from '@mmw/services-core-news';
import TraderProductsService from '@mmw/services-core-product';
import ResourceService from '@mmw/services-core-resource';
import StoreLocatorService from '@mmw/services-core-store-locator';
import TraderAgreementService from '@mmw/services-core-trader-agreement';
import TraderBankAccountService from '@mmw/services-core-trader-bank-account';
import TraderBusinessRelationService from '@mmw/services-core-trader-business-relation';
import TraderCampaignsService from '@mmw/services-core-trader-campaigns';
import TraderClassOfGoodsService from '@mmw/services-core-trader-classofgoods';
import TraderContractsService from '@mmw/services-core-trader-contracts';
import TraderEmployeesService from '@mmw/services-core-trader-employees';
import TraderOrgunitService from '@mmw/services-core-trader-orgunit';
import TraderProductGroupService from '@mmw/services-core-trader-product-group';
import TraderRegistrationService from '@mmw/services-core-trader-registration';
import TraderWalletService from '@mmw/services-core-trader-wallet';
import TraderWalletByEmployeeService from '@mmw/services-core-trader-wallet-by-employee';
import UserProfileService from '@mmw/services-core-user-profile';
import {
  getAuthenticationService,
  setCommonService,
  setConsumerService,
  setDocumentGeneratorService,
  setFaqService,
  setLoggedUserDataService,
  setManufacturerRegistrationService,
  setNewsService,
  setResourceService,
  setStoreLocatorService,
  setTraderAgreementService,
  setTraderBankAccountService,
  setTraderBusinessRelationService,
  setTraderCampaignsService,
  setTraderClassOfGoodsService,
  setTraderContractsService,
  setTraderEmployeesService,
  setTraderOrgunitService,
  setTraderProductService,
  setTraderProductsService,
  setTraderRegistrationService,
  setTraderWalletByEmployeeService,
  setTraderWalletService,
  setUserProfileService,
} from '@mmw/services-holder';

const setupBaseServices = (): void => {
  const authenticationService = getAuthenticationService();

  const manufacturerService = new ManufacturerRegistrationService({
    authenticationService,
  });
  setManufacturerRegistrationService(manufacturerService);

  const resourceService = new ResourceService({
    apiv2: createApi({ baseURI: live.api.v2.baseURI }),
  });
  setResourceService(resourceService);

  const traderProductsService = new TraderProductsService({
    authenticationService,
  });
  setTraderProductsService(traderProductsService);

  const commonService = new CommonService({});
  setCommonService(commonService);

  const loggedUserDataService = new LoggedUserDataService({
    authenticationService,
  });
  setLoggedUserDataService(loggedUserDataService);

  const traderConsumer = new TraderConsumer({
    authenticationService,
  });
  setConsumerService(traderConsumer);

  const traderRegistrationService = new TraderRegistrationService({
    authenticationService,
  });
  setTraderRegistrationService(traderRegistrationService);

  const traderCampaignsService = new TraderCampaignsService({
    authenticationService,
  });
  setTraderCampaignsService(traderCampaignsService);

  const traderAgreementService = new TraderAgreementService({
    authenticationService,
  });
  setTraderAgreementService(traderAgreementService);

  const traderOrgunitService = new TraderOrgunitService({
    authenticationService,
  });
  setTraderOrgunitService(traderOrgunitService);

  const traderClassOfGoodsService = new TraderClassOfGoodsService({
    authenticationService,
  });
  setTraderClassOfGoodsService(traderClassOfGoodsService);

  const storeLocatorService = new StoreLocatorService({
    authenticationService,
  });
  setStoreLocatorService(storeLocatorService);

  const faqService = new FaqService({
    authenticationService,
  });
  setFaqService(faqService);

  const newsService = new NewsService({
    authenticationService,
  });
  setNewsService(newsService);

  const traderEmployeesService = new TraderEmployeesService({
    authenticationService,
  });
  setTraderEmployeesService(traderEmployeesService);

  const userProfileService = new UserProfileService({
    authenticationService,
  });
  setUserProfileService(userProfileService);

  const documentGeneratorService = new DocumentGeneratorService({
    authenticationService,
  });
  setDocumentGeneratorService(documentGeneratorService);

  const traderBankAccountService = new TraderBankAccountService({
    authenticationService,
  });
  setTraderBankAccountService(traderBankAccountService);

  const traderWalletService = new TraderWalletService({
    authenticationService,
  });
  setTraderWalletService(traderWalletService);

  const traderWalletByEmployeeService = new TraderWalletByEmployeeService({
    authenticationService,
  });
  setTraderWalletByEmployeeService(traderWalletByEmployeeService);

  const traderContractsService = new TraderContractsService({
    authenticationService,
  });
  setTraderContractsService(traderContractsService);

  const traderBusinessRelationService = new TraderBusinessRelationService({
    authenticationService,
  });
  setTraderBusinessRelationService(traderBusinessRelationService);

  const traderProductGroupService = new TraderProductGroupService({
    authenticationService,
  });
  setTraderProductService(traderProductGroupService);
};

export default setupBaseServices;
