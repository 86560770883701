import { values } from 'lodash';

export enum ConsumerType {
  PRIVATE = 'PRIVATE', // RESIDENTIAL
  BUSINESS = 'BUSINESS', // COMMERCIAL
}

export const { PRIVATE, BUSINESS } = ConsumerType;

export const VALUES = values(ConsumerType);
