import defaultApiV2, {
  ApiResponse,
  oneSnapApi as defaultOneSnapApi,
} from '@mmw/api-v2';
import { FileType } from '@mmw/constants-file-types';
import { LanguageCode } from '@mmw/constants-languages';
import { RegistrationSource } from '@mmw/constants-registration-sources';
import { SalesOrgBrand } from '@mmw/constants-salesorgbrand-ids';
import defaultConfig from '@mmw/contextual-config';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import {
  Pagination,
  UploadableFileBlob,
  UploadableFileJSON,
} from '@mmw/services-core-common/types';
import {
  ChangeRegistrationStatusOperations,
  CreateIssueRequestJSON,
  CreateIssueResponseJSON,
  PerformIssueActionJSON,
  RegistrationActionWithStatusRequestJSON,
  RegistrationActionWithValidationErrorRequestJSON,
  ReverseRegistrationRequestJSON,
} from '@mmw/services-core-manufacturer-registration/types';
import { normalizeQuestionsIntoRegistrationResponse } from '@mmw/utils-campaign-item';
import { fetchStreaming } from '@mmw/utils-stream-api-reader';
import autoBind from 'auto-bind';
import { startsWith } from 'lodash';
import map from 'lodash/map';
import { F, U } from 'ts-toolbelt';

import {
  ChangeRegistrationStatusPath,
  ChangeRegistrationValidationErrorPath,
  DownloadRegistrationAsXLSPath,
  GetCampaignRankingForTraderPath,
  GetCampaignScoresForTraderPath,
  GetCreateIssuesPath,
  GetOneSnapRegistrationWithStreamPath,
  GetProductSelectionPath,
  GetPurchaseSelectionPath,
  GetRegistrationPath,
  GetSearchRegistrationsPath,
  PerformIssueActionPath,
  PerformRegistrationPath,
  RegisterThirdPartyCampaignItemPath,
  RemoveFileByIdPath,
  RevalidateRegistrationPath,
  ReverseRegistrationPath,
  SetDataPrivacyConsentPath,
  UpdateCampaignItemsQuestionsPath,
  UpdateFilesByTypePath,
  UploadDataPrivacyFilePath,
} from './apiPaths';
import logger from './log';
import { transformRegistrationRequestToSimulation } from './transformers';
import {
  AvailableThirdPartyCampaignJSON,
  CampaignItemJSON,
  CampaignRankingJSON,
  CampaignScoreJSON,
  DATA_PRIVACY_FILE_PREFIX,
  OneSnapRegistrationItemData,
  OneSnapRegistrationRequest,
  OneSnapStreamFinalProgressJSON,
  OneSnapStreamProgressJSON,
  PurchaseSelection,
  RegistrationItemSelectionJSON,
  RegistrationRequestJSON,
  RegistrationResponseJSON,
  SearchRegistrationRequestJSON,
  UploadRegistrationFilesResultJSON,
} from './types';

type Api = typeof defaultApiV2;

type OneSnapApi = typeof defaultOneSnapApi;

type TraderRegistrationServiceOptions = {
  apiv2?: Api;
  oneSnapApi?: OneSnapApi;
  oneSnapBaseURI?: string;
  authenticationService: AuthenticationService;
};

class TraderRegistrationService {
  api: Api;

  oneSnapApi: OneSnapApi;

  oneSnapBaseURI: string;

  authenticationService: AuthenticationService;

  constructor({
    apiv2,
    oneSnapApi,
    oneSnapBaseURI,
    authenticationService,
  }: TraderRegistrationServiceOptions) {
    this.api = apiv2 || defaultApiV2;
    this.oneSnapApi = oneSnapApi || defaultOneSnapApi;
    this.oneSnapBaseURI =
      oneSnapBaseURI || defaultConfig.api.fileEvaluator.baseURI;
    this.authenticationService = authenticationService;
    autoBind(this);
  }

  async performRegistration(
    registrationRequest: RegistrationRequestJSON,
    simulate: boolean,
    source: RegistrationSource,
  ): Promise<RegistrationResponseJSON> {
    logger.debug(
      `Trying to perform registration, simulate=${String(simulate)}`,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<RegistrationResponseJSON> =
        await this.api.post(
          PerformRegistrationPath(simulate, source),
          !simulate
            ? registrationRequest
            : transformRegistrationRequestToSimulation(registrationRequest),
          { headers },
        );
      const data = normalizeQuestionsIntoRegistrationResponse(response.data);
      if (!simulate) {
        logger.info(
          'Successfully registered, ordernumber=%s',
          data.ordernumber,
        );
      } else {
        logger.info('Successfully simulated');
      }
      return data;
    } catch (error) {
      logger.error('Error when performing registration, error=%O', error);
      throw error;
    }
  }

  async getRegistration(
    registrationId: number,
    language: LanguageCode,
  ): Promise<RegistrationResponseJSON> {
    logger.debug('Trying to load registration by id', registrationId);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<RegistrationResponseJSON> =
        await this.api.get(GetRegistrationPath(registrationId, language), {
          headers,
        });
      const data = normalizeQuestionsIntoRegistrationResponse(response.data);
      logger.info('Successfully got registration by id=', registrationId);
      return data;
    } catch (error) {
      logger.error('Error when getting registration, error=%O', error);
      throw error;
    }
  }

  // XXX: to be the same as manu service
  async retrieveRegistration(
    registrationID: number,
    language: LanguageCode,
  ): Promise<RegistrationResponseJSON> {
    return this.getRegistration(registrationID, language);
  }

  async getRegistrations(
    options: SearchRegistrationRequestJSON,
    language: LanguageCode,
  ): Promise<Pagination<RegistrationResponseJSON>> {
    logger.debug('Trying to load registrations by request=%O', options);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Pagination<RegistrationResponseJSON>> =
        await this.api.post(GetSearchRegistrationsPath(language), options, {
          headers,
        });
      const { data } = response;
      logger.info('Successfully got registrations by request=%O', options);
      return {
        ...data,
        list: map(data.list, normalizeQuestionsIntoRegistrationResponse),
      };
    } catch (error) {
      logger.error('Error when getting registrations, error=%O', error);
      throw error;
    }
  }

  async uploadDataPrivacyFile(
    registrationId: number,
    file: UploadableFileBlob,
    language: LanguageCode,
  ): Promise<RegistrationResponseJSON> {
    logger.debug(
      'Trying to upload data privacy file for registration by id',
      registrationId,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      await this.api.post(
        UploadDataPrivacyFilePath(registrationId, language),
        file,
        {
          headers,
        },
      );
      logger.info(
        'Successfully upload data privacy file by id=',
        registrationId,
      );
      return this.getRegistration(registrationId, language);
    } catch (error) {
      logger.error('Error when upload data privacy file, error=%O', error);
      throw error;
    }
  }

  async setDataPrivacyConsent(
    registrationId: number,
    consent: boolean,
    language: LanguageCode,
  ): Promise<RegistrationResponseJSON> {
    logger.debug(
      'Trying to set data privacy consent for registration by id',
      registrationId,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      await this.api.post(
        SetDataPrivacyConsentPath(registrationId, consent, language),
        {},
        { headers },
      );
      logger.info(
        'Successfully set data privacy consent by id=',
        registrationId,
      );
      return this.getRegistration(registrationId, language);
    } catch (error) {
      logger.error('Error when set data privacy consent, error=%O', error);
      throw error;
    }
  }

  static isDataPrivacyFile({
    name,
    type,
  }: {
    name: string;
    type: FileType;
  }): boolean {
    return (
      type === FileType.DATA_PRIVACY ||
      startsWith(name, DATA_PRIVACY_FILE_PREFIX)
    );
  }

  async registerThirdPartyCampaignItem(
    registrationId: number,
    thirdPartyCi: AvailableThirdPartyCampaignJSON,
    language: LanguageCode,
  ): Promise<RegistrationResponseJSON> {
    logger.debug(
      'Trying to register third party ci item for registration by id',
      registrationId,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      await this.api.post(
        RegisterThirdPartyCampaignItemPath(registrationId, language),
        thirdPartyCi,
        { headers },
      );
      logger.info(
        'Successfully register third party ci by reg id=',
        registrationId,
      );
      // XXX: API BUG!
      // XXX: had to call the API again, first time the return doesnt contain selected package!
      return this.getRegistration(registrationId, language);
    } catch (error) {
      logger.error(
        `Error when trying to register third party ci, reg id=${registrationId}, error=%O`,
        error,
      );
      throw error;
    }
  }

  async getCampaignScoresForTrader(
    campaignCode: string,
    salesOrgBrandID: SalesOrgBrand,
  ): Promise<CampaignScoreJSON> {
    logger.debug('Trying to load campaign scores for', campaignCode);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<CampaignScoreJSON> = await this.api.get(
        GetCampaignScoresForTraderPath(campaignCode, salesOrgBrandID),
        {
          headers,
        },
      );
      logger.info('Successfully got scores for campaign', campaignCode);
      return response.data;
    } catch (error) {
      logger.error('Error when getting campaign scores, error=%O', error);
      throw error;
    }
  }

  async updateCampaignItemsQuestions(
    registrationID: number,
    language: LanguageCode,
    campaignItems: CampaignItemJSON[],
  ): Promise<RegistrationResponseJSON> {
    logger.debug(
      'Trying to update questions for',
      registrationID,
      campaignItems,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<RegistrationResponseJSON> =
        await this.api.post(
          UpdateCampaignItemsQuestionsPath(registrationID, language),
          campaignItems,
          {
            headers,
          },
        );
      logger.info(
        'Successfully updated questions for',
        registrationID,
        response.data.campaignItems,
      );
      return response.data;
    } catch (error) {
      logger.error('Error when updating questions, error=%O', error);
      throw error;
    }
  }

  async updateFilesByType(
    registrationID: number,
    language: LanguageCode,
    files: UploadableFileJSON[],
    ignoreStatusChangeAfterCorrection = true,
  ): Promise<UploadRegistrationFilesResultJSON> {
    logger.debug('Trying to upload files for', registrationID, files);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<UploadRegistrationFilesResultJSON> =
        await this.api.post(
          UpdateFilesByTypePath(
            registrationID,
            language,
            ignoreStatusChangeAfterCorrection,
          ),
          files,
          {
            headers,
          },
        );
      logger.info(
        'Successfully uploaded files for',
        registrationID,
        response.data.files,
      );
      return response.data;
    } catch (error) {
      logger.error('Error when uploading files, error=%O', error);
      throw error;
    }
  }

  async removeFileById(
    registrationID: number,
    fileID: number,
    language: LanguageCode,
    ignoreStatusChangeAfterCorrection = true,
  ): Promise<UploadRegistrationFilesResultJSON> {
    logger.debug(
      `Trying to remove file id=${fileID} for reg id=${registrationID}`,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<UploadRegistrationFilesResultJSON> =
        await this.api.post(
          RemoveFileByIdPath(
            registrationID,
            fileID,
            language,
            ignoreStatusChangeAfterCorrection,
          ),
          {},
          {
            headers,
          },
        );
      logger.info(
        `Successfully removed file id=${fileID} for reg id=${registrationID}`,
      );
      return response.data;
    } catch (error) {
      logger.error(`Error when removing file by id=${fileID}, error=%O`, error);
      throw error;
    }
  }

  async changeRegistrationStatusByRequest(
    request: RegistrationActionWithStatusRequestJSON,
  ): Promise<ChangeRegistrationStatusOperations> {
    logger.debug(`Trying to change status of registration`);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ChangeRegistrationStatusOperations> =
        await this.api.post(ChangeRegistrationStatusPath(), request, {
          headers,
        });
      const { data } = response;
      logger.info(`Status on response to change registration status`, data);
      return data;
    } catch (error) {
      logger.error(`Error changing status of registration`, error);
      throw error;
    }
  }

  async changeRegistrationValidationErrorByRequest(
    request: RegistrationActionWithValidationErrorRequestJSON,
  ): Promise<ChangeRegistrationStatusOperations> {
    logger.debug(`Trying to change validation error of registration`);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ChangeRegistrationStatusOperations> =
        await this.api.post(ChangeRegistrationValidationErrorPath(), request, {
          headers,
        });
      const { data } = response;
      logger.info(
        `Status on response to change registration validation error`,
        data,
      );
      return data;
    } catch (error) {
      logger.error(`Error changing validation error of registration`, error);
      throw error;
    }
  }

  async updateIssue<D>(
    registrationID: number,
    issueID: number,
    language: LanguageCode,
    actions: PerformIssueActionJSON<D>[],
  ): Promise<ChangeRegistrationStatusOperations> {
    logger.debug(
      `Trying to peform issue action/update for issue id=${issueID}`,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ChangeRegistrationStatusOperations> =
        await this.api.post(
          PerformIssueActionPath(registrationID, issueID, language),
          actions,
          {
            headers,
          },
        );
      const { data } = response;
      logger.info(`Peformed issue action/update for issue id=${issueID}`);
      return data;
    } catch (error) {
      logger.error(`Error changing issue id=${issueID} of registration`, error);
      throw error;
    }
  }

  async createIssues(
    request: CreateIssueRequestJSON,
  ): Promise<CreateIssueResponseJSON> {
    logger.debug('Trying to create issue for registration', request);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<CreateIssueResponseJSON> =
        await this.api.post(GetCreateIssuesPath(), request, {
          headers,
        });
      logger.info('Successfully created issue for registration', request);
      return response.data;
    } catch (error) {
      logger.error(
        'Error when created issue for registration, error=%O',
        error,
      );
      throw error;
    }
  }

  async getCampaignRankingForTrader(
    campaignCode: string,
    salesOrgBrandID: SalesOrgBrand,
    orgunitID: number,
  ): Promise<CampaignRankingJSON> {
    logger.debug('Trying to load campaign scores for', campaignCode);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<CampaignRankingJSON> = await this.api.get(
        GetCampaignRankingForTraderPath(
          campaignCode,
          salesOrgBrandID,
          orgunitID,
        ),
        {
          headers,
        },
      );
      logger.info('Successfully got scores for campaign', campaignCode);
      return response.data;
    } catch (error) {
      logger.error('Error when getting campaign scores, error=%O', error);
      throw error;
    }
  }

  async updateProductSelection(
    registrationId: number,
    language: LanguageCode,
    request: RegistrationItemSelectionJSON[],
  ): Promise<RegistrationResponseJSON> {
    logger.debug('Trying to update registration by id', registrationId);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<RegistrationResponseJSON> =
        await this.api.post(
          GetProductSelectionPath(registrationId, language),
          request,
          {
            headers,
          },
        );
      const data = normalizeQuestionsIntoRegistrationResponse(response.data);
      logger.info('Successfully got registration by id=', registrationId);
      return data;
    } catch (error) {
      logger.error('Error when getting registration, error=%O', error);
      throw error;
    }
  }

  async updatePurchaseSelection(
    registrationId: number,
    language: LanguageCode,
    request: PurchaseSelection,
  ): Promise<RegistrationResponseJSON> {
    logger.debug('Trying to update registration by id', registrationId);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<RegistrationResponseJSON> =
        await this.api.post(
          GetPurchaseSelectionPath(registrationId, language),
          request,
          {
            headers,
          },
        );
      const data = normalizeQuestionsIntoRegistrationResponse(response.data);
      logger.info('Successfully got registration by id=', registrationId);
      return data;
    } catch (error) {
      logger.error('Error when getting registration, error=%O', error);
      throw error;
    }
  }

  async downloadRegistrationsAsXLS(
    request: string[],
    language: LanguageCode,
  ): Promise<Blob> {
    logger.debug('Trying to download registrations by request=%O', request);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Blob> = await this.api.post(
        DownloadRegistrationAsXLSPath(language),
        request,
        {
          headers,
          responseType: 'blob',
        },
      );
      logger.info(`Successfully downloaded registrations`);
      return new Blob([response.data]);
    } catch (error) {
      logger.error('Error when downloading registrations, error=%O', error);
      throw error;
    }
  }

  async reverseRegistrations(
    request: Partial<ReverseRegistrationRequestJSON>,
    language: LanguageCode,
  ): Promise<ChangeRegistrationStatusOperations> {
    logger.debug('Trying to reverse registrations by request=%O', request);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ChangeRegistrationStatusOperations> =
        await this.api.post(ReverseRegistrationPath(language), request, {
          headers,
        });
      logger.info('Successfully reversed registrations by request=%O', request);
      return response.data;
    } catch (error) {
      logger.error(
        `Error when trying to reverse registrations, error=%O`,
        error,
      );
      throw error;
    }
  }

  async revalidateRegistrations(
    request: string[],
    language: LanguageCode,
  ): Promise<ChangeRegistrationStatusOperations> {
    logger.debug('Trying to revalidate registrations by request=%O', request);
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<ChangeRegistrationStatusOperations> =
        await this.api.post(RevalidateRegistrationPath(language), request, {
          headers,
        });
      logger.info(
        'Successfully revalidate registrations by request=%O',
        request,
      );
      return response.data;
    } catch (error) {
      logger.error(
        `Error when trying to revalidate registrations, error=%O`,
        error,
      );
      throw error;
    }
  }

  async oneSnapProcessInvoiceRegistration(
    request: OneSnapRegistrationRequest,
    streamCallback: F.Function<[OneSnapStreamProgressJSON]>,
  ): Promise<
    U.Nullable<OneSnapStreamFinalProgressJSON<OneSnapRegistrationItemData>>
  > {
    try {
      logger.debug('Trying to perform one snap registration');
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const accessToken =
        this.authenticationService.getAccessTokenFromHttpHeaders(headers);
      const response = await fetchStreaming<
        OneSnapStreamProgressJSON,
        OneSnapStreamFinalProgressJSON<OneSnapRegistrationItemData>
      >(
        `${this.oneSnapBaseURI}${GetOneSnapRegistrationWithStreamPath()}`,
        accessToken,
        request,
        streamCallback,
      );
      logger.info('One snap registration finished with success');
      return response;
    } catch (error) {
      logger.error(
        'Error when trying to call one snap registration, error=%O',
        error,
      );
      throw error;
    }
  }
}

export default TraderRegistrationService;
