import { createIssuesForApprovedForInitialOrder } from '@audit/utils/createIssuesForApprovedForInitialOrder';
import { hasEverHadUploadIssues } from '@audit/utils/hasEverHadUploadIssues';
import { CampaignStatus } from '@mmw/constants-campaign-status';
import { CampaignType } from '@mmw/constants-campaign-types';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@mmw/constants-utils';
import { RegistrationResponseJSON } from '@mmw/services-core-trader-registration/types';
import { findCampaignItemByCampaignType } from '@mmw/utils-campaign-item';
import { findKey, get, isEmpty } from 'lodash';
import { U } from 'ts-toolbelt';

import {
  ORALB_ALLOWED_STATUS_TO_CREATE_ISSUES,
  ORALB_CAMPAIGN_STATUS_MAPPING,
  ORALB_EMPTY_AUDIT_STATE,
} from './constants';
import { OralbAuditState, OralbAuditStatus } from './types';

type OralbCampaignToAuditStatusMappingOptions = {
  isApprovedPending: boolean;
};

export function mapOralbCampaignToAuditStatus(
  s: CampaignStatus,
  {
    isApprovedPending,
  }: Partial<OralbCampaignToAuditStatusMappingOptions> = EMPTY_OBJECT,
): OralbAuditStatus {
  return isApprovedPending
    ? (findKey(
        ORALB_CAMPAIGN_STATUS_MAPPING,
        v => v === CampaignStatus.VERIFIED_INCOMPLETE,
      ) as OralbAuditStatus)
    : (findKey(
        ORALB_CAMPAIGN_STATUS_MAPPING,
        v => v === s,
      ) as OralbAuditStatus);
}

export function getOralbAllowedStatusTransitions(
  status: OralbAuditStatus,
): OralbAuditStatus[] {
  switch (status) {
    case OralbAuditStatus.OPEN:
      return [OralbAuditStatus.REJECTED];
    case OralbAuditStatus.TRANSMITTED:
      return [
        OralbAuditStatus.APPROVED_FOR_INITIAL_ORDER,
        OralbAuditStatus.NEEDS_REVISION,
        OralbAuditStatus.REJECTED,
      ];
    case OralbAuditStatus.APPROVED_FOR_INITIAL_ORDER:
      return [OralbAuditStatus.REJECTED];
    case OralbAuditStatus.IN_VERFICATION:
      return [
        OralbAuditStatus.APPROVED,
        OralbAuditStatus.NEEDS_REVISION,
        OralbAuditStatus.REJECTED,
      ];
    default:
      return EMPTY_ARRAY;
  }
}

export function oralbCreateAuditState(
  registration: U.Nullable<RegistrationResponseJSON>,
): OralbAuditState {
  if (!registration) {
    return ORALB_EMPTY_AUDIT_STATE;
  }
  const campaignItem = findCampaignItemByCampaignType(
    registration.campaignItems,
    CampaignType.AUDIT,
  );
  const campaignStatus = get(campaignItem, 'campaignStatus');

  /* XXX:
  CampaignStatus.VERIFIED_INCOMPLETE = OralbAuditStatus.APPROVED_PENDING
  when no external order is found
  */
  const hasExternalOrdernumber = !isEmpty(registration.externalOrdernumber);
  const isVerifiedIncomplete =
    CampaignStatus.VERIFIED_INCOMPLETE === campaignStatus;
  const isApprovedPending = isVerifiedIncomplete && !hasExternalOrdernumber;
  const status = mapOralbCampaignToAuditStatus(campaignStatus, {
    isApprovedPending,
  });

  return {
    registration,
    id: registration.id,
    created: true,
    status,
    canOrderProducts:
      status !== OralbAuditStatus.OPEN &&
      status !== OralbAuditStatus.TRANSMITTED &&
      status !== OralbAuditStatus.REJECTED &&
      status !== OralbAuditStatus.NEEDS_REVISION &&
      !isEmpty(registration.externalOrdernumber),
    canStartAudit: status === OralbAuditStatus.OPEN,
    canShowFilesCard:
      status === OralbAuditStatus.APPROVED_FOR_INITIAL_ORDER ||
      status === OralbAuditStatus.IN_VERFICATION ||
      (status === OralbAuditStatus.NEEDS_REVISION &&
        hasEverHadUploadIssues(registration)),
    canCompleteAudit:
      status === OralbAuditStatus.APPROVED_FOR_INITIAL_ORDER ||
      status === OralbAuditStatus.APPROVED_PENDING || // XXX: this line must be commented for GO LIVE, in order to help tests, since external order number is not editable!!!
      status === OralbAuditStatus.NEEDS_REVISION,
    canUploadSellout: status === OralbAuditStatus.APPROVED,
    allowedStatusTransitions: getOralbAllowedStatusTransitions(status),
    isRejected: status === OralbAuditStatus.REJECTED,
    allowedStatusToCreateIssues: ORALB_ALLOWED_STATUS_TO_CREATE_ISSUES,
    canCreateIssues:
      status === OralbAuditStatus.IN_VERFICATION ||
      status === OralbAuditStatus.TRANSMITTED,
    createIssuesOnTransition: {
      [OralbAuditStatus.APPROVED_FOR_INITIAL_ORDER]:
        createIssuesForApprovedForInitialOrder(registration),
    },
  };
}
