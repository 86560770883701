import { fastHash } from '@hasher/object-hash';
import {
  useIsLoggedIn,
  useIsLoggingOut,
} from '@mmw/redux-store-auth-api-authentication/hooks';
import { useNavigate } from '@mmw/ui-web-libraries-react-router/hooks';
import { css } from '@ui-system/css';
import { getNavMenuRoutes } from '@ui-system/custom-nav-menu';
import {
  NavItemConfig,
  NavItemConfigInterface,
} from '@ui-system/custom-nav-menu/types';
import { useNavItemHandler } from '@ui-system/custom-nav-menu/useNavItemHandler';
import { MenuOption } from '@ui-system/interfaces-menu';
import UI from '@ui-system/ui';
import { useHover } from 'ahooks';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { useMemo, useRef, useState } from 'react';
import * as React from 'react';

const NAV_MENU_ROUTES = getNavMenuRoutes();

const NavItem = ({ ...config }: NavItemConfigInterface) => {
  const [isSubMenuOpened, setOpened] = useState(false);
  const active = useNavItemHandler({
    route: config.route,
    matchGroup: config.matchGroup,
  });
  const navigate = useNavigate();
  const item = useMemo(
    () => new NavItemConfig(config, navigate),
    [config, navigate],
  );
  const onClickHandler = item.useOnClick();

  const menuOptions = useMemo(() => {
    if (isEmpty(item.subRoutes)) return null;
    return map(
      item.subRoutes,
      subRoute =>
        new MenuOption({
          label: subRoute.label,
          useOnClick: subRoute.useOnClick,
          useIsVisible: subRoute.useIsVisible,
        }),
    );
  }, [item.subRoutes]);
  const ref = useRef(null);
  const hover = useHover(ref);

  const activeOrHover = useMemo(() => active || hover, [active, hover]);
  const visible = item.useIsVisible();
  if (!visible) return null;
  if (!menuOptions)
    return (
      <div
        ref={ref}
        style={css`
          height: 100%;
          width: 100%;
        `}
      >
        <UI.Container
          onClick={onClickHandler}
          active={active}
          p={3}
          align="center"
          justify="center"
          h="100%"
          w="100%"
        >
          <UI.ButtonText
            variant="body1"
            i18n={item.label}
            modifiers={`capitalize, center, nowrap, ${activeOrHover ? 'primary' : 'black'}`}
          />
        </UI.Container>
      </div>
    );
  return (
    <UI.Menu.MenuContainer
      options={menuOptions}
      onToggleSubMenu={isOpen => setOpened(isOpen)}
      menuItemStyle={css`
        width: 200px;
        text-align: center;
      `}
    >
      <div
        ref={ref}
        style={css`
          height: 100%;
          width: 100%;
        `}
      >
        <UI.Container
          onClick={onClickHandler}
          active={isSubMenuOpened || activeOrHover}
          p={3}
          align="center"
          justify="center"
          h="100%"
          w="100%"
          gap={1}
        >
          <UI.ButtonText
            variant="body1"
            i18n={item.label}
            modifiers={`capitalize, center, nowrap, ${activeOrHover ? 'primary' : 'black'}`}
          />
          <UI.Icon
            size={10}
            name="down"
            color={activeOrHover ? 'black' : 'white'}
          />
        </UI.Container>
      </div>
    </UI.Menu.MenuContainer>
  );
};

const Nav = ({ ...props }) => {
  const isLoggedIn = useIsLoggedIn();
  const isLoggingOut = useIsLoggingOut();

  if (!isLoggedIn || isLoggingOut || isEmpty(NAV_MENU_ROUTES)) return null;

  return (
    <UI.Container align="center" {...props}>
      {NAV_MENU_ROUTES.map(config => (
        <NavItem key={fastHash(config)} {...config} />
      ))}
    </UI.Container>
  );
};

export default Nav;
