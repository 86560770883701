import { AuditState } from '@audit/utils';
import { ManufacturerAuditStatusOption } from '@manufacturer/audits-ui/types';
import { useTranslate } from '@mmw/utils-text-utils/hooks';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import { useMemo } from 'react';
import { U } from 'ts-toolbelt';

import { JURA_AUDIT_I18N_MAPPING } from './constants';
import { JuraAuditStatus } from './types';

export function getAuditStatusIcon(
  status: U.Nullable<JuraAuditStatus>,
): string {
  const icons = {
    [JuraAuditStatus.OPEN]: 'check',
    [JuraAuditStatus.APPROVED]: 'check',
    [JuraAuditStatus.REJECTED]: 'close',
    [JuraAuditStatus.TRANSMITTED]: 'check',
    [JuraAuditStatus.NEEDS_REVISION]: 'edit',
  };
  if (status) {
    return icons[status];
  }
  return '';
}

export function useJuraAuditStatusChangeOptions(
  auditState: U.Nullable<AuditState> = null,
): ManufacturerAuditStatusOption[] {
  const translate = useTranslate();
  const allOptions = useMemo<ManufacturerAuditStatusOption[]>(
    () => [
      {
        name: translate(JURA_AUDIT_I18N_MAPPING.OPEN),
        value: JuraAuditStatus.OPEN,
      },
      {
        name: translate(JURA_AUDIT_I18N_MAPPING.APPROVED),
        value: JuraAuditStatus.APPROVED,
        canCreateApprovalIssues: true,
      },
      {
        name: translate(JURA_AUDIT_I18N_MAPPING.REJECTED),
        value: JuraAuditStatus.REJECTED,
        canCreateCorrectionIssues: true,
      },
      {
        name: translate(JURA_AUDIT_I18N_MAPPING.TRANSMITTED),
        value: JuraAuditStatus.TRANSMITTED,
      },
      {
        name: translate(JURA_AUDIT_I18N_MAPPING.NEEDS_REVISION),
        value: JuraAuditStatus.NEEDS_REVISION,
        requiresToCreateCorrectionIssues: true,
        canCreateCorrectionIssues: true,
        canCreateApprovalIssues: true,
      },
    ],
    [translate],
  );

  return useMemo<ManufacturerAuditStatusOption[]>(
    () =>
      filter(
        allOptions,
        ({ value }) =>
          value === auditState?.status ||
          includes(auditState?.allowedStatusTransitions, value),
      ),
    [allOptions, auditState?.status, auditState?.allowedStatusTransitions],
  );
}
