import { I18nShape } from '@mmw/constants-i18n';
import { FieldPath } from '@mmw/redux-store-fast-form';
import React from 'react';

import FormField from './FormField';
import Input, { FastFormInputProps, MultilineFormInput } from './Input';

type OwnProps = {
  fieldPath: FieldPath;
  defaultValue?: any;
  labelI18n?: I18nShape;
  placeholderI18n?: I18nShape;
} & FastFormInputProps;

const FastFormInputField: React.FC<OwnProps> = ({
  fieldPath,
  labelI18n,
  placeholderI18n,
  tabIndex,
  ...props
}: OwnProps) => (
  <FormField fieldPath={fieldPath} labelI18n={labelI18n} {...props}>
    <Input
      fieldPath={fieldPath}
      placeholder={placeholderI18n}
      tabIndex={tabIndex}
      {...props}
    />
  </FormField>
);

export const FastFormMultilineInputField = ({
  fieldPath,
  labelI18n,
  placeholderI18n,
  tabIndex,
  ...props
}: OwnProps) => (
  <FormField fieldPath={fieldPath} labelI18n={labelI18n} {...props}>
    <MultilineFormInput
      fieldPath={fieldPath}
      placeholder={placeholderI18n}
      {...props}
      tabIndex={tabIndex}
    />
  </FormField>
);

export default FastFormInputField;
