import convertValueToValidDate from '@mmw/utils-date-utils';
import { injectIntl } from 'react-intl';
import { ComponentEnhancer, compose, withHandlers } from 'recompose';

type dateFormat = {
  year: string;
  month: string;
  day: string;
};

const withFormatDate: ComponentEnhancer<any, any> = compose(
  injectIntl,
  withHandlers({
    formatDate:
      ({ intl }: Record<string, any>) =>
      (date: Date | number, format?: dateFormat) => {
        if (!date) {
          return null;
        }
        return intl.formatDate(
          convertValueToValidDate(date),
          format || {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          },
        );
      },
  }),
);

export type FormatDateType = (
  date?: Date | number,
  format?: dateFormat,
) => string | null;

export type WithFormatDateProps = {
  formatDate: FormatDateType;
};

export default withFormatDate;
