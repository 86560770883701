export enum QuestionGroupType {
  CAMPAIGN_TERMS = 1, // not used by registration, just for activation of campaign
  ITEM_TERMS = 2, // campaign terms for registration
  ITEM_QUESTION1 = 3,
  ITEM_QUESTION2 = 4,
  ITEM_QUESTION3 = 5,
  MARKET_QUESTION = 6,
  SMS_REGISTRATION_STATUS_DOUBLE_OPT_IN = 7, // agreement
  NEWSLETTER_DOUBLE_OPT_IN = 8, // agreement
}

export const {
  CAMPAIGN_TERMS,
  ITEM_TERMS,
  ITEM_QUESTION1,
  ITEM_QUESTION2,
  ITEM_QUESTION3,
  MARKET_QUESTION,
  SMS_REGISTRATION_STATUS_DOUBLE_OPT_IN,
  NEWSLETTER_DOUBLE_OPT_IN,
} = QuestionGroupType;
