import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import {
  languageSelector,
  RootState as LangState,
} from '@mmw/redux-store-i18n/stateSelector';
import { getTraderSSOService } from '@mmw/services-holder';
import { ActionsObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  tap,
  timeout,
  withLatestFrom,
} from 'rxjs/operators';

import {
  authenticateTraderBySSOTokenErrorAction,
  authenticateTraderBySSOTokenSuccessAction,
} from '../actions';
import {
  AUTHENTICATE_TRADER_BY_SSO_START,
  AuthenticateTraderBySSOErrorAction,
  AuthenticateTraderBySSOStartAction,
  AuthenticateTraderBySSOSuccessAction,
  RootState,
} from '../types';

const { logger } = contextualConfig.application;
const { defaultTimeout } = contextualConfig.api;

type Input = AuthenticateTraderBySSOStartAction;
type Output =
  | AuthenticateTraderBySSOSuccessAction
  | AuthenticateTraderBySSOErrorAction;
const authenticateTraderBySSOEpic = (
  action$: ActionsObservable<Input>,
  state$: Observable<RootState & LangState>,
): Observable<Output> =>
  action$.pipe(
    ofType(AUTHENTICATE_TRADER_BY_SSO_START),
    tap(() => logger.debug(`Trying to authenticate user by ssoToken`)),
    withLatestFrom(state$.pipe(map(languageSelector))),
    switchMap(([{ payload }, language]) =>
      from(
        getTraderSSOService().authenticateTraderBySSO(
          payload.site,
          payload.params,
          language,
        ),
      ).pipe(
        timeout(defaultTimeout),
        map(data => {
          if (!data.success) {
            return authenticateTraderBySSOTokenErrorAction(data?.error);
          }
          return authenticateTraderBySSOTokenSuccessAction(data);
        }),
        catchError(error => of(authenticateTraderBySSOTokenErrorAction(error))),
      ),
    ),
  );

export default authenticateTraderBySSOEpic;
