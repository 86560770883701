import { StyledProps, Theme } from '@mmw/ui-theme/types';
import { isSafari } from 'react-device-detect';

export const MARGIN = '10px 0px';

export default {
  content: {
    style: ({ isLoading }) => `
    margin: ${isLoading ? 'auto' : '0'};
  `,
    modifiers: undefined,
  },
  total: {
    style: ({ theme }) => `
      margin: ${theme.advancedSearch.variable.MARGIN};
      justify-content: flex-start;
      span {
        margin: 3px;
      }
      min-height: ${isSafari ? '5%' : ''};
      ${theme.media.phone`
        width: 100%;
      `};
    `,
    modifiers: undefined,
  },
  actions: {
    style: ({ theme }) => `
      margin: ${theme.advancedSearch.variable.MARGIN};
      display: flex;
      align-items: center; 
      justify-content: space-between;
      width: 100%;
      box-sizing: border-box;
      min-height: ${isSafari ? '10%' : ''};
      ${theme.media.phone`
         flex-direction: column;
         min-height: 130px;
         padding: 15px;
      `};
    `,
    modifiers: undefined,
  },
  inputs: {
    style: ({ theme }: StyledProps<{}>) => `
      margin: ${theme.advancedSearch.variable.MARGIN};
      display: flex;
      justify-content: space-between;
      width: 100%;
      div {
        margin: 0 2px 0 2px;
      };
      min-height: ${isSafari ? '20%' : ''};
      box-sizing: border-box;
      ${theme.media.upToTablet(`
         flex-direction: column;
         padding: 15px;
         div {
          width: 100%;
          margin: 0;
        };
        ${theme.media.tablet`
          padding: 0;
        `}
      `)};
    `,
    modifiers: undefined,
  },
  paginatorContainer: {
    style: ({ theme }) => `
      flex: 4;
      justify-content: flex-end;
      ${theme.media.IE11(`
        height: 50px;
      `)}
      ${theme.media.phone`
        margin-top: 20px;
      `};
    `,
    modifiers: undefined,
  },
  body: {
    style: ({ theme }) => `
      display: flex;
      flex-direction: column;
      width: 960px;
      min-width: 750px;
      height: 60vh;
      justify-content: flex-start;
      ${theme.media.upToTablet`
        width: 100%;
        min-width: auto;
        height: 100%;
      `};
    `,
    modifiers: undefined,
  },
  footer: {
    style: ({ theme }) => `
      margin-top: auto;
      padding: 25px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      box-sizing: border-box;
      ${theme.media.phone`
         flex-direction: column;
         padding: 15px;
      `};
    `,
    modifiers: undefined,
  },
  save: {
    style: ({ theme }: { theme: Theme }) => `
    margin: 0 10px;
    ${theme.media.phone`
       margin: 10px 0;
       width: 100%;
    `};
  `,
    modifiers: undefined,
  },
  variable: { MARGIN },
};
