export const GET_API_STATUS_START =
  '@mmw-api-connection-status/GET_API_STATUS_START';
export const GET_API_STATUS_SUCCESS =
  '@mmw-api-connection-status/GET_API_STATUS_SUCCESS';
export const GET_API_STATUS_ERROR =
  '@mmw-api-connection-status/GET_API_STATUS_ERROR';

export type GetApiStatusStartAction = {
  type: '@mmw-api-connection-status/GET_API_STATUS_START';
};

export type GetApiStatusSuccessAction = {
  type: '@mmw-api-connection-status/GET_API_STATUS_SUCCESS';
};

export type GetApiStatusErrorAction = {
  type: '@mmw-api-connection-status/GET_API_STATUS_ERROR';
  payload: {
    error: Error;
  };
};

export type State = {
  loading: boolean;
  hasConnection: boolean;
};

export const NAMESPACE = 'apiConnectionStatus';

export type RootState = {
  [NAMESPACE]: State;
};

export type Action =
  | GetApiStatusStartAction
  | GetApiStatusSuccessAction
  | GetApiStatusErrorAction;

export type Reducer = (state: State, action: Action) => State;
