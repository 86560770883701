import {
  FormikErrors as _FormikErrors,
  FormikErrors,
  FormikState as _FormikState,
  FormikState,
  getIn,
  useFormikContext,
} from 'formik';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

export {
  connect,
  FastField,
  Formik as FormikProvider,
  getIn,
  useFormik,
  useFormikContext,
  withFormik,
} from 'formik';

export type FormikErrors = _FormikErrors;
export type FormikState = _FormikState;

export const useFormikButtonSubmit = () => {
  const formik = useFormikContext();
  return formik.handleSubmit;
};

export const useFormikSetFieldValue = () => {
  const formik = useFormikContext();
  return formik.setFieldValue;
};
// export const useFormikGetFieldProps = () => {
//   const formik = useFormikContext();
//   return formik.getFieldProps;
// };

export function useFormikSetFormikState<T>(): (
  state: FormikState<T>,
) => FormikState<T> {
  const formik = useFormikContext<T>();
  return formik.setFormikState;
}

export function useFormikValues<T>(): T {
  const formik = useFormikContext<T>();
  return formik.values;
}
export function useFormikIsValid(): boolean {
  const formik = useFormikContext();
  return formik.isValid;
}
export function useFormikIsValidating(): boolean {
  const formik = useFormikContext();
  return formik.isValidating;
}

export function useFormikSubmitCount(): number {
  const formik = useFormikContext();
  return formik.submitCount;
}

export function useGetFormikValueByPath<T>(fieldPath: string): T {
  const values = useFormikValues<T>();
  return useMemo(() => getIn(values, fieldPath), [values, fieldPath]);
}

export function useOnChangeByPath<Value>(fieldPath: string) {
  const setFieldValue = useFormikSetFieldValue();
  return useCallback(
    (value: Value) => {
      setFieldValue(fieldPath, value);
    },
    [setFieldValue, fieldPath],
  );
}

export function useFormikErrors<T>(): FormikErrors<T> {
  const formik = useFormikContext<T>();
  return formik.errors;
}

export function useFormikTouched() {
  const formik = useFormikContext();
  return formik.touched;
}

type Action = {
  type: any;
  payload: any;
};

type ActionCreator = (formValues: Object) => Action;

export const useReduxSubmit = (actionCreator: ActionCreator) => {
  const dispatch = useDispatch();
  return useCallback(
    (formValues: Object) => dispatch(actionCreator(formValues)),
    [dispatch, actionCreator],
  );
};
