import {
  CANCEL_PATTERN,
  DISMISS_ERROR_PATTERN,
  ERROR_PATTERN,
  START_PATTERN,
  SUCCESS_PATTERN,
} from '@redux-async-module/actions-utils';
import { Actions } from '@redux-async-module/interfaces';
import { Handlers } from '@redux-basic-module/interfaces';
import defaultTo from 'lodash/defaultTo';
import forEach from 'lodash/forEach';
import includes from 'lodash/includes';
import values from 'lodash/values';

export function createHandlers<State, StartPayload, SuccessPayload>(
  actions: Actions<StartPayload, SuccessPayload>,
  initialState: State,
): Handlers<State> {
  const handlers = {};
  forEach(values(actions), action => {
    if (includes(action.type, START_PATTERN)) {
      handlers[action.type] = (state, { payload }) => ({
        ...state,
        lastStartPayload: defaultTo(payload, {}),
        success: false,
        isLoading: true,
      });
      return;
    }
    if (includes(action.type, SUCCESS_PATTERN)) {
      handlers[action.type] = (state, { payload }) => ({
        ...state,
        data: defaultTo(payload, {}),
        success: true,
        isLoading: false,
        error: null,
      });
      return;
    }
    if (includes(action.type, ERROR_PATTERN)) {
      handlers[action.type] = (state, { payload }) => ({
        ...state,
        error: defaultTo(payload, {}),
        success: false,
        isLoading: false,
      });
      return;
    }
    if (includes(action.type, DISMISS_ERROR_PATTERN)) {
      handlers[action.type] = state => ({
        ...state,
        error: null,
        success: false,
      });
      return;
    }
    if (includes(action.type, CANCEL_PATTERN)) {
      handlers[action.type] = state => ({ ...state, isLoading: false });
      return;
    }
    // IF ITS RESET HANDLER
    handlers[action.type] = () => initialState;
  });
  return handlers;
}
