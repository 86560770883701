import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import { getAuthenticationService } from '@mmw/services-holder';
import { ActionsObservable } from 'redux-observable';
import { concat, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, timeout } from 'rxjs/operators';

import {
  retrieveUseridByEmailErrorAction,
  retrieveUseridByEmailSuccessAction,
} from '../actions';
import {
  RETRIEVE_USERID_BY_EMAIL_START,
  RetrieveUseridByEmailErrorAction,
  RetrieveUseridByEmailStartAction,
  RetrieveUseridByEmailSuccessAction,
} from '../types';

const { logger } = contextualConfig.application;
const { defaultTimeout } = contextualConfig.api;

const retrieveUseridByEmailEpic = (
  action$: ActionsObservable<RetrieveUseridByEmailStartAction>,
): Observable<
  RetrieveUseridByEmailSuccessAction | RetrieveUseridByEmailErrorAction
> =>
  action$.pipe(
    ofType(RETRIEVE_USERID_BY_EMAIL_START),
    tap(() => logger.debug('Trying to retrieve userid by email')),
    switchMap(({ payload: { email } }: RetrieveUseridByEmailStartAction) =>
      from(getAuthenticationService().retrieveUserid(email)).pipe(
        timeout(defaultTimeout),
        map(userid => retrieveUseridByEmailSuccessAction(userid)),
        catchError(error =>
          concat(of(retrieveUseridByEmailErrorAction(error))),
        ),
      ),
    ),
  );

export default retrieveUseridByEmailEpic;
