export default {
  style: ({ theme }) => `
    display: flex;
    background-color: ${theme.colors.pallet.basicLight};
    border: 1px dashed ${theme.colors.pallet.basic};
    width: 100%;
    height: auto;
    padding: 10px;
    box-sizing: border-box;
    margin: 5px 0;
  `,
  modifiers: undefined,
  block: {
    style: ({ theme }) => `
      background-color: transparent;
      width: 100%;
      height: auto;
      box-sizing: border-box;
      ${theme.media.desktop`
        margin: 0 10px;
      `}
    `,
    modifiers: undefined,
  },
  inline: {
    style: () => `
      align-items: center;
      background-color: transparent;
      width: 100%;
      height: auto;
      box-sizing: border-box;
      margin: 10px;
    `,
    modifiers: undefined,
  },
  container: {
    style: ({ theme }) => `
      display: flex;
      width: 100%;
      ${theme.media.phone`
        flex-direction: column
      `}
    `,
  },
  text: {
    style: ({ theme }) => `
      color: ${theme.colors.pallet.secondary};
    `,
    modifiers: undefined,
  },
};
