import { createColorSchemaForPallet } from '@mmw/ui-theme/color';
import { Pallet } from '@mmw/ui-theme/color/types';

// TODO: set pallet colors to default framework colors

export const pallet: Pallet = {
  primary: '#004B93',
  secondary: '#4A8BC2',
  dark: '#000',
  basic: '#DDDCD7',
  basicDark: '#313A3E',
  basicLight: '#E8E6D4',
  success: '#257150',
  error: '#DB0D0D',
  light: '#FFF',
};

export default createColorSchemaForPallet(pallet);
