import {
  OperationsEnum,
  TanRequestErrorCode,
  TwoFactorAuthResult,
} from '@mmw/services-auth-api-authentication/types';
import { getFieldPaths } from '@mmw/utils-object-utils';
import { F, U } from 'ts-toolbelt';

export const REQUEST_EMAIL_VERIFICATION_START =
  '@mmw/auth-api/email-verification/REQUEST_EMAIL_VERIFICATION_START';
export const REQUEST_EMAIL_VERIFICATION_SUCCESS =
  '@mmw/auth-api/email-verification/REQUEST_EMAIL_VERIFICATION_SUCCESS';
export const REQUEST_EMAIL_VERIFICATION_ERROR =
  '@mmw/auth-api/email-verification/REQUEST_EMAIL_VERIFICATION_ERROR';

export const VERIFY_EMAIL_START =
  '@mmw/auth-api/email-verification/VERIFY_EMAIL_START';
export const VERIFY_EMAIL_SUCCESS =
  '@mmw/auth-api/email-verification/VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_ERROR =
  '@mmw/auth-api/email-verification/VERIFY_EMAIL_ERROR';
export const RESET = '@mmw/auth-api/email-verification/RESET';

export type VerifyEmailResponse = TwoFactorAuthResult;

// export type SetUserEmailAction = {
//   type: '@mmw/auth-api/email-verification/SET_USERNAME',
//   payload: {
//     email: string,
//   },
// };

export type RequestEmailVerificationStartAction = {
  type: '@mmw/auth-api/email-verification/REQUEST_EMAIL_VERIFICATION_START';
  payload: {
    email: string;
    operationId: OperationsEnum;
    recaptchaToken?: string;
  };
};

export type RequestEmailVerificationSuccessAction = {
  type: '@mmw/auth-api/email-verification/REQUEST_EMAIL_VERIFICATION_SUCCESS';
  payload: {
    success: boolean;
    error: U.Nullable<TanRequestErrorCode>;
  };
};

export type RequestEmailVerificationErrorAction = {
  type: '@mmw/auth-api/email-verification/REQUEST_EMAIL_VERIFICATION_ERROR';
  payload: {
    error: Error;
  };
};

export type VerifyEmailStartAction = {
  type: '@mmw/auth-api/email-verification/VERIFY_EMAIL_START';
  payload: {
    email: string;
    tan: string;
    operationId: OperationsEnum;
    callback: F.Function<[VerifyEmailResponse]>;
    recaptchaToken?: U.Nullable<string>;
  };
};

export type VerifyEmailSuccessAction = {
  type: '@mmw/auth-api/email-verification/VERIFY_EMAIL_SUCCESS';
  payload: VerifyEmailResponse;
};

export type VerifyEmailErrorAction = {
  type: '@mmw/auth-api/email-verification/VERIFY_EMAIL_ERROR';
  payload: {
    error: Error;
  };
};

export type ResetAction = {
  type: '@mmw/auth-api/email-verification/RESET';
};

export type State = {
  error: U.Nullable<Error | TanRequestErrorCode>;
  loading: boolean;
  notAuthenticatedOperationToken: U.Nullable<string>;
  sendEmailStatus: U.Nullable<boolean>;
};

export const NAMESPACE = 'emailVerification';

export class VerifyTanFormData {
  tanCode = '';
}

export const FIELD_PATHS = getFieldPaths(new VerifyTanFormData());

export type RootState = {
  [NAMESPACE]: State;
};

export type Action =
  | RequestEmailVerificationStartAction
  | RequestEmailVerificationSuccessAction
  | RequestEmailVerificationErrorAction
  | VerifyEmailStartAction
  | VerifyEmailSuccessAction
  | VerifyEmailErrorAction
  | ResetAction;

export type Reducer = (state: State, action: Action) => State;
