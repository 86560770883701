import { useCallback } from 'react';

import useSubmitStart from '../useSubmitStart';

function useOnClickSubmit() {
  const submit = useSubmitStart();
  return useCallback(() => {
    submit();
  }, [submit]);
}

export default useOnClickSubmit;
