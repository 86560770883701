import useSearchUuid from '@audit/audits-redux-store/hooks/useSearchUuid';
import { CampaignJSON } from '@mmw/services-core-common/types';
import { OrgUnitJSON } from '@mmw/services-core-manu-orgunit/types';
import { ProjectJSON } from '@mmw/services-core-projects/types';
import {
  useNavigate,
  useNavigateToRoute,
} from '@mmw/ui-web-libraries-react-router/hooks';
import { useNavigateWithParams } from '@mmw/ui-web-libraries-react-router/hooks/useNavigate';
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';
import { useCallback } from 'react';
import { F } from 'ts-toolbelt';

import Routes, { canSobNavigateToRoute } from './routes';

// ???
export const useManufacturerNavigation = (): F.Function<
  [Routes, boolean],
  void
> => {
  const navigate = useNavigate();
  return useCallback(
    (route: Routes, force = false) => {
      if (canSobNavigateToRoute(route)) return navigate(route);
      if (force) return navigate(route);
      throw new Error('not allowed route to be navigated by this SoB');
    },
    [navigate],
  );
};

export const useNavigateToLogin = (): F.Function => {
  const navigate = useNavigate();
  return useCallback(() => navigate(Routes.LOGIN), [navigate]);
};
export const useNavigateToHome = (): F.Function => {
  const navigate = useNavigate();
  return useCallback(() => navigate(Routes.HOME), [navigate]);
};
export const useNavigateToDashboard = (): F.Function => {
  const navigate = useNavigate();
  return useCallback(() => navigate(Routes.HOME), [navigate]);
};
export const useNavigateToAudits = (): F.Function => {
  const navigate = useNavigate();
  return useCallback(() => navigate(Routes.AUDITS), [navigate]);
};
export const useNavigateToPartners = (): F.Function => {
  const navigate = useNavigate();
  return useCallback(() => navigate(Routes.PARTNERS), [navigate]);
};
export const useNavigateToPartner = () => {
  const navigate = useNavigate();
  return useCallback(
    (orgunit: OrgUnitJSON) => {
      navigate(Routes.PARTNER.replace(':id', `${orgunit.id}`));
    },
    [navigate],
  );
};
export const useNavigateToRegistration = () => {
  const navigate = useNavigate();
  return useCallback(
    (project: ProjectJSON) => {
      navigate(Routes.REGISTRATION.replace(':id', `${project.id}`));
    },
    [navigate],
  );
};
export type NavigateToAuditQueryParams = {
  searchUuid: string;
};
export type NavigateToAuditParams = {
  id: string;
};
export function useNavigateToAudit(): F.Function<[number]> {
  const navigate = useNavigateWithParams<
    NavigateToAuditParams,
    NavigateToAuditQueryParams
  >();
  const searchUuid = useSearchUuid();
  return useCallback(
    (id: number) => {
      navigate(
        Routes.AUDIT,
        {
          id: id.toString(),
        },
        { searchUuid },
      );
    },
    [navigate, searchUuid],
  );
}
export const useNavigateToStores = (): F.Function => {
  const navigate = useNavigate();
  return useCallback(() => navigate(Routes.STORES), [navigate]);
};
export type NavigateToStoreParams = {
  id: string;
};
export type NavigateToStoreQueryParams = {
  searchUuid: string;
};
export const useNavigateToStore = (): F.Function<[number]> => {
  const navigate = useNavigateWithParams<
    NavigateToStoreParams,
    NavigateToStoreQueryParams
  >();
  // const searchUuid = useSearchUuid();
  return useCallback(
    (id: number) =>
      navigate(
        Routes.STORE,
        {
          id: id.toString(),
        },
        // { searchUuid },
      ),
    [navigate],
  );
};

export const useNavigateToStoreNewWindow = () =>
  useCallback((id: number) => {
    const url = `${Routes.STORE.replace(':id', id.toString())}`;
    window.open(url, '_blank');
  }, []);

export const useNavigateToIbvs = (): F.Function => {
  const navigate = useNavigate();
  return useCallback(() => navigate(Routes.IBVS), [navigate]);
};

// export const useNavigateToProjects = (): F.Function => {
//   const navigate = useNavigate();
//   return useCallback(() => navigate(Routes.PROJECTS), [navigate]);
// };

// export const useNavigateToRegister = (): F.Function => {
//   const language = useLanguage();
//   return useCallback(
//     (project: ProjectJSON) => {
//       window.open(
//         `${getContextPath()}${REGISTER.path.replace(
//           ':id',
//           `${project.id}`,
//         )}?${qs.stringify({ language })}`,
//         '_blank',
//       );
//     },
//     [language],
//   );
// };

export const useNavigateToIbv = (): F.Function<[number]> => {
  const searchUuid = useSearchUuid();
  return useCallback(
    (id: number) => {
      const url = `${Routes.IBV.replace(':id', id.toString())}?${qs.stringify({
        searchUuid,
      })}`;
      window.open(url, '_blank');
    },
    [searchUuid],
  );
};

export const useNavigateToMarketingBudgets = (): F.Function => {
  const navigate = useNavigate();
  return useCallback(() => navigate(Routes.MARKETING_BUDGETS), [navigate]);
};

export const useNavigateToMarketingBudget = (): F.Function<[number]> => {
  const navigate = useNavigateWithParams<
    NavigateToStoreParams,
    NavigateToStoreQueryParams
  >();
  const searchUuid = useSearchUuid();
  return useCallback(
    (id: number) =>
      navigate(
        Routes.MARKETING_BUDGET,
        {
          id: id.toString(),
        },
        {
          searchUuid,
        },
      ),
    [navigate, searchUuid],
  );
};

export const useNavigateToRECoins = (): F.Function<[number]> => {
  const navigate = useNavigateWithParams<
    NavigateToStoreParams,
    NavigateToStoreQueryParams
  >();
  const searchUuid = useSearchUuid();
  return useCallback(
    (id: number) =>
      navigate(
        Routes.REC_COINS_STATEMENT,
        {
          id: id.toString(),
        },
        {
          searchUuid,
        },
      ),
    [navigate, searchUuid],
  );
};

export const useNavigateToMassMarketingBudget = (): F.Function => {
  const navigate = useNavigate();
  return useCallback(() => navigate(Routes.MASS_MARKETING_BUDGET), [navigate]);
};

export const useNavigateToOnlineReporting = (): F.Function => {
  const navigate = useNavigate();
  return useCallback(() => navigate(Routes.ONLINE_REPORTING), [navigate]);
};

export const useNavigateToDistributionChannels = (): F.Function => {
  const navigate = useNavigate();
  return useCallback(() => navigate(Routes.DISTRIBUTION_CHANNELS), [navigate]);
};

export const useNavigateToUsers = (): F.Function => {
  const navigate = useNavigate();
  return useCallback(() => navigate(Routes.USERS), [navigate]);
};

export const useNavigateToCampaigns = (): F.Function => {
  const navigate = useNavigate();
  return useCallback(() => navigate(Routes.CAMPAIGNS), [navigate]);
};

export const useNavigateToTerms = (): F.Function => {
  const navigate = useNavigate();
  return useCallback(state => navigate(Routes.TERMS, state), [navigate]);
};

export type NavigateToCampaignParams = {
  campaign?: CampaignJSON;
};

export const useNavigateToCampaign = (campaign?: CampaignJSON): F.Function => {
  const navigate = useNavigateToRoute(Routes.CAMPAIGN);
  return useCallback(
    (paramCampaign?: CampaignJSON) => {
      if (isEmpty(campaign) && isEmpty(paramCampaign)) return null;
      return navigate({
        campaign: campaign || paramCampaign,
      });
    },
    [campaign, navigate],
  );
};

export const useNavigateToRecoverPassword = (): F.Function => {
  const navigate = useNavigate();
  return useCallback(() => navigate(Routes.RECOVER_PASSWORD_ROOT), [navigate]);
};

export const useNavigateToNewAccount = (): F.Function => {
  const navigate = useNavigate();
  return useCallback(() => navigate(Routes.NEW_ACCOUNT), [navigate]);
};
