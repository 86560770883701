import { TRADER_ROLES } from '@mmw/constants-roles';
import contextualConfig from '@mmw/contextual-config';
import { setTraderConsumerBasePath } from '@mmw/services-core-consumer/paths';
import PasswordService from '@mmw/services-core-password';
import { setTraderProductBasePath } from '@mmw/services-core-product/paths';
import SmsService from '@mmw/services-core-sms-phone-verification';
import TraderRegistrationService from '@mmw/services-core-trader-registration';
import TraderSSOService from '@mmw/services-core-trader-sso';
import {
  getAuthenticationService,
  setClientFingerprintService,
  setPasswordService,
  setRegistrationsService,
  setSmsService,
  setTraderSSOService,
} from '@mmw/services-holder';
import BrowserFingerprint from '@mmw/web-common-browser-fingerprint';
import { BrowserFingerprintOptions } from '@mmw/web-common-browser-fingerprint/types';
import AsyncStore, { StoreCache } from '@mmw/web-common-store';
import setupAuthentication from '@retail/configure-services/modules/authentication';
import setupBaseServices from '@retail/configure-services/modules/base';
import { DEFAULT_SCOPE_NAME } from '@retail/constants-general';

const {
  defaultResourceBundleName: bundleName,
  applicationId,
  resources,
} = contextualConfig.application;

const clientFingerprint = new BrowserFingerprint(
  {} as BrowserFingerprintOptions,
);
setClientFingerprintService(clientFingerprint);

const RECOVER_PASSWORD_EMAIL_BODY_RESOURCEKEY =
  'email.passwordRecoverLinkMailContent'; // TODO: CHECK RIGHT RESOURCES
const RECOVER_PASSWORD_EMAIL_SUBJECT_RESOURCEKEY =
  'email.eMailSubjectResetPassword'; // TODO: CHECK RIGHT RESOURCES

const RECOVER_USERNAME_EMAIL_BODY_RESOURCEKEY =
  'email.usernameRecoverLinkMailContent'; // TODO: CHECK RIGHT RESOURCES
const RECOVER_USERNAME_EMAIL_SUBJECT_RESOURCEKEY =
  'email.eMailSubjectUsernameRecover'; // TODO: CHECK RIGHT RESOURCES

setupAuthentication({
  cache: new StoreCache({
    store: AsyncStore.namespace(contextualConfig.store.authenticationNamespace),
  }),
  bundleName,
  clientFingerprint,
  TAN_EMAIL_SUBJECT_RESOURCEKEY: undefined,
  TAN_EMAIL_BODY_RESOURCEKEY: undefined,
  REQUEST_EMAIL_VERIFICATION_EMAIL_BODY_RESOURCEKEY:
    resources?.REQUEST_EMAIL_VERIFICATION_EMAIL_BODY_RESOURCEKEY as string,
  REQUEST_EMAIL_VERIFICATION_SUBJECT_RESOURCEKEY:
    resources?.REQUEST_EMAIL_VERIFICATION_EMAIL_SUBJECT_RESOURCEKEY as string,
  REQUEST_PHONE_VERIFICATION_BY_SMS_RESOURCEKEY:
    resources?.REQUEST_PHONE_VERIFICATION_BY_SMS_RESOURCEKEY as string,
  RECOVER_USERNAME_EMAIL_BODY_RESOURCEKEY,
  RECOVER_USERNAME_EMAIL_SUBJECT_RESOURCEKEY,
  RECOVER_PASSWORD_EMAIL_BODY_RESOURCEKEY,
  RECOVER_PASSWORD_EMAIL_SUBJECT_RESOURCEKEY,
  allowedRoleStatus: {
    consumer: false,
    trader: true,
    manufacturer: false,
    mmw: false,
    solar: false,
  },
  applicationId,
  allowedRoles: TRADER_ROLES,
  defaultScopeName: DEFAULT_SCOPE_NAME,
});

setupBaseServices();
setTraderProductBasePath();
setTraderConsumerBasePath();

const authenticationService = getAuthenticationService();

const smsService = new SmsService({ authenticationService });
setSmsService(smsService);

const traderSSOService = new TraderSSOService({
  authenticationService,
});
setTraderSSOService(traderSSOService);

const retailerRegistrationService = new TraderRegistrationService({
  authenticationService,
});

setRegistrationsService(retailerRegistrationService);

setPasswordService(
  new PasswordService({
    authenticationService,
  }),
);
