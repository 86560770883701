import { useNavigate } from '@mmw/ui-web-libraries-react-router/hooks';
import { INFORM_TOKEN } from '@retail/constants-routes';
import { useCallback } from 'react';

function useNavigateToInformToken() {
  const navigate = useNavigate();
  return useCallback(data => navigate(INFORM_TOKEN, data), [navigate]);
}

export default useNavigateToInformToken;
