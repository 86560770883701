import { PERSON as PERSON_I18N } from '@mmw/constants-i18n-fields';
import { EMPTY_ARRAY } from '@mmw/constants-utils';
import { useLegalFormList } from '@mmw/redux-store-common-legal-form-list/hooks';
import { useSetFieldValue } from '@mmw/redux-store-fast-form/hooks';
import { useValue } from '@mmw/redux-store-fast-form/hooks/fields';
import { useMemo } from '@mmw/redux-store-i18n/hooks';
import { SelectFormField as FastFormSelectFormField } from '@mmw/ui-hybrid-blocks-fast-form';
import { FastFormSelectProps } from '@mmw/ui-web-libraries-fast-form/SelectFormField';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';

export const FastFormLegalFormSelect: React.FC<FastFormSelectProps> = ({
  fieldPath,
  ...props
}: FastFormSelectProps) => {
  const legalFormList = useLegalFormList();
  const value = useValue(fieldPath);
  const setValue = useSetFieldValue();
  const options = useMemo(() => {
    if (isEmpty(legalFormList)) {
      return EMPTY_ARRAY;
    }
    const [, ...tail] = legalFormList;
    // xxx: removes the empty first option from api.
    return tail;
  }, [legalFormList]);
  useEffect(() => {
    if (isEmpty(value)) {
      setValue(fieldPath, 0);
    }
  }, [fieldPath, setValue, value]);
  return (
    <FastFormSelectFormField
      labelI18n={PERSON_I18N.LEGAL_FORM.LABEL}
      placeholderI18n={PERSON_I18N.LEGAL_FORM.PLACEHOLDER}
      fieldPath={fieldPath}
      options={options}
      optionsName="name"
      optionsValue="id"
      {...props}
    />
  );
};
