import { css } from '@ui-system/css';
import UI from '@ui-system/ui';
import React from 'react';
import { F } from 'ts-toolbelt';

interface Props {
  onClick: F.Function;
}

const ActionContainer: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  onClick,
}: React.PropsWithChildren<Props>) => (
  <UI.Link
    style={css`
      text-wrap: nowrap;
    `}
    onClick={onClick}
  >
    {children}
  </UI.Link>
);

export default ActionContainer;
