import { COMMON as VALIDATION } from '@mmw/constants-fields-validations';
import { DEFAULT as I18N } from '@mmw/constants-i18n-validations';
import { string, StringSchema } from 'yup';

const LANGUAGE = string()
  .max(VALIDATION.LANGUAGE.MAX_LENGTH)
  .min(VALIDATION.LANGUAGE.MIN_LENGTH);

const EMAIL = string().max(VALIDATION.EMAIL.MAX_LENGTH).email(I18N.EMAIL.key);

const USER = string().max(VALIDATION.USER.MAX_LENGTH);

const PASSWORD = string().max(VALIDATION.PASSWORD.MAX_LENGTH);

// const APP_ID = string().max(VALIDATION.APP_ID.MAX_LENGTH);

const GET_EMAIL = (message: string): StringSchema<string> =>
  string().max(VALIDATION.EMAIL.MAX_LENGTH).email(message);

export const COMMON = {
  LANGUAGE,
  EMAIL,
  GET_EMAIL,
  USER,
  USER_REQUIRED: USER.required(I18N.REQUIRED.key),
  PASSWORD,
  PASSWORD_REQUIRED: PASSWORD.required(I18N.REQUIRED.key),
  // APP_ID,
  // APP_ID_REQUIRED: APP_ID.required(I18N.REQUIRED.key),
  EMAIL_REQUIRED: EMAIL.required(I18N.REQUIRED.key),
  LANGUAGE_REQUIRED: LANGUAGE.required(I18N.REQUIRED.key),
};

export default COMMON;
