import { useCallback, useMemo, useState } from 'react';

const useIsVisible = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggle = useCallback(() => {
    setIsVisible(visible => !visible);
  }, [isVisible, setIsVisible]);
  const show = useCallback(() => {
    setIsVisible(true);
  }, [setIsVisible]);
  const hide = useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);
  return useMemo(
    () => ({
      isVisible,
      toggle,
      show,
      hide,
    }),
    [isVisible, toggle, show, hide],
  );
};

export default useIsVisible;
