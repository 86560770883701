import { SUBMIT } from '@mmw/constants-i18n-buttons';
import { useSubmitStart } from '@mmw/redux-store-fast-form/hooks';
import { ButtonProps } from '@ui-system/interfaces-button';
import UI from '@ui-system/ui';
import * as React from 'react';
import { useCallback } from 'react';
import { F } from 'ts-toolbelt';

type Props = Omit<ButtonProps, 'onClick'> & {
  onAfterSubmitStart?: F.Function;
};

const FastFormSubmitButton: React.FC<Props> = ({
  i18n,
  onAfterSubmitStart,
  ...props
}: Props) => {
  const submit = useSubmitStart();

  const handleClick = useCallback(() => {
    submit();
    if (onAfterSubmitStart) onAfterSubmitStart();
  }, [onAfterSubmitStart, submit]);
  return <UI.Button i18n={i18n || SUBMIT} onClick={handleClick} {...props} />;
};

export default FastFormSubmitButton;
