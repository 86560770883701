import {
  AsyncModuleActionTypes,
  createTypes as createAsyncTypes,
} from '@redux-async-module/actions-utils';

export interface SearchModuleActionTypes extends AsyncModuleActionTypes {
  PAGINATE: string;
  RESET_REQUEST_FIELDS: string;
  CHANGE_LIMIT: string;
}
export const PAGINATE_PATTERN = '_PAGINATE';
export const RESET_REQUEST_FIELDS_PATTERN = '_RESET_REQUEST_FIELDS';
export const CHANGE_LIMIT_PATTERN = '_CHANGE_LIMIT';

export function createSearchTypes(
  namespace: string,
  actionName: string,
): SearchModuleActionTypes {
  return {
    ...createAsyncTypes(namespace, actionName),
    RESET_REQUEST_FIELDS: `${namespace}/${actionName}${RESET_REQUEST_FIELDS_PATTERN}`,
    PAGINATE: `${namespace}/${actionName}${PAGINATE_PATTERN}`,
    CHANGE_LIMIT: `${namespace}/${actionName}${CHANGE_LIMIT_PATTERN}`,
  };
}
