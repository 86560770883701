export default {
  padding: 0,
  // not in use?
  // fontWeight: '500',
  // fontSize: '1.5rem',
  icon: {
    width: '45px',
    height: '45px',
    marginRight: '25px',
    phone: `
      width: 2.2rem;
      height: 2.2rem;
      padding: 15px;
    `,
    tablet: `
      padding: 20px;
      margin-right: 0.5rem;
    `,
  },
};
