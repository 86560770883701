import { IModule } from 'redux-dynamic-modules';

import spinnerReducer from './reducer';
import { NAMESPACE as spinnerNameSpace, RootState } from './types';

export const reducer = spinnerReducer;
export const epics = [];
export const NAMESPACE = spinnerNameSpace;

export * from './types';

export function getSpinnerModule(): IModule<RootState> {
  return {
    id: NAMESPACE,
    reducerMap: {
      // @ts-ignore
      [NAMESPACE]: reducer,
    },
    epics,
  };
}
