import { LegalFormJSON } from '@mmw/services-core-common/types';

import {
  GET_LEGAL_FORM_LIST_ERROR,
  GET_LEGAL_FORM_LIST_START,
  GET_LEGAL_FORM_LIST_SUCCESS,
  GetLegalFormListErrorAction,
  GetLegalFormListStartAction,
  GetLegalFormListSuccessAction,
} from './types';

export const getLegalFormListStartAction = (): GetLegalFormListStartAction => ({
  type: GET_LEGAL_FORM_LIST_START,
});

type GetLegalFormListSuccess = (
  legalFormList: Array<LegalFormJSON>,
) => GetLegalFormListSuccessAction;

export const getLegalFormListSuccessAction: GetLegalFormListSuccess =
  legalFormList => ({
    type: GET_LEGAL_FORM_LIST_SUCCESS,
    payload: {
      legalFormList,
    },
  });

export const getLegalFormListErrorAction = (
  error: Error,
): GetLegalFormListErrorAction => ({
  type: GET_LEGAL_FORM_LIST_ERROR,

  payload: {
    error,
  },
});

const initialActions = [getLegalFormListStartAction()];

export default initialActions;
