import {
  GET_API_STATUS_ERROR,
  GET_API_STATUS_START,
  GET_API_STATUS_SUCCESS,
  Reducer,
} from './types';

const INITIAL_STATE = {
  hasConnection: true,
  loading: false,
};

const apiConnectionStatusReducer: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_API_STATUS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_API_STATUS_SUCCESS:
      return {
        ...state,
        hasConnection: true,
        loading: false,
      };
    case GET_API_STATUS_ERROR:
      return {
        ...state,
        hasConnection: false,
        loading: false,
      };
    default:
      return state;
  }
};

export default apiConnectionStatusReducer;
