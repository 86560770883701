import { SimpleAsyncState } from '@redux-async-module/reducer-utils';

import { SuccessPayload } from './actions';

export type State = SimpleAsyncState<SuccessPayload>;

export const NAMESPACE = 'registration-remove-file';

export type RootState = {
  [NAMESPACE]: State;
};
