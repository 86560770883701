import { I18nShape } from '@mmw/constants-i18n';
import { SUBMIT } from '@mmw/constants-i18n-buttons';
import { useSubmitStart } from '@mmw/redux-store-fast-form/hooks';
import Button from '@mmw/ui-web-materials-button';
import React from 'react';
import styled from 'styled-components';

// TODO: Create DividedButton variant

const Container = styled.div`
  margin-top: 10px;
  width: 200px;
`;

const CustomButton = styled(Button)(
  ({ theme }) => `
  ${theme.media.IE11`
    display: block;
  `}
`,
);

interface Props {
  style?: Record<string, any>;
  i18n?: I18nShape;
  disabled?: boolean;
}

function SubmitButton({ style, i18n, disabled, ...props }: Props): JSX.Element {
  const submit = useSubmitStart();
  return (
    <Container style={style} {...props}>
      <CustomButton onClick={submit} disabled={disabled}>
        <Button.Text i18n={i18n || SUBMIT} modifiers="uppercase" />
      </CustomButton>
    </Container>
  );
}

export default SubmitButton;
