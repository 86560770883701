import { StyledProps } from '@mmw/web-theme';

export default {
  style: ({ theme }: StyledProps<unknown>) => `
    align-items: center;
    ${theme.media.IE11`
      height: 40px;
    `}
    ${theme.media.phone`
      box-sizing: border-box;
    `}
  `,
  statusContainer: {
    style: ({ backgroundColor }: StyledProps<unknown>) => `
      flex: 1;
      background-color: ${backgroundColor};
      padding: 5px;
    `,
  },
  labelContainer: {
    style: () => `
      flex: 1;
      padding: 0 10px;
    `,
  },
  icon: {
    style: ({ theme }) => `
      fill: ${theme.colors.pallet.light};
      color: ${theme.colors.pallet.light};
      margin: 5px 10px;
      ${theme.media.IE11`
        height: 1.25rem;
      `}
    `,
  },
  text: {
    style: ({ theme }) => `
      color: ${theme.colors.pallet.light};
    `,
  },
};
