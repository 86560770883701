// ui-system good example.
import { Hex } from '@ui-system/color';
import { Color } from '@ui-system/css';
import { makeStyleHookWithMergedCtx } from '@ui-system/default-styles/internal';
import { Modifiers } from '@ui-system/interfaces';
import { createMock } from '@ui-system/mocks/createMock';
import { makeStyle } from '@ui-system/style';
import UI from '@ui-system/ui';
import { nanoid } from 'nanoid';
import * as React from 'react';
import { U } from 'ts-toolbelt';

interface Props {
  text: U.Nullable<string>;
  color: Hex;
  modifiers?: Modifiers;
  iconColor?: Color;
  loading?: boolean;
  icon?: string;
}
// create a default style that will mostly fit for the component
const useDefaultStyle = makeStyle<Pick<Props, 'color'>>(
  ({ css, props, theme }) => css`
    background-color: ${props?.color || theme.colors[theme.mode].primary.main};
    border-radius: 20px;
  `,
);

// create a option to customize this style via StylesProvider
export const AUDIT_STATUS_CARD_VIEW_UUID = nanoid();

const useStyle = makeStyleHookWithMergedCtx(
  AUDIT_STATUS_CARD_VIEW_UUID,
  useDefaultStyle,
);

const SPINNER_STYLE = {
  color: 'white',
};
export const AuditStatusCardView: React.FC<Props> = ({
  color,
  text,
  icon,
  modifiers,
  iconColor,
  loading,
}: Props) => {
  const style = useStyle({ color });
  return (
    <UI.Container style={style} align="center" p="1, 3" gap={6}>
      <UI.Body2 modifiers={modifiers}>{text || ' '}</UI.Body2>
      <UI.Container>
        {loading ? (
          <UI.Spinner visible size="tiny" style={SPINNER_STYLE} />
        ) : (
          <UI.Icon name={icon || 'status'} size={25} color={iconColor} />
        )}
      </UI.Container>
    </UI.Container>
  );
};

// To customize on a new app or new usage
AuditStatusCardView.defaultProps = {
  modifiers: 'light, bold',
  iconColor: 'white',
};

// Export a ready to render mock to catalog later.
export const AuditStatusCardMock = createMock<Props>(AuditStatusCardView, {
  text: 'Card Mock',
  color: '#000',
});
