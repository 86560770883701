import leftArrow from '@mmw/web-assets/arrow-left.png';

export default ({ disabled }) => `
  background-image: url(${leftArrow});
  background-size: 60px;
  width: 60px;
  height: 60px;
  opacity: ${disabled ? '0.3' : '1'};
  cursor: ${disabled ? 'default' : 'pointer'};
`;
