import { I18nShape } from '@mmw/constants-i18n';
import AUTH_I18N from '@mmw/constants-i18n-authentication';
import { INTERNAL_ERROR } from '@mmw/constants-i18n-messages';
import { UNKNOWN_ERROR_I18N } from '@mmw/redux-store-payload-error/constants';

import {
  BadCredentialsError,
  CaptchaBlockedError,
  EmailBlockedError,
  MissingAuthorityError,
  MissingSalesOrgBusinessRelationError,
  UserNotFoundError,
} from '../errors';

export function isAccountBlockedByEmail(error: Error) {
  return error instanceof EmailBlockedError;
}

export function getErrorI18n(error: Error): {
  TITLE: I18nShape;
  DESCRIPTION: I18nShape;
} {
  if (error instanceof CaptchaBlockedError) {
    return AUTH_I18N.CAPTCHA_BLOCKED;
  }
  if (error instanceof EmailBlockedError) {
    return AUTH_I18N.EMAIL_BLOCKED;
  }
  if (error instanceof BadCredentialsError) {
    return AUTH_I18N.BAD_CREDENTIALS;
  }
  if (error instanceof MissingSalesOrgBusinessRelationError) {
    return AUTH_I18N.USER_MISSING_SOB;
  }
  if (error instanceof MissingAuthorityError) {
    return AUTH_I18N.USER_MISSING_AUTHORITY;
  }
  if (error instanceof UserNotFoundError) {
    return {
      TITLE: AUTH_I18N.USER_CONFIG.NOT_FOUND,
      DESCRIPTION: null,
    };
  }
  return {
    TITLE: UNKNOWN_ERROR_I18N,
    DESCRIPTION: INTERNAL_ERROR,
  };
}
