import { RefreshAuthenticationResponse } from '@mmw/services-auth-api-authentication/types';

import { refreshAccessToken } from './actions';
import {
  AUTHENTICATE_BY_SSO_TOKEN_ERROR,
  AUTHENTICATE_BY_SSO_TOKEN_START,
  AUTHENTICATE_BY_SSO_TOKEN_SUCCESS,
  AUTHENTICATE_BY_TAN_ERROR,
  AUTHENTICATE_BY_TAN_START,
  AUTHENTICATE_BY_TAN_SUCCESS,
  AUTHENTICATE_ERROR,
  AUTHENTICATE_START,
  AUTHENTICATE_SUCCESS,
  AUTHENTICATE_TRADER_BY_SSO_ERROR,
  AUTHENTICATE_TRADER_BY_SSO_START,
  AUTHENTICATE_TRADER_BY_SSO_SUCCESS,
  AUTHENTICATE_WITH_GOOGLE_ERROR,
  AUTHENTICATE_WITH_GOOGLE_START,
  AUTHENTICATE_WITH_GOOGLE_SUCCESS,
  BYPASS_FORCE_CHANGE_PW,
  CHECK_AUTHENTICATION_ERROR,
  CHECK_AUTHENTICATION_START,
  CHECK_AUTHENTICATION_SUCCESS,
  CHECK_AUTHENTICATION_WITH_SYSTEM_TOKEN_ERROR,
  CHECK_AUTHENTICATION_WITH_SYSTEM_TOKEN_START,
  CHECK_AUTHENTICATION_WITH_SYSTEM_TOKEN_SUCCESS,
  LOGOUT_ERROR,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  LOGOUT_WITH_APP_ID_START,
  Reducer,
  REQUEST_TAN_ERROR,
  REQUEST_TAN_START,
  REQUEST_TAN_SUCCESS,
  RESET_AUTHENTICATION_ERRORS,
  RETRIEVE_USERID_BY_EMAIL_ERROR,
  RETRIEVE_USERID_BY_EMAIL_START,
  RETRIEVE_USERID_BY_EMAIL_SUCCESS,
} from './types';

const INITIAL_STATE = {
  authenticating: false,
  isLoggedIn: false,
  loggedUser: null,
  accessToken: null,
  verificationError: null,
  verificationsCounter: 0,
  verifying: false,
  authenticationError: null,
  requestTanError: null,
  requestingTan: false,
  logoutError: null,
  loggingOut: false,
  loading: false,
  requestingUseridByEmail: false,
  foundUserid: null,
  googleAuthFailed: false,
  authWithSystemToken: false,
  bypassForceChangePw: null,
};

const authenticationReducer: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHECK_AUTHENTICATION_START:
      return {
        ...state,
        verificationError: null,
        verifying: true,
      };

    case CHECK_AUTHENTICATION_SUCCESS:
      return {
        ...state,
        isLoggedIn: action.payload.isLoggedIn,
        loggedUser: action.payload.loggedUser,
        accessToken: action.payload.accessToken,
        verificationError: null,
        verificationsCounter: state.verificationsCounter + 1,
        verifying: false,
      };

    case CHECK_AUTHENTICATION_ERROR:
      return {
        ...state,
        verificationError: action.payload.error,
        verificationsCounter: state.verificationsCounter + 1,
        verifying: false,
      };

    case CHECK_AUTHENTICATION_WITH_SYSTEM_TOKEN_START:
      return {
        ...state,
        authWithSystemToken: false,
        verificationError: null,
        verifying: true,
      };

    case CHECK_AUTHENTICATION_WITH_SYSTEM_TOKEN_SUCCESS:
      return {
        ...state,
        authWithSystemToken: action.payload.isLoggedIn,
        isLoggedIn: action.payload.isLoggedIn,
        loggedUser: action.payload.loggedUser,
        accessToken: action.payload.accessToken,
        verificationError: null,
        verificationsCounter: state.verificationsCounter + 1,
        verifying: false,
      };

    case CHECK_AUTHENTICATION_WITH_SYSTEM_TOKEN_ERROR:
      return {
        ...state,
        verificationError: action.payload.error,
        verificationsCounter: state.verificationsCounter + 1,
        verifying: false,
      };

    case RESET_AUTHENTICATION_ERRORS:
      return {
        ...state,
        verificationError: null,
        requestTanError: null,
        authenticationError: null,
      };
    case AUTHENTICATE_START:
      return {
        ...state,
        authenticating: true,
        authenticationError: null,
        googleAuthFailed: false,
      };

    case AUTHENTICATE_SUCCESS:
      return {
        ...state,
        authenticating: false,
        isLoggedIn: true,
        loggedUser: action.payload.loggedUser,
        accessToken: action.payload.accessToken,
        authenticationError: null,
        googleAuthFailed: false,
      };

    case AUTHENTICATE_ERROR:
      return {
        ...state,
        authenticating: false,
        authenticationError: action.payload.error,
      };

    case AUTHENTICATE_WITH_GOOGLE_START:
      return {
        ...state,
        authenticating: true,
        authenticationError: null,
      };

    case AUTHENTICATE_WITH_GOOGLE_SUCCESS:
      return {
        ...state,
        authenticating: false,
        isLoggedIn: true,
        accessToken: action.payload.accessToken,
        authenticationError: null,
      };

    case AUTHENTICATE_WITH_GOOGLE_ERROR:
      return {
        ...state,
        authenticating: false,
        authenticationError: action.payload.error,
        googleAuthFailed: !!action.payload.error,
      };

    case AUTHENTICATE_BY_TAN_START:
      return {
        ...state,
        authenticating: true,
        authenticationError: null,
      };

    case AUTHENTICATE_BY_TAN_SUCCESS:
      return {
        ...state,
        authenticating: false,
        isLoggedIn: true,
        loggedUser: action.payload.loggedUser,
        accessToken: action.payload.accessToken,
        authenticationError: null,
      };

    case AUTHENTICATE_BY_TAN_ERROR:
      return {
        ...state,
        authenticating: false,
        authenticationError: action.payload.error,
      };
    case AUTHENTICATE_BY_SSO_TOKEN_START:
      return {
        ...state,
        authenticating: true,
        authenticationError: null,
      };

    case AUTHENTICATE_BY_SSO_TOKEN_SUCCESS:
      return {
        ...state,
        authenticating: false,
        isLoggedIn: true,
        loggedUser: action.payload.loggedUser,
        accessToken: action.payload.accessToken,
        authenticationError: null,
      };

    case AUTHENTICATE_BY_SSO_TOKEN_ERROR:
      return {
        ...state,
        authenticating: false,
        authenticationError: action.payload.error,
      };

    case AUTHENTICATE_TRADER_BY_SSO_START:
      return {
        ...state,
        authenticating: true,
        authenticationError: null,
      };

    case AUTHENTICATE_TRADER_BY_SSO_SUCCESS:
      return {
        ...state,
        authenticating: false,
        isLoggedIn: action.payload.success,
        loggedUser: action.payload.loginData,
        authenticationError: null,
      };

    case AUTHENTICATE_TRADER_BY_SSO_ERROR:
      return {
        ...state,
        authenticating: false,
        authenticationError: action.payload.error,
      };

    case REQUEST_TAN_START:
      return {
        ...state,
        requestingTan: true,
        requestTanError: null,
      };

    case REQUEST_TAN_SUCCESS:
      return {
        ...state,
        requestingTan: false,
      };
    case REQUEST_TAN_ERROR:
      return {
        ...state,
        requestingTan: false,
        requestTanError: action.payload.error,
      };

    case LOGOUT_START:
      return {
        ...state,
        loggingOut: true,
        logoutError: null,
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        loggingOut: false,
        isLoggedIn: false,
        loggedUser: null,
        accessToken: null,
        logoutError: null,
        unlockedWithBiometry: false,
      };

    case LOGOUT_ERROR:
      return {
        ...state,
        loggingOut: false,
        logoutError: action.payload.error,
      };

    case LOGOUT_WITH_APP_ID_START:
      return {
        ...state,
        loggingOut: true,
        logoutError: null,
      };

    case RETRIEVE_USERID_BY_EMAIL_START:
      return {
        ...state,
        requestingUseridByEmail: true,
      };

    case RETRIEVE_USERID_BY_EMAIL_SUCCESS:
      return {
        ...state,
        requestingUseridByEmail: false,
        foundUserid: action.payload.userid,
      };
    case RETRIEVE_USERID_BY_EMAIL_ERROR:
      return {
        ...state,
        requestingUseridByEmail: false,
        logoutError: action.payload.error,
      };
    case refreshAccessToken.success.type:
      return {
        ...state,
        accessToken: (action.payload as RefreshAuthenticationResponse)
          .accessToken,
      };
    case BYPASS_FORCE_CHANGE_PW:
      return {
        ...state,
        bypassForceChangePw: action.payload.changePwUuid,
      };

    default:
      return state;
  }
};

export default authenticationReducer;
