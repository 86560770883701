import { MANUFACTURER } from '@mmw/constants-roles';
import contextualConfig from '@mmw/contextual-config';
import ofType, { BatchAction } from '@mmw/redux-rx-of-type-operator';
import { getLoggedUserDataService } from '@mmw/services-holder';
import { batchActions } from 'redux-batched-actions';
import { ActionsObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, timeout } from 'rxjs/operators';

import {
  getAvailableStoresStartAction,
  getLoggedTraderDataErrorAction,
  getLoggedTraderDataSuccessAction,
  getOrgunitGroupNamesStartAction,
  getSalesmenStartAction,
} from '../actions';
import {
  GET_LOGGED_TRADER_DATA_START,
  GetLoggedTraderDataErrorAction,
  GetLoggedTraderDataStartAction,
  GetLoggedTraderDataSuccessAction,
} from '../types';

const { logger } = contextualConfig.application;
const { defaultTimeout } = contextualConfig.api;

type Input = GetLoggedTraderDataStartAction;
type Output = GetLoggedTraderDataSuccessAction | GetLoggedTraderDataErrorAction;
const getLoggedTraderDataEpic = (
  action$: ActionsObservable<Input>,
): Observable<Output | BatchAction<Output>> =>
  action$.pipe(
    ofType(GET_LOGGED_TRADER_DATA_START),
    tap(() => logger.debug('Get Logged in trader data Start')),
    switchMap(() =>
      from(getLoggedUserDataService().getLoggedUserData()).pipe(
        timeout(defaultTimeout),
        tap(() => logger.debug('Got logged user data')),
        map(data => {
          if (data.authorities.includes(MANUFACTURER)) {
            return getLoggedTraderDataSuccessAction(data);
          }
          return <BatchAction<Output>>(
            batchActions([
              getLoggedTraderDataSuccessAction(data),
              getAvailableStoresStartAction(),
              getSalesmenStartAction(),
              getOrgunitGroupNamesStartAction(),
            ])
          );
        }),
        catchError(error => of(getLoggedTraderDataErrorAction(error))),
      ),
    ),
  );

export default getLoggedTraderDataEpic;
