const namespace = 'authentication';

const AUTHENTICATION = {
  ENTER_YOUR_CREDENTIALS: {
    key: `${namespace}.enterYourCredentialsToSignIn`,
    message: 'Enter your credentials to sign in',
  },
  INFORM_LOGIN_OR_EMAIL: {
    key: `${namespace}.enterLoginOrEmail`,
    message: 'Enter your user login or e-mail',
  },
  APP_ID: {
    key: `${namespace}.appId`,
    message: 'Application ID',
  },
  INFORM_APP_ID: {
    key: `${namespace}.enterAppId`,
    message: 'Enter application ID',
  },
  INFORM_USER: {
    key: `${namespace}.enterUser`,
    message: 'Enter your user login',
  },
  INFORM_USERID: {
    key: `${namespace}.enterUserId`,
    message: 'Enter your user id',
  },
  INFORM_YOUR_PASSWORD: {
    key: `${namespace}.enterYourPassword`,
    message: 'Enter your password',
  },
  INFORM_YOUR_TOKEN: {
    key: `${namespace}.enterYourToken`,
    message: 'Enter your token',
  },
  NEW_PASSWORD: {
    key: `${namespace}.newPassword`,
    message: 'New Password',
  },
  CONFIRM_PASSWORD: {
    key: `${namespace}.confirmNewPassword`,
    message: 'Confirm New Password',
  },
  SECURITY_TOKEN: {
    key: `${namespace}.securityToken`,
    message: 'Security Token',
  },
  PLEASE_ENTER_THE_SECURITY_TOKEN: {
    key: `${namespace}.pleaseEnterTheSecurityToken`,
    message: 'Please enter the security token sent to your e-mail',
  },
  USERNAME_FORGOTTEN: {
    key: `${namespace}.usernameForgotten`,
    message: 'Username forgotten',
  },
  PASSWORD_FORGOTTEN: {
    key: `${namespace}.passwordForgotten`,
    message: 'Password forgotten',
  },
  I_HAVE_NO_ACCOUNT: {
    key: `${namespace}.iHaveNoAccount`,
    message: 'I have no account,',
  },
  I_WILL_INFORM_MY: {
    key: `${namespace}.iWillEnter`,
    message: 'I will enter my own data',
  },
  WELCOME: {
    key: `${namespace}.welcomeUser`,
    message: 'Welcome',
  },
  SEND_NEW_TOKEN: {
    key: `${namespace}.sendNewToken`,
    message: 'Send new token',
  },
  THIS_EMAIL_IS_NOT: {
    key: `${namespace}.thisEmailIsNot`,
    message: 'This email is not registered',
  },
  CREATE_NEW_USER: {
    key: `${namespace}.createNewUser`,
    message: 'Create new user',
  },
  UNBLOCK_EMAIL: {
    key: `${namespace}.unblockEmail`,
    message:
      'You will receive an e-mail with instructions to unlock your account!',
  },
  TROUBLE_LOGGING_IN: {
    key: `${namespace}.troubleLoggingIn`,
    message: 'Trouble logging in',
  },
  CONTACT_EMAIL_SUBJECT: {
    key: `${namespace}.contactEmailSubject`,
    message: 'Subject',
  },
  CONTACT_EMAIL_BODY: {
    key: `${namespace}.contactEmailBody`,
    message: 'Body',
  },
  RECEIVE_PASSWORD: {
    key: `${namespace}.receivePassword`,
    message: 'Receive Password',
  },
  SENT_CHANGE_PASSWORD_EMAIL: {
    key: `${namespace}.sentChangePasswordEmail`,
    message: 'You will receive an e-mail with further instructions',
  },
  BACK_TO_LOGIN: {
    key: `${namespace}.backToLogin`,
    message: 'Back to Login',
  },
  RECOVER_PASSWORD: {
    key: `${namespace}.recoverPassword`,
    message: 'Recover Password',
  },
  RECOVER_PASSWORD_DESCRIPTION: {
    key: `${namespace}.recoverPasswordDescription`,
    message:
      'Please inform your login name here, and you will receive an e-mail with further instructions',
  },
  ENTER_NEW_PASSWORD: {
    key: `${namespace}.enterNewPassword`,
    message: 'Enter your new password below',
  },
  PASSWORD_CHANGED: {
    key: `${namespace}.passwordChanged`,
    message: 'Your password has been changed with success',
  },
  RECOVERY_PASSWORD_ERROR: {
    key: `${namespace}.recoveryPasswordError`,
    message: 'Error sending recovery password email',
  },
  I_ACCEPT_DATA_PRIVACY: {
    key: `${namespace}.iAcceptDataPrivacy`,
    message: 'I accept the data privacy',
  },
  CHANGE_PASSWORD: {
    key: `${namespace}.changePassword`,
    message: 'Change Password',
  },
  RECOVER_PASS_EMAIL_SUCCESS: {
    key: `${namespace}.changePassEmailSuccess`,
    message: 'Recovery email successfully sent',
  },
  DIDINT_RECEIVE: {
    key: `${namespace}.didintReceiveEmail`,
    message: "Didn't you receive? Request again",
  },
  RECOVER_USERNAME: {
    key: `${namespace}.recoverUsername`,
    message: 'Recover Username',
  },
};

const RECOVER_USERNAME = {
  INFORM_YOUR_EMAIL: {
    TITLE: {
      key: `${namespace}.recoverUsernameTitle`,
      message: 'Recover Username',
    },
    SUB_TITLE: {
      key: `${namespace}.recoverUsernameDescription`,
      message: 'Inform your e-mail to recover your username',
    },
  },
  SUCCESS: {
    key: `${namespace}.recoverUsernameSuccess`,
    message: 'Your username has been sent to your e-mail',
  },
  ERROR: {
    key: `${namespace}.recoverUsernameError`,
    message: 'Error recovering your username',
  },
};

const I18N = {
  AUTHENTICATION,
  RECOVER_USERNAME,
};

export default I18N;
