import { LegalFormJSON } from '@mmw/services-core-common/types';

export const GET_LEGAL_FORM_LIST_START =
  '@mmw/legal-form-list/GET_LEGAL_FORM_LIST_START';
export const GET_LEGAL_FORM_LIST_SUCCESS =
  '@mmw/legal-form-list/GET_LEGAL_FORM_LIST_SUCCESS';
export const GET_LEGAL_FORM_LIST_ERROR =
  '@mmw/legal-form-list/GET_LEGAL_FORM_LIST_ERROR';

export type GetLegalFormListStartAction = {
  type: '@mmw/legal-form-list/GET_LEGAL_FORM_LIST_START';
};

export type GetLegalFormListSuccessAction = {
  type: '@mmw/legal-form-list/GET_LEGAL_FORM_LIST_SUCCESS';
  payload: {
    legalFormList: Array<LegalFormJSON>;
  };
};

export type GetLegalFormListErrorAction = {
  type: '@mmw/legal-form-list/GET_LEGAL_FORM_LIST_ERROR';
  payload: {
    error: Error;
  };
};

export type State = {
  loading: boolean;
  legalFormList: Array<LegalFormJSON>;
  error: Error | null;
};

export type Action =
  | GetLegalFormListStartAction
  | GetLegalFormListSuccessAction
  | GetLegalFormListErrorAction;

export type Reducer = (state: State, action: Action) => State;
