/* eslint-disable no-param-reassign */
import { AsyncActions } from '@redux-async-module/actions-utils/types';
import { Builder } from '@redux-basic-module/reducer-utils/ReducerBuilder';
import { enableMapSet } from 'immer';

import { SimpleAsyncState } from './state';

enableMapSet();

export function addReducerSimpleAsyncCases<StartPayload, SuccessPayload>(
  initialState: SimpleAsyncState<SuccessPayload>,
  actions: AsyncActions<StartPayload, SuccessPayload, any>,
  builder: Builder<SimpleAsyncState<SuccessPayload>>,
): void {
  builder
    .addCase(actions.start, draft => {
      draft.loading = true;
      return draft;
    })
    .addCase(actions.success, (draft, { payload }) => {
      draft.data = <SuccessPayload>payload;
      draft.loading = false;
      draft.error = null;
      draft.requestAttempted = true;
      return draft;
    })
    .addCase(actions.error, (draft, { payload }) => {
      draft.loading = false;
      draft.error = payload;
      draft.requestAttempted = true;
      return draft;
    })
    .addCase(actions.cancel, draft => {
      draft.loading = false;
      return draft;
    })
    .addCase(actions.dismissError, draft => {
      draft.error = null;
      return draft;
    })
    .addCase(actions.reset, () => initialState);
}
