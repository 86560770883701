import { ChangeEvent, useCallback, useState } from 'react';
import { F } from 'ts-toolbelt';

const useRadioState = (
  initialValue: string,
  callback?: F.Function<[string]>,
): [string, F.Function<[ChangeEvent<HTMLInputElement>]>] => {
  const [value, setValue] = useState<string>(initialValue);
  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setValue(newValue);
      if (typeof callback === 'function') callback(newValue);
    },
    [callback],
  );
  return [value, onChange];
};

const hooks = {
  useRadioState,
};

export { hooks as default, useRadioState };
