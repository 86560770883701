import { align } from '@mmw/ui-theme/modifiers';
import { StyledProps } from '@mmw/ui-theme/types';

import { BACKGROUND_COLOR_MODIFIERS, PADDING } from './constants';

export default {
  closeButton: {
    style: (props: StyledProps<{}>) => `
      position: absolute;
      top: 10px;
      right: 10px;
      width: 30px;
      height: 30px;
      padding: 0;
      cursor: pointer;
      fill: ${props.theme.colors.pallet.primary};
    `,
    modifiers: undefined,
  },
  content: {
    style: ({ theme }) => `
      display: inline-block;
      padding: ${PADDING};
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      
      ${theme.media.phone`
        overflow: unset;
        padding: 0;
        width: 100%;
        height: auto;
        box-sizing: border-box;
      `}
      ${theme.media.tablet`
        overflow: none;
      `}
    `,
    modifiers: BACKGROUND_COLOR_MODIFIERS,
  },
  header: {
    style: ({ theme }) => `
      padding: ${PADDING};
      background-color: ${theme.colors.pallet.background};
      width: 100%;
      box-sizing: border-box;
      ${theme.media.upToTablet`
       padding: 15px 20px;
      `};
    `,
    modifiers: BACKGROUND_COLOR_MODIFIERS,
  },
  footer: {
    style: ({ theme }) => `
      display: flex;
      flex-direction: row;
      margin-top: auto;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: ${PADDING};
      background: ${theme.colors.pallet.light}
      ${theme.media.phone`
        padding: 20px;
        flex-direction: column;
      `}
    `,
    modifiers: {
      ...BACKGROUND_COLOR_MODIFIERS,
      ...align.justifyContent,
    },
  },
};
