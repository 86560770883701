import { enableBatching } from 'redux-batched-actions';

import changePasswordEpic from './epics/changePasswordEpic';
import recoverPasswordEpic from './epics/recoverPasswordEpic';
import validateChangePasswordUuidEpic from './epics/validateChangePasswordUuidEpic';
import recoverPasswordReducer from './reducer';
import { NAMESPACE as authenticationNamespace } from './types';

export const reducer = recoverPasswordReducer;
export const NAMESPACE = authenticationNamespace;
export const epics = [
  recoverPasswordEpic(),
  changePasswordEpic(),
  validateChangePasswordUuidEpic(),
];

export function getRecoverPasswordModule(props?: any) {
  return {
    id: NAMESPACE,
    reducerMap: {
      [NAMESPACE]: enableBatching(reducer),
    },
    epics: [
      recoverPasswordEpic(props),
      changePasswordEpic(props),
      validateChangePasswordUuidEpic(props),
    ],
  };
}
