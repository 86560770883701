import { ConsumerCampaignClientCampaignJSON } from '@ccc/service-campaigns-list/types';
import { State } from '@redux-async-module/interfaces';

export const NAMESPACE = 'consumer-campaign-client-campaigns';

export interface CampaignsListStartPayload {
  sob?: number;
}

export type CampaignsListSuccessPayload = ConsumerCampaignClientCampaignJSON[];

export interface RootState
  extends State<CampaignsListStartPayload, CampaignsListSuccessPayload> {
  currentCampaignsCountry: string;
  alreadySearch: boolean;
}
