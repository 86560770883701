import {
  CreateIssueJSON,
  IssueActionRequired,
  IssueType,
} from '@issue/interfaces';
import { CampaignType } from '@mmw/constants-campaign-types';
import { EMPTY_ARRAY } from '@mmw/constants-utils';
import { RegistrationResponseJSON } from '@mmw/services-core-trader-registration/types';
import { findCampaignItemByCampaignType } from '@mmw/utils-campaign-item';
import map from 'lodash/map';

export function createIssuesForApprovedForInitialOrder(
  registration: RegistrationResponseJSON,
): CreateIssueJSON[] {
  if (!registration) return EMPTY_ARRAY;
  const ci = findCampaignItemByCampaignType(
    registration.campaignItems,
    CampaignType.AUDIT,
  );
  return map(
    ci?.questionGroups,
    (questionGroup): CreateIssueJSON => ({
      summary: '',
      actorvisibility: null,
      actionRequired: IssueActionRequired.CORRECT_QUESTION_ATTACHMENT_FILE,
      type: IssueType.DATA_CORRECTION,
      dueDate: null,
      assigneeID: null,
      data: {
        campaignItemIDs: [ci.campaignitemID],
        message: '',
        questionGroupCodes: [questionGroup.code],
        questionCodes: EMPTY_ARRAY,
        registrationItemIDs: EMPTY_ARRAY,
        configuration: {
          associatedToCampaignItems: true,
          associatedToRegistrationItems: true,
          amountOfActionsRequiredToResolve: questionGroup.requiresUploads,
        },
        entries: EMPTY_ARRAY,
        fileIDs: EMPTY_ARRAY,
      },
      initialStatus: null,
      initialResolutionMode: null,
    }),
  );
}
