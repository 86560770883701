import navHover from '@mmw/web-assets/navHover.png';

export default {
  height: '100%',
  width: '9.375rem',
  header: {
    height: '9.375rem',
  },
  title: {
    paddingBottom: '15px',
    paddingRight: '10px',
    width: '80%',
    fontWeight: 'bold',
    bottom: 0,
    right: 0,
    textAlign: 'right',
  },
  content: {
    paddingTop: '20px',
  },
  links: {
    fontWeight: 400,
    bgHover: `url(${navHover}) left bottom no-repeat`,
    link: {
      marginTop: '10px',
      paddingBottom: '10px',
    },
    subLink: {
      lineHeight: '2rem',
    },
  },
  nav: {
    paddingLeft: '10px',
  },
  navItem: {
    marginBottom: '15px',
  },
};
