import {
  CacheInterface,
  CallMethodOptions,
  CallPromiseOptions,
} from '@mmw/common-cache';
import { StoreInterface } from '@mmw/common-store';
import autoBind from 'auto-bind';

import log from './log';

const logger = log.extend('store-cache');

type StoreCacheOptions = {
  store: StoreInterface;
};

class StoreCache implements CacheInterface {
  store: StoreInterface;

  constructor({ store }: StoreCacheOptions) {
    this.store = store;
    autoBind(this);
  }

  async get(key: string): Promise<T | null> {
    logger.trace('Trying to get from store key=%s', key);
    const value: T | null = await this.store.get(key);
    if (!value) {
      return Promise.resolve(null);
    }
    logger.trace('Found key=%s into store with value=%O', key, value);
    return Promise.resolve(value);
  }

  async set(key: string, value: T): Promise<void> {
    await this.store.set<T>(key, value, true);
    logger.trace('Set key=%s with value=%O', key, value);
    return Promise.resolve();
  }

  async clear(key: string): Promise<void> {
    await this.store.set<T>(key, null, true);
    logger.trace('Cleared key=%s', key);
    return Promise.resolve();
  }

  async callPromise({ cacheKey, method }: CallPromiseOptions<T>): Promise<T> {
    let value: T | null;
    try {
      value = await this.get<T>(cacheKey);
      if (value) {
        return value;
      }
    } catch (error) {
      logger.error('Error while trying to get from store, error=%O', error);
    }
    try {
      value = await method();
    } catch (error) {
      logger.error('Error while trying to run promise, error=%O', error);
      throw error;
    }
    try {
      await this.set<T>(cacheKey, value);
    } catch (error) {
      logger.error(
        'Error while trying to set value into store, error=%O',
        error,
      );
    }
    return value;
  }

  async callMethod({ cacheKey, method }: CallMethodOptions<T>): Promise<T> {
    let value: T | null;
    try {
      value = await this.get<T>(cacheKey);
      if (value) {
        return value;
      }
    } catch (error) {
      logger.error('Error while trying to get from store, error=%O', error);
    }
    try {
      value = method();
    } catch (error) {
      logger.error('Error while trying to run promise, error=%O', error);
      throw error;
    }
    try {
      await this.set<T>(cacheKey, value);
    } catch (error) {
      logger.error(
        'Error while trying to set value into store, error=%O',
        error,
      );
    }
    return value;
  }
}

export default StoreCache;
