import { StyledComponent } from '@mmw/ui-theme/types';
import { use } from '@mmw/ui-theme/utils';
import { Body2 } from '@mmw/ui-web-elements-typography';
import styled from 'styled-components';

const LinkText: StyledComponent<typeof Body2, {}> = styled(Body2)(props =>
  use(props, 'link.text'),
);

export default LinkText;
