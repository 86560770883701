import defaultApiV2, { ApiResponse } from '@mmw/api-v2';
import { LanguageCode } from '@mmw/constants-languages';
import AuthenticationService from '@mmw/services-auth-api-authentication';
import { Pagination } from '@mmw/services-core-common/types';
import autoBind from 'auto-bind';
import { U } from 'ts-toolbelt';

import { getAcceptedBusinessRelationPath } from './apiPaths';
import logger from './log';
import { BusinessRelationJSON } from './types';

type Api = typeof defaultApiV2;

type TraderBusinessRelationOptions = {
  apiv2?: Api;
  authenticationService: AuthenticationService;
};

class TraderBusinessRelationService {
  api: Api;

  authenticationService: AuthenticationService;

  constructor({ apiv2, authenticationService }: TraderBusinessRelationOptions) {
    this.api = apiv2 || defaultApiV2;
    this.authenticationService = authenticationService;
    autoBind(this);
  }

  async getAllAcceptedBusinessRelations(
    salesOrgBrandID: number,
    offerorRole: U.Nullable<string>,
    branchID: string | number,
    language: LanguageCode,
  ): Promise<BusinessRelationJSON[]> {
    logger.debug(
      'Trying to get all accepted business relations of branchID=%s',
      branchID,
    );
    try {
      const headers =
        await this.authenticationService.getAuthenticationHttpHeaders();
      const response: ApiResponse<Pagination<BusinessRelationJSON>> =
        await this.api.post(
          getAcceptedBusinessRelationPath(branchID, language),
          {
            salesOrgBrandID,
            limit: 100000,
            offset: 0,
            offerorrole: offerorRole,
          },
          { headers },
        );
      const { data } = response;
      logger.info(
        'Successfully got accepted business relations of branchID=%s',
        branchID,
      );
      return data?.list;
    } catch (error) {
      logger.error(
        'Error when trying yo get accepted business relations of branchID=, error=%O',
        branchID,
        error,
      );
      throw error;
    }
  }
}

export default TraderBusinessRelationService;
