import { ConsumerProfileJSON } from '@mmw/services-core-consumer-profile/types';
import { createAsyncActions } from '@redux-async-module/actions-utils';
import { createAction } from '@reduxjs/toolkit';

import {
  ChangePasswordStartPayload,
  NAMESPACE,
  ProfileDataPayload,
  RESET_DATA,
  RESET_SUCCESS_FLAG,
} from './types';

export const getConsumerProfileData = createAsyncActions<
  void,
  ProfileDataPayload,
  Error,
  void
>(NAMESPACE, 'get-consumer-profile-data');

export const saveConsumerProfileData = createAsyncActions<
  ConsumerProfileJSON,
  ProfileDataPayload,
  Error,
  void
>(NAMESPACE, 'save-consumer-profile-data');

export const changePasswordByOperationToken = createAsyncActions<
  ChangePasswordStartPayload,
  boolean,
  Error,
  void
>(NAMESPACE, 'change-password-by-operation-token');

export const resetSuccessFlagsAction = createAction(
  RESET_SUCCESS_FLAG,
  (payload: boolean) => ({
    payload,
  }),
);

export const resetProfileDataAction = createAction(RESET_DATA);
