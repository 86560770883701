import contextualConfig from '@mmw/contextual-config';
import ofType from '@mmw/redux-rx-of-type-operator';
import { languageFieldPath } from '@mmw/redux-store-i18n/stateSelector';
import { getCommonService } from '@mmw/services-holder';
import { from, of } from 'rxjs';
import {
  catchError,
  combineLatest,
  distinctUntilChanged,
  map,
  pluck,
  switchMap,
  tap,
} from 'rxjs/operators';

import {
  getAvailableSalutationsErrorAction,
  getAvailableSalutationsSuccessAction,
} from './actions';
import { GET_AVAILABLE_SALUTATIONS_START } from './types';

const { logger } = contextualConfig.application;

const getAvailableSalutationsEpic = (action$, state$) =>
  action$.pipe(
    ofType(GET_AVAILABLE_SALUTATIONS_START),
    tap(() => logger.debug('Get Available Salutations Start')),
    combineLatest(
      state$.pipe(pluck(...languageFieldPath), distinctUntilChanged()),
    ),
    tap(([, language]) =>
      logger.debug('Trying to get available salutations by lang=%s', language),
    ),
    switchMap(([, language]) =>
      from(getCommonService().getSalutations(language)).pipe(
        tap(data =>
          logger.info(
            `Got salutations for lang=%s with size=${data.length}`,
            language,
          ),
        ),
        map(data => getAvailableSalutationsSuccessAction(data)),
        catchError(error => of(getAvailableSalutationsErrorAction(error))),
      ),
    ),
  );

export default getAvailableSalutationsEpic;
