import { CampaignType } from '@mmw/constants-campaign-types';
import {
  QuestionGroupJSON,
  QuestionJSON,
} from '@mmw/services-core-common/types';
import {
  CampaignItemJSON,
  RegistrationResponseJSON,
} from '@mmw/services-core-trader-registration/types';
import {
  getQuestionGroupAnswer as internalGetQuestionGroupAnswer,
  normalizeQuestionsInQuestionGroups,
  updateQuestionGroupAnswer,
} from '@mmw/utils-questions';
import { each, filter, find, isEmpty, map, mapKeys, uniq, xor } from 'lodash';
import get from 'lodash/get';
import { U } from 'ts-toolbelt';

export const mapCampaignItemKey = (ci: CampaignItemJSON): string =>
  `${ci.campaign.campaignID}-${ci.productPosition}-${ci.productID}`;

export const mapCampaignItemKeys = (
  campaignItems: U.Nullable<CampaignItemJSON[]>,
): string[] => map<CampaignItemJSON, string>(campaignItems, mapCampaignItemKey);

export const mapIndexesWithQuestionGroup = (
  campaignItems: U.Nullable<CampaignItemJSON[]>,
  questionGroup: QuestionGroupJSON,
): number[] => {
  const indexes = map<CampaignItemJSON, number>(campaignItems, (ci, index) => {
    const foundQg = find(
      ci.questionGroups,
      qg => qg.code === questionGroup.code,
    );
    return foundQg != null ? index : -1;
  });
  return filter<number>(indexes, (idx: number) => idx > -1);
};

export const findQuestionGroupIndex = (
  campaignItems: U.Nullable<CampaignItemJSON[]>,
  questionGroup: QuestionGroupJSON,
): number => {
  const indexes = mapIndexesWithQuestionGroup(campaignItems, questionGroup);
  if (isEmpty(indexes)) {
    return -1;
  }
  let index = -1;
  const ciIndex: number = indexes[0];
  each(
    get(campaignItems, `${ciIndex}.questionGroups`) as QuestionGroupJSON[],
    (qg: QuestionGroupJSON, qgIndex: number) => {
      if (qg.code === questionGroup.code) {
        index = qgIndex;
      }
    },
  );
  return index;
};

export const findMatchingCampaignItem = (
  campaignItem: CampaignItemJSON,
  campaignItems: U.Nullable<CampaignItemJSON[]>,
  checkPosition = true,
): U.Nullable<CampaignItemJSON> =>
  find<CampaignItemJSON>(campaignItems, (ci: CampaignItemJSON) => {
    const sameCampaign =
      ci.campaign.campaignID === campaignItem.campaign.campaignID;
    const samePosition = ci.productPosition === campaignItem.productPosition;
    if (!checkPosition) {
      return sameCampaign;
    }
    return sameCampaign && samePosition;
  });

export const findCampaignItemWithSameQuestions = (
  campaignItem: CampaignItemJSON,
  campaignItems: U.Nullable<CampaignItemJSON[]>,
): U.Nullable<CampaignItemJSON> => {
  const currentQuestionCodes = map(campaignItem.questionGroups, 'code');
  return find(campaignItems, ci => {
    const questionCodes = map(ci.questionGroups, 'code');
    return xor(currentQuestionCodes, questionCodes).length === 0;
  });
};

type UpdatedPath = Record<string, string | boolean>;

type UpdateResult = {
  updated: boolean;
  updatedPaths: UpdatedPath;
};

export const updateCampaignItemsQuestionAnswer = (
  answer: string | boolean,
  questionGroup: QuestionGroupJSON,
  // XXX: when you only want to update question group (RADIO)
  question: QuestionJSON | null,
  campaignItems: U.Nullable<CampaignItemJSON[]>,
): UpdateResult => {
  let updated = false;
  let updatedPaths = {};
  each(campaignItems, (ci, index) => {
    const { updated: updatedQg, updatedPaths: updatedQuestionGroupPaths } =
      updateQuestionGroupAnswer(
        answer,
        questionGroup,
        question,
        ci.questionGroups,
      );
    if (!updatedQg) {
      return;
    }
    updated = true;
    updatedPaths = {
      ...updatedPaths,
      ...mapKeys(updatedQuestionGroupPaths, (value, key) => `${index}.${key}`),
    };
  });
  return {
    updated,
    updatedPaths,
  };
};

export const findQuestionGroup = (
  citems: U.Nullable<CampaignItemJSON[]>,
  code: string,
): U.Nullable<QuestionGroupJSON> => {
  const campaignItem = find(
    citems,
    ci => find(ci.questionGroups, qg => qg.code === code) != null,
  );
  if (campaignItem) {
    return find(campaignItem.questionGroups, qg => qg.code === code);
  }
  return null;
};

export const getQuestionGroupAnswer = (
  citems: U.Nullable<CampaignItemJSON[]>,
  code: string,
): string => {
  const qg = findQuestionGroup(citems, code);
  return internalGetQuestionGroupAnswer(qg);
};

export const getScoreCampaignCodes = (
  campaignItems: U.Nullable<CampaignItemJSON[]>,
): string[] => {
  const scoreCampaignItems = filter(
    campaignItems,
    ci => ci.campaign.type === CampaignType.SCORE,
  );
  return uniq(map(scoreCampaignItems, ci => ci.campaign.code));
};

export const findCampaignItemByCampaignType = (
  campaignItems: U.Nullable<CampaignItemJSON[]>,
  campaignType: CampaignType,
): U.Nullable<CampaignItemJSON> =>
  find(campaignItems, ci => ci.campaign.type === campaignType);

const normalizeQuestionsInCampaignItems = (
  campaignItems: U.Nullable<CampaignItemJSON[]>,
) =>
  map<CampaignItemJSON, CampaignItemJSON>(campaignItems, ci => ({
    ...ci,
    questionGroups: normalizeQuestionsInQuestionGroups(ci.questionGroups),
  }));

export const normalizeQuestionsIntoRegistrationResponse = (
  response: RegistrationResponseJSON,
): RegistrationResponseJSON => ({
  ...response,
  campaignItems: normalizeQuestionsInCampaignItems(response.campaignItems),
});
