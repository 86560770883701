import borderSchema from '@mmw/ui-theme/modifiers/color/border';

export default {
  ...borderSchema,
  textcenter: () => `
    text-align: center;
  `,
  textright: () => `
    text-align: right;
  `,

  center: () => `
    display: flex;
    justify-content: center;
    align-content: center;
  `,
  right: () => `
    display: flex;
    justify-content: flex-end;
    align-content: center;
  `,
};
