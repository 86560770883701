import {
  NEW_PASSWORD_VALIDATION_SCHEMA,
  NewPasswordForm,
} from '@auth/form-data';
import { BackToLoginAction, PasswordField } from '@auth/view-form-components';
import { PasswordConfirmationField } from '@auth/view-form-components/PasswordField';
import { AUTHENTICATION_I18N } from '@consumer/constants-i18n';
import { useIsLoading } from '@consumer/profile-redux-store/hooks';
import { FastFormSubmitButton } from '@fast-form/ui-system';
import { CONFIRM } from '@mmw/constants-i18n-buttons';
import { PASSWORD } from '@mmw/constants-i18n-common';
import { FastFormProvider } from '@mmw/redux-store-fast-form';
import { AUTHENTICATION as AUTH_I18N } from '@retail/constants-i18n';
import { css } from '@ui-system/css';
import UI from '@ui-system/ui';
import React from 'react';
import { F } from 'ts-toolbelt';

const INITIAL_VALUES = new NewPasswordForm();

interface Props {
  onSubmitSuccess: F.Function<[NewPasswordForm]>;
  onCancel: F.Function;
}

const NewPassword: React.FC<Props> = ({ onSubmitSuccess, onCancel }: Props) => {
  const loading = useIsLoading();
  return (
    <FastFormProvider
      // @ts-ignore
      validationSchema={NEW_PASSWORD_VALIDATION_SCHEMA}
      initialValues={INITIAL_VALUES}
      onSubmitSuccess={onSubmitSuccess}
    >
      <UI.Container justify="center" w="100%">
        <UI.Container bg="white" borderRadius={20} w={565} p={6} shadow={10}>
          <UI.Container direction="column" gap={4} f={1}>
            <UI.Container direction="column" gap={2}>
              <UI.Container justify="space-between">
                <UI.Typography
                  modifiers="primary"
                  variant="h4"
                  i18n={AUTH_I18N.AUTHENTICATION.RECOVER_PASSWORD}
                />
                {onCancel && (
                  <UI.Container
                    justify="flex-end"
                    style={css`
                      text-align: right;
                      text-wrap: nowrap;
                    `}
                  >
                    <BackToLoginAction
                      onClick={onCancel}
                      textModifiers="capitalize, grayA300"
                      textVariant="caption"
                      useLink
                    />
                  </UI.Container>
                )}
              </UI.Container>
            </UI.Container>
            <PasswordField
              label={PASSWORD}
              accessoryLeft={
                <UI.Icon name="password" size={20} color="primary.light" />
              }
            />
            <PasswordConfirmationField
              label={AUTHENTICATION_I18N.SIGN_UP.CONFIRM_PASSWORD}
              accessoryLeft={
                <UI.Icon name="password" size={20} color="primary.light" />
              }
            />
            <UI.Container>
              <FastFormSubmitButton i18n={CONFIRM} loading={loading} />
            </UI.Container>
          </UI.Container>
        </UI.Container>
      </UI.Container>
    </FastFormProvider>
  );
};

export default NewPassword;
