import { useContext } from 'react';
import { U } from 'ts-toolbelt';

import { ReCaptchaV3Context } from './Context';

const useReCaptchaV3Context = () => useContext(ReCaptchaV3Context);

export const useGetToken = (): ((
  action: string,
) => Promise<U.Nullable<string>>) => {
  const { getToken } = useReCaptchaV3Context();
  return getToken;
};
